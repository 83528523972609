import LedgerService from '../service/ledger';

export const getLedger = (pageNumber, fromDate, toDate, lookForOrderID) => {
  return async(dispatch) => {
    try {
      let response = await LedgerService.getLedger({fromDate, toDate, pageNumber, lookForOrderID});
      dispatch({
        type: 'GET_LEDGER',
        payload: {...response, pageNumber, fromDate, toDate, lookForOrderID}
      });
    } catch(err) {
      dispatch({
        type: 'GET_LEDGER',
        payload: {data: null}
      });
    }
  }
}