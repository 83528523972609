import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col, Card, message, Skeleton, Tabs, Input, Modal } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Footer from '../footer'
import CartService from '../../service/cart';
import MediaUrl from '../../service/mediaUrl'
import myImage from '../../imageNotFound.jpeg';
import { OrderReturnStatus } from '../../service/statusTypes'
import './index.scss'
import { withTranslation } from 'react-i18next';
const { TabPane } = Tabs;
const { Search } = Input

const filterValue = { all: "All", sold: 'Sold', purchase: 'Purchased' }

class ReturnsListComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			returnOrdersData: [],
			status: '',
			searchStr: '',
			visible: false,
			filter: 'all'
		}
	}

	componentDidMount() {
		const values = queryString.parse(this.props.location.search)
		if (values && values.status) {
			if (values && values.status && Array.isArray(values.status)) {
				this.setState({ status: values.status[0] }, () => {
					this.getReturnOrders()
				})
			} else {
				this.setState({ status: values.status }, () => {
					this.getReturnOrders()
				})
			}
		} else {
			this.setState({ status: 'All' }, () => {
				this.getReturnOrders()
			})
		}
	}

	getReturnOrders = async () => {
		const values = queryString.parse(this.props.location.search)
		const { status, searchStr, filter } = this.state
		const { t } = this.props

		let obj = { limit: 100, pageNumber: 1, searchStr, filter }

		if (status !== 'All' || status === '') {
			obj.status = values && values.status ? values.status : status
		}

		let x = await CartService.getAllReturnOrders(obj)

		if (x.data.isSuccess) {
			let { data } = x.data
			this.setState({
				returnOrdersData: data.results,
				currentPage: data.currentPage,
				totalCount: data.totalCount,
				totalPageCount: data.totalPageCount,

			}, () => { })

		} else {
			message.error(`${t('Error in fetching data')}`)
		}

	}

	showModal = () => {
		this.setState({ visible: true })
	}

	closeModal = () => {
		this.setState({ visible: false })
	}

	navigate = (url) => {
		this.props.history.push({ pathname: url })
	}

	onTabChange = (key) => {
		switch (key) {
			case 'All':
				this.props.history.push({ pathname: '/your-returns', search: `?status=${key}` })
				return this.setState({ status: 'All' }, () => {
					this.getReturnOrders()
				})
			case 'Placed':
				this.props.history.push({ pathname: '/your-returns', search: `?status=${key}` })
				return this.setState({ status: key }, () => {
					this.getReturnOrders()
				})
			case 'Rejected':
				this.props.history.push({ pathname: '/your-returns', search: `?status=${key}` })
				return this.setState({ status: key }, () => {
					this.getReturnOrders()
				})
			case 'Approved':
				this.props.history.push({ pathname: '/your-returns', search: `?status=Approved&status=Delivered&status=Shipped&status=ApprovedWithDiscount` })
				return this.setState({ status: key }, () => {
					this.getReturnOrders()
				})

			default:
				return
		}
	}

	render() {
		const { returnOrdersData, status, visible, filter } = this.state
		const { currentUser, t } = this.props
		const returnOrdersList = <>
			{returnOrdersData && returnOrdersData.length > 0 ? returnOrdersData.map((item, returnkey) => {
				return (<Row key={returnkey}>
					<Col span={24}>
						<div className={'p-3 profile_card'}>
							<div className={'flexContainer'} >
								<div className={'productImg'}>
									<img src={item.mapOrderReturnFileUploadedDatas[0] && item.mapOrderReturnFileUploadedDatas[0].fileUploadedData.url && MediaUrl.completeMediaUrl(item.mapOrderReturnFileUploadedDatas[0].fileUploadedData.url, true, 100, 100) || myImage} alt={'preview'} />
								</div>
								<div className={'flexRightDiv'}>

									<p className='textcolor font12px'>{t('Order By')} : <span style={{ fontWeight: '700', color: '#000', fontSize: '14px' }}>{item['buyerAccountName']}</span></p>

									<p className='textcolor font12px'>{t('Sold By')} : <span style={{ fontWeight: '700', color: '#000', fontSize: '14px' }}>{item['sellerAccountName']}</span></p>
								</div>
								<div className='flexLeftDiv'>
								</div>
							</div>
							<hr />
							<div className={'flexContainer pointer_Cursor'}
								onClick={(e) => this.navigate(`/return-details/${item.orderReturnId}`)}>
								<div className={'flexFullDiv'} id= "return-details-box" >
									<p className='label textcolor font16px'>{t('Return Order Id')}</p>
									<div><b className='font14px'>{item.orderReturnId}</b></div>

									{/* <div className={'textcolor font12px mt-3'}>Invoice No.<span className="rightIcon">Invoice Date</span></div> */}
									{/* <p className={'font12px'}><span className="rightIcon"><b>{moment().format('DD/MM/YYYY')}</b></span></p> */}
									{
										(item.sellerAccountId === currentUser.accountId && currentUser.account.type === 'Seller')
											? <div className='font14px mt-3 mb-3'><b>₹ {item && (item.totalAmount - item.shippingCharges).toFixed(2)} /-</b></div>
											: <div className='font14px mt-3 mb-3'><b>₹ {item ? item.totalAmount : 0} /-</b></div>
									}
									<p className='label font16px'>{t('Created On')}: {moment(item.createdOn).format('LL')}</p>
									<p className='label textcolor font16px mt-3'>{t('Status')}</p>
									<p className='label font14px'>
										<b>{item.status === 'ApprovedWithDiscount' ? `${t('Approved with Discount')}` : `${t(item.status)}`}</b>
									</p>

									{item.status === 'Rejected' && <>
										<p className='label textcolor font16px'>{t('Rejected Due To')}</p>
										<p className='label font14px'><b>{item.reason ? item.reason : ''}</b></p>
									</>}
								</div>
							</div>
						</div>

					</Col>
				</Row>)
			}) : (returnOrdersData.length === 0 ? <Card className={'no_data_div'}>No returns to show</Card> : <Skeleton active />)
			}
		</>

		return (<>
			<LayoutComponent title={t('Your returns')}>
				<div className='divWithoutFixButton mt-4 mb-5'>
					<Row>
						<Col span={24}>
							<div className={'toolbar order-tab-bar flexContainer'}>
								<div className={'flexRightDiv'}>
									<Search className='mySearch'
										size={'large'} bordered={false}
										onChange={e => {
											this.setState({ searchStr: e.target.value }, () => {
												this.getReturnOrders()
											})
										}}
										placeholder={t('Search')} />
								</div>
								<div className={'orders'}>{t(`${filterValue[filter]}`)}<DownOutlined
									className={'down_icon'}
									onClick={this.showModal} /></div>
							</div>
						</Col>
						<Col span={24}>
							<Tabs className='tabHeader'
								activeKey={status}
								size={'large'}
								onChange={this.onTabChange}>
								<TabPane tab={t('ALL')} key="All">{returnOrdersList}</TabPane>
								<TabPane tab={t('PENDING')} key="Placed">{returnOrdersList}</TabPane>
								<TabPane tab={t('APPROVED')} key="Approved">{returnOrdersList}</TabPane>
								<TabPane tab={t('REJECTED')} key="Rejected">{returnOrdersList}</TabPane>
							</Tabs>
						</Col>
					</Row>
				</div>

				<Modal
					visible={visible}
					onCancel={this.closeModal}
					footer={null}
					width={1000}
					centered={true}
					maskClosable={true}
				>
					<p className={'action pointer_Cursor'} onClick={() => {
						this.setState({ filter: 'all' }, () => {
							this.closeModal()
							this.getReturnOrders()
						})
					}}>{t('All')}</p>
					<p className={'action pointer_Cursor'} onClick={() => {
						this.setState({ filter: 'sold' }, () => {
							this.closeModal()
							this.getReturnOrders()
						})
					}}>{t('Sold')}</p>
					<p className={'action pointer_Cursor'} onClick={() => {
						this.setState({ filter: 'purchase' }, () => {
							this.closeModal()
							this.getReturnOrders()
						})
					}}>{t('Purchased')}</p>

				</Modal>
			</LayoutComponent>
			<Footer />
		</>)
	}
}


const mapStateToProps = ({ global }) => ({
	currentUser: global.currentUser,
})
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ReturnsListComponent)));
