import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Drawer,
  Radio,
  message,
  Select,
  Form,
  Input,
  Tooltip,
} from "antd";
import _ from "lodash";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import LayoutComponent from "../layoutComponents/layoutComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import userImg from "../../assests/user.png";
import { CaretDownOutlined } from "@ant-design/icons";
import AuthService from "../../service/authService";
import CartService from "../../service/cart";
import MediaUrl from "../../service/mediaUrl";
import { hideLoader, showLoader } from "../../actions";
import "./index.scss";
import LoaderComponent from "../loader";
import logistics from "../../assests/logistics.png";
const { Option } = Select;

class OrderFormsCheckOutComponent extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      quantity: 1,
      hideLessBtn: false,
      addressDrawerVisible: false,
      visibleAddAddressDrawer: false,
      gstDrawerVisible: false,
      addressData: [],
      cartData: {},
      choosedGST: {},
      primaryAddress: {},
      states: [],
      addressIndex: 0,
      isloaderVisible: false,
      gstData: [],
      primaryGST: {},
      gstIndex: 0,
      sellerAvailable: true,
      minOrderValue: 0,
      specialNoteData: [],
      showLabelInput: false,
      labelValue: '',
      showPaymentBtn: true
    };
  }

  getText = (data, id) => {
    let text = "";
    data.forEach((eachItem) => {
      if (eachItem.id == id && eachItem.specialNote) {
        text = eachItem.specialNote;
      }
    });
    return text;
  };

  componentDidMount() {
    this.getCartData();
    this.getAddressDetails();
    this.getGSTDetails();
  }

  getSellerDetails = async (sellerId) => {
    const { t } = this.props;
    let { data } = await AuthService.getVendorDetailsById(sellerId);
    if (data.applicationCode === 200 && data.isSuccess) {
      this.setState({ minOrderValue: data.data.minimumOrderValue });
    } else {
      message.error(`${t("Error in fetching data")}`);
    }
  };

  getCartData = async () => {
    const { t } = this.props;
    let id = this.props.match.params.id;
    this.setState({ cartData: null });
    let { data: resp } = await CartService.getCartItemById(id);

    if (resp && resp.isSuccess) {
      let { data } = resp;
      if (data.sellerAccount && data.sellerAccount.imageFileUploadedData.url) {
        let url = MediaUrl.completeMediaUrl(
          data.sellerAccount.imageFileUploadedData.url,
          true,
          100,
          100
        );
        data.sellerAccount.imageFileUploadedData.url = url;
      }
      let specialNotes = [];
      let showPaymentBtn = true
      data.orderLineItems.forEach((item, ind) => {
        // if (ind === 0) {
        //   item['isAvailable'] = true
        // } else {
        //   item['isAvailable'] = false
        // }

        if (!item.productOrderLineItem.isAvailable) {
          showPaymentBtn = false
        }

        let { mapProductFileUploadedDatas } = item.productOrderLineItem;

        if (
          mapProductFileUploadedDatas.length &&
          mapProductFileUploadedDatas[0] &&
          mapProductFileUploadedDatas[0].fileUploadedData &&
          mapProductFileUploadedDatas[0].fileUploadedData.url
        ) {
          let url = MediaUrl.completeMediaUrl(
            mapProductFileUploadedDatas[0].fileUploadedData.url,
            true,
            100,
            100
          );
          item.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url =
            url;
        } else {
          item.productOrderLineItem.mapProductFileUploadedDatas = [];
        }
        specialNotes.push({
          id: item.id,
          specialNote: item.specialNote,
        });
      });
      this.setState(
        {
          showPaymentBtn: showPaymentBtn,
          cartData: data,
          specialNoteData: [...specialNotes],
        },
        () => {
          this.getSellerDetails(data.sellerAccountId);
        }
      );
    } else {
      if (resp.applicationCode === 400) {
        this.setState(
          {
            sellerAvailable: false,
          },
          () => {
            message.error(resp.message || `${t("Something went wrong")}`);
          }
        );
      } else {
        message.error(resp.message);
      }
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  changeAddressDrawer = () => {
    this.setState({
      addressDrawerVisible: true,
    });
  };

  changeGSTDrawer = () => {
    this.setState({
      gstDrawerVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      addressDrawerVisible: false,
      gstDrawerVisible: false,
    });
  };

  decreaseQty = () => {
    let orderObj = { ...this.state.orderLineItem };

    if (orderObj.quantity === 0) {
      this.setState({ hideLessBtn: true });
    } else {
      if (orderObj.quantity === orderObj.productOrderLineItem.minimumSetOrder) {
        this.setState({ hideLessBtn: true });
        message.warning(
          `Minimum set order can't be less than ${orderObj.quantity}`
        );
        // orderObj.quantity = orderObj.quantity - orderObj.productOrderLineItem.minimumSetOrder;
      } else {
        this.setState({ hideLessBtn: false });
        orderObj.quantity = orderObj.quantity - 1;
      }
      this.setState({ orderLineItem: orderObj });
    }
  };

  increaseQty = () => {
    let orderObj = { ...this.state.orderLineItem };
    if (orderObj.quantity > 0) {
      this.setState({ hideLessBtn: false });
    }

    // if (orderObj.quantity < orderObj.productOrderLineItem.minimumSetOrder) {
    //     orderObj.quantity = orderObj.quantity + orderObj.productOrderLineItem.minimumSetOrder;
    // } else {
    //     orderObj.quantity = orderObj.quantity + 1;
    // }
    orderObj.quantity = orderObj.quantity + 1;
    this.setState({ orderLineItem: orderObj });
  };

  getAddressDetails = () => {
    const { t } = this.props;
    AuthService.getAddressLists().then(
      (res) => {
        let { data } = res.data;
        let index = _.findIndex(data, (item) => item.isDefault);
        this.setState({
          addressData: data,
          primaryAddress: data[index],
          addressIndex: index,
        });
      },
      (err) => {
        message.error(`${t("Kindly add your address")}`);
      }
    );
  };

  getGSTDetails = () => {
    const { t } = this.props;
    AuthService.getAllGSTAccounts().then(
      (res) => {
        let { data } = res.data;
        let index = _.findIndex(data, (item) => item.isDefault);
        this.setState({
          gstData: data,
          primaryGST: data[index],
          gstIndex: index,
        });
      },
      (err) => {
        message.error(`${t("Kindly add your GST")}`);
      }
    );
  };

  updateOrderLineQtyItem = async () => {
    let { orderLineItem, cartData } = this.state;
    let { dispatch, t } = this.props;

    dispatch(showLoader());

    let x = await CartService.updateOrderLineItemById({
      cartId: cartData.shoppingCartToken,
      orderId: orderLineItem.id,
      body: { quantity: orderLineItem.quantity },
    });

    if (x.data.isSuccess) {
      dispatch(hideLoader());
      let { data } = x.data;
      if (data.sellerAccount.imageFileUploadedData.url) {
        let url = MediaUrl.completeMediaUrl(
          data.sellerAccount.imageFileUploadedData.url,
          true,
          100,
          100
        );
        data.sellerAccount.imageFileUploadedData.url = url;
      }
      data.orderLineItems.forEach((item) => {
        if (
          item.productOrderLineItem.mapProductFileUploadedDatas[0]
            .fileUploadedData.url
        ) {
          let url = MediaUrl.completeMediaUrl(
            item.productOrderLineItem.mapProductFileUploadedDatas[0]
              .fileUploadedData.url,
            true,
            100,
            100
          );
          item.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url =
            url;
        }
      });
      this.setState({ visible: false, cartData: data });
    } else {
      message.error(`${t("Error in updation")}`);
    }
  };

  updateSpecialNote = async () => {
    const { specialNoteData, cartData } = this.state;

    let resp = await CartService.updateOrderLineItemSpecialNote({
      cartToken: cartData.shoppingCartToken,
      body: specialNoteData,
    });
  };

  removeOrderItem = async (deleteobj) => {
    const { t } = this.props;
    let { data } = await CartService.deleteOrderLineItemById(deleteobj);
    if (data.isSuccess) {
      message.success(`${t("Items deleted from cart")}`);
      if (this.state.cartData.orderLineItems.length === 1) {
        this.props.history.push({ pathname: "/order-forms" });
      } else {
        this.getCartData();
      }
    } else {
      message.error(`${t("Error in deletion")}`);
    }
  };

  navigateToPaymentPage = () => {
    const { t } = this.props;
    const { cartData, choosedAddress, choosedGST, primaryGST, minOrderValue } =
      this.state;
    let gstId = null;
    let gstNumber = "";

    if (cartData.priceWithoutGst + cartData.gstAmount < minOrderValue) {
      return message.error(
        `${t("Minimum order value should be greater than")} ${minOrderValue}.`
      );
    } else {
      if (choosedGST && choosedGST.id) {
        gstId = choosedGST.id;
        gstNumber = choosedGST.number;
      } else if (primaryGST && primaryGST.id) {
        gstId = primaryGST.id;
        gstNumber = primaryGST.number;
      } else {
        //do nothing
      }

      let primaryAddress = this.state.primaryAddress
        ? this.state.primaryAddress
        : this.state.addressData[0];
      this.updateSpecialNote();
      this.props.history.push({
        pathname: `/order/payment`,
        search: `?shoppingCartToken=${cartData.shoppingCartToken
          }&shipToAddressId=${choosedAddress ? choosedAddress.id : primaryAddress.id
          }&gstId=${gstId}&gstNumber=${gstNumber}`,
      });
    }
  };

  navigateToProductDetailPage = (productId) => {
    this.props.history.push({ pathname: "/view-product/" + productId });
  };

  getStates = () => {
    AuthService.getStates().then(
      (res) => {
        this.setState({ states: res.data.data });
      },
      (err) => { }
    );
  };

  onFinish = async (values) => {
    console.log(values,"values")
    const { t } = this.props;
    const regExp = /[a-zA-Z]/g;
    const specialChars = /[`!@#$%^&*()_+\=\[\]{};'"\\|<>\?~]/;
    values.label = values.label === 'Other' ? this.state.labelValue : values.label
    if (values && values.addressLine1 && !regExp.test(values.addressLine1)) {
      return message.error(
        `${t("AddressLine1 should contain alphabets also")}`
      );
    } else if(specialChars.test(values.addressLine1) || specialChars.test(values.addressLine2)){
      return message.error(`${t("Special characters are not allowed")}`)
    }
    this.setState({ isloaderVisible: true });
    values["country"] = "India";
    let { data } = await AuthService.addAddress(values);

    if (data.isSuccess) {
      message.success(`${t("Address added successfully")}`);
      this.formRef.current.resetFields();
      this.getAddressDetails();
      this.setState({ visibleAddAddressDrawer: false, isloaderVisible: false, labelValue: '', showLabelInput: false });
    } else {
      message.error(`${t("Error in saving")}`);
    }
  };

  closeDrawer = () => {
    this.setState({ visibleAddAddressDrawer: false });
  };

  checkLabelValue = (val) => {
    if (val === 'Home' || val === 'Office') {
      this.setState({ showLabelInput: false })
    } else {
      this.setState({ showLabelInput: true })
    }
  }


  render() {
    const { loading, t } = this.props;
    const {
      visible,
      hideLessBtn,
      addressDrawerVisible,
      states,
      isloaderVisible,
      addressData,
      primaryAddress,
      cartData,
      orderLineItem,
      visibleAddAddressDrawer,
      choosedAddress,
      gstData,
      primaryGST,
      gstIndex,
      gstDrawerVisible,
      choosedGST,
      specialNoteData,
      showLabelInput,
      labelValue,
      showPaymentBtn
    } = this.state;
    const footer = (
      <>
        <Row>
          <Col span={6}>
            <Button className={"backBtn"} onClick={this.onClose}>
              {t("Back")}
            </Button>
          </Col>
          <Col span={18}>
            <Button
              loading={loading}
              className={"manageBtn"}
              style={{ width: "100%" }}
              onClick={() => {
                this.updateOrderLineQtyItem();
              }}
            >
              {t("Confirm")}
            </Button>
          </Col>
        </Row>
      </>
    );
    const gstFooter = (
      <>
        <Row>
          <Col span={24}>
            <Button
              loading={loading}
              className={"manageBtn"}
              style={{ width: "100%" }}
              onClick={() => {
                this.setState({ gstDrawerVisible: false });
              }}
            >
              {t("Done")}
            </Button>
          </Col>
        </Row>
      </>
    );
    const addressFooter = (
      <>
        <Row>
          <Col span={24}>
            <Button
              className={"manageBtn"}
              style={{ width: "100%" }}
              onClick={() => {
                this.setState({ addressDrawerVisible: false });
              }}
            >
              {t("Done")}
            </Button>
          </Col>
        </Row>
      </>
    );
    const layout = {
      labelCol: { span: 8, offset: 4 },
      wrapperCol: { span: 16, offset: 4 },
    };

    if (!this.state.sellerAvailable) {
      return (
        <LayoutComponent
          title={
            <div className={"py-3"}>
              <div className={"flexContainer"}>
                {/* <div className={'flexLeftDiv'}>
												<img src={(cartData && cartData.sellerAccount && cartData.sellerAccount.imageFileUploadedData.url) ? cartData.sellerAccount.imageFileUploadedData.url : myImage} alt={'smallimg'} className={'headerimg'} />
										</div> */}
                <div className={"flexFullDiv"}>
                  <div>
                    <b>
                      {cartData &&
                        cartData.sellerAccount &&
                        cartData.sellerAccount.name
                        ? cartData.sellerAccount.name
                        : ""}
                    </b>
                  </div>
                  <div className={"textcolor font12px"}>
                    {cartData &&
                      cartData.sellerAccount &&
                      cartData.sellerAccount.addresses &&
                      cartData.sellerAccount.addresses.length &&
                      cartData.sellerAccount.addresses[0]
                      ? cartData.sellerAccount.addresses[0].city +
                      "," +
                      cartData.sellerAccount.addresses[0].stateName
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          }
        >
          <div
            style={{ marginTop: "30vh", flexDirection: "column" }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={logistics}
              style={{ height: "200px", objectFit: "contain" }}
              alt="my image"
            />
            <p className="mt-4">
              {t("Seller is not accepting orders at the moment")}
            </p>
          </div>
        </LayoutComponent>
      );
    }

    return (
      <LayoutComponent
        title={
          <div className={"py-3"}>
            <div className={"flexContainer"}>
              {/* <div className={'flexLeftDiv'}>
                    <img src={(cartData && cartData.sellerAccount && cartData.sellerAccount.imageFileUploadedData.url) ? cartData.sellerAccount.imageFileUploadedData.url : myImage} alt={'smallimg'} className={'headerimg'} />
                </div> */}
              <div className={"flexFullDiv"}>
                <div>
                  <b>
                    {cartData &&
                      cartData.sellerAccount &&
                      cartData.sellerAccount.name
                      ? cartData.sellerAccount.name
                      : ""}
                  </b>
                </div>
                <div className={"textcolor font12px"}>
                  {cartData &&
                    cartData.sellerAccount &&
                    cartData.sellerAccount.addresses &&
                    cartData.sellerAccount.addresses.length &&
                    cartData.sellerAccount.addresses[0]
                    ? cartData.sellerAccount.addresses[0].city +
                    "," +
                    cartData.sellerAccount.addresses[0].stateName
                    : ""}
                </div>
              </div>
            </div>
          </div>
        }
      >
        {cartData && cartData.sellerAccount ? (
          <Row className="checkoutContainer">
            <Col span={24}>
              <div className={"px-3 pt-4"}>
                <div className={"textcolor"}>{t("DELIVER TO")}</div>
                <div>
                  {choosedAddress ? (
                    <span className="font14px">
                      {`${choosedAddress.addressLine1}, ${choosedAddress.addressLine2}, ${choosedAddress.city} ${choosedAddress.stateName} ${choosedAddress.zipCode} ${choosedAddress.country}`}
                    </span>
                  ) : (
                    <span className="font14px">
                      {`${primaryAddress.addressLine1}, ${primaryAddress.addressLine2}, ${primaryAddress.city} ${primaryAddress.stateName} ${primaryAddress.zipCode} ${primaryAddress.country}`}
                    </span>
                  )}

                  {/* <span className='font14px'>{primaryAddress ? primaryAddress.addressLine1 : addressData && addressData[0].addressLine1} , {primaryAddress ? primaryAddress.addressLine2 : addressData && addressData[0].addressLine2} , {primaryAddress ? primaryAddress.zipCode : addressData && addressData[0].zipCode}</span> */}
                  <span style={{ float: "right" }}>
                    <Button
                      type={"text"}
                      className={"changeBtn"}
                      onClick={this.changeAddressDrawer}
                    >
                      {t("Change")}
                    </Button>
                  </span>
                </div>
                <hr />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {choosedGST && choosedGST.id ? (
                    <p className={"textcolor"}>
                      {t("GSTIN")} : {choosedGST.number}
                    </p>
                  ) : primaryGST && primaryGST.id ? (
                    <p className={"textcolor"}>
                      {t("GSTIN")} : {primaryGST.number}
                    </p>
                  ) : (
                    <p className={"textcolor"}>
                      {t("GSTIN")} : {t("No GST number found")}
                    </p>
                  )}
                  <div style={{ float: "right" }}>
                    <Button
                      type={"text"}
                      className={"changeBtn"}
                      onClick={this.changeGSTDrawer}
                    >
                      {t("Change")}
                    </Button>
                  </div>
                </div>
              </div>
              <hr />
              {/* <div className={'px-3 pb-3'}>
                            <div className={'textcolor font12px'}><span>DELIVERY IN : 0-1 days , DELIVERY CHARGES : ₹ 129</span>
                                <span style={{ float: 'right' }}><span className='font10px' >Know More</span></span></div>
                            <div className={'textcolor font12px'}>DELIVERY PARTNER : Akar Express</div>
                        </div> */}

              {/* <div className={'background_div'} ></div> */}

              <div style={{ padding: "10px 20px" }}>
                <div className="flexContainer">
                  <div className="flexRightDiv">
                    <span className="font14px">
                      {cartData &&
                        cartData.sellerAccount &&
                        cartData.sellerAccount.name
                        ? cartData.sellerAccount.name
                        : "Suit villa"}{" "}
                    </span>
                  </div>
                  <div className="flexLeftDiv">
                    <div className="smallVendorImg">
                      <img
                        src={
                          cartData.sellerAccount &&
                            cartData.sellerAccount.imageFileUploadedData.url
                            ? cartData.sellerAccount.imageFileUploadedData.url
                            : userImg
                        }
                        alt="userImage"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"background_div"}></div>
              <div className={"px-3 pt-3 pb-5"}>
                <div className={"textcolor"}>{t("ITEMS")}</div>
                <hr />
                {cartData &&
                  cartData.orderLineItems &&
                  cartData.orderLineItems.length > 0 &&
                  cartData.orderLineItems.map((item, index) => {
                    return (
                      <div key={`${index}`}>
                        <Row
                          className={" pb-2 "}
                          style={{ borderBottom: "1px solid #e5e5e5" }}
                        >
                          <Col xs={9} sm={10} className={""}>
                            <div
                              onClick={(e) =>
                                this.navigateToProductDetailPage(
                                  item.productOrderLineItem.productId
                                )
                              }
                            >
                              <b className="font14px">
                                {item.productOrderLineItem.name}
                              </b>
                            </div>
                            <div className={"textcolor font12px"}>
                              {t("Color")}: <b>{t("Multi Color")}</b>{t("Size")}: <b>M,L,XL,2XL</b>
                            </div>
                            <div className={"priceColor font14px"}>
                              ₹ <b>{item.productOrderLineItem.pricePerSet}</b>{" "}
                              {t('per set')}
                            </div>
                          </Col>
                          <Col xs={9} sm={10} className={" font12px"}>
                            <Col span={24} className={" font12px p-1"}>
                              <b>{t('Special Note')} : </b>
                            </Col>

                            <Col xs={24} sm={24} className={""}>
                              <Input.TextArea
                                rows={2}
                                placeholder={t("Enter details like Model No, Design, etc.")}
                                allowClear
                                value={this.getText(
                                  specialNoteData,
                                  item.id
                                )}
                                onChange={(e) => {
                                  let dump = specialNoteData.map(
                                    (eachItem) => {
                                      if (eachItem.id == item.id) {
                                        return {
                                          ...eachItem,
                                          specialNote: e.target.value,
                                        };
                                      } else {
                                        return eachItem;
                                      }
                                    }
                                  );
                                  this.setState({
                                    specialNoteData: dump,
                                  });
                                }}
                              />
                            </Col>
                          </Col>

                          <Col
                            className="checkOutProductImg margin_auto"
                            onClick={(e) =>
                              this.navigateToProductDetailPage(
                                item.productOrderLineItem.productId
                              )
                            }
                          >
                            <img
                              src={
                                item.productOrderLineItem &&
                                item.productOrderLineItem
                                  .mapProductFileUploadedDatas[0] &&
                                item.productOrderLineItem
                                  .mapProductFileUploadedDatas[0]
                                  .fileUploadedData.url
                              }
                              alt="my image"
                            />
                          </Col>
                        </Row>
                        <div className={""}>
                          <div className={"flexContainer darkBottom_border"}>
                            <div
                              className={
                                "halfDiv contentInCenter height50Px pointer_Cursor"
                              }
                              onClick={() => {
                                this.removeOrderItem({
                                  cartId: cartData.shoppingCartToken,
                                  orderId: item.id,
                                });
                              }}
                              style={{
                                textAlign: "center",
                                color: "#929da6",
                                borderRight: "1px solid #929da6",
                                fontSize: "12px",
                              }}
                            >
                              <span>{t("Remove")}</span>{" "}
                            </div>

                            <div
                              className="halfDiv contentInCenter height50Px pointer_Cursor"
                              style={{ textAlign: "center" }}
                            >
                              {item.productOrderLineItem.isAvailable ? <div
                                className="contentInCenter"
                                style={{ width: "100%", height: "100%" }}
                                onClick={() => {
                                  this.setState({ orderLineItem: item }, () => {
                                    this.showDrawer();
                                  });
                                }}>
                                <span style={{ fontSize: "12px" }}>
                                  {t("Qty")} : {item.quantity}
                                </span>
                                <span className="centerIcon">
                                  <CaretDownOutlined
                                    style={{ fontSize: "11px" }}
                                  />
                                </span>
                              </div> : <div className="priceColor font14px">
                                This item is not available
                              </div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className={"background_div"}></div>
            </Col>

            <div className={"p-3 stickToBottom boxShadow height80px"}>
              <div className={"flexContainer"}>
                <div className={"halfDiv"}>
                  <div className={"priceColor"}>
                    ₹ <b>{cartData.totalPrice + cartData.shippingCharges}</b>
                  </div>
                  {/* <div
                    className={"textcolor font10px"}
                  >{`(₹ ${cartData.priceWithoutGst} + ₹ ${cartData.gstAmount} GST)`}</div> */}
                  <div className={"textcolor font10px"}>
                    + ₹ {cartData.shippingCharges} {t("Delivery Charges")}
                  </div>
                </div>
                <div className={"halfDiv checkOutPaymentButton"}>
                  {showPaymentBtn ? <Button
                    size={"large"}
                    className={"manageBtn"}
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.navigateToPaymentPage();
                    }}
                  >
                    {t("Select payment")}
                  </Button> :
                    <div className="font14px" style={{
                      color: 'white',
                      background: 'gray',
                      padding: '10px',
                      borderRadius: '4px',
                      maxWidth: '350px',
                      textAlign: 'center'
                    }}>
                      Some items in your cart are not available, Please remove unavailable items
                    </div>}
                </div>
              </div>
            </div>
          </Row>
        ) : (
          <div className="no_data_div mt-5">
            <h6>{t("Your Akar Cart is empty")}</h6>
          </div>
        )
        }

        <Drawer
          title={t("Change GST number")}
          placement={"bottom"}
          onClose={this.onClose}
          visible={gstDrawerVisible}
          maskClosable={true}
          footer={gstFooter}
          height={"100vh"}
          key={"2"}
        >
          <div className={"mt-4 pl-3"}>
            <Button
              type={"primary"}
              onClick={() => {
                this.props.history.push("/account-profile");
              }}
            >
              {t("Add new GST number")}
            </Button>
          </div>
          <div className={"qtyDrawer"}>
            {gstData && gstData.length ? (
              gstData.map((gstItem, index) => {
                return (
                  <div className={"mt-4"} key={`${index}`}>
                    <Row>
                      <Col md={1}>
                        <Radio.Group
                          value={this.state.gstIndex}
                          onChange={(e) => {
                            this.setState({
                              choosedGST: gstData[index],
                              gstIndex: e.target.value,
                            });
                          }}
                        >
                          <Radio value={index} />
                        </Radio.Group>
                      </Col>
                      <Col md={23}>
                        <p className={"label"}>{gstItem.number}</p>
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <p style={{ padding: "16px" }}>
                {" "}
                {t("Please provide your GST details first")}
              </p>
            )}
          </div>
        </Drawer>

        <Drawer
          title={t("Change or add delivery address")}
          placement={"bottom"}
          onClose={this.onClose}
          visible={addressDrawerVisible}
          maskClosable={true}
          footer={addressFooter}
          height={"100vh"}
          key={"2"}
        >
          <div className={"qtyDrawer"}>
            <div className={"mt-4"}>
              <Button
                type={"primary"}
                onClick={() => {
                  this.setState({ visibleAddAddressDrawer: true }, () => {
                    this.getStates();
                  });
                }}
              >
                {t("Add new address")}
              </Button>
            </div>

            {addressData &&
              addressData.length > 0 &&
              addressData.map((addItem, index) => {
                return (
                  <div className={"mt-4"} key={`${index}`}>
                    <Row>
                      <Col md={1}>
                        <Radio.Group
                          value={this.state.addressIndex}
                          onChange={(e) => {
                            this.setState({
                              choosedAddress: addressData[index],
                              addressIndex: e.target.value,
                            });
                          }}
                        >
                          <Radio value={index} />
                        </Radio.Group>
                      </Col>
                      <Col md={23}>
                        <h2>{addItem.label}</h2>
                        <p className={"label"}>{addItem.addressLine1}</p>
                        <p className={"label"}>{addItem.addressLine2}</p>
                        <p className={"label"}>{addItem.city}</p>
                        <p className={"label"}>{addItem.stateName}</p>
                        <p className={"label"}>{addItem.zipCode}</p>
                        <p className={"label"}>{addItem.country}</p>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </div>
        </Drawer>

        <Drawer
          title={t("Add new delivery address")}
          placement={"bottom"}
          onClose={this.closeDrawer}
          visible={visibleAddAddressDrawer}
          maskClosable={false}
          footer={null}
          height={"80vh"}
          key={"3"}
        >
          <div className={"addressDrawer"}>
            <Form
              {...layout}
              onFinish={this.onFinish}
              ref={this.formRef}
              scrollToFirstError
              name="horizontal_address"
              layout="vertical"
            >
              <Row>
                <Col span={24}>

                  <Form.Item label={`${t('Address label')}*`}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `${t('Address label reqd validation')}`
                      }
                    ]} name="label"
                    style={{ marginBottom: `${showLabelInput ? '5px' : '24px'}` }}>

                    <Select placeholder='Please select the label' onChange={(val) => this.checkLabelValue(val)}>
                      <Option value="Home">Home</Option>
                      <Option value="Office">Office</Option>
                      <Option value="Other">Other</Option>
                    </Select>

                  </Form.Item>

                  {showLabelInput ?
                    <div className="ant-col ant-col-16 ant-col-offset-4 ">
                      <Input style={{ marginBottom: '24px' }} maxLength={80} defaultValue={labelValue} placeholder={t('Home_office')}
                        onChange={(e) => this.setState({ labelValue: e.target.value })} />
                    </div>
                    : null

                  }

                </Col>

                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `${t("Address line 1 reqd validation")}`,
                      },
                      {
                        pattern: new RegExp(
                          /^([a-zA-Z\-_/,. ]*)+\d+[a-zA-Z0-9\-_/,. ]*/g
                        ),
                        required: true,
                        message: `${t(
                          "Address Line 1 combination validation"
                        )}`,
                      },
                      {
                        min: 10,
                        message: `${t("Address Line 1 length10 validation")}`,
                      },
                      {
                        max: 50,
                        message: `${t("Address Line 1 length50 validation")}`,
                      }
                    ]}
                    validateTrigger="onBlur"
                    name="addressLine1"
                    label={`${t("Address line 1")}*`}
                  >
                    <Tooltip title={t("Address line 1 tooltip msg")}>
                      <Input
                        placeholder={t("Building No_colony")}
                        onChange={(e) => {
                          this.formRef.current.setFieldsValue({
                            addressLine1: e.target.value,
                          });
                        }}
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `${t("Address line 2 reqd validation")}`,
                      },
                      {
                        min: 10,
                        message: `${t("Address Line 2 length10 validation")}`,
                      },
                      {
                        max: 50,
                        message: `${t("Address Line 2 length50 validation")}`,
                      }
                    ]}
                    validateTrigger="onBlur"
                    name="addressLine2"
                    label={`${t("Address line 2")}*`}
                  >
                    <Input placeholder={t("Area_District")} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `${t("City reqd validation")}`,
                      },
                    ]}
                    name="city"
                    label={`${t("City")}*`}
                  >
                    <Input placeholder={t("City")} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="stateId"
                    label={`${t("State")}*`}
                    rules={[
                      {
                        required: true,
                        message: `${t("State reqd validation")}`,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={t("Select state")}
                    >
                      {states &&
                        states.map((item, skey) => {
                          return (
                            <Option key={`${skey}`} value={item.id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="zipCode"
                    label={`${t("PinCode")}*`}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: `${t("Pincode reqd validation")}`,
                      },

                      {
                        len: 6,
                        message: `${t("Invalid length pincode")}`,
                      },
                    ]}
                  >
                    <Input type="number" placeholder={t("6 digit pincode")} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item>
                    {isloaderVisible ? (
                      <LoaderComponent />
                    ) : (
                      <Button className="my-button" htmlType="submit">
                        {t("Save")}
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Drawer>

        <Drawer
          title={orderLineItem && orderLineItem.productOrderLineItem.name}
          placement={"bottom"}
          onClose={this.onClose}
          visible={visible}
          maskClosable={true}
          footer={footer}
          height={300}
          key={"1"}
        >
          <div className={"qtyDrawer"}>
            <div className={"qty_content"}>
              <div className={"textdiv"}>
                <div className={"textcolor"}>
                  {t("Color")}: <b>Multi Color</b> {t("Size")}:{" "}
                  <b>M,L,XL,2XL</b>
                </div>
                <div className={"priceColor"}>
                  ₹ <b>{orderLineItem && orderLineItem.pricePerSet}</b> per set
                </div>
                <div className={"textcolor"}>{`(${orderLineItem &&
                  orderLineItem.productOrderLineItem.piecePerSet
                  } pcs per set)`}</div>
              </div>

              <div className={"counter"}>
                <div>
                  <button
                    disabled={hideLessBtn}
                    onClick={(e) => this.decreaseQty()}
                  >
                    {" "}
                    -{" "}
                  </button>
                </div>
                <div>{orderLineItem && orderLineItem.quantity}</div>
                <div>
                  <button onClick={(e) => this.increaseQty()}> + </button>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </LayoutComponent >
    );
  }
}

const mapStateToProps = ({ global }) => ({
  loading: global.buttonLoading,
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(OrderFormsCheckOutComponent))
);
