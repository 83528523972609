import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col, Tabs, Input, Modal, Card, message, Skeleton, Button } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import CartService from '../../service/cart';
import MediaUrl from '../../service/mediaUrl'
import { OrderStatus } from '../../service/statusTypes'
import myImage from '../../imageNotFound.jpeg';
import './index.scss'
import { withTranslation } from 'react-i18next';
const { TabPane } = Tabs;
const { Search } = Input

const filterValue = { all: "All", sold: 'Sold', purchase: 'Purchased' }

class OrdersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            status: '',
            searchStr: '',
            filter: 'all',
            isBankAccountVerified: false
        }
    }

    ordersList = async () => {
        let { status, searchStr, filter } = this.state
        const values = queryString.parse(this.props.location.search)
        let obj = { filter, searchStr, limit: 100, pageNumber: 1 }

        if (status !== 'All' || status === '') {
            if (values.status === 'PickupRescheduled' || status === 'PickupRescheduled') {
                obj.status = 'PickupError'
            } else {
                obj.status = values && values.status ? values.status : status
            }
        }

        if (status === 'RTO') {
            obj.status = 'RTOInitiated&status=RTOInTransit&status=RTODelivered'
        }

        if (status === 'Cancelled') {
            obj.status = "Cancelled"
        }

        if (status === 'Shipped') {
            obj.status = "Shipped&status=PickedUp"
        }

        let x = await CartService.getAllOrdersList(obj)
        this.setState({ isBankAccountVerified: this.props.currentUser.account.isBankDetailsExist })

        if (x.data.isSuccess) {
            let { data } = x.data

            data.orders.forEach(item => {
                if (item.productImageUrl) {
                    let url = MediaUrl.completeMediaUrl(item.productImageUrl, true, 100, 100)
                    item.productImageUrl = url
                }
            })

            this.setState({ ordersData: data.orders })

        } else {
            message.error('Error in getting data')
        }

    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        if (values && values.status) {
            this.setState({ status: values.status }, () => {
                this.ordersList()
            })
        } else {
            this.setState({ status: 'All' }, () => {
                this.ordersList()
            })
        }
    }

    showModal = () => {
        this.setState({ visible: true })
    }

    closeModal = () => {
        this.setState({ visible: false })
    }

    callback = (key) => {
        switch (key) {
            case 'All':
                this.props.history.push({ pathname: '/your-orders' })
                return this.setState({ status: 'All', ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'Pending':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Pending, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'Cancelled':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Cancelled, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'Shipped':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Shipped, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'Hold':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Hold, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'RTO':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: 'RTO', ordersData: [] }, () => {
                    this.ordersList()
                })
            // case 'RTOBySeller':
            //     this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
            //     return this.setState({ status: OrderStatus.RTOBySeller, ordersData: [] }, () => {
            //         this.ordersList()
            //     })
            case 'Expired':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Expired, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'Delivered':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Delivered, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'CourierReturn':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.CourierReturn, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'Partial':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Partial, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'Failed':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Failed, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'Placed':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.Placed, ordersData: [] }, () => {
                    this.ordersList()
                })
            case 'ReadyToShip':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.ReadyToShip, ordersData: [] }, () => {
                    this.ordersList()
                })
            // case 'CancelledByVendor':
            //     this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
            //     return this.setState({ status: OrderStatus.CancelledByVendor, ordersData: [] }, () => {
            //         this.ordersList()
            //     })
            case 'PickupRescheduled':
                this.props.history.push({ pathname: '/your-orders', search: `?status=${key}` })
                return this.setState({ status: OrderStatus.PickupRescheduled, ordersData: [] }, () => {
                    this.ordersList()
                })
            default:
                return
        }
    }

    renderOrderStatus = (status) => {
        const { t } = this.props;
        if (status === 'CourierReturn') { return `${t('Courier Returned')}` }
        else if (status === 'ReadyToShip') { return `${t('Ready To Ship')}` }
        else if (status === 'CancelledByVendor') { return `${t('Cancelled By Vendor')}` }
        else if (status === 'CancelledByAkar') { return `${t('Cancelled By Akar')}` }
        else if (status === 'PickupError') { return `${t('Pickup Rescheduled')}` }
        else if (status === 'RTOInitiated') { return `${t('RTO Initiated')}` }
        else if (status === 'RTOInTransit') { return `${t('RTO In Transit')}` }
        else if (status === 'RTODelivered') { return `${t('RTO Delivered')}` }
        else if (status === 'WaitingForApproval') { return `${t('Waiting For Approval')}`}
        else { return `${t(OrderStatus[status])}` }
    }

    render() {
        const { ordersData, visible, status, filter, isBankAccountVerified } = this.state
        const { currentUser, t } = this.props;
        const orders = <>{ordersData && ordersData.length > 0 ? ordersData.map((orderItem, index) => {
            return (<Row className='order-content-container' key={index}>
                <Col md={24} className='order-container'>
                    <div className='orderCard pointer_Cursor' onClick={() => { this.props.history.push({ pathname: `/order-details/${orderItem.orderId}` }) }}>
                        <div className={'my_order_image'}>
                            <img className={'product_Img'} src={orderItem.productImageUrl ? orderItem.productImageUrl : myImage} alt='banner' />
                        </div>
                        <div className={'right_div px-4'}>

                            <p className='order_title'>{t('Order By')} : <span className='seller_name'>{orderItem['buyerAccountName']}</span></p>

                            <p className='order_title'>{t('Sold By')} : <span className='seller_name'>{orderItem['sellerAccountName']}</span></p>
                            <div className='packing_status'>
                                {orderItem && orderItem.orderStatus && this.renderOrderStatus(orderItem.orderStatus)}
                                {(orderItem?.orderStatus === 'Cancelled' && orderItem?.cancelledBy === 1 ? ' by Seller' : '')}
                                {(orderItem?.orderStatus === 'Cancelled' && orderItem?.cancelledBy === 2 ? ' by Akar' : '')}
                                {(orderItem?.orderStatus === 'Cancelled' && orderItem?.cancelledBy === 0 ? ' by Buyer' : '')}


                            </div>
                            {
                                currentUser.accountId === orderItem.sellerAccountId
                                    ? <div className='amount'>₹ {orderItem && (orderItem.totalAmount - orderItem.shippingCharges).toFixed(2)}<span className={'rightIcon font16px'}><RightOutlined /></span></div>
                                    : <div className='amount'>₹ {orderItem.totalAmount}<span className={'rightIcon font16px'}><RightOutlined /></span></div>
                            }
                            <div className='order_title mt-2'>{t('Order Id')}: {orderItem ? orderItem.orderId : ''}</div>
                            <div className='order_title'>{t('Placed On')}: {moment.utc(orderItem.createdOn).utcOffset('+0530').format('lll')}</div>
                        </div>
                    </div>

                </Col>
            </Row>)
        }) : (ordersData && ordersData.length === 0 ? <Card className={'no_data_div'}>{t('No order exists for this category')}</Card> : <Skeleton active />)}</>

        return (
            <LayoutComponent title={t('Orders')}>
                <div className='divWithoutFixButton'>
                    <Row>
                        <Col span={24}>
                            <div className={'toolbar order-tab-bar flexContainer'}>
                                <div className={'flexRightDiv'}>
                                    <Search className='mySearch' size={'large'} bordered={false}
                                        onChange={e => {
                                            this.setState({ searchStr: e.target.value }, () => {
                                                this.ordersList()
                                            })
                                        }}
                                        placeholder={t('Search')} />
                                </div>
                                <div className={'orders'}>{t(`${filterValue[filter]}`)} <DownOutlined className={'down_icon'} onClick={this.showModal} /></div>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Tabs className='tabHeader'
                                activeKey={status}
                                size={'large'}
                                onChange={this.callback}>
                                <TabPane tab={t('ALL')} key="All">{orders}</TabPane>
                                <TabPane tab={t('PENDING')} key="Pending">{orders}</TabPane>
                                <TabPane tab={t('FAILED')} key="Failed">{orders}</TabPane>
                                <TabPane tab={t('CONFIRMED')} key="Placed">{orders}</TabPane>
                                <TabPane tab={t('HOLD')} key="Hold">{orders}</TabPane>
                                {isBankAccountVerified ? <TabPane tab={t('READY TO SHIP')}
                                    key="ReadyToShip">{orders}</TabPane> : null}
                                <TabPane tab={t('SHIPPED')} key="Shipped">{orders}</TabPane>
                                <TabPane tab={t('DELIVERED')} key="Delivered">{orders}</TabPane>
                                <TabPane tab={t('COURIER RETURNED')} key="CourierReturn">{orders}</TabPane>
                                <TabPane tab={t('CANCELLED')} key="Cancelled">{orders}</TabPane>
                                {/* <TabPane tab={t('CANCELLED BY VENDOR')} key="CancelledByVendor">{orders}</TabPane> */}
                                <TabPane tab={t('PICKUP RESCHEDULED')} key="PickupRescheduled">{orders}</TabPane>
                                <TabPane tab={t('RTO')} key="RTO">{orders}</TabPane>
                                {/* <TabPane tab={t('RTO By Seller')} key="RTOBySeller">{orders}</TabPane> */}
                                <TabPane tab={t('EXPIRED')} key="Expired">{orders}</TabPane>
                            </Tabs>
                        </Col>
                    </Row>

                </div>

                <Modal
                    visible={visible}
                    onCancel={this.closeModal}
                    footer={null}
                    width={1000}
                    centered={true}
                    maskClosable={true}
                >
                    <p className={'action pointer_Cursor'} onClick={() => {
                        this.setState({ filter: 'all' }, () => {
                            this.closeModal()
                            this.ordersList()
                        })
                    }}>{t('All')}</p>
                    <p className={'action pointer_Cursor'} onClick={() => {
                        this.setState({ filter: 'sold' }, () => {
                            this.closeModal()
                            this.ordersList()
                        })
                    }}>{t('Sold')}</p>
                    <p className={'action pointer_Cursor'} onClick={() => {
                        this.setState({ filter: 'purchase' }, () => {
                            this.closeModal()
                            this.ordersList()
                        })
                    }}>{t('Purchased')}</p>
                </Modal>
            </LayoutComponent>
        )
    }
}

const mapStateToProps = ({ global }) => ({
    currentUser: global.currentUser,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdersComponent)));

