const transEN = {
  //login screen
  'Enter mobile number': 'Enter Mobile Number',
  'Mobile description': 'Enter your 10-digit mobile number to receive the verification code.',
  'I accept': 'I accept',
  'Terms and conditions': 'Terms and Conditions',
  'Request otp': 'Request OTP',
  "Mobile number": "Mobile Number",
  "Mobile No validation": "Please input your mobile number!",
  'Invalid mobile': 'Invalid mobile number!',

  //otp screen
  'Verify mobile no': 'Verify Mobile Number',
  'Enter 4 digit code': 'Enter your 4-digit verification code sent on',
  'To proceed': 'to proceed',
  'login': 'Login',
  'Enter otp': 'Enter OTP',
  'Otp validation': 'Please enter your OTP!',
  'Invalid otp': 'Invalid OTP !',
  'Enter business name': 'Enter business name',
  'Attempts left': 'Attempts Left',
  'Resend otp': 'Resend OTP',
  'Buisname name validation': 'Please enter your business name!',
  "second": 'sec',
  'Resend otp in': 'Resend OTP in',

  //bank details screen
  'Enter bank account info': 'Enter Bank Account Information',
  'Submit': 'Submit',

  //kyc screen
  'KYC': 'KYC',
  'Document type': 'Document Type',
  'GST': 'GST',
  'Select document type': 'Select Document Type',
  'Aadhaar': 'Aadhaar',
  'FSSAI': 'FSSAI',
  'Trademark': 'Trademark',
  'Municipal Trade License': 'Municipal Trade License',
  'PAN + AddressProof': 'PAN + AddressProof',
  'GST address': 'GST Address',
  'Select GST address': 'Select GST Address',
  'Note': 'Note',
  'Please upload GST documents corresponding to Gst Number': 'Please upload GST documents corresponding to Gst Number',
  'Add Documents for AddressProof': 'Add Documents for AddressProof',
  'Add Documents for PAN': 'Add Documents for PAN',
  'Add Documents for Aadhaar': 'Add Documents for Aadhaar',
  "Add Documents for FSSAI": "Add Documents for FSSAI",
  "Add Documents for Trademark": "Add Documents for Trademark",
  "Add Documents for Municipal Trade License": "Add Documents for Municipal Trade License",
  "Add Documents for GST": "Add Documents for GST",

  //LOGIN flow modal
  "Thankyou": "Thankyou",
  "KYC Details Submitted": "KYC Details Submitted.",
  "You KYC will be approved shortly - para": "You KYC will be approved shortly by team Akar after verifying submitted documents.",
  "Ok": "Ok",

  // terms of service screen
  'I agree akar rights': 'I agree that akar reserves the right, at its discretion, to amend, add, or remove portions of this declaration at any time.',

  //Drawer menu
  'Sign in': 'Sign In',
  'Logout': 'Logout',
  'Signout': 'Signout',
  'Support': 'Support',
  'FAQs': 'FAQs',
  'About Akar': 'About Akar',
  'Information': 'Information',
  'Your brands': 'Your Brands',
  'Your listings': 'Your Listings',
  'Business settings': 'Business Settings',
  'Profile_Settings': 'Profile & Settings',
  'Account management': 'Account Management',
  'Complete your kyc': 'Complete Your KYC',
  'Finance': 'Finance',
  'Disputes': 'Disputes',
  'Your returns': 'Your Returns',
  'Your orders': 'Your Orders',
  'Alerts': 'Alerts',
  'Sell on Akar': 'Sell On Akar',
  "Change Language": "Change Language",

  //homepage
  'Change': 'Change',
  'Select': 'Select',
  'Selected': 'Selected',
  "View All": "View All",

  //product detail page
  'Add sets': 'Add sets to proceed.',
  'Buy now': 'Buy Now',
  'Add to order form': 'Add to Order Form',
  'Tax': 'Tax',
  'Total qty': 'Total Qty',
  'View details': 'View Details',
  'KYC verification is under process': 'KYC verification is under process',
  'Complete kyc': 'Complete KYC',
  'Edit listing': 'Edit Listing',
  'Available': 'Available',
  'Unavailable': 'Unavailable',
  'Product expired': 'This Product is expired',
  'Reason of rejection': 'Reason of Rejection',
  'Specifications': 'Specifications',
  'Description': 'Description',
  'Tags': 'Tags',
  'Return policy': 'Return Policy',
  'Buying options': 'Buying Options',
  'Color': 'Color',
  'Size': 'Size',
  'Similar products': 'Similar products',
  'Qty': 'Qty',
  'Price': 'Price',
  "Market": "Market",
  "per pc": "per pc",
  "Min. qty": "Min. qty",
  "set": "set",
  "pcs": "pcs",
  "Retail Margin": "Retail Margin",
  "per set": "per set",
  "Fast and Easy returns with in 36 hours hours of delivery": "Fast and Easy returns with in 36 hours hours of delivery.",

  //account page
  'Your profile': 'Your Profile',
  'Modify your profile': 'Modify Your Profile',
  'Business profile': 'Business Profile',
  'Modify your business profile': 'Modify Your Business Profile',
  'Account settings': 'Account Settings',
  'Your team': 'Your Team',
  'Manage people in your team': 'Manage people in your team',
  'Address, Bank Details etc': 'Address, Bank Details etc',
  'Account': 'Account',

  // your profile page
  'Name': 'Name',
  'Mobile': 'Mobile',
  'Email': 'Email',
  'Update details': 'Update Details',
  'Kyc status': 'Kyc Status',
  'Apply for KYC': 'Apply for KYC',
  'Re-Apply for KYC': 'Re-Apply for KYC',
  'Edit user details': 'Edit User Details',
  'Save': 'Save',
  'Cancel': 'Cancel',
  'email reqd validation': 'Please enter your Email!',
  'Invalid email': 'The input is not valid E-mail!',
  'Name reqd validation': 'Please enter your Name!',
  'User account setting': "User Account Setting",

  //buisness profile page
  'Edit': 'Edit',
  'Your business name': 'Your Business Name',
  'Establishment year': 'Establishment Year',
  'Business type': 'Business Type',
  'PAN number': 'PAN Number',
  'Edit business details': 'Edit Business Details',
  'Business name': 'Business Name',
  'Description reqd validation': 'Please enter some description!',
  'Pan reqd validation': 'Please enter PAN Number!',
  'Incorrect Pan': 'PAN Number must be a combination of alphabets and numbers only!',
  'Select business type': 'Select business type',
  'Buisness type reqd validation': 'Please select your Business Type!',
  'Establishment year reqd validation': 'Please enter Establishment Year!',
  'Select establishment year': 'Select Establishment Year',
  'Business name reqd validation': 'Please enter Business name!',
  'Business details': 'Business Details',

  //manage team page
  'Manage team': 'Manage Team',
  'Logout from all devices': 'Logout from all devices',
  'Members found': 'Members found',
  'Member found': 'Member found',
  'Invite to Team': 'Invite to Team',
  'Delete account': 'Delete Account ',
  'Remove': 'Remove',
  'Leave': 'Leave',
  'Invite': 'Invite',
  'Confirm': 'Confirm',
  'Are you sure you want to delete this Account': 'Are you sure you want to delete this Account?',
  'Please enter the Mobile Number you want to invite to your team': 'Please enter the Mobile Number you want to invite to your team',
  'Mobile number must contain 10 digits validation': 'Mobile Number must contain 10 digits',
  'Enter 10 digit mobile number': 'Enter 10 digit Mobile Number',

  //Account settings page
  'My vacation': 'My Vacation',
  'On vacation': 'On Vacation',
  'Minimum order value': 'Minimum order value',
  'What minimum order value': `What's Minimum Order Value`,
  'Min price message': `Buyers wan't be able to place an order less than the minimum amount decided by you.`,
  'My addresses': 'My Addresses',
  'Add': 'Add',
  'You cannot add more than 10 Address details': 'You cannot add more than 10 Address details!',
  'Set default': 'Set Default',
  'Default address': 'Default Address',
  'No address Found': 'No address Found',
  'Bank details': 'Bank Details',
  'You cannot add more than 10 Bank accounts': 'You cannot add more than 10 Bank accounts!',
  'Bank name': 'Bank Name',
  'Default account': 'Default Account',
  'Holder name': `Holder's Name`,
  'Account number': 'Account Number',
  'IFSC code': 'IFSC Code',
  'Set as Inactive': 'Set as Inactive',
  'Set as Active': 'Set as Active',
  'Active': 'Active',
  'Inactive': 'Inactive',
  'Verify account now': 'Verify Account Now',
  'Bank verified': 'Bank Verified',
  'No bank details found': 'No Bank Details Found',
  'GST details': 'GST Details',
  'You cannot add more than 10 GST accounts': 'You cannot add more than 10 GST accounts!',
  'GST name': 'GST Name',
  'GST number': 'GST Number',
  'Address': 'Address',
  'Default GST Number': 'Default GST Number',
  'No GST Details Found': 'No GST Details Found',
  'Verify bank details': 'Verify Bank Details',
  'Verify': 'Verify',
  'Skip': 'Skip',
  'Add bank details': 'Add Bank Details',
  'Resend amount': 'Resend Amount',
  'Please enter debited amount reqd validation': 'Please Enter Debited Amount!',
  'Account holder name': 'Account Holder Name',
  'Account holder name reqd validation': 'Please input account holder name!',
  'Username must be minimum 4 characters': 'Username must be minimum 4 characters.',
  'Username must not exceed 50 characters': 'Username must not exceed 50 characters.',
  'IFSC Code must be 11-digit': 'IFSC Code must be 11-digit!',
  'IFSC code reqd validation': 'Please input your IFSC Code!',
  'Account no reqd validation': 'Please input your Account Number !',
  'Re-enter account no reqd validation': 'Please re enter your Account Number!',
  'Re enter account number': 'Re Enter Account Number',
  'Add GST details': 'Add GST Details',
  'Choose address': 'Choose Address',
  'Choose address reqd validation': 'Please choose Address!',
  'Gst name reqd validation': 'Please input your GST Name',
  'GST Name must contain alphabets only': 'GST Name must contain alphabets only!',
  'GST Number reqd validation': 'Please input your GST Number!',
  'GST Number must be a combination of alphabets and numbers only': 'GST Number must be a combination of alphabets and numbers only!',
  'Add minimum order value': 'Add Minimum Order Value',
  'Enter minimum order value': 'Enter minimum order value',
  'Minimum order value reqd validation': 'Please enter minimum order value!',
  'Edit address details': 'Edit Address Details',
  'Add new address': 'Add New Address',
  'Address label reqd validation': 'Please input label like Home, Office etc.!',
  'Address label': 'Address Label',
  'Home_office': 'Home, Office',
  'Building No_colony': 'Building No, Colony...',
  'Address line 1': 'Address Line 1',
  'Address line 1 tooltip msg': 'Address Line 1 must be a combination of digits, alphabets and greater than characters of length 10.',
  'Address line 1 reqd validation': 'Please input your address line 1.',
  'Address Line 1 combination validation': 'Address Line 1 must be a combination of digits and alphabets.',
  'Address Line 1 length10 validation': 'Address Line 1 must be greater than 10 characters.',
  'Address Line 1 length50 validation': 'Address Line 1 must be less than 50 characters.',
  'Address Line 2 length10 validation': 'Address Line 2 must be greater than 10 characters.',
  'Address Line 2 length50 validation': 'Address Line 2 must be less than 50 characters.',
  'Address line 2': 'Address Line 2',
  'Address line 2 reqd validation': 'Please input your address line 2.',
  'Address Line 2 combination validation': 'Address Line 2 must be a combination of digits and alphabets.',
  'Area_District': 'Area, District',
  'City': 'City',
  'State': 'State',
  'State reqd validation': 'Please select state.',
  'City reqd validation': 'Please input your city.',
  'Select state': 'Select state',
  'PinCode': 'PinCode',
  '6 digit pincode': '6 digit pincode',
  'Invalid length pincode': 'Please enter a valid Pincode.',
  'Pincode reqd validation': 'Please enter your Pincode.',
  'Update': 'Update',

  // product listings page
  'Pending': 'Pending',
  'Approved': 'Approved',
  'Expired': 'Expired',
  'New listing': 'New Listing',
  'Items found': 'Items Found',
  'Product expired status': 'Product Expired',
  'Expires on': 'Expires on',
  'Load more': 'Load More',

  // Registration page
  'Enter account info': 'Enter Account Information',
  'Register your business on Akar': 'Register your business on Akar.',
  'Your name': 'Your Name',
  'Input name reqd validation': 'Please input your name.',
  'Your email': 'Your Email',
  'Role': 'Role',
  'Please select role': 'Please select role.',
  'Select role': 'Select Role',
  'Retailer': 'Retailer',
  'Wholesaler': 'Wholesaler',
  'Buyer': 'Buyer',
  'Seller': 'Seller',
  'Address details': 'Address Details',
  'Other details': 'Other Details',
  'Reference number': 'Reference Number',
  'Reference number tooltip msg': 'Reference Number must contains digits, alphabets, underscore and hypen.',
  'Enter reference number': 'Please enter your Reference Number.',
  'Sign up': 'Sign up',

  //order forms page
  'Order forms': 'Order Forms',
  'Sets': 'Sets',
  'Total amount': 'Total Amount',
  'Review And Order': 'Review And Order',
  'Your Akar basket is empty': 'Your Akar Basket is empty',

  //checkout page
  'Back': 'Back',
  'Done': 'Done',
  'Seller is not accepting orders at the moment': 'Seller is not accepting orders at the moment !',
  'DELIVER TO': 'DELIVER TO',
  'GSTIN': 'GSTIN',
  'No GST number found': 'No GST Number Found',
  'ITEMS': 'ITEMS',
  'Select payment': 'Select Payment',
  'Delivery charges': 'Delivery Charges',
  'Your Akar Cart is empty': 'Your Akar Cart is empty',
  'Change GST number': 'Change GST Number',
  'Add new GST number': 'Add New GST Number',
  'Please provide your GST details first': 'Please provide your GST details first',
  'Change or add delivery address': 'Change or Add Delivery Address',
  'Add new delivery address': 'Add New Delivery Address',

  //payment page
  'Payment': 'Payment',
  'To Pay': 'To Pay',
  'Order amount can change based on invoice': 'Order amount can change based on invoice',
  'GST Info': 'GST INFORMATION',
  'Invoice issued against GSTIN': 'Invoice issued against GSTIN',
  'Payment method': 'PAYMENT METHOD',
  'Unable to place order msg': 'Sorry! We are unable to place the order. (Shipment to provided pincode is not available ! )',
  'Make payment': 'Make Payment',
  'Proceed': 'Proceed',
  'Order place on cod msg': 'This order will be placed with Cash on Delivery.',
  'Sure to place order msg': 'Are you sure you want to place this order?',
  'Order cannot be returned': 'This order cannot be returned.',
  'Wallet': 'Wallet',
  'Net Banking': 'Net Banking',
  'UPI': 'UPI',
  'Debit/Credit Card': 'Debit/Credit Card',

  // category listing page
  'Select a Product Type': 'Select a Product Type',
  'Search for a product type': 'Search for a product type',
  'Or select a category': 'Or select a category',
  'Select a sub-category': 'Select a sub-category',

  //create list-- 5 steps sets page
  'Redirect': 'Redirect',
  'Oops some error occured': 'Oops! some error occured.',
  'Create listing 5 steps': 'Create listing in 5 steps by adding information below.',
  'Select brand': 'Select Brand',
  'Select a brand to create listing': 'Select a brand to create listing',
  'Add image': 'Add Image',
  'Add images for listing': 'Add all images for this listing',
  'Cover image': 'Cover Image',
  'Create Sets': 'Create Sets',
  'Create sets for buying options': 'Create sets for buying options',
  'Set': 'Set',
  'Compatible Device': 'Compatible Device',
  'Compatible Devices': 'Compatible Devices',
  'Colors': 'Colors',
  'Bulk Prices': 'Bulk Prices',
  'Product details': 'Product Details',
  'Select specifications for listing': 'Select specifications for this listing',
  'Additional details': 'Additional Details',
  'Edit Title,  GST etc': 'Edit Title,  GST etc',
  'Publish': 'Publish',

  //create sets page
  'Please select any': 'Please Select any',
  'Colors to proceed': 'colors to proceed.',
  'How many colors': 'How many colors this set has?',
  'Selected colors': 'Selected Colors',
  'Sizes': 'Sizes',
  'Select multiple sizes': 'Select mutiple sizes form any of the size option',
  'Selected sizes': 'Selected Sizes',
  'Size option': 'Size Option',
  'Images': 'Images',
  'Provide image for every color': 'Provide image for every color',
  'Please select color from color tab': 'Please select color from color tab',
  'Min set of order': 'Min. set of order',
  'Price per piece': 'Price per piece',
  'Enter price': 'Enter Price',
  'MRP per piece': 'MRP per piece',
  'Available quantity': 'Available Quantity',
  'SKU ID': 'SKU ID',
  'Bulk Price': 'Bulk Price',
  'Regular price': 'Regular price',
  'Excluding GST': 'Excluding GST',
  'Including GST':'Including GST',
  'Add more': 'Add More',
  'Please select all mandatory attributes': 'Please select all mandatory attributes',
  'Select Category': 'Select Category',
  'Add now': 'Add Now',
  'Set Price Per Piece and Min set of order first': 'Set Price Per Piece and Min set of order first.',
  'Save_Next': 'Save & Next',
  'Pricing calculator': 'Pricing Calculator',
  'Enter the amount without gst': `Enter the amount without GST you want to sell your product at. Akar's Algorithm will calculate the effective pricing itself.`,
  'Calculate': 'Calculate',
  'Price including Akar charges': 'Price including Akar charges',
  'Use this price': 'Use this price',
  'Price per piece that includes your profit': 'Price per piece that includes your profit but excludes GST',
  'Price you should use': 'Price you should use',
  'Select images': 'Select Images',
  'Select item': 'Select Item',
  'Device selected': 'device selected',

  // set content screen
  'Set contents': 'Set Contents',
  'Edit the quantities': 'Edit the quantities in this set',

  // product specifications listing page
  'Enter your option Here': 'Enter your option Here',
  'Add new option': 'Add new option?',
  'Custom options includes': 'Custom option allows alphabets, numbers, spaces, hyphens and underscores only !',
  'Clear all': 'Clear All',
  'Search': 'Search',

  //additional detail page
  'Listing title': 'Listing Title',
  'Please enter description': 'Please enter description',
  'GST rate': 'GST Rate',
  'Listing expiry': 'Listing Expiry',
  'None': 'None',
  'Video': 'Video',
  'Preview': 'Preview',
  'Attach': 'Attach',

  //add edit set screen
  'Create new set': 'Create New Set',
  'Copy': 'Copy',
  'Delete': 'Delete',
  'Set details': 'Set Details',
  'Quantity': 'Quantity',

  // apply to sell page
  'Become a seller': 'Become a Seller',
  'Goto account settings': 'Goto Account Settings',
  'GST details mandatory': 'GST Details Mandatory!',
  'Please provide GST details': 'Please provide GST Details in Account Settings section and come back to become a seller.',

  // brands listing page
  'Select listing brand': `Select Listing's Brand`,
  'You can create listing only of your registered brand': 'You can create listing only of your registered brand. Register your brand id if not done yet.',
  'My brands': 'MY BRANDS',
  'Search brand': 'Search Brand',
  'Brand rejected': 'Brand Rejected',
  'Add a new brand': 'Add a new brand',

  // authorise brand page
  'Brand': 'Brand',
  'Brand owner': 'Brand Owner',
  'Select your relationship with': 'Select your relationship with',
  'I am the manufacturer/Owner of this brand': 'I am the manufacturer/Owner of this brand',
  'Authorized Distributor/Reseller': 'Authorized Distributor/Reseller',
  'I am the Authorized Distributor': 'I am the Authorized Distributor or Reseller of this brand',
  'Wholesaler/Trader': 'Wholesaler/Trader',
  'I am the Third party Seller of this brand': 'I am the Third party Seller of this brand',

  //brand docs verification
  'Brand authorization certificate': 'Brand Authorization Certificate',
  'Trademark certificate': 'Trademark Certificate',
  'An Authorization Certificate issued by the Brand': 'An Authorization Certificate issued by the Brand',
  'Registered Trademark Certificate': 'Registered Trademark Certificate issued by the Government of India',
  'Trademark application': 'Trademark Application',
  'Application for registration of a Trademark': 'Application for registration of a Trademark',
  'Continue': 'Continue',

  //brand owner letterhead
  'Step': 'Step',
  'Download the Format': 'Download the Format',
  'Declaration printed on letterhead': 'Declaration printed on letterhead',
  'Upload signed and stamped Letterhead': 'Upload signed and stamped Letterhead',

  //brand owner verification
  'Upload any document as a proof': 'Upload any document as a proof',
  'Letterhead': 'Letterhead',

  // new brand page
  'Create brand': 'Create Brand',
  'Add logo for brand': 'Add logo image for your brand',
  'Upload': 'Upload',

  //terms and condition for brands
  'Details': 'Details',
  'Brand name': 'Brand Name',
  'Relationship': 'Relationship',
  'Terms_Conditions': 'Terms & Conditions',
  'By clicking I agree': 'By clicking I agree, I do hereby declare as follows',
  'I solemnly state that': `I solemnly state that the contents of this declaration are true to the best of my knowledge and belief and that it conceals nothing and that no part of it is false.`,

  //add new brand list page
  'Relation type': 'Relation Type',
  'Status': 'Status',
  'Brand description': 'Brand Description',
  'Reapply': 'Reapply',

  //orders page
  "Orders": "Orders",
  "Order": "Order",
  "All": "All",
  "Sold": "Sold",
  "Purchased": "Purchased",
  "ALL": "ALL",
  "PENDING": "PENDING",
  "FAILED": "FAILED",
  "CONFIRMED": "CONFIRMED",
  "HOLD": "HOLD",
  "READY TO SHIP": "READY TO SHIP",
  "SHIPPED": "SHIPPED",
  "DELIVERED": "DELIVERED",
  "COURIER RETURNED": "COURIER RETURNED",
  "PICKUP RESCHEDULED": "PICKUP RESCHEDULED",
  "CANCELLED": "CANCELLED",
  'Describe your business': 'Describe your business',
  'Refund Details': 'Refund Details',
  "CANCELLED BY VENDOR": "CANCELLED BY VENDOR",
  "EXPIRED": "EXPIRED",
  "Failed": "Failed",
  "Shipped": "Shipped",
  "Cancelled": "Cancelled",
  "Courier Returned": "Courier Returned",
  "Delivered": "Delivered",
  "Hold": "Hold",
  "Ready To Ship": "Ready To Ship",
  "Cancelled By Vendor": "Cancelled By Vendor",
  "Partial": "Partial",
  "Placed": "Placed",
  "Pickup Rescheduled": "Pickup Rescheduled",
  "No order exists for this category": "No order exists for this category",
  "Order By": "Order By",
  "Sold By": "Sold By",
  "Order Id": "Order Id",
  "Placed On": "Placed on",
  'RTO By Buyer': 'RTO By Buyer',
  'RTO By Seller': 'RTO By Seller',
  "Order Returned to origin by seller": "Order Returned to origin by seller",
  "Order Returned to origin by Buyer": "Order Returned to origin by Buyer",
  "Reason": "Reason",
  "Tracking Not Started Yet": "Tracking Not Started Yet",
  "Shipment Details": "Shipment Details",
  "Cost": "Cost",
  'COD not available due to too many returns': 'COD not available due to too many returns',
  "Order Amount (excluding - GST)": "ORDER AMOUNT (excluding - GST)",
  "Total GST": "TOTAL GST",
  "includes": "includes",
  "GST": "GST",
  "sets": "सेट",
  "days": "days",
  "AWB Number": "AWB Number",
  "Delivery By": "Delivery By",
  "View Tracking Details": "View Tracking Details",
  "Return Items": "Return Items",
  "Want to Return Items": "Want to Return Items",
  "View Return Policy": "View Return Policy",
  "Day": "Day",
  "Days": "Days",
  "Details": "Details",
  "Select Reason": "Select Reason",
  "Amount": "Amount",
  "with GST": "with GST",
  "Notice": "Notice",
  "Hold Order": "Hold Order",
  "Cancel Order": "Cancel Order",
  "Enter details like Model No, Design, etc.": 'Enter details like Model No, Design, etc.',
  "Select days to hold order for": "Select days to hold order for",
  "Cash On Delivery": "Cash On Delivery",
  "Prepaid": "Prepaid",
  "Your order has been picked and is in-transit": "Your order has been picked and is in-transit",
  "You put the order on hold for": "You put the order on hold for",
  "Your order is on hold for": "Your order is on hold for",
  "Order has been cancelled by Buyer": "Order has been cancelled by Buyer",
  "Order has been cancelled by Seller": "Order has been cancelled by Seller",
  "You have cancelled the order": "You have cancelled the order",
  "Your Order has been Failed": "Your order has been Failed",
  "Your Order has been Cancelled": "Your order has been Cancelled",
  "Your Order has been returned to seller": "Your order has been returned to seller",
  "Your Order has been Delivered": "Your order has been Delivered",
  "Your Order has been put on Hold": "Your order has been put on Hold",
  "Your Order is ready to shipped": "Your order is ready to shipped",
  "Your Order has been cancelled by seller": "Your Order has been cancelled by seller",
  "Order is in partial state": "Order is in partial state",
  "Please wait while we verify your payment. We will update the status real soon": "Please wait while we verify your payment. We will update the status real soon",
  "Order has been Placed sucessfully": "Order has been Placed sucessfully",
  "Order has been Expired": "Order has been Expired",
  "Pickup of order has been rescheduled": "Pickup of order has been rescheduled",
  "Invoice will be available once the order fulfilment has been completed": "Invoice will be available once the order fulfilment has been completed",
  "You can create a return for this order within 36 hrs of delivery": "You can create a return for this order within 36 hrs of delivery",
  "Buyer not reachable": "Buyer not reachable",
  "Buyer do not want to order": "Buyer do not want to order",
  "Stock unavailable": "Stock unavailable",
  "Duplicate order from buyer": "Duplicate order from buyer",
  "Combined with other order": "Combined with other order",
  "Incomplete information provided": "Incomplete information provided",
  "Non serviceable by logistics": "Non serviceable by logistics",
  "Other": "Other",
  "Order is arriving late": "Order is arriving late",
  "Product is not required anymore": "Product is not required anymore",
  "Any amount deducted would be refunded in 48 hours": "Any amount deducted would be refunded in 48 hours",
  "ORDER ID": "ORDER ID",
  "Ordered On": "ORDERED ON",
  "Total Amount": "TOTAL AMOUNT",
  "Buyer GST Number": "Buyer GST Number",
  "Seller GST Number": "Seller GST Number",
  "SUPPORT": "SUPPORT",
  "Shipping Charges": "SHIPPING CHARGES",
  "Delivery Address": "DELIVERY ADDRESS",
  "Payment Method": "PAYMENT METHOD",
  "Invoice Details": "INVOICE DETAILS",
  "Order Items": "ORDERED ITEMS",
  "Pack product": "Pack Product",
  "Choose Action": "Choose Action",
  "Error in fetching shipment details": "Error in fetching shipment details!",
  "Download Shipping Label": "Download Shipping Label",
  "Download Manifest url": "Download Manifest url",
  "Chat with Customer": "Chat with Customer",
  "Chat with Vendor": "Chat with Vendor",
  "Please Verify Bank details first from account settings in settings menu": "Please Verify Bank details first from account settings in settings menu",
  "Retry": "Retry",
  "Not Available": "Not Available",
  "Unable to fetch manifest at the moment": "Unable to fetch manifest at the moment.",
  "Shipping not started yet. Please try again after 30 minutes": "Shipping not started yet. Please try again after 30 minutes.",
  "Not Generated": "Not Generated",
  "reason": "reason",
  "days due to": "days due to",
  "View seller invoice": "View seller invoice",
  "View buyer invoice": "View buyer invoice",
  "View Invoice": "View Invoice",
  "Unable to fetch invoice at the moment": "Unable to fetch invoice at the moment",
  "Invoice will be available once the order is out for delivery": "Invoice will be available once the order is out for delivery.",
  "Order Details": "Order Details",
  "Order has been cancelled by You": "Order has been cancelled by You",

  // ship packet drawer
  "Price cannot be empty": "Price cannot be empty!",
  "Hsn code is required and can consist of numbers only": "Hsn code is required and can consist of numbers only!",
  "Gst cannot be empty": "Gst cannot be empty!",
  "Weight cannot be empty": "Weight cannot be empty!",
  "Invoice No cannot be empty": "Invoice No cannot be empty!",
  "Error in shipment": "Error in shipment!",
  "Update Shipment": "Update Shipment",
  "Package Details": "Package Details",
  "Units": "Units",
  "Price per set": "Price per set",
  "Gst Percentage": "Gst Percentage",
  "Enter GST": "Enter GST",
  "Enter Units": "Enter Units",
  "HSN Code": "HSN Code",
  "Box Details": "Box Details",
  "No of Boxes": "No of Boxes",
  "Total Weight": "Total Weight",
  "kg": "kg",
  "gm": "gm",
  "Select Weight": "Select Weight",
  "Above": "Above",
  "Invoice No": "Invoice No",
  "Add Boxes": "Add Boxes",
  "Invoice Number": "Invoice Number",
  "Akar currently Supports shipment of single box - para": "Akar currently Supports shipment of single box. To ship multiple boxes please contact Akar's customer support.",

  //hold order page
  "Hold Details": "",
  "No of days": "No of days",
  "Comment": "Comment",
  "Enter no of days": "Enter no of days!",
  "No of days should be less than 5": "No of days should be less than 5!",
  "Enter comment": "Enter comment",

  //Return listing and return detail page
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED",
  "Rejected": "Rejected",
  "Return Order Id": "Return Order Id",
  "Created On": "Created On",
  "Approved with Discount": "Approved with Discount",
  "Return Details": "Return Details",
  "Items": "Items",
  "Unit": "Unit",
  "Comments": "Comments",
  "Rejected Due To": "Rejected Due To",
  "No returns to show": "No returns to show",
  "Raise Dispute": "Raise Dispute",
  "Shipping_Charges": "Shipping Charges",
  "Buyer Invoice Number": "Buyer Invoice Number",
  "Seller Invoice Number": "Seller Invoice Number",
  "Approved amount": "Approved amount",
  "Pickup Details": "Pickup Details",
  "Including Gst": "including Gst",

  //Dispute page listing and detail
  "Attach file in png/jpg/jpeg/mp4/mov format": "Attach file in png/jpg/jpeg/mp4/mov format",
  "Please select reason": "Please select reason!",
  "Upload Media": "Upload Media",
  "Error in raising dispute": "Error in raising dispute!",
  "Dispute raised successfully": "Dispute raised successfully",
  "Including tax": "Including tax",
  "Add Image/Video": "Add Image/Video",
  "Mention Reason": "Mention Reason",
  "Wrong items sent": "Wrong items sent",
  "Items missing": "Items missing",
  "Items damaged": "Items damaged",
  "Dispute Id": "Dispute Id",
  "Dispute Date": "Dispute Date",
  "Accepted With Discount": "Accepted With Discount",
  "OPEN": "OPEN",
  "ACCEPTED": "ACCEPTED",
  "Open": "Open",
  "Accepted": "Accepted",
  "Your RTO Disputes": "Your RTO Disputes",
  "No dispute exists for this category": "No dispute exists for this category",
  "Error in fetching comments": "Error in fetching comments!",
  "Error in creating comment": "Error in creating comment!",
  "Dispute Details": "Dispute Details",
  "By Akar": "By Akar",
  "Reason For Dispute": "Reason For Dispute",
  "Dispute Media": "Dispute Media",
  "Items in Dispute": "Items in Dispute",
  "Add Comment": "Add Comment",

  //alerts page and ledger
  "No Alerts exists to show": "No Alerts exists to show",
  "Please provide valid time duration": "Please provide valid time duration !",
  "Results are already fetched for selected filters": 'Results are already fetched for selected filters !',
  "Please select time duration": "Please select time duration.",
  "No entries found": "No entries found.",
  "Start Date": "Start Date",
  "End Date": "End Date",
  "SEARCH": "SEARCH",
  "General Ledger": "General Ledger",
  "Total Credit": "Total Credit",
  "Total Debit": "Total Debit",

  //About us page
  "About Us": "About Us",
  "Para - About Us": "Akar is a Business-to-Business (B2B) ecommerce platform, designed to solve core trade problems for small, medium and large businesses across India. It is the largest such national distribution platform of its kind enabling retailers and businesses to source merchandise from manufacturers, brands, white labels, importers etc. on a single platform. Akar brings to users the power of technology to grow their business.",
  "Policy": "Policy",
  "Para - Policy": "You can easily register on AKAR India clothing websites and complete your KYC by submitting only few documents.We do not ask for any commission from our members. AKAR India offers customer friendly facilities such as Cash on Delivery, Order delivery at doorsteps, Easy Return, Payment Assurity, etc.",
  "REGISTRATION IS OPEN": "REGISTRATION IS OPEN",

  //FAQ Page
  'FAQ': 'FAQ',
  "Frequently Asked Questions (FAQs)": "Frequently Asked Questions (FAQs)",
  "FAQ - Q1": "Q1. In how many days you can request for return ?",
  "FAQ - A1": "Ans. You can raise return request within 3 days after the time of delivery.",
  "FAQ - Q2": "Q2. If your order is not delivered to you and returned to seller in any given situation, till when can you report the dispute?",
  "FAQ - A2": "Ans. You can raise dispute within the 36 hours after the time of delivery of RTO.",
  "FAQ - Q3": "Q3. If seller finds anything missing or damaged RTO delivered to him, till when can he report the dispute ?",
  "FAQ - A3": "Ans. Seller can raise dispute against the RTO within 36 hours of RTO delivery.",
  "FAQ - Q4": "Q4. Will sellers be charged for the returned orders ?",
  "FAQ - A4": "Ans. No sellers will not be charged any amount for using the platform and any other charges in such a case.",

  //footer
  "SOCIAL": "SOCIAL",
  "POLICY": "POLICY",
  "Privacy Policy": "Privacy Policy",
  "Return Shipment": "Return Shipment",
  "Facebook": "Facebook",
  "Instagram": "Instagram",
  "Contact Us": "Contact Us",
  "HELP": "HELP",

  //contact us page
  "In case you have problem": "In case you have problem with one of your order, Please reach out to ",
  "with details of your order": " with details of your order. Akar is a B2B marketplace aimed to bring traders, wholesalers, retailers and manufacturers across India on to a single online platform. If you need any help, you can reach to customer care at",
  "Also you can react out to us on our whatsapp": " Also you can react out to us on our whatsapp.",
  "Our office timings are monday to friday 10 am to 6 pm": " Our office timings are monday to friday 10 am to 6 pm.",
  "If you are a seller": "If you are a seller, Registrations are now open and you can register yourself as seller to list your products. If you want to know about more about how we operate, Please visit our terms and conditions page.",

  //privacy policy page
  "Last updated": "Last updated",
  "Privacy p1": "This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.",
  "Privacy p2": "We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. ",
  "Interpretation and Definitions": "Interpretation and Definitions",
  "Interpretation": "Interpretation",
  "Interpretation p1": "The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
  "Definitions": "Definitions",
  "For the purposes of this Privacy Policy": "For the purposes of this Privacy Policy:",
  "Account": "Account",
  "Account para": "means a unique account created for You to access our Service or parts of our Service.",
  "Affiliate": "Affiliate",
  "Affiliate para": "means an entity that controls, is controlled by or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.",
  "Application": "Application",
  "Application para": "means the software program provided by the Company downloaded by You on any electronic device, named AKAR",
  "Company": "Company",
  "Company para": "(referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement) refers to AKAR COMMERCE INDIA PRIVATE LIMITED , X-856 , STREET NO 10, CHAND MOHALLA , GANDHI NAGAR , DELHI 110031.",
  "Country": "Country",
  "Country para": "refers to: Delhi, India",
  "Device": "Device",
  "Device para": "means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
  "Personal Data": "Personal Data",
  "Personal Data para": "is any information that relates to an identified or identifiable individual.",
  "Service": "Service",
  "Service para": "refers to the Application.",
  "Service Provider": "Service Provider",
  "Service Provider para": "means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.",
  "Third-party Social Media Service": "Third-party Social Media Service",
  "Third-party Social Media Service para": "refers to any website or any social network website through which a User can log in or create an account to use the Service.",
  "Usage Data": "Usage Data",
  "Usage Data para": "refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).",
  "You": "You",
  "You para": "means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
  "Collecting and Using Your Personal Data": "Collecting and Using Your Personal Data",
  "Types of Data Collected": "Types of Data Collected",
  "Personal Data h": "While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:",
  "Email address": "Email address",
  "First name and last name": "First name and last name",
  "Phone number": "Phone number",
  "Address, State, Province, ZIP/Postal code, City": "Address, State, Province, ZIP/Postal code, City",
  "Usage Data para1": "Usage Data is collected automatically when using the Service.",
  "Usage Data para2": "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
  "Usage Data para3": "When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.",
  "Usage Data para4": "We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.",
  "Information from Third-Party Social Media Services": "Information from Third-Party Social Media Services",
  "Information from Third-Party Social Media Services h": "The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:",
  "Google": "Google",
  "Facebook": "Facebook",
  "Twitter": "Twitter",
  "Information from Third-Party Social Media Services para1": "If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.",
  "Information from Third-Party Social Media Services para2": "You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.",
  "Information Collected while Using the Application": "Information Collected while Using the Application",
  "Information Collected while Using the Application para1": "While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:",
  "Information regarding your location": "Information regarding your location",
  "Information from your Device's phone book (contacts list)": "Information from your Device's phone book (contacts list)",
  "Pictures and other information from your Device's camera and photo library": "Pictures and other information from your Device's camera and photo library",
  "Information Collected while Using the Application para2": "We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.",
  "Information Collected while Using the Application para3": "You can enable or disable access to this information at any time, through Your Device settings.",
  "Use of Your Personal Data": "Use of Your Personal Data",
  "Use of Your Personal Data h": "The Company may use Personal Data for the following purposes:",
  "To provide and maintain our Service": "To provide and maintain our Service,",
  "including to monitor the usage of our Service": " including to monitor the usage of our Service.",
  "To manage Your Account": "To manage Your Account: ",
  "To manage Your Account para": "to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.",
  "For the performance of a contract": "For the performance of a contract:",
  "For the performance of a contract para": "the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.",
  "To contact You": "To contact You: ",
  "To contact You para": "To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
  "To provide You": "To provide You",
  "To provide You para": "with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.",
  "To manage Your requests": "To manage Your requests:",
  "To manage Your requests para": "To attend and manage Your requests to Us.",
  "For business transfers": "For business transfers: ",
  "For business transfers para": "We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.",
  "For other purposes": "For other purposes: ",
  "For other purposes para": "We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.",
  "We may share Your personal information in the following situations": "We may share Your personal information in the following situations:",
  "With Service Providers": " With Service Providers:",
  "With Service Providers para": "We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.  ",
  "With Affiliates": "With Affiliates:",
  "With Affiliates para": "We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.",
  "For business transfers para_2": "We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
  "With business partners": " With business partners: ",
  "With business partners para": "We may share Your information with Our business partners to offer You certain products, services or promotions.",
  "With other users": "With other users:",
  "With other users para": "when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.",
  "With Your consent": "With Your consent: ",
  "With Your consent para": "We may disclose Your personal information for any other purpose with Your consent.",
  "Retention of Your Personal Data": "Retention of Your Personal Data",
  "Retention of Your Personal Data para1": "The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.",
  "Retention of Your Personal Data para2": "The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.",
  "Transfer of Your Personal Data": "Transfer of Your Personal Data",
  "Transfer of Your Personal Data para1": "Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
  "Transfer of Your Personal Data para2": "Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.",
  "Transfer of Your Personal Data para3": "The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.",
  "Disclosure of Your Personal Data": "Disclosure of Your Personal Data",
  "Business Transactions": "Business Transactions",
  "Business Transactions para": "If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.",
  "Law enforcement": "Law enforcement",
  "Law enforcement para": "Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).",
  "Other legal requirements": "Other legal requirements",
  "Other legal requirements h": "The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:",
  "Comply with a legal obligation": "Comply with a legal obligation",
  "Protect and defend the rights or property of the Company": "Protect and defend the rights or property of the Company",
  "Prevent or investigate possible wrongdoing in connection with the Service": "Prevent or investigate possible wrongdoing in connection with the Service",
  "Protect the personal safety of Users of the Service or the public": "Protect the personal safety of Users of the Service or the public",
  "Protect against legal liability": "Protect against legal liability",
  "Security of Your Personal Data": "Security of Your Personal Data",
  "Security of Your Personal Data h": "The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.",
  "Children's Privacy": "Children's Privacy",
  "Children's Privacy para1": "Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.",
  "Children's Privacy para2": "If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
  "Links to Other Websites": "Links to Other Websites",
  "Links to Other Websites para1": "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
  "Links to Other Websites para2": "We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.",
  "Changes to this Privacy Policy": "Changes to this Privacy Policy",
  "Changes to this Privacy Policy para1": "We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.",
  "Changes to this Privacy Policy para2": "We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the 'Last updated' date at the top of this Privacy Policy.",
  "Changes to this Privacy Policy para3": "You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
  "Contact Us para1": "If you have any questions about this Privacy Policy, You can ",
  "Contact Us para2": "Privacy Policy for AKAR",
  "Special Note": "Special Note",
  "Ship Package": 'Ship Package',

  // messages keys
  'Error in loading data': 'Error in loading data',
  'Data not found': 'Data not found',
  'No data found': 'No Data Found',
  'Something went wrong': 'Something went wrong',
  'Attach image in png/jpg/jpeg file format': 'Attach image in png/jpg/jpeg file format!',
  'Start typing': 'Start typing...',

  //account settings page---> error/sucess popups messages
  'Error in fetching gst details': 'Error in fetching gst details!',
  'AddressLine1 should contain alphabets also': 'AddressLine1 should contain alphabets also!',
  'Error in updation': 'Error in updation',
  'Error in saving': 'Error in saving',
  'Address added successfully': 'Address added successfully',
  'Entered amount is incorrect': 'Entered amount is incorrect',
  'Account number mismatch': 'Account Number Mismatch !',
  'Error in deleting address': 'Error in deleting Address',
  'Status updated successfully': 'Status updated successfully',
  'Error in deleting GST details': 'Error in deleting GST details!',
  'Enter valid minimum order value': 'Please enter a valid minimum order value!',
  'Error in fetching data': 'Error in fetching data',

  //manage team page
  'Removed member successfully': 'Removed member successfully',
  'User already exists': 'User already exists',
  'Invalid phone number': 'Invalid phone number',

  //kyc page
  'Attach file in png/jpg/jpeg/pdf format': 'Attach file in png/jpg/jpeg/pdf format!',
  'Documents files need to be less than 5': 'Documents files need to be less than 5',
  'Gst account added successfully': 'Gst account added successfully',

  //login page
  'Please accept Terms and services': 'Please accept Terms and services!',

  //otp page
  'Access denied': 'Access Denied!',

  //Registration page
  'Error in registering user': 'Error in registering user!',

  //brand docs verification page
  'Cannot upload more than 5 documents': 'Cannot upload more than 5 documents!',

  //brand owner letterhead page
  'Attach file in pdf format': 'Attach file in pdf format!',

  //chat screen
  'Video size limit validation': `Video size can't exceed from 20 MB`,
  'Attach video in mp4/mov file format': 'Attach video in mp4/mov file format',
  "Chat": "Chat",
  "Type a message": "Type a message",
  "Image": "Image",
  "Choose attachment type": "Choose attachment type",
  "Send": "Send",
  "No chats for this order": "No chats for this order.",
  "Today": "Today",

  //order forms page
  'Error in deletion': 'Error in deletion',
  'Item remove from cart': 'Item remove from cart',

  //checkout page
  'Kindly add your address': 'Kindly add your address!',
  'Kindly add your GST': 'Kindly add your GST!',
  'Items deleted from cart': 'Items deleted from cart',
  'Minimum order value should be greater than': 'Minimum order value should be greater than',

  //payment page
  'Error in redirection': 'Error in redirection',
  'Please select payment type': 'Please Select Payment Type',

  //product detail listing flow
  'Select value for': 'Select value for',

  //set content page
  'Set should have at least 1 quantity': 'Set should have at least 1 quantity.',

  //add/edit set page
  'Error in deleting set': 'Error in deleting set',
  'Product must contain atleast 1 set': 'Product must contain atleast 1 set!',
  'Product contain only 1 set': 'Product contain only 1 set. So, Cannot change status of set!',

  //create list page
  'Please fill all required data': 'Please fill all required data.',
  'Some error occured': 'Some error occured',
  'Product added successfully': 'Product added successfully',
  'Product updated successfully': 'Product updated successfully',

  //create sets page
  'Bulk': 'Bulk',
  'should be more than original': 'should be more than original',
  'Add unique': 'Add unique',
  'Invalid set': 'Invalid set',
  'New Bulk MOQ must be greater than previous MOQ': 'New Bulk MOQ must be greater than previous MOQ.',
  'Please select number of colors': 'Please select number of colors.',
  'Colors not Selected as per count': 'Colors not Selected as per count.',
  'Please select a size': 'Please select a size.',
  'Min sets to order is required': 'Min. sets to order is required.',
  'Price per piece is required': 'Price per piece is required.',
  'Price per piece cannot be greater than MRP per piece': 'Price per piece cannot be greater than MRP per piece.',
  'Available quantity must be greater than Min. set to order': 'Available quantity must be greater than Min. set to order.',
  'Bulk MOQ cannot be empty': 'Bulk MOQ cannot be empty.',
  'Bulk price per piece cannot be empty': 'Bulk price per piece cannot be empty.',
  "Order Placed Successfully!": "Order Placed Successfully!",
  "Go To Home": "Go To Home",
  "Track this Order": "Track this Order",
  "Payment Successfully Done!": "Payment Successfully Done!",
  //terms and condition page
  "The terms of use specify how para": "The terms of use specify how both seller and buyer or any person / party associated with the platform ‘AKAR’",
  "The buyer associated to the platform para": "The buyer associated to the platform can order the any of the products from the the listing mentioned. The buyer can by the product on cash on delivery or an prepaid basis (the payment option may depend upon the pincode of the customer ).The buyer may have to pay the delivery charges on each order he/she/they places. The delivery charges slabs are mentioned below -:",
  "Order amount < Rs.10,000": "Order amount < Rs.10,000/ ---- Delivery charges = Rs.99/-",
  "Order amount b/w Rs.10,000": "Order amount b/w Rs.10,000/ - Rs. 20,000/-------- delivery charges = Rs.159/-",
  "Order amount > Rs.20,000": "Order amount > Rs.20,000/ ---- Delivery charges = Rs.199/-",
  "The slabs mentioned above may change from  time to time": "The slabs mentioned above may change from  time to time .",
  "The order placed by the buyer para": "The order placed by the buyer is treated as a genuine order , and platform is not responsible for any illegal activity held by the buyer from the product he purchased from the platform ‘AKAR’. If any buyer found in such scenario he is solely responsible for the same, and he /she / they may also invites legal asking , or compensation / punishment mentioned in the constitution of india. ",
  "If the buyer , did not accept or returns para": "If the buyer , did not accept or returns more than 25% of the deliveries or orders , then the buyer may be blacklisted on the platform , and in such case he can only purchase on prepaid basis and COD option will be disabled for that buyer.",
  "Seller / buyer or any other person para": "Seller / buyer or any other person connected to platform ‘AKAR’ found in illegal / fraudlent activity on the platform is solely responsible for the same and can be punished under the law of state or law of india.",
  "The platform para": "The platform ‘AKAR’ may not onboard sellers or buyer from some of the pincodes , this is due logisitic limitation.",
  "Any products other than mentioned para": "Any products other than mentioned in invoice if against law and constitution of state or country ,been packed or sold by the seller then the seller is solely responsible . the app owner , team , or the shipping company will not be held responsible for any illegal product packed by the seller. Company can also sue and can ask for compensation from the seller.",
  "Any seller or buyer involved para": "Any seller or buyer involved in any fraudlent activity through the website or app portal causing financial loss or any other type of lossto the company or any other person or artificial person related to the ‘AKAR’ , will be liable to pay compensation decided by the company and Is also liable to accept any law suit which company can put on him.",
  "The seller have to pack para": "The seller have to pack the goods by standards of company and can only be packed by the fulfillment material available on the portal . the fulfillment material will be charged and is not complimentary.  ",
  "Product Listing Policy": "Product Listing Policy",
  "The product listed by the seller para": "The product listed by the seller must be original as stated , and one can not post any content or sell any item on ‘AKAR’ platform that is restricted or prohibited by the federal , state or local law of india.",
  "The product listed by seller must be genuine para": "The product listed by seller must be genuine and original as stated. Seller is solely responsile for the listing which he made. Platform Akar have all the rights to remove the listing which is inappropriate/wrong/or goes against the terms and condition of the company or violates local law of india.",
  "Seller can not duplicate the other brands para": "Seller can not duplicate the other brands product or articles , if done so seller is solely responsible for the same is punishable offence under the constitution of INDIA.",
  "Akar also prohibited some of the items para": "  Akar also prohibited some of the items , which may not be prohibited by the law , posting any content or listing any item below are specifically prohibited unless permitted in writing by AKAR.",
  "Giveaways, lotteries": "Giveaways, lotteries , raffles or contests",
  "Items that does not offer physical product para": " Items that does not offer physical product or service, such as digital currencies and advertisement solely for collecting users information.",
  "Aduly/pornographic materials": "Aduly/pornographic materials or items that are sexual in nature.",
  "Items that promotes illegal activities para": "Items that promotes illegal activities (eg. Synthetic urine for cheating drugs test, lock pick tool etc).",
  "Item that promotes hatred para": "Item that promotes hatred, violence, racial or religious intolerance.",
  "Stock, bonds, investments para": "Stock, bonds, investments interests and other securities.",
  "Akar reserves the right to impose para": "Akar reserves the right to impose additional restrictions and prohibitions.",
  "SOME OF THE SPECIFICALLY PROHIBITED": "SOME OF THE SPECIFICALLY PROHIBITED AND CONTROLLED ITEMS",
  "DRUGS AND DRUG PARAPHERNALIA": "DRUGS AND DRUG PARAPHERNALIA",
  "AKAR forbids any and all listing or sale of narcotics para": "AKAR forbids any and all listing or sale of narcotics, psychotropic drugs, natural drugs, synthetic drugs, steroids and other controlled substances (including all drugs listed in Drugs and Cosmetics Act, Narcotics or Psychopathic Substances Act or any other law applicable).",
  "Drug paraphernalia, including all items para": "Drug paraphernalia, including all items that are primarily intended or designed for use in manufacturing, concealing, or using a controlled substance.",
  "The listing or sale of packaging materials para": "The listing or sale of packaging materials which may be utilized to contain controlled substances, materials conducive to smuggling, storing, trafficking, transporting and manufacturing illicit drugs (e.g. marijuana grow lights), publications and other media providing information related to the production of illicit drugs.",
  "The listing or sale of orally administered para": "The listing or sale of orally administered or ingested sexual enhancement foods and supplements.",
  "EXPLOSIVE, HAZARDOUS CHEMICALS AND FIREARMS": "EXPLOSIVE, HAZARDOUS CHEMICALS AND FIREARMS",
  "The posting of explosives and related ignition and detonation equipment": "The posting of explosives and related ignition and detonation equipment.",
  "Radioactive substances, toxic and poisonous chemicals": "Radioactive substances, toxic and poisonous chemicals.",
  "The posting, offering for sale, or offering for purchase of hazardous or dangerous materials": "The posting, offering for sale, or offering for purchase of hazardous or dangerous materials.",
  "Ozone depleting substances are not permitted to be listed": "Ozone depleting substances are not permitted to be listed.",
  "Listing of fireworks, firecrackers para": "Listing of fireworks, firecrackers and associated products are forbidden. Where the User is a properly licensed to sell these products an exception can be made.",
  "Any service, instruction, process para": "Any service, instruction, process, or aid for producing any biological, chemical, or nuclear weapons, or other Weapons of Mass Destruction (WMD) or known associated agents.",
  "The posting of, offering for sale para": "The posting of, offering for sale, or offering for purchase of any arms, military ordnance, weapons (including explosive weapons), and/or any related parts and components (whether integral or otherwise).",
  "Posting, offering for sale, or offering of purchase of weapons para": " Posting, offering for sale, or offering of purchase of weapons that can incapacitate or cause serious physical harm to others (e.g. stun guns, batons, crossbows).",
  "While listing of most knives and other cutting para": "While listing of most knives and other cutting instruments is permitted, switchblade knives, gravity knifes, knuckledusters (bladed or not), bladed handheld devices, and disguised knives are prohibited.",
  "AKAR maintains discretion over what items para": "AKAR maintains discretion over what items are appropriate and may cause removal of a listing that it deems as a weapon.",
  "GOVERNMENT, LAW ENFORCEMENT AND MILITARY ISSUED ITEMS": "GOVERNMENT, LAW ENFORCEMENT AND MILITARY ISSUED ITEMS",
  "Following items are not permitted to be listed": "Following items are not permitted to be listed",
  "Articles of clothing or identification para": "Articles of clothing or identification that claim to be, or appear similar to, official government uniforms.",
  "Law enforcement badges or law enforcement equipment para": "Law enforcement badges or law enforcement equipment from any public authority, including badges issued by the government of any country.",
  "Military decorations, medals and awards para": "Military decorations, medals and awards, in addition to items with substantially similar designs.",
  "Police uniforms, police insignia and police vehicles": "Police uniforms, police insignia and police vehicles.",
  "There are some police items that may be listed on the AKAR, provided they observe the following guidelines": "There are some police items that may be listed on the AKAR, provided they observe the following guidelines:",
  "Authorized general souvenir items that do not resemble badges, and paperweights that do not contain badges": "Authorized general souvenir items that do not resemble badges, and paperweights that do not contain badges.",
  "Badges that are clearly not genuine or official": "Badges that are clearly not genuine or official.",
  "Historical badges that do not resemble modern para": "Historical badges that do not resemble modern law enforcement badges, provided that the item description clearly states that the badge is a historical piece.",
  "Any official, internal, classified or non-public documents": "Any official, internal, classified or non-public documents.",
  "Listing of police equipment and associated products": "Listing of police equipment and associated products",
  "ADULT AND OBSCENE MATERIALS": "ADULT AND OBSCENE MATERIALS",
  "The posting or sale of pornographic materials": "The posting or sale of pornographic materials.",
  "Items depicting or suggestive of bestiality para": "Items depicting or suggestive of bestiality, rape sex, incest or sex with graphic violence or degradation, and any items depicting or suggestive of sex involving minors, are strictly prohibited.",
  "CIRCUMVENTION DEVICES AND OTHER EQUIPMENT USED FOR ILLICIT PURPOSES": "CIRCUMVENTION DEVICES AND OTHER EQUIPMENT USED FOR ILLICIT PURPOSES",
  "Descramblers and other items that can be used para": "Descramblers and other items that can be used to gain unauthorized access to television programming (such as satellite and cable TV), internet access, telephone, data or other protected, restricted, or premium services.",
  "Devices designed to intentionally block para": "Devices designed to intentionally block, jam or interfere with authorized radio communications, such as cellular and personal communication services, police radar, global positioning systems (GPS) and wireless networking services (Wi-Fi).",
  "The listing or sale of spy equipment para": "The listing or sale of spy equipment and devices used for interception of wire, oral and electronic communications.",
  "Bank card readers and skimmers": "Bank card readers and “skimmers”.",
  "Any and all unauthorized circumvention para": "Any and all unauthorized circumvention devices not included in the above are also strictly prohibited.",
  "ILLEGAL SERVICES": "ILLEGAL SERVICES",
  "Listings claiming to provide government services and related products": "Listings claiming to provide government services and related products.",
  "The listing or sale of any form of invoices para": " The listing or sale of any form of invoices or receipts (including blank, pre-filled, or value added invoices or receipts).",
  "AKAR prohibits listings for the sole purpose of collecting user information or raising money": "AKAR prohibits listings for the sole purpose of collecting user information or raising money.",
  "COLLECTIONS, ARTIFACTS AND PRECIOUS METALS": "COLLECTIONS, ARTIFACTS AND PRECIOUS METALS",
  "AKAR strictly forbids the sale and purchase of currency para": "AKAR strictly forbids the sale and purchase of currency, coins, banknotes, stocks, bonds, money orders, credit and debit cards, investment interest, currency in digital or any intangible form (e.g. crypto-currency), as well as the equipment and materials used to produce such items.",
  "Counterfeits of the identified articles, legal tender and stamps": "Counterfeits of the identified articles, legal tender and stamps.",
  "Reproductions or replicas of coins para": "Reproductions or replicas of coins as collectible items must be clearly marked with the word 'COPY', 'REPRODUCTION' or 'REPLICA' and comply with local laws.",
  "Rough diamonds and “conflict minerals” originating from non-compliant countries": "Rough diamonds and “conflict minerals” originating from non-compliant countries.",
  "Artefacts, cultural relics para": "Artefacts, cultural relics, historical grave markers, and related items are protected under the laws of India.",
  "HUMAN PARTS, HUMAN REMAINS AND PROTECTED FLORA AND FAUNA": "HUMAN PARTS, HUMAN REMAINS AND PROTECTED FLORA AND FAUNA",
  "Listing of human body parts and remains": "Listing of human body parts and remains.",
  "The listing or sale of any animal para": "The listing or sale of any animal (including any animal parts such as pelts, skins, internal organs, teeth, claws, shells, bones, tusks, ivory and other parts) protected by the Convention on International Trade in Endangered Species of Wild Fauna and Flora (CITES) or any other local law or regulation.",
  "OFFENSIVE MATERIAL AND INFORMATION DETRIMENTAL TO NATIONAL SECURITY": "OFFENSIVE MATERIAL AND INFORMATION DETRIMENTAL TO NATIONAL SECURITY",
  "Any publication or other media containing state para": "Any publication or other media containing state secrets or information detrimental to national security or public order.",
  "Any information supporting or advocating para": "Any information supporting or advocating infringement of national sovereignty, terrorist organizations or discrimination on grounds of race, sex, or religion.",
  "Postings that are ethnically or racially offensive": "Postings that are ethnically or racially offensive.",
  "Materials advocating, promoting para": "Materials advocating, promoting or otherwise supporting fascism, Nazism and other extreme ideologies.",
  "TOBACCO PRODUCTS": "TOBACCO PRODUCTS",
  "The posting of tobacco products para": "The posting of tobacco products, including but not limited to cigars, cigarettes, cigarette tobacco, pipe tobacco, hookah tobacco, chewing tobacco and tobacco leaf is prohibited.",
  "PACKING INSTRUCTIONS": "PACKING INSTRUCTIONS",
  "PACKING INSTRUCTIONS para 1": "The parcel must be packed properly with all the genuine products and articles , the parcel must be packed with the packing material available on the platform properly sealed from all sides with tape available on platform.",
  "PACKING INSTRUCTIONS para 2": "The seller must paste the shipping label firmly on the parcel , and put the invoice generated by the platform inside the box.",
  "PACKING INSTRUCTIONS para 3": "Box found without invoice may be charged the penalty fees decided by the company.",
  "PACKING INSTRUCTIONS para 4": "Video and images must be clicked and recorded during the packing and after the packing, as this is one of the crucial proof if any dispute is raised.",
  "PACKING INSTRUCTIONS para 5": "Also if the buyer returns the parcel and the return request is approved the company the buyer must pack the parcel properly then and share the images and videos of packed parcel with company , then only the return request will be approved.",
  "ALL THE CLAIMS ON THE COMPANY": "ALL THE CLAIMS ON THE COMPANY IF ANY CAN ONLY BE PROCESSED IN DELHI JURISDICTION ONLY. SUBJECT TO DELHI JURISDICTION  ONLY.",

  // return shipment policy
  "Return Shipment Policy": "Return Shipment Policy",
  "Case 1 – RTO": "Case 1 – RTO",
  "RTO": "RTO",
  "RTO para1": "is the case when the shipment is not delivered to the buyer and the shipment returns to seller , the reason for the same can be multiple (Example : buyer refuses to take the parcel , cash not ready , logisitics policy of attempt completion etc.)",
  "RTO para2": "In case the parcel or shipment is not delivered to customer or buyer because of any of the reason , the seller is bound to accept the return, the seller will not be charged the platform fees and other charges in such case.",
  "RTO para3": "In case the seller find any product missing , or change in product , or any other issue in RTO delivered to him he can raise a dispute against the RTO ( within specified time window formulated by the company i.e. within 36 hours of RTO delivery ) and has to mention the reason of dispute with valid proofs and documentation.",
  "Time window for raising dispute is 36 hours after the time of delivery of RTO": "Time window for raising dispute is 36 hours after the time of delivery of RTO.",
  "For further clarification the AKAR executive para": "For further clarification the AKAR executive can contact the seller and can ask for other documentation and valid proofs such as packing video, unpacking video or images of the RTO received. The packing video and unpacking video should be recorded and shall fulfill the criteria of video made by the company ‘ AKAR COMMERCE INDIA PRIVATE’ or ‘AKAR’",
  "If all the proofs and documentation provided by the seller para": "If all the proofs and documentation provided by the seller stand valid and genuine then the dispute will settled against the seller and this case will be then treated same as the shipment which get delivered and the seller will get order amount after deducting all the platform charges and taxes after 4 working days from once the dispute have been resolved",
  "If the proofs and documentation provided by the seller does not satisfy para": "If the proofs and documentation provided by the seller does not satisfy the claim reason or does not stand as a valid proof , then the claim will be rejected and will be settled against seller and he will not get any claim, and also the seller has to accept the same .",
  "The criteria of recording packing video": "The criteria of recording packing video :",
  "The criteria of recording packing video para": "The packing video should be recorded from the very beginning . firstly all the corner and each side of the box should be recorded clearly, number of pieces or set of the product should be clearly visible and countable while recording the video , the video should only end when the box is completely packed and the shipping label is pasted on the box and the shipping label should also be clearly visible. The video should not contain any cut or edit from start to end. If any cut , edit or any other discrepancy found in the video it will not accepted as a valid proof and the dispute may set against the seller , which means the seller will not compensation against the RTO delivered, and the seller has to accept the same.",
  "The criteria of recording unpacking video": "The criteria of recording unpacking video",
  "While making the unpacking of any shipment para": "While making the unpacking of any shipment , the video shall start from clearly capturing the shipping label before starting unpacking , also the every side and corner of the box or shipment should clearly recorded fully sealed and taped , after capturing all these things stated , the unpacking shall start , while recording it should be kept in mind that the number of pieces or sets of product should be clearly visible and countable, at the end the empty box have to be recorded clearly, the video should not contain any cut or edit from start to end.",
  "If any cut , edit or any other discrepancy para": "If any cut , edit or any other discrepancy is found , it will not be accepted as the valid proof and the claim may be rejected.",
  "Case 2 – RVP": "Case 2 – RVP",
  "RVP": "RVP",
  "RVP para": "is the case when the buyer accept the delivery and raise the return request, with in formulated time span by the company ‘AKAR’ the reason may be multiple such as",
  "Product(s) is physically damaged or is defective": "Product(s) is physically damaged or is defective",
  "Warranty issues or quality related issues with respect to the product delivered": "Warranty issues or quality related issues with respect to the product delivered",
  "Wrong product or the product is not matching para": "Wrong product or the product is not matching the description or specifications mentioned on the listing page on platform",
  "Part of the order/products is found missing due to reasons para": "Part of the order/products is found missing due to reasons attribute to the seller, etc; At the time of raising return the buyer can only raise the request if the time window has not been closed, if the window is closed then the return request will be rejected by the company and buyer can not return the products and also the company is liable for the products being delivered",
  "Time window for raising return request is 36 hours after the time of delivery": "Time window for raising return request is 36 hours after the time of delivery.",
  "Time window for raising return request is 36 hours after the time of delivery para1": "In case the buyer raise a return request within formulated time span he has to provide the valid reason , proofs and documentation for the same. For further clarification AKAR executive may contact the buyer and can ask for valid proofs such as unpacking video and image , the executive can also ask for the clarification of what quality is differed from the listing of seller that he is not satisfied with, if the buyer provides valid and satisfactory proofs and answers , then the return would be accepted and if not ,the return request will be rejected and the buyer is bound to accept the decision of company.",
  "Time window for raising return request is 36 hours after the time of delivery para2": "If the buyer raise a return request , and the request is accepted by company ‘AKAR’ , the buyer has to repack the parcel with the genuine products which he/she/they received , with the genuine quantity for which the return have been accepted or approved, And the packing video of the same is mandatory or compulsory for the buyer. The packing video must be recorded as per the terms specified and formulated by the company ‘AKAR’. In case of any dispute , if the buyer is unable to provide the proper packing video the dispute will settled against buyer and he will not get any refund or compensation for the parcel he returned, neither ‘we’ or ‘AKAR’ is responsible for the same and also not responsible for returning the shipment or parcel again to the buyer",
  "Time window for raising return request is 36 hours after the time of delivery para3": "Also, if the buyer returns the wrong product(s), different product(s), damaged product(s) or any other product or the same product in different state from which he received , the buyer refund request will not be approved and neither the ‘AKAR’ is responsible for returning the shipment or parcel again to the buyer , if any dispute is raised by the seller the dispute will settled in favor of seller. In case the seller has to raise a dispute against the RVP received, he/she/they has to raise a dispute within the formulated time span i.e. within 36 hours of RVP received. The seller has not right to raise the dispute after the time span formulated. If the seller raise a dispute within time span formulated, he has to mention the reason of dispute with proper proofs and documentation. For further clarification the ‘AKAR’ executive may contact the seller for further clarification and can also ask for valid proofs such as packing and unpacking video, images or any other relevant proof",
  "Time window for raising return request is 36 hours after the time of delivery para4": "The packing video and unpacking video should fulfill the criteria made by the company, ‘AKAR’. In the case the seller is unable to provide the valid proofs asked by the company, the dispute will settled against the seller and also the seller have to bear the transit cost of delivery of the product as well as the RVP received to him. And if the seller provides all the proofs and documentation satisfactory to the company the dispute will settled in favor of the seller. In such case after the dispute is solved the seller will get the payment with in the 4 days",
  "Time window for raising return request is 36 hours after the time of delivery para5": "The buyer and seller has to make the packing and unpacking video according to the criteria mentioned and formulated by the company ‘AKAR’",
  "The criteria of recording packing video": "The criteria of recording packing video",
  "The criteria of recording packing video para": "The packing video should be recorded from the very beginning. firstly all the corner and each side of the box should be recorded clearly, number of pieces or set of the product should be clearly visible and countable while recording the video , the video should only end when the box is completely packed and the shipping label is pasted on the box and the shipping label should also be clearly visible. The video should not contain any cut or edit from start to end. If any cut , edit or any other discrepancy found in the video it will not accepted as a valid proof and the dispute may set against the seller , which means the seller will not compensation against the RTO delivered, and the seller has to accept the same.",
  "The criteria of recording unpacking video para1": "While making the unpacking of any shipment , the video shall start from clearly capturing the shipping label before starting unpacking , also the every side and corner of the box or shipment should clearly recorded fully sealed and taped , after capturing all these things stated , the unpacking shall start , while recording it should be kept in mind that the number of pieces or sets of product should be clearly visible and countable, at the end the empty box have to be recorded clearly, the video should not contain any cut or edit from start to end.",
  "The criteria of recording unpacking video para2": "While the dispute is being solved the funds of both the side i.e. buyer and seller will be on hold, the funds will released within in the 4 days after the dispute is solved.",
  "The criteria of recording unpacking video para3": "If the dispute is solved in favor of buyer the buyer will get the refund of the product along with the delivery charges, if any, charged on him during the delivery of order, within 4 days after the dispute is solved, till then the balance will credited in the electronic ledger of the buyer and the buyer can use the balance to buy or purchase any of the products from the platform ‘AKAR’.",
  "The criteria of recording unpacking video para4": "If the dispute is settled against the buyer the buyer will not get the refund or any other charges he paid during the delivery of products ,and neither the company nor the seller is responsible for delivering the parcel back to the buyer (parcel which was returned by the buyer.)",
  "The criteria of recording unpacking video para5": "If the dispute is settled in favor of seller, the seller will get order amount within 4 days after solving the dispute after deducting the platform charges or any other charges the seller is liable to pay for . And if the dispute is settled against the seller, the seller will not get the order amount , also the logistic charges incurred on delivering the parcel and return both will be deducted from the future payment and will be debited in the electronic ledger of seller.",
  "The criteria of recording unpacking video para6": "The both buyer and seller is bound to accept the decision made by the company ‘AKAR’ or ‘ AKAR COMMERCE INDIA PRIVATE LIMITED’.",
  "THE RETURN SHIPMENT POLICY CAN BE CHANGED FROM TIME TO TIME": "THE RETURN SHIPMENT POLICY CAN BE CHANGED FROM TIME TO TIME.",
  "Scheduled": "Scheduled"
}

export default transEN;
