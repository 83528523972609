import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


class ComingSoonComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title } = this.props
        return (
            <div style={{
                height: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
            }}>
                <div style={{
                    color: '#f26522'
                }}>
                    <h6>{title}</h6>
                    <p>Coming Soon...</p>
                </div>
            </div>

        );
    }
}

export default ComingSoonComponent;
