import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './footer.scss'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    render() {
        const { t } = this.props
        return (<>
            <div className={'footer'}>
                <div className={'topFooter'}>
                    <div className="container">
                        <div className={'row'}>
                            {/* <div className={'col-md-3 col-sm-6 col-xs-12 endFooterMenu'}>
                                <div className={'categories'}>
                                    <h2>ABOUT</h2>
                                    <ul className={'menu'}>
                                        <li><Link to="/contact-us" >
                                            Contact Us
                                        </Link></li>
                                        <li><a className="/about-us" >About Us</a></li>
                                    </ul>
                                </div>
                            </div> */}
                            <div className={'col-md-4 col-sm-6 col-xs-12 endFooterMenu'}>
                                <div className={'categories content_center'}>
                                    <ul className={'menu text-center'}>
                                        <h2>{t('HELP')}</h2>
                                        <li><Link to="/contact-us" >
                                            {t('Contact Us')}
                                        </Link></li>
                                        <li><Link to='/terms-and-conditions'>{t('Terms and conditions')}</Link></li>
                                        <li><Link to='/refund-cancellation-policy'>Refund & Cancellation Policy</Link></li>
                                        <li><Link to='/payment-cycle'>Payment Cycle</Link></li>

                                        <li><a className="" >{t('Sell on Akar')}</a> </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'col-md-4 col-sm-6 col-xs-12 endFooterMenu'}>
                                <div className={'categories content_center'}>
                                    <ul className={'menu text-center'}>
                                        <h2>{t('POLICY')}</h2>
                                        <li><Link to="/privacy-policy" >
                                            {t('Privacy Policy')}
                                        </Link></li>
                                        <li><Link to="/return-shipment-policy" >
                                            {t('Return Shipment')}
                                        </Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'col-md-4 col-sm-6 col-xs-12 endFooterMenu'}>
                                <div className={'categories content_center'}>
                                    <ul className={'menu text-center'}>
                                        <h2>{t('SOCIAL')}</h2>
                                        <li><a className="" target='_blank' href={'https://www.facebook.com/Akar-India-B2B-Online-Market-Place-104391688380298/'}>{t('Facebook')}</a></li>
                                        <li><a className="" target='_blank' href={'https://instagram.com/akar_india_b2b?igshid=1ohj66uw0yrt7'}>{t('Instagram')}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }
}
const mapStateToProps = ({ global }) => ({
    loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer)));

