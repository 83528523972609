import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../../layoutComponents/layoutComponent';
import { Row, Col, Input, Upload, message, Button } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { CloseCircleFilled, FileAddOutlined, UploadOutlined } from '@ant-design/icons';
import BrandService from '../../../service/brandService'
import SaveListing from '../../../service/saveListingService'
const { TextArea } = Input;

class NewBrandComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageFiles: [],
            fileList: [],
            description: ''
        }
    }

    componentDidMount() { }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    onChange(e) {
        const { t } = this.props
        let fileList = this.state.fileList
        let selectedFile = e.target.files[0]
        const fileExtn = selectedFile.name.substr(selectedFile.name.lastIndexOf("."))

        if ((fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png') && selectedFile) {
            let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
            selectedFile = new File([blob], moment().valueOf() + fileExtn, { type: selectedFile.type });
            this.addImageInAWS(selectedFile)
            var url = URL.createObjectURL(selectedFile)
            fileList.push(url)
            this.setState(fileList)
            if (fileList.length > 1) {
                this.deleteImage('', 0)
            }
        } else {
            return message.error(`${t('Attach image in png/jpg/jpeg file format')}`)
        }
        document.getElementById('myInput').value = null
    }

    addImageInAWS = (data) => {
        BrandService.addImage(data, 'BRAND_DISPLAY_FILE')
            .then((res) => {
                let imageFiles = this.state.imageFiles
                imageFiles.push(res.data.data[0])
                this.setState(imageFiles)

            }, err => {
                //console.log(err)
            })
    }

    deleteImage = (file, index) => {
        var images = [...this.state.imageFiles];
        this.deleteImageFromAWS(images[index].url)
        images.splice(index, 1);
        this.setState({ imageFiles: images });
        var array = [...this.state.fileList];
        array.splice(index, 1);
        this.setState({ fileList: array });
    }

    deleteImageFromAWS = (data) => {
        BrandService.deleteImage(data)
            .then((res) => {
                //console.log(res)
            }, err => {
                //console.log(err)
            })
    }

    submitBrandVarification = () => {
        SaveListing.brand.imageFileUploadedData = this.state.imageFiles[0] || {}
        SaveListing.brand.brand.description = this.state.description
        this.navigate('/brand-relation')
    }

    render() {
        const { fileList, description, imageFiles } = this.state;
        const { t } = this.props;
        return (
            <LayoutComponent title={t('Brand')}>
                <Row className='sidebar_navigation-items mt-3' >
                    <Col span={24}>

                        <div className='brand_list mt-0 border'>
                            <div className=''>
                                <div className='step'>
                                    <div>
                                        <h4>{t('Create brand')}</h4>
                                        <p>{t('Description')}</p>
                                        <TextArea autoSize={{ minRows: 3, maxRows: 6 }}
                                            placeholder={t('Description')}
                                            value={description}
                                            onChange={(val) => {
                                                this.setState({ description: val.target.value })
                                            }} />
                                        <p className={'mt-2'}>
                                            {t('Add logo for brand')}
                                        </p>
                                    </div>
                                    <div className='' style={{ overflowX: 'unset' }}>

                                        {fileList.map((item, i) => {
                                            return (
                                                <div key={i} className='file ml-2'>
                                                    <a href={item} target="_blank">
                                                    <img src={item} alt='Something wrong' /></a>
                                                    <span className="remove-pdf" onClick={() => this.deleteImage(item, i)}>
                                                        <CloseCircleFilled />
                                                    </span>
                                                </div>
                                            )
                                        })}

                                        <input
                                            id="myInput"
                                            type="file"
                                            ref={(ref) => this.myInput = ref}
                                            onChange={this.onChange.bind(this)}
                                            accept=".png,.jpg,.jpeg"
                                            style={{ display: 'none' }} />
                                        <Button className='mr-3' onClick={() => { this.myInput.click() }}
                                            icon={<UploadOutlined />}>{t('Upload')}</Button>

                                        {/* <button className="float-right mr-3 file" onClick={() => { this.myInput.click() }}  > <FileAddOutlined /></button> */}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row>

                    <Col span={24} className='stickToBottom'>
                        <div className='button_div'>
                            <button onClick={(e) => this.submitBrandVarification()}>
                                {t('Continue')}
                            </button>
                        </div>
                    </Col>

                </Row>

            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(NewBrandComponent));