import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './App.scss';
import { useLocation, useHistory } from 'react-router-dom';
import Router from './components/route'
import { connect } from 'react-redux'
import AuthService from './service/authService';
import CartService from './service/cart';

// import ReactGA from 'react-ga4';
  //  ReactGA.pageview(window.location.pathname + window.location.search);
const App = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [isloaded, setIsLoaded] = useState(false);
  // const TAG_ID = process.env.REACT_APP_TAG_ID // OUR_TAG_ID
  // const PROD_TAG_ID = process.env.REACT_APP_PROD_TAG_ID // OUR_TAG_ID

// if(process.env.REACT_APP_WEBSITE_URL == "https://www.akarindia.com/#/"){

//   ReactGA.initialize(PROD_TAG_ID);
// } else{
//   ReactGA.initialize(TAG_ID);
  
// }

//   history.listen((location) => {
//     // ReactGA.set({ page: location.pathname });
//     // ReactGA.pageview(location.pathname)
//     ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: location.pathname })
//   }
//   );
//   window.gtag('event', 'page_view');

  const getUserDetails = () => {
    const { dispatch } = props
    let AuthToken = typeof window !== 'undefined' ? localStorage.getItem('Authorization') : null
    if (AuthToken) {
      AuthService.getUserDetails().then((res) => {
        let { data } = res.data
        dispatch({
          type: 'SET_CURRENT_USER',
          user: data
        })
        setIsLoaded(state => true)
      }, (err) => {
        //console.log(err)
      });
    } else {
      setIsLoaded(state => true)
    }
  }

  const loadCartItems = () => {
    const { dispatch, isItemsInCartExist } = props
    let AuthToken = typeof window !== 'undefined' ? localStorage.getItem('Authorization') : null
    if (!isItemsInCartExist && AuthToken) {
      CartService.getCartItemsData().then((res) => {
        let { data } = res.data
        if (data.length > 0) {
          dispatch({
            type: 'SET_CART_ITEMS_FLAG',
            isExist: true
          })
        } else {
          dispatch({
            type: 'SET_CART_ITEMS_FLAG',
            isExist: false
          })
        }
      }, (err) => {
        //console.log(err)
      });
    }
  }

  useEffect(() => {
    if (location.pathname) {
      let splitStr = location.pathname.split('/')
      if (!props.token && (splitStr[1] === 'view-product' || splitStr[1] === 'order-details')) {
        location.pathname = location.pathname.replace('/', '');
        history.push({ pathname: '/login', search: `?redirect=${location.pathname}` })
      }
      else {
        let redirectUrl = location.search ? location.pathname + location.search : location.pathname
        redirectUrl = redirectUrl.replace('/', '');
      }
    }
    getUserDetails()
  }, [])

  useEffect(() => {
    if (location.pathname) {
      let redirectUrl = location.search ? location.pathname + location.search : location.pathname
      redirectUrl = redirectUrl.replace('/', '');
    }
    loadCartItems()
  }, [location]);

  return (<>{isloaded ? <Router /> : null}</>);
}

const mapStateToProps = ({ global }) => ({
  loading: global.buttonLoading,
  token: global.token,
  currentUser: global.currentUser,
  isItemsInCartExist: global.isItemsInCartExist
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
