import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import PageVisibility from 'react-page-visibility';
import imageCompression from 'browser-image-compression';
import { Row, Tabs, Input, message, Drawer, Col, Modal, Button } from 'antd';

import './chatStyle.scss';
import userImage from '../../user.jpg';
import MediaUrl from '../../service/mediaUrl';
import BrandService from '../../service/brandService';
import { SendOutlined, LinkOutlined, PlayCircleOutlined } from '@ant-design/icons';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { ChatType, ChatDirection } from '../../service/statusTypes';

import { sendChatMessage, getAllChatsByGuid, getAllChatThreads, clearUserChat } from '../../actions'
import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;

class ChatComponent extends Component {
	tabPane = React.createRef()
	leftTabPane = React.createRef()
	constructor(props) {
		super(props);
		this.state = {
			imgArr: [],
			threadId: '',
			videoArr: [],
			videoList: [],
			imageList: [],
			loading: false,
			textTosend: '',
			videoFile: null,
			showPreview: false,
			singleChatItem: {},
			showSendBtn: false,
			drawerVisible: false,
			showModalFooter: true,
			noMoreDataMessage: '',
		}
	}

	handleVisibilityChange = isVisible => {
		const values = queryString.parse(this.props.location.search)
		if (isVisible) {
			if (this.state.threadId) {
				//this.onFocus()
			}
		}
	}

	componentDidMount = async () => {
		let { dispatch, allChatThreads, individualChatMessages } = this.props
		const values = queryString.parse(this.props.location.search)

		this.setState({ threadId: values.threadId || null })

		if (allChatThreads.threads.length === 0) {
			dispatch(getAllChatThreads(allChatThreads.page, allChatThreads.pageLimit, 'assign'))
		}

		if (values.threadId) {
			dispatch(getAllChatsByGuid(values.threadId, individualChatMessages.page,
				individualChatMessages.pageLimit, 'assign'))
		}

		setTimeout(() => { this.scrollToBottom() }, 800)

		this.tabPane = document.getElementsByClassName("ant-tabs-content-holder")[0]
		this.leftTabPane = document.getElementsByClassName('ant-tabs-nav-list')[0]

		if (this.leftTabPane) {
			this.leftTabPane.addEventListener('scroll', (event) => {
				this.leftTabScroll()
			})
		}
		if (this.tabPane) {
			this.tabPane.addEventListener('scroll', (event) => {
				this.rightTabScroll()
			})
		}

		if (Notification.permission !== "granted") {
			setInterval(() => {
				dispatch(getAllChatsByGuid(this.state.threadId, 1, individualChatMessages.pageLimit, "assign"))
				dispatch(getAllChatThreads(1, allChatThreads.pageLimit, 'assign'))
			}, 10000);
		}

	}

	rightTabScroll = () => {
		let { dispatch, individualChatMessages } = this.props
		if (this.tabPane.scrollTop === 0) {
			if (individualChatMessages.page < individualChatMessages.totalPageCount) {
				dispatch(getAllChatsByGuid(this.state.threadId,
					individualChatMessages.page + 1,
					individualChatMessages.pageLimit, 'append'))
			} else {
				this.setState({
					noMoreDataMessage: 'You have reached at the starting of the conversation.'
				})
			}
		}
	}

	leftTabScroll = () => {
		let { dispatch, allChatThreads } = this.props
		let maxScroll = this.leftTabPane.scrollHeight - this.leftTabPane.clientHeight
		let currentScroll = this.leftTabPane.scrollTop
		if (Math.ceil(currentScroll) === maxScroll) {
			if (allChatThreads.threads.length !== allChatThreads.totalCount) {
				dispatch(getAllChatThreads(allChatThreads.page + 1, allChatThreads.pageLimit, 'append'))
			}
		}
	}

	componentDidUpdate = (prevProps) => {
		const { individualChatMessages, allChatThreads, dispatch } = this.props
		const values = queryString.parse(this.props.location.search)

		if (!values.threadId && allChatThreads && allChatThreads.threads && allChatThreads.threads &&
			allChatThreads.threads.length) {
			this.setState({ threadId: allChatThreads.threads[0].chatThreadGuid }, () => {
				this.props.history.push({ pathname: '/chat', search: `?threadId=${allChatThreads.threads[0].chatThreadGuid}` })
				dispatch(getAllChatsByGuid(allChatThreads.threads[0].chatThreadGuid, individualChatMessages.page,
					individualChatMessages.pageLimit, 'assign'))
			})
		}

		if (individualChatMessages.totalCount && prevProps.individualChatMessages.totalCount &&
			individualChatMessages.totalCount !== prevProps.individualChatMessages.totalCount) {
			this.scrollToBottom();
		}
	}

	componentWillUnmount = () => {
		const { dispatch } = this.props
		const { allChatThreads } = this.state;
		dispatch(clearUserChat())
		if (allChatThreads && allChatThreads.length) {
			this.leftTabPane.removeEventListener('scroll', (event) => {
				this.leftTabScroll()
			})
			this.tabPane.removeEventListener('scroll', (event) => {
				this.rightTabScroll()
			})
		}
	}

	sendMessage = async (chatData) => {

		const { dispatch, allChatThreads } = this.props
		let messageType, dataToSend

		if (this.state.videoList.length > 0) {
			messageType = ChatType.Video
			dataToSend = { url: this.state.videoList[0].url }
		}

		if (this.state.imageList.length > 0) {
			messageType = ChatType.Image
			dataToSend = { url: this.state.imageList[0].url }
		}

		if (this.state.textTosend) {
			messageType = ChatType.Text
			dataToSend = { textMessage: this.state.textTosend.trim() }
		}
		dispatch(sendChatMessage(chatData.to, chatData.orderId, messageType, dataToSend))
		setTimeout(() => {
			dispatch(getAllChatThreads(1, allChatThreads.pageLimit, 'assign'))
		}, 2000);

		this.setState({
			singleChatItem: {},
			textTosend: '', showSendBtn: false,
			imageList: [], videoList: [],
			imgArr: [], videoArr: []
		})


	}

	onTabChange = (activeChatThreadId) => {
		const { dispatch, individualChatMessages } = this.props
		this.props.history.push({ pathname: '/chat', search: `?threadId=${activeChatThreadId}` })
		this.setState({ threadId: activeChatThreadId }, () => {
			dispatch(getAllChatsByGuid(this.state.threadId, individualChatMessages.page,
				individualChatMessages.pageLimit, 'assign'))
		})
	}

	onCloseDrawer = () => {
		this.setState({ drawerVisible: false })
	}

	onChange = (e) => {
		const { t } = this.props
		const selectedVedioFile = e.target.files[0]
		let movcondition = selectedVedioFile.name.includes(".mov");
		let mp4cond = selectedVedioFile.name.includes(".mp4");

		if (mp4cond || movcondition) {
			let videoArr = this.state.videoArr
			if (selectedVedioFile) {
				let fileSize = this.returnFileSize(selectedVedioFile.size)
				if (selectedVedioFile.size >= 20971520) {
					return message.error(`${t('Video size limit validation')}`)
				} else {
					var url = URL.createObjectURL(selectedVedioFile)
					videoArr.push(url)
					this.setState({
						videoFile: selectedVedioFile, videoArr,
						showPreview: true, drawerVisible: false,
						showModalFooter: true
					})
				}
			}

		} else {
			this.setState({ drawerVisible: false })
			return message.error(`${t('Attach video in mp4/mov file format')}`)
		}
		document.getElementById('myVedioInput').value = null
	}

	onImageChange = async (event) => {
		const { t } = this.props
		let imgArr = this.state.imgArr
		let imageFile = event.target.files[0];
		const fileExtn = imageFile.name.substr(imageFile.name.lastIndexOf("."))
		const options = {
			maxSizeMB: 2,
			maxWidthOrHeight: 1920,
			useWebWorker: true
		}
		if ((fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png') && imageFile) {
			try {
				let blob = imageFile.slice(0, imageFile.size, imageFile.type);
				imageFile = new File([blob], moment().valueOf() + fileExtn, { type: imageFile.type });
				const compressedFile = await imageCompression(imageFile, options);
				var url = URL.createObjectURL(compressedFile)
				imgArr.push(url)
				this.setState({
					compressedImgFile: compressedFile, imgArr,
					showPreview: true, drawerVisible: false,
					showModalFooter: true
				})
			}
			catch (error) { }
		} else {
			this.setState({ drawerVisible: false })
			return message.error(`${t('Attach image in png/jpg/jpeg file format')}`)
		}

		document.getElementById('myImageInput').value = null
	}

	addImageInAWS = (data) => {
		const { singleChatItem } = this.state
		BrandService.addImage(data, 'PRODUCT_DISPLAY_FILE')
			.then((res) => {
				let ImageList = this.state.imageList
				let data = res.data.data[0]
				ImageList.push(data)
				this.setState({
					imageList: ImageList,
					showPreview: false, loading: false
				}, () => {
					this.sendMessage(singleChatItem)
				})
			}, err => { })
	}

	addVedioInAWS = (data) => {
		const { singleChatItem } = this.state
		BrandService.addImage(data, 'PRODUCT_DISPLAY_FILE')
			.then((res) => {
				let fileList = this.state.videoList
				let data = res.data.data[0]
				fileList.push(data)
				this.setState({ videoList: fileList, showPreview: false, loading: false }, () => {
					this.sendMessage(singleChatItem)
				})
			}, err => { })
	}

	returnFileSize = (number) => {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number >= 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number >= 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
	}

	handleOk = () => {
		const { imgArr, compressedImgFile, videoFile } = this.state
		this.setState({ loading: true }, () => {
			if (compressedImgFile && imgArr) {
				this.addImageInAWS(compressedImgFile)
			}
			else {
				this.addVedioInAWS(videoFile)
			}
		})
	};

	handleCancel = () => {
		this.setState({
			showPreview: false,
			imgArr: [], videoArr: [],
			previewImageUrl: '', previewVideoUrl: ''
		})
	};

	scrollIfNecessary = () => {
		let shouldScroll = this.tabPane.scrollTop + this.tabPane.clientHeight === this.tabPane.scrollHeight;
		if (!shouldScroll) {
			this.scrollToBottom();
		}
	}

	scrollToBottom = () => {
		const { allChatThreads } = this.state;
		if (allChatThreads && allChatThreads.length) {
			this.tabPane.scrollTop = this.tabPane.scrollHeight;
		}
	}

	breakString = (str) => {
		if (str.length > 35) {
			let temp = str.substring(0, 40)
			return temp + ' ...'
		}
		return str;
	}

	render() {

		const { allChatThreads, individualChatMessages, t } = this.props
		const {
			textTosend, showModalFooter, showSendBtn, drawerVisible,
			showPreview, imgArr, loading, videoArr
		} = this.state

		let chatDates = Object.keys(this.props.individualChatMessages.chats);

		const renderTab = (item) => {
			return <>
				<div className='tab_heading'>
					<div className={'chat_left'}>
						<div className={'chat_div'}>
							<img className={'chat_img'} src={item.image && item.image.url ? MediaUrl.completeMediaUrl(item.image.url, true, 100, 100) : userImage} />
						</div>
						<div className={'chat_text'}>
							<div className="userInfo" style={{ justifyContent: 'space-between' }}>
								<div style={{ width: "78%", overflow: "hidden" }}>
									<p className="username"> {item.orderId ? `${item.orderId} ` : null} </p>
									<p style={{ fontSize: "6px" }}> {item.toName ? `${item.toName}` : null} </p>
								</div>
								{/* <p className="spacer"></p> */}
								<div style={{ width: "20%" }}>
									<p className="dateOnLeft" >{moment(item.modifiedOn).format('DD/MM/YY')}</p>
								</div>
							</div>

							<p className="userText" style={{ color: "#adadad", fontSize: "4px", marginBottom: "-5px" }}>{item.lastMessage ? this.breakString(item.lastMessage) : ''}</p>
						</div>
					</div>
				</div>
			</>
		}

		const getBubble = (item, index) => {
			return (
				<div key={index}>
					{
						(item.chatFile && item.chatFile.url) ?
							(<div className={(item.direction === ChatDirection.Out
								? "container  message_out imgBubble"
								: "container  message_in imgBubble")}
							>
								{item.type === ChatType.Text ? <p>{item.message}</p> : null}
								{item.type === ChatType.Image && item.chatFile && item.chatFile.url && <div className="chat_Media_Div" onClick={() => {
									this.setState({ showPreview: true, previewImageUrl: item.chatFile.url, showModalFooter: false, previewVideoUrl: '' })
								}}>
									<img src={item.chatFile.url} />
								</div>}
								{item.type === ChatType.Video && item.chatFile && item.chatFile.url && <div className="chat_Media_Div" onClick={() => {
									this.setState({ showPreview: true, previewVideoUrl: item.chatFile.url, showModalFooter: false, previewImageUrl: '' })
								}}>
									{/* <video src={item.chatFile.url} autoPlay={false} preload={'none'} controls controlsList="nodownload" /> */}
									<div style={{ height: '120px', width: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
										<PlayCircleOutlined style={{ color: '#000', fontSize: '22px', fontWeight: '700px' }} />
									</div>
								</div>}
								<span className={"time-right"}>{item.createdOn && moment.utc(item.createdOn).utcOffset('+0530').format('h:mm a')}</span>
							</div>) :
							<div className={(item.direction === ChatDirection.Out ? "container message_out" : "container message_in")} >
								{item.type === ChatType.Text ? <p>{item.message}</p> : null}
								<span className={"time-right"}>{item.createdOn && moment.utc(item.createdOn).utcOffset('+0530').format('h:mm a')}</span>
							</div>
					}

					{/* {(item.chatFile === null) ?
						<div className={(item.direction === ChatDirection.Out ? "container message_out" : "container message_in")} >
							{item.type === ChatType.Text ? <p>{item.message}</p> : null}
							<span className={"time-right"}>{item.createdOn && moment.utc(item.createdOn).utcOffset('+0530').format('h:mm a')}</span>
						</div>
						: null} */}
				</div >)
		}

		return (
			<PageVisibility onChange={this.handleVisibilityChange}>
				<LayoutComponent title={t("Chat")}>
					<Row>
						{allChatThreads.threads.length === 0 ? <Col span={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "35vh" }}>
							<Row ><p >No chat records found</p></Row></Col>
							: <Tabs className='custom_chat_tabs hideOverflow'
								activeKey={this.state.threadId}
								onChange={this.onTabChange}
								tabPosition='left'>
								{allChatThreads && allChatThreads.threads &&
									allChatThreads.threads.length > 0 &&
									allChatThreads.threads.map((chatItem, chatIndex) => {
										return (
											<TabPane className="tabpane_div"
												tab={renderTab(chatItem)}
												key={chatItem.chatThreadGuid} >
												{this.state.threadId
													? <Row className="right_chat_container">
														<div className='chatsSection'>
															{
																chatDates && chatDates.length > 0
																	? chatDates.slice(0).reverse().map((item, ikey) => {
																		return (
																			<div key={ikey}
																				style={{
																					width: '100%',
																					display: 'flex',
																					flexDirection: 'column'
																				}}>
																				<div className="datePartition">
																					{
																						moment(item).isSame(moment().startOf('day'), 'd')
																							? `${t('Today')}`
																							: item
																					}
																				</div>
																				{
																					individualChatMessages && individualChatMessages.chats[item].length
																						? individualChatMessages.chats[item].map((temp, index) => {
																							return getBubble(temp, index)
																						})
																						: <div>no chats</div>
																				}
																			</div>
																		)
																	})
																	: <Col span={24}>
																		<div className="no_chat_div">
																			<h2>{(chatItem.lastMessage && chatItem.lastMessage.search('hold')) ? `${chatItem.lastMessage}` : `${t('No chats for this order')}`}</h2>
																		</div>
																	</Col>
															}
														</div>

														<div className={'bottom_fixed'}>
															<span className={'chat_icons'}>
																<LinkOutlined onClick={() => {
																	this.setState({ singleChatItem: chatItem, drawerVisible: true })
																}} />
															</span>
															<span style={{ width: '100%', margin: '0px 5px' }}>
																<Input value={textTosend}
																	onPressEnter={(e) => {
																		this.sendMessage(chatItem, chatIndex)
																	}}
																	onChange={(val) => {
																		if (val.target.value === "" || val.target.value === " ") {
																			this.setState({ textTosend: '', showSendBtn: false })
																		} else {
																			this.setState({ textTosend: val.target.value, showSendBtn: true })
																		}
																	}}
																	placeholder={t('Type a message')} /></span>
															<span style={{ visibility: showSendBtn ? 'visible' : 'hidden' }} className={'chat_icons'}><SendOutlined onClick={() => { this.sendMessage(chatItem, chatIndex) }} /></span>
														</div>

													</Row>
													: null}
											</TabPane>
										)
									})}
							</Tabs>}
					</Row>
					<Drawer
						height={270}
						placement='bottom'
						onClose={this.onCloseDrawer}
						visible={drawerVisible}
						title={t('Choose attachment type')}
						maskClosable={true}
						key={'select_bottom_chat'}
						className='bottom-drawer1280px'
					>
						<div className="sidebar_navigation-items">
							<div>
								<input id="myImageInput" type="file" ref={(ref) => this.myImageInput = ref}
									onChange={this.onImageChange.bind(this)}
									accept=".png,.jpg,.jpeg"
									style={{ display: 'none' }} />

								<p className={"attachment border_bottom"}
									onClick={() => { this.myImageInput.click() }}> {t('Image')} </p>

								<input id="myVedioInput" type="file" ref={(ref) => this.myInput = ref}
									onChange={this.onChange.bind(this)}
									accept=".mp4,.mov"
									style={{ display: 'none' }} />

								<p className={"attachment"}
									onClick={() => { this.myInput.click() }}>
									{t('Video')}
								</p>
							</div>

						</div>
					</Drawer>
					<Modal className={'img_modal'}
						title={t('Preview')}
						closable={showModalFooter ? false : true}
						centered={true}
						maskClosable={false}
						visible={showPreview}
						footer={showModalFooter ? [
							<Button key="back" onClick={this.handleCancel}>
								{t('Cancel')}
							</Button>,
							<Button key="submit" type="primary" loading={loading} onClick={this.handleOk}>
								{t('Send')}
							</Button>,
						] : null}
						onOk={this.handleOk}
						onCancel={this.handleCancel}
					>
						<div className={'chat_modalFiles_div'}>
							{imgArr && imgArr.length > 0 && imgArr.map((item, i) => {
								return (
									<div key={i} className='chat_file ml-2'>
										<img src={item} alt='Something wrong' />
									</div>
								)
							})}

							{this.state.previewImageUrl && <div className='chat_file ml-2'>
								<img src={this.state.previewImageUrl} alt='Something wrong' />
							</div>}

							{videoArr && videoArr.length > 0 && videoArr.map((item, vIndex) => {
								return (
									<div key={vIndex} >
										<video src={item} autoPlay={false} preload={'none'} controls controlsList="nodownload" />
									</div>
								)
							})}

							{this.state.previewVideoUrl && <div className='chat_file ml-2'>
								<video src={this.state.previewVideoUrl} autoPlay={false} preload={'none'} controls controlsList="nodownload" />
							</div>}
						</div>
					</Modal>
				</LayoutComponent>
			</PageVisibility>
		)
	}
}

const mapStateToProps = ({ chat }) => {
	return {
		allChatThreads: chat.chatThreads,
		individualChatMessages: chat.userChats,
		//  activeChatThreadId: chat.activeChatThreadId || ''
	}
}
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ChatComponent)));


	// onFocus = () => {
	//     const { dispatch, individualChatMessages } = this.props
	//     const values = queryString.parse(this.props.location.search)
	//     console.log("focus", values.threadId, individualChatMessages.page, individualChatMessages.pageLimit)
	//     dispatch(getAllChatsByGuid(values.threadId, individualChatMessages.page, individualChatMessages.pageLimit, 'assign'))
	// }