import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import profileImg from '../../assests/profile.svg'
import businessImg from '../../assests/buisness-settings.svg'
import accountImg from '../../assests/account-settings.svg'
import supportImg from '../../assests/support.svg'
import { RightOutlined } from '@ant-design/icons';

class AccountComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() { }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    render() {
        const { t } = this.props
        return (
            <LayoutComponent title={t('Account')}>
                <Row>
                    <Col span={24} className=''>

                        <div onClick={(e) => this.navigate('/user-profile')}
                            className='account_option '>
                            <img src={profileImg} alt='user' />
                            <div>
                                <h3>{t('Your profile')}</h3>
                                <p className='label textcolor'>{t('Modify your profile')}</p>
                            </div>
                            <div className="spacer" />
                            <div className='icon_div'>
                                <RightOutlined />
                            </div>
                        </div>

                        <div onClick={(e) => this.navigate('/business-profile')}
                            className='account_option '>
                            <img src={businessImg} alt='business' />
                            <div>
                                <h3>{t('Business profile')}</h3>
                                <p className='label textcolor'>{t('Modify your business profile')}</p>
                            </div>
                            <div className="spacer" />
                            <div className='icon_div'>
                                <RightOutlined />
                            </div>
                        </div>

                        <div onClick={(e) => this.navigate('/account-profile')}
                            className='account_option '>
                            <img src={accountImg} alt='account' />
                            <div>
                                <h3>{t('Account settings')}</h3>
                                <p className='label textcolor'>{t('Address, Bank Details etc')}</p>
                            </div>
                            <div className="spacer" />
                            <div className='icon_div'>
                                <RightOutlined />
                            </div>
                        </div>

                        <div onClick={(e) => this.navigate('/manage-team')}
                            className='account_option '>
                            <img src={supportImg} alt='account' />
                            <div>
                                <h3>{t('Your team')}</h3>
                                <p className='label textcolor'>{t('Manage people in your team')}</p>
                            </div>
                            <div className="spacer" />
                            <div className='icon_div'>
                                <RightOutlined />
                            </div>
                        </div>

                    </Col>
                </Row>
            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(AccountComponent))
