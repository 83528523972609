import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Row, Col, Card, Skeleton, Input, List, message } from "antd";
import queryString from "query-string";
import moment from "moment";
import { connect } from "react-redux";
import DisputeService from "../../service/disputeService";
import MediaUrl from "../../service/mediaUrl";
import LayoutComponent from "../layoutComponents/layoutComponent";
import myImage from "../../imageNotFound.jpeg";
import "./index.scss";
import { withTranslation } from "react-i18next";
const { TextArea } = Input;

class DisputeDetailComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disputedLineItems: [{}],
      commentsList: [],
      comment: "",
      disputeDetails: {},
      disputeImages: [],
      disputeVideos: [],
      loading: false,
    };
  }
  navigate = (url) => {
    this.props.history.push({ pathname: url });
  };

  getAllComments = async () => {
    const { t } = this.props
    const searchParams = queryString.parse(this.props.location.search);
    this.setState({ loading: true });
    let { data: commentRespp } = await DisputeService.getAllDisputeComments(
      searchParams.disputeCustomId
    );

    if (commentRespp.isSuccess) {
      let { data } = commentRespp;
      this.setState({
        commentsList: data.results.reverse(),
        loading: false,
      });
    } else {
      message.error(`${t("Error in fetching comments")}`);
      this.setState({ loading: false });
    }
  };

  createComment = async () => {
    const { t } = this.props;
    const searchParams = queryString.parse(this.props.location.search);
    let obj = {
      disputeCustomId: searchParams.disputeCustomId,
      comment: this.state.comment,
    };
    let { data } = await DisputeService.createDisputeComment(obj);
    if (data.isSuccess) {
      this.getAllComments();
      this.setState({ comment: "" });
    } else {
      message.error(`${t("Error in creating comment")}`);
    }
  };

  getDisputeDetailsByDisputeId = async () => {
    const searchParams = queryString.parse(this.props.location.search);
    const { t } = this.props;
    let { data: disputeResp } = await DisputeService.getDisputeDetails(
      searchParams.disputeCustomId
    );

    if (disputeResp.isSuccess) {
      let { data } = disputeResp;

      this.setState(
        {
          disputedLineItems: data.disputeLineItems,
          disputeDetails: data,
        },
        () => {
          if (data.disputeFileUploads.length) {
            let imagesArr = [];
            let videoArr = [];
            data.disputeFileUploads.forEach((item, key) => {
              if (
                item.fileUploadedData.name.includes(".mov") ||
                item.fileUploadedData.name.includes(".mp4")
              ) {
                let url = MediaUrl.completeMediaUrl(
                  item.fileUploadedData.url,
                  false,
                  100,
                  100
                );
                videoArr.push(url);
              } else {
                let url = MediaUrl.completeMediaUrl(
                  item.fileUploadedData.url,
                  true,
                  100,
                  100
                );
                imagesArr.push(url);
              }
            });
            this.setState({
              disputeImages: imagesArr || [],
              disputeVideos: videoArr || [],
            });
          }
        }
      );
    } else {
      message.error(`${t("Error in fetching data")}`);
    }
  };

  componentDidMount() {
    this.getDisputeDetailsByDisputeId();
    this.getAllComments();
  }

  render() {
    const {
      disputedLineItems,
      commentsList,
      comment,
      disputeDetails,
      disputeImages,
      disputeVideos,
    } = this.state;

    const { currentUser, t } = this.props;
    const disputesItems = (
      <>
        {disputedLineItems && disputedLineItems.length > 0 ? (
          disputedLineItems.map((item, index) => {
            let imgurl =
              item.productOrderLineItem &&
              item.productOrderLineItem.mapProductFileUploadedDatas &&
              item.productOrderLineItem.mapProductFileUploadedDatas[0]
                .fileUploadedData &&
              item.productOrderLineItem.mapProductFileUploadedDatas[0]
                .fileUploadedData.url;

            return (
              <div className="profile_card">
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col xl={4} xs={24}>
                        <div className="prodImg_div">
                          <img
                            src={
                              imgurl
                                ? MediaUrl.completeMediaUrl(
                                  imgurl,
                                  true,
                                  100,
                                  100
                                )
                                : myImage
                            }
                            alt="preview"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </Col>
                      <Col xl={20} xs={24}>
                        <div>
                          <div className="font16px mb-3">
                            <b>
                              {item.productOrderLineItem &&
                                item.productOrderLineItem
                                ? item.productOrderLineItem.name
                                : ""}
                            </b>
                          </div>
                          <Row>
                            <Col span={8}>
                              <b className="textcolor">{t('Units')}</b>
                            </Col>
                            <Col span={8}>
                              <b className="textcolor">{t('Price')}/{t('Unit')}</b>
                            </Col>
                            <Col span={8}>
                              <b className="textcolor">
                                {t('Price')}/{t('Unit')} ({t('Including Gst')})
                              </b>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col span={8}>
                              {item.vendorQuantity
                                ? item.vendorQuantity
                                : "N / A"}
                            </Col>
                            <Col span={8}>
                              ₹{" "}
                              {item.vendorPricePerSetWithoutGst
                                ? item.vendorPricePerSetWithoutGst
                                : "N / A"}
                            </Col>
                            <Col span={8}>
                              ₹{" "}
                              {item.vendorPricePerSet
                                ? item.vendorPricePerSet
                                : "N / A"}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })
        ) : disputedLineItems.length === 0 ? (
          <Card className={"no_data_div"}>{t('No dispute exists for this category')}</Card>
        ) : (
          <Skeleton active />
        )}
      </>
    );

    return (
      <>
        <LayoutComponent title={t('Dispute Details')}>
          <div style={{ marginTop: "10px" }}>
            <Row>
              <Col span={24}>
                <div className="heading_div">
                  <h2 className="mb-0">
                    {t('Details')}
                    {/* {disputeDetails.status === "Open"
                      ? `${t(disputeDetails.status)}`
                      : `${t(disputeDetails.status)} ${t('By Akar')}`} */}
                  </h2>
                </div>
              </Col>

              <Col span={24} className="reason_div">
                {/* {disputeDetails.status === "Rejected" ? (
                  <>
                    <p className="font16px mt-2">
                      <b>{t('Reason of rejection')}:</b>
                    </p>
                    <p className="font12px textcolor">
                      Proofs not shared on time
                    </p>
                    <hr />
                  </>
                ) : null} */}

                {/* <p className="font16px mt-2">
                  <b>Qty. Approved</b>
                </p>
                <p className="font12px textcolor">
                  Suitville Blended Party Wear Double Breasted for Men: 1pcs
                </p> */}

                <p className="font16px mt-2">
                  <b>{t('Sold By')}: {disputeDetails.sellerAccountName}</b>{" "}
                </p>
                <p className="font16px mt-2">
                  <b>{t('Order By')}: {disputeDetails.buyerAccountName}</b>
                </p>
                <p className="font12px textcolor">
                  {t('Dispute Id')}: {disputeDetails.disputeCustomId}
                </p>
                <p className="font12px textcolor">
                  {t('Dispute Date')}:{" "}
                  {disputeDetails.createdOn &&
                    moment
                      .utc(disputeDetails.createdOn)
                      .utcOffset("+0530")
                      .format("lll")}
                </p>

                {currentUser.accountId === disputeDetails.buyerAccountId ? (
                  <div>
                    <p className="font12px textcolor">
                      {t("Shipping_Charges")}: ₹ {disputeDetails.shippingCharges} /-
                    </p>
                    <p className="font12px textcolor">
                      {t("Total Amount")}: ₹ {disputeDetails.totalAmount} /-
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="font12px textcolor">
                      {t("Total Amount")}: ₹{" "}
                      {disputeDetails.totalAmount -
                        disputeDetails.shippingCharges}{" "}
                      /-
                    </p>
                  </div>
                )}

                <p>
                  <span className="font12px textcolor">{t('Status')}: </span>
                  <span>{disputeDetails.status === 'AcceptedWithDiscount' ? `${t('Accepted With Discount')}` : `${t(disputeDetails.status)}`}</span>
                </p>
                <hr />
                <p className="font16px">
                  <b>{t("Reason For Dispute")}</b>
                </p>
                <p className="font12px textcolor mb-3">
                  {disputeDetails.disputeReason
                    ? `${t(disputeDetails.disputeReason)}`
                    : ""}
                </p>
              </Col>
              <Col span={24} className="reason_div">
                <div className="heading_div">
                  <h2>{t('Dispute Media')}</h2>
                </div>
                <div>
                  <p className="font16px mt-2">
                    <b>{t('Images')}:</b>
                  </p>
                  <div className="dispute_images">
                    {disputeImages && disputeImages.length > 0
                      ? disputeImages.map((item, imgKey) => {
                        return (
                          <img
                            key={imgKey}
                            src={item ? item : myImage}
                            alt="preview"
                          />
                        );
                      })
                      : null}
                  </div>
                </div>
                <div>
                  <p className="font16px mt-2">
                    <b>{t('Video')}:</b>
                  </p>
                  <div
                    style={{
                      height: "175px",
                      width: "300px",
                      margin: "10px 15px",
                    }}
                  >
                    {disputeVideos.length > 0 ? (
                      <>
                        <video
                          controls
                          autoPlay={false}
                          width="100%"
                          height="100%"
                          src={disputeVideos[0]}
                          alt="preview"
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="heading_div">
                  <h2>{t('Items in Dispute')}</h2>
                </div>
                {disputesItems}
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div>
                  <List
                    loading={this.state.loading}
                    header={<b>{t('Comments')}</b>}
                    bordered={true}
                    itemLayout="horizontal"
                    dataSource={commentsList}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<b>{item.fromName}</b>}
                          description={item.comment}
                        />
                        <div>
                          {item.createdOn &&
                            moment
                              .utc(item.createdOn)
                              .utcOffset("+0530")
                              .format("lll")}
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  placeholder={t("Add Comment")}
                  value={comment}
                  onChange={(val) => {
                    this.setState({ comment: val.target.value });
                  }}
                />
                <div className="button_div">
                  <Button
                    type="primary"
                    onClick={() => {
                      this.createComment();
                    }}
                  >
                    {t("Add Comment")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </LayoutComponent>
      </>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default withTranslation()(connect(mapStateToProps,
  mapDispatchToProps
)(withRouter(DisputeDetailComponent)));
