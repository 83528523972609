import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MediaUrl from "../../service/mediaUrl";
import ProductCard from "../elements/ProductCard";
import SectionLoader from "./sectionLoader";
import { withTranslation } from "react-i18next";
import SearchService from "../../service/searchService";
import Api from "../../service/network";
import { Row, Col, Button } from "antd";
import ComingSoonComponent from "./comingSoonSection"

class RowCardSliderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardData: [],
            cardPostData: [],
            payLoad: {},
        };
    }

    componentDidMount() {
        const { itemData } = this.props;
        if (itemData.callType === "Get") {
            Api.makeGetRequest(itemData.dataUrl, null, null, null).then((res) => {
                let output = res.data.data.data;
                output.forEach((img) => {
                    if (img.imageUrl) {
                        let url = MediaUrl.completeMediaUrl(img.imageUrl, true, 100, 100);
                        img.imageUrl = url;
                    }
                });
                this.setState({ cardData: output });
            });
        }
        if (itemData.callType === "Post") {
            let payLoad = itemData.payLoad;
            let filters;
            try {
                if (typeof payLoad === "string") {
                    filters = JSON.parse(payLoad);
                } else {
                    filters = payLoad;
                }
            } catch (error) {
            }

            SearchService.algoliaProductsearch("", filters).then((data) => {
                this.setState({ cardPostData: data, payLoad: filters });
            });
        }
    }

    goToAllDataPage = () => {
        const { payLoad } = this.state;
        const { itemData } = this.props;
        let parsedPayload = payLoad;
        if (parsedPayload && parsedPayload["hitsPerPage"]) {
            delete parsedPayload["hitsPerPage"];
        }
        let title = encodeURIComponent(itemData.name);
        let encoded = Buffer.from(
            JSON.stringify(parsedPayload),
            "ascii"
        ).toString("base64");
        this.props.history.push({
            pathname: `/search/listings/title=${title}/${encoded}`,
        })
    }

    render() {
        const { cardData, cardPostData } = this.state;
        const { itemData, token, t } = this.props;

        if (itemData && ((cardPostData && cardPostData.length > 0) || cardData)) {
            return (
                <div className="toolbar_navigation-items mt-2">
                    {cardPostData && cardPostData.length > 0 ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                background: "#F4F5F7",
                            }}
                            onClick={() => { this.goToAllDataPage() }}
                        >
                            <div className={"productSectionTitle"}>{itemData.name}</div>
                            <div className={" d-flex align-items-center"}>
                                <p
                                    style={{ textDecoration: "underline" }}
                                    className="pointer_Cursor tab_heading mt-3 mr-3"
                                >
                                    {t("View All")}
                                </p>
                            </div>
                        </div>
                    ) : null}

                    <div
                        style={{
                             marginTop: "15px",
                              display: 'flex', overflowX: 'scroll' }}
                    >
                        {cardData &&
                            cardData.length > 0 &&
                            cardData.map((cardval, cardKey) => {
                                return (
                                    <div style={{ margin: '20px' }} className="colFive" key={cardKey}>
                                        <ProductCard data={cardval} token={token} />
                                    </div>
                                );
                            })}
                        {cardPostData && cardPostData.length > 0
                            ? cardPostData.map((item, index) => {
                                return (
                                    <div style={{ margin: '20px' }} className="colFive" key={index}>
                                        <ProductCard data={item} token={token} />
                                    </div>
                                );
                            })
                            : null}
                    </div>

                </div>
            );
        }

        return <div className="sectionloader"><SectionLoader /></div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        currentUser: global.currentUser,
        token: global.token,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};
export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(RowCardSliderView))
);
