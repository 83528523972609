import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "./layout";
import { Row, Col } from "antd";
import ProductCardsHolder from "./elements/ProductCardsHolder";
import Home1 from "../assests/Home1.png";
import Home2 from "../assests/Home2.png";
import Home3 from "../assests/Home3.png";
import ffs1 from "../assests/ffs1.png";
import ffs2 from "../assests/ffs2.png";
import ffs3 from "../assests/ffs3.png";
import ffs4 from "../assests/ffs4.png";
import afb1 from "../assests/afb1.png";
import afb2 from "../assests/afb2.png";
import afb3 from "../assests/afb3.png";
import afb4 from "../assests/afb4.png";
import cyk1 from "../assests/cyk1.svg";
import cyk2 from "../assests/cyk2.png";
import cyk3 from "../assests/cyk3.svg";
import line from "../assests/line.svg";
import mobleft from "../assests/mobleft.png";
import mobright from "../assests/mobright.png";
import cable from "../assests/cable.png";
import mobileCase from "../assests/mobileCase.png";
import smartSpeaker from "../assests/smartSpeaker.png";
import mobileStand from "../assests/mobileStand.png";
import mobileCharger from "../assests/mobileCharger.png";
import powerBank from "../assests/powerBank.png";
import portableSpeaker from "../assests/portableSpeaker.png";
import headphones from "../assests/headphones.png";
import battery from "../assests/battery.png";
import playstorebadge from "../assests/playstorebadge.png";
import appstoreBadge from "../assests/appstore-badge.png";
import verticalcard from "../assests/verticalcard.png";
import Footer from "./elements/footer";
import ProductCardTabs from "./elements/ProductCardsHolder/ProductCardTabs";
import VerticalImageCard from "./elements/ProductCardsHolder/VerticalImageCard";
import CarouselBanner from "./elements/carousel";
import { DownloadOutlined } from "@ant-design/icons";

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadText: false,
    };
  }

  imageLoadHandler = () => {
    this.setState({ loadText: true });
  };

  componentDidMount() {
    let mobile = typeof window !== 'undefined' ? localStorage.getItem("mobile") : null;
    if (mobile) {
      if (typeof window !== 'undefined')
        localStorage.removeItem("mobile");
    }
  }
  render() {
    const { loadText } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <div className="mt-2"></div>
        <div className=" mb-2 download-app-banner">
          <Row className="fullWidthBanner-simple-row ">
            <Col xs={24}>
              <div className="download-image-mask-container">
                <a
                  className="link-download"
                  href="https://play.google.com/store/apps/details?id=com.akar&hl=en_IN"
                  target={"blank"}
                >
                  <span id={"link-download-text"}>Download the App Now</span>
                  <span>
                    <DownloadOutlined id="home-download-icon" />
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <div className="caraousel-container home-carousel mb-5">
          <CarouselBanner />
        </div>
        <div className="fullWidthBanner-simple">
          <Row className="fullWidthBanner-simple-row">
            <Col xs={24} sm={12}>
              <div className="h-100 py-4 px-5 text-container">
                <p className="small-heading text-blue">
                  Free Registration for First 100 Sellers
                </p>
                <p className="medium-heading text-blue">
                  First Ever B2B Zero Commission Platform
                </p>
                <p className="text-blue">
                  AKAR India provides platform to make your business an online e
                  commerce business. You can sell anywhere throughout the
                  country through our online ecommerce website. It is easy to
                  use. You can start your trading in three easy steps. You can
                  deal with ‘n’ numbers of buyers from all over the country.
                  AKAR never ask for any commission. While trading online, we
                  often worry about payment frauds but here on AKAR India, we
                  assure our registered members of their payments.
                </p>
                <button className="banner-button" onClick={() => {
                  this.props.history.push({
                    pathname: '/login'
                  })
                }}>Shop Now</button>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="text-center image-container">
                <img
                  className="mt-5"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/login",
                      search: ``,
                    });
                  }}
                  style={{ width: "335px" }}
                  onLoad={this.imageLoadHandler}
                  src={Home1}
                  alt="test_banner"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="px-2 m-auto">
          <ProductCardsHolder />
        </div>
        <div className="fullWidthBanner-simple" style={{ background: "white" }}>
          <Row className="fullWidthBanner-simple-row">
            <Col xs={24} sm={12}>
              <div className="h-100 pb-4 px-5 text-container">
                <p className="small-heading text-blue">
                  Free Registration for First 100 Sellers
                </p>
                <p className="medium-heading text-blue">Features For Sellers</p>
                <p className="text-blue">
                  <img width="30px" src={ffs1} alt="icon" /> Make your shop
                  digital
                </p>
                <p className="text-blue">
                  <img width="30px" src={ffs2} alt="icon" /> Bring your shop
                  online
                </p>
                <p className="text-blue">
                  <img width="30px" src={ffs3} alt="icon" /> Deal with multiple
                  buyer from all over india
                </p>
                <p className="text-blue">
                  <img width="30px" src={ffs4} alt="icon" /> Payment assurity
                </p>
                <button className="banner-button" onClick={() => {
                  this.props.history.push({
                    pathname: '/login'
                  })
                }}>Shop Now</button>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="text-center image-container">
                <img
                  className="mt-5"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/login",
                      search: ``,
                    });
                  }}
                  style={{ width: "335px" }}
                  onLoad={this.imageLoadHandler}
                  src={Home2}
                  alt="test_banner"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="fullWidthBanner-simple reverse-responsive my-5">
          <Row className="fullWidthBanner-simple-row">
            <Col xs={24} sm={12}>
              <div className="dual-image-container">
                <img
                  className="left-image"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/login",
                      search: ``,
                    });
                  }}
                  style={{ width: "224.27px", height: "466.99px" }}
                  onLoad={this.imageLoadHandler}
                  src={mobleft}
                  alt="test_banner"
                />
                <img
                  className="right-image"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/login",
                      search: ``,
                    });
                  }}
                  style={{ width: "196.27px", height: "406.99px" }}
                  onLoad={this.imageLoadHandler}
                  src={mobright}
                  alt="test_banner"
                />
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="h-100 py-4 px-5 text-container">
                <p className="small-heading text-blue">
                  Download the Akar app now
                </p>
                <p className="medium-heading text-blue">
                  India’s Largest B2B Wholesale Platform Digitally
                </p>
                {/* <Row>
                  <Col span={12}>
                    <p className="text-blue">
                      <img width="30px" src={ffs1} alt="icon" /> Enter
                      Information
                    </p>
                  </Col>
                  <Col span={12}>
                    <p className="text-blue">
                      <img width="30px" src={ffs1} alt="icon" /> Enter
                      Information
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <p className="text-blue">
                      <img width="30px" src={ffs2} alt="icon" /> Enter
                      Information
                    </p>
                  </Col>
                  <Col span={12}>
                    <p className="text-blue">
                      <img width="30px" src={ffs3} alt="icon" /> Enter
                      Information
                    </p>
                  </Col>
                </Row>

                <p className="text-blue">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here.
                </p> */}
                <a
                  target={"blank"}
                  href="https://play.google.com/store/apps/details?id=com.akar&hl=en_IN"
                >
                  <button className="banner-button android-app-download">
                    <img
                      width={"100%"}
                      height="100%"
                      src={playstorebadge}
                      alt="ivon"
                    />
                  </button>
                </a>
                <a
                  className="ml-5"
                  href="#"
                >
                  <button className="banner-button ios-app-download">
                    <img
                      width={"100%"}
                      height="100%"
                      src={appstoreBadge}
                      alt="ivon"
                    />
                  </button>
                </a>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div className="vertical-image-products-section">
          <Row  className="">
            <Col xs={24} sm={7} className={"VerticalImageCard-container-col"}>
              <VerticalImageCard image={verticalcard} />
            </Col>
            <Col xs={24} sm={{span:16,offset:1}}>
              <ProductCardTabs />
            </Col>
          </Row>
        </div> */}
        <div className="fullWidthBanner-simple my-5">
          <Row className="fullWidthBanner-simple-row">
            <Col xs={24} sm={12}>
              <div className="h-100 py-4 px-5 text-container">
                <p className="small-heading text-blue">
                  Free Registration for First 100 Sellers
                </p>
                <p className="medium-heading text-blue">
                  Advance Features for Buyer
                </p>
                <p className="text-blue">
                  <img width="30px" src={afb1} alt="icon" /> Vast range of
                  variety on your screen
                </p>
                <p className="text-blue">
                  <img width="30px" src={afb2} alt="icon" /> Cash on delivery
                  facility
                </p>
                <p className="text-blue">
                  <img width="30px" src={afb3} alt="icon" /> Door step delivery
                </p>
                <p className="text-blue">
                  <img width="30px" src={afb4} alt="icon" /> Easy returns if
                  goods are not upto mark/damged
                </p>
                <button className="banner-button" onClick={() => {
                  this.props.history.push({
                    pathname: '/login'
                  })
                }}>Shop Now</button>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="text-center image-container">
                <img
                  className="mt-5"
                  onClick={() => {
                    this.props.history.push({
                      pathname: "/login",
                      search: ``,
                    });
                  }}
                  style={{ width: "335px" }}
                  onLoad={this.imageLoadHandler}
                  src={Home3}
                  alt="test_banner"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blue-banner py-4 my-5">
          <Row>
            <Col xs={24} sm={24}>
              <div className="text-center">
                <p className="medium-heading text-white">Complete Your KYC</p>
              </div>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={4} className="text-center">
              <div>
                <div className="">
                  <img src={cyk1} />
                </div>
                <div>
                  <p className="text-white">Create an account</p>
                  <p className="text-white small-text">
                    Register using your mobile number. Enter your Name, Shop name and Pincode.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={4} className="text-center">
              <div className="line-image-container">
                <div>
                  <img src={line} className="line-image" />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={4} className="text-center">
              <div>
                <div className="mb-3">
                  <img src={cyk2} />
                </div>
                <div>
                  <p className="text-white">Complete Shop KYC</p>
                  <p className="text-white small-text ">
                    Upload any one of shop's KYC documents like GSTIN, Shop & Establishment Licence.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={4} className="text-center">
              <div className="line-image-container">
                <div>
                  <img src={line} className="line-image" />
                </div>
              </div>
            </Col>
            <Col xs={24} sm={4} className="text-center">
              <div>
                <div className="mb-3">
                  <img src={cyk3} />
                </div>
                <div>
                  <p className="text-white">Start Ordering</p>
                  <p className="text-white small-text ">
                    Browse and order products for your shop from top sellers & brands.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24}>
              <div className="text-center">
                <button className="banner-button register-button" onClick={() => {
                  this.props.history.push({
                    pathname: '/login'
                  })
                }}>Register</button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="category-banner w-100 my-5">
          <Row justify="center" align="center">
            <Col span={24}>
              <div className="h-100 text-container ">
                <p className="small-heading text-blue text-center">
                  Our Product
                </p>
              </div>
            </Col>
            <Col span={24}>
              <div className="h-100  text-container ">
                <p className="medium-heading text-blue text-center px-2">
                  Explore Our Categories
                </p>
              </div>
            </Col>
            <Row className="mt-2 category-card-row" gutter={[40, 8]}>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/191?categoryId=192&title=Headphones & Earphones`;
                    // this.props.history.push({
                    //   pathname: `/login`,
                    //   search: `?redirect=${redirectUrl}`,
                    // });
                    this.props.history.push({
                      pathname: redirectUrl,
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={headphones} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Headphone & Earphones
                    </p>
                    <p className="text-blue small-text">
                      Boat, Realme, Oppo, Vivo
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/191?categoryId=265&title=Mobile Battery`;
                    this.props.history.push({
                      pathname: redirectUrl,
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={battery} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Mobile Battery
                    </p>
                    <p className="text-blue small-text">
                      Vivo, Intex, Oppo, Jio, Mi, Nokia.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/191?categoryId=246&title=Mobile Cable`;
                    this.props.history.push({
                      pathname: redirectUrl,
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={cable} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Mobile Cable
                    </p>
                    <p className="text-blue small-text">
                      Apple, Oppo, Vivo, Samsung, Realme, Mi, Red Point.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/210?categoryId=214&title=Smart%20Speaker`;
                    this.props.history.push({
                      pathname: redirectUrl
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={smartSpeaker} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Smart Speaker
                    </p>
                    <p className="text-blue small-text">
                      JBL, Realme, Boat.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/191?categoryId=266&title=Mobile Cases`;
                    this.props.history.push({
                      pathname: redirectUrl,
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={mobileCase} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Mobile Cases
                    </p>
                    <p className="text-blue small-text">
                      OnePlus, Motorola, Nokia, Samsung, Mi, Realme, Apple
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/191?categoryId=248&title=Power Banks`;
                    this.props.history.push({
                      pathname: redirectUrl,
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={powerBank} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Power Banks
                    </p>
                    <p className="text-blue small-text">
                      Oppo, Vivo, Samsung, Realme, Mi.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/210?categoryId=249&title=Portable Speakers`;
                    this.props.history.push({
                      pathname: redirectUrl,
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={portableSpeaker} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Portable Speakers
                    </p>
                    <p className="text-blue small-text">
                      Realme, Boat, Jbl.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/191?categoryId=247&title=Mobile Chargers`;
                    this.props.history.push({
                      pathname: redirectUrl,
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={mobileCharger} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Mobile Chargers
                    </p>
                    <p className="text-blue small-text">
                      Mi, Red point, Realme.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={8} className="category-card-col">
                <div
                  className="category-card-container"
                  onClick={() => {
                    let redirectUrl = `subcategory/191?categoryId=199&title=Mobile Stand & Holder`;
                    this.props.history.push({
                      pathname: redirectUrl,
                    });
                  }}
                >
                  <div className="category-card-image-container">
                    <img src={mobileStand} />
                  </div>
                  <div className="category-card-info-container">
                    <p className="text-blue mb-1" style={{ fontSize: "14px" }}>
                      Mobile Stand & Holder
                    </p>
                    <p className="text-blue small-text">
                      Red Point, Shivu & more
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
        </div>
        <Footer />
      </Layout>
    );
  }
}

export default withRouter(HomeComponent);
