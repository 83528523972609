import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import myimage from '../../imageNotFound.jpeg'
import Api from '../../service/network'
import MediaUrl from '../../service/mediaUrl'


import SectionLoader from './sectionLoader'

class GridView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridData: []
        }
    }
    componentDidMount() {
        const { itemData } = this.props

        if (itemData.callType === 'Get') {
            Api.makeGetRequest(itemData.dataUrl).then((res) => {
                let output = res.data.data.data
                output.forEach(item => {
                    if (item.imageUrl) {
                        let url = MediaUrl.completeMediaUrl(item.imageUrl, true, 100, 100)
                        item.imageUrl = url
                    }
                })
                this.setState({ gridData: output })
            })
        }
    }

    render() {
        const { gridData } = this.state
        const { itemData } = this.props

        return (<>{itemData ? <div className="toolbar_navigation-items mt-2 slider_div">
            <h4 className={'mb-3'}>{itemData.name}</h4>
            <ul className='product_div' >
                {gridData && gridData.length > 0 && gridData.map((gridVal, gridkey) => {
                    return (<li key={gridkey}>
                        <img src={myimage} alt='banner' />
                        <p className='label'>{gridVal.title}</p>
                        <h5>{gridVal ? gridVal.price : '₹ 1234'}</h5>
                    </li>)
                })}

            </ul>

        </div> : <div className="sectionloader">
                <SectionLoader />
            </div>}
        </>)

    }
}
export default withRouter(GridView)
