import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from 'moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { Row, Col, message, Modal, Button, Checkbox, Input, Card, Image, Dropdown, Menu } from 'antd';
import { FileAddOutlined, CloseCircleOutlined, CloseOutlined, DownOutlined, DashOutlined } from '@ant-design/icons';
import LayoutComponent from '../layoutComponents/layoutComponent';
import SaveListing from '../../service/saveListingService';
import BrandService from '../../service/brandService';
import AddListingService from '../../service/addListingService'
import MediaUrl from '../../service/mediaUrl'
import LoaderComponent from "./../loader";


class CreateListComponent extends Component {

    myInput;
    productID;

    constructor(props) {
        super(props);
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            imageFiles: [],
            images: [],
            allColors: {},
            listingStatus: {},
            savingProduct: false,
            allSizeOpt: [],
            visible: false,
            imageToDeleteIndex: '',
            needToRedirect: false,
            allModels: [],
            isEditingSetSelection: false,
            editSetVisible: false,
            isSetsComparisonScreen: false,
            product: {},
            pricePerPieceWithoutCommission: '',
            pricePerPieceModal: '',
            priceCommission: {},
            loading: false,
            bulkSetIndex: null,
            buyerPricePerPieceModal: false,
            buyerPriceCommission: {},
            buyerPricePerPieceWithoutCommission: "",
            bulkPrice: [{ set: null, price: null, buyerPrice: null }],
            buyerPricePerPiece: 0,
            showLoader: false,
            showContinueEditBtn: false
        }
    }

    componentDidMount() {
        const isClone = SaveListing.isClone
        if (SaveListing.isBrandSelected) {
            console.log(SaveListing.isBrandSelected)
        }
        console.log(SaveListing.brand)
        if (isClone) {
            const id = SaveListing.productID
            this.getProductDetails(id)
            SaveListing.productID = ''
            SaveListing.isClone = false
        } else {
            let id = this.props.match.params.id
            if (id) {
                this.productID = id
                SaveListing.productID = id
                if (typeof window !== 'undefined') localStorage.setItem('id', id)

                if (!SaveListing.isDataStoredFromApi || SaveListing.needToProductCall) {
                    this.getProductDetails(id)
                }
            } else {
                if (SaveListing.mapCategoryId === "") {
                    this.setState({ needToRedirect: true })
                }
            }

            if (SaveListing.productImages.length) {
                this.setState({ imageFiles: SaveListing.productImages })
            }
        }
    }


    getProductDetailsForClone = (id) => {
        //DO as getProductDetails
    }

    getEmergencyFoundImg = urlImg => {
        urlImg = "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
        let self = this
        var xhr = new XMLHttpRequest();
        xhr.open("GET", urlImg, true);
        xhr.responseType = "blob";
        xhr.onload = function (e) {
            var reader = new FileReader();
            reader.onload = function (event) {
                var res = event.target.result;
                const b = res.split(',')
                let selectedFile = b[1]
                const fileExtn = '.jpg'
                if (selectedFile) {
                    let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
                    selectedFile = new File([blob], moment().valueOf() + fileExtn, { type: selectedFile.type });
                    var url = URL.createObjectURL(selectedFile)
                    self.addImageInAWS(selectedFile, url)
                } else {
                    return message.error('some')
                }
            }
            var file = this.response;
            reader.readAsDataURL(file)
        };
        xhr.send()
    };

    getProductDetails = async (id) => {
        const { currentUser } = this.props
        const isClone = SaveListing.isClone
        this.setState({ showLoader: true })
        AddListingService.getProduct(id)
            .then((res) => {

                let data = res.data.data
                console.log(data,"getProductDetails")
                this.setState({ product: data })
                if (currentUser && currentUser.accountId === data.accountId) {
                    SaveListing.isDataStoredFromApi = true
                    SaveListing.isSetCreated = true
                    SaveListing.isProductDetailAdded = true
                    SaveListing.selectProductSetID = data.mapProductSets[0].id
                    SaveListing.title = data.name
                    SaveListing['newBulkPrice'] = [{ set: null, price: null, buyerPrice: null }]

                    let additionDetails = {
                        gstRate: data.gstId,
                        expiry: data.expiryDays,
                        minOrderQuanitity: data.minimumOrderQuantity,
                        description: data.description,
                        includeGst: data.isGSTIncluded,
                    }
                    SaveListing.needToProductCall = false
                    SaveListing.additionDetails = additionDetails
                    SaveListing.mapCategoryId = data.mapCategoryId

                    this.getCategoryAtrribute(data.mapCategoryId, data)

                    SaveListing.isBrandSelected = true
                    SaveListing.brand = res.data.data.mapBrandAccount
                    let imageFiles = []
                    res.data.data.mapProductFileUploadedDatas.forEach(item => {
                        item.fileUploadedData['type'] = item.type
                        let newUrl = ''
                        if (isClone) {
                            AddListingService.cloneMedia(item.fileUploadedData.url).then(res => {
                                newUrl = MediaUrl.completeMediaUrl(res.data.data.destinationFile.url, true, 100, 100)
                                item.fileUploadedData.url = newUrl
                                imageFiles.push(item.fileUploadedData)
                            })
                        } else {
                            newUrl = MediaUrl.completeMediaUrl(item.fileUploadedData.url, true, 100, 100)
                            item.fileUploadedData.url = newUrl
                            imageFiles.push(item.fileUploadedData)
                        }
                    })

                    this.setState({ imageFiles: imageFiles })
                    SaveListing.productImages = imageFiles;
                }
            }, err => { })
    }

    getCategoryAtrribute = (id, data) => {
        AddListingService.getCategoryAtrribute(id)
            .then((res) => {

                let mapProductAttributes = []
                let size = []
                let models = [];
                res.data.data.forEach(item => {

                    if (item.parentAttribute.isProductAttribute === true) {
                        let options = []
                        item.parentAttribute.mapAttributes.forEach(element => {
                            if (element.childAttribute) {
                                options.push(element.childAttribute.value)
                            }
                        })
                        item['options'] = options
                        item['optionsSaved'] = options
                        item['selectedList'] = []
                        mapProductAttributes.push(item)
                    } else {
                        if (item.parentAttribute.type === 'SIZE') {
                            size.push(item)
                        } else if (item.parentAttribute.type === 'COLOR') {
                            item.parentAttribute.mapAttributes.forEach(clr => {
                                clr['images'] = []
                            })
                            this.setState({ allColors: { ...item } })
                        } else if (item.parentAttribute.type === 'COMPATIBLE_DEVICES') {
                            models.push(item);
                        }
                    }
                })

                this.setState({ allSizeOpt: [...size], allModels: models })

                //CREATE SET
                SaveListing.mapProductSets = []
                data.mapProductSets.forEach((item) => {
                    this.createProductSet(item)
                })

                data.mapProductAttributes.forEach((item) => {
                    mapProductAttributes.forEach((attribute) => {
                        if (item.mapAttribute.parentAttribute.key === attribute.parentAttribute.key) {
                            attribute.selectedList.push(item.mapAttribute.childAttribute.value)
                        }
                    })
                })
                SaveListing.mapProductAttributes = mapProductAttributes

                let productAttribute = []
                res.data.data.forEach(item => {
                    if (item.parentAttribute.isProductAttribute === true) {
                        productAttribute.push(item)
                    }
                })
                this.setState({ productAttribute: productAttribute, showLoader: false })
            }, err => { })
    }

    convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item
            };
        }, initialValue);
    }

    createProductSet = (item) => {
        let obj = {}
        let { allColors } = this.state
        let myColor = { ...allColors }
        let { allSizeOpt } = this.state
        let mySize = allSizeOpt;
        let { allModels } = this.state;
        let mapProductSetBulkPrices = [];
        if (item.mapProductSetBulkPrices) {
            item.mapProductSetBulkPrices.forEach(set => {
                mapProductSetBulkPrices.push({
                    set: set.minimumOrderQuantity,
                    //price: set.pricePerPiece
                    price: set.pricePerPieceWithGST
                })
            })
        } else {
            mapProductSetBulkPrices.push({ set: null, price: null })
        }
        obj['isAvailable'] = item.isAvailable
        obj['mapProductSetBulkPrices'] = mapProductSetBulkPrices

        obj['minimumSetOrder'] = item.minimumSetOrder
        //obj['pricePerPiece'] = item.pricePerPiece
        obj['pricePerPiece'] = item.pricePerPieceWithGST
        obj['mrpPerPiece'] = item.mrpPerPiece
        //For Edit
        obj['newMinimumSetOrder'] = item.minimumSetOrder
        obj['newPricePerPiece'] = item.pricePerPiece
        obj['newPrpPerPiece'] = item.mrpPerPiece

        obj['availableQuantity'] = item.availableQuantity
        obj['sku'] = item.sku;
        obj['allSizes'] = mySize;
        obj['allModels'] = allModels;

        let selectedSize = [];
        let selectedColor = [];
        let selectedModel = {};

        let allSizes = JSON.parse(JSON.stringify(mySize)) || [];
        item.mapProductSetAttributes.forEach((attribute, i) => {
            if (attribute.mapAttribute.parentAttribute.type === 'COLOR') {
                selectedColor.push(attribute);
                // if (i === 0) {
                attribute.mapProductSetAttributeDetails.forEach(itm => {
                    selectedSize.push(itm);
                })
                // }
            }
            else if (attribute.mapAttribute.parentAttribute.type === 'SIZE') {
                selectedSize.push(attribute);
            }
            else if (attribute.mapAttribute.parentAttribute.type === 'COMPATIBLE_DEVICES') {
                selectedModel = attribute;
            }
        })
        let sizeOptType = ''
        selectedSize.forEach(sizeOpt => {
            if (sizeOpt.mapAttribute.childAttribute.type) {
                sizeOptType = sizeOpt.mapAttribute.childAttribute.type;
            }
        })

        let selectedSizeSet = {}
        allSizes.forEach(siz => {
            if (siz.parentAttribute.key === sizeOptType) {
                selectedSizeSet = siz;
            }
        })

        selectedSize.forEach(element => {
            if (selectedSizeSet && selectedSizeSet.parentAttribute) {
                selectedSizeSet.parentAttribute.mapAttributes.forEach(sElem => {
                    if (element && element.mapAttribute.childAttribute.key === sElem.childAttribute.key) {
                        sElem['selected'] = true;
                    }
                })
            }
        })

        let modelParentKey = selectedModel?.mapAttribute?.parentAttribute?.key;
        let modelChildKey = selectedModel?.mapAttribute?.childAttribute?.key;

        let selectedModelSet = {};
        let newModels = JSON.parse(JSON.stringify(allModels)) || [];
        newModels.forEach((model, i) => {
            if (model.parentAttribute.key === modelParentKey) {
                model['selected'] = true;
                model.parentAttribute.mapAttributes.forEach((mAttr, mi) => {
                    if (mAttr.childAttribute.key === modelChildKey) {
                        mAttr["selected"] = true;
                    }
                })
                selectedModelSet = model;
            }
        });

        let selectedModelSetObj = {}
        if (Object.keys(selectedModelSet).length) {
            selectedModelSetObj = this.convertArrayToObject([selectedModelSet], "id");
        }

        obj['selectedSizeSet'] = selectedSizeSet.parentAttribute;
        obj['selectedModelSet'] = selectedModelSetObj;
        obj['colorsCount'] = selectedColor.length;
        let color = JSON.parse(JSON.stringify(myColor));
        selectedColor.forEach(atr => {
            let size = {}
            if (selectedSizeSet.parentAttribute) {
                size = JSON.parse(JSON.stringify(selectedSizeSet.parentAttribute))
            }

            let images = []
            atr.mapProductSetAttributeDetails.forEach(attr => {
                if (!attr.mapAttributeId) {
                    images.push(attr.fileUploadedData)
                }
            })

            atr.mapProductSetAttributeDetails.forEach((sizAttr) => {
                if (size && size.mapAttributes) {
                    size.mapAttributes.forEach(siz => {
                        if (sizAttr.mapAttribute.childAttribute.key === siz.childAttribute.key) {
                            siz['quantity'] = sizAttr.quantity
                        }
                    })
                }
            })


            if (color && color.parentAttribute) {
                color.parentAttribute.mapAttributes.forEach(clr => {
                    if (clr.childAttribute.key === atr.mapAttribute.childAttribute.key) {
                        clr['selected'] = true
                        clr['sizes'] = size
                        clr['allModels'] = newModels
                        if (images.length) {
                            clr['images'] = images
                        }
                    }
                })
            }
        })

        obj['colorWithSize'] = color

        if (!SaveListing.isClone) {
            obj['id'] = item.id
        }
        SaveListing.mapProductSets.push(obj)
    }

    onChange(e) {
        const { t } = this.props
        let selectedFile = e.target.files[0]
        const fileExtn = selectedFile.name.substr(selectedFile.name.lastIndexOf("."))

        if ((fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png') && selectedFile) {
            // SaveListing.isCoverImageAdded = true
            let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
            selectedFile = new File([blob], moment().valueOf() + fileExtn, { type: selectedFile.type });
            var url = URL.createObjectURL(selectedFile)
            this.addImageInAWS(selectedFile, url)
        } else {
            return message.error(`${t('Attach image in png/jpg/jpeg file format')}`)
        }
        document.getElementById('myInput').value = null
    }

    deleteImage = (index) => {
        let { imageFiles, images } = this.state

        if (SaveListing.mapProductSets.length > 0) {

            let productSets = SaveListing.mapProductSets
            productSets.forEach((item, key) => {
                console.log(item, "itemmain")
                if (item.colorWithSize.parentAttribute.mapAttributes.length > 0) {
                    item.colorWithSize.parentAttribute.mapAttributes.forEach((val, key1) => {
                        if (val.selected && val.images.length) {
                            let found = _.find(val.images, (imgVal, key3) => {
                                return imageFiles[index].url === imgVal.url
                            })
                            if (found) {
                                val.images = val.images.filter(item => item.url !== found.url)
                            }
                        }
                    })
                }
            })
            SaveListing.mapProductSets = productSets
        }

        images.push(imageFiles[index])
        imageFiles.splice(index, 1);
        SaveListing.productImages = imageFiles;

        this.setState({ imageFiles, visible: false })

        if (this.state.imageFiles.length === 0) {
            // SaveListing.isCoverImageAdded = true
        }
    }

    setCoverImage = (index) => {
        let { imageFiles } = this.state
        imageFiles.map((item, ikey) => {
            if (ikey === index) {
                item.type = 'ProductCoverImage'
            } else {
                item.type = 'ProductImage'
            }
            return item
        })
        SaveListing.productImages = imageFiles

        this.setState({
            visible: false, imageFiles
        });
    }

    addImageInAWS = (data, url) => {
        BrandService.addImage(data, 'PRODUCT_DISPLAY_FILE')
            .then((res) => {
                let { imageFiles } = this.state
                let data = res.data.data[0]
                data['localImageurl'] = url
                data['type'] = imageFiles.length === 0 ? 'ProductCoverImage' : 'ProductImage'

                let newUrl = MediaUrl.completeMediaUrl(data.url, true, 100, 100)
                data.url = newUrl

                imageFiles.push(data)

                this.setState({ imageFiles: imageFiles })
                SaveListing.productImages = imageFiles
            }, err => { })
    }

    deleteImageFromAWS = (data) => {
        BrandService.deleteImage(data)
            .then((res) => {
                //console.log(res)
            }, err => {
                //console.log(err)
            })
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    navigateCreateSet = () => {
        if ((this.state.imageFiles.length !== 0)) {
            if (SaveListing.mapProductSets.length) {
                SaveListing.isNewSet = false
                this.navigate('/add-edit-set')
            } else {
                SaveListing.isNewSet = true
                this.navigate('/create-sets')
            }
        }
    }

    navigateProductDetail = () => {
        if ((this.state.imageFiles.length !== 0)) {
            this.navigate('/product-detail')
        }
    }

    navigateAdditionalDetail = () => {
        if (SaveListing.isProductDetailAdded) {
            this.navigate('/additional-detail')
        }
    }

    createProductList = async () => {
        const { t } = this.props
        if (SaveListing.additionDetails.gstRate) {
            let tempObj = SaveListing;
            let data = { ...tempObj };
            let images = JSON.parse(JSON.stringify(data.productImages));
            let mapProductFileUploadedDatas = []
            let deletedFileUploadedDatas = []

            console.log(data)
            images.push(data.productVideo)
            images.forEach((item, i) => {

                let url = MediaUrl.trimMediaUrl(item.url)
                item.url = url

                if (item.type === 'ProductCoverImage') {
                    mapProductFileUploadedDatas.push(
                        {
                            type: 'ProductCoverImage',
                            fileUploadedData: {
                                name: item.fileName,
                                url: item.url,
                                type: 'PRODUCT_IMAGE'
                            }
                        }
                    )
                }

                else if (item.type === 'ProductVideo') {
                    mapProductFileUploadedDatas.push(
                        {
                            type: 'ProductVideo',
                            fileUploadedData: {
                                name: item.fileName,
                                url: item.url,
                                type: 'PRODUCT_VIDEO'
                            }
                        }
                    )
                } else if (item.type === 'ProductImage') {
                    mapProductFileUploadedDatas.push(
                        {
                            type: 'ProductImage',
                            fileUploadedData: {
                                name: item.fileName,
                                url: item.url,
                                type: 'PRODUCT_IMAGE'
                            }
                        }
                    )
                }
            })

            this.state.images && this.state.images.length > 0 && this.state.images.forEach((item, delKey) => {
                let url = MediaUrl.trimMediaUrl(item.url)
                item.url = url
                deletedFileUploadedDatas.push({ url: item.url })
            })

            let mapProductAttributes = []
            let name = data.brand.brand.name + " ";

            SaveListing.mapProductAttributes.forEach(item => {
                let slectedMapAttributes = []
                let newAttribute = []
                item.selectedList.forEach((element, i) => {
                    item.parentAttribute.mapAttributes.forEach(attribute => {
                        if (attribute.childAttribute) {
                            if (element === attribute.childAttribute.value) {
                                slectedMapAttributes.push(attribute)
                                newAttribute.push(element)
                            }
                        }
                    })
                })


                if (newAttribute.length !== item.selectedList.length) {
                    var onlyNewAttribute = item.selectedList.filter(function (obj) { return newAttribute.indexOf(obj) == -1; });
                    onlyNewAttribute.forEach(selectedAttribute => {
                        mapProductAttributes.push({
                            mapAttributeId: 0,
                            mapAttribute: {
                                parentAttributeId: item.parentAttribute.id,
                                childAttributeId: 0,
                                childAttribute: {
                                    key: selectedAttribute.toUpperCase(),
                                    value: selectedAttribute,
                                    type: item.parentAttribute.type
                                }
                            }
                        })
                    })
                }

                if (slectedMapAttributes.length) {
                    slectedMapAttributes.forEach(selectedAttribute => {
                        if (selectedAttribute.childAttribute.type === "TOP_FABRIC" || selectedAttribute.childAttribute.type === "TOP_PATTERN" || selectedAttribute.childAttribute.type === "TOP_SHAPE" || selectedAttribute.childAttribute.type === "IDEAL_FOR") {
                            name = name + selectedAttribute.childAttribute.value + " "
                        }
                        mapProductAttributes.push({
                            mapAttributeId: selectedAttribute.id,
                            mapAttribute: null
                        })
                    })
                }
            })

            console.log(data.title)
            console.log(name)
            // let title =
            let obj = {
                mapBrandAccountId: SaveListing.brand.id,
                mapCategoryId: SaveListing.mapCategoryId,
                mapProductAttributes: mapProductAttributes
            }
            let { data: response } = await AddListingService.produceProductTitle(obj)

            let title = data.title ? data.title : name
            if (response.isSuccess) {
                title = response.data.productTitle
                console.log(title)
            }

            let dataToPost = {
                name: title,
                gstId: data.additionDetails.gstRate,
                mapCategoryId: data.mapCategoryId,
                isGSTIncluded: true,
                expiryDays: Number(data.additionDetails.expiry),
                minimumOrderQuantity: Number(data.additionDetails.minOrderQuanitity),
                description: data.additionDetails.description,
                // SaveListing.brand.isNewBrand ? SaveListing.brand.id : SaveListing.brand.brand.id
                mapBrandAccountId: data.brand.id,
                mapProductAttributes: mapProductAttributes,
                mapProductFileUploadedDatas: mapProductFileUploadedDatas,
                deletedFileUploadedDatas: deletedFileUploadedDatas
            }

            let id = this.props.match.params.id
            if (id) {
                this.setState({ savingProduct: true });
                AddListingService.updateProduct(dataToPost, id)
                    .then((res) => {
                        this.setState({ savingProduct: false });
                        if (this.state.images.length) {
                            this.state.images.forEach(img => {
                                this.deleteImageFromAWS(img.url)
                            })
                        }
                        message.success(`${t('Product updated successfully')}`);
                        this.navigate('/manage-listings')
                    }, err => {
                        this.setState({ savingProduct: false });
                        this.navigate('/manage-listings')
                        data.productImages = this.state.imageFiles
                        message.error(err.data.message);
                    })
            } else {
                let mapProductSets = []
                data.mapProductSets.forEach(item => {

                    let obj = {}
                    if (item.minimumSetOrder) {
                        obj['minimumSetOrder'] = Number(item.minimumSetOrder)
                    }
                    if (item.pricePerPiece) {
                        let price = item.pricePerPiece
                        obj['pricePerPiece'] = Number(price)
                    }
                    if (item.mrpPerPiece) {
                        let price = item.mrpPerPiece
                        obj['mrpPerPiece'] = Number(price)
                    }
                    if (item.availableQuantity) {
                        obj['availableQuantity'] = Number(item.availableQuantity)
                    }
                    let mapProductSetBulkPrices = []
                    item.mapProductSetBulkPrices.forEach(bulkSet => {
                        if (bulkSet.set !== null && bulkSet.price !== null) {
                            let price = bulkSet.price

                            mapProductSetBulkPrices.push({
                                'minimumOrderQuantity': Number(bulkSet.set),
                                'pricePerPiece': Number(price)
                            })
                        }
                    })

                    if (mapProductSetBulkPrices.length > 0) {
                        obj['mapProductSetBulkPrices'] = mapProductSetBulkPrices
                    }
                    let mapProductSetAttributes = []
                    let mySelectedSize = []
                    let mapSetMultipleSelectableAttributes = []
                    let selectedModelSetObj = {}

                    for (let modelId in item.selectedModelSet) {
                        selectedModelSetObj = item.selectedModelSet[modelId];
                        selectedModelSetObj && selectedModelSetObj.parentAttribute && selectedModelSetObj.parentAttribute.mapAttributes && selectedModelSetObj.parentAttribute.mapAttributes.forEach(modalSet => {
                            if (modalSet.selected) {
                                mapSetMultipleSelectableAttributes.push(modalSet)
                            }
                        })
                    }


                    item.colorWithSize.parentAttribute.mapAttributes.forEach(clr => {
                        if (clr.selected) {
                            let mapProductSetAttributeDetails = []
                            mySelectedSize = clr.sizes

                            if (clr.images.length) {

                                clr.images.forEach((itemClr, key) => {
                                    let url = MediaUrl.trimMediaUrl(itemClr.url)
                                    mapProductSetAttributeDetails.push({
                                        mapAttributeId: null,
                                        fileUploadedData: { name: itemClr.fileName, url },
                                        quantity: 0
                                    })
                                })

                            }

                            clr.sizes.mapAttributes.forEach(element => {
                                if (element.selected) {

                                    mapProductSetAttributeDetails.push({
                                        mapAttributeId: element.id,
                                        fileUploadedData: null,
                                        quantity: Number(element.quantity)
                                    })
                                }
                            })
                            mapProductSetAttributes.push({
                                mapAttributeId: clr.id,
                                mapProductSetAttributeDetails: mapProductSetAttributeDetails
                            })
                        }
                    })

                    mySelectedSize?.mapAttribute && mySelectedSize.mapAttributes.forEach(size => {
                        if (size.selected) {
                            mapProductSetAttributes.push({
                                mapAttributeId: size.id,
                                mapProductSetAttributeDetails: null
                            })
                        }
                    })
                    obj['mapProductSetAttributes'] = mapProductSetAttributes
                    if (mapSetMultipleSelectableAttributes.length) {
                        obj['mapSetMultipleSelectableAttributes'] = mapSetMultipleSelectableAttributes
                    }
                    mapProductSets.push(obj)
                })

                dataToPost['mapProductSets'] = mapProductSets
                // dataToPost['mapSetMultipleSelectableAttributes'] = SaveListing.mapSetMultipleSelectableAttributes || [];

                this.setState({ savingProduct: true });

                AddListingService.createProductList(dataToPost)
                    .then((res) => {
                        this.setState({ savingProduct: false });
                        if (this.state.images.length) {
                            this.state.images.forEach(img => {
                                let url = MediaUrl.trimMediaUrl(img.url)
                                this.deleteImageFromAWS(url)
                            })
                        }

                        message.success(`${t('Product added successfully')}`);
                        this.navigate('/manage-listings')

                    }, err => {
                        this.setState({ savingProduct: false });
                        data.productImages = this.state.imageFiles
                        if (err.data && err.data.message) {
                            message.error(err.data.message);
                            this.navigate('/manage-listings')
                        }
                        else {
                            message.error(`${t('Some error occured')}`);
                            this.navigate('/manage-listings')
                        }
                    })
            }
        } else {
            message.error(`${t('Please fill all required data')}`)
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleOk = () => {
        this.setState({
            visible: false,
        });
    };
    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    renderModelName = (data) => {
        const { t } = this.props
        const { allModels } = this.state;
        const { selectedModelSet } = data;
        let selectedModelSetLenght = 0;
        if (typeof (selectedModelSet) == 'object') {
            selectedModelSetLenght = Object.keys(selectedModelSet);
        }
        if (allModels.length || selectedModelSetLenght) {
            let name = [];
            for (var item in selectedModelSet) {
                selectedModelSet[item].parentAttribute.mapAttributes.forEach(attr => {
                    if (attr.selected) {
                        name.push(attr.childAttribute.value)
                    }
                })
            }
            if (name.length) {
                return <p className={'ml-1'}>{t('Compatible Device')}: {name.join(", ")} <span>|</span></p>
            } else {
                return null
            }
        }
        return null;
    }

    renderSize = (item) => {
        let arr = []
        const { t } = this.props
        item && item.mapAttributes && item.mapAttributes.length > 0 && item.mapAttributes.forEach((size, key) => {
            if (size.selected) {
                arr.push(size.childAttribute.value)
            }
        })
        return arr.length ? <p className={'ml-1'}>{t('Size')} - {`${arr.join(", ")} `}<span >|</span></p> : null
    }

    selectSets = (value, index) => {
        SaveListing.mapProductSets[index]['selectedForEdit'] = value
        let selectedCount = 0
        SaveListing.mapProductSets.forEach(item => {
            if (item.selectedForEdit) {
                selectedCount = selectedCount + 1
            }
        })
        if (selectedCount > 1) {
            this.setState({ showContinueEditBtn: true })
        } else {
            this.setState({ showContinueEditBtn: false })
        }
    }

    saveEditedSets = () => {

        var mapProductSets = []
        const { product, bulkPrice } = this.state
        let bp = []
        bulkPrice.forEach(item => {
            if (item.set != null && item.price != null) {
                bp.push({ 'minimumOrderQuantity': item.set, 'pricePerPiece': item.price })
            }
        })
        console.log(SaveListing,"SaveListing")

        SaveListing.mapProductSets.forEach((item, index) => {
            console.log(item,"itemReturn")
            if (item.selectedForEdit) {
                const obj = {
                    "id": item.id,
                    "minimumSetOrder": Number(SaveListing.newMinimumSetOrder ?? item.minimumSetOrder),
                    "pricePerPiece": Number(SaveListing.newPricePerPiece ?? item.pricePerPiece),
                    "mrpPerPiece": Number(SaveListing.newMrpPerPiece ?? item.mrpPerPiece),
                    "mapProductSetBulkPrices": (bp.length) ? bp : product.mapProductSets[index].mapProductSetBulkPrices,
                    "mapProductSetAttributes":product.mapProductSets ? product.mapProductSets[index].mapProductSetAttributes:"null",
                    "addedFileUploadedDatas": product.mapProductSets[index] .addedFileUploadedDatas
                }

                mapProductSets.push(obj)
            }
        })

        const dataToSend = {
            "MapProductSets": mapProductSets
        }
        console.log(dataToSend,"dataToSend")

        AddListingService.updateProductSets(this.productID, dataToSend)
            .then((res) => {
                this.resetValues()
                if (res.status !== 200) {
                    message.error(
                        'Something is wrong, Please try again later'
                    );
                } else {
                    let id = this.props.match.params.id
                    this.getProductDetails(id)
                }
            }, (err) => {
                this.resetValues()
            })


    }

    resetValues() {
        this.setState({ editSetsVisible: false, isSetsComparisonScreen: false, showContinueEditBtn: false })
        SaveListing.newPricePerPiece = null
        SaveListing.newMinimumSetOrder = null
        SaveListing.newPricePerPiece = null
        SaveListing.newMrpPerPiece = null
        this.setState({ bulkPrice: [{ set: null, price: null, buyerPrice: null }] })
    }

    calculateCommissionPrice = async () => {
        let { pricePerPieceWithoutCommission } = this.state;
        this.setState({ loading: true });
        let { data: priceResp } = await AddListingService.getProductCommission(
            SaveListing.mapCategoryId,
            pricePerPieceWithoutCommission
        );
        if (priceResp.isSuccess) {
            this.setState({ priceCommission: priceResp.data, loading: false });
        }
    };

    setsView(item, i) {
        const { t } = this.props
        return (
            <div key={i} className='listing_set_data'>
                <strong>{t('Set')} {i + 1}:</strong>
                {this.renderSize(item.selectedSizeSet)}

                <p className={'ml-1'}>{t('Colors')}:{item.colorWithSize.parentAttribute && item.colorWithSize.parentAttribute.mapAttributes.map((color, cIndex) => {
                    return (<span key={cIndex}>
                        {color.selected ?
                            (color.childAttribute.value === 'Multicolor' ?

                                <span key={`color${cIndex}`}
                                    style={{
                                        display: 'inline-block', height: 12, width: 12,
                                        backgroundSize: 'contain',
                                        backgroundImage: 'url(https://akar-prod-media.s3.ap-south-1.amazonaws.com/StaticAssets/color-circle.png)', margin: '0px 4px'
                                    }}>
                                </span> : <span key={`color${cIndex}`}
                                    style={{ display: 'inline-block', height: 12, width: 12, backgroundColor: color.childAttribute.value, margin: '0px 4px' }}>
                                </span>)
                            : ''
                        }
                    </span>)
                }
                )}
                    <span>|</span>
                </p>
                <p className={'ml-1'}>{t('Price')}: ₹{item.pricePerPiece} <span>|</span></p>
                {item.mapProductSetBulkPrices.length ? <p className={'ml-1'}>{t('Bulk Prices')} <span>|</span></p> : ''}
                {item && typeof (item.selectedModelSet['undefined']) !== 'object' ? this.renderModelName(item) : null}
                <p className={'ml-1'}> MOQ- {item.minimumSetOrder}</p>

            </div>
        )
    }

    //bulkPrice Checks

    setBulkSet = (e, i) => {
        if (e.target.value > 0 || e.target.value === "") {
            let bulkPrice = this.state.bulkPrice;
            if (e.target.value === "") {
                bulkPrice[i].set = null;
            } else {
                bulkPrice[i].set = Number(e.target.value);
            }
            this.setState(bulkPrice);
        }
    };

    bulkSetInd = (i) => {
        let _i = Number(i);
        this.setState({ bulkSetIndex: _i });
    };

    setPricePerPieceWithoutCommission = (e) => {
        if (e.target.value > 0 || e.target.value === "") {
            this.setState(
                { pricePerPieceWithoutCommission: e.target.value, priceCommission: {} }
            );
        }
    };

    setBulkSetPrice = (i) => {
        let { buyerPricePerPieceWithoutCommission } = this.state;

        if (
            buyerPricePerPieceWithoutCommission > 0 ||
            buyerPricePerPieceWithoutCommission === ""
        ) {
            let bulkPrice = this.state.bulkPrice;
            if (buyerPricePerPieceWithoutCommission === "") {
                // here buyerprice and price have been interchanged
                bulkPrice[i].buyerPrice = null;
            } else {
                bulkPrice[i].buyerPrice = Number(buyerPricePerPieceWithoutCommission);
            }
            this.setState(bulkPrice);
        }
    };

    addNewPriceSet = () => {
        const { t } = this.props
        let bulkPrice = this.state.bulkPrice;
        let minimumSetOrder = SaveListing.newMinimumSetOrder;
        if (!minimumSetOrder) {
            message.error('Please add Minimum order quantity');
            return;
        }
        let isSetValid = this.checkBulkSetWithMOQ();

        if (isSetValid) {
            bulkPrice.push({ set: null, price: null, buyerPrice: null });
            this.setState(bulkPrice);
        } else {
            message.error(`${t("Invalid set")}`);
        }
    };

    setBuyerPricePerPieceWithoutCommission = (e) => {
        if (e.target.value > 0 || e.target.value === "") {
            this.setState(
                {
                    buyerPricePerPieceWithoutCommission: e.target.value,
                    buyerPriceCommission: {},
                }
            );
        }
    };

    deletePriceSet = (index) => {
        let bulkPrice = this.state.bulkPrice;
        if (bulkPrice.length === 1) {
            bulkPrice[0].price = null;
            bulkPrice[0].buyerPrice = null;
            bulkPrice[0].set = null;
        } else {
            bulkPrice.splice(index, 1);
        }
        this.setState(bulkPrice);
    };

    calculateCommissionBuyerPrice = async () => {
        let { buyerPricePerPieceWithoutCommission, bulkSetIndex } = this.state;
        this.setState({ loading: true });
        let { data: priceResp } = await AddListingService.getProductCommission(
            SaveListing.mapCategoryId,
            buyerPricePerPieceWithoutCommission
        );
        if (priceResp.isSuccess) {
            this.setState({ buyerPriceCommission: priceResp.data, loading: false });

            this.setBulkSetBuyerPrice(bulkSetIndex);
        }
    };


    setBulkSetBuyerPrice = (e,i) => {
        let { buyerPricePerPiece } = this.state;

        if (e.target.value > 0 || e.target.value === "") {
            let bulkPrice = this.state.bulkPrice;
            if (e.target.value === "") {
                // here buyer price and price have been interchanged
                bulkPrice[i].price = null;
            } else {
                bulkPrice[i].price = Number(e.target.value);
            }
            this.setState(bulkPrice);
        }
    };


    handleBuyerPricingCancel = () => {
        this.setState({
            buyerPricePerPieceModal: false,
            buyerPricePerPieceWithoutCommission: "",
            buyerPriceCommission: {},
        });
    };

    continueEdit() {

        let bulkPrice = this.state.bulkPrice;
        let minimumSetOrder = SaveListing.newMinimumSetOrder;
        if (minimumSetOrder && bulkPrice[0].set != null) {
            let isSetValid = this.checkBulkSetWithMOQ();
            if (isSetValid) {
                this.setState({ isSetsComparisonScreen: true })
            }
        } else {
            this.setState({ isSetsComparisonScreen: true })
        }

    }

    checkBulkSetWithMOQ() {

        const { t } = this.props
        let bulkPrice = this.state.bulkPrice;
        let minimumSetOrder = SaveListing.newMinimumSetOrder;
        let isSetValid = true;
        let isDuplicateSet = false;

        bulkPrice.forEach((item) => {
            if (Number(item.set) <= Number(minimumSetOrder)) {
                isSetValid = false;
                message.error(
                    `${t('Bulk')} MOQ (${item.set}) ${t('should be more than original')} MOQ (${minimumSetOrder})`
                );
                return;
            }
            let set = bulkPrice.filter(
                (number) => Number(number.set) === Number(item.set)
            );

            if (set.length > 1) {
                isSetValid = false;
                isDuplicateSet = true;
                return;
            }
        });

        if (bulkPrice.length > 1) {
            if (
                Number(bulkPrice[bulkPrice.length - 2].set) >
                Number(bulkPrice[bulkPrice.length - 1].set)
            ) {
                message.error(`${t("New Bulk MOQ must be greater than previous MOQ")}`);
                return;
            }
        }
        if (isDuplicateSet) {
            message.error(`${t('Add unique')} MOQ`);
            return;
        }

        if (isSetValid) {
            return true
        } else {
            return false
        }
    }



    render() {
        const { t } = this.props
        const { imageFiles, needToRedirect, editSetsVisible, isSetsComparisonScreen,
            isEditingSetSelection, pricePerPieceModal, pricePerPieceWithoutCommission, priceCommission, loading, bulkSetIndex,
            buyerPricePerPieceModal, buyerPriceCommission, buyerPricePerPieceWithoutCommission, bulkPrice, showLoader, showContinueEditBtn, visible,imageToDeleteIndex } = this.state;
        return (
            <LayoutComponent title={t('New listing')} >
                {needToRedirect ?
                    <div className={'no_data_id mt-5'}>
                        <p>{t('Oops some error occured')}</p>
                        <Link to='/category'><Button>{t('Redirect')}</Button></Link>
                    </div> :
                    <>
                        {!showLoader ? <>
                            <Row className='divWithFixButtonForNoHeader'>
                                <Col span={24} className='bottom-border toolbar create-listing-bar '>
                                    <div className='fontSize13Px'>
                                        {t('Create listing 5 steps')}
                                    </div>
                                </Col>

                                <Col span={24} className='steps_container'>
                                    <div className={`list_steps ${this.productID ? 'disable_Class' : ''}`} onClick={(e) => { this.productID ? console.log('') : this.navigate('/brand') }}>
                                        <div className='label'><div>1</div></div>
                                        <div className='step'>
                                            <h4>{t('Select brand')}</h4>
                                            <p>{t('Select a brand to create listing')}</p>

                                            {(SaveListing.isBrandSelected === true) ?
                                                <p className='selectedItem'>{SaveListing.brand.brand.name}</p>
                                                : ''
                                            }
                                        </div>
                                    </div>


                                    <div className={`list_steps ${SaveListing.isBrandSelected ? "" : "disable_Class"}`} >
                                        <div className='label'>
                                            <div>2</div>
                                        </div>
                                        <div className='step'>
                                            <div>
                                                <h4>{t('Add image')}</h4>
                                                <p>{t('Add images for listing')}</p>
                                            </div>

                                            {
                                                SaveListing.isBrandSelected === true
                                                    ? <div className='assets'>
                                                        <input id="myInput" type="file" ref={(ref) => this.myInput = ref}
                                                            onChange={this.onChange.bind(this)}
                                                            accept=".png,.jpg,.jpeg"
                                                            style={{ display: 'none' }} />
                                                        <button
                                                            className="float-right mr-3 file"
                                                            onClick={() => { this.myInput.click() }}>
                                                            <FileAddOutlined />
                                                        </button>
                                                        {
                                                            imageFiles.map((item, i) => {
                                                                // console.log(item, "item")
                                                                if (item.type !== "ProductVideo") {
                                                                    return (
                                                                        <>

                                                                            <div key={i} style={{ position: "relative" }} className='file createListHoverImages' >
                                                                                <img style={{ display: "block" }} src={item.url} alt='' />
                                                                                {
                                                                                    item.type === 'ProductCoverImage'
                                                                                        ? <div className="container">
                                                                                            <div className={'coverImg'}>{t('Cover image')}</div>
                                                                                        </div>
                                                                                        :
                                                                                        <div className="container" onClick={() => {
                                                                                            this.setState({ imageToDeleteIndex: i },
                                                                                                () => { this.showModal() })
                                                                                        }}>
                                                                                        </div>
                                                                                }
                                                                            </div>                                                                         
                                                                        </>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <Modal
                                            title={'Choose Action'}
                                            visible={visible}
                                            footer={null}
                                            centered={true}
                                            closable={false}
                                        >
                                            <p onClick={() => this.deleteImage(imageToDeleteIndex)}
                                                className={'pointer_Cursor'}>{t('Remove')}</p>
                                            <p onClick={() => this.setCoverImage(imageToDeleteIndex)}
                                                className={'pointer_Cursor'}>{t('Cover image')}</p>
                                            <p onClick={() => this.setState({ visible: false })}
                                                className={'pointer_Cursor'}>{t('Cancel')}</p>

                                        </Modal>
                                    </div>

                                    <div className={`list_steps ${(imageFiles.length !== 0) ? "" : "disable_Class"}`}
                                        style={{ overflowY: 'scroll', maxHeight: '200px' }}
                                        onClick={(e) => SaveListing.productID ? '' : this.navigateCreateSet()}
                                    >
                                        <div className='label'><div>3</div> </div>
                                        <div className='step' >
                                            <div >
                                                <Row>
                                                    <Col span={18}>
                                                        <h4>{t('Create Sets')}</h4>
                                                        <p>{t('Create sets for buying options')}</p>
                                                    </Col>
                                                    {SaveListing.productID ?
                                                        <Col span={6} style={{ textAlign: 'right', right: '40px' }}>
                                                            {showContinueEditBtn ?
                                                                <Button style={{ padding: '0 15px', height: '26px' }}
                                                                    onClick={() => this.setState({ editSetsVisible: true, isEditingSetSelection: false })}>Continue</Button> :
                                                                <Button style={{ marginRight: '10px', padding: '0 15px', height: '26px' }} onClick={() => this.setState({ isEditingSetSelection: !isEditingSetSelection })} >{!isEditingSetSelection ? 'Edit' : 'Cancel'}</Button>
                                                            }
                                                        </Col> : null}
                                                </Row>
                                            </div>
                                            {SaveListing.mapProductSets.map((item, i) => {
                                                return (
                                                    <>
                                                        {isEditingSetSelection ?
                                                            <Checkbox key={i} onChange={(e) => this.selectSets(e.target.checked, i)} style={{ marginLeft: 0, width: '100%' }}>
                                                                {this.setsView(item, i)}
                                                            </Checkbox> :
                                                            <div onClick={(e) => this.navigateCreateSet()}>
                                                                {this.setsView(item, i)}
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>

                                    <div className={`list_steps ${SaveListing.isSetCreated ? "" : "disable_Class"}`}
                                        onClick={(e) => this.navigateProductDetail()}>
                                        <div className='label'><div>4</div> </div>
                                        <div className='step' style={{ width: '95%' }}>
                                            <div>
                                                <h4>{t('Product details')}</h4>
                                                <p>{t('Select specifications for listing')}</p>
                                            </div>

                                            {SaveListing.mapProductAttributes.length ?
                                                <div className='assets'>
                                                    {SaveListing.mapProductAttributes.map((item, i) => {
                                                        return (<>{item.selectedList && item.selectedList.map((ele, ind) => {
                                                            return (<p
                                                                className='selectedItem cardTitle productDetailItem mr-1'
                                                                key={ind}>{ele}</p>
                                                            )
                                                        })
                                                        } </>)
                                                    })}
                                                </div> : ''}
                                        </div>
                                    </div>

                                    <div
                                        className={`list_steps ${SaveListing.isProductDetailAdded ? "" : "disable_Class"}`}
                                        onClick={(e) => this.navigateAdditionalDetail()}>
                                        <div className='label'><div>5</div> </div>
                                        <div className='step'>
                                            <h4>{t('Additional details')}</h4>
                                            <p>{t('Edit Title,  GST etc')}</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} className='stickToBottom'>
                                    <div className='button_div'>
                                        <Button
                                            loading={this.state.savingProduct}
                                            onClick={() => {
                                                this.createProductList()
                                            }}>
                                            {t('Publish')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Modal
                                title={'Edit Sets'}
                                visible={editSetsVisible}
                                footer={null}
                                centered={true}
                                onCancel={() => this.resetValues()}
                                width={800}
                            >
                                <>
                                    {!isSetsComparisonScreen ?
                                        <>
                                            <div>
                                                <div >Edit Set </div>
                                                <Row>
                                                    <Col span={1}></Col>
                                                    <Col xs={24} sm={10}>
                                                        <label className="label">Minimum order quantity</label>
                                                        <Input className="input" type='number'
                                                            defaultValue={SaveListing?.newMinimumSetOrder}
                                                            onChange={(e) => (SaveListing['newMinimumSetOrder'] = e.target.value)}
                                                        ></Input>
                                                    </Col>
                                                    <Col span={2}></Col>
                                                    <Col xs={24} sm={10}>
                                                        <label className="label">Price per piece</label>

                                                        <div style={{ display: "inline-flex", width: '100%' }}>
                                                            <Input
                                                                // placeholder="1"
                                                                style={{ width: "60%" }}
                                                                // value={SaveListing?.newPricePerPiece}
                                                                disabled={false}
                                                                onChange = {(e) => {
                                                                    SaveListing['newPricePerPiece'] = e.target.value
                                                                }}
                                                            />
                                                            {/* <Button
                                                                className="enterPriceButton"
                                                                type={"primary"}
                                                                style={{ width: "40%" }}
                                                                onClick={() => {
                                                                    this.setState({ pricePerPieceModal: true });
                                                                }}
                                                            >
                                                                {t('Enter price')}
                                                            </Button> */}
                                                        </div>
                                                    </Col>
                                                    <Col span={1}></Col>
                                                    <Col span={1}></Col>
                                                    <Col xs={24} sm={10}>
                                                        <label className="label">MRP per piece</label>
                                                        <Input className="input" type='number' defaultValue={SaveListing.newMrpPerPiece} onChange={(e) => (SaveListing['newMrpPerPiece'] = e.target.value)}></Input>
                                                    </Col>

                                                    <Col span={24} className='mt-4'>
                                                        <Row>
                                                            <Col span={1}></Col>
                                                            <Col span={23}>
                                                                <label className="label mb-2">Bulk prices</label>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    {bulkPrice.length &&
                                                        bulkPrice.map((item, i) => {
                                                            return (
                                                                <Col span={24} key={i} className='mt-2'>
                                                                    <Row>
                                                                        <Col span={1}></Col>
                                                                        <Col xs={22} sm={10} className="input">
                                                                            <Input
                                                                                type="number"
                                                                                placeholder={t("Sets")}
                                                                                value={item.set}
                                                                                onChange={(e) => {
                                                                                    this.setBulkSet(e, i);
                                                                                    this.bulkSetInd(i);
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={1} sm={2} ></Col>
                                                                        <Col xs={1} sm={0} ></Col>

                                                                        <Col
                                                                            xs={22} sm={10}
                                                                            className="input"
                                                                            style={{
                                                                                display: "inline-flex",
                                                                                alignItems: "flex-end",
                                                                            }}
                                                                        >
                                                                            <Input
                                                                                className={'enterPriceInput'}
                                                                                // placeholder="1"
                                                                                style={{ width: "60%" }}
                                                                                value={item.price}
                                                                                disabled={false}
                                                                                onChange={(e)=>{
                                                                                    this.setState({buyerPricePerPiece:e.target.value})
                                                                                    this.setBulkSetBuyerPrice(e,i)
                                                                                    this.bulkSetInd(i)
                                                                                }}
                                                                            />
                                                                            {/* <Button
                                                                                className={'enterPriceButton'}
                                                                                type={"primary"}
                                                                                style={{ width: "40%" }}
                                                                                onClick={(e) => {
                                                                                    this.bulkSetInd(i);
                                                                                    this.setState({
                                                                                        buyerPricePerPieceModal: true,
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {t('Enter price')}
                                                                            </Button> */}
                                                                        </Col>
                                                                        {/* <Col span={1}></Col> */}

                                                                        {bulkPrice[bulkPrice.length - 1].price !== null ||
                                                                            bulkPrice[bulkPrice.length - 1].set !== null ||
                                                                            bulkPrice.length > 1 ? (
                                                                            <Col
                                                                                span={1}
                                                                                onClick={() => this.deletePriceSet(i)}
                                                                                className="input mt-1"
                                                                                style={{
                                                                                    paddingLeft: '4px'
                                                                                }}
                                                                            >
                                                                                <CloseCircleOutlined />
                                                                            </Col>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Row>
                                                                </Col>
                                                            );
                                                        })}


                                                    {bulkPrice.length &&
                                                        bulkPrice[bulkPrice.length - 1].price !== null &&
                                                        bulkPrice[bulkPrice.length - 1].set !== null ? (
                                                        <Col span={22}>
                                                            <div className="add_button_container">
                                                                <button
                                                                    className="float-right"
                                                                    onClick={() => this.addNewPriceSet()}
                                                                >
                                                                    {" "}
                                                                    + {t('Add more')}
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    ) : (
                                                        ""
                                                    )}

                                                </Row>
                                                <hr />

                                            </div>
                                            < div className='button_div mt-3'>
                                                <Button type='primary' onClick={() => this.continueEdit()}>Edit</Button>
                                            </div>
                                        </>
                                        :
                                        <div>
                                            <Row>
                                                <Col span={6}></Col>

                                                <Col span={9}>
                                                    Set's old value
                                                </Col>
                                                <Col span={9}>
                                                    Set's new value
                                                </Col>

                                            </Row>
                                            {SaveListing.mapProductSets.map((item, index) => {
                                                return (
                                                    <>
                                                        {item.selectedForEdit ?
                                                            <Card className='mt-4'>
                                                                <Row className='mt-2'>
                                                                    <Col span={24}>
                                                                        <h6>Set {index + 1}</h6>
                                                                    </Col>
                                                                    <Col span={6}>Min order quantity</Col>
                                                                    <Col span={9}>{item.minimumSetOrder}</Col>
                                                                    <Col span={9} className={(SaveListing.newMinimumSetOrder && (SaveListing.newMinimumSetOrder != item.minimumSetOrder)) ? 'bold-theme-color-text' : ''}>{SaveListing.newMinimumSetOrder ?? item.minimumSetOrder}</Col>

                                                                    <Col span={6} className='mt-2'>Price per piece</Col>
                                                                    <Col span={9} className='mt-2'>{item.pricePerPiece}</Col>
                                                                    <Col span={9} className={(SaveListing.newPricePerPiece && (SaveListing.newPricePerPiece != item.pricePerPiece)) ? 'bold-theme-color-text mt-2' : 'mt-2'}>{SaveListing.newPricePerPiece ?? item.pricePerPiece}</Col>

                                                                    <Col span={6} className='mt-2'>MRP per piece</Col>
                                                                    <Col span={9} className='mt-2'>{item.mrpPerPiece}</Col>
                                                                    <Col span={9} className={(SaveListing.newMrpPerPiece && (SaveListing.newMrpPerPiece != item.mrpPerPiece)) ? 'bold-theme-color-text mt-2' : 'mt-2'}>{SaveListing.newMrpPerPiece ?? item.mrpPerPiece}</Col>


                                                                    <Col span={6} className='mt-2'>Bulk Price</Col>
                                                                    <Col span={9} className='mt-2'>{item.mapProductSetBulkPrices.length ?
                                                                        <>{item.mapProductSetBulkPrices.map(set => {
                                                                            return (
                                                                                <div>
                                                                                    <span>MOQ: {set.set}, </span>
                                                                                    <span>  Price: {set.price}</span>
                                                                                </div>
                                                                            )
                                                                        })}</> : null
                                                                    }</Col>
                                                                    <Col span={9} className='mt-2'>{(bulkPrice.length && bulkPrice[0].set !== null) ?
                                                                        <>{bulkPrice.map(set => {
                                                                            return (
                                                                                <div className='bold-theme-color-text'>
                                                                                    <span>MOQ: {set.set}, </span>
                                                                                    <span> Price: {set.price}</span>
                                                                                </div>
                                                                            )
                                                                        })}</> : <> {item.mapProductSetBulkPrices.length ?
                                                                            <>{item.mapProductSetBulkPrices.map(set => {
                                                                                return (
                                                                                    <div>
                                                                                        <span>MOQ: {set.set}, </span>
                                                                                        <span>  Price: {set.price}</span>
                                                                                    </div>
                                                                                )
                                                                            })}</> : null
                                                                        }
                                                                        </>
                                                                    }</Col>


                                                                </Row>
                                                            </Card> : null}
                                                    </>
                                                )

                                            })
                                            }

                                            < div className='button_div mt-4'>
                                                <Button style={{ width: '50%' }} onClick={() => this.setState({ isSetsComparisonScreen: false })}>Back</Button>
                                                <Button type='primary' style={{ width: '50%' }} onClick={() => this.saveEditedSets()}>Save</Button>
                                            </div>
                                        </div>
                                    }
                                </>
                            </Modal>

                            {/*  Price Per piece Price */}
                            <Modal
                                title={t('Pricing calculator')}
                                visible={pricePerPieceModal}
                                onCancel={() => this.setState({ pricePerPieceModal: false })}
                                footer={null}
                                centered={true}
                                maskClosable={false}
                            >
                                <div>
                                    <Row>
                                        <Col span={24}>
                                            <p className="label mb-2">
                                                {t('Price per piece that includes your profit')}*
                                            </p>
                                            <div style={{ display: "inline-flex", width: "100%" }}>
                                                <Input
                                                    placeholder={t("Enter price")}
                                                    value={pricePerPieceWithoutCommission}
                                                    onChange={this.setPricePerPieceWithoutCommission}
                                                />
                                                <Button
                                                    type={"primary"}
                                                    style={{
                                                        visibility: pricePerPieceWithoutCommission
                                                            ? "visible"
                                                            : "hidden",
                                                    }}
                                                    loading={loading}
                                                    onClick={() => {
                                                        this.calculateCommissionPrice();
                                                    }}
                                                >
                                                    {t('Calculate')}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            {pricePerPieceWithoutCommission &&
                                                priceCommission.pricePerPieceWithCommission && (
                                                    <>
                                                        <div style={{ textAlign: "center", marginTop: "50px" }}>
                                                            <div className="mb-2">
                                                                {t('Price you should use')}: -{" "}
                                                                <b>
                                                                    Rs {priceCommission.pricePerPieceWithCommission} /-
                                                                </b>
                                                            </div>
                                                            <Button
                                                                type={"primary"}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        pricePerPieceModal: false,
                                                                        pricePerPieceWithoutCommission: "",
                                                                        priceCommission: {},
                                                                    });
                                                                    SaveListing['newPricePerPiece'] = priceCommission.pricePerPieceWithCommission
                                                    
                                                                }}
                                                            >
                                                                {t('Use this price')}
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                        </Col>
                                    </Row>
                                </div>
                            </Modal>

                            {/* Bulk Price Calculate */}

                            <Modal
                                title={t("Pricing calculator")}
                                visible={buyerPricePerPieceModal}
                                onCancel={this.handleBuyerPricingCancel}
                                footer={null}
                                centered={true}
                                maskClosable={false}
                            >
                                <div>
                                    <Row>
                                        <Col span={24}>
                                            <p className="label mb-2">
                                                {t('Enter the amount without gst')}
                                            </p>
                                            <div
                                                style={{
                                                    display: "inline-flex",
                                                    width: "100%",
                                                }}
                                            >
                                                <Input
                                                    placeholder={t("Enter price")}
                                                    value={buyerPricePerPieceWithoutCommission}
                                                    onChange={this.setBuyerPricePerPieceWithoutCommission}
                                                />
                                                <Button
                                                    type={"primary"}
                                                    style={{
                                                        visibility: buyerPricePerPieceWithoutCommission
                                                            ? "visible"
                                                            : "hidden",
                                                    }}
                                                    loading={loading}
                                                    onClick={() => {
                                                        this.setBulkSetPrice(bulkSetIndex);
                                                        this.calculateCommissionBuyerPrice();
                                                    }}
                                                >
                                                    {t('Calculate')}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            {buyerPricePerPieceWithoutCommission &&
                                                buyerPriceCommission.pricePerPieceWithCommission && (
                                                    <>
                                                        <div
                                                            style={{
                                                                textAlign: "center",
                                                                marginTop: "50px",
                                                            }}
                                                        >
                                                            <div className="mb-2">
                                                                {t('Price including Akar charges')}: -{" "}
                                                                <b>
                                                                    Rs{" "}
                                                                    {
                                                                        buyerPriceCommission.pricePerPieceWithCommission
                                                                    }{" "}
                                                                    /-
                                                                </b>
                                                            </div>
                                                            <Button
                                                                type={"primary"}
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            buyerPricePerPiece: buyerPriceCommission.pricePerPieceWithCommission,
                                                                            buyerPricePerPieceModal: false,
                                                                            buyerPricePerPieceWithoutCommission: "",
                                                                            buyerPriceCommission: {},
                                                                        },
                                                                        () => {
                                                                            this.setBulkSetBuyerPrice(
                                                                                bulkSetIndex
                                                                            );
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                {t('Use this price')}
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                        </Col>
                                    </Row>
                                </div>
                            </Modal>
                        </> :
                            <div className={"sectionloader"}>
                                <LoaderComponent />
                            </div>
                        }
                    </>
                }

            </LayoutComponent>
        )
    }
}

const mapStateToProps = ({ global }) => ({
    loading: global.buttonLoading,
    token: global.token,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateListComponent)));

