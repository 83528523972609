const baseUrl = process.env.REACT_APP_BASE_URL;
const businessBaseUrl = process.env.REACT_APP_BUSINESS_BASE_URL;
const communicationBaseUrl = process.env.REACT_APP_COMMUNICATION_BASE_URL;

const Urls = {
    login: baseUrl + "v1/api/Authentication/login",
    signup: baseUrl + "v1/api/Authentication/registration",
    userById: baseUrl + "v1/api/user/",
    userDetails: baseUrl + "v1/api/user/currentuser",
    updateCurrentUserDetails: baseUrl + "v1/api/user/currentuser",
    vacation: baseUrl + "v1/api/account/update/acceptingorder",
    getBusinessName: baseUrl + "v1/api/Authentication/businessname",
    logoutFromAllPlatform: baseUrl + "v1/api/user/logout/all",

    agreeSellerTermsOfService: baseUrl + "v1/api/account/term/update",
    sellerSections: businessBaseUrl + "v1/api/section/view/seller/",
    vendorDetails: baseUrl + "v1/api/account/vendor/",
    states: baseUrl + "v1/api/address/states",
    gst: businessBaseUrl + "v1/api/master/gst/all",
    businessTypes: baseUrl + "v1/api/account/businesstype",

    updateKYCDocuments: baseUrl + "v1/api/account/kyc",
    accountDetails: baseUrl + "v1/api/account/currentuser",
    updateAccountDetails: baseUrl + "v1/api/account/update",
    addBankAccountDetails: baseUrl + "v1/api/account/bankdetail",
    bankLists: baseUrl + "v1/api/account/bankdetail",
    addressList: baseUrl + "v1/api/address/all",
    createAddress: baseUrl + "v1/api/Address/create",
    addressById: baseUrl + "v1/api/Address/",
    updateAddres: baseUrl + "v1/api/Address/",
    deleteAddress: baseUrl + "v1/api/Address/",
    updateAddressIsDefaultStatus: baseUrl + "v1/api/address/",
    updateBankIsDefaultStatus: baseUrl + "v1/api/account/bankdetail/",
    updateBankIsActiveStatus: baseUrl + "v1/api/account/bankdetail/",
    retryBankAccountVerification: baseUrl + "v1/api/account/bankverification/retry/",
    gstAccountList: baseUrl + "v1/api/account/gstdetail/all",
    createGST: baseUrl + "v1/api/account/gstdetail/create",
    createUPI: baseUrl + "v1/api/account/vpadetail",
    makeGSTdefault: baseUrl + "v1/api/account/gstdetail/",
    deleteGST: baseUrl + "v1/api/account/gstdetail/",
    minOrderValue: baseUrl + "v1/api/account/update/minordervalue",

    addUserDocImage: baseUrl + "v1/api/uploadfile/add",
    deleteUserDocImage: baseUrl + "v1/api/uploadfile/delete",
    addImage: businessBaseUrl + "v1/api/uploadfile/add",
    deleteImage: businessBaseUrl + "v1/api/uploadfile/delete",

    allCategory: businessBaseUrl + "v1/api/category/parent/all",
    categoryHierarchy: businessBaseUrl + "v1/api/category/hierarchy/parentId/",
    categoryAtrribute: businessBaseUrl + "v1/api/attribute/mapcategory/",
    userBrand: businessBaseUrl + "v1/api/brand/currentuser/associated",
    searchAllBrand: businessBaseUrl + "v1/api/brand/master/all?brandname=",
    createBrand: businessBaseUrl + "v1/api/brand/create",
    reapplyBrand: businessBaseUrl + "v1/api/brand/",

    createProduct: businessBaseUrl + "v1/api/product/create",
    updateProduct: businessBaseUrl + "v1/api/product/",
    productList: businessBaseUrl + "v1/api/product/currentuser/all",
    product: businessBaseUrl + "v1/api/product/",
    updateIsAvailableProductStatus: businessBaseUrl + "v1/api/product/",
    updateIsAvailableSetStatus: businessBaseUrl + "v1/api/product/",
    sectionData: businessBaseUrl + "v1/api/section/view",

    addCartItems: businessBaseUrl + "v1/api/cart/orderlineitems/add",
    updateSpecialNote: businessBaseUrl + "v1/api/cart/orderLineItemId/specialNote/",
    allCartItems: businessBaseUrl + "v1/api/cart/all",
    deleteCartById: businessBaseUrl + "v1/api/cart/",
    getCartById: businessBaseUrl + "v1/api/cart/",
    deleteOrderLineItemById: businessBaseUrl + "v1/api/cart/",
    updateOrderLineItem: businessBaseUrl + "v1/api/cart/",

    createOrderByCartId: businessBaseUrl + "v1/api/order/add",
    getAllOrders: businessBaseUrl + "v1/api/order/currentuser/all",
    getOrderById: businessBaseUrl + "v1/api/order/",
    cancelOrder: businessBaseUrl + "v1/api/order/",
    search: businessBaseUrl + "v1/api/product/autocomplete",
    productSearch: businessBaseUrl + "v1/api/product/search",
    homepageSearch: businessBaseUrl + "v1/api/homepage/search",
    deleteSet: businessBaseUrl + "v1/api/product/",
    updatePaymentStatus : businessBaseUrl + "v1/api/payment/exchangetoken",
    addProductRating: businessBaseUrl + "v1/api/Product/create/rating",
    getProductRating: businessBaseUrl + "v1/api/Product/rating/",

    getScheduledTransactions: businessBaseUrl + "v1/api/ScheduleTransaction/all/",
    generateProductTitle: businessBaseUrl + "v1/api/product/title",
    getProductTitle: businessBaseUrl + "v1/api/product/title/",
    getPaymentOptions: businessBaseUrl + "v1/api/payment/paymentoptions",

    productCommission: businessBaseUrl + "v1/api/product/price/commission",

    //chat urls
    getChats: communicationBaseUrl + "v1/api/chat/thread/get",
    createChat: communicationBaseUrl + "v1/api/chat/create",
    getAllChatsByGuid: communicationBaseUrl + "v1/api/chat/get",
    chatThreadPost: communicationBaseUrl + "v1/api/chat/thread/create",
    //notifications urls
    updateNotificationToken: communicationBaseUrl + "v1/api/pushnotification/currentuser/token/add",
    sendNotification: communicationBaseUrl + "v1/api/pushnotification/internal/send",
    deleteNotificationToken: communicationBaseUrl + "v1/api/pushnotification/token/delete",

    //payment urls
    verifyPayment: businessBaseUrl + "v1/api/payment/verify",
    readyToShipPacket: businessBaseUrl + "v1/api/shipping/readytoship",
    //return order urls
    returnOrders: businessBaseUrl + "v1/api/order/return/all",
    singleReturnOrder: businessBaseUrl + "v1/api/order/return/",
    singleGetReturnOrder: businessBaseUrl + "v1/api/order/return/get/",
    returnShipment: businessBaseUrl + "v1/api/shipping/orderreturn/",
    //dispute urls
    createDispute: businessBaseUrl + "v1/api/order/dispute/create",
    getOrderDisputes: businessBaseUrl + "v1/api/order/dispute/get",
    updateOrderDispute: businessBaseUrl + "v1/api/order/dispute/update/user",
    createCommentOnDispute: businessBaseUrl + "v1/api/order/dispute/comment/create",
    getCommentsOverDispute: businessBaseUrl + "v1/api/order/dispute/comment/get",
    currentUserDisputeList: businessBaseUrl + "v1/api/order/dispute/all",
    getDisputeDetailsById: businessBaseUrl + "v1/api/order/dispute/",

    //order urls
    updateOrderStatusToHold: businessBaseUrl + "v1/api/order/status/hold",
    orderTracking: businessBaseUrl + "v1/api/order/",
    shipmentTracking: businessBaseUrl + "v1/api/shipping/",
    bankVerification: baseUrl + "v1/api/account/bankdetail/",
    retryFailedOrder: businessBaseUrl + "v1/api/order/",
    updateShipedPacket: businessBaseUrl + "v1/api/shipping/readytoship",

    //ledger
    getLedger: businessBaseUrl + "v1/api/payment/ledger",

    //manage team
    getAllTeamMembers: baseUrl + "v1/api/user/all/account/",
    removeTeamMember: baseUrl + "v1/api/user/remove/",
    closeAccount: baseUrl + "v1/api/account/close",
    inviteTeamMember: baseUrl + "v1/api/user/invite",

    //alerts 
    getAllAlerts: communicationBaseUrl + "v1/api/notification/userNotification",
    cloneMedia: businessBaseUrl + "v1/api/uploadfile/clone/media",
    warehouse: businessBaseUrl + "v1/api/Order/warehouse/",

    createProduct2: businessBaseUrl + "v2/api/productv2/create"

}
export default Urls;