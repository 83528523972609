import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Input, Button, Card, Modal, Form, Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import {
    MobileOutlined, UserAddOutlined,
    MailOutlined, ReloadOutlined
} from '@ant-design/icons';
import { MdCreate } from 'react-icons/md';
import userImg from '../../assests/user.png'
import './index.scss';
import AuthService from '../../service/authService'
import MediaUrl from '../../service/mediaUrl'
import ModalComponent from '../modal/modal'

class ProfileComponent extends Component {
    userFormRef = React.createRef()
    constructor(props) {
        super(props);
        this.state = {
            showUserModal: false,
            loading: false,
            isOpenModal: false
        }
    }

    componentDidMount() { }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    handleUserModalCancel = () => {
        this.setState({ showUserModal: false }, () => {
            this.userFormRef.current.resetFields()
        })
    }

    editUserDetails = values => {
        const { dispatch } = this.props
        this.setState({ loading: true })
        AuthService.updateUserDetails(values).then((res) => {
            let { data } = res.data
            dispatch({
                type: 'SET_CURRENT_USER',
                user: data
            })
            this.userFormRef.current.resetFields()
            this.setState({ loading: false, showUserModal: false })

        }, (err) => {
            //console.log(err)
        })
    }

    onFinishFailed = errorInfo => { }

    reloadUserDetails = () => {
        const { dispatch } = this.props
        AuthService.getUserDetails().then((res) => {
            let { data } = res.data
            dispatch({
                type: 'SET_CURRENT_USER',
                user: data
            })
        }, (err) => { });
    }

    editUserProfileImage = (values) => {
        const { dispatch, currentUser } = this.props;
        this.setState({ loading: true })
        AuthService.updateUserDetails(values).then((res) => {
            let { data } = res.data
            dispatch({
                type: 'SET_CURRENT_USER',
                user: data
            })
            this.setState({ loading: false })
            document.getElementById('profileImage').value = null
        }, (err) => { })
    }

    addImageInAWS = (data) => {
        let { currentUser } = this.props;
        let { name, email } = currentUser;
        AuthService.addUserKYCDocImage(data, 'ACCOUNT_IMAGE_FILE')
            .then((res) => {
                let data = res.data.data[0]
                let url = MediaUrl.trimMediaUrl(data.url);
                let obj = {
                    "name": name ? name : '',
                    "email": email ? email : '',
                    "profileImageFileUploadedData": {
                        "name": data.fileName,
                        "url": url
                    }
                }
                this.editUserProfileImage(obj)
            }, err => { })
    }

    onImageChange = (event) => {
        const { t } = this.props;
        let imageFile = event.target.files[0];
        const fileExtn = imageFile.name.substr(imageFile.name.lastIndexOf("."))

        if ((fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png') && imageFile) {
            let blob = imageFile.slice(0, imageFile.size, imageFile.type);
            imageFile = new File([blob], moment().valueOf() + fileExtn, { type: imageFile.type });
            let filearr = []
            filearr.push(imageFile)
            this.addImageInAWS(filearr)
        } else {
            return message.error(`${t('Attach image in png/jpg/jpeg file format')}`)
        }
        document.getElementById("profileImage").value = null;
    }

    render() {

        const { currentUser, t } = this.props;
        const { showUserModal, loading, isOpenModal } = this.state

        return (
            <LayoutComponent title={t('User account setting')}>
                <div style={{ height: '100vh' }}>
                    <div className='user-content mt-3'>
                        <div className='justify-content-center'>
                            <Card className='profile-card'>

                                <div className='justify-content-center mb-4'>
                                    <input id="profileImage"
                                        type="file"
                                        ref={(ref) => this.myInput = ref}
                                        onChange={this.onImageChange.bind(this)}
                                        accept=".png,.jpg,.jpeg"
                                        style={{ display: 'none' }} />

                                    <div className="profile_img_div">
                                        <img
                                            src={currentUser.profileImageFileUploadedData && currentUser.profileImageFileUploadedData.url ? MediaUrl.completeMediaUrl(currentUser.profileImageFileUploadedData.url, true, 100, 100) : userImg}
                                            alt={'userImg'} />

                                        <div className="icon_container">
                                            <span className="edit-btn"
                                                onClick={() => this.myInput.click()}>
                                                <MdCreate />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <p className="label font14px"><b>{t('Name')}</b></p>
                                    <Input className="mt-2 mb-2"
                                        prefix={<><UserAddOutlined
                                            className="site-form-item-icon margin-right-1" /></>}
                                        placeholder={t('Name')}
                                        value={currentUser ? currentUser.name : ''}
                                        disabled />

                                    <p className="label font14px"><b>{t('Mobile')}</b></p>
                                    <Input className="mt-2 mb-2" prefix={<><MobileOutlined
                                        className="site-form-item-icon margin-right-1" /></>}
                                        placeholder={t('Mobile number')} disabled
                                        value={currentUser ? currentUser.phoneNumber : ''} />

                                    <p className="label font14px"><b>{t('Email')}</b></p>
                                    <Input className="mt-2 mb-2" prefix={<><MailOutlined
                                        className="site-form-item-icon margin-right-1" /></>}
                                        placeholder={t('Email')}
                                        value={currentUser ? currentUser.email : ''}
                                        disabled />
                                </div>

                                <div className='btn-right'>
                                    <Button size="small"
                                        className='small-button'
                                        onClick={() => {
                                            this.setState({ showUserModal: true })
                                        }}>{t('Update details')}</Button>
                                </div>
                            </Card>
                        </div>

                    </div >
                    <div className='user-content mt-3 mb-3'>
                        <div className='justify-content-center'>
                            <Card className='profile-card'>
                                <div >
                                    {currentUser && currentUser.account && currentUser.account.kycStatus && currentUser.account.kycStatus !== "NoDocuments" ?
                                        <>
                                            <p className="label font14px"><b>{t('Kyc status')}</b></p>
                                            <h2 className="mb-1 mt-4">{currentUser && currentUser.account.kycStatus ? currentUser.account.kycStatus : ''}</h2>
                                            {
                                                currentUser.account.kycStatus === "Rejected"
                                                    ? <p style={{ color: '#333', fontStyle: 'italic' }}>{t('Reason of rejection')}: <span>{currentUser.account && currentUser.account.reason ? currentUser.account.reason : ""}</span></p>
                                                    : null
                                            }
                                            <div className="btn-right mt-3">
                                                {currentUser && currentUser.account.kycStatus && currentUser.account.kycStatus === 'Pending' ?
                                                    <div className="refreshBtn">
                                                        <ReloadOutlined onClick={() => {
                                                            this.reloadUserDetails()
                                                        }} />
                                                    </div>
                                                    : null}

                                                {currentUser && currentUser.account.kycStatus && currentUser.account.kycStatus === 'Rejected' ?
                                                    <Button size="small"
                                                        className='small-button'
                                                        onClick={(e) => { this.setState({ isOpenModal: true }) }}
                                                    >{t('Re-Apply for KYC')}</Button>
                                                    : null}
                                            </div>
                                        </> : <Button
                                            size="small"
                                            type="primary"
                                            onClick={() => {
                                                this.setState({ isOpenModal: true })
                                            }}>{t('Apply for KYC')}</Button>}
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>

                {isOpenModal ?
                    <ModalComponent showModal={isOpenModal}
                        onClose={() => {
                            this.setState({ isOpenModal: false })
                        }} /> : null
                }

                <Modal
                    title={t('Edit user details')}
                    visible={showUserModal}
                    onCancel={this.handleUserModalCancel}
                    footer={null}
                    centered={true}
                    maskClosable={false}
                >
                    <div>
                        <Form ref={this.userFormRef}
                            scrollToFirstError
                            layout="vertical"
                            requiredMark={false}
                            onFinishFailed={this.onFinishFailed}
                            onFinish={this.editUserDetails}
                            initialValues={{
                                name: currentUser.name,
                                email: currentUser.email
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('Name*')}
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('Name reqd validation')}`,
                                                whitespace: true
                                            }
                                        ]}
                                    >
                                        <Input placeholder={t('Name')} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={t('Email*')}
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('email reqd validation')}`,
                                                whitespace: true
                                            },
                                            {
                                                type: 'email',
                                                message: `${t('Invalid email')}`,
                                                validateTrigger: 'onSubmit'
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t('Email')} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            htmlType='submit'
                                            className={'submitBtn'}
                                            loading={loading}
                                            type="primary"
                                        >{t('Save')}</Button>
                                        <Button
                                            className={'cancelBtn'}
                                            type="default"
                                            onClick={this.handleUserModalCancel}
                                        >{t('Cancel')}</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
            </LayoutComponent >
        )
    }
}

const mapStateToProps = ({ global }) => {
    return ({
        token: global.token,
        currentUser: global.currentUser,
        loading: global.loading
    })
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileComponent)));

