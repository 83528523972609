const axios = require('axios').default;
const websiteUrl = process.env.REACT_APP_WEBSITE_URL;


const API = {

    makeRequest(method, path, headerDict, queryParams, body, options) {
        var url = new URL(path)
        if (queryParams) {
            url.search = new URLSearchParams(queryParams).toString();
        }

        var myHeaders = {};

        myHeaders["content-type"] = "application/json"
        var token = typeof window !== 'undefined' ? localStorage.getItem('Authorization') : null
        var language = typeof window !== 'undefined' ? localStorage.getItem('language') : null
        if (language && language != 'en') {
            myHeaders["Accept-Language"] = `${language}-IN,en-GB,en-US;q=0.9,en;q=0.8`
            myHeaders["CultureInfoHeader"] = `en-${language}`
        } else {
            myHeaders["Accept-Language"] = "en-GB,en-US;q=0.9,en;q=0.8"
        }
        if (token) {
            myHeaders["Authorization"] = token
        }

        for (var key in headerDict) {
            myHeaders[String(key)] = String(headerDict[key])
        }

        return axios({
            method: method,
            url: url,
            headers: myHeaders,
            data: body
        }).then((res) => {
            return res
        }).catch((err) => {
            if (err.response.status === 401 || err.response.status === 403) {
                if (typeof window !== 'undefined') { localStorage.clear() }
                sessionStorage.clear()
                window.location = `/`
            } else {
                throw err.response
            }
        })
    },

    makeRequestNoHeader(method, path, header, body, options) {
        var url = new URL(path)

        return axios({
            method: method,
            url: url,
            headers: header,
            data: body
        }).then((res) => {
            return res
        }).catch((err) => {
            throw err.response
        })
    },


    makeGetRequest(path, headerDict, queryParams, options) {
        return this.makeRequest('get', path, headerDict, queryParams, null, options);
    },

    makePostRequest(path, headerDict, queryParams, body, options) {
        return this.makeRequest('post', path, headerDict, queryParams, body, options);
    },

    makePutRequest(path, headerDict, queryParams, body, options) {
        return this.makeRequest('put', path, headerDict, queryParams, body, options);
    },
    makePutRequestNoHeader(path, header, body, options) {
        return this.makeRequestNoHeader('put', path, header, body, options);
    },
    makeDeleateRequest(path, headerDict, queryParams, body, options) {
        return this.makeRequest('delete', path, headerDict, queryParams, body, options);
    }

};

export default API;