import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { withTranslation } from 'react-i18next';
import {
	Row, Col, Button, Card, Tag, Switch,
	Drawer, Collapse, message, Breadcrumb, Skeleton
} from 'antd';
import { connect } from 'react-redux';
import Jeans2 from '../../assests/jeans2.jpg';
import videoThumbNail from '../../assests/Thumbnail-Video.jpg';
import './index.scss';
import { ShareAltOutlined } from '@ant-design/icons';
import ImageGallery from 'react-image-gallery';
import moment from 'moment';
import _, { floor, set } from 'lodash';
import { showLoader, hideLoader } from '../../actions';
import { commonRedirections } from '../sections/redirections';
import AddListingService from '../../service/addListingService';
import SaveListing from '../../service/saveListingService';
import SearchService from '../../service/searchService';
import CartService from '../../service/cart';
import Footer from '../footer';
import MediaUrl from '../../service/mediaUrl';
import ProductCard from '../elements/ProductCard'
import ModalComponent from '../modal/modal';
import { MdPlayCircleFilled, MdPauseCircleFilled } from 'react-icons/md';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import {Helmet} from "react-helmet";

// Rating Component
import Rating from '@mui/material/Rating';
const { Panel } = Collapse;
//import SearchService from "../../service/searchService";
class ProductViewComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			product: {},
			showEditProduct: false,
			specificationsArr: [],
			tagsArr: [],
			drawerVisible: false,
			placement: 'bottom',
			quantity: 0,
			buyingOptions: [],
			buyingOptionsCopy: [],
			similarProductCategoryName: '',
			hideLessBtn: false,
			images: [],
			isGSTIncluded: false,
			productMinMaxPrice: '',
			productMinMaxPriceWithoutGst: '',
			breadcrumbRoutes: [],
			productName: '',
			showProductImages: true,
			kycApproved: false,
			isOpenModal: false,
			pendingKyc: false,
			showVideo: {},
			btnText: '',
			showPlayBtn: true,
			isExpired: false,
			similarProducts: {},
			setAlertDiv: false,
			retailMargin: '',
			isBackButtonClicked: false,
			productRating:"",
			totalRatings:0,

		}
		this.myImageGallery = React.createRef()
	}

	componentDidMount() {
		window.scroll(0, 0);
		this.getProductDetails();
		this.getProductName();
		this.getKycStatus();
		this.getProductRating()
	}

	getProductRating = async() => {
		let id = this.props.match.params.id;
		let {data} = await AddListingService.getProductRating(id)
		if(data.isSuccess){
			this.setState({
				productRating:Math.round(data.data.rating*10)/10,
				totalRatings:data.data.totalNumberOfRatings})
		}
	}

	getKycStatus = () => {
		const { currentUser } = this.props
		const userRegistered = currentUser.account.isUserRegistered

		const condition2 = currentUser.account.kycStatus !== 'NoDocuments' &&
			currentUser.account.isTermsAndConditionApprovedForSeller &&
			currentUser.account.isBankDetailsExist

		if (currentUser && currentUser.account.type === 'Seller' && currentUser.account) {
			if (userRegistered && currentUser.account.kycStatus === 'Approved') {
				this.setState({
					pendingKyc: false, kycApproved: true
				})
			}
			else if (userRegistered && condition2) {
				if (currentUser.account.kycStatus === 'Pending') {
					this.setState({
						pendingKyc: true, kycApproved: false,
						btnText: 'KYC verification is under process'
					})
				}
				else if (currentUser.account.kycStatus === 'Rejected') {
					this.setState({
						pendingKyc: false, kycApproved: false,
						btnText: 'Complete KYC'
					})
				}
				else {
					//docs approved
					this.setState({
						pendingKyc: false, kycApproved: true
					})
				}

			} else {
				this.setState({
					pendingKyc: false, kycApproved: false,
					btnText: 'Complete KYC'
				})
			}
		} else {
			if (currentUser.account.isUserRegistered && currentUser.account.kycStatus === 'Pending') {
				this.setState({
					pendingKyc: true, kycApproved: false,
					btnText: 'KYC verification is under process'
				})
			}
			else if (currentUser.account.isUserRegistered && currentUser.account.kycStatus === 'Rejected') {
				this.setState({
					pendingKyc: false, kycApproved: false, btnText: 'Complete KYC'
				})
			} else if (currentUser.account.isUserRegistered && currentUser.account.kycStatus === 'Approved') {
				this.setState({ pendingKyc: false, kycApproved: true })
			} else {
				//buyer not registered
				this.setState({
					pendingKyc: false, kycApproved: false,
					btnText: 'Complete KYC'
				})
			}
		}
	}

	getCategoryName = (data) => {
		if (data && data.childCategory && !data.childCategory.mapCategories.length || data.childCategory.mapCategories === null) {
			return data.childCategory['name'];
		} else {
			let subCat = data.childCategory.mapCategories[0];
			let subcatresp = this.getCategoryName(subCat);
			return subcatresp;
		}
	}

	similarProductSearch = async () => {
		let payload = {
			"filters": `(categoryList.parentCategory.name : \"${this.getCategoryName(this.state.product.mapCategory)}\" OR categoryName : \"${this.getCategoryName(this.state.product.mapCategory)}\")`,
			"hitsPerPage": 10
		}
		let hits = await SearchService.algoliaProductsearch("", payload)
		if (hits) {
			this.setState({ similarProducts: hits })
		}
	}

	getProductName = async () => {
		const id = this.props.match.params.id
		let { data } = await AddListingService.getProductTitle(id)
		if (data.isSuccess) {
			this.setState({ productName: data.data.productTitle });

			     // OG:TITLE(Product Name)
				 const metaTitle = document.createElement('meta');
				 metaTitle.setAttribute('property', 'og:title');
				 metaTitle.content =data.data.productTitle ;
				 document.head.appendChild(metaTitle);
		}
	}

	handleCancel = () => {
		let { buyingOptions } = this.state
		buyingOptions && buyingOptions.forEach((item) => {
			item.optedQty = 0
		})
		this.setState({ drawerVisible: false, quantity: null, buyingOptions, buyingOptionsCopy: buyingOptions })
	}

	onSwitchChange = (val) => {
		let { product } = this.state
		AddListingService.updateProductIsAvailableStatus(product.id, val)
			.then((res) => {
				if (res.data.isSuccess) {
					this.setState({ isAvailable: val })
				}
				message.success('Status updated successfully')
			}, err => {
				message.error(err.data.message)
			})
	}

	createBreadcrumbs = (category) => {
		let { breadcrumbRoutes } = this.state
		const { t } = this.props
		let { mapCategories } = category.childCategory

		if (mapCategories.length) {
			breadcrumbRoutes.push({ path: '/', breadcrumbName: `${t('Market')}` })

			mapCategories.forEach((item, key1) => {
				breadcrumbRoutes.push({
					path: '/',
					breadcrumbName: item.childCategory.name
				})
				let { mapCategories: subMapCategories } = item.childCategory
				if (subMapCategories.length) {
					subMapCategories.forEach((subItem, key2) => {
						breadcrumbRoutes.push({
							path: '',
							breadcrumbName: subItem.childCategory.name
						})
						let { mapCategories: subSubMapCategories } = subItem.childCategory
						if (subSubMapCategories.length) {
							subSubMapCategories.forEach((val, key3) => {
								breadcrumbRoutes.push({
									path: `/subcategory/${val.id}`,
									breadcrumbName: val.childCategory.name
								})
							})
						}

					})
				}
			})
		}
		this.setState({ breadcrumbRoutes })
	}

	_onSlide() {
		this._resetVideo();
	}

	_resetVideo() {
		this.setState({ showVideo: {} }, () => {
			this.pauseVideo()
		});

	}

	playVideo = () => {
		document.getElementById('videoPlayer').play();
		this.setState({ showPlayBtn: false })
	}

	pauseVideo = () => {
		if (document.getElementById('videoPlayer')) {
			document.getElementById('videoPlayer').pause();
			this.setState({ showPlayBtn: true })
		}
	}

	_renderVideo(item) {
		const { showPlayBtn } = this.state

		return (
			<div>
				<div className='video-wrapper'>
					<video
						className='productVideo'
						id={'videoPlayer'}
						src={item.embedUrl}
						controls={this.state.showThumb ? true : false} />

					{this.state.showThumb ? null :
						<div className="btnContainer">
							{showPlayBtn ? <MdPlayCircleFilled
								className="play-icon"
								onClick={() => this.playVideo()} /> : <  MdPauseCircleFilled
								className="pause-icon"
								onClick={() => this.pauseVideo()} />}
						</div>}
				</div>
			</div>
		);
	}

	getProductDetails = () => {
		let id = this.props.match.params.id;
		const { currentUser } = this.props;
		AddListingService.getProduct(id)
			.then((res) => {
				let { data } = res.data
				if (currentUser && currentUser.accountId === data.accountId) {
					this.setState({ showEditProduct: true })
				}
				this.setState({
					product: data,
					isAvailable: data.isAvailable,
					isExpired: data.isExpired
				}, () => {
					this.similarProductSearch();
					let productImages = this.state.product.mapProductFileUploadedDatas

					let category = this.state.product.mapCategory

					if (category) {
						this.createBreadcrumbs(category)
					}

					productImages.forEach(img => {
						let url = MediaUrl.completeMediaUrl(img.fileUploadedData.url, true, 500, 100)
						img.fileUploadedData.url = url
					})

					let productAttributeArr = this.state.product.mapProductAttributes
					let productSetsArr = this.state.product.mapProductSets
					let specArr = this.state.specificationsArr
					let tagArr = this.state.tagsArr

					let buyingOptionsArr = this.state.buyingOptions
					let imagesArr = this.state.images

					this.setState({ isGSTIncluded: this.state.product.isGSTIncluded })

					if (productAttributeArr.length > 0) {
						productAttributeArr.forEach((item, key) => {
							let obj = {}

							if (item.mapAttribute.parentAttribute.type === "ADDITIONAL_TAGS") {
								tagArr.push(`#${item.mapAttribute.childAttribute.value}`)
							}
							else {
								let found = _.find(specArr, (specArrItem) => {
									return specArrItem.specParentAttributeId === item.mapAttribute.parentAttributeId
								})
								if (found) {
									found.specValue.push(item.mapAttribute.childAttribute.value);
								}
								else {
									obj.specKey = item.mapAttribute.parentAttribute.value;
									obj.specValue = [item.mapAttribute.childAttribute.value];
									obj.specParentAttributeId = item.mapAttribute.parentAttributeId;
									specArr.push(obj)
								}
							}
						});
						this.setState({ specificationsArr: specArr, tagsArr: tagArr })
					}

					let priceArr = []
					if (productSetsArr.length > 0) {
						productSetsArr.forEach((item, key) => {
							if (item.isAvailable) {
								let obj = {
									pricePerPiece: item.pricePerPiece,
									mrpPerPiece: item.mrpPerPiece,
									minimumSetOrder: item.minimumSetOrder,
									set: item,
									gstPercentage: this.state.product.gstMaster.percentage,
									colorsWithSize: [],
									optedQty: 0,
									totalPrice: 0,
									priceWithoutGSt: 0,
									priceGst: 0
								}
								priceArr.push(item.pricePerPiece)

								if (item.mapProductSetBulkPrices.length) {
									item.mapProductSetBulkPrices.forEach(bulkItem => {
										priceArr.push(bulkItem.pricePerPiece)
									})
								}

								let color = []
								let imgthumbArr = []
								let totalPcOnSet = 0

								item.mapProductSetAttributes && item.mapProductSetAttributes.length > 0 && item.mapProductSetAttributes.forEach((set, key1) => {

									if (set.mapAttribute.parentAttribute.type === 'COLOR') {
										let sizewithqty = [];
										let sizeWithoutQty = [];
										let colorVal = set.mapAttribute.childAttribute.key;

										set.mapProductSetAttributeDetails && set.mapProductSetAttributeDetails.length > 0 && set.mapProductSetAttributeDetails.forEach((sizeItem, key3) => {
											let sizeVal = sizeItem.mapAttribute.childAttribute.value
											let sizeqty = sizeItem.quantity !== 0 ? sizeItem.quantity : ''
											totalPcOnSet += sizeItem.quantity
											if (sizeItem.mapAttributeId === null) {
												let url = MediaUrl.completeMediaUrl(sizeItem.fileUploadedData.url, true, 100, 100)
												imgthumbArr.push(url)
											}
											else {
												sizeWithoutQty.push(sizeVal)
												if (sizeqty !== "") {
													sizewithqty.push(`${sizeVal} (${sizeqty})`)
												}
											}
										})
										obj.colorsWithSize.push({ color: colorVal, size: sizewithqty.join(', ') })
										color.push(colorVal)
										obj.size = sizeWithoutQty.join(', ')
									}
									if (set.mapAttribute.parentAttribute.type === 'COMPATIBLE_DEVICES') {
										let compatible = set.mapAttribute.childAttribute.value || "";
										obj.compatible = compatible;
									}
								})

								obj.setImageUrls = imgthumbArr
								obj.color = color.join(', ')
								obj.totalPcOnSet = totalPcOnSet
								buyingOptionsArr.push(obj)
								item['totalPcOnSet'] = totalPcOnSet
							}
						})
						this.setState({ buyingOptions: buyingOptionsArr, buyingOptionsCopy: buyingOptionsArr })
					}

					if (priceArr.length) {
						// let min = Math.min(...priceArr)
						// let max = Math.max(...priceArr)

						let min = data.minPrice
						let max = data.maxPrice

						let minWithGst = min + ((min * data.gstMaster.percentage) / 100)
						let maxWithGst = max + ((max * data.gstMaster.percentage) / 100)
						minWithGst = _.round(minWithGst, 2)
						maxWithGst = _.round(maxWithGst, 2)
						min = _.round(min, 2)
						max = _.round(max, 2)

						let price = '';
						let p = '';
						let RetailMargin = '';
						let mrp = data.mapProductSets[0].mrpPerPiece;
						if (min === max) {
							price = minWithGst
							p = min
							if (mrp && mrp > min) {
								RetailMargin = `${_.round(((mrp - min) / min) * 100, 2)}`;
							}
						} else {
							price = `${minWithGst} - ${maxWithGst}`;
							p = `${min} - ${max}`;
							if (mrp && mrp > min && mrp > max) {
								RetailMargin = `${_.round(((mrp - min) / min) * 100, 2)} - ${_.round(((mrp - max) / max) * 100, 2)}`;
							}
						}
						this.setState({
							productMinMaxPrice: price,
							productMinMaxPriceWithoutGst: p,
							retailMargin: RetailMargin
						})
					}



					if (productImages.length > 0) {
						productImages.forEach((item, key) => {

							let obj = {
								original: item.fileUploadedData.url,
								thumbnail: item.fileUploadedData.url,
							}
							if (item.type === "ProductVideo") {
								obj.embedUrl = item.fileUploadedData.url
								obj.renderItem = this._renderVideo.bind(this)
								obj.thumbnail = videoThumbNail
								obj.original = videoThumbNail
							}
							imagesArr.push(obj)
						})
						this.setState({ images: imagesArr })

				              // OG:DESCRIPTION
							    const metaDescription = document.createElement('meta');
								metaDescription.name = 'description';
								metaDescription.content = (data && data.description)? data.description:"";
								document.head.appendChild(metaDescription);

                                // OG:URL
								const metaUrl = document.createElement('meta');
								metaUrl.setAttribute('property', 'og:url');
								metaUrl.content = window.location.href;
								document.head.appendChild(metaUrl);

			                   // OG:IMAGE
				       			const metaImage = document.createElement('meta');
               				    metaImage.setAttribute('property', 'og:image');
                				metaImage.content =imagesArr?imagesArr[0].original:"" ;
                                document.head.appendChild(metaImage);

					}

				})
			},
				(err) => { })
	}

	navigate = (url) => {
		this.props.history.push({ pathname: url })
	}

	editProductList = () => {
		let id = this.props.match.params.id
		SaveListing.isDataStoredFromApi = false
		let url = '/create-list/' + id
		this.props.history.push({ pathname: url })
	}

	cloneProductList = () => {
		let id = this.props.match.params.id
		SaveListing.productID = id
		SaveListing.isClone = true
		let url = '/create-list'
		this.props.history.push({ pathname: url })
	}

	showDrawer = () => {
		this.setState({
			drawerVisible: true,
		});
	};

	countQty = () => {
		let arr = [...this.state.buyingOptions]
		let qty = _.sumBy(arr, (item) => item.optedQty)
		let amountWithOutGst = _.sumBy(arr, (item) => item.priceWithoutGSt)
		let gstPrice = _.sumBy(arr, (item) => item.priceGst)
		amountWithOutGst = _.round(amountWithOutGst, 2);
		gstPrice = _.round(gstPrice, 2); // Akash
		let amount = _.round(amountWithOutGst + gstPrice, 2);     // Akash
		this.setState({ quantity: qty, amount, amountWithOutGst, gstPrice })
	}

	decreaseQty = (buyItem, index) => {
		let { setAlertDiv } = this.setState;

		if (setAlertDiv === true) {

			this.setState({ setAlertDiv: false })
		}

		if (buyItem.optedQty == 0) {
			this.setState({ hideLessBtn: true })
		}
		else {
			this.setState({ hideLessBtn: false })
			let arr = [...this.state.buyingOptions]
			let decquantity
			if (buyItem.optedQty === buyItem.minimumSetOrder) {
				decquantity = buyItem.optedQty - buyItem.minimumSetOrder;
			}
			else {
				decquantity = buyItem.optedQty - 1;
			}

			arr[index].optedQty = decquantity
			let pricePerPiece = arr[index].pricePerPiece

			// let pricePerPiece = arr[index].pricePerPiece
			if (buyItem.set.mapProductSetBulkPrices.length) {
				if (decquantity >= buyItem.set.mapProductSetBulkPrices[0].minimumOrderQuantity) {
					let isAllValChecked = false
					buyItem.set.mapProductSetBulkPrices.forEach((item, i) => {
						if (buyItem.set.mapProductSetBulkPrices.length === (i + 1)) {
							if (!isAllValChecked) {
								let lastIndex = (buyItem.set.mapProductSetBulkPrices.length - 1)
								pricePerPiece = buyItem.set.mapProductSetBulkPrices[lastIndex].pricePerPiece
							}
						} else {
							if (decquantity < buyItem.set.mapProductSetBulkPrices[i + 1].minimumOrderQuantity) {
								isAllValChecked = true
								pricePerPiece = item.pricePerPiece
							}
						}
					})
				}
			}

			// arr[index].totalPrice = parseFloat(arr[index].optedQty * arr[index].pricePerPiece)
			arr[index].totalPrice = parseFloat((arr[index].optedQty * pricePerPiece) * arr[index].totalPcOnSet) + ((((arr[index].optedQty * pricePerPiece) * arr[index].totalPcOnSet) * arr[index].gstPercentage) / 100)
			arr[index].priceWithoutGSt = parseFloat((arr[index].optedQty * pricePerPiece) * arr[index].totalPcOnSet)
			// arr[index].priceGst = parseFloat((((arr[index].optedQty * pricePerPiece) * arr[index].totalPcOnSet) * arr[index].gstPercentage) / 100)
			let gstPriceBeforeRoundOff = parseFloat(((arr[index].gstPercentage) * (arr[index].totalPcOnSet * pricePerPiece)) / 100)
			let gstPriceAfterRoundOff = _.round(gstPriceBeforeRoundOff, 2);
			arr[index].priceGst = gstPriceAfterRoundOff * arr[index].optedQty;
			this.setState({ buyingOptions: arr }, () => {
				this.countQty()
			})
		}
	}

	increaseQty = (buyItem, index) => {

		let availableQuantity = this.state.product.mapProductSets[index].availableQuantity;
		let { setAlertDiv } = this.state;

		if ((availableQuantity <= buyItem.optedQty) && (availableQuantity !== 0) && (availableQuantity !== null))  {
			this.setState({ setAlertDiv: true })
		}
		else {
			let incquantity
			let arr = [...this.state.buyingOptions]
			if (buyItem.optedQty > 0) {
				this.setState({ hideLessBtn: false })
			}

			if (buyItem.optedQty < buyItem.minimumSetOrder) {
				incquantity = buyItem.optedQty + buyItem.minimumSetOrder;
			} else {
				incquantity = buyItem.optedQty + 1;
			}
			arr[index].optedQty = incquantity

			let pricePerPiece = arr[index].pricePerPiece
			if (buyItem.set.mapProductSetBulkPrices.length) {
				if (incquantity >= buyItem.set.mapProductSetBulkPrices[0].minimumOrderQuantity) {
					let isAllValChecked = false
					buyItem.set.mapProductSetBulkPrices.forEach((item, i) => {
						if (buyItem.set.mapProductSetBulkPrices.length === (i + 1)) {
							if (!isAllValChecked) {
								let lastIndex = (buyItem.set.mapProductSetBulkPrices.length - 1)
								pricePerPiece = buyItem.set.mapProductSetBulkPrices[lastIndex].pricePerPiece
							}
						} else {
							if (incquantity < buyItem.set.mapProductSetBulkPrices[i + 1].minimumOrderQuantity) {
								isAllValChecked = true
								pricePerPiece = item.pricePerPiece
							}
						}
					})
				}

			}

			arr[index].totalPrice = parseFloat((arr[index].optedQty) * (pricePerPiece * arr[index].totalPcOnSet)) + ((((arr[index].optedQty * pricePerPiece) * arr[index].totalPcOnSet) * arr[index].gstPercentage) / 100)
			arr[index].priceWithoutGSt = parseFloat((arr[index].optedQty) * (arr[index].totalPcOnSet * pricePerPiece))
			//arr[index].priceGst = parseFloat((((arr[index].optedQty) * (arr[index].totalPcOnSet * pricePerPiece)) * arr[index].gstPercentage) / 100)

			let gstPriceBeforeRoundOff = parseFloat(((arr[index].gstPercentage) * (arr[index].totalPcOnSet * pricePerPiece)) / 100)
			let gstPriceAfterRoundOff = _.round(gstPriceBeforeRoundOff, 2);
			arr[index].priceGst = gstPriceAfterRoundOff * arr[index].optedQty;

			this.setState({ buyingOptions: arr }, () => {
				this.countQty()
			})
		}
	}

	addItemToCart = async () => {
		const { buyingOptions } = this.state;
		const { t } = this.props;
		let cartData = [];

		buyingOptions && buyingOptions.forEach((item) => {
			if (item.optedQty > 0) {
				let obj = { mapProductSetId: item.set.id, quantity: item.optedQty }
				cartData.push(obj);
			}
		})
		let x = await CartService.addItemsToCart(cartData);
		if (x.data.isSuccess) {
			this.handleCancel();
		}
		else {
			message.error(x.data.message || `${t('Error in saving')}`)
		}
	}

	buyNowCart = async () => {
		const { buyingOptions } = this.state

		const { dispatch, t } = this.props
		let cartData = []

		buyingOptions && buyingOptions.forEach((item) => {
			if (item.optedQty > 0) {
				let obj = { mapProductSetId: item.set.id, quantity: item.optedQty }
				cartData.push(obj)
			}
		})
		dispatch(showLoader())

		let x = await CartService.addItemsToCart(cartData)
		if (x.data.isSuccess) {
			dispatch(hideLoader())
			this.navigate('/order-forms')
		}
		else {
			dispatch(hideLoader())
			message.error(x.data.message || `${t('Error in saving')}`)
		}
	}

	itemRender = (route, params, routes, paths) => {
		return (<Link
			to={route.path}
		// to={{ pathname: `/subcategory/${child.childCategoryId}`, search: queryString.stringify({ categoryId: item.childCategoryId, title: child.childCategory.name }) }}
		>{route.breadcrumbName}</Link>)

	}

	checkKycStatus = () => {
		const { currentUser } = this.props
		if (currentUser) {
			this.setState({ isOpenModal: true })
		}
	}

	setQuery = (e) => {

		let val = e.target.value

		let option = JSON.parse(JSON.stringify(this.state.buyingOptions))
		let result = option.filter(item => {
			if ((item.compatible).toLowerCase().includes(val.toLowerCase())) {
				return item
			}
		})
		// option.parentAttribute.mapAttributes = result
		this.setState({ buyingOptionsCopy: result })

	}

	showAllRelatedProducts = (item, index) => {
		const rootCategory = this.state.product.mapCategory.childCategory.name
		const attributeName = this.state.product.mapProductAttributes[index].mapAttribute.parentAttribute.key
		const attributeValue = this.state.product.mapProductAttributes[index].mapAttribute.childAttribute.value
		let payload = {
			query: item,
			filterAttributes: {
				"filters": "(categoryList.parentCategory.name:\"" + rootCategory + "\" OR " + "attribute." + attributeName + ":\"" + attributeValue + "\")"
			}
		};
		let encoded = Buffer.from(JSON.stringify(payload), 'ascii').toString('base64');
		let title = encodeURIComponent(item)
		this.props.history.push({
			pathname: `/search/listings/title=${title}/${encoded}`
		})

	}

	render() {

		const { loading, currentUser, token, t } = this.props
		const { images, hideLessBtn, specificationsArr, productName,
			product, isAvailable, placement, drawerVisible,
			quantity, buyingOptions, buyingOptionsCopy, isGSTIncluded, showEditProduct,
			productMinMaxPrice, productMinMaxPriceWithoutGst, retailMargin,
			breadcrumbRoutes, tagsArr, showProductImages, kycApproved,
			isOpenModal, pendingKyc, setAlertDiv, btnText, isExpired, similarProducts,productRating,totalRatings } = this.state;
		if (setAlertDiv) {
			setTimeout(() => {
				this.setState({ setAlertDiv: false })
			}, 3000);
		}

		const smallImgStyl = { width: '100%', height: '100%', borderRadius: '2px', objectFit: 'cover' }
		const fixedFooter = <>{quantity > 0 ? <div>
			<div className={'totalPrice'}>
				<Row>
					<Col sm={20} xs={14}><div className={'pl-3 smallestFont textATBottom'}>{t('Total qty')}: {quantity}</div></Col>
					<Col sm={4} xs={10}>
						<div className={'priceColor'}><b>₹ {this.state.amount}</b></div>
						<div className='smallestFont'>{`₹ ${this.state.amountWithOutGst} + ₹ ${this.state.gstPrice} `}{t('Tax')}</div>
					</Col>
				</Row>
			</div>
			<Row>
				<Col span={12}><Button className={'orderFormBtn'} onClick={this.addItemToCart}>{t('Add to order form')}</Button></Col>
				<Col span={12}><Button className={'buynowBtn'} id='buyNowButtonIdTwo'
					loading={loading} onClick={this.buyNowCart}>{t('Buy now')}</Button></Col>
			</Row></div> : <div className={'bottomLabel'}>{t('Add sets')}</div>}
		</>

		return (
			<>
			{/* {
				window.location.href.includes("view-product")
				? <Helmet>
						{productName &&  <meta property="og:title" content={productName} /> }
						{images.length>0 && <meta property="og:image" itemprop="image" content={images[0].original} />}
						<meta property="og:image:width" content="300"/>
						<meta property="og:image:height" content="300"/>
						
						{product.description &&  <meta property="og:description" content={product.description} />}
						<meta property="og:url" content={window.location.href} />
						<meta property="og:type" content="website" />
                 </Helmet>
				: 
				<Helmet>
					 <link rel="icon" href="%PUBLIC_URL%/favicon.png" />
					 <meta property="al:android:url" content="app://dev.akarindia.com" />
    				 <meta property="al:android:app_name" content="Akar" />
    				 <meta property="al:android:package" content="com.akar" />
    				 <meta property="al:web:url" content="https://dev.akarindia.com/#/" />
					 <meta name="title" content="Akar India"></meta>
					 <meta name="description" content="B2B solutions for your clothing needs" />
				</Helmet>
				
				
			} */}
			
				{product &&
					<LayoutComponent title={<div className={'py-3'}>
						<div className={'flexContainer'}>
							<div className={'flexFullDiv'}>
								<div className={'responsive_web'}>
									<b>{product && product.account && product.account.name ? `${product.account.name}` : ''}</b>
								</div>
								<div className={'font12px responsive_mobile'}>
									<b>{
										product && product.account && product.account.name &&
											product.account.name.length > 15 ? `${product.account.name.slice(0, 15)}...` :
											product && product.account && product.account.name ? `${product.account.name}` : ''}
									</b>
								</div>
								<div className={'textcolor font13px responsive_mobile'}>
									{product && product.account && product.account.addresses && product.account.addresses.length ? `${product.account.addresses[0]['city']}` : ''}
								</div>
								<div className={'textcolor font12px responsive_web'}>
									{product && product.account && product.account.addresses && product.account.addresses.length ? `${product.account.addresses[0]['city']}, ${product.account.addresses[0]['stateName']}` : ''}
								</div>
							</div>
						</div>
					</div>}>
						<div className={'produt_head'}>
							<Breadcrumb itemRender={this.itemRender} routes={breadcrumbRoutes} separator=">" />
						</div>
						<div className={'productContainer'}>
							<Row className={'product_Row'}>
								{images.length ? <>
									<Col sm={12} xs={24} className={'imgColumn'} >
										<div>
											<Card
												bodyStyle={{ padding: '0px 0px 8px' }}
												bordered={false}
												style={{ margin: '8px 10px 25px' }}>
												<div>
													<ImageGallery
														ref={this.myImageGallery}
														onSlide={this._onSlide.bind(this)}
														items={showProductImages ? images : this.state.setImages}
														showBullets={images.length > 1 ? true : false}
														// showBullets={ this.state.showThumb ? false : true}
														showPlayButton={false}
														showThumbnails={this.state.showThumb ? true : false}
														showFullscreenButton={true}
														onScreenChange={(val) => {
															this.setState({ showThumb: val })
															if (val === false) {
																this.setState({ showProductImages: true }, () => {
																	this._resetVideo();
																})
															}
														}}
														thumbnailPosition={'bottom'}
													/>
												</div>

												<div className={'mt-4'}>

													{
														kycApproved
															? <Button
																className={'buynowBtn'}
																id='buyNowButtonId'
																style={{
																	height: '40px',
																	visibility: isExpired || !isAvailable || showEditProduct || product.status !== 'Approved' ? 'hidden' : 'visible'
																}}
																onClick={this.showDrawer}>
																{showEditProduct ? `${t('View details')}` : `${t('Buy now')}`}
															</Button> :
															<Button
																className={'buynowBtn'}
																style={{ height: '40px' }}
																disabled={pendingKyc ? true : false}
																onClick={this.checkKycStatus}>
																{(btnText === 'KYC verification is under process') ? `${t('KYC verification is under process')}` : null}
																{(btnText === 'Complete KYC') ? `${t('Complete kyc')}` : null}
															</Button>
													}

													<Button
														className={'buynowBtn'}
														style={{
															height: '40px',
															visibility: showEditProduct ? 'visible' : 'hidden'
														}}
														onClick={this.showDrawer}>
														{t('View details')}
													</Button>

												</div>
											</Card>

										</div>
									</Col>
									<Col sm={12} xs={24} className={'productColumn'}>
										<div style={{ overflowY: 'scroll', height: '580px' }}>
											<Card size={'small'} bordered={false}
												className={'product_Card'}
												bodyStyle={{ background: '#f9fafb', padding: '0px' }}
											>
												<Row>
													<Col span={22} >
														<p className="mb-1">{productName}</p>
													</Col>
													{this.state.isAvailable ?
														<Col span={2} style={{ background: 'white', paddingRight: '12px', paddingTop: '12px' }} >
															<a
																onClick={() => {
																	window.location.href = ''
																}}
																href={`mailto:?subject=${this.state.productName}&body=Check out this product ${window.location.href} .`}
																title="Share by E-Mail">
																<ShareAltOutlined
																	style={{ fontWeight: "700", fontSize: "24px" }} />
															</a>
														</Col> : null}
												</Row>
												<div className={'btnsDiv'}>
													<div>
														{
															showEditProduct
																? <Button
																	className={'productBtn smallbtn'}
																	onClick={(e) => this.editProductList()}>
																	{t('Edit listing')}
																</Button>
																: null
														}
														{
															showEditProduct
																? <Button
																	className={'productBtn smallbtn'}
																	onClick={(e) => this.cloneProductList()}>
																	Clone Listing
																</Button>
																: null
														}
														{
															showEditProduct && product.status === 'Approved' && !product.isExpired
																? <span className={'availSpan'}>
																	{isAvailable ? `${t('Available')}` : `${t('Unavailable')}`}
																	<Switch checked={isAvailable} onChange={this.onSwitchChange} />
																</span>
																: null
														}

													</div>
												</div>

												{
													isExpired
														? <div
															style={{
																fontSize: '14px',
																color: 'red',
																fontWeight: 'bold',
																padding: '5px 15px'
															}}>
															{t('Product expired')}
														</div>
														: null
												}
												{
													product.status === 'Rejected'
														? <div
															style={{
																fontSize: '14px',
																color: '#333',
																padding: '5px 15px'
															}}>
															{t('Reason of rejection')}:  {product.reason}.
														</div>
														: null
												}

											</Card>
											<Card size={'small'} className={'price_card'} bordered={false}>
												{token && <div>
													<div className={'price_div'} >
														{product && product.mapProductSets &&
															< span >
																<span className='actualPrice'>  <b className='price_heading'>₹ {productMinMaxPrice} </b>{t('per pc')}</span>
															</span>
														}
														<span style={{ float: 'right' }}><b className='smlHeading'>{t('Min. qty')}</b></span>
													</div>
													<div className={'price_div'} >
														<span className='smlText'>{`(₹ ${productMinMaxPriceWithoutGst} + ${product.gstMaster && product.gstMaster.percentage}% ${t('GST')})`}</span>
														{/* <span className='smlText'>{`(₹ ${product.minPrice} - ${product.maxPrice} + ${product.gstMaster && product.gstMaster.percentage}% ${t('GST')})`}</span> */}

														<span style={{ float: 'right' }}><b className='smlHeading'>{product && product.mapProductSets && product.mapProductSets[0].minimumSetOrder} {t('Set')} ({product && product.mapProductSets && product.mapProductSets[0].totalPcOnSet} {t('pcs')}) </b> </span>
													</div>
													{retailMargin ?
														<div className={'price_div'} >
															{product && product.mapProductSets &&
																< span >
																	<span className='actualPrice'>  <b > {retailMargin} </b>% {t('Retail Margin')}</span>
																</span>
															}
														</div>
														: null}
													{(buyingOptions[0]?.mrpPerPiece > 0) ?
														<div className={'price_div'} >
															<span className='smlText'>MRP: ₹ {buyingOptions[0].mrpPerPiece} </span></div> : null}

													<div className={'price_div'} >
														<div className='d-flex align-items-center'>
															{productRating? <div className="font-weight-bold mr-1 pt-1">({productRating})</div>:<div className="font-weight-bold mr-1 pt-1">(0)</div>}
															<Rating readOnly value={productRating} precision={0.1} />
													    </div>
														{totalRatings!=null ?

															<span className='smlText'>
																{`${totalRatings}`} total ratings
															</span>
															:
															<span className='smlText'>
																 No ratings available
															</span>}
													</div>

												</div>}
											</Card>

											<Card
												className={'specifications_card'}
												size={'small'}
												bordered={false} >
												<div className={'specdiv'}>
													<div className='detailsTableTitle'>{t('Specifications')}</div>
													{specificationsArr && specificationsArr.length > 0 && specificationsArr.map((item, index) => {
														return (<div key={index} className={'d-flex'}>
															<div className={'leftspec'}>{item.specKey}</div>
															<div className={'rightspec'}>
															{item.specValue.length>0 && item.specValue.map((i,ind)=>{

																if(i=="N/A" || i=="NA" || i=="na"){
																	return  (<div key={ind} className={'rightspecitem'}>{i}</div>)
																} else{
																	return (<div key={ind} className={'rightspecitem'}  onClick={() => this.showAllRelatedProducts(i,ind)}>{i}</div>)

																	}

																})}
															</div>

														</div>)
													})}
												</div>
											</Card>

											{product.description ? <Card size={'small'} className={'description_card'}
												bordered={false}>
												<div className='detailsTableTitle'>{t('Description')}</div>
												<div className={'description'}>{product.description}</div>
											</Card> : null}

											{tagsArr && tagsArr.length > 0 ? <Card className={'tags_card'} size={'small'} bordered={false}>
												<div className='detailsTableTitle'>{t('Tags')}</div>
												<div className='tagContainer'>
													<div className='tagsDiv'>
														{tagsArr && tagsArr.length > 0 && tagsArr.map((item, tagKey) => {
															return (<div className='tag' key={tagKey}>{item}</div>)
														})}

													</div>
												</div>
											</Card> : null}

											<Card className={'return_card'} size={'small'} bordered={false}>
												<div className='detailsTableTitle'>{t('Return policy')}</div>
												<Collapse>
													<Panel style={{fontWeight: 10, color: '#637381'}} showArrow={true} className={'description'} header={t('Fast and Easy returns')}>
														<div style={{marginLeft: "20px", fontSize: "10px"}}>
															<br></br>
															<div style={{borderBottom: "2px solid grey", fontSize: "11.3px"}}>
																<h3>{t('IT & Accessories')}</h3>
															</div>
															<br></br>
															<p style={{color: '#F26522'}}>{t('What returns are accepted?')}</p>
															<h2>{t('Returns are approved only after internal review')}</h2>
															<h3><b style={{fontSize: "12px"}}>{t('Under General Policy')}</b></h3>
															<div>
															<ul style={{listStyleType: "none", fontSize: "11px", marginLeft: "1rem"}}>
																<li>{t('Damaged Items')}</li>
																<li>{t('Wrong Items')}</li>
																<li>{t('Missing Items')}</li>
															</ul>
															</div>
															<div className='return_policy d-flex'>
																<div style={{marginRight: "10px", backgroundColor: "lightGrey", borderRadius: "5px", padding: "3px", border: "1px solid black"}}>{t('With Unpacking Video')}</div>
																<div style={{backgroundColor: "lightGrey", borderRadius: "5px", padding: "3px", border: "1px solid black"}}>{t('36 hours from Delivery')}</div>
															</div>
															<br/>
														</div>
													</Panel>
												</Collapse>
											</Card>
											
											<Card className={'buying_card'} size={'small'} bordered={false}>
												{token && <>
													<div className='detailsTableTitle'>{t('Buying options')}</div>

													{buyingOptions && buyingOptions.length > 0 && buyingOptions.map((item, index) => {
														let itemPrice = (item.pricePerPiece + ((item.pricePerPiece * product.gstMaster.percentage) / 100)) * item.totalPcOnSet
														itemPrice = _.round(itemPrice, 2)

														let priceWithoutgst = (item.pricePerPiece * item.totalPcOnSet)
														priceWithoutgst = _.round(priceWithoutgst, 2)

														return (<div key={index} className={'buying'}>

															<div className={'d-flex'}>
																<div style={{ width: '100%' }}>
																	<div className={'buyingFont'}>{t('Color')} : <b>{item.color}</b></div>
																	<div className={'buyingFont'}>{t('Size')} : <b>{item.size}</b></div>
																	{item && item.compatible ?
																		<div className={'buyingFont'}>{t('Compatible Device')} : <b>{item.compatible}</b></div>
																		: null}
																</div>

																{item.setImageUrls.length ? <div style={{ width: '44px', height: '44px', borderRadius: '2px' }}>
																	<img onClick={() => {
																		if (item.setImageUrls.length > 1) {
																			let setImagesArr = []
																			item.setImageUrls.forEach((item, key) => {
																				let obj = { original: item, thumbnail: item }
																				setImagesArr.push(obj)
																			})
																			this.setState({ showProductImages: false, setImages: setImagesArr }, () => {
																				this.myImageGallery.current.fullScreen()
																			})
																		}
																	}} src={item.setImageUrls[0]} alt={'smallimg'}
																		style={smallImgStyl} />
																</div> : <div></div>}
															</div>

															<div className={'buyingFont mb-1'}><b>{t('Set')} of {item.totalPcOnSet}</b></div>
															<div className={'buyingFont'}>
																<Collapse accordion={true} bordered={false}>
																	<Panel className='small-width' showArrow={false} header={t('View details')} key={index}>
																		{item.colorsWithSize && item.colorsWithSize.length > 0 &&
																			<table className={'productViewTable'}>
																				<thead>
																					<tr>
																						<th>{t('Color')}</th>
																						<th>{t('Size')} ({t('Qty')})</th>
																					</tr>
																				</thead>
																				<tbody>{item.colorsWithSize.map((itemColor, tablekey) => {
																					return (<tr key={tablekey}>
																						<td>{itemColor.color}</td>
																						<td>{itemColor.size}</td>
																					</tr>)
																				})}</tbody>
																			</table>
																		}
																	</Panel>
																</Collapse>
															</div>
															<div>
																<div className={'buyingFont'}>
																	<span><b className='fontSize14Px'>₹ {itemPrice} {t('per set')}</b></span>
																	<span style={{ float: 'right' }}>{t('Min. qty')}</span>
																</div>
																<div className={'buyingFont'}>
																	<span className='smallestFont'>(₹ {priceWithoutgst} + {product.gstMaster.percentage}% {t('GST')})</span>
																	<span style={{ float: 'right' }}><b>{item.minimumSetOrder} {t('Set')}</b></span>
																</div>
															</div>
														</div>)
													})}</>}
											</Card>
										</div>
									</Col>
								</> : <Skeleton active />}
							</Row>
						</div >

						{similarProducts && similarProducts.length > 1 ?
							<div style={{overflowX: 'scroll'}} className="toolbar_navigation-items mr-3 ml-3 mt-2 slider_div mb-4">
								<h4>
									{t('Similar products')}
								</h4>
								<hr />

								<ul className='product_div similarProductsList' >
									{
										similarProducts.length > 0
											? similarProducts.map((item, index) => {
												let priceWithGst;
												let price = Number(item.mapProductSets[0].pricePerPiece);
												let gstPercentage = Number(item.gstMaster.percentage);
												let gst = (Number(price) * gstPercentage) / 100;
												if (!item.isGSTIncluded) {
													let num = price + gst;
													priceWithGst = (Math.round(num * 100) / 100).toFixed(2);
												} else {
													priceWithGst = price;
												}
												if (item.id === product.id) return null;
												return (
													<div className="slider-card-container px-2">
														<ProductCard data={item} token={token} />
													</div>
												)
											})
											: <Skeleton active />}
								</ul>

							</div> : null}

					</LayoutComponent >

				}

				{isOpenModal ?
					<ModalComponent showModal={isOpenModal} onClose={() => {
						this.setState({ isOpenModal: false })
					}} /> : null
				}

				<Drawer
					title={productName}
					placement={placement}
					onClose={this.handleCancel}
					footer={!showEditProduct ? fixedFooter : null}
					visible={drawerVisible}
					maskClosable={true}
					height={550}
					key={placement}
					className='buyOptDrawer'>
					<div className={'qtyDrawer'}>
						{buyingOptions[0]?.compatible ? <div>
							<input placeholder="Search" className="akr-search-input mb-2" onChange={this.setQuery} />
						</div> : ''}
						{buyingOptionsCopy.length ?
							<>
								{buyingOptionsCopy && buyingOptionsCopy.map((item, cc) => {
									let itemPrice = (item.pricePerPiece + ((item.pricePerPiece * product.gstMaster.percentage) / 100)) * item.totalPcOnSet
									itemPrice = _.round(itemPrice, 2)
									return (<div className='setContainer' key={cc}>
										{(item.set.mapProductSetBulkPrices && item.set.mapProductSetBulkPrices.length) ?
											<table className={'productViewTable'}>
												<thead>
													<tr>
														<th>{t('Qty')}</th>
														<th>{t('Price')}</th>
													</tr>
												</thead>
												<tbody>
													<tr key='firstSet'>
														<td>{(item.set.mapProductSetBulkPrices[0].minimumOrderQuantity - 1)}  {('sets')}</td>
														{/* <td>₹{(item.pricePerPiece + ((item.pricePerPiece * product.gstMaster.percentage) / 100)) * item.totalPcOnSet} per set</td> */}
														<td>₹{itemPrice} {t('per set')}</td>
													</tr>
													{item.set.mapProductSetBulkPrices.map((bulkSet, bIndex) => {
														return (
															<tr key={bIndex}>
																{(item.set.mapProductSetBulkPrices.length === (bIndex + 1)) ?
																	<td> ≥ {bulkSet.minimumOrderQuantity} {('sets')}</td> :
																	<td>{bulkSet.minimumOrderQuantity} - {item.set.mapProductSetBulkPrices[bIndex + 1].minimumOrderQuantity - 1} {t('sets')}</td>
																}
																<td>₹{(bulkSet.pricePerPiece * item.totalPcOnSet + ((bulkSet.pricePerPiece * item.totalPcOnSet) * product.gstMaster.percentage) / 100).toFixed(2)} {t('per set')}</td>
															</tr>
														)
													})}
												</tbody>
											</table> : ''
										}
										<div className={'buyingBrder'} key={cc}>

											{item.setImageUrls.length ? <div className={'imgdiv'}>
												<img src={item.setImageUrls[0]} alt={'clothImg'} />
											</div> : <div className={'imgdiv'} ></div>
											}
											<div className={'textdiv'}>
												<div className={'textcolor'}><b>{t('Set')} of {item.totalPcOnSet}</b></div>
												<div className={'textcolor'}>{t('Min. qty')}: {item.minimumSetOrder} {t('Sets')}</div>
												<div className={'textcolor lightColor'}>{t('Color')}: <b className='fontSize12Px'>{item.color}</b> {t('Size')}: <b className='fontSize12Px'>{item.size}</b>{item.compatible ? <> {t('Compatible Device')}: <b className='fontSize12Px'>{item.compatible}</b></> : null}</div>
												{item.set.mapProductSetBulkPrices && !item.set.mapProductSetBulkPrices.length &&
													<div className={'priceColor'}>
														<b>₹ {itemPrice} {t('per set')}</b>
														{/* <b>₹ {(item.pricePerPiece + ((item.pricePerPiece * product.gstMaster.percentage) / 100)) * item.totalPcOnSet} per set</b> */}
													</div>
												}

												<div className={'textcolor'}>({item.totalPcOnSet} {t('pcs')} {t('per set')}) </div>

												<div className={'drawerCollapse'}>
													<Collapse accordion={true} bordered={false}>
														<Panel className='small-width' showArrow={false} header={t('View details')} key={cc}>
															{item.colorsWithSize && item.colorsWithSize.length > 0 &&
																<table className={'productViewTable'}>
																	<thead>
																		<tr>
																			<th>{t('Color')}</th>
																			<th>{t('Size')} ({t('Qty')})</th>
																		</tr>
																	</thead>
																	<tbody>{item.colorsWithSize.map((itemColor, tablekey) => {
																		return (<tr key={tablekey}>
																			<td>{itemColor.color}</td>
																			<td>{itemColor.size}</td>
																		</tr>)
																	})}</tbody>

																</table>
															}

														</Panel>
													</Collapse>
												</div>
											</div>
											<div>


												<div className={'counter'}>
													{!showEditProduct ? <>
														<div>
															<button id={'decreaseBtn'} disabled={hideLessBtn} onClick={() => { this.setState({ setAlertDiv: false }, (e) => this.decreaseQty(item, cc)) }}> - </button>
														</div>

														<div> {item.optedQty} </div>
														<div>
															<button id={'increaseBtn'} onClick={(e) => this.increaseQty(item, cc)}> + </button>
														</div>
													</> : null}
												</div>
												<div className="alertDiv ">{this.state.setAlertDiv ?
													<p style={{ color: "red" }}>No more stocks*</p> : null
												}
												</div>
											</div>

										</div>
									</div>)
								})}
							</> :
							<div className="text-center pt-2">
								No data found
							</div>
						}

					</div>
				</Drawer>
				<Footer />
			</>
		)
	}
}

const mapStateToProps = ({ global }) => ({
	loading: global.buttonLoading,
	token: global.token,
	currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductViewComponent)));
