import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Collapse, Button, Col, message, Row, Spin, Input } from 'antd';
import LayoutComponent from '../layoutComponents/layoutComponent';
import Footer from '../footer'
import moment from 'moment';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import CartService from '../../service/cart';
import imageCompression from 'browser-image-compression';
import MediaUrl from '../../service/mediaUrl';
import { FileAddOutlined, VideoCameraOutlined, CloseCircleFilled, FilePdfOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import BrandService from '../../service/brandService';
import {PDFDocument} from 'pdf-lib'
// var PDFDocument = window.PDFLib.PDFDocument;


const { Panel } = Collapse;
const { TextArea } = Input;

class CreateReturnComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderData: {},
      count: 0,
      loadVideo: false,
      itemDetails: {},
      initialOrderReturnData: [],
      orderLineItems: [],
      mapOrderReturnFileUploadedDatas: [],
      returnReason: "",
      comment: '',
      loadText: false,
      imgArr: [],
      videoArr: [],
      videoLoader: false,
      openPanel: 1,
      videoFile: null,
      imgLoader: false,
      invoiceLoader: false,
      invoiceArr: [],
      returnReasons: [
        {
          id: 1,
          title: 'Performance or quality not adequate.',
          selected: false
        },
        {
          id: 2,
          title: 'Product damaged but delivery box OK.',
          selected: false
        },
        {
          id: 3,
          title: 'Missing part or accessories.',
          selected: false
        },
        {
          id: 4,
          title: 'Both product and shipping box damaged.',
          selected: false
        },
        {
          id: 5,
          title: 'Wrong items were sent',
          selected: false
        },
        {
          id: 6,
          title: "Items defective or doesn't work",
          selected: false
        }
      ]

    }
  }

  decrement = (e, orderQuantity, items) => {
    let { orderLineItems } = this.state
    orderLineItems.forEach((val, index) => {
      if (items == val.id) {
        if (val.returnQuantity > 0) {

          val.returnQuantity = val.returnQuantity - 1
        }
      }
    })
    this.setState({ orderLineItems: orderLineItems })
  }

  increment = (e, orderQuantity, items) => {
    let { orderLineItems } = this.state
    orderLineItems.forEach((val, index) => {
      if (items == val.id) {
        if (val.returnQuantity < val.vendorQuantity) {
          val.returnQuantity = val.returnQuantity + 1
        }
      }
    })
    this.setState({ orderLineItems: orderLineItems })

  }

  onChange = key => {
    this.setState({
      openPanel: key
    });
  }

  addMediaInAWS = (data, type) => {
    BrandService.addImage(data, 'PRODUCT_DISPLAY_FILE')
      .then((res) => {
        let data = res.data.data[0]
        let obj = {}
        switch (type) {
          case "invoice":
            {
              obj = {
                "type": "Invoice Document",
                fileUploadedData: {
                  "name": data.fileName,
                  "url": data.url
                }
              }
              break
            }
          case "image":
            {
              obj = {
                "type": "Issue File",
                fileUploadedData: {
                  "name": data.fileName,
                  "url": data.url
                }
              }

              this.setState({ imgArr: [...this.state.imgArr, data.url] })
              break
            }
          case "video":
            {
              obj = {
                "type": "Video File",
                fileUploadedData: {
                  "name": data.fileName,
                  "url": data.url
                }
              }
              let videoArr = this.state.videoArr
              this.setState({ videoArr: [...this.state.videoArr, data.url], videoLoader: false })
              break
            }
        }
        this.setState({
          mapOrderReturnFileUploadedDatas: [...this.state.mapOrderReturnFileUploadedDatas, obj], invoiceLoader: false
        })
      }, err => {
        console.log(err)
      })
  }

  returnFileSize = (number) => {
    if (number < 1024) {
      return number + 'bytes';
    } else if (number >= 1024 && number < 1048576) {
      return (number / 1024).toFixed(1) + 'KB';
    } else if (number >= 1048576) {
      return (number / 1048576).toFixed(1) + 'MB';
    }
  }

  onImageChange = async (event) => {
    const { t } = this.props
    this.setState({ imgLoader: true });
    let imageFile = event.target.files[0];
    const fileExtn = imageFile.name.substr(imageFile.name.lastIndexOf("."))
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    if ((fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png') && imageFile) {
      try {
        let blob = imageFile.slice(0, imageFile.size, imageFile.type);
        imageFile = new File([blob], moment().valueOf() + fileExtn, { type: imageFile.type });
        const compressedFile = await imageCompression(imageFile, options);
        var url = URL.createObjectURL(compressedFile)
        this.addMediaInAWS(imageFile, "image")
      }
      catch (error) { }
    } else {
      return message.error(`$Attach image in png/jpg/jpeg file format')}`)
    }
    this.setState({imgLoader: false})
    document.getElementById('myImageInput').value = null
  }

  onChangeVideo = async (e) => {
    this.setState({ videoLoader: true });
    let selectedVideoFile = e.target.files[0]
    const fileExtn = selectedVideoFile.name.substr(selectedVideoFile.name.lastIndexOf("."))
    if ((fileExtn === '.mov' || fileExtn === '.mp4') && selectedVideoFile) {
      let fileSize = this.returnFileSize(selectedVideoFile.size)
      if (selectedVideoFile.size >= 20971520) {
        return message.error(`${('Video size limit validation')}`)
      } else {
        let blob = selectedVideoFile.slice(0, selectedVideoFile.size, selectedVideoFile.type);
        selectedVideoFile = new File([blob], moment().valueOf() + fileExtn, { type: selectedVideoFile.type });
        var url = URL.createObjectURL(selectedVideoFile)
        this.addMediaInAWS(selectedVideoFile, 'video')
      }
    } else {
      return message.error(`${('Attach video in mp4/mov file format')}`)
    }
    document.getElementById("myVideoInput").value = null;
  }

  onInvoiceChange = async (e) => {
    const { t } = this.props
    let invoiceArr = this.state.invoiceArr
    if(invoiceArr.length > 0){
      this.setState({ invoiceLoader: false })
      message.warning(`${t("Cannot upload more than one file")}`)
    }
    else{
      this.setState({ invoiceLoader: true })
      let invoiceFile = e.target.files[0];
      const fileExtn = invoiceFile ? invoiceFile.name.substr(invoiceFile.name.lastIndexOf(".")) : ""
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      if ((fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png') && invoiceFile) {
        try {
          let blob = invoiceFile.slice(0, invoiceFile.size, invoiceFile.type);
          invoiceFile = new File([blob], moment().valueOf() + fileExtn, { type: invoiceFile.type });
          const compressedFile = await imageCompression(invoiceFile, options);
          var url = URL.createObjectURL(compressedFile)
          invoiceArr.push(url)
          this.setState({ invoiceArr })
          this.addMediaInAWS(invoiceFile, "invoice")
        }
        catch (error) { }
      }
      else if ((fileExtn === '.pdf') && invoiceFile) {
        try{   
          let blob = invoiceFile.slice(0, invoiceFile.size, invoiceFile.type);
          invoiceFile = new File([blob], moment().valueOf() + fileExtn, { type: invoiceFile.type });
          var url = URL.createObjectURL(invoiceFile)
          const pdfBytes = await fetch(url).then((res) => res.arrayBuffer());
          const pdfDoc = await PDFDocument.load(pdfBytes, {ignoreEncryption: true} );
          const isEncrypted = pdfDoc.isEncrypted;

          if(isEncrypted){
            this.setState({ invoiceLoader: false })
            message.error(`${t("Password protected documents cannot be uploaded")}`)
          }
          else{
            invoiceArr.push(url)
            this.setState(invoiceArr)
            this.addMediaInAWS(invoiceFile, "invoice")
          }
        }
        catch(error) { }
      }
      else {
        return message.error(`$Attach invoice in pdf or jpg file format')}`)
      }
    }
  }

  deleteImage = (file, index) => {
    this.deleteDocImageFromAWS(file)
    var array = [...this.state.imgArr];
    array.splice(index, 1);
    this.setState({ imgArr: array });
  }

  deleteVideo = (file, index) => {
    this.deleteDocImageFromAWS(file)
    var array = [...this.state.videoArr];
    array.splice(index, 1);
    this.setState({ videoArr: array });
  }

  deleteInvoice = (file, index) => {
    this.deleteDocImageFromAWS(file)
    let array = [...this.state.mapOrderReturnFileUploadedDatas];
    array.splice(index, 1);
    this.setState({ mapOrderReturnFileUploadedDatas: array });

    let fileList = [...this.state.invoiceArr];
    fileList.splice(index, 1);
    this.setState({invoiceArr: fileList});
  }

  deleteDocImageFromAWS = (data, index) => {
    BrandService.deleteImage(data)
      .then((res) => { }, err => { })
  }

  handleOrderReturnPanel = (id) => {
    let result = this.state.orderLineItems.map(a => a.returnQuantity);
    const data = result.some((element) => {
      return element > 0
    })
    if (data == true) {
      this.setState({ openPanel: 2 })
    }
    else {
      message.error("Please select atleast one order item to return")
    }
  }

  handleReturnReasonPanel = () => {
    if (this.state.returnReason.length > 0) {
      this.setState({ openPanel: 3 })
    }
    else {
      message.error("Please select any of the one reason")
    }
  }

  handleDescriptionPanel = () => {
    if (this.state.comment.length > 0) {
      this.setState({ openPanel: 1 })
    }
    else {
      message.error("Please briefly explain the reason of your return")
    }
  }

  getReturnItemDetails = async (orderId) => {
    let response = await CartService.orderById(orderId)
    let order = response?.data.data;
    let orderLineItemsData = [];
    if (order) {
      order.orderLineItems.forEach(element => {
        let obj = { ...element };
        obj.returnQuantity = 0
        obj["orderLineItemId"] = element.id;
        obj["mapProductSetId"] = element.productOrderLineItem.mapProductSetId;
        obj["vendorQuantity"] = element.vendorQuantity;
        orderLineItemsData.push(obj);
      });
      this.setState({ orderData: response.data.data, initialOrderReturnData: orderLineItemsData, orderLineItems: orderLineItemsData })
    }
  }

  createReturn = async (orderId) => {
    let { comment,invoiceArr, imgArr,videoArr, orderLineItems, returnReason, mapOrderReturnFileUploadedDatas } = this.state
    let newArr = []
    orderLineItems.forEach((item) => {
      if (item.returnQuantity > 0) {
        newArr.push({
          orderLineItemId: item.orderLineItemId,
          mapProductSetId: item.mapProductSetId,
          vendorQuantity: item.returnQuantity
        });
      }
    });

    if (!newArr.length) {
      message.error("Please select atleast one order item to return")
      return
    }
    else if(!invoiceArr.length){
      message.error("Please upload invoice")
      return
    }
    else if(!imgArr.length && !videoArr.length){
      message.error("Please upload media file")
      return
    }

    else if (!returnReason) {
      message.error("Please select any of the one reason")
      return;
    }
    else if (!comment) {
      message.error("Please briefly explain the reason of your return")
      return;
    }

    const dataToPost = {
      comment, Reason: returnReason,
      orderReturnLineItems: newArr,
      mapOrderReturnFileUploadedDatas: mapOrderReturnFileUploadedDatas
    }
    let response = await CartService.createOrderReturn(orderId, dataToPost)
    if (response.isSuccess) {

      message.success(response.data)
      this.setState({ comment: '', returnReason: '' })
      this.props.history.push({ pathname: '/your-returns' })
    } else {
      this.setState({ comment: '', returnReason: '' })
      message.error(response.data.message)
    }
  }

  componentDidMount() {
    let orderId = this.props.match.params.orderId;
    this.getReturnItemDetails(orderId);
  }

  handleReturnReason = (item) => {
    let { returnReasons } = this.state;
    returnReasons.forEach(reason => {
      if (reason.id == item.id) {
        reason.selected = true;
      } else {
        reason.selected = false;
      }
    })
    this.setState({ returnReason: item.title })
  }
  imageLoadHandler = () => {
    this.setState({ loadText: true });
  };
  handleReturnComment = (e) => {
    let comment = e.target.value;
    this.setState({ comment: comment })
  }

  render() {
    const { imgArr, mapOrderReturnFileUploadedDatas, videoArr, orderData, videoLoader, imgLoader, orderLineItems,
      returnReasons, openPanel, invoiceLoader, loadText } = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    return (<>
      <LayoutComponent >
        <div className="mx-2" >
          <div className='cart-item m-md-5 m-0' >
            <Col xl={24} >
              <h3 className='p-3 text-center'>Order Details</h3>

              <div className={'order_div'}>
                <div className={'d-flex col-12'}>
                  <p className={'title col-6'}>Ordered On</p>
                  <p className={'order_text col-6'}>{orderData ? moment.utc(orderData.createdOn).utcOffset('+0530').format('lll') : ''}</p>
                </div>

                <div className={'d-flex col-12'}>
                  <p className={'title col-6'}>ORDER ID</p>
                  <p className={'order_text col-6'}>{orderData ? orderData.orderId : null}</p>
                </div>

                <div className={'d-flex col-12'}>
                  <p className={'title col-6'}>Order Amount (excluding - GST)</p>
                  <p className={'order_text col-6'}>₹ {orderData ? orderData.amountWithoutGst : 0}</p>
                </div>

                <div className={'d-flex col-12'}>
                  <p className={'title col-6'}>Total GST</p>
                  <p className={'order_text col-6'}>₹ {orderData ? orderData.totalGst : ''}</p>
                </div>

                <div className={'d-flex col-12'}>
                  <p className={'title col-6'}>Buyer GST Number</p>
                  <p className={'order_text col-6'}>{orderData && orderData.buyerGstNumber ? orderData.buyerGstNumber : `${('Not Available')}`}</p>
                </div>

                <div className={'d-flex col-12'}>
                  <p className={'title col-6'}>Seller GST Number</p>
                  <p className={'order_text col-6'}>{orderData && orderData.sellerGstNumber ? orderData.sellerGstNumber : `${('Not Available')}`}</p>
                </div>
                <div className={'d-flex col-12'}>
                  <p className={'title col-6'}>Delivery Address</p>
                  <p className={'order_text col-6'}>{orderData && orderData.deliveryOrderAddress ? `${orderData.deliveryOrderAddress.addressLine1} ${orderData.deliveryOrderAddress.addressLine2}, ${orderData.deliveryOrderAddress.zipCode}` : ''}</p>
                </div>
              </div>
            </Col>
          </div>
          <div className='m-md-5 m-0 mt-3'>
            <Collapse activeKey={openPanel} dropdownMatchSelectWidth={false} onChange={(key) => this.setState({ openPanel: key })} >
              <Panel header="Order Return*" id="panelOrderReturn" key="1" className='innerDiv' >
                <div id='order-return' className='panel-text innerDiv'>
                  <div className="col-12 d-flex flex-column innerDiv">

                    {orderLineItems?.map((item, index) => {
                      let url = item.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url
                      return (
                        <div key={index} className="cart-item p-3 m-2 ">
                          <div key={index} className="d-flex flex-row innerDiv">
                            <img className="col-md-3 col-lg-2 col-sm-4 productToBeReturnedImg" src={MediaUrl.completeMediaUrl(url)} alt="" />
                            <div className="col-md-7 col-4 quantty-div">
                              <h6>{item.name}</h6>
                              <h6>Quantity Ordered: {item.vendorQuantity}</h6>
                            </div>
                            <div className="pt-4">
                              <div className='counter ml-6 '>
                                <div>
                                  <button className='decrement-box' onClick={(e) => this.decrement(e, item.quantity, item.id)}> - </button>
                                </div>
                                <div className="d-flex quantity-box">
                                  {item.returnQuantity}

                                </div>
                                <div className="d-flex increment-box">
                                  <button onClick={(e) => this.increment(e, item.quantity, item.id)}> + </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className="p-2 m-2 fw-bolder nextBtndiv">

                  <Button className="nextBtn" id="nextBtn-one" onClick={() => this.handleOrderReturnPanel()} >Next</Button>

                </div>
              </Panel >

              <Panel header="Select Reason*" key="2"  >
                <div className='panel-text'>
                  <div className="m-3 fw-bolder">
                    <h6>Select a reason that best describes your problem with the product</h6>
                  </div>

                  {returnReasons && returnReasons.map((item, ind) => (<div onClick={() => this.handleReturnReason(item)} key={ind} className="cart-item  m-2 returnReason">
                    {item.selected ? <div className="col-12 pt-3 d-flex flex-column returnReason" style={{ backgroundColor: "#f26522",height:"100%", color: "white", borderRadius: "10px" }}>
                      <p>{item.title} </p>
                    </div> :
                      <div  className="col-12 pt-3 d-flex flex-column returnReason">
                        <p id="return-reason" >{item.title} </p>
                      </div>}
                  </div>))}
                </div>
                <div className="p-2 m-2 fw-bolder nextBtndiv">
                  <Button className="nextBtn"  onClick={() => this.setState({ openPanel: 1 })}>Back</Button>
                  <Button className="nextBtn" id="nextBtn-two" onClick={() => this.handleReturnReasonPanel()}>Next</Button>
                </div>
              </Panel>

              <Panel header="Add Media*" key="3"  >
                <div className='p-4' >
                  <p className='mb-0'>Upload Image</p>
                  <div className='mediaDiv'>
                    <input id="myImageInput" type="file" ref={(ref) => this.myImageInput = ref}
                      onChange={this.onImageChange.bind(this)}
                      accept=".png,.jpg,.jpeg"
                      style={{ display: 'none' }} />
                    
                    <button className="float-right mr-3 file imageUploader" onClick={() => {
                      this.myImageInput.click()
                    }}><FileAddOutlined /></button>

{
                        imgLoader ?
                          <Spin indicator={antIcon} size={'small'} className="mediaSpinner" /> :
                    <div style={{ display: 'flex' }} >
                      {
                        imgArr.map((item, i) => {
                          return (
                            <div> 
                                <div key={i} className='file'>
                                  <a className="imgesFile"
                                    href={MediaUrl.completeMediaUrl(item, true, 100, 100)}
                                    target="_blank">
                                    <img src={MediaUrl.completeMediaUrl(item, true, 100, 100)} alt='Something wrong'
                                    // onLoad={this.imageLoadHandler}
                                    />
                                  </a>
                                  <span className="remove-btn"
                                    style={{ position: 'absolute', top: '-20%', right: '-3%', cursor: 'pointer' }}
                                    onClick={() => this.deleteImage(item, i)}>
                                    <CloseCircleFilled />
                                  </span>
                                </div>
                            
                            </div>
                          )
                        }).reverse()
                      }
                    </div>
                        }

                  </div>
                
                  <p className='mb-0 mt-3'>Upload Video</p>
                  <div className='mediaDiv'>

                    <input id="myVideoInput" type="file" ref={(ref) => this.myVideoInput = ref}
                      onChange={this.onChangeVideo.bind(this)}

                      accept=".mp4,.mov"
                      style={{ display: 'none' }} />

                    <button className="float-right mr-3 file videoUploader" onClick={() => {
                      this.myVideoInput.click()
                    }}><VideoCameraOutlined /></button>
                    {videoLoader ?
                      <Spin indicator={antIcon} size={'small'} className="mediaSpinner" /> :
                      <div style={{ display: 'flex' }}>

                        {videoArr.map((item, i) => {
                          return (<div key={i} style={{
                            margin: '10px',
                            width: '300px', height: '150px',
                            position: 'relative'
                          }}>

                            <video src={MediaUrl.completeMediaUrl(item, true, 100, 100)}
                              controls
                              width="100%"
                              height="100%"
                              controlsList="nodownload"
                              autoPlay={false} alt="video"
                            />

                            <span className="remove-btn remove-videoBtn"
                              style={{ position: 'absolute', top: '-20%', right: '0%', cursor: 'pointer' }}
                              onClick={() => this.deleteVideo(item, i)}>
                              <CloseCircleFilled />
                            </span>

                          </div>)
                        })}
                      </div>}
                  </div>

                </div>
                <div className="p-2 m-2 fw-bolder nextBtndiv">
                  <Button className="nextBtn" onClick={() => this.setState({ openPanel: 2 })}>Back</Button>
                  <Button className="nextBtn" id="nextBtn-three"onClick={() => this.setState({ openPanel: 4 })}>Next</Button>
                </div>
              </Panel>

              <Panel header="Upload Invoice*" key="4" >
                <div className='panel-text'>
                  <div className='mediaDiv'>
                    <input id="myInvoiceInput" type="file" ref={(ref) => this.myInvoiceInput = ref}
                      onChange={this.onInvoiceChange.bind(this)}
                      accept=".jpg, .jpeg, .png, .pdf"
                      style={{ display: 'none' }}
                    />

                    <Button className='mr-3 mt-4 invoiceUploader' onClick={() => { this.myInvoiceInput.click() }}
                      icon={<UploadOutlined />}>Upload</Button>
                    {invoiceLoader ?
                      <Spin indicator={antIcon} size={'small'} className="mediaSpinner" /> :
                      <div style={{ display: 'flex' }}>
                        {mapOrderReturnFileUploadedDatas.map((item, i) => {
                          let fileUploadedDataUrl = item.fileUploadedData.url
                          let pdfCondition = fileUploadedDataUrl && fileUploadedDataUrl.includes(".pdf");
                          const fileExtn = fileUploadedDataUrl.substr(fileUploadedDataUrl.lastIndexOf("."))
                          if (pdfCondition && item.type === 'Invoice Document') {
                            let url = MediaUrl.completeMediaUrl(fileUploadedDataUrl, false, 100, 100)
                            return (<div key={i} className='file'>
                              <a className="fileDoc"
                                href={`${url}`}
                                target="_blank"><FilePdfOutlined /></a>
                              <span className="remove-pdf"
                                onClick={() => this.deleteInvoice(fileUploadedDataUrl, i)}>
                                <CloseCircleFilled />
                              </span>
                            </div>)
                          }
                          else if ((fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png') && item.type === 'Invoice Document') {
                            let url = MediaUrl.completeMediaUrl(fileUploadedDataUrl, true, 100, 100)
                            return (<div key={i} className='file'>
                              <a className="imgesFile"
                                href={url}
                                target="_blank">
                                <img src={url}
                                  alt='Preview unavailable' /></a>
                                <span className="remove-btn"
                                  style={{ position: 'absolute', top: '-20%', right: '-3%', cursor: 'pointer' }}
                                  onClick={() => this.deleteInvoice(fileUploadedDataUrl, i)}>
                                  <CloseCircleFilled />
                                </span>
                            </div>)
                          }
                          else {
                            return null;
                          }

                        }).reverse()}
                      </div>}
                  </div>

                </div>
                <div className="p-2 m-2 fw-bolder nextBtndiv">
                  <Button className="nextBtn" onClick={() => this.setState({ openPanel: 3 })}>Back</Button>
                  <Button className="nextBtn" id="nextBtn-four"onClick={() => this.setState({ openPanel: 5 })}>Next</Button>

                </div>
              </Panel>

              <Panel header="Briefly explain your problem*" key="5" >
                <div className='panel-text'>
                  <TextArea rows={6} id="description-panel" placeholder="Start Typing..." onChange={(e) => { this.handleReturnComment(e) }} />
                </div>
                <div className="p-2 m-2 fw-bolder nextBtndiv">
                  <Button className="nextBtn" onClick={() => this.setState({ openPanel: 4 })}>Back</Button>

                </div>
              </Panel>
              <div className="p-2 m-2 fw-bolder nextBtndiv">
                <Button className="nextBtn" onClick={() => this.handleDescriptionPanel()}>Back To Top</Button>
              </div>
            </Collapse>
          </div>
          <Row className='mx-2' >
            <Col span={24}>
              <Button block className={'proceedBtn'}

                onClick={() => this.createReturn(this.state.orderData.orderId)} >Create Return
              </Button>
            </Col>
          </Row>
        </div >
      </LayoutComponent>
      <Footer />
    </>)
  }
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser,
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateReturnComponent)));
