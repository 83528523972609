import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import myimage from '../../imageNotFound.jpeg'
import { Row, Col, Input, AutoComplete } from 'antd';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import AddListingService from '../../service/addListingService'
import SaveListing from '../../service/saveListingService'
import { Accordion, Card } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import MediaUrl from '../../service/mediaUrl'
import Footer from '../footer'

const { Search } = Input;
const { Option } = AutoComplete;

class CategoryComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			categoryHierarchy: [],
			selectedCategoryName: '',
			childCategory: [],
			result: [],
			parentCategoryId: null,
			parentCategory: {}
		}
	}

	componentDidMount() {
		this.getCategoryHierarchy()
		let id = typeof window !== 'undefined' ? localStorage.getItem('id') : null
		if (id) {
			if(typeof window !== 'undefined')  localStorage.removeItem('id')
		}
	}

	getCategoryHierarchy = () => {
		AddListingService.getCategoryHierarchy(0)
			.then((res) => {
				this.setState({
					categoryHierarchy: res.data.data.mapCategories
				})
			}, err => { })
	}

	changeChildCategory = (data) => {
		this.setState({
			childCategory: data.childCategory.mapCategories,
			selectedCategoryName: data.childCategory.name
		})
	}

	changeParentCategory = (data) => {
		this.setState({
			parentCategoryId: data.id,
			parentCategory: data,
			childCategory: [],
			selectedCategoryName: []
		})
	}

	navigate = (url) => {
		this.props.history.push({ pathname: url })
	}

	createNewProduct = (id) => {

		SaveListing.mapCategoryId = id
		SaveListing.isDataStoredFromApi = false
		SaveListing.isBrandSelected = false
		SaveListing.productImages = []
		SaveListing.isCoverImageAdded = false
		SaveListing.coverImages = []
		SaveListing.isSetCreated = false
		SaveListing.isNewSet = true
		SaveListing.isProductDetailAdded = false
		SaveListing.selectProductSetIndex = 0
		SaveListing.productID = ''
		SaveListing.selectProductSetID = ''
		SaveListing.mapProductSets = []
		SaveListing.mapProductAttributes = []
		SaveListing.additionDetails = {}
		SaveListing.brand = {
			isNewBrand: false,
			brand: {},
			relationType: '',
			mapBrandAccountFileUploadedDatas: [],
			imageFileUploadedData: {},
			brandDocTYpe: ''
		}

		this.navigate('/create-list')
	}

	handleSearch = (value) => {

		const { categoryHierarchy } = this.state
		let res = []
		if(categoryHierarchy && categoryHierarchy.mapCategories){
			categoryHierarchy.mapCategories.forEach((firstItem, key) => {
				if (firstItem.childCategory.mapCategories && firstItem.childCategory.mapCategories.length) {

					let { mapCategories } = firstItem.childCategory
					mapCategories.forEach((scndItem, key1) => {
						if (scndItem.childCategory.mapCategories && scndItem.childCategory.mapCategories.length) {
							let { mapCategories } = scndItem.childCategory
							mapCategories.forEach((thirdItem, key3) => {
								res.push({ text: thirdItem.childCategory.name, id: thirdItem.childCategory.id })
							})
						}
					})
				}
			})
		}

		if (!value) {
			res = [];
		} else {
			res = res.filter(search =>
				search.text.toLowerCase().includes(value.toLowerCase().trim()));
		}

		this.setState({ result: res })
	}

	onSelect = (value) => {
		this.createNewProduct(value)
	}

	render() {
		const { t } = this.props
		const { categoryHierarchy, childCategory, result, selectedCategoryName, parentCategory, parentCategoryId } = this.state
		return (<>
			<LayoutComponent title={t('Select a Product Type')}>
				<Row>
					<Col span={24} className='bottom-border toolbar category-search-bar '>
						<p className='label'>
							{t('Search for a product type')}
						</p>
						<AutoComplete
							style={{ width: '100%' }}
							onSearch={this.handleSearch}
							onSelect={this.onSelect}
							placeholder="Search"
						>
							{result.map((item) => (
								<Option key={item.id} value={`${item.id}`}>
									{item.text}
								</Option>
							))}
						</AutoComplete>
					</Col>

					<Col span={24} className='category_container bottom-border'>
						<p className='label'>
							{t('Or select a category')}
						</p>
						<div>
							<Row className='category'>
								{
									categoryHierarchy && categoryHierarchy.length
										? categoryHierarchy.map((item, i) => {
											let imgurl
											if (item.childCategory.imageFileUploadedData && item.childCategory.imageFileUploadedData.url) {
												imgurl = MediaUrl.completeMediaUrl(item.childCategory.imageFileUploadedData.url, true, 100, 100)
											}
											return (
												<div
													key={i}
													className='category_image'
													onClick={() => this.changeParentCategory(item)}>
													<img src={imgurl ? imgurl : myimage} alt='banner' />
													<p className='label' style={parentCategoryId === item.id ? { fontWeight: '700', color: '#f26522' } : {}}>
														{item.childCategory.name}
													</p>
												</div>
											)
										})
										: null
								}
							</Row>
						</div>
						{
							parentCategoryId
								? <div>
									<br />
									<p className='label'>{t('Select a sub-category')}</p>
									<Row className='category'>
										{parentCategory?.childCategory?.mapCategories && parentCategory?.childCategory?.mapCategories.map((item, i) => {
											let imgurl
											if (item.childCategory.imageFileUploadedData && item.childCategory.imageFileUploadedData.url) {
												imgurl = MediaUrl.completeMediaUrl(item.childCategory.imageFileUploadedData.url, true, 100, 100)
											}
											return (
												<div
													key={i}
													className='category_image'
													onClick={() => this.changeChildCategory(item)}>
													<img src={imgurl ? imgurl : myimage} alt='banner' />
													<p className='label' style={selectedCategoryName === item.childCategory.name ? { fontWeight: '700', color: '#f26522' } : {}}>
														{item.childCategory.name}
													</p>
												</div>
											)
										})}
									</Row>
								</div>
								: null
						}
					</Col>
					<Col span={24} className='category_container'>
						<Accordion className='sub_category'>
							{childCategory.map((item, i) => {
								let imgurl
								if (item.childCategory.imageFileUploadedData && item.childCategory.imageFileUploadedData.url) {
									imgurl = MediaUrl.completeMediaUrl(item.childCategory.imageFileUploadedData.url, true, 100, 100)
								}
								return (
									<Card key={i} className="category-accordian-card">
										<Card.Header  >
											{(item.childCategory.mapCategories.length > 0) &&
												<Accordion.Toggle className='sub_category_header' variant="link" eventKey={item.id}>
													<img src={imgurl ? imgurl : myimage} alt='banner' />
													<h2>{item.childCategory.name}</h2>
													<div className='spacer'></div>
													<DownOutlined />
												</Accordion.Toggle>
											}
											{(item.childCategory.mapCategories.length === 0) &&
												<Accordion.Toggle className='sub_category_header' onClick={(e) => this.createNewProduct(item.childCategory.id)} variant="link" eventKey={item.id}>
													<img src={imgurl ? imgurl : myimage} alt='banner' />
													<h2>{item.childCategory.name}</h2>
													<div className='spacer'></div>
													<RightOutlined />
												</Accordion.Toggle>
											}

										</Card.Header>

										{item.childCategory.mapCategories && <Accordion.Collapse eventKey={item.id}>
											<Card.Body className='sub_category_body'>
												{item.childCategory.mapCategories.map((child, index) => {
													return (
														<p key={index} onClick={(e) => this.createNewProduct(child.id)}>  {child.childCategory.name}</p>
													)
												})}

											</Card.Body>
										</Accordion.Collapse>}
									</Card>
								)
							})}
						</Accordion>


					</Col>
				</Row>
			</LayoutComponent >
			<Footer />
		</>
		)
	}
}

export default withTranslation()(withRouter(CategoryComponent));