import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import LayoutComponent from './layoutComponents/layoutComponent'
import AddListingService from '../service/addListingService'
import Api from "../service/network";
import GridSection from './sections/grid'
import CardSliderSection from './sections/cardslider'
import RowCardSliderSection from './sections/rowCardSlider'
import RowSquareCardSliderSection from './sections/rowSquareCardSlider'
import FilterTabView from './sections/filterTab'
import FullWidthBannerSection from './sections/fullwidthbanner'
import ImageCardSection from './sections/imagecard'
import SquareCardSection from './sections/sqaurecard'
import ButtonSliderSection from './sections/buttonSlider'
import CarouselBannerSection from './sections/carouselBanner'
import SearchService from "../service/searchService";
import ComingSoonComponent from "./sections/comingSoonSection"

import Footer from './footer'

import LoaderComponent from './loader'

class CategoryThirdLevel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionData: [],
            categoryTitleName: '',
            showComingSoon: false
        }
    }

    async componentDidMount() {
        debugger
        const values = queryString.parse(this.props.location.search)
        let subCategoryId = this.props.match.params.subcategoryid
        const { t } = this.props;

        let obj = { pageTypeId: 5, id: subCategoryId }

        let { data } = await AddListingService.getSectionsData(obj)

        if (data.isSuccess) {
            let sectionData = data.data
            if (sectionData.length === 1) {
                this.checkForData(sectionData[0])
            }
            this.setState({
                sectionData,
                categoryTitleName: values.title
            })
        } else {
            message.error(`${t('Error in loading data')}`)
        }
    }

    async checkForData(itemData) {
        if (itemData.callType === "Get") {
            let path = `${itemData.dataUrl}`
            if (itemData.queryParam) {
                path = path + itemData.queryParam
            }
            Api.makeGetRequest(path, null, null, null).then((res) => {
                let output = res.data.data.data;
                if (output && output.length) {
                    this.setState({ showComingSoon: false });
                } else {
                    this.setState({ showComingSoon: true });
                }
            });
        }
        if (itemData.callType === "Post") {
            let payLoad = itemData.payLoad;
            let filters;
            try {
                if (typeof payLoad === "string") {
                    filters = JSON.parse(payLoad);
                } else {
                    filters = payLoad;
                }
            } catch (error) { }
            this.setState({ showComingSoon: true });
            await SearchService.algoliaProductsearch("", filters).then((result) => {
                this.setState({ showComingSoon: false });
            });
        }
    }

    render() {
        const { categoryTitleName, sectionData, showComingSoon } = this.state

        return (<>
            <LayoutComponent title={categoryTitleName}>
                <div>
                    {showComingSoon ? <ComingSoonComponent title={sectionData[0]?.name}></ComingSoonComponent> : null}
                    {!showComingSoon && sectionData && sectionData.length > 0 && sectionData.map((item, index) => {
                        switch (item.viewType) {
                            case 'ImageCard':
                                return <div key={index} className={'mb-5'}><ImageCardSection itemData={item} /></div>
                            case 'ButtonSlider':
                                return <div key={index} className={'mb-5'}><ButtonSliderSection itemData={item} /></div>
                            case 'SquareCard':
                                return <div key={index} className={'mb-5'}><SquareCardSection itemData={item} /></div>
                            case 'Grid':
                                return <div key={index} className={'mb-5'}><GridSection itemData={item} /></div>
                            case 'CarouselBanner':
                                return <div key={index} className={'mb-5'}><CarouselBannerSection itemData={item} /></div>
                            case 'FullWidthBanner':
                                return <div key={index} className={'mb-5'}><FullWidthBannerSection itemData={item} /></div>
                            case 'CardSlider':
                                return <div key={index} className={'mb-5'}><CardSliderSection showScrollBar={false} showLoadMore={true} showComingSoon={true} itemData={item} /></div>
                            case 'SingleRowCardSlider':
                                return <div key={index} className={'mb-5'}><RowCardSliderSection showComingSoon={true} itemData={item} /></div>
                            case 'MediumHorizontalSlider':
                                return <div key={index} className={'mb-5'}><RowSquareCardSliderSection showComingSoon={true} itemData={item} /></div>
                            case 'TextPillsSlider':
                                return <div key={index} className={'mb-5'}><FilterTabView showComingSoon={true} itemData={item} /></div>
                            default:
                                return <div key={index} className={'sectionloader'}><LoaderComponent /></div>
                        }
                    })
                    }
                </div>
            </LayoutComponent>
            <Footer />
        </>)
    }
}
export default withTranslation()(withRouter(CategoryThirdLevel));