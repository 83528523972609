import React, { Component } from "react";
import "./index.scss";
export class HeaderInfo extends Component {
  render() {
    return (
      <>
        <div className="d-flex justify-content-around headerInfo-container">
          <div className="headerInfo-container-block">
            <a href="mailto:akarindia2022@gmail.com">
              Email:-akarindia2022@gmail.com
            </a>
          </div>
          <div className="headerInfo-container-block"><p>“Whatever you’ve got in mind, we’ve got inside”</p></div>
          <div className="headerInfo-container-block"><p>“Complete your KYC”</p></div>
        </div>
      </>
    );
  }
}

export default HeaderInfo;
