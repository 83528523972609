import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import LayoutMain from "../layoutMain";
import Layout from "../layout";
import Footer from "../elements/footer";
import { withTranslation } from "react-i18next";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.token = typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null;
  }
  componentDidMount() {}
  aboutSection = () => {
    const { t } = this.props;
    return (
      <div className={"contact_container"}>
        <div style={{ minHeight: "70vh" }}>
          <h1>{t("About Us")}</h1>
          <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
          >
            <span style={{ fontSize: "14.0pt" }}>{t("Para - About Us")}</span>
          </p>

          <h1 style={{ marginTop: "30px" }}>{t("Policy")}</h1>
          <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
          >
            <span style={{ fontSize: "14.0pt" }}> {t("Para - Policy")}</span>
          </p>
          <Link to="/login">
            <h3 style={{ fontWeight: "700", marginTop: "10px" }}>
              {t("REGISTRATION IS OPEN")}
            </h3>
          </Link>
        </div>
      </div>
    );
  };
  render() {
    const { t } = this.props;
    if (this.token) {
      return (
        <>
          <LayoutMain title={t("About Us")}>{this.aboutSection()}</LayoutMain>
          <Footer />
        </>
      );
    } else {
      return (
        <>
          <Layout title={t("About Us")}>{this.aboutSection()}</Layout>
          <Footer />
        </>
      );
    }
  }
}

export default withTranslation()(withRouter(AboutUs));
