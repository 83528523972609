import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent'
import { HistoryOutlined } from '@ant-design/icons';
import { Row, Col, Tabs, message } from 'antd';
import moment from 'moment';
import Footer from '../footer';
import myImage from '../../imageNotFound.jpeg'
import AuthService from '../../service/authService'
import CardSliderSection from '../sections/cardslider'
import MediaUrl from '../../service/mediaUrl';
import userImg from '../../assests/user.png';
import { withTranslation } from 'react-i18next';

const { TabPane } = Tabs;

class ViewProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sellerData: {},
            productSections: []
        }
    }
    componentDidMount() {
        this.getSellerSectionsData()
        this.getSellerDetails()
    }

    getSellerSectionsData = async () => {
        const { t } = this.props
        const sellerId = this.props.match.params.id
        let { data } = await AuthService.getSellerSections(sellerId)
        
        if (data.applicationCode === 200 && data.isSuccess) {
            this.setState({ productSections: data.data })
        } else {
            message.error(`${t('Error in fetching data')}`)
        }
    }

    getSellerDetails = async () => {
        const sellerId = this.props.match.params.id
        const { t } = this.props
        let { data } = await AuthService.getVendorDetailsById(sellerId);

        if (data.applicationCode === 200 && data.isSuccess) {
            this.setState({ sellerData: data.data })
        } else {
            message.error(`${t('Error in fetching data')}`)
        }
    }

    callback = (key) => { }

    render() {
        const { sellerData, productSections } = this.state;

        return (<>
            <LayoutComponent title={'Akar'}>
                <div className={'view_container'}>
                    <div className={'flexContainer mb-4 px-3'}>
                        <div className={'flexRightDiv'}>
                            <div className={'font16px mb-1'}><b>{sellerData && sellerData.name ? sellerData.name : ''} </b></div>
                            {sellerData.addresses ? <div className={'font12px items_price'}></div>
                                : null}
                        </div>
                        <div className={'sellerImg'}>
                            <img src={sellerData && sellerData.imageFileUploadedData &&
                                sellerData.imageFileUploadedData.url ? MediaUrl.completeMediaUrl(sellerData.imageFileUploadedData.url, true, 100, 100) : userImg}
                                alt={'smallimg'} />
                        </div>
                    </div>
                    {/* <div className={'history_div px-3 font14px'}><HistoryOutlined className={'pr-1'} /> View History</div> */}

                    <Row>
                        <Col span={24}>
                            <Tabs className='tabHeader' tabBarGutter={3} tabBarStyle={{ textAlign: 'center' }} defaultActiveKey="1" size={'large'} onChange={this.callback}>
                                <TabPane tab="Products" key="1">
                                    {productSections && productSections.length ? productSections.map((item, index) => {
                                        return (<div className={'mb-2'} key={index}><CardSliderSection showScrollBar={true} itemData={item} /></div>)
                                    }) : null}

                                </TabPane>

                                <TabPane tab="About" key="2">
                                    <div className={'about_div'}>
                                        <p className={'title'}>OWNER</p>
                                        <p className={'about_text'}>{sellerData && sellerData.name ? sellerData.name : ''} {sellerData && sellerData.phoneNumber ? sellerData.phoneNumber : ''}</p>
                                        <hr />
                                        {/* <p className={'title'}>CUSTOMERS</p>
                                        <p className={'about_text'}>3610</p>
                                        <hr />
                                        <p className={'title'}>REPEAT CUSTOMERS</p>
                                        <p className={'about_text'}>10% </p>
                                        <hr />
                                        <p className={'title'}>ON AKAAR SINCE</p>
                                        <p className={'about_text'}>{moment().format('lll')}</p>
                                        <hr /> */}
                                        <p className={'title'}>Started Business</p>
                                        <p className={'about_text'}>{sellerData && sellerData.establishmentYear ? sellerData.establishmentYear : '---'}</p>
                                        {/* <hr />
                                        <p className={'title'}>GSTIN</p>
                                        <p className={'about_text'}>Available</p>
                                        <hr />
                                        <p className={'title'}>Connection</p>
                                        <p className={'about_text'}>3973</p>
                                        <hr />
                                        <p className={'title'}>GALLERY</p>
                                        <div>
                                            <img src={myImage} alt={'smallimg'} className={'galler_img'} />
                                        </div>
                                        <hr /> */}
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>

                </div>
            </LayoutComponent>
            <Footer />

        </>)
    }
}


export default withTranslation()(withRouter(ViewProfile));

