import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, Drawer, Input, Card, message, Modal, Select } from 'antd'
import CartService from '../../service/cart';
import AuthService from '../../service/authService';
import { withTranslation } from 'react-i18next';

const Option = Select.Option



class ShipmentPacketComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orderLineItems: [],
            packageDetailsVisible: false,
            isCodMode: false,
            loading: false,
            boxDimensions: [{
                length: '', breadth: '', height: '', weight: '',
                boxes: 1, invoiceNo: ''
            }],
            showModal: false,
            addressData: [],
            selectedAddressId: 0
        };
    }
    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }
    getText = (data, id) => {
        let text = "";
        data.forEach((item) => {
            if (item.id == id) {
                text = `${item.addressLine1 ? item.addressLine1 : null}, ${item.addressLine2 ? item.addressLine2 : null}, ${item.city ? item.city : null}, ${item.stateName ? item.stateName : null} - ${item.zipCode ? item.zipCode : null}`
            }
        });
        return text;
    };
    componentDidMount() {
        const { orderData } = this.props
        let isCod = true;
        if (orderData.paymentType === "PaymentGateway") {
            isCod = false;
        }
        this.setState({
            orderLineItems: orderData.orderLineItems,
            packageDetailsVisible: true,
            isCodMode: isCod
        })
        this.getAddressDetails();
    }

    getAddressDetails = () => {
        const { pickupOrderAddress } = this.props.orderData;
        pickupOrderAddress.id = 0;
        pickupOrderAddress.stateName = pickupOrderAddress.state.name;
        let dump = [];
        dump.push(pickupOrderAddress)
        AuthService.getAddressLists().then((res) => {
            let { data } = res.data
            data.forEach(element => {
                dump.push(element)
            });
            this.setState({ addressData: dump })
        },
            (err) => {
                console.log(err)
            })
    }

    onchangeInvoice = (val, index) => {
        let { boxDimensions } = this.state
        boxDimensions[index].invoiceNo = val.target.value
        this.setState({ boxDimensions })
    }

    onChangeWeight = (val, index) => {
        let { boxDimensions } = this.state
        if (val) {
            if (val !== 0.1) {
                boxDimensions[index].height = 10
                boxDimensions[index].length = 10
                boxDimensions[index].breadth = 10
            } else {
                boxDimensions[index].height = 1
                boxDimensions[index].length = 1
                boxDimensions[index].breadth = 1
            }
            boxDimensions[index].weight = val
        }
        this.setState({ boxDimensions })
    }

    onChangePrice = (val, index) => {
        let { orderLineItems } = this.state
        if (val.target.value > 0 || val.target.value === '') {
            orderLineItems[index].pricePerSetWithoutGst = val.target.value
        }
        this.setState({ orderLineItems })
    }

    onClose = () => {
        const { onclose } = this.props
        this.setState({
            packageDetailsVisible: false,
        }, () => {
            onclose();
        });
    };

    validateHsn = (val) => {
        var hasDigits = /^[0-9]+$/;
        if (val.match(hasDigits)) {
            return false;
        }
        return true;
    }

    proceedToShip = async () => {
        let { boxDimensions, orderLineItems } = this.state
        const { orderData, onclose, t } = this.props

        let orderItems = []
        let dimensions = []
        orderLineItems.forEach((item, key) => {
            console.log(item)
            if (!item.pricePerSet) {
                return message.error(`${t('Price cannot be empty')}`)
            } else if (!item.hsnCode?.trim().length || this.validateHsn(item.hsnCode)) {
                return message.error(`${t('HsnCode is required and can have numbers only')}`)
            } else if (!item.gstPercentage) {
                return message.error(`${t('Gst cannot be empty')}`)
            } else {
                orderItems.push({
                    id: item.id,
                    toPay: item.pricePerSetWithoutGst,
                    quantity: item.quantity,
                    hSNCode: item.hsnCode,
                    gstPercentage: item.gstPercentage
                })
            }
        })

        boxDimensions.forEach((item, key) => {
            if (!item.weight) {
                return message.error(`${t('Weight cannot be empty')}`)
            }
            else {
                dimensions.push({
                    length: item.length,
                    breadth: item.breadth,
                    height: item.height,
                    weight: item.weight
                })
            }

        })

        if (boxDimensions && !boxDimensions[0].invoiceNo) {
            return message.error(`${t('Invoice No cannot be empty')}`)
        }

        if (orderLineItems.length != orderItems.length) { return }
        if (dimensions.length === 0) { return }

        let obj = {
            orderId: orderData.orderId,
            channelId: null,
            invoiceNumber: boxDimensions[0].invoiceNo,
            dimensionDetails: dimensions,
            orderItems: orderItems,
            addressId: this.state.selectedAddressId
        }

        this.setState({ loading: true })

        let { data: shipRespp } = await CartService.readyToShip(obj)
        if (shipRespp.applicationCode === 200) {
            this.setState({ boxDimensions: [], loading: false, packageDetailsVisible: false })
            if (onclose) {
                onclose(shipRespp);
            }
        } else {
            this.setState({
                loading: false,
                // packageDetailsVisible: false
            })
            return message.error(shipRespp.message || `${t("Something went wrong")}`)

        }
    }
    selectAddress = (val) => {
        if (typeof (val) == 'number') {
            this.setState({ selectedAddressId: val })
        }
    }
    openModal = () => {
        this.setState({ showModal: true })
    }

    closeModal = e => {
        this.setState({
            showModal: false
        });
    };

    render() {
        const {
            orderLineItems,
            packageDetailsVisible,
            boxDimensions, showModal,
            isCodMode,
            loading,
            addressData,
            selectedAddressId
        } = this.state
        const { t } = this.props

        const proceedButton = <>
            <Row>
                <Col span={24}>
                    <Button loading={loading}
                        className={'manageBtn'}
                        style={{ width: '100%' }}
                        onClick={() => {
                            this.proceedToShip()
                        }}>{t('Proceed')}</Button>
                </Col>
            </Row>
        </>

        return (<>
            <Drawer title={t("Package Details")}
                placement={'bottom'}
                visible={packageDetailsVisible}
                onClose={this.onClose}
                footer={proceedButton}
                height={'100vh'}
                key={'package'}
            >
                <div className={'qtyDrawer'}>
                    <div className={'mt-4'}>
                        {orderLineItems && orderLineItems.length > 0 ? orderLineItems.map((item, orderIndex) => {
                            return (<div className={'mt-4'} key={orderIndex}>
                                <Row >
                                    <Col xs={24}>
                                        <Card title={`${item.productOrderLineItem.name}`}
                                            key={orderIndex}>
                                            <Row gutter={[16, 16]}>
                                                <Col span={8} >
                                                    <p className="label">{t('Price per set')}( {t('Excluding GST')})*</p>
                                                    <Input
                                                        onChange={(val) => { this.onChangePrice(val, orderIndex) }}
                                                        disabled={isCodMode ? false : true}
                                                        type="number"
                                                        value={item.pricePerSetWithoutGst}
                                                        placeholder={t("Price")} />
                                                </Col>
                                                <Col span={8} >
                                                    <p className="label">{t('Gst Percentage')}*</p>
                                                    <Input
                                                        disabled
                                                        value={item.gstPercentage}
                                                        type="number"
                                                        placeholder={t("Enter GST")} />
                                                </Col>

                                                <Col span={8} >
                                                    <p className="label">{t('Units')}*</p>
                                                    <Input

                                                        disabled={isCodMode ? false : true}
                                                        value={item.quantity}
                                                        type="number"
                                                        onChange={(val) => {
                                                            let { orderLineItems } = this.state
                                                            if (val.target.value > 0 || val.target.value === '') {
                                                                orderLineItems[orderIndex].quantity = val.target.value
                                                            }
                                                            this.setState({ orderLineItems })
                                                        }}
                                                        placeholder={t("Enter Units")} />
                                                </Col>
                                                <Col key={item.id} span={24} >
                                                    <p className="label">{t('HSN Code')}*</p>
                                                    <Input key={item.id}
                                                        value={item.hsnCode}
                                                        onChange={(val) => {
                                                            let { orderLineItems } = this.state;
                                                            orderLineItems[orderIndex].hsnCode = val.target.value
                                                            this.setState({ orderLineItems })
                                                        }}
                                                        placeholder={t('HSN Code')}id="hsn-input-element" />
                                                </Col>
                                                {
                                                    item.specialNote ?
                                                        <Col span={24} >
                                                            <p className="label">{t("Special Note")}</p>
                                                            <Input
                                                                value={item.specialNote}
                                                                disabled={true}
                                                            />
                                                        </Col>
                                                        : null
                                                }


                                            </Row>
                                        </Card>

                                    </Col>
                                </Row>
                            </div>)
                        }) : null}

                        <div className="mt-4">
                            <Card title={t('Box Details')}>
                                {boxDimensions ? boxDimensions.map((item, boxindex) => {
                                    return (<div key={boxindex}>
                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <p className="label">{t('No of Boxes')}*</p>
                                                <Input
                                                    disabled={true}
                                                    value={item.boxes}
                                                    type="number"
                                                    placeholder={t('No of Boxes')} />
                                            </Col>
                                            <Col span={12}>
                                                <p className="label">{t('Total Weight')} ({t('kg')})*</p>

                                                <Select onChange={(val) => { this.onChangeWeight(val, boxindex) }}
                                                    placeholder={t('Select Weight')} style={{ width: '100%' }}>
                                                    <Option key={'0'} value={0.1}>{`0-1 ${t('kg')}`}</Option>
                                                    <Option key={'1'} value={2}>{`1-2 ${t('kg')}`}</Option>
                                                    <Option key={'2'} value={3}>{`2-5 ${t('kg')}`}</Option>
                                                    <Option key={'3'} value={5}>{`5-10 ${t('kg')}`}</Option>
                                                    <Option key={'4'} value={10}>{`10-20 ${t('kg')}`}</Option>
                                                    <Option key={'5'} value={20}>{`${t('Above')} 20 ${t('kg')}`}</Option>
                                                </Select>
                                            </Col>
                                            <Col span={24}>
                                                <p className="label">{t('Invoice No')}*</p>
                                                <Input
                                                    value={item.invoiceNo}
                                                    onChange={(val) => { this.onchangeInvoice(val, boxindex) }}
                                                    placeholder={t('Invoice No') }id="invoice-number-input-element"
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <p className="label">{'Pickup Address'} </p>
                                                <Row>
                                                    <Col span={20}>
                                                        <Select onChange={(val) => this.selectAddress(val)}
                                                            placeholder={t('Select Address')} style={{ width: '100%' }}
                                                            value={this.getText(addressData, selectedAddressId)}>
                                                            <Option value={"Selected"} disabled>Selected Address</Option>
                                                            <Option value={"item"}>{this.getText(addressData, selectedAddressId)}</Option>
                                                            <Option value={"Other"} disabled>Other Addresses</Option>
                                                            {addressData.map((item, index) => {
                                                                return (
                                                                    <Option key={index} value={item.id}>
                                                                        {
                                                                            `${item.addressLine1 ? item.addressLine1 : null}, ${item.addressLine2 ? item.addressLine2 : null}, ${item.city ? item.city : null}, ${item.stateName ? item.stateName : null} - ${item.zipCode ? item.zipCode : null}`
                                                                        }
                                                                    </Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Col>
                                                    <Col span={4} className="justify-content-center">
                                                        <Button onClick={() => { this.props.history.push("/account-profile") }}>Add New Address</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row className="mt-2" gutter={[16, 16]}>
                                            <Col span={24}>
                                                <Button type="primary"
                                                    style={{ width: '100%' }}
                                                    onClick={() => {
                                                        this.openModal()
                                                    }}
                                                >+{t('Add Boxes')}</Button>
                                            </Col>
                                        </Row>

                                    </div>)
                                }) : null}
                            </Card>
                        </div>
                    </div>
                </div>
            </Drawer>

            <Modal title={t("Notice")}
                visible={showModal}
                onCancel={this.closeModal}
                footer={null}
                centered={true}
            >
                <p>{t('Akar currently Supports shipment of single box - para')}</p>
            </Modal>
        </>

        );
    }
}

export default withTranslation()(withRouter(ShipmentPacketComponent));