import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Tabs, Col, Input, Checkbox, message, Radio } from 'antd';
import _ from 'lodash';
import SaveListing from '../../service/saveListingService'
import AddListingService from '../../service/addListingService'
import { CheckCircleOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;
const { Search } = Input;

class ProductDetailComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productAttribute: [],
            // checkedList: [],
            currentTab: 0,
            addedValue: ''
        }
    }

    componentDidMount() {
        console.log(SaveListing.isClone)
        if (SaveListing.mapProductAttributes.length) {
            this.setState({ productAttribute: SaveListing.mapProductAttributes })
        } else {
            if (SaveListing.mapCategoryId !== "") {
                this.getCategoryAtrribute()
            }
            else {
                window.onbeforeunload = function (evt) {
                    var message = 'Are you sure you want to leave?';
                    if (typeof evt == 'undefined') {
                        evt = window.event;
                    }
                    if (evt) {
                        evt.returnValue = message;
                    }
                    return message;
                }

                let productId = typeof window !== 'undefined' ? localStorage.getItem('id') : null
                if (productId) {
                    productId = parseInt(productId)
                    if(typeof window !== 'undefined')  localStorage.removeItem('id')
                    this.props.history.push({ pathname: `/create-list/${productId}` })

                } else {
                    this.props.history.push({ pathname: `/category` })
                }
            }
        }
    }

    getCategoryAtrribute = () => {
        AddListingService.getCategoryAtrribute(SaveListing.mapCategoryId)
            .then((res) => {
                let productAttribute = []
                res.data.data.forEach(item => {
                    if (item.parentAttribute.isProductAttribute === true) {
                        let options = []
                        item.parentAttribute.mapAttributes.forEach(element => {
                            if (element.childAttribute) {
                                options.push(element.childAttribute.value)
                            }
                        })
                        item['options'] = options
                        item['optionsSaved'] = options
                        item['selectedList'] = []
                        productAttribute.push(item)
                    }
                })
                this.setState({ productAttribute: productAttribute })
            }, err => { })
    }

    onChecked = (checkedList) => {
        let productAttribute = this.state.productAttribute
        let currentTab = this.state.currentTab
        productAttribute[currentTab].selectedList = checkedList
        this.setState(productAttribute)
    };

    onChangeRadio = (e) => {
        let productAttribute = this.state.productAttribute
        let currentTab = this.state.currentTab
        productAttribute[currentTab].selectedList = [e.target.value]
        this.setState(productAttribute)
    }

    addNewFabric = () => {

        let fabric = this.state.addedValue
        let attribute = [...this.state.productAttribute]
        let currentTab = this.state.currentTab
        let options = attribute[currentTab].options
        let selectedList = attribute[currentTab].selectedList


        let filteredOptions = options.filter((search) => {
            return search.toLowerCase().includes(fabric.toLowerCase().trim())
        });

        if (filteredOptions.length) {

        } else {
            options.splice(0, 0, fabric)
            attribute[currentTab].optionsSaved = options
            attribute[currentTab].options = options
        }

        let viewType = attribute[currentTab].parentAttribute.viewType
        if (viewType === "Radio") {
            selectedList[0] = fabric
        } else {
            if (!filteredOptions.length) {
                selectedList.push(fabric)
            } else {
                let index = filteredOptions.findIndex(x => x.toLowerCase() === fabric.toLowerCase())
                if (!selectedList.includes(filteredOptions[index])) {
                    selectedList.push(filteredOptions[index])
                }
            }

        }
        this.setState({ productAttribute: [...attribute], addedValue: '' })
        this.onSearchFebric('')
    }

    onChangeInput = e => {
        let str = e.target.value;
        if (!this.state.addedValue && str === " ") {
            this.setState({
                addedValue: ''
            });
        } else if (/^[-\w ]+$/.test(str) || str === "") {    // server_name is OK
            this.setState({
                addedValue: str
            });
        } else { }   // server_name NOT OK
    };

    onSearchFebric = searchTerm => {
        let productAttribute = this.state.productAttribute
        let currentTab = this.state.currentTab
        let optionsSaved = productAttribute[currentTab].optionsSaved
        // let options = optionsSaved[currentTab].options
        let options = optionsSaved.filter(search =>
            search.toLowerCase().includes(searchTerm.toLowerCase().trim()));
        productAttribute[currentTab].options = options
        this.setState(productAttribute)
    }

    clearAllSelctedDetailValue = () => {
        let { productAttribute } = this.state
        productAttribute.forEach((item) => {
            item.selectedList = []
        })
        this.setState(productAttribute)
    }

    createProductTitle = async () => {
        let attributes = []
        let name = SaveListing.brand.brand.name + " "
        SaveListing.mapProductAttributes.forEach((item, key) => {
            let slectedMapAttributes = []
            let newAttribute = []

            item.selectedList.forEach((element, i) => {
                item.parentAttribute.mapAttributes.forEach(attribute => {
                    if (attribute.childAttribute) {
                        if (element === attribute.childAttribute.value) {
                            slectedMapAttributes.push(attribute)
                            newAttribute.push(element)
                        }
                    }
                })
            })

            if (newAttribute.length !== item.selectedList.length) {
                var onlyNewAttribute = item.selectedList.filter(function (obj) { return newAttribute.indexOf(obj) == -1; });
                onlyNewAttribute.forEach(selectedAttribute => {
                    attributes.push({
                        mapAttributeId: 0,
                        mapAttribute: {
                            parentAttributeId: item.parentAttribute.id,
                            childAttributeId: 0,
                            childAttribute: {
                                key: selectedAttribute.toUpperCase(),
                                value: selectedAttribute,
                                type: item.parentAttribute.type
                            }
                        }
                    })
                })
            }

            if (slectedMapAttributes.length) {
                slectedMapAttributes.forEach(selectedAttribute => {
                    if (selectedAttribute.childAttribute.type === "TOP_FABRIC" || selectedAttribute.childAttribute.type === "TOP_PATTERN" || selectedAttribute.childAttribute.type === "TOP_SHAPE" || selectedAttribute.childAttribute.type === "IDEAL_FOR") {
                        name = name + selectedAttribute.childAttribute.value + " "
                    }
                    attributes.push({
                        mapAttributeId: selectedAttribute.id,
                        mapAttribute: null
                    })
                })
            }
        })

        let obj = {
            mapBrandAccountId: SaveListing.brand.id,
            mapCategoryId: SaveListing.mapCategoryId,
            mapProductAttributes: attributes
        }
        let { data: response } = await AddListingService.produceProductTitle(obj)

        if (response.isSuccess) {
            SaveListing.title = response.data.productTitle
            let url = '/create-list'
            if (SaveListing.productID) {
                url = url + '/' + SaveListing.productID
            }
            this.props.history.push({ pathname: url })
        }
    }

    navigateToCreateSetPage = () => {
        const { t } = this.props
        SaveListing.isProductDetailAdded = true
        let isAllProductSelected = true
        let name = SaveListing.brand.brand.name + " "
        this.state.productAttribute.forEach((item) => {
            if (!item.selectedList.length) {
                if (item.parentAttribute.isMandatory) {
                    isAllProductSelected = false
                    message.error(`${t('Select value for')} ${item.parentAttribute.value}`)
                    return
                }
            } else {
                if (item.parentAttribute.isMandatory) {
                    name = name + item.selectedList[0] + ' '
                }
            }
        })

        if (isAllProductSelected) {
            SaveListing.mapProductAttributes = this.state.productAttribute
            this.createProductTitle()
        }
    }

    onTabChange = (currentTab) => {
        this.setState({ currentTab: currentTab })
    }

    render() {
        const { addedValue, productAttribute } = this.state;
        const { t } = this.props;

        return (
            <LayoutComponent title={t('Product details')}>

                <Row className=' sidebar_navigation-items' >
                    <Tabs className='custom_tabs hideOverflow'
                        defaultActiveKey="0"
                        tabPosition='left'
                        onChange={this.onTabChange}
                    >
                        {productAttribute.map((item, i) => {
                            return (

                                <TabPane style={{ width: '100%' }}
                                    tab={<div className='tab_heading'> <p> {item.parentAttribute.value}{item.parentAttribute.isMandatory ? '*' : ''} </p> <div className='spacer' /> {item.selectedList.length ? < CheckCircleOutlined className='float_right' /> : ''} </div>}
                                    key={i}
                                    onClick={() => this.onTabChange(item)}>
                                    <Row className='number_div product_detail_row'>
                                        <Col span={24} className='product_detail_col'>
                                            <Search
                                                className="product_detail_search"
                                                placeholder={t('Search')}
                                                onChange={value => this.onSearchFebric(value.target.value)}
                                                style={{ width: "100%", background: 'rgb(237, 237, 237)', borderRadius: '2px', marginBottom: '15px' }}
                                            />

                                            {(item.parentAttribute.viewType === 'Radio') ?

                                                <Radio.Group
                                                    className='checkbox_div product_detail_checkbox_div'
                                                    options={item.options}
                                                    onChange={this.onChangeRadio}
                                                    value={item.selectedList[0]}
                                                />
                                                :
                                                <CheckboxGroup
                                                    className='checkbox_div product_detail_checkbox_div'
                                                    value={item.selectedList}
                                                    options={item.options}
                                                    onChange={this.onChecked}
                                                />
                                            }
                                        </Col>
                                        <Col span={24} className='add_option product_detail_add_option'>
                                            {t('Add new option')}
                                            <Input type='text' value={addedValue} onChange={this.onChangeInput}
                                                placeholder={t("Enter your option Here")} />
                                            <p style={{ fontSize: 10, color: '#cacaca', marginTop: 0 }}>
                                                ( {t('Note')}: {t('Custom options includes')} )</p>
                                            {(addedValue !== '') ? <div className='add_button_container'>
                                                <button className='float-right' onClick={this.addNewFabric} >{t('Add')}</button>
                                            </div> : ''}
                                        </Col>
                                    </Row>
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </Row>
                <Row>
                    <Col span={24} className='stickToBottom'>
                        <div className='button_div'>
                            <Row>
                                <Col span={12}><button onClick={() => this.clearAllSelctedDetailValue()} className='whiteBGButton'>{t('Clear all')}</button></Col>
                                <Col span={12}><button onClick={() => this.navigateToCreateSetPage()}>{t('Save')}</button></Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(ProductDetailComponent));