import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col, Input, Radio, Form, message, Modal, Button } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { VideoCameraOutlined, DeleteOutlined } from '@ant-design/icons';
import LoaderComponent from '../loader'
import SaveListing from '../../service/saveListingService'
import AddListingService from '../../service/addListingService'
import BrandService from '../../service/brandService';
import MediaUrl from '../../service/mediaUrl'
const { TextArea } = Input;


class AdditionalDetailComponent extends Component {
    myInput;
    formRef = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            disableSaveBtn: true, isloaderVisible: false, videoFile: null,
            fileList: [], gstRate: [], loading: false, showPreview: false, videoArr: [],
            productVideo: {}
        }
    }

    componentDidMount() {
        this.getGST()
        window.scroll(0, 0)
        console.log(SaveListing.isClone)
        if (SaveListing.mapCategoryId !== "") {
            if (SaveListing.additionDetails.gstRate) {
                let formData = SaveListing.additionDetails

                this.formRef.current.setFieldsValue({
                    gstRate: formData.gstRate,
                    expiry: formData.expiry,
                    description: formData.description,
                    // minOrderQuanitity: formData.minOrderQuanitity,
                    // includeGst: formData.includeGst,
                });
                this.setState({ disableSaveBtn: false })
            }

            // if (SaveListing.productVideo) {
            //     this.setState({ productVideo: SaveListing.productVideo })
            // }
            console.log(SaveListing.productImages)
            if (SaveListing.productImages.length > 0) {
                let productVideo = SaveListing.productImages.find((item) => (item.type === 'ProductVideo'))
                if (productVideo) {
                    this.setState({ productVideo: productVideo })
                }
            }
        }
        else {
            window.onbeforeunload = function (evt) {
                var message = 'Are you sure you want to leave?';
                if (typeof evt == 'undefined') {
                    evt = window.event;
                }
                if (evt) {
                    evt.returnValue = message;
                }
                return message;
            }
            let productId = typeof window !== 'undefined' ? localStorage.getItem('id') : null
            if (productId) {
                productId = parseInt(productId)
                if (typeof window !== 'undefined') localStorage.removeItem('id')
                this.props.history.push({ pathname: `/create-list/${productId}` })

            } else {
                this.props.history.push({ pathname: `/category` })
            }
        }
    }

    getGST = () => {
        AddListingService.getGST()
            .then((res) => {
                this.setState({ gstRate: res.data.data })
            },
                err => { })

    }

    onChange = (e) => {
        const { t } = this.props
        let selectedVideoFile = e.target.files[0]
        const fileExtn = selectedVideoFile.name.substr(selectedVideoFile.name.lastIndexOf("."))
        if ((fileExtn === '.mov' || fileExtn === '.mp4') && selectedVideoFile) {
            let fileSize = this.returnFileSize(selectedVideoFile.size)
            if (selectedVideoFile.size >= 20971520) {
                return message.error(`${t('Video size limit validation')}`)
            } else {
                // let fileList = this.state.fileList
                let videoArr = this.state.videoArr


                let blob = selectedVideoFile.slice(0, selectedVideoFile.size, selectedVideoFile.type);
                selectedVideoFile = new File([blob], moment().valueOf() + fileExtn, { type: selectedVideoFile.type });

                var url = URL.createObjectURL(selectedVideoFile)
                videoArr.push(url)
                this.setState({
                    // fileList,
                    showPreview: true,
                    videoArr,
                    videoFile: selectedVideoFile
                })
            }
        } else {
            return message.error(`${t('Attach video in mp4/mov file format')}`)
        }
        document.getElementById("myInput").value = null;

    }

    addImageInAWS = (data) => {
        BrandService.addImage(data, 'PRODUCT_DISPLAY_FILE')
            .then((res) => {
                let vedioData = {}
                let data = res.data.data[0]
                data['type'] = 'ProductVideo'
                vedioData = data
                // SaveListing.productVideo = vedioData
                SaveListing.productImages.push(vedioData)
                console.log(SaveListing.productImages)
                vedioData.url = MediaUrl.completeMediaUrl(vedioData.url, false, 100, 100)
                this.setState({ showPreview: false, loading: false, videoArr: [], productVideo: vedioData })
            }, err => { })
    }

    deleteVideoFromAWS = (data) => {
        BrandService.deleteImage(data)
            .then((res) => {
                SaveListing.productVideo = {}
                let rejectedFiles = _.reject(SaveListing.productImages, (item) => item.type === 'ProductVideo')
                SaveListing.productImages = rejectedFiles
                this.setState({ productVideo: {} })
            }, err => { })
    }

    deleteVedio = () => {
        let { productVideo } = this.state
        let url = MediaUrl.trimMediaUrl(productVideo.url)
        this.deleteVideoFromAWS(url)
    }

    radioChange = e => {
        let expirylist = this.formRef.current.getFieldValue('expiry')
        let gstRate = this.formRef.current.getFieldValue('gstRate')

        if (gstRate && expirylist) {
            this.setState({ disableSaveBtn: false })
        }
    }

    onFinish = values => {
        SaveListing.additionDetails = values

        let id = SaveListing.productID
        if (id !== '') {
            this.props.history.push({ pathname: `/create-list/${id}` })
        } else {
            this.props.history.push({ pathname: '/create-list' })

        }
    };

    returnFileSize = (number) => {
        if (number < 1024) {
            return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
            return (number / 1048576).toFixed(1) + 'MB';
        }
    }

    handleCancel = () => {
        this.setState({ showPreview: false, videoArr: [] })
    };

    handleOk = () => {
        const { videoFile } = this.state
        this.setState({ loading: true }, () => {
            this.addImageInAWS(videoFile)
        })
    };

    render() {
        const { disableSaveBtn, isloaderVisible, fileList,
            gstRate, videoArr, showPreview, loading, productVideo } = this.state
        const deleteBtn = { visibility: 'visible' }
        const { t } = this.props;

        return (
            <LayoutComponent title={t('Additional details')}>

                <div className='divWithFixButton'>
                    <Form
                        scrollToFirstError
                        ref={this.formRef}
                        hideRequiredMark={false}
                        onFinishFailed={this.onFinishFailed}
                        name="additional_DetailsForm"
                        layout="vertical"
                        onFinish={this.onFinish}>
                        <Row className='fixed_container' >

                            <Col span={24} >
                                <p className='mb-0'>{t('Listing title')}*</p>
                                <p className='smallHeading'>{SaveListing.title}</p>

                                <Form.Item
                                    label={t("Description")}
                                    name="description"
                                    rules={[{ whitespace: true, message: `${t('Please enter description')}` }]}
                                >
                                    <TextArea autoSize={{ minRows: 3, maxRows: 6 }}
                                        placeholder={t("Description")}
                                        onChange={(val) => { }} />
                                </Form.Item>

                                <Form.Item
                                    label={`${t('GST rate')}*`}
                                    name="gstRate"
                                // rules={[{ required: true, message: '' }]}
                                >
                                    <Radio.Group onChange={this.radioChange} >
                                        {gstRate.length && gstRate.map(item => {
                                            return (
                                                <Radio key={item.id} value={item.id}>{item.percentage}%</Radio>
                                            )
                                        })}
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item
                                    label={`${t('Listing expiry')}*`}
                                    name="expiry"
                                // rules={[{ required: true, message: '' }]}
                                >
                                    <Radio.Group onChange={this.radioChange} >
                                        <Radio value={'0'}>{t('None')}</Radio>
                                        <Radio value={7}>7 {t('days')}</Radio>
                                        <Radio value={15}>15 {t('days')}</Radio>
                                        <Radio value={30}>30 {t('days')}</Radio>
                                        <Radio value={60}>60 {t('days')}</Radio>
                                        <Radio value={90}>90 {t('days')}</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <p className='mt-1 mb-0'>{t('Video')}</p>
                                {
                                    productVideo && productVideo.url
                                        ? null
                                        : <div>
                                            <input id="myInput"
                                                type="file"
                                                ref={(ref) => this.myInput = ref}
                                                onChange={this.onChange.bind(this)}
                                                accept=".mp4,.mov"
                                                style={{ display: 'none' }} />
                                            <button
                                                type='button'
                                                className="mr-3 file"
                                                onClick={() => { this.myInput.click() }}  >
                                                <VideoCameraOutlined />
                                            </button>
                                        </div>
                                }

                                {
                                    productVideo && productVideo.url
                                        ? <>
                                            <div className="Video_container">
                                                <video
                                                    src={productVideo.url}
                                                    autoPlay={false}
                                                    preload={'none'}
                                                    controls
                                                    controlsList="nodownload"
                                                    alt="video" />
                                                <p style={{ color: 'red', cursor: 'pointer' }}
                                                    onClick={() => this.deleteVedio()}>Remove Video</p>
                                            </div>
                                        </>
                                        : null
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className='stickToBottom'>
                                <div className='button_div'>
                                    <Form.Item>
                                        {
                                            isloaderVisible ? <LoaderComponent /> :
                                                <button className="additional_details_save_btn"
                                                    disabled={disableSaveBtn}
                                                >{t('Save')}</button>
                                        }
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <Modal className={'img_modal'}
                    title={t('Preview')}
                    centered={true}
                    maskClosable={false}
                    visible={showPreview}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            {t('Cancel')}
                        </Button>,
                        <Button key="submit" type="primary"
                            loading={loading} onClick={this.handleOk}>
                            {t('Attach')}
                        </Button>]}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <div className={'chat_modalFiles_div'}>
                        {videoArr && videoArr.length > 0 && videoArr.map((item, vIndex) => {
                            return (
                                <div key={vIndex} >
                                    <video src={item} autoPlay={false}
                                        preload={'none'} controls
                                        controlsList="nodownload" />
                                </div>
                            )
                        })}
                    </div>
                </Modal>

            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(AdditionalDetailComponent));