
let recursiveCategories = (categoryPayload) => {
    if (categoryPayload.categories && !categoryPayload.categories.length || categoryPayload.categories === null) {
        return categoryPayload;
    } else {
        let subCat = categoryPayload.categories[0]
        let subcatresp = recursiveCategories(subCat)
        return subcatresp;
    }
}

let x = {
    redirectToProductGridAndBrandGrid: (title, payload) => {
        let encoded = Buffer.from(JSON.stringify(payload), 'ascii').toString('base64');
        title = encodeURIComponent(title);
        let pathname = `/search/listings/title=${title}/${encoded}`;
        return pathname;
    },
    redirectToSellerGrid: (payload) => {
        let pathname = `/view-profile/${payload.supplier.id}`
        return pathname;
    },
    redirectToThirdLevelCategory: (payload) => {
        return recursiveCategories(payload)
    },
    redirectToProductDetails: (payload) => {
        let pathname = `/view-product/${payload.id}`
        return pathname;
    }
}

export const commonRedirections = (item) => {

    // let searchStr = []
    // let words = item.htmlMarkUp.split(' ');
    // words.forEach(title => {
    //     if (title.includes('<b>')) {
    //         title = title.replace('<b>', "").replace('</b>', "")
    //     }
    //     if (title.includes('/')) {
    //         title = title.replace('/', "")
    //     }
    //     searchStr.push(title)
    // })

    // let title = searchStr.join('+')
    let searchStr = []
    let title = '';
    if(typeof(item.payLoad) === 'string'){
        item.payLoad = JSON.parse(item.payLoad)
    }
    let payload = item.payLoad.payload;
    if(typeof(payload) === 'string'){
        payload = JSON.parse(payload)
    }
    if (item.htmlMarkUp) {
        let words = item.htmlMarkUp.split(' ');
        words.forEach(title => {
            if (title.includes('<b>')) {
                title = title.replace('<b>', "").replace('</b>', "")
            }
            if (title.includes('/')) {
                title = title.replace('/', "")
            }
            searchStr.push(title)
        })

        title = searchStr.join('+')
    } else if (item.itemDescription && item.itemDescription.title) {
        title = item.itemDescription.title
    } else {
        if(!title){
            title = item.payLoad.displayName;
        }

    }

    if (item.pageType === "ProductGrid" || item.pageType === 'BrandGrid') {
        return x.redirectToProductGridAndBrandGrid(title, payload)
    }

    if (item.pageType === "Category_Category_Category") {
        return x.redirectToThirdLevelCategory(payload)
    }
    if (item.pageType === "SellerGrid") {
        return x.redirectToSellerGrid(payload)
    }
    if (item.pageType === "ProductDetails") {
        return x.redirectToProductDetails(payload)
    }
}

