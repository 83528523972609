import React, { Component } from 'react';
import { Row, Col, Input, Button, message, Select, Form, Card } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Authentication from "../../service/authService"
const { Option } = Select;

class BankDetailsComponent extends Component {
    formRef = React.createRef()
    upiRef = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            bankDetailType: 'upi'
        }
    }
    componentDidMount() { }

    onFinish = (values) => {
        const { dispatch, onBankDetailsSubmit } = this.props;

        if (values.reEnterAccountNumber !== values.accountNumber) {
            return alert("Account Number Mismatch !")
        } else {
            this.setState({ loading: true })

            Authentication.addBankDetails(values)
                .then((res) => {
                    if (res && res.data && res.data.applicationCode === 200) {
                        let { data } = res.data
                        dispatch({
                            type: 'SET_CURRENT_USER_ACCOUNT_DETAILS',
                            account: data
                        })

                        if (onBankDetailsSubmit) {
                            onBankDetailsSubmit(res.headers.bankaccountid);
                        }
                        this.setState({ loading: false }, () => {
                            this.formRef.current.resetFields()
                        })
                    } else {
                        this.setState({ loading: false })
                        message.error(res.data.message)
                    }
                }, (err) => {
                    this.setState({ loading: false })
                });
        }
    };


    addUPIDetail = (values) => {
        const { dispatch, onBankDetailsSubmit } = this.props;
        this.setState({ loading: true })
        let data = { 'vpa': values.upi }
        Authentication.createUPIAccount(data)
            .then((res) => {
                if (res && res.data && res.data.applicationCode === 200) {
                    let { data } = res.data
                    dispatch({
                        type: 'SET_CURRENT_USER_ACCOUNT_DETAILS',
                        account: data
                    })

                    if (onBankDetailsSubmit) {
                        onBankDetailsSubmit(res.headers.bankaccountid);
                    }
                    this.setState({ loading: false }, () => {
                        this.upiRef.current.resetFields()
                    })
                } else {
                    this.setState({ loading: false })
                    res.data.message ? message.error(res.data.message) : message.error("User does not have permission")
                }

            }, (err) => {
                this.setState({ loading: false })
            });
    }


    changeBankDetailType = (val) => {
        this.setState({ bankDetailType: val })
    }

    render() {

        const { loading, bankDetailType } = this.state
        const { t } = this.props

        return (<>

            <div className='justify-content-center'>
                <Card style={{ width: 380 }} bordered={false}>
                    <div className='text-align-center mb-4'>
                        <h3>{t('Enter bank account info')}</h3>
                    </div>

                    <Row>
                        <Col span={24}>
                            <Select defaultValue="upi" className='ant-col ant-col-24' onChange={(val) => this.changeBankDetailType(val)}>
                                <Option value='upi'>Add UPI</Option>
                                <Option value='bank'>Add Bank Detail</Option>
                            </Select>
                        </Col>
                    </Row>

                    <div className='mt-4'>
                        {bankDetailType == 'upi' ?

                            <Form ref={this.upiRef}
                                layout={'vertical'}
                                scrollToFirstError
                                onFinishFailed={this.onFinishFailed}
                                onFinish={this.addUPIDetail}
                                requiredMark={false}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label='Your UPI Id'
                                            name="upi"
                                            rules={[
                                                {
                                                    required: true, whitespace: true,
                                                    message: 'Please input your UPI id ',
                                                },
                                            ]}
                                        >
                                            <Input placeholder={t('UPI Id')} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item>
                                            {/* <Button
                                                htmlType='submit'
                                                className={'submitBtn'}
                                                loading={this.state.loading}
                                                type="primary"
                                            >{t('Save')}</Button> */}

                                            <Button
                                                loading={loading}
                                                className='my-button'
                                                htmlType="submit" >
                                                {t('Submit')}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form> :
                            <Form
                                ref={this.formRef}
                                scrollToFirstError
                                name="horizontal_banking_details_form"
                                layout="vertical"
                                requiredMark={false}
                                onFinish={this.onFinish}
                            >
                                <Form.Item
                                    label={`${t('Account holder name')}*`}
                                    name="holderName"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: `${t('Account holder name reqd validation')}`
                                        },
                                        {
                                            min: 4,
                                            message: `${t('Username must be minimum 4 characters')}`
                                        },
                                        {
                                            max: 50,
                                            message: `${t('Username must not exceed 50 characters')}`
                                        }
                                    ]}
                                >
                                    <Input placeholder={t('Account holder name')} />
                                </Form.Item>

                                <Form.Item
                                    label={`${t('IFSC code')}*`}
                                    name="iFSCCode"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: `${t('IFSC code reqd validation')}`
                                        }, {
                                            len: 11,
                                            message: `${t('IFSC Code must be 11-digit')}`
                                        }

                                    ]}
                                >
                                    <Input placeholder={t('IFSC code')} />
                                </Form.Item>

                                <Form.Item
                                    label={`${t('Account number')}*`}
                                    name="accountNumber"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: `${t('Account no reqd validation')}`
                                        }
                                    ]}
                                >
                                    <Input placeholder={t('Account number')} />
                                </Form.Item>

                                <Form.Item
                                    type="password"
                                    label={`${t('Re enter account number')}*`}
                                    name="reEnterAccountNumber"
                                    rules={[
                                        {
                                            required: true, whitespace: true,
                                            message: `${t('Re-enter account no reqd validation')}`
                                        }
                                    ]}
                                >
                                    <Input type="password" placeholder={t('Account number')} />
                                </Form.Item>

                                <Form.Item >
                                    <Button
                                        loading={loading}
                                        className='my-button'
                                        htmlType="submit" >
                                        {t('Submit')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        }
                    </div>

                </Card>
            </div>

        </>
        );
    }
}


const mapStateToProps = ({ global }) => ({
    token: global.token,
    currentUser: global.currentUser,
    loading: global.loading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(BankDetailsComponent)));


