import React, { Component } from "react";
import "./index.scss";
import { withRouter } from "react-router-dom";
import { Row, Col, Tabs, Button } from "antd";

export class VerticalImageCard extends Component {
  render() {
    let { image } = this.props;
    return (
      <div className="vertical-card-image-Container text-center">
        <div className="image-mask-container text-center"></div>
        <div className="image-text-container">
          <p className="text-white font16px font-weight-bold">Women's Wear</p>
          <p className="text-white font12px px-5">
            It is a long established fact that a reader will be{" "}
          </p>
          <button
            className="card-button"
            onClick={() => {
              this.props.history.push({
                pathname: "/login",
                search: "",
              });
            }}
          >
            View More
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(VerticalImageCard);
