import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaLanguage } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import { logout } from '../../actions'
import './style.scss'
import whiteLogo from '../../akar_White.png'
import AuthService from '../../service/authService'
import MediaUrl from '../../service/mediaUrl'
import ModalComponent from '../modal/modal'
import { Drawer, Button, Col, Badge, Modal, Radio } from 'antd';
import userImg from '../../assests/user.png';
import logoutImg from '../../assests/logout.svg'
import supportImg from '../../assests/support.svg';
import brandImg from '../../assests/brands.svg';
import ordersImg from '../../assests/orders.svg';
import profileImg from '../../assests/profile.svg';
import returnsImg from '../../assests/returns.svg';
import listingsImg from '../../assests/listings.svg';
import kycImg from '../../assests/kyc.svg';
import businessSettingsImg from '../../assests/buisness-settings.svg';
import disputesSVG from '../../assests/disputes.svg';
import termsConditionSVG from '../../assests/terms-and-conditions.svg';
import aboutImg from '../../assests/about.svg';
import chatSVG from '../../assests/chat-white.svg';
import cartSVG from '../../assests/cart-white.svg';
import sellOnAkarSVG from '../../assests/sell-on-akar.svg';
import faq from '../../assests/faq.png'
import bell from '../../assests/bell.png'

class LayoutComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			showCompleteKycBtn: false,
			isOpenModal: false,
			selectedLanguage: ''
		}
		this.logout = this.logout.bind(this)
	}

	componentDidMount() {

	}

	logout = async () => {
		const { dispatch } = this.props
		await AuthService.deleteNotificationToken();
		dispatch(logout())
		dispatch({ type: 'SET_AUTH_TOKEN', token: null })
		dispatch({ type: 'SET_CART_ITEMS_FLAG', isExist: false })
		if (typeof window !== 'undefined') { localStorage.removeItem('Authorization') }
		this.props.history.push({ pathname: '/' })
	}

	showKYCBtn = () => {
		const { currentUser } = this.props
		if (currentUser && currentUser.account && currentUser.account.type === 'Seller') {
			if (
				(currentUser.account.kycStatus === 'Pending' || currentUser.account.kycStatus === 'Approved') &&
				currentUser.account.isTermsAndConditionApprovedForSeller &&
				currentUser.account.isUserRegistered &&
				currentUser.account.isBankDetailsExist
			) {
				this.setState({ showCompleteKycBtn: false })
			} else {
				this.setState({ showCompleteKycBtn: true })
			}
		} else {
			if (
				(currentUser && (currentUser.account && currentUser.account.kycStatus === 'Pending') || (currentUser.account && currentUser.account.kycStatus === 'Approved')) &&
				currentUser.account.isUserRegistered
			) {
				this.setState({ showCompleteKycBtn: false })
			} else {
				this.setState({ showCompleteKycBtn: true })
			}
		}
	}

	showDrawer = () => {
		this.setState({
			visible: true,
		}, () => {

			this.showKYCBtn()
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	navigate = (url) => {
		this.props.history.push({ pathname: url })
	}

	openLanguageModal = () => {
		const { i18n } = this.props
		let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null;
		this.setState({ openLanguageModal: true, visible: false, selectedLanguage: i18n.language || language })
	}

	render() {
		const { visible, showCompleteKycBtn, isOpenModal, openLanguageModal, selectedLanguage } = this.state;
		const { currentUser, isItemsInCartExist, t, i18n } = this.props
		if (currentUser && !currentUser.id) return null;
		let { isUserRegistered, kycStatus } = currentUser && currentUser.account;
		const userType = currentUser && currentUser.account && currentUser.account.type

		return (

			<Col className='main-container'>
				<>
					<header className="toolbar z_index_1000 component-toolbar fixedHeader">
						<nav className="toolbar__navigation div1280pxWidh">
							<div style={{ height: '30px' }} >
								<MenuOutlined className='white_color' onClick={this.showDrawer} />
							</div>
							<div className='ml-3'>
								<Link to="/">
									<img height='40'
										src={whiteLogo} />
								</Link>
							</div>

							<div className='header_title'>
								{this.props.title}
							</div>
							<div className="spacer" />
							<div className="toolbar_navigation-items hideScroller">
								{currentUser.id ?
									<ul>
										<li>
											<a onClick={() => {
												this.props.history.push({ pathname: '/chat' })
											}}>
												<img src={chatSVG} alt="chat" className="header_imgs" />
											</a>
										</li>
										<li>
											<a onClick={() => {
												this.props.history.push({ pathname: '/order-forms' })
											}}>

												{isItemsInCartExist ? <Badge dot>
													<img src={cartSVG} alt="cart" className="header_imgs" />
												</Badge> : <img src={cartSVG} alt="cart" className="header_imgs" />}
											</a>
										</li>
										<li>
											<div>
												<img src={currentUser.profileImageFileUploadedData && currentUser.profileImageFileUploadedData.url ? MediaUrl.completeMediaUrl(currentUser.profileImageFileUploadedData.url, true, 100, 100) : userImg}
													className="leftUserImg"
													alt={'userImg'} />
												<span className={'user_name'}>{currentUser && currentUser.name ? currentUser.name : 'User'}</span>
											</div>
										</li>
									</ul>
									:
									<div className="toolbar_navigation-items hideScroller">
										<ul>
											<li>
												<Link to="/login" >
													Login
												</Link>
											</li>
										</ul>
									</div>}
							</div>
						</nav>
					</header>
					<Drawer
						placement={'left'}
						closable={false}
						onClose={this.onClose}
						maskClosable={true}
						visible={visible}
						key={'1'}
					>
						<div className="sidebar_navigation-items">
							<ul>
								{currentUser.id && currentUser.account && currentUser.account.isUserRegistered ?
									<li className="userProfile">
										<div className="flexContainer" onClick={() => this.navigate('/account')}>
											<div className="fortyRatioDiv" >
												<img src={currentUser.profileImageFileUploadedData && currentUser.profileImageFileUploadedData.url ? MediaUrl.completeMediaUrl(currentUser.profileImageFileUploadedData.url, true, 100, 100) : userImg}
													alt={'userImg'} />
											</div>
											<div className="sixtyRatioDiv" >
												<p className="label userName">{currentUser && currentUser.name ? currentUser.name : "Name"}</p>
												<p className="textcolor">{currentUser && currentUser.phoneNumber ? currentUser.phoneNumber : "Mobile"}</p>
											</div>
										</div>
									</li>
									:
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={profileImg} alt="about" />
										</div>
										<Link to="/login">{t('login')}</Link>
									</li>}

								{isUserRegistered && (kycStatus === 'Pending' || kycStatus === 'Approved') && userType === 'Buyer' ?
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={sellOnAkarSVG} alt="sellOnAkar" />
										</div>
										<Link to="/apply-to-sell" >
											{t('Sell on Akar')}
										</Link>
									</li> : null}

								<li onClick={this.onClose}>
									<div className="logo_div">
										<img src={bell} alt="alerts" />
									</div>
									<Link to="/alerts" >
										{t('Alerts')}
									</Link>
								</li>
								{currentUser.id ?
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={ordersImg} alt="orders" />
										</div>
										<Link to="/your-orders" >
											{t('Your orders')}
										</Link>
									</li>
									: null}
								{currentUser.id ?
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={returnsImg} alt="returns" />
										</div>
										<Link to="/your-returns" >
											{t('Your returns')}
										</Link>
									</li>
									: null}
								{currentUser.id ?
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={disputesSVG} alt="disputes" />
										</div>
										<Link to="/rto-dispute/disputes" >
											{t('Disputes')}
										</Link>
									</li>
									: null}

								<li onClick={this.onClose}>
									<div className="logo_div">
										<img src={businessSettingsImg} alt="logout" />
									</div>
									<Link to="/finance" >
										{t('Finance')}
									</Link>
								</li>

								{currentUser.id ?
									<li className='saperator'>
										<p>{t('Account management')}</p>
									</li>
									: null}
								{currentUser.id && showCompleteKycBtn ? <li onClick={this.onClose}>
									<div className="logo_div">
										<img src={kycImg} alt="kyc" />
									</div>
									<a onClick={() => {
										this.setState({ isOpenModal: true })
									}}>{t('Complete your kyc')}</a>
								</li> : null}

								{currentUser && currentUser.account && currentUser.account.isUserRegistered ? <>
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={profileImg} alt="profile" />
										</div>
										<Link to="/account" >
											{t('Profile_Settings')}
										</Link>
									</li>
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={businessSettingsImg} alt="business" />
										</div>
										<Link to="/business-profile" >
											{t('Business settings')}
										</Link>
									</li>
								</> : null}

								{currentUser && currentUser.account && currentUser.account.type === 'Seller' && currentUser.account.isUserRegistered && currentUser.account.kycStatus === 'Approved' && currentUser.account.isBankDetailsExist && currentUser.account.isTermsAndConditionApprovedForSeller ?
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={listingsImg} alt="listings" />
										</div>
										<Link to="/manage-listings" >
											{t('Your listings')}
										</Link>
									</li> : null}

								{currentUser && currentUser.account && currentUser.account.type === 'Seller' && currentUser.account.isUserRegistered && currentUser.account.kycStatus === 'Approved' && currentUser.account.isBankDetailsExist && currentUser.account.isTermsAndConditionApprovedForSeller ?
									<li onClick={this.onClose}>
										<div className="logo_div">
											<img src={brandImg} alt="brands" />
										</div>
										<Link to="/manage-brands" >
											{t('Your brands')}
										</Link>
									</li> : null}

								<li className='saperator'>
									<p>{t('Information')}</p>
								</li>
								<li onClick={this.onClose}>
									<div className="logo_div">
										<img src={aboutImg} alt="about" />
									</div>
									<Link to="/about-us" >
										{t('About Akar')}
									</Link>

								</li>
								<li onClick={this.onClose}>
									<div className="logo_div">
										<img src={termsConditionSVG} alt="terms" />
									</div>
									<Link to="/terms-and-conditions" >
										{t('Terms and conditions')}
									</Link>
								</li>

								<li onClick={this.openLanguageModal}>
									<div className="logo_div">
										<FaLanguage />
									</div>
									<a>{t('Change Language')}</a>
								</li>

								<li onClick={this.onClose}>
									<div className="logo_div">
										<img src={faq} alt="faq" />
									</div>
									<Link to="/FAQ" >
										{t('FAQs')}
									</Link>

								</li>
								<li onClick={this.onClose}>
									<div className="logo_div">
										<img src={supportImg} alt="support" />
									</div>
									<Link to="/contact-us">{t('Support')}</Link>
								</li>
								{currentUser.id ?
									<li className='saperator'>
										<p>{t('Signout')}</p>
									</li>
									: null}
								{currentUser.id ?
									<li onClick={this.logout}>
										<div className="logo_div">
											<img src={logoutImg} alt="logout" />
										</div>
										<a>{t('Logout')}</a>
									</li>
									: null}
							</ul>
						</div>
					</Drawer>

					<div className='content-container div1280pxWidh'>
						{this.props.children}
					</div>

					{isOpenModal ?
						<ModalComponent showModal={isOpenModal} onClose={() => {
							this.setState({ isOpenModal: false })
						}} /> : null
					}

					<Modal footer={null}
						centered={true}
						onCancel={() => {
							this.setState({ openLanguageModal: false, selectedLanguage: i18n.language })
						}}
						maskClosable={false}
						visible={openLanguageModal}>
						<div className="text-center">
							<h3 className="mb-4">CHOOSE LANGUAGE / अपनी भाषा चुनें</h3>
							<div className="d-flex" style={{ justifyContent: 'center' }}>
								<Radio.Group value={selectedLanguage}
									onChange={(e) => {
										this.setState({ selectedLanguage: e.target.value })
									}}>
									<Radio value={'en'}>English</Radio>
									<Radio value={'hi'}>हिंदी</Radio>
								</Radio.Group>
							</div>
							<Button type={'primary'} className="my-3" onClick={() => {
								i18n.changeLanguage(selectedLanguage)
								if (typeof window !== 'undefined') { localStorage.setItem("language", selectedLanguage) };
								this.setState({ openLanguageModal: false })
								window.location.reload();
							}}>
								{(selectedLanguage === 'en') ? 'CONTINUE IN ENGLISH' : null}
								{(selectedLanguage === 'hi') ? 'हिंदी में जारी रखें' : null}
							</Button>
							<p>
								{(selectedLanguage === 'en') ? "Language options can be changed anytime. We will translate information to help you browse and order. Please note that translations have been provided for convenience only and English version of the Akar's, including our Conditions of Use, is the definitive version." : null}
								{(selectedLanguage === 'hi') ? "भाषा विकल्प कभी भी बदले जा सकते हैं। ब्राउज़ करने और ऑर्डर करने में आपकी सहायता के लिए हम जानकारी का अनुवाद करेंगे। कृपया ध्यान दें कि अनुवाद केवल सुविधा के लिए प्रदान किए गए हैं और हमारी उपयोग की शर्तों सहित आकार का अंग्रेजी संस्करण निश्चित संस्करण है।" : null}
							</p>
						</div>
					</Modal>
				</>
			</Col>
		);
	}
}
const mapStateToProps = ({ counter, global }) => ({
	token: global.token,
	currentUser: global.currentUser,
	isItemsInCartExist: global.isItemsInCartExist
})
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(LayoutComponent)));
