import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Cardslider from "../../sections/cardslider";
import Api from "../../../service/network";
import MediaUrl from "../../../service/mediaUrl";
import { Row, Col } from "antd";
import "./index.scss";

class ImageCardSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageCardData: [],
      cardPostData: [],
      imageOrientation: "horizontal",
    };
  }
  componentDidMount = () => {
    const { itemData } = this.props;
    let payLoad = itemData.payLoad;
    if (typeof payLoad === "string") {
      payLoad = JSON.parse(payLoad);
    }
    this.setState({ imageOrientation: payLoad["imageOrientation"] });

    if (itemData.callType === "Get") {
      let path = `${itemData.dataUrl}${itemData.queryParam}`;
      Api.makeGetRequest(path).then((res) => {
        let output = res.data.data.data;
        output.forEach((item) => {
          if (itemData.name) {
            item.name = itemData.name;
          }
          if (item.imageUrl) {
            let url = MediaUrl.completeMediaUrl(item.imageUrl, true, 100, 100);
            item.imageUrl = url;
          }
        });
        this.setState({ imageCardData: output });
      });
    }
  };
  redirectTo = (item) => {
    let title = encodeURIComponent(item.name);
    let payload = item.payLoad;
    let encoded = Buffer.from(JSON.stringify(payload), "ascii").toString("base64");
    console.log("this.props.history = =", this.props);
    this.props.history.push({
      pathname: `/search/listings/title=${title}/${encoded}`,
    });
  };
  render() {
    let { imageCardData, imageOrientation } = this.state;

    return (
      <div className="ImageCardSlider-container">
        <Row className="ImageCardSlider-container-row">
          <Col
            className="ImageCardSlider-container-col"
            xs={24}
            sm={imageOrientation == "horizontal" ? 8 : 5}
          >
            {imageCardData && imageCardData[0] && imageCardData[0].imageUrl ? (
              <div className="image-mask-container">
                <img
                  className="image-container"
                  src={imageCardData[0].imageUrl}
                  alt="banner"
                  style={{
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    this.redirectTo(imageCardData[0]);
                  }}
                />
              </div>
            ) : null}
          </Col>
          <Col
            className={`ImageCardSlider-container-col`}
            xs={24}
            sm={{ offset: 1, span: imageOrientation == "horizontal" ? 15 : 18 }}
          >
            <div className="w-100 ">
              {imageCardData && imageCardData[0] ? (
                <Cardslider itemData={imageCardData[0]} />
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(ImageCardSlider);
//
// ${
//   imageOrientation == "vertical" && "vertical-orientation"
// }`
