import React, { Component } from "react";
import "./index.scss";
import { Input } from "antd";
const { Search } = Input;

const onSearch = (value) => console.log(value);

export class SearchBar extends Component {
  render() {
    return (
      <div className="searchBar-container">
        <Search
          className="searchBar"
          placeholder="Search entire store here..."
          allowClear
          enterButton="Search"
          onSearch={onSearch}
        />
      </div>
    );
  }
}

export default SearchBar;
