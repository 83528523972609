let token = typeof window !== 'undefined' ? localStorage.getItem('Authorization') : null

const initialState = {
    currentUser: {
        email: null,
        mob: null,
        name: null,
        type: null,
        account: { kycStatus: 'NoDocuments', isUserRegistered: false }
    },
    token: token ? token : null,
    buttonLoading: false,
    isItemsInCartExist: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER':
            let qwe = {
                ...state,
                currentUser: action.user
            }
            return qwe;

        case 'SET_CURRENT_USER_ACCOUNT_DETAILS':
            let abc = {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    account: action.account,
                    currentUser: {
                        type: action.userType && action.userType,
                    }
                }
            }
            return abc

        case 'LOGOUT':
            return {
                ...state,
                currentUser: {}
            }

        case 'SHOW_BTN_LOADING':
            return {
                ...state,
                buttonLoading: true
            }

        case 'HIDE_BTN_LOADING':
            return {
                ...state,
                buttonLoading: false
            }

        case 'SET_AUTH_TOKEN':
            return {
                ...state,
                token: action.token
            }
        case 'SET_CART_ITEMS_FLAG':
            return {
                ...state,
                isItemsInCartExist: action.isExist
            }

        default:
            return state
    }
}