import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Row, Col, Input } from 'antd';
import { withTranslation } from 'react-i18next';
import myimage from '../../imageNotFound.jpeg'
import LayoutComponent from '../layoutComponents/layoutComponent';
import BrandService from '../../service/brandService'
import SaveListing from '../../service/saveListingService'
import { BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import MediaUrl from '../../service/mediaUrl';
const { Search } = Input;

class BrandListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userBrandList: [],
            userSearchBrands: [],
            selectedBrand: {},
            disableDoneBtn: true
        }
    }

    componentDidMount() {
        if (SaveListing.brand.brand.name) {
            this.setState({ selectedBrand: SaveListing.brand })
            this.setState({ disableDoneBtn: false })
        }
        this.getAllUsersBrands()
    }

    searchBrand = (searchTerm) => {
        if (searchTerm) {
            let { userSearchBrands } = this.state
            let options = userSearchBrands.filter((item) => {
                return item.brand && item.brand.name && item.brand.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
            })
            this.setState({ userBrandList: options })
        } else {
            this.getAllUsersBrands()
        }
    }

    selectBrand = (brand) => {
        this.setState({
            selectedBrand: brand,
            disableDoneBtn: false
        })
    }

    getAllUsersBrands() {
        BrandService.getUsersBrand()
            .then((res) => {
                this.setState({ userBrandList: res.data.data, userSearchBrands: res.data.data }, () => {
                })
            }, err => { })
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    saveSelectedBrand = () => {
        let brand = this.state.selectedBrand
        SaveListing.isBrandSelected = true
        SaveListing.brand = brand
        SaveListing.brand.isNewBrand = false
        this.navigate('/create-list')
    }

    render() {
        const { t } = this.props
        const { userBrandList, selectedBrand, disableDoneBtn } = this.state;
        return (
            <LayoutComponent title={t('New listing')}>
                <Row className='divWithFixButton'>
                    <Col span={24} className='toolbar create-listing-bar '>
                        <div>
                            <h4 className='mb-0'>{t('Select listing brand')}</h4>
                            <p className='label'>
                                {t('You can create listing only of your registered brand')}
                            </p>
                        </div>
                    </Col>
                    <Col span={24} className='toolbar brand_search_bar'>
                        <p className='label mb-1'>
                            {t('My brands')}
                        </p>

                        <Search
                            placeholder={t("Search brand")}
                            onChange={e => this.searchBrand(e.target.value)}
                            style={{ width: "100%", background: 'rgb(237, 237, 237)', borderRadius: '2px' }}
                        />
                    </Col>

                    <Col span={24} className='brand_list bottom_margin'>
                        <Row>
                            <Col span={24} >
                                {userBrandList.map((item) => {
                                    return (
                                        <div key={item.id} className='brand' onClick={(e) => this.selectBrand(item)}>
                                            <img src={item.brand.imageFileUploadedData.url ? MediaUrl.completeMediaUrl(item.brand.imageFileUploadedData.url) : myimage} alt='banner' />
                                            <p className='label' >{item.brand.name}</p>
                                            <div className='spacer'></div>
                                            {
                                                item.status === 'REJECTED'
                                                    ? <p style={{ color: '#cacaca', fontSize: 12 }}>
                                                        {t('Brand rejected')}</p>
                                                    : <div >
                                                        {
                                                            selectedBrand && (selectedBrand.brandId === item.brandId)
                                                                ? <CheckSquareOutlined />
                                                                : <BorderOutlined />
                                                        }
                                                    </div>
                                            }
                                        </div>
                                    )
                                })}
                            </Col>

                            <Col span={24} className='add_brand'
                                onClick={(e) => this.navigate('/onBoarding')}>
                                <div className='plus_icon'>
                                    <div className='plus'>
                                        <p>+</p>
                                    </div>
                                </div>
                                <p className='label'>{t('Add a new brand')}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className='stickToBottom'>
                        <div className='button_div'>
                            <button disabled={disableDoneBtn} onClick={(e) => this.saveSelectedBrand()}>
                                {t('Done')}
                            </button>
                        </div>
                    </Col>
                </Row>


            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(BrandListComponent));