import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import OrderListingHeader from './listingHeader'
import { Row, Col } from 'antd';


class ExpiryListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() { }

    render() {

        return (
            <OrderListingHeader>
                <Row>
                    <Col span={24} className='bottom-border fixed-Header'>
                        + New Listing
                    </Col>
                    <Col span={24} >
                        <header className="toolbar filter-bar">
                            <nav className="toolbar__navigation">
                                0 items Found
                        
                                <div className="spacer" />
                                {/* <div className="toolbar_navigation-items">
                                    <ul>
                                        <li>
                                                Sort
                                        </li>

                                        <li>
                                                Filter
                                        </li>
                                    </ul>
                                </div>
                             */}
                            </nav>
                        </header>
                    </Col>
                </Row>

                <Row className='order-content-container'>
                    <Col>
                         Contents
                    </Col>
                </Row>
                
            </OrderListingHeader>
        )
    }
}

export default withRouter(ExpiryListComponent)