import API from './network';
import Urls from './urls'

export const AlertService = {

  getAllAlerts(data){
    const { results, pageNumber, searchValue, status } = data;
    let path = `${Urls.getAllAlerts}?pageNumber=${pageNumber}&limit=${results}`;
    return API.makeGetRequest(path, null, null, null).then((res) => {
      return res;
    })
  }    
}