import API from './network';
import Urls from './urls'

const BrandService = {
    
    getUsersBrand() {
        const path = Urls.userBrand
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            })
    },
    getSearchedBrand(param) {
        const path = Urls.searchAllBrand + param
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            })
    },

    createBrand(data) {
        const path = Urls.createBrand
        let dataToPost =
        {
            "relationType": data.relationType,
            "isAcknowledged": data.IsAcknowledged,
        }

        if (data.isNewBrand) {
            dataToPost['Brand'] = {
                "name": data.brand.name,
                "description": data.description,
                "imageFileUploadedData": data.imageFileUploadedData
            }
        } else {
            dataToPost['brandId'] = data.brand.id
        }

        if (data.relationType !== 'TRADER') {
            dataToPost['mapBrandAccountFileUploadedDatas'] = data.mapBrandAccountFileUploadedDatas
        }
        return API.makePostRequest(path, null, null, dataToPost, null)
            .then((res) => {
                return res
            })
    },

    reapplyBrand(id, brandId, data) {
        const path = Urls.reapplyBrand + id ;

        let dataToPost =
        {
            "relationType": data.relationType,
            "isAcknowledged": data.IsAcknowledged,
            "brandId": Number(brandId)
        }

        if (data.relationType !== 'TRADER') {
            dataToPost['mapBrandAccountFileUploadedDatas'] = data.mapBrandAccountFileUploadedDatas
        }
        return API.makePutRequest(path, null, null, dataToPost, null)
            .then((res) => {
                return res
            })
    },

    addImage(data, type) {
        const path = Urls.addImage
        var bodyFormData = new FormData();
        bodyFormData.append('files', data);
        bodyFormData.append('type', type);
        return API.makePostRequest(path, null, null, bodyFormData, null)
            .then((res) => {
                return res
            })
    },

    deleteImage(data) {
        const path = Urls.deleteImage
        // var bodyFormData = new FormData();
        // bodyFormData.append('fileurl', data);
        const body = { fileurl: data }
        return API.makeDeleateRequest(path, null, null, body, null)
            .then((res) => {
                return res
            })
    }

};

export default BrandService;