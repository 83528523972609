import React from 'react';
import { hydrate, render } from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import { Provider } from 'react-redux'
import { HashRouter } from "react-router-dom";
//  import { StaticRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store'
import './translations/i18n'
import { initializeApp } from 'firebase/app';
import { getAllChatThreads } from './actions';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import AuthService from './service/authService';
const webPushPublicKey = process.env.REACT_APP_FCM_WEB_PUSH_PUBLIC_KEY;

var firebaseConfig = {
  apiKey: "AIzaSyDxgdzzpH3Gww_AX9O2pHJRziVlwYvisCw",
  authDomain: "akar-33188.firebaseapp.com",
  databaseURL: "https://akar-33188.firebaseio.com",
  projectId: "akar-33188",
  storageBucket: "akar-33188.appspot.com",
  messagingSenderId: "593541010796",
  appId: "1:593541010796:web:b6b5f1241d1baa858e0f49"
};
const target = document.querySelector('#root')
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js").then(function (registration) { }).catch(function (err) { });
}

const registerForNotification = (notificationToken) => {
  AuthService.addNotificationToken(notificationToken).then((resp) => { }, (error) => { })
}

const firebaseTokenRegistration = (messaging) => {
  getToken(messaging, { vapidKey: webPushPublicKey }).then((currentToken) => {
    let AuthToken = typeof window !== 'undefined' ? localStorage.getItem('Authorization') : null
    if (currentToken && AuthToken) {
      registerForNotification(currentToken)
    } else { }
  }).catch((err) => { });
}


firebaseTokenRegistration(messaging);


let device_id = typeof window !== 'undefined' ? localStorage.getItem('deviceId') : null
if (!device_id) {
  let deviceId = uuidv4();
  if (typeof window !== 'undefined') {
    localStorage.setItem('deviceId', deviceId)
  }
}

const listenToNotifications = () => {
  onMessage(messaging, (payload) => {
    const notificationTitle = payload.data.notificationTitle;
    let body = {};
    let notificationOptions = {
      body: payload.data.notificationBody,
      icon: '/akar-logo-small.png',
    };
    if (payload.data.type === "UserChat") { //Chat Notifications
      body = JSON.parse(payload.data.body);
      notificationOptions['data'] = { url: `#/chat?threadId=${body.chatThreadGuid}` }
      if (body.direction === 0) {
        store.dispatch({
          type: 'SET_NOTIFICATION_DATA_IF_TAB_ACTIVE',
          payload: { data: body }
        })

        setTimeout(() => {
          store.dispatch(getAllChatThreads(1, 10, 'assign'))
        }, 1000);
      }
    }
    else if (payload.data.type === "ProductDetail") {
      notificationOptions['data'] = { url: `#/view-product/${payload.data.id}` }
    }
    else if (payload.data.type === "BrandDetail") {
      notificationOptions['data'] = { url: `#/` }
    }
    else if (payload.data.type === "AccountDetail") {
      notificationOptions['data'] = { url: `#/user-profile` }
    }
    else if (payload.data.type === "OrderReturnDetail") {
      notificationOptions['data'] = { url: `#/return-details/${payload.data.id}` }
    }
    else if (payload.data.type === "OrderDisputeDetail") {
      notificationOptions['data'] = { url: `#/rto-dispute/dispute?disputeCustomId=${payload.data.id}` }
    }
    else { //Notifications of order type
      notificationOptions['data'] = { url: `#/order-details/${payload.data.id}` }
    }

    var notification = new Notification(notificationTitle, notificationOptions);
    notification.onclick = (notification, event) => {
      // console.log(notification, notification.target.data.url, "target url notification")
      window.open(notification.target.data.url)
    }
  });
}

listenToNotifications()
//  if (messaging.isSupported()) {
//    if (Notification.permission === "granted") {
//    }
//  }

render(
  <Provider store={store} >
    <script src="https://www.gstatic.com/firebasejs/9.6.7/firebase-app.js"></script>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider >,
  target
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

