import "./index.scss";
import { withRouter } from "react-router-dom";
import { Row, Col, Tabs, Button } from "antd";
import ProductCard from "../ProductCard";
import React, { Component } from "react";
import SearchService from "../../../service/searchService";
const { TabPane } = Tabs;

let payloads = {
  Clothings: {
    filters: ``,
    hitsPerPage: 10,
  },
  Handbag: {
    filters: `( categoryList.parentCategory.name : "Men Suits" OR categoryName : "Men Suits")`,
    hitsPerPage: 10,
  },
  Shoes: {
    filters: `( categoryList.parentCategory.name : "Men Suits" OR categoryName : "Men Suits")`,
    hitsPerPage: 10,
  },
  Watch: {
    filters: `( categoryList.parentCategory.name : "Women Kurti" OR categoryName : "Women Kurti")`,
    hitsPerPage: 10,
  },
  Accessories: {
    filters: `( categoryList.parentCategory.name : "Men Suits" OR categoryName : "Men Suits")`,
    hitsPerPage: 10,
  },
};

class ProductCardTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Clothings",
      visibleProducts: [],
    };
  }

  componentDidMount = () => {
    let { activeTab } = this.state;
    let payload = payloads[activeTab];
    this.fetchProductsData(payload);
  };

  fetchProductsData = (payload) => {
    SearchService.algoliaProductsearch("", payload).then(data=>{
      this.setState({ visibleProducts: data })
    });
  };
  handleTabChange = (data) => {
    this.setState({ activeTab: data });
    let payload = payloads[data];
    this.fetchProductsData(payload);
  };
  renderTabsData = () => {
    let { visibleProducts } = this.state;
    return (
      <div className="w-100 card-grid-container-lg">
        <Row className="py-2" align="middle">
          {visibleProducts.map((item, index) => {
            return (
              <Col key={index} className="text-center py-2 px-2" xs={12} sm={{ span: 6 }}>
                <ProductCard data={item} token={true} />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };
  render() {
    let { viewAll } = this.props;
    let {activeTab} = this.state;
    const operations = (
      <button
        onClick={()=> {
          this.props.history.push({
            pathname: `search/listings/title=${activeTab}/${payloads[activeTab]}`,
            search: `?redirect=search/listings/title=${activeTab}/${payloads[activeTab]}` })
        }}
        style={{
          paddingRight: "20px",
          border: "none",
          backgroundColor: "transparent",
        }}
      >
        View All
      </button>
    );
    return (
      <Col span={24} className="">
        <Tabs tabBarExtraContent={operations} onChange={this.handleTabChange}>
          <TabPane tab="Clothings" key="Clothings">
            {this.renderTabsData()}
          </TabPane>
          <TabPane tab="Handbag" key="Handbag">
            {this.renderTabsData()}
          </TabPane>
          <TabPane tab="Shoes" key="Shoes">
            {this.renderTabsData()}
          </TabPane>
          <TabPane tab="Accessories" key="Accessories">
            {this.renderTabsData()}
          </TabPane>
          <TabPane tab="Watch" key="Watch">
            {this.renderTabsData()}
          </TabPane>
        </Tabs>
      </Col>
    );
  }
}

export default withRouter(ProductCardTabs);
