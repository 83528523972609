import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import myimage from '../../imageNotFound.jpeg'
import Api from '../../service/network'
import MediaUrl from '../../service/mediaUrl'
import './index.scss'
import SectionLoader from './sectionLoader'
import { commonRedirections } from '../sections/redirections';

class ButtonSliderView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnGetData: [],
            buttonPostData: []
        }
    }
    componentDidMount() {
        const { itemData } = this.props
        if (itemData.callType === 'Get') {
            Api.makeGetRequest(itemData.dataUrl).then((res) => {
                let output = res.data.data.data
                this.setState({ btnGetData: output })
            })
        }
        if (itemData.callType === 'Post') {
            Api.makePostRequest(itemData.dataUrl, null, null, {}, null).then((res) => {
                let output = res.data.data
                output.sectionView.data.forEach(item => {
                    if (item.imageUrl) {
                        let url = MediaUrl.completeMediaUrl(item.imageUrl, true, 100, 100)
                        item.imageUrl = url
                    }
                })
                this.setState({ buttonPostData: output })
            })
        }
    }

    render() {
        const { itemData } = this.props
        const { btnGetData, buttonPostData } = this.state

        return (<>{itemData ? <div className="toolbar_navigation-items mt-2 slider_div">
            <div className={'mb-3 productSectionTitle'}>{itemData.name}</div>
            <ul className={'btnUl'}>
                {btnGetData && btnGetData.length > 0 && btnGetData.map((btnVal, btnkey) => {
                    return (<li key={btnkey} onClick={() => {
                        btnVal.payLoad = {}
                        let response = commonRedirections(btnVal)
                        this.props.history.push({ pathname: response })

                    }}><span > {btnVal.itemDescription.title} </span>
                    </li>)
                })}

                {buttonPostData && buttonPostData.sectionView && buttonPostData.sectionView.data.length > 0 && buttonPostData.sectionView.data.map((item, index) => {
                    return (<li key={index} onClick={() => {
                        let encoded = Buffer.from(JSON.stringify(buttonPostData.sectionView.viewAll.payLoad), 'ascii').toString('base64');
                        this.props.history.push({ pathname: `/search/listings/title=${itemData.name}/${encoded}` })
                    }}>
                        {item && item.itemDescription && item.itemDescription.title ? item.itemDescription.title : 'Mens wear'}
                    </li>)
                })}
            </ul>

        </div> : <div className="sectionloader">
                <SectionLoader />
            </div>}</>)
    }
}
export default withRouter(ButtonSliderView)
