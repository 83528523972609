import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col, Steps } from 'antd';
import moment from 'moment';
import Footer from '../footer';
import './index.scss'
const { Step } = Steps;

class ShipmentTrackingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() { }

    render() {
        return (<><LayoutComponent title={'Shipment Tracking'}>
            <div className={'top_details'}>
                <Row>
                    <Col span={24}>
                        <div className={'flexContainer order_values'}>
                            <div className={'order_key'}>AWB</div>
                            <div className={'order_value'} >GJH76786786</div>
                        </div>

                    </Col>
                    <Col span={24}>
                        <div className={'flexContainer order_values'}>
                            <div className={'order_key'} >ORDER ID</div>
                            <div className={'order_value'}>GJH76786786</div>
                        </div>

                    </Col>
                    <Col span={24}>
                        <div className={'flexContainer order_values'}>
                            <div className={'order_key'} >Seller</div>
                            <div className={'order_value'}>SUIT Ville</div>
                        </div>

                    </Col>
                    <Col span={24}>
                        <div className={'flexContainer order_values'}>
                            <div className={'order_key'}>Buyer</div>
                            <div className={'order_value'}>RS GROUP</div>
                        </div>

                    </Col>
                    <Col span={24}>
                        <div className={'flexContainer order_values'}>
                            <div className={'order_key'}>Current City</div>
                            <div className={'order_value'}>Rohtak</div>
                        </div>

                    </Col>
                    <Col span={24}>
                        <div className={'flexContainer order_values'}>
                            <div className={'order_key'}>Destination City</div>
                            <div className={'order_value'}>Delhi</div>
                        </div>

                    </Col>
                    <Col span={24}>
                        <div className={'flexContainer estimateVal'}>
                            <div className={'order_key lastVal'}>Estimated date of Arrival</div>
                            <div className={'order_value lastVal'}>{moment().format('lll')}</div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{ height: '8px' }} />
            <div className={'top_details mb-4'}>
                <div ><h2>Tracking Details</h2></div>
                <div className={'tracking_stages'}>
                    <Row>
                        <Col span={24}>
                            <Steps current={1} direction="vertical">
                                <Step
                                    subTitle={<div>
                                        <b className='dark_font_color'>{moment().format('ll')}</b>
                                        <p><b className='dark_font_color'>Shiped ready for pickup</b></p>
                                        <div className={'flexContainer'}>
                                            <p className={'halfDiv order_key'}>City</p>
                                            <p className={'halfDiv position order_value '}>Delhi</p>
                                        </div>
                                        <div className={'flexContainer mb-4'}>
                                            <p className={'halfDiv order_key'}>State</p>
                                            <p className={'halfDiv position order_value'}>Delhi</p>
                                        </div>
                                    </div>}
                                />
                                <Step
                                    subTitle={<div>
                                        <b className='dark_font_color'>{moment().add(2, 'days').format('ll')}</b>
                                        <p><b className='dark_font_color'>Pick up from seller</b></p>
                                        <div className={'flexContainer'}>
                                            <p className={'halfDiv order_key'}>City</p>
                                            <p className={'halfDiv position order_value'}>Delhi</p>
                                        </div>
                                        <div className={'flexContainer mb-4'}>
                                            <p className={'halfDiv order_key'}>State</p>
                                            <p className={'halfDiv position order_value'}>Delhi</p>
                                        </div>
                                    </div>} />
                                <Step
                                    subTitle={<div>
                                        <b className='dark_font_color'>{moment().add(4, 'days').format('ll')}</b>
                                        <p><b className='dark_font_color'>Received at Gandhinagar</b></p>
                                        <div className={'flexContainer'}>
                                            <p className={'halfDiv order_key'}>City</p>
                                            <p className={'halfDiv position order_value'}>Delhi</p>
                                        </div>
                                        <div className={'flexContainer mb-4'}>
                                            <p className={'halfDiv order_key'}>State</p>
                                            <p className={'halfDiv position order_value'}>Delhi</p>
                                        </div>
                                    </div>}
                                />

                            </Steps>
                        </Col>
                    </Row>
                </div>
            </div>
        </LayoutComponent><Footer /></>)
    }
}

export default withRouter(ShipmentTrackingComponent)