import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Row, Col, Tabs, Skeleton, Card, message } from 'antd'
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux'
import queryString from 'query-string';
import { DisputeStatus } from '../../service/statusTypes'
import DisputeService from '../../service/disputeService'
import LayoutComponent from '../layoutComponents/layoutComponent'
import myImage from '../../imageNotFound.jpeg';
import { withTranslation } from 'react-i18next';
const { TabPane } = Tabs;

class DisputeListingComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { disputesData: [{}], status: '', pageNumber: 1 };
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        if (values && values.status) {
            if (values && values.status && Array.isArray(values.status)) {
                this.setState({ status: values.status[0] }, () => {
                    this.getAllUserDisputes()
                })
            } else {
                this.setState({ status: values.status }, () => {
                    this.getAllUserDisputes()
                })
            }
        } else {
            this.setState({ status: 'Open' }, () => {
                this.getAllUserDisputes()
            })
        }
    }

    getAllUserDisputes = async () => {
        const values = queryString.parse(this.props.location.search)
        const { t } = this.props
        let obj = {
            limit: 100,
            pageNumber: this.state.pageNumber,
            // status: values && values.status ? values.status : this.state.status
        }

        if (this.state.status !== '') {
            obj.status = values && values.status ? values.status : this.state.status
        }

        let { data } = await DisputeService.getAllDisputes(obj)
        if (data.isSuccess) {
            this.setState({ disputesData: data.data.results })
        } else {
            message.error(`${t('Error in fetching data')}`)
        }
    }

    navigate = (url, disputeCustomId) => {
        this.props.history.push({ pathname: url, search: `?disputeCustomId=${disputeCustomId}&buyerName=Suitville` })
    }

    onTabChange = (key) => {
        switch (key) {
            case 'Open':
                this.props.history.push({ pathname: '/rto-dispute/disputes', search: `?status=${key}` })
                return this.setState({ status: key, disputesData: [] }, () => {
                    this.getAllUserDisputes()
                })
            case 'Rejected':
                this.props.history.push({ pathname: '/rto-dispute/disputes', search: `?status=${key}` })
                return this.setState({ status: key, disputesData: [] }, () => {
                    this.getAllUserDisputes()
                })
            case 'Accepted':
                this.props.history.push({ pathname: '/rto-dispute/disputes', search: `?status=Accepted&status=AcceptedWithDiscount` })
                return this.setState({ status: key, disputesData: [] }, () => {
                    this.getAllUserDisputes()
                })
            case 'Hold':
                this.props.history.push({ pathname: '/rto-dispute/disputes', search: `?status=${key}` })
                return this.setState({ status: key, disputesData: [] }, () => {
                    this.getAllUserDisputes()
                })
            default:
                return
        }
    }

    render() {
        const { disputesData, status } = this.state
        const { currentUser, t } = this.props

        const disputes = <>
            {disputesData && disputesData.length > 0 ? disputesData.map((item, index) => {
                return (<Row className='order-content-container' key={index}>
                    <Col xs={24} className='order-container'>
                        <div
                            className='orderCard pointer_Cursor'
                            onClick={() => { this.navigate('/rto-dispute/dispute', item.disputeCustomId) }}
                        >
                            <div className={'my_order_image'}>
                                <img className={'product_Img'} src={item.productImageUrl ? item.productImageUrl : myImage} alt='banner' />
                            </div>
                            <div className={'right_div px-4'}>
                                <div className='seller_name'>{t('Order By')}: {item.buyerAccountName}</div>
                                <div className='seller_name'>{t('Sold By')}: {item.sellerAccountName}</div>
                                <div className='order_title mt-2'>{t('Dispute Id')}: {item.disputeCustomId}<span className={'rightIcon font16px'}><RightOutlined /></span></div>
                                <div className='order_title'>{t('Dispute Date')}: {item.createdOn && moment.utc(item.createdOn).utcOffset('+0530').format('lll')}</div>
                                <div>{currentUser.accountId === item.buyerAccountId ?
                                    <div>
                                        {/* <div className='order_title' style={{ color:"black"}}>Shipping Charges: <b>{item.shippingCharges}</b></div> */}
                                        <div className='order_title' style={{ color: "black" }}>{t('Total Amount')}: <b>₹ {item.totalAmount}</b></div>
                                    </div> :
                                    <div>
                                        <div className='order_title' style={{ color: "black" }}>{t('Total Amount')}: <b>₹ {item.totalAmount - item.shippingCharges}</b></div>
                                    </div>}</div>
                                <div className='packing_status' > <span style={{ color: "black" }}>{t('Status')}:</span>
                                    {item.status === 'AcceptedWithDiscount' ? ` ${t('Accepted With Discount')}` : ` ${t(item.status)}`}
                                </div>
                            </div>
                        </div>

                    </Col>
                </Row>)
            }) : (disputesData.length === 0 ? <Card className={'no_data_div'}>{t('No dispute exists for this category')}</Card> : <Skeleton active />)}
        </>

        return (<LayoutComponent title={t("Your RTO Disputes")}>
            <Row className='fixed_container'>
                <Col span={24}>
                    <Tabs className='tabHeader'
                        size={'large'}
                        onChange={this.onTabChange}
                        activeKey={status}
                        centered >
                        <TabPane tab={t("OPEN")} key="Open">{disputes}</TabPane>
                        <TabPane tab={t('REJECTED')} key="Rejected"> {disputes}</TabPane>
                        <TabPane tab={t('ACCEPTED')} key="Accepted">{disputes}</TabPane>
                        <TabPane tab={t('HOLD')} key="Hold">{disputes}</TabPane>
                    </Tabs>
                </Col>
            </Row>
        </LayoutComponent>);
    }
}

const mapStateToProps = ({ global }) => ({
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(DisputeListingComponent)));
