import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import myimage from "../../imageNotFound.jpeg";
import Api from "../../service/network";
import MediaUrl from "../../service/mediaUrl";
import { commonRedirections } from "../sections/redirections";
import SectionLoader from "./sectionLoader";
import { Row, Col } from "antd";
import "./index.scss";

class SquareCardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      squareCardData: [],
    };
  }
  componentDidMount() {
    const { itemData } = this.props;

    if (itemData.callType === "Get") {
      let path = `${itemData.dataUrl}${itemData.queryParam}`;
      Api.makeGetRequest(path, null, null, null).then((res) => {
        let output = res.data.data.data;
        output.forEach((item) => {
          if (item.imageUrl) {
            let url = MediaUrl.completeMediaUrl(item.imageUrl, true, 100, 100);
            item.imageUrl = url;
          }
        });
        this.setState({ squareCardData: output });
      });
    }
  }

  render() {
    const { squareCardData } = this.state;
    const { itemData } = this.props;
    return (
      <>
        {itemData ? (
          <div className="toolbar_navigation-items mt-2 slider_div">
            <ul className="hideScrollbar">

              {squareCardData &&
                squareCardData.length > 0 &&
                squareCardData.map((sqCardVal, sqkey) => {

                  return (
                    <li
                      key={sqkey}
                      onClick={() => {
                        let response = commonRedirections(sqCardVal);
                        this.props.history.push({ pathname: response });
                      }}
                    >
                      <img
                        className="square-card-image"
                        src={sqCardVal.imageUrl ? sqCardVal.imageUrl : myimage}
                        alt="banner"
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : (
          <div className="sectionloader">
            <SectionLoader />
          </div>
        )}
      </>
    );
  }
}
export default withRouter(SquareCardView);
