import React from "react";
import {Col,Row} from 'antd'
const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    table: {
      border: "1.5px solid black",
      borderRadius: "12px",
    },
    td: {
      textAlign: "center",
      padding: "10px",
      borderBottom: "1px solid black",
    },
    th: {
      borderBottom: "1.5px solid black",
      padding: "20px",
      textAlign: "center",
    },
    spacer: {
      height: "20px",
    },
    underline: {
      textDecoration: "underline",
    },
  };
function StaticOfferContent() {
  return (
    <Row>
      <Col span={24} style={{padding:"5px"}}>
        <img
          src="https://akar-prod-media.s3.ap-south-1.amazonaws.com/StaticAssets/2-2-min.jpg"
          width="100%"
        />
      </Col>
      <Col span={24}>
        <Col span={20} offset={2}>
          <div style={styles.spacer}></div>
          <div style={styles.spacer}></div>
          <div style={styles.container}>
            <h2 style={{ ...styles.underline, color: "red" }}>
              GET CASHBACK UPTO RS.2000/-
            </h2>
            <div style={styles.spacer}></div>
            <p>
              Now purchase and get cashback upto Rs.2000/- , direct into your
              bank account or in your paytm wallet, Gpay wallet , phonePe wallet
              Or through UPI.
            </p>
            <h3>OFFER DATE - 26th October 2021 to 10th November 2021</h3>
            <div style={styles.spacer}></div>
            <table style={styles.table}>
              <tr>
                <th style={{ ...styles.th, ...styles.underline }}>
                  NET PURCHASE
                </th>
                <th style={{ ...styles.th, ...styles.underline }}>
                  CASHBACK AMOUNT
                </th>
              </tr>
              <tr>
                <td style={styles.td}>ABOVE Rs.21000/-</td>
                <td style={styles.td}>Rs.500/-</td>
              </tr>
              <tr>
                <td style={styles.td}>ABOVE Rs.41000/-</td>
                <td style={styles.td}>Rs.1000/-</td>
              </tr>
              <tr>
                <td style={styles.td}>ABOVE Rs.95000/-</td>
                <td style={styles.td}>Rs.2000/-</td>
              </tr>
            </table>
            <div style={styles.spacer}></div>
          </div>

          {/* offer terms & condition div */}

          <div>
            <h2 style={styles.underline}>OFFER TERMS AND CONDITIONS -:</h2>
            <p>
              The cashback will be given under some specific terms and condition
              mentioned below.
            </p>
            <p>
              If the net purchase amount exceeds Rs.21000/- , between the time
              span of 26/10/21 and 10/11/21 the buyer will be given cashback of
              Rs.500/- , if the buyer exceeds net purchase amount of Rs.41000/-
              and Rs.95000/- , the buyer will get cashback of Rs.1000/- and
              2000/- respectively.
            </p>
            <p>
              The cashback will be awarded on net purchase amount. Net purchase
              amount will be calculated by the means i.e.
              <h4 style={styles.underline}>
                NET PURCHASE = (Total Purchase) - (RTO)-(RVP or any other kind
                of returns)
              </h4>
            </p>
            <p>
              Order that will be counted or calculated in the net purchase will
              be the orders placed between the time span of 26/10/21 – 10/11/21.
            </p>
            <p>
              The cashback will be given after the successful delivery of the
              last order between the offer time or after the delivery of return
              parcel back to seller if any, or after the settlement of the
              dispute if any during the offer time period.
            </p>
          </div>
          <div style={styles.spacer}></div>
          <div style={styles.spacer}></div>
          <div style={styles.spacer}></div>
        </Col>
      </Col>
    </Row>
  );
}

export default StaticOfferContent;
