import React, { useEffect } from "react";
import LayoutMain from "./layoutMain";
import Layout from "./layout";
import Footer from "./elements/footer";
import { withTranslation } from "react-i18next";

const TermsAndConditionSection = (props) => {
  const { t } = props;
  return (
    <>
      <div className={"contact_container"} style={{ minHeight: "70vh" }}>
        <>
          <div className="WordSection1">
            <div className="topBoxTerms">
              <p
                className="MsoNormal"
                align="center"
                style={{
                  marginBottom: "0in",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <b>
                  <u>
                    <span style={{ fontSize: "22.0pt" }}>
                      {t("Terms and conditions")}
                    </span>
                  </u>
                </b>
              </p>
              <br />

              <p
                className="MsoNormal"
                style={{ marginBottom: "0in", lineHeight: "normal" }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  {t("The terms of use specify how para")}
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0in", lineHeight: "normal" }}
              >
                <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
              </p>

              <p
                className="MsoListParagraph"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  1)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("The buyer associated to the platform para")}
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: ".5in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpFirst"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "77.25pt",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  A)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Order amount < Rs.10,000")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "77.25pt",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  B)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Order amount b/w Rs.10,000")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpLast"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: "77.25pt",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  C)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Order amount > Rs.20,000")}
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0in", lineHeight: "normal" }}
              >
                <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0in", lineHeight: "normal" }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  {t("The slabs mentioned above may change from  time to time")}
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0in", lineHeight: "normal" }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  Minimum delivery time: 2 days Maximum delivery time: 10 days
                </span>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0in", lineHeight: "normal" }}
              >
                <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpFirst"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  2)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("The order placed by the buyer para")}
                  &nbsp;{" "}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  3)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("If the buyer , did not accept or returns para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  4)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Seller / buyer or any other person para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  5)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("The platform para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  6)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Any products other than mentioned para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  7)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Any seller or buyer involved para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  8)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("The seller have to pack para")} &nbsp;
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                align="right"
                style={{
                  marginBottom: "0in",
                  textAlign: "right",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                align="center"
                style={{
                  marginBottom: "0in",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpLast"
                align="center"
                style={{
                  marginBottom: "0in",
                  textAlign: "center",
                  lineHeight: "normal",
                }}
              >
                <b>
                  <u>
                    <span style={{ fontSize: "22.0pt" }}>
                      {t("Product Listing Policy")}{" "}
                    </span>
                  </u>
                </b>
              </p>

              <p
                className="MsoNormal"
                style={{ marginBottom: "0in", lineHeight: "normal" }}
              >
                <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
              </p>

              <p
                className="MsoListParagraphCxSpFirst"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  A)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("The product listed by the seller para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  B)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("The product listed by seller must be genuine para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  C)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Seller can not duplicate the other brands para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  D)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Akar also prohibited some of the items para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{ marginBottom: "0in", lineHeight: "normal" }}
              >
                <span style={{ fontSize: "14.0pt" }}>&nbsp;&nbsp; </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: ".75in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  1)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Giveaways, lotteries")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: ".75in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  2)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Items that does not offer physical product para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: ".75in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  3)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Aduly/pornographic materials para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: ".75in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  4)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Items that promotes illegal activities para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: ".75in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  5)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Item that promotes hatred para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpMiddle"
                style={{
                  marginTop: "0in",
                  marginRight: "0in",
                  marginBottom: "0in",
                  marginLeft: ".75in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  6)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Stock, bonds, investments para")}
                </span>
              </p>

              <p
                className="MsoListParagraphCxSpLast"
                style={{
                  marginBottom: "0in",
                  textIndent: "-.25in",
                  lineHeight: "normal",
                }}
              >
                <span style={{ fontSize: "14.0pt" }}>
                  E)
                  <span style={{ font: '7.0pt "Times New Roman"' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                </span>
                <span style={{ fontSize: "14.0pt" }}>
                  {t("Akar reserves the right to impose para")}
                </span>
              </p>
            </div>
            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".25in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
            </p>

            <p
              className="MsoNormal"
              align="center"
              style={{
                marginBottom: "0in",
                textAlign: "center",
                lineHeight: "normal",
              }}
            >
              <b>
                <u>
                  <span style={{ fontSize: "14.0pt" }}>
                    {t("SOME OF THE SPECIFICALLY PROHIBITED")}
                  </span>
                </u>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{ marginBottom: "0in", lineHeight: "normal" }}
            >
              <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
        
      
                }}
              >
                1.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                    
                
                  }}
                >
                  {t("DRUGS AND DRUG PARAPHERNALIA")}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                  
                }}
              >
                {t(
                  "AKAR forbids any and all listing or sale of narcotics para"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                 
                }}
              >
                {t("Drug paraphernalia, including all items para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                  
                }}
              >
                {t("The listing or sale of packaging materials para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                 
                }}
              >
                {t("The listing or sale of orally administered para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "13.5pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                2.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                    
                  }}
                >
                  {t("EXPLOSIVE, HAZARDOUS CHEMICALS AND FIREARMS")}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                  
                }}
              >
                {t(
                  "The posting of explosives and related ignition and detonation equipment"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Radioactive substances, toxic and poisonous chemicals")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "The posting, offering for sale, or offering for purchase of hazardous or dangerous materials"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Ozone depleting substances are not permitted to be listed")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Listing of fireworks, firecrackers para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Any service, instruction, process para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("The posting of, offering for sale para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "Posting, offering for sale, or offering of purchase of weapons para"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("While listing of most knives and other cutting para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("AKAR maintains discretion over what items para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                3.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "13.5pt",
                    fontFamily: "Roboto",
                    color: "black",
                  }}
                >
                  {t("GOVERNMENT, LAW ENFORCEMENT AND MILITARY ISSUED ITEMS")}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Following items are not permitted to be listed")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Articles of clothing or identification para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Law enforcement badges or law enforcement para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Military decorations, medals and awards para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Police uniforms, police insignia and police vehicles")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "There are some police items that may be listed on the AKAR, provided they observe the following guidelines"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                §
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "Authorized general souvenir items that do not resemble badges, and paperweights that do not contain badges"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: "Wingdings",
                  color: "black",
                }}
              >
                §
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Badges that are clearly not genuine or official")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: "Wingdings",
                  color: "black",
                }}
              >
                §
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Historical badges that do not resemble modern para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: "Wingdings",
                  color: "black",
                }}
              >
                §
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "Any official, internal, classified or non-public documents"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: "Wingdings",
                  color: "black",
                }}
              >
                §
                <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Listing of police equipment and associated products")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "13.5pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                4.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                  }}
                >
                  {t("ADULT AND OBSCENE MATERIALS")}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("The posting or sale of pornographic materials")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Items depicting or suggestive of bestiality para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "13.5pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                5.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                  }}
                >
                  {t(
                    "CIRCUMVENTION DEVICES AND OTHER EQUIPMENT USED FOR ILLICIT PURPOSES"
                  )}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Descramblers and other items that can be used para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Devices designed to intentionally block para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("The listing or sale of spy equipment para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Bank card readers and skimmers")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Any and all unauthorized circumvention para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                6.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                   
                  }}
                >
                  {t("ILLEGAL SERVICES")}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "Listings claiming to provide government services and related products"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("The listing or sale of any form of invoices para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "AKAR prohibits listings for the sole purpose of collecting user information or raising money"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                7.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                  }}
                >
                  {t("COLLECTIONS, ARTIFACTS AND PRECIOUS METALS")}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "AKAR strictly forbids the sale and purchase of currency para"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "Counterfeits of the identified articles, legal tender and stamps"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Reproductions or replicas of coins para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t(
                  "Rough diamonds and “conflict minerals” originating from non-compliant countries"
                )}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Artefacts, cultural relics para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                8.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                  }}
                >
                  {t(
                    "HUMAN PARTS, HUMAN REMAINS AND PROTECTED FLORA AND FAUNA"
                  )}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Listing of human body parts and remains")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("The listing or sale of any animal para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                9.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                  }}
                >
                  {t(
                    "OFFENSIVE MATERIAL AND INFORMATION DETRIMENTAL TO NATIONAL SECURITY"
                  )}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Any publication or other media containing state para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Any information supporting or advocating para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Postings that are ethnically or racially offensive")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("Materials advocating, promoting para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                &nbsp;
              </span>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: ".5in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "14.0pt",
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                10.
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <b>
                <span
                  style={{
                    fontSize: "14.0pt",
                  }}
                >
                  {t("TOBACCO PRODUCTS")}
                </span>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginLeft: "1.0in",
                textIndent: "-.25in",
                lineHeight: "normal",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "10.0.0pt",
                  fontFamily: '"Courier New"',
                  color: "black",
                }}
              >
                o
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;{" "}
                </span>
              </span>
              <span
                style={{
                  fontSize: "14.0pt",
                }}
              >
                {t("The posting of tobacco products para")}
              </span>
            </p>

            <p
              className="MsoNormal"
              align="center"
              style={{
                marginBottom: "0in",
                textAlign: "center",
                lineHeight: "normal",
              }}
            >
              <b>
                <u>
                  <span style={{ fontSize: "22.0pt" }}>
                    {t("PACKING INSTRUCTIONS")}
                  </span>
                </u>
              </b>
            </p>

            <p
              className="MsoNormal"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".5in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
            </p>

            <p
              className="MsoListParagraphCxSpFirst"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                textIndent: "-.25in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt", fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{ fontSize: "14.0pt" }}>
                {t("PACKING INSTRUCTIONS para 1")}
              </span>
            </p>

            <p
              className="MsoListParagraphCxSpMiddle"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                textIndent: "-.25in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt", fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{ fontSize: "14.0pt" }}>
                {t("PACKING INSTRUCTIONS para 2")}
              </span>
            </p>

            <p
              className="MsoListParagraphCxSpMiddle"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                textIndent: "-.25in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt", fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{ fontSize: "14.0pt" }}>
                {t("PACKING INSTRUCTIONS para 3")}
              </span>
            </p>

            <p
              className="MsoListParagraphCxSpMiddle"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                textIndent: "-.25in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt", fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{ fontSize: "14.0pt" }}>
                {t("PACKING INSTRUCTIONS para 4")}
              </span>
            </p>

            <p
              className="MsoListParagraphCxSpMiddle"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                textIndent: "-.25in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt", fontFamily: "Symbol" }}>
                ·
                <span style={{ font: '7.0pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{ fontSize: "14.0pt" }}>
                {t("PACKING INSTRUCTIONS para 5")}
              </span>
            </p>

            <p
              className="MsoListParagraphCxSpMiddle"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
            </p>

            <p
              className="MsoListParagraphCxSpMiddle"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                lineHeight: "normal",
              }}
            >
              <b>
                <span style={{ fontSize: "16.0pt" }}>
                  {t("ALL THE CLAIMS ON THE COMPANY")}
                </span>
              </b>
            </p>

            <p
              className="MsoListParagraphCxSpMiddle"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                lineHeight: "normal",
              }}
            >
              <b>
                <u>
                  <span style={{ fontSize: "14.0pt" }}>
                    <span style={{ textDecoration: "none" }}>&nbsp;</span>
                  </span>
                </u>
              </b>
            </p>

            <p
              className="MsoListParagraphCxSpLast"
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "0in",
                marginLeft: ".75in",
                lineHeight: "normal",
              }}
            >
              <span style={{ fontSize: "14.0pt" }}>&nbsp;</span>
            </p>
          </div>
        </>
      </div>
    </>
  );
};
const TermsAndConditions = (props) => {
  const token = typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null;
  const { t } = props;
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (token) {
    return (
      <>
        <LayoutMain title={t("Terms and conditions")}><TermsAndConditionSection  {...props} /></LayoutMain>
      </>
    );
  }
  else {
    return (
      <>
        <Layout title={t("Terms and conditions")}><TermsAndConditionSection {...props} /></Layout>
        <Footer />
      </>
    );
  }
};

export default withTranslation()(TermsAndConditions);
