import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import LayoutComponent from '../../layoutComponents/layoutComponent';
import { Row, Tabs, Col, Input, Checkbox } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import myimage from '../../../imageNotFound.jpeg'
import BrandService from '../../../service/brandService'
import SaveListing from '../../../service/saveListingService'
import MediaUrl from '../../../service/mediaUrl';

const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;
const { Search } = Input;


class BrandComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brandList: [],
            searchBrandTerm: ''
        }
    }

    componentDidMount() {
        window.scroll(0, 0)
    }

    getSearchedBrands = (brand) => {
        BrandService.getSearchedBrand(brand)
            .then((res) => {
                this.setState({ brandList: res.data.data })
            }, err => {
                //console.log(err)
            })
    }

    createNewBrand = () => {
        let brand = { "name": this.state.searchBrandTerm }
        SaveListing.brand.brand = brand
        SaveListing.brand.isNewBrand = true
        if (typeof window !== 'undefined') { localStorage.setItem('brand', JSON.stringify(brand)) }
        this.navigate('/new-brand')
    }

    selectBrand = (brand) => {
        SaveListing.isBrandSelected = true
        SaveListing.brand.brand = brand
        SaveListing.brand.isNewBrand = false
        if (typeof window !== 'undefined') { localStorage.setItem('brand', JSON.stringify(brand)) }
        this.navigate('/brand-relation')
    }

    onSearchBrand = searchTerm => {
        this.setState({ searchBrandTerm: searchTerm })
        if (searchTerm === '') {
            this.setState({ brandList: [] })
        } else {
            this.getSearchedBrands(searchTerm)
        }
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    showAddNewButton = () => {
        const { brandList, searchBrandTerm } = this.state;
        let flag = false;
        if (brandList && brandList.length) {
            brandList.forEach((item) => {
                if (item.name.trim().toLowerCase() == searchBrandTerm.trim().toLowerCase()) {
                    flag = true;
                }
            }
            )
        }
        return flag;
    }

    render() {
        const { brandList, searchBrandTerm } = this.state;
        const { t } = this.props;

        return (
            <LayoutComponent title={t('Brand')}>
                <Row className='sidebar_navigation-items mt-3' >
                    <Col span={24}>
                        <Search
                            placeholder={t('Search brand')}
                            onChange={value => this.onSearchBrand(value.target.value)}
                            style={{
                                width: "100%", background: 'rgb(237, 237, 237)',
                                borderRadius: '2px', marginBottom: '15px'
                            }}
                        />

                        <div className='brand_list mt-0'>
                            {brandList.map((item) => {
                                return (
                                    <div key={item.id} className='brand' onClick={(e) => this.selectBrand(item)}>
                                        <img src={item.imageFileUploadedData.url ? MediaUrl.completeMediaUrl(item.imageFileUploadedData.url) : myimage} alt='banner' />
                                        <p className='label'>{item.name}</p>
                                        <div className='spacer'></div>
                                        <RightOutlined />
                                    </div>
                                )
                            })}
                        </div>

                        {
                            this.showAddNewButton() || !searchBrandTerm
                                ? null
                                : <button className='card_button' onClick={(e) => this.createNewBrand()}>
                                    {t('Add a new brand')} ({searchBrandTerm})
                                </button>
                        }
                    </Col>

                </Row>
            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(BrandComponent))