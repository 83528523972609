export const INITIAL_STATE = {
  ledger: [],
  loader: true,
  fromDate: '',
  toDate: '',
  pagination: {
    currentPage: 0,
    pageLimit: 0,
    totalCount: 0,
    totalPageCount: 0,
  },
  results: {
    totalPayable: 0.00, 
    totalPayment: 0.00, 
    totalReceivable: 0.00, 
    fromDate: '',
    toDate: '',
    orderCustomId: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  let { payload } = action;
  let { data } = payload || {};

  const getChildLedgers = (data = []) => {
    let temp = [];
    data.forEach((child) => {
      temp.push({
        id: child.id ? child.id.toString() : '',
        price: child.price,
        transactionType: child.transactionType,
        description: child.description || '',
        orderCustomId: child.orderCustomId,
        status: child.status,
        modifiedOn: child.modifiedOn,
        parentLedgerId: child.parentLedgerId,
        transactionAccountId: child.transactionAccountId
      })
    })
    return temp;
  }

  const getLedger = (data) => {
    let temp = [];
    let pageNumber = action.payload.pageNumber;
    if (pageNumber > 1) {
      temp = [...state.ledger]
    }
    data.forEach((item) => {
      temp.push({
        id: item.id ? item.id.toString() : '',
        price: item.price,
        transactionType: item.transactionType,
        description: item.description || '',
        orderCustomId: item.orderCustomId,
        status: item.status,
        modifiedOn: item.modifiedOn,
        parentLedgerId: item.parentLedgerId,
        parentLedger: item.parentLedger,
        childLedgers: getChildLedgers(item.childLedgers),
        transactionAccountId: item.transactionAccountId
      })
    });
    return temp;
  }

  const getAdditionalData = (data) => {
    let results =  { 
      totalPayable: data.totalPayable, 
      totalPayment: data.totalPayment, 
      totalReceivable: data.totalReceivable,
      fromDate: action.payload.fromDate,
      toDate: action.payload.toDate,
      orderCustomId: action.payload.lookForOrderID
    }
    let pagination = {
      currentPage: data.currentPage,
      pageLimit: data.pageLimit,
      totalCount: data.totalCount,
      totalPageCount: data.totalPageCount,
    }
    return {
      results,
      pagination
    }
  }

  switch (action.type) {
    case 'GET_LEDGER':
      if (data) {
        return {
          ...state,
          ledger: getLedger(data.results),
          ...getAdditionalData(data),
          loader: false
        };
      }
      return state;

    default:
      return state;
  }

};
