import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col, message } from 'antd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import myimage from '../../imageNotFound.jpeg'
import SaveListing from '../../service/saveListingService'
import AddListingService from '../../service/addListingService'
import MediaUrl from '../../service/mediaUrl';


class SetContentComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            colorWithSize: {},
            selectedColorWithSize: [],
            isClone: false
        }
        this.confirmSet = _.debounce(this.navigateToCreateSetPage, 1000, true)
    }

    componentDidMount() {
        let productSet = SaveListing.productSetObj
        if (SaveListing.isClone) {
            this.setState({ isClone: true })
        }
        // this.setState({ colorWithSize: productSet.colorWithSize })
        let colorWithSize = productSet.colorWithSize
        let selectedColorWithSize = []
        if (colorWithSize) {
            colorWithSize.parentAttribute.mapAttributes.forEach(clr => {
                if (clr.selected) {
                    selectedColorWithSize.push(clr)
                }
            });
        }

        this.setState({ colorWithSize: productSet.colorWithSize, selectedColorWithSize: selectedColorWithSize })


        if (SaveListing.mapCategoryId === "") {
            window.onbeforeunload = function (evt) {
                var message = 'Are you sure you want to leave?';
                if (typeof evt == 'undefined') {
                    evt = window.event;
                }
                if (evt) {
                    evt.returnValue = message;
                }
                return message;
            }
            let productId = typeof window !== 'undefined' ? localStorage.getItem('id') : null
            if (productId) {
                productId = parseInt(productId)
                if (typeof window !== 'undefined') localStorage.removeItem('id')
                this.props.history.push({ pathname: `/create-list/${productId}` })

            } else {
                this.props.history.push({ pathname: `/category` })
            }
        }
    }

    navigateToCreateSetPage = () => {
        let productSet = SaveListing.productSetObj
        const { t } = this.props;
        let isColorQtyZero = false
        productSet.colorWithSize.parentAttribute.mapAttributes.forEach(clr => {
            if (clr.selected) {
                let totalQty = 0
                clr.sizes.mapAttributes.forEach(element => {
                    if (element.selected) {
                        totalQty = totalQty + Number(element.quantity)
                    }
                });
                if (totalQty === 0) {
                    isColorQtyZero = true
                    return
                }
            }
        });

        if (isColorQtyZero) {
            message.error(`${t('Set should have at least 1 quantity')}`)
            return
        }

        SaveListing.isSetCreated = true
        productSet.colorWithSize = this.state.colorWithSize

        if (SaveListing.isNewSet) {
            SaveListing.mapProductSets.push(productSet)
        } else {
            productSet['id'] = SaveListing.mapProductSets[SaveListing.selectProductSetIndex].id
            SaveListing.mapProductSets[SaveListing.selectProductSetIndex] = productSet
        }

        if (SaveListing.productID && !this.state.isClone) {
            let productId = SaveListing.productID
            let selectProductSetID = SaveListing.selectProductSetID
            let item = productSet

            let obj = {}
            if (item.minimumSetOrder) {
                obj['minimumSetOrder'] = Number(item.minimumSetOrder)
            }
            if (item.pricePerPiece) {
                // let price = item.pricePerPiece
                obj['pricePerPiece'] = Number(item.pricePerPiece)
            }
            if (item.mrpPerPiece) {
                //  let price = item.mrpPerPiece
                obj['mrpPerPiece'] = Number(item.mrpPerPiece)
            }
            if (item.availableQuantity) {
                obj['availableQuantity'] = Number(item.availableQuantity)
            }
            if (item.sku) {
                obj['sku'] = item.sku
            }
            if (item.PricePerPieceBeforeDiscount) {
                obj['PricePerPieceBeforeDiscount'] = Number(item.PricePerPieceBeforeDiscount)

            }

            let mapProductSetBulkPrices = []
            item.mapProductSetBulkPrices.forEach(bulkSet => {
                if (bulkSet.set !== null && bulkSet.price !== null) {
                    let price = bulkSet.price
                    mapProductSetBulkPrices.push({
                        'minimumOrderQuantity': Number(bulkSet.set),
                        'pricePerPiece': Number(price)
                    })
                }
            })

            if (mapProductSetBulkPrices.length > 0) {
                obj['mapProductSetBulkPrices'] = mapProductSetBulkPrices
            }

            let mapProductSetAttributes = []
            let mySelectedSize = []
            item.colorWithSize.parentAttribute.mapAttributes.forEach(clr => {
                if (clr.selected) {
                    let mapProductSetAttributeDetails = []
                    mySelectedSize = clr.sizes
                    clr.sizes.mapAttributes.forEach(element => {
                        if (element.selected) {
                            mapProductSetAttributeDetails.push({
                                mapAttributeId: element.id,
                                fileUploadedData: null,
                                quantity: Number(element.quantity)
                            })
                        }
                    })
                    if (clr.images.length) {
                        clr.images.forEach(element => {
                            mapProductSetAttributeDetails.push({
                                mapAttributeId: null,
                                fileUploadedData: {
                                    name: element.name ? element.name : element.fileName,
                                    url: MediaUrl.trimMediaUrl(element.url)
                                },
                                quantity: 0
                            })
                        })
                    }
                    mapProductSetAttributes.push({
                        mapAttributeId: clr.id,
                        mapProductSetAttributeDetails: mapProductSetAttributeDetails
                    })
                }
            })

            if (item.selectedModels && item.selectedModels.length) {
                item.selectedModels.forEach(model => {
                    if (model.selected) {
                        model.parentAttribute.mapAttributes.forEach(mAttr => {
                            if (mAttr.selected) {
                                mapProductSetAttributes.push({
                                    mapAttributeId: mAttr.id,
                                    mapProductSetAttributeDetails: []
                                })
                            }
                        })
                    }
                })
            }

            mySelectedSize.mapAttributes.forEach(size => {
                if (size.selected) {
                    mapProductSetAttributes.push({
                        mapAttributeId: size.id,
                        mapProductSetAttributeDetails: null
                    })
                }
            })

            obj['mapProductSetAttributes'] = mapProductSetAttributes

            obj["addedFileUploadedDatas"] = SaveListing.addedFileUploadedDatas

            let url = '/create-list/' + productId

            if (selectProductSetID) {
                AddListingService.updateProductSet(productId, selectProductSetID, obj)
                    .then((res) => {
                        SaveListing.needToProductCall = true
                        message.success('Product Set Updated successfully');
                        this.props.history.push({ pathname: url })
                    },
                        err => {
                            this.props.history.push({ pathname: url })
                            message.error(err.data.message);

                        })

            } else {

                AddListingService.createProductSet(productId, obj)
                    .then((res) => {
                        productSet['id'] = res.data.data.id
                        SaveListing.mapProductSets.push(productSet)
                        if (typeof window !== 'undefined') { localStorage.removeItem('currentCopySet') }
                        message.success('Product Set Created successfully');
                        if (productId) {
                            SaveListing.needToProductCall = true
                        }

                        this.props.history.push({ pathname: url })

                    },
                        err => {
                            message.error(err.data.message);
                            this.props.history.push({ pathname: url })
                        })
            }

        } else {
            let url = '/create-list'
            this.props.history.push({ pathname: url })
        }
    }

    decreaseQty = (colorIndex, sizeIndex) => {
        let colorWithSize = this.state.colorWithSize
        // let temp = { ...colorWithSize }
        if (colorWithSize.parentAttribute.mapAttributes[colorIndex].sizes.mapAttributes[sizeIndex].quantity > 0) {
            let mySize = JSON.parse(JSON.stringify(colorWithSize.parentAttribute.mapAttributes[colorIndex].sizes));
            mySize.mapAttributes[sizeIndex].quantity -= 1
            colorWithSize.parentAttribute.mapAttributes[colorIndex].sizes = mySize
            // temp.parentAttribute.mapAttributes[colorIndex].sizes.mapAttributes[sizeIndex].quantity = (temp.parentAttribute.mapAttributes[colorIndex].sizes.mapAttributes[sizeIndex].quantity - 1)
            this.setState({ colorWithSize: colorWithSize })
        }
    }

    increaseQty = (item, colorIndex, sizeIndex) => {
        let colorWithSize = this.state.colorWithSize
        let mySize = JSON.parse(JSON.stringify(colorWithSize.parentAttribute.mapAttributes[colorIndex].sizes));
        mySize.mapAttributes[sizeIndex].quantity += 1
        colorWithSize.parentAttribute.mapAttributes[colorIndex].sizes = mySize
        this.setState({ colorWithSize: colorWithSize })
    }

    render() {
        const { colorWithSize } = this.state
        const { t } = this.props

        return (
            <LayoutComponent title={t('Set contents')}>
                <Row className='divWithFixButton'>

                    <Col span={24} className='bottom-border toolbar create-listing-bar '>
                        <div>
                            {t('Edit the quantities')}
                        </div>
                    </Col>

                    {colorWithSize.parentAttribute && colorWithSize.parentAttribute.mapAttributes.map((item, i) => {
                        return (
                            <Col span={24} key={i}>{
                                item.selected ?
                                    <div className='quantuty_content'>
                                        <div className='quantity_box'>
                                            <div className='quantity_header'>
                                                <img src={item.images.length ? MediaUrl.completeMediaUrl(item.images[0].url) : myimage}
                                                    alt='banner' />
                                                {item.childAttribute.value === 'Multicolor' ? <div className='color' style={{
                                                    backgroundSize: 'contain',
                                                    backgroundImage: 'url(https://akar-prod-media.s3.ap-south-1.amazonaws.com/StaticAssets/color-circle.png)',
                                                }}></div>
                                                    : <div className='color' style={{ backgroundColor: item.childAttribute.value }}></div>
                                                }
                                                {/* <div className='color' style={{ backgroundColor: item.childAttribute.value }}></div> */}
                                                <h4>{item.childAttribute.key}</h4>

                                            </div>
                                            {item.sizes && item.sizes.mapAttributes && item.sizes.mapAttributes.map((size, sizeIndex) => {
                                                return (
                                                    <div key={`size${i}${sizeIndex}`}>{
                                                        size.selected ?
                                                            <div className='counter_div'>
                                                                <p>
                                                                    {size.childAttribute.value}
                                                                </p>
                                                                <div className="spacer" />

                                                                <div className='counter'>
                                                                    <div>
                                                                        <button onClick={(e) => this.decreaseQty(i, sizeIndex)}> - </button>
                                                                    </div>
                                                                    <div> {size.quantity} </div>
                                                                    <div>
                                                                        <button onClick={(e) => this.increaseQty(item, i, sizeIndex)}> + </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                    }
                                                    </div>

                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ''
                            }
                            </Col>

                        )
                    })
                    }



                </Row>
                <Row>
                    <Col span={24} className='stickToBottom'>
                        <div className='button_div'>
                            <button onClick={this.confirmSet}>
                                {t('Confirm')}
                            </button>
                        </div>
                    </Col>
                </Row>
            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(SetContentComponent));