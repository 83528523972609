import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LayoutComponent from "../../layoutComponents/layoutComponent";
import { Row, Col, message } from "antd";
import moment from "moment";
import { withTranslation } from "react-i18next";
import {
  CloseCircleFilled,
  FileAddOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import SaveListing from "../../../service/saveListingService";
import BrandService from "../../../service/brandService";
import MediaUrl from "../../../service/mediaUrl";
// import { Prev } from "react-bootstrap/esm/PageItem";
import { PDFDocument } from "pdf-lib";
// var PDFDocument = window.PDFLib.PDFDocument;
const LetterheadUrl = process.env.REACT_APP_IMAGE_BASE_URL;

class LetterheadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageFiles: [],
            fileList: []
        }
    }

    async onChange(e) {
        const { t } = this.props;
        const fileList = this.state.fileList
        if(fileList.length > 0){
          message.warning(`${t("Cannot upload more than one file")}`)
        }
        else{
          let selectedFile = e.target.files[0];
          const fileExtn = selectedFile.name.substr(
            selectedFile.name.lastIndexOf(".")
          );
    
          if (fileExtn === ".pdf" && selectedFile) {
            try{
              let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
              selectedFile = new File([blob], moment().valueOf() + fileExtn, {
                type: selectedFile.type,
              });
              var url = URL.createObjectURL(selectedFile);
    
              const pdfBytes = await fetch(url).then((res) => res.arrayBuffer());
              const pdfDoc = await PDFDocument.load(pdfBytes, {ignoreEncryption: true} );
              const isEncrypted = pdfDoc.isEncrypted;
    
              if(isEncrypted){
                message.error(`${t("Password protected documents cannot be uploaded")}`)
              }
              else{
                this.addImageInAWS(selectedFile);
                fileList.push(url);
                this.setState(fileList);
              }
            }
            catch(error){
              console.log(error)
            }
          } else {
            return message.error(`${t("Attach file in pdf format")}`);
          }
          document.getElementById("myInput").value = null;
        }
    }

    addImageInAWS = (data) => {
        BrandService.addImage(data, 'BRAND_DOCUMENT_FILE')
            .then((res) => {
                let imageFiles = this.state.imageFiles
                imageFiles.push(res.data.data[0])
                this.setState(imageFiles)
                //console.log(res)
            }, err => {
                //console.log(err)
            })
    }

    deleteImage = (file, index) => {
        var images = [...this.state.imageFiles];
        this.deleteImageFromAWS(images[index].url)
        images.splice(index, 1);
        this.setState({ imageFiles: images });

        var array = [...this.state.fileList];
        array.splice(index, 1);
        this.setState({ fileList: array });
    }

    deleteImageFromAWS = (data) => {
        BrandService.deleteImage(data)
            .then((res) => {
                //console.log(res)
            }, err => {
                //console.log(err)
            })
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }
    onPdfLoad = (e) =>{
        console.log("onPdfLoad(e) == ", e.target)
    }
    submitBrandVarification = () => {
        SaveListing.brand.mapBrandAccountFileUploadedDatas = this.state.imageFiles
        this.navigate('/terms-condition-varification')
    }

    render() {
        const { fileList } = this.state;
        const { t } = this.props;

        return (
            <LayoutComponent title={t('Brand')}>
                <Row className=' sidebar_navigation-items mt-4'>
                    <Col span={24}>

                        <div className='brand_list mt-0 border'>
                            <div className=''>
                                <div className='step'>
                                    <div>
                                        <h4>{t('Step')} 1: </h4>
                                        <a style={{ cursor: 'pointer' }} target="_blank" href={LetterheadUrl + "StaticAssets/Self-Declartion-Brand-Owner.pdf"}>
                                            <u>{t('Download the Format')}</u></a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='brand_list mt-2 border'>
                            <div className=''>
                                <div className='step'>
                                    <div>
                                        <h4>{t('Step')} 2: </h4>
                                        <p>{t('Declaration printed on letterhead')}</p>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='brand_list mt-2 border'>
                            <div className=''>
                                <div className='step'>
                                    <div>
                                        <h4>{t('Step')} 3:</h4>
                                        <p>{t('Upload signed and stamped Letterhead')}</p>
                                    </div>
                                    <div className='assets'>
                                        <input id="myInput" type="file" ref={(ref) => this.myInput = ref}
                                            onChange={this.onChange.bind(this)}
                                            accept=".pdf"
                                            style={{ display: 'none' }} 
                                            onLoad ={(e)=> this.onPdfLoad(e)}/>
                                        <button className="float-right mr-3 file" onClick={() => {
                                            this.myInput.click()
                                        }}><FileAddOutlined /></button>

                                        {fileList.map((item, i) => {
                                            return (
                                                <div key={i} className='file'>
                                                    <a className="fileDoc"
                                                        href={`${item}`}
                                                        target="_blank"
                                                        onLoad ={(e)=> this.onPdfLoad(e)}
                                                        ><FilePdfOutlined /></a>
                                                        
                                                        <span
                                                            className="remove-btn"
                                                            style={{ position: 'absolute', top: '-15%', right: '-13%', cursor: 'pointer' }}
                                                            onClick={() => this.deleteImage(item, i)}
                                                        >
                                                        <CloseCircleFilled />
                                                        </span>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>


                </Row>
                <Row>
                    {(fileList.length !== 0) &&
                        <Col span={24} className='stickToBottom'>
                            <div className='button_div'>
                                <button onClick={(e) => this.submitBrandVarification()}>
                                    {t('Continue')}
                                </button>
                            </div>
                        </Col>
                    }
                </Row>
            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(LetterheadComponent))