import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../../layoutComponents/layoutComponent';
import { Row, Col, message } from 'antd';
import { withTranslation } from 'react-i18next';
import {
  CloseCircleFilled,
  FileAddOutlined,
  FilePdfOutlined,
  FileWordOutlined,
} from "@ant-design/icons";
import moment from "moment";
import BrandService from "../../../service/brandService";
import SaveListing from "../../../service/saveListingService";
import MediaUrl from "../../../service/mediaUrl";
import { PDFDocument } from "pdf-lib";
// var PDFDocument = window.PDFLib.PDFDocument;

class BrandDocsVarification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      brand: {},
    };
  }

  componentDidMount() {
    let brand =
      typeof window !== "undefined" ? localStorage.getItem("brand") : null;
    if (brand) {
      this.setState({ brand: JSON.parse(brand) });
    }
  }
  isPdfPasswordProtected(file) {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        const pdfBytes = new Uint8Array(event.target.result);
        try {
          const pdfDoc = await PDFDocument.load(pdfBytes, { password: "" });
          resolve(false);
        } catch (error) {
          if (
            error instanceof Error &&
            error.message ===
              "Input document to `PDFDocument.load` is encrypted. You can use `PDFDocument.load(..., { ignoreEncryption: true })` if you wish to load the document anyways."
          ) {
            resolve(true);
          } else {
            reject(error);
          }
        }
      };
    })
}

  onChange(e) {
    const { t } = this.props;
    let selectedFile = e.target.files[0];
    const fileExtn = selectedFile.name.substr(
      selectedFile.name.lastIndexOf(".")
    );

    if (
      (fileExtn === ".jpg" || fileExtn === ".jpeg" || fileExtn === ".png") &&
      selectedFile
    ) {
      let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
      selectedFile = new File([blob], moment().valueOf() + fileExtn, {
        type: selectedFile.type,
      });
      this.addImageInAWS(selectedFile);
    }
     else if (fileExtn === ".pdf" && selectedFile) {
      let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
      selectedFile = new File([blob], moment().valueOf() + fileExtn, {
        type: selectedFile.type,
      });
      this.isPdfPasswordProtected(selectedFile)
        .then((isProtected) => {
          if (isProtected) {
            message.error(`${t("Password protected documents cannot be uploaded")}`)
          } else {
            this.addImageInAWS(selectedFile);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
     else {
      return message.error(`${t("Attach file in png/jpg/jpeg/pdf format")}`);
    }
    document.getElementById('myInput').value = null
  }

    componentDidMount() {
        let brand = typeof window !== 'undefined' ? localStorage.getItem('brand') : null
        if (brand) {
            this.setState({ brand: JSON.parse(brand) })
        }
    }

    addImageInAWS = (data) => {
        BrandService.addImage(data, 'BRAND_DOCUMENT_FILE')
            .then((res) => {
                let docFiles = this.state.fileList
                docFiles.push(res.data.data[0])
                this.setState({ fileList: docFiles })
            }, err => { })
    }

    deleteImage = (file, index) => {
        var images = [...this.state.fileList];
        this.deleteImageFromAWS(images[index].url)
        images.splice(index, 1);
        this.setState({ fileList: images });
    }

    deleteImageFromAWS = (data) => {
        BrandService.deleteImage(data)
            .then((res) => { }, err => { })
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    submitBrandVarification = () => {
        const { t } = this.props
        if (this.state.fileList.length > 5) {
            return message.error(`${t('Cannot upload more than 5 documents')}`)
        } else {
            SaveListing.brand.mapBrandAccountFileUploadedDatas = this.state.fileList
            this.navigate('/brand-terms')
        }
    }

    render() {
        const { fileList } = this.state;
        const { t } = this.props;

        return (
            <LayoutComponent title={t('Brand')}>
                <Row className='sidebar_navigation-items mt-3'>
                    <Col span={24}>

                        <div className='brand_list mt-0 border'>
                            <div className=''>
                                <div className='step'>
                                    <div>
                                        {(SaveListing.brand.brandDocTYpe === 'AuthorizationCertificateBrandAssociation') &&
                                            <>
                                                <h4>{t('Brand authorization certificate')}</h4>
                                                <p>
                                                    {t('An Authorization Certificate issued by the Brand')}
                                                </p>
                                            </>
                                        }
                                        {(SaveListing.brand.brandDocTYpe === 'TradeMarkCertificateBrandAssociation') &&
                                            <>
                                                <h4>{t('Trademark certificate')}</h4>
                                                <p>
                                                    {t('Registered Trademark Certificate')}
                                                </p>
                                            </>
                                        }
                                        {(SaveListing.brand.brandDocTYpe === 'TradeMarkApplicationBrandAssociation') &&
                                            <>
                                                <h4>{t('Trademark application')}</h4>
                                                <p>{t('Application for registration of a Trademark')}</p>
                                            </>
                                        }
                                    </div>

                                    <div className='assets'>

                                        <input id="myInput" type="file"
                                            ref={(ref) => this.myInput = ref}
                                            onChange={this.onChange.bind(this)}
                                            accept=".jpeg,.jpg,.png,.pdf"
                                            style={{ display: 'none' }} />
                                        <button className="float-right mr-3 file" onClick={() => {
                                            this.myInput.click()
                                        }}><FileAddOutlined /></button>

                                        {fileList.map((item, i) => {
                                          let pdfCondition = item.url.includes(".pdf");
                                          let docCondition = item.url.includes(".doc");

                                          if (pdfCondition || docCondition) {
                                              let url = MediaUrl.completeMediaUrl(item.url, false, 100, 100)
                                              return (<div key={i} className='file'>
                                                  <a className="fileDoc" href={`${url}`} target="_blank">{pdfCondition ? <FilePdfOutlined /> : <FileWordOutlined />}</a>
                                                    <span
                                                      className="remove-pdf"
                                                      // style={{ position: 'absolute', top: '-15%', right: '-13%', cursor: 'pointer' }}
                                                      onClick={() => this.deleteImage(item.url, i)}
                                                    >
                                                      <CloseCircleFilled />
                                                    </span>
                                              </div>)

                                          } else {
                                              let url = MediaUrl.completeMediaUrl(item.url, true, 100, 100)
                                              return (
                                                  <div key={i} className='file'>
                                                      <a href={url} target="_blank">
                                                      <img src={url} alt='Something wrong' /></a>
                                                        <span className="remove-pdf"
                                                            onClick={() => this.deleteImage(item, i)}>
                                                            <CloseCircleFilled />
                                                        </span>
                                                  </div>
                                              )
                                          }

                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>


                </Row>
                <Row>
                    {(fileList.length !== 0) &&
                        <Col span={24} className='stickToBottom'>
                            <div className='button_div'>
                                <button onClick={(e) => this.submitBrandVarification()}>
                                    {t('Continue')}
                                </button>
                            </div>
                        </Col>

                    }
                </Row>
            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(BrandDocsVarification));