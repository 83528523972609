import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Result } from 'antd';
import queryString from 'query-string';
import {withTranslation} from 'react-i18next';

class OrderSuccessComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { orderId: '', mode: '' };
    }


    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    componentDidMount() {
        const { history,t } = this.props;
        let orderId = this.props.match.params.orderId
        const values = queryString.parse(this.props.location.search)

        // window.addEventListener('popstate', () => {
        //     history.push(null, null, null);
        // })
        this.setState({ orderId, mode: values.mode })
    }

    // componentWillUnmount = () => {
    //     window.removeEventListener('popstate')
    // }

    render() {
        const {t } = this.props
        const { orderId, mode } = this.state
        return (<>
            <div className="paymentRespDiv">
                {orderId !== null && mode === 'COD' ?
                    <Result
                        status="success"
                        title= {t("Order Placed Successfully!")}
                        subTitle={`${t('Order Id')}: ${orderId}`}
                        extra={[
                            <Button type="primary" key="home" onClick={(e) => this.navigate('/')}>
                                {t("Go To Home")}
                            </Button>,
                            <Button key="your-order" onClick={(e) => this.navigate(`/order-details/${orderId}`)}>{t('Track this Order')}</Button>,
                        ]}
                    />
                    : <Result
                        status="success"
                        title="Payment Successfully Done!"
                        subTitle={`${t('Order Id')}: ${orderId}`}
                        //  subTitle={`Order number: ${orderSuccess.razorPayOrderId}`}
                        extra={[
                            <Button type="primary" key="home" onClick={(e) => this.navigate('/')}>
                                {t("Go To Home")}
                            </Button>,
                            <Button key="your-order" onClick={(e) => this.navigate(`/order-details/${orderId}`)}>Track this Order</Button>,
                        ]}
                    />}
            </div>
        </>
        );
    }
}



export default withTranslation()(withRouter(OrderSuccessComponent)); 
