import React, { useEffect } from "react";
import LayoutMain from "./layoutMain";
import Layout from "./layout";
import Footer from "./elements/footer";
import { withTranslation } from "react-i18next";

const ContactUsSection = (props) => {
  const { t } = props;
  const openWhatsapp = () => {
    window.open("https://api.whatsapp.com/send?phone=+919910345267");
  };

  const openEmail = () => {
    window.open("mailto:akarindiab2b@gmail.com");
  };
  return (
    <div className={"contact_container"} style={{ minHeight: "70vh" }}>
      <div>
        <p
          className="MsoNormal mt-4"
          style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
          <span style={{ fontSize: "14.0pt" }}>
            <span>{t("In case you have problem")}</span>
            <span
              onClick={openEmail}
              style={{ fontWeight: "700", cursor: "pointer" }}
            >
              akarindiab2b@gmail.com
            </span>
            <span>{t("with details of your order")}</span>
            <span
              onClick={openWhatsapp}
              style={{ fontWeight: "700", cursor: "pointer" }}
            >
              {" "}
              9910345267
            </span>
            .<span>{t("Also you can react out to us on our whatsapp")}</span>
            <span>
              {t("Our office timings are monday to friday 10 am to 6 pm")}
            </span>
          </span>
        </p>
        <p
          className="MsoNormal mt-4"
          style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
          <span style={{ fontSize: "14.0pt" }}>{t("If you are a seller")}</span>
        </p>

        <p
          className="MsoNormal mt-4"
          style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
          <span style={{ fontSize: "14.0pt" }}> Address : 712, Tower C, ithum, Nodia Sector 62- 201309</span>
        </p>
      </div>
    </div>
  );
};

const ContactUs = (props) => {
  const { t } = props;
  const token = typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  if (token) {
    return (
      <>
        <LayoutMain title={t("Contact Us")}><ContactUsSection  {...props} /></LayoutMain>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Layout title={t("Contact Us")}><ContactUsSection {...props} /></Layout>
        <Footer />
      </>
    );
  }
};

export default withTranslation()(ContactUs);
