import { combineReducers } from 'redux';
import counter from './counter';
import global from './global';
import theme from './theme';
import chat from './chat';
import ledger from './ledger';


const reducers = combineReducers({
  counter,
  global,
  theme,
  chat, 
  ledger
})

export default reducers;