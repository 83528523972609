
import React from 'react'
import LayoutComponent from '../layoutComponents/layoutComponent'



const OrderListingHeader = (props) => {



    return (
        <>
            <LayoutComponent title='Your Listings'>
                <header className="toolbar order-tab-bar">
                    <nav className="toolbar__navigation">

                        <div className="toolbar_navigation-items theme-color">
                            <ul className='theme-color '>
                                <li>
                                    <p className='selected_listing'>Active</p>
                                    {/* <a className='theme-color'>
                                        Active
                                    </a> */}
                                </li>

                                {/* <li>
                                    <a>
                                        Expiring Soon
                                    </a>
                                </li> */}
                                <li>
                                    {/* <p className='theme-color'>Inactive</p> */}

                                    {/* <a className='theme-color'>
                                        Inactive
                                </a> */}
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                {props.children}
            </LayoutComponent>
        </>
    )
}


export default (OrderListingHeader)

