import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Input } from "antd";
import _ from "lodash";
import LayoutComponent from "../layoutComponents/layoutComponent";
import SearchService from "../../service/searchService";
import Footer from "../footer";
import "./index.scss";
import { ConsoleSqlOutlined, SearchOutlined } from "@ant-design/icons";
import { withTranslation } from "react-i18next";

class AutoCompleteSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryResults: [],
      options: [],
      searchString: "",
    };

    this.onSearch = _.debounce(this.onSearchResults, 1000, true);
  }

  onSearchResults = (searchString) => {
    // debugger
    this.setState({ searchString });
    const rootCategory = sessionStorage.getItem("rootCategory");
    let query = SearchService.createMultipleQuery(searchString, rootCategory);
    let dump = [];
    SearchService.postAlgoliaMultipleQueries(query).then((res) => {
      res.forEach((item) => {
        if (item["hits"].length) {
          item["hits"].forEach((hit) => {
            hit["index"] = item.index;
          });
          dump = dump.concat(item["hits"]);
        }
      });
      this.setState({ options: dump });
    });
  };

  onSelect = (item) => {
    // debugger
    if (item.index && item.index.includes("categories")) {
      this.redirectTocategoryPage(item)
    } else if (item.index && item.index.includes("seller")) {
      this.redirectToViewProfile(item)
    } else if (item.index && item.index.includes("brand")) {
      let brandName = item["name"];
      let payload = { filters: `( brandName : "${brandName}")` }
      this.redirectToSearchListing(item["name"], payload)
    } else if (item.index && item.index.includes("product")) {
      let payload = { query: this.state.searchString };
      this.redirectToSearchListing(item["name"], payload)
    }
  };
  redirectToViewProfile = (data) => {
    this.props.history.push({
      pathname: `/view-profile/${data['id']}`
    });
  }
  redirectToSearchListing = (query, payload) => {
    // debugger
    let encoded = Buffer.from(JSON.stringify(payload), 'ascii').toString('base64');
    let title = encodeURIComponent(query)
    this.props.history.push({ pathname: `/search/listings/title=${title}/${encoded}` })
  }
  redirectTocategoryPage = (data) => {
    let title = data.name
    this.props.history.push({
      pathname: `/subcategory/${data.id}`,
      search: `?title=${title}`,
    });
  }

  render() {
    const { options } = this.state;
    const { t } = this.props;
    return (
      <>
        <LayoutComponent title={t("Search")}>
          <div style={{ minHeight: `calc(100vh - 55px)` }}>
            <Input
              ref={(input) => input && input.focus()}
              style={{ paddingTop: "12px", paddingBottom: "12px" }}
              prefix={
                <SearchOutlined className="font16px lineHeight0 searchIconColor mr-1" />
              }
              onChange={(value) => {
                this.onSearch(value.target.value);
              }}
              placeholder={t("Start typing")}
            />

            {options.length ? (
              <div>
                <div className={"background_div"}></div>
                <ul className="pl-0">
                  {this.state.searchString ? (
                    <li className="tab_heading searchResultLI" onClick={() => {
                      let payload = { query: this.state.searchString };
                      this.redirectToSearchListing(this.state.searchString, payload)
                    }}>
                      <SearchOutlined className="font12px searchIconColor" />
                      <div className="mysearchreuslt ml-3">
                        <b>{this.state.searchString}</b>
                      </div>
                    </li>
                  ) : null}
                  {options.length > 0 && options.map((item, skey) => {
                    return (
                      <li
                        key={skey}
                        className=" searchResultLI"
                        onClick={(e) => this.onSelect(item)}
                      >
                        <div className="tab_heading">
                          <SearchOutlined className="font12px searchIconColor" />
                          <div
                            className="mysearchreuslt ml-3"
                            dangerouslySetInnerHTML={{
                              __html: `${item._highlightResult ? item._highlightResult.name.value:""}`,
                            }}
                          >
                          </div>
                        </div>
                        <p className="sub-info">{item.index.includes("categories") ? 'category' :
                          item.index.includes("seller") ? 'seller' :
                          item.index.includes("brand") ? 'brand' :
                          item.index.includes("product") ? 'product' : ''}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        </LayoutComponent>
        <Footer />
      </>
    );
  }
}

export default withTranslation()(withRouter(AutoCompleteSearch));
