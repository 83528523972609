import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent'
import Footer from '../footer';

const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

class TermsOfUse extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() { }

    render() {
        return (<><LayoutComponent title={'Terms and Conditions'}>
            <div className={'contact_container'}>
                <div>
                    <h2>Terms and Conditions</h2>
                    <a href={websiteUrl} TARGET="_blank">Tap to know </a>
                </div>
            </div>
        </LayoutComponent>
            <Footer />

        </>)
    }
}


export default withRouter(TermsOfUse);

