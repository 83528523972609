import React, { Component } from "react";
import { Row, Col } from "antd";
import akar_icon from "../../../assests/akar_White.png";
import youtube from "../../../assests/youtube.png";
import { withRouter } from 'react-router-dom';
import facebook from "../../../assests/facebook.png";
import linkedin from "../../../assests/linkedin.png";
import twitter from "../../../assests/twitter.png";
import "./index.scss";

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.token = typeof window !== 'undefined' ? localStorage.getItem('Authorization') : null;
  }
  render() {
    return (
      <div className="footer-container">
        <Row className="footer-row">
          <Col className="gutter-col" xs={24} sm={8}>
            <div className="akar-icon-container">
              <img src={akar_icon} alt="icon" className="mb-3" />
            </div>
            <div className="akar-icon-text-container">
              <p>
                AKAR India provides platform to make your business an online e
                commerce business. You can sell anywhere throughout the country
                through our online ecommerce website.{" "}
              </p>
            </div>
            <div className="social-icons-container ">
              <img src={facebook} alt="" className="social-icon" />
              <img src={twitter} alt="" className="social-icon" />
              <img src={linkedin} alt="" className="social-icon" />
              <img src={youtube} alt="" className="social-icon" />
            </div>
          </Col>
          <Col className="" xs={24} sm={{ span: 14, offset: 2 }}>
            <Row>
              <Col span={8} className="footer-links-container-col">
                <div className="footer-links-container">
                  <h3>Information</h3>
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: "/about-us",
                      search: ``,
                    });
                  }}>About Us</p>
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: "/terms-and-conditions",
                      search: ``,
                    });
                  }}>Terms & Condition</p>
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: "/refund-cancellation-policy",
                      search: ``,
                    });
                  }}>Refund & Cancellation Policy</p>
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: "/payment-cycle",
                      search: ``,
                    });
                  }}>Payment Cycle</p>
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: "/FAQ",
                      search: ``,
                    });
                  }}>FAQ's</p>
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: '/terms-and-conditions'
                    })
                  }}>For Sellers</p>
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: '/terms-and-conditions'
                    })
                  }}>For Buyers</p>
                </div>
              </Col>
              <Col span={8} className="footer-links-container-col">
                <div className="footer-links-container">
                  <h3>Buyer Tool Kit</h3>
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: '/login'
                    })
                  }}>Latest Products</p>
                  {/* <p>Learning Center</p> */}
                </div>
              </Col>
              <Col span={8} className="footer-links-container-col">
                <div className="footer-links-container">
                  <h3>Supplier Tool Kit</h3>
                  <p onClick={() => {
                    if (this.token) {
                      this.props.history.push({
                        pathname: '/apply-to-sell'
                      })
                    } else {
                      this.props.history.push({
                        pathname: '/login',
                        search: `?redirect=apply-to-sell`
                      })
                    }
                  }}>Sell on Akar</p>
                  {/* <p  onClick={()=>{
                    this.props.history.push({
                      pathname:'/login'
                    })
                }}>Latest Buy Lead</p> */}
                  <p onClick={() => {
                    this.props.history.push({
                      pathname: '/login'
                    })
                  }}>Learning Center</p>
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col className="" xs={24} sm={8}>
            <Row>

            </Row>
          </Col> */}
          <Row className="footer-base-row">
            <Col
              className="mt-3 d-flex justify-content-between align-items-center copyright-text-container"
              xs={24}
              sm={12}
            >
              <a className="copyright-text">
                Copyright @ akarindia 2022 Ltd. All rights reserved
              </a>
            </Col>
            <Col
              className="mt-3 align-items-center terms-privacy-container"
              xs={24}
              sm={12}
            >
              <a className="terms">
                <span onClick={() => {
                  this.props.history.push({
                    pathname: '/terms-and-conditions'
                  })
                }}>Terms of Use</span>
                {" - "}
                <span onClick={() => {
                  this.props.history.push({
                    pathname: '/privacy-policy'
                  })
                }}>Privacy | Policy</span>
              </a>
            </Col>
          </Row>
        </Row>
      </div>
    );
  }
}

export default withRouter(Footer);
