import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MediaUrl from "../../service/mediaUrl";
import SectionLoader from "./sectionLoader";
import { withTranslation } from "react-i18next";
import SearchService from "../../service/searchService";
import Api from "../../service/network";
import './index.scss'

class FilterTabView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardData: [],
            cardPostData: [],
            payLoad: {},
        };
    }

    componentDidMount() {
        const { itemData } = this.props;

        if (itemData.callType === "Get") {
            let path = `${itemData.dataUrl}${itemData.queryParam}`
            Api.makeGetRequest(path, null, null, null).then((res) => {
                let output = res.data.data.data;
                output.forEach((img) => {
                    if (img.imageUrl) {
                        let url = MediaUrl.completeMediaUrl(img.imageUrl, true, 100, 100);
                        img.imageUrl = url;
                    }
                });
                this.setState({ cardData: output });
            });
        }
        if (itemData.callType === "Post") {
            let payLoad = itemData.payLoad;
            let filters;
            try {
                if (typeof payLoad === "string") {
                    filters = JSON.parse(payLoad);
                } else {
                    filters = payLoad;
                }
            } catch (error) { }

            SearchService.algoliaProductsearch("", filters).then((data) => {
                this.setState({ cardPostData: data, payLoad: filters });
            });
        }
    }

    goToAllDataPage = () => {
        const { payLoad } = this.state;
        const { itemData } = this.props;
        let parsedPayload = payLoad;
        if (parsedPayload && parsedPayload["hitsPerPage"]) {
            delete parsedPayload["hitsPerPage"];
        }
        let title = encodeURIComponent(itemData.name);
        let encoded = Buffer.from(
            JSON.stringify(parsedPayload),
            "ascii"
        ).toString("base64");
        debugger
        this.props.history.push({
            pathname: `/search/listings/title=${title}/${encoded}`,
        })
    }

    navigateToDetailPage(item) {
        const token = typeof window !== 'undefined' ? localStorage.getItem('Authorization') : null;
        if (token) {
            let searchStr = []
            let title = '';
            if (typeof (item.payLoad) === 'string') {
                item.payLoad = JSON.parse(item.payLoad)
            }
            let payload = item.payLoad;
            if (typeof (payload) === 'string') {
                payload = JSON.parse(payload)
            }
            if (item.htmlMarkUp) {
                let words = item.htmlMarkUp.split(' ');
                words.forEach(title => {
                    if (title.includes('<b>')) {
                        title = title.replace('<b>', "").replace('</b>', "")
                    }
                    if (title.includes('/')) {
                        title = title.replace('/', "")
                    }
                    searchStr.push(title)
                })

                title = searchStr.join('+')
            } else if (item.itemDescription && item.itemDescription.title) {
                title = item.itemDescription.title
            } else if (item.name) {
                title = item.name
            } else {
                if (!title) {
                    title = item.payLoad.displayName;
                }
            }

            if (title && payload) {
                let encoded = Buffer.from(JSON.stringify(payload), 'ascii').toString('base64');
                title = encodeURIComponent(title);
                let pathname = `/search/listings/title=${title}/${encoded}`;

                this.props.history.push({
                    pathname: pathname,
                });
                window.location.reload();
            }
        }
    }

    pickRandomBGColor(name) {
        let colors = ['#BA68C8', '#F06292', '#E57373', '#7986CB', '#9575CD', '#4DD0E1', '#4FC3F7', '#4DB6AC']
        let ascii = 0
        for (var i = 0; i < name.length; i++) {
            ascii = ascii + name.charCodeAt(i)
        }
        let index = this.getSum(ascii, colors.length)
        if (index === 8) {
            index = 0
        }
        return colors[index]
    }

    getSum(n, total) {
        var sum = 0;
        while (n !== 0) {
            n = parseInt(n / 10);
            sum = sum + (n % 10);
        }
        if (sum > total) {
            return this.getSum(sum, total)
        } else {
            return sum;
        }
    }

    render() {
        const { cardData, cardPostData } = this.state;
        const { itemData, t } = this.props;

        if (itemData && ((cardPostData && cardPostData.length > 0) || cardData)) {
            return (
                <div className="toolbar_navigation-items mt-2  slider_div">
                    {/* {cardPostData && cardPostData.length > 0 ? ( */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: "#F4F5F7",
                        }}
                        onClick={() => { this.goToAllDataPage() }}
                    >
                        <div className={"productSectionTitle"}>{itemData.name}</div>
                        <div className={" d-flex align-items-center"}>
                            <p
                                style={{ textDecoration: "underline" }}
                                className="pointer_Cursor tab_heading mt-3 mr-3"
                            >
                                {t("View All")}
                            </p>
                        </div>
                    </div>
                    {/* ) : null} */}

                    <div
                        className="hideScrollbar"
                        style={{ marginTop: "15px", overflowX: 'scroll', whiteSpace: 'nowrap' }}
                    >
                        {cardData &&
                            cardData.length > 0 &&
                            cardData.map((item, index) => {

                                return (
                                    <div
                                        className="filterTab"
                                        style={{ background: this.pickRandomBGColor(item.name) }}
                                        key={index}
                                        onClick={() => { this.navigateToDetailPage(item) }}>
                                        <div>{item.name}</div>
                                    </div >
                                );
                            })}
                        {cardPostData && cardPostData.length > 0
                            ? cardPostData.map((item, index) => {
                                return (
                                    <div
                                        className="filterTab"
                                        style={{ background: this.pickRandomBGColor() }}
                                        key={index}
                                        onClick={() => { this.navigateToDetailPage(item) }}>
                                        <div>{item.name} hh</div>
                                    </div>
                                );
                            })
                            : null}
                    </div>

                </div>
            );
        }

        return <div className="sectionloader"><SectionLoader /></div >
    }
}

const mapStateToProps = ({ global }) => {
    return {
        currentUser: global.currentUser,
        token: global.token,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};
export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(FilterTabView))
);
