import moment from 'moment';
import MediaUrl from '../service/mediaUrl';

const initialState = {
	chatThreads: {
		threads: [],
		pageLimit: 10,
		totalCount: 0,
		page: 1,
		totalPageCount: 0
	},
	userChats: {
		chats: {},
		pageLimit: 15,
		totalCount: 0,
		page: 1,
		totalPageCount: 0
	},
	activeChatThreadId: null
}

export default (state = initialState, action) => {
	let { payload } = action;
	let { data } = payload || {};

	function getChats(data, key) {
		let temp = {};
		if (key === 'append') {
			temp = state.userChats.chats;
		}
		data.forEach((item, i) => {
			let date = moment(data[i]['createdOn']).format('LL');
			if (temp.hasOwnProperty(date)) {
				temp[date] = [
					data[i],
					...temp[date]
				]
			} else {
				temp[date] = [data[i]];
			}
		})
		return temp;
	}

	switch (action.type) {

		case 'SET_ACTIVE_KEY':
			return {
				...state,
				activeChatThreadId: action.threadId,
				userChats: {
					chats: [],
					pageLimit: 15,
					totalCount: 0,
					totalPageCount: 0,
					page: 1
				}
			}

		case 'GET_ALL_CHAT_THREADS':
			if (data) {
				return ({
					...state,
					chatThreads: {
						threads: [...data.results],
						page: data.currentPage,
						pageLimit: data.pageLimit,
						totalCount: data.totalCount,
						totalPageCount: data.totalPageCount
					}
				});
			}
			return state;

		case 'APPEND_TO_CHAT_THREADS':
			if (data) {
				return ({
					...state,
					...state['chatThreads'],
					chatThreads: {
						threads: [...state.chatThreads.threads, ...data.results],
						page: data.currentPage,
						pageLimit: data.pageLimit,
						totalCount: data.totalCount,
						totalPageCount: data.totalPageCount
					}
				});
			}
			return state;

		case 'GET_ALL_CHATS':
			if (data) {
				return ({
					...state,
					activeChatThreadId: data.chatThreadId,
					userChats: {
						chats: {
							...getChats(data.results, 'new'),
						},
						pageLimit: data.pageLimit,
						totalCount: data.totalCount,
						page: data.currentPage,
						totalPageCount: data.totalPageCount
					}
				});
			}
			return state;

		case 'APPEND_TO_ALL_CHATS':
			if (data) {
				return ({
					...state,
					activeChatThreadId: data.chatThreadId,
					userChats: {
						chats: {
							...getChats(data.results, 'append'),
						},
						pageLimit: data.pageLimit,
						totalCount: data.totalCount,
						page: data.currentPage,
						totalPageCount: data.totalPageCount
					}
				});

			}
			return state;

		case 'SEND_CHAT_MESSAGE':
			if (data) {
				let clone = state.userChats.chats;
				let date = moment(data['createdOn']).format('LL');
				if (Object.keys(clone).includes(date)) {
					clone[date] = [...clone[date], data]
				} else {
					clone[date] = [data]
				}
				return ({
					...state,
					userChats: {
						...state['userChats'],
						chats: { ...clone },
						totalCount: state.userChats.totalCount + 1
					},
				})
			}
			return state;

		case 'SET_NOTIFICATION_DATA_IF_TAB_ACTIVE':
			if (data && data.chatThreadGuid === state.activeChatThreadId) {
				if (data.type === 1) {
					data.chatFile.url = MediaUrl.completeMediaUrl(data.chatFile.url, true, 100, 100);
				}
				if (data.type === 2) {
					data.chatFile.url = MediaUrl.completeMediaUrl(data.chatFile.url, false, 100, 100);
				}
				let clone = state.userChats.chats;
				let date = moment(data['createdOn']).format('LL');
				if (Object.keys(clone).includes(date)) {
					clone[date] = [...clone[date], data]
				} else {
					clone[date] = [data];
				}
				return ({
					...state,
					userChats: {
						...state['userChats'],
						chats: { ...clone },
						totalCount: state.userChats.totalCount + 1
					},
				});
			}
			return state;


		case 'CLEAR_CHAT':
			return ({
				...state,
				userChats: {
					chats: {},
					pageLimit: 15,
					totalCount: 0,
					totalPageCount: 0,
					page: 1
				},
				chatThreads: {
					threads: [],
					page: 1,
					pageLimit: 10,
					totalCount: 0,
					totalPageCount: 0
				},
				activeChatThreadId: null
			})

		default:
			return state
	}
}