import React, { Component } from 'react';
import { Form, Input, Button, Card, Checkbox, message, Modal, Radio } from 'antd';
import { withTranslation } from 'react-i18next';
import { MobileOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Authentication from "../../service/authService"
import { showLoader, hideLoader } from '../../actions'
import loginImage from '../../assests/Login_Web.svg'
import logo from '../../akar_dark.png';

const website_url = process.env.REACT_APP_WEBSITE_URL;

class LoginComponent extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props);
        this.state = {
            invalidPhoneError: false,
            invalidPhoneMsg: '',
            openLanguageModal: false,
            selectedLanguage: ''
        }
    }
    openLanguageModal = () => {
        const { i18n } = this.props
        let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null;
        this.setState({ openLanguageModal: true, selectedLanguage: language || i18n.language })
    }
    onFinish = (values) => {
        let isPhoneNumberValid = /^\d+$/.test(values.mobile.substring(0, 10)) && !values.mobile[10] || values.mobile[10] === ".";
        const { dispatch, t } = this.props
        if (isPhoneNumberValid) {
            if (!values.IsTermsAndConditionApprovedByBuyer) {
                return message.error(`${t('Please accept Terms and services')}`)
            }
            dispatch(showLoader())

            let mob = "+91" + values.mobile
            if (typeof window !== 'undefined') localStorage.setItem('mobile', mob)

            Authentication.postLoginMobile(mob)
                .then((res) => {
                    dispatch(hideLoader())
                    let redirect = this.props.history.location.search
                    if (res.data.applicationCode === 200) {
                        message.success(res.data.data.message)
                        if (redirect) {
                            redirect = decodeURI(redirect);
                            this.props.history.push({ pathname: '/otp', search: `${redirect}` })
                        } else {
                            this.props.history.push({ pathname: '/otp' })
                        }
                    }

                }, (err) => {
                    dispatch(hideLoader())
                    this.setState({ invalidPhoneError: true, invalidPhoneMsg: err.data.message })
                    setTimeout(() => {
                        this.setState({ invalidPhoneError: false, invalidPhoneMsg: '' })
                    }, 5000);

                });
        } else {
            return message.error(`${t('Invalid mobile')}`)
        }
    }

    componentDidMount() {

        const { token, currentUser } = this.props
        if (token && currentUser.action.type !== null) {
            this.props.history.push({ pathname: '/' })
        }
    }

    render() {

        const { loading, t, i18n } = this.props;
        const { openLanguageModal, selectedLanguage } = this.state;

        return (
            <>
                <div className='content-center'>
                    <div className='justify-content-center'>
                        <div>
                            <div className='justify-content-center mb-4'>
                                <img height='100' src={logo} alt='logo' />
                            </div>
                            <Card
                                className='auth-card'
                                style={{ width: 380 }}
                            >
                                <div className='justify-content-center mb-4'>
                                    <img height='120' src={loginImage} alt={'logoImg'} />

                                </div>
                                <div className='text-align-center mb-3'>
                                    <h3>{t('Enter mobile number')} </h3>
                                    <p>
                                        {t('Mobile description')}
                                    </p>
                                </div>

                                <Form ref={this.formRef}
                                    name="horizontal_login"
                                    layout="vertical"
                                    initialValues={{
                                        IsTermsAndConditionApprovedByBuyer: true,
                                    }}
                                    onFinish={this.onFinish}>

                                    <Form.Item
                                        name="mobile"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('Mobile No validation')}`,
                                            }, {
                                                message: `${t('Invalid mobile')}`,
                                                validateTrigger: 'onSubmit'
                                            }
                                        ]}
                                    >

                                        <Input prefix={<> <MobileOutlined
                                            className="site-form-item-icon margin-right-1" /> 91</>}
                                            placeholder={t('Mobile number')}
                                            maxLength="11"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="IsTermsAndConditionApprovedByBuyer"
                                        valuePropName="checked">
                                        <Checkbox>{t('I accept')}
                                            <span style={{ marginLeft: '5px' }} onClick={() => { window.open('#/terms-and-conditions') }}>
                                                {t('Terms and conditions')}
                                            </span>
                                        </Checkbox>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button loading={loading} className='my-button'
                                            htmlType="submit">
                                            {t('Request otp')}
                                        </Button>
                                    </Form.Item>
                                    <Form.Item className="login_change_language">
                                        <a
                                            onClick={() => {
                                                this.setState({ openLanguageModal: true })
                                            }}>{t("Change Language")}</a>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </div>
                        <Modal footer={null}
                            centered={true}
                            onCancel={() => {
                                this.setState({ openLanguageModal: false, selectedLanguage: i18n.language })
                            }}
                            maskClosable={false}
                            visible={openLanguageModal}>
                            <div className="text-center">
                                <h3 className="mb-4">CHOOSE LANGUAGE / अपनी भाषा चुनें</h3>
                                <div className="d-flex" style={{ justifyContent: 'center' }}>
                                    <Radio.Group value={selectedLanguage}
                                        onChange={(e) => {
                                            this.setState({ selectedLanguage: e.target.value })
                                        }}>
                                        <Radio value={'en'}>English</Radio>
                                        <Radio value={'hi'}>हिंदी</Radio>
                                    </Radio.Group>
                                </div>
                                <Button type={'primary'} className="my-3" onClick={() => {
                                    i18n.changeLanguage(selectedLanguage)
                                    if (typeof window !== 'undefined') localStorage.setItem("language", selectedLanguage);
                                    this.setState({ openLanguageModal: false })
                                }}>
                                    {(selectedLanguage === 'en') ? 'CONTINUE IN ENGLISH' : null}
                                    {(selectedLanguage === 'hi') ? 'हिंदी में जारी रखें' : null}
                                </Button>
                                <p>
                                    {(selectedLanguage === 'en') ? "Language options can be changed anytime. We will translate information to help you browse and order. Please note that translations have been provided for convenience only and English version of the Akar's, including our Conditions of Use, is the definitive version." : null}
                                    {(selectedLanguage === 'hi') ? "भाषा विकल्प कभी भी बदले जा सकते हैं। ब्राउज़ करने और ऑर्डर करने में आपकी सहायता के लिए हम जानकारी का अनुवाद करेंगे। कृपया ध्यान दें कि अनुवाद केवल सुविधा के लिए प्रदान किए गए हैं और हमारी उपयोग की शर्तों सहित आकार का अंग्रेजी संस्करण निश्चित संस्करण है।" : null}
                                </p>
                            </div>
                        </Modal>

                    </div>
                </div >
            </>
        );
    }


}

const mapStateToProps = ({ global }) => ({
    loading: global.buttonLoading,
    token: global.token,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginComponent)));
