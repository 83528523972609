import API from './network';
import Urls from './urls'

const AddListingService = {

    getAllCategory() {
        const path = Urls.allCategory
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })    
    },
    getCategoryHierarchy(id) {
        const path = Urls.categoryHierarchy + id
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    getCategoryAtrribute(id) {
        const path = Urls.categoryAtrribute + id
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    getProduct(id) {
        const path = Urls.product + id
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    getProductList(data) {
        const path = `${Urls.productList}?pageNumber=${data.pageNumber}&limit=10&status=${data.status}`
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    getGST() {
        const path = Urls.gst
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    createProductList(dataToPost) {
        const path = Urls.createProduct2
        return API.makePostRequest(path, null, null, dataToPost, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    updateProduct(dataToPost, id) {
        const path = Urls.updateProduct + id
        return API.makePutRequest(path, null, null, dataToPost, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    updateProductSet(productId, setId, dataToPost) {
        const path = Urls.updateProduct + productId + '/set/' + setId
        return API.makePutRequest(path, null, null, dataToPost, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    createProductSet(productId, dataToPost) {
        const path = Urls.updateProduct + productId + '/set/create'
        return API.makePostRequest(path, null, null, dataToPost, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    updateProductIsAvailableStatus(productId, status) {
        const path = Urls.updateIsAvailableProductStatus + productId + '/availability'
        let body = { "isAvailable": status }
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    updateSetIsAvailableStatus(productId, setId, status) {
        const path = Urls.updateIsAvailableSetStatus + productId + '/set/' + setId + '/availability'
        let body = { "isAvailable": status }
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    getSectionsData(data) {
        // const path = Urls.sectionData + `/${PagetypeId}?categoryId=1&`
        // const path = 'https://api.akarindia.com/business-service/v1/api/section/view' + `/${data.pageTypeId}?pageTypeId=${data.id}&`
        const path = Urls.sectionData + `/${data.pageTypeId}?pageTypeId=${data.id}&`
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    deleteSetById(data) {
        const path = Urls.deleteSet + data.id + '/set/' + data.setId
        return API.makeDeleateRequest(path, null, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    produceProductTitle(data) {
        const path = Urls.generateProductTitle
        return API.makePostRequest(path, null, null, data, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    getProductTitle(id) {
        const path = Urls.getProductTitle + id
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    getProductCommission(mapCategoryId, pricePerPiece) {
        const path = Urls.productCommission
        let body = { mapCategoryId, pricePerPiece: Number(pricePerPiece) }
        return API.makePostRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    cloneMedia(url) {
        const path = Urls.cloneMedia
        let body = {
            url: url,
            fileaccess: "public"
        }
        return API.makePostRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    updateProductSets(productID, data) {
        const path = Urls.product + productID + '/set/multiple'
        return API.makePutRequest(path, null, null, data, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    getProductRating(id) {
        const path = Urls.getProductRating + id
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
};

export default AddListingService;