import API from "./network";
import Urls from "./urls";
import algoliasearch from "algoliasearch";
import MediaUrl from './mediaUrl'
const algoliaKey = process.env.REACT_APP_ALGOLIA_KEY;
const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID;
const productIndexName = process.env.REACT_APP_ALGOLIA_PRODUCT_INDEX_NAME;
const brandIndexName = process.env.REACT_APP_ALGOLIA_BRAND_INDEX_NAME;
const categoryIndexName = process.env.REACT_APP_ALGOLIA_CATEGORY_INDEX_NAME;
const sellerIndexName = process.env.REACT_APP_ALGOLIA_SELLER_INDEX_NAME;
const searchClient = algoliasearch(
  algoliaAppId, algoliaKey
);

const editSettingClient = algoliasearch(
  algoliaAppId, process.env.REACT_APP_ALGOLIA_DATE_SHORT_KEY
);

const productIndex = searchClient.initIndex(productIndexName);
const sellerIndex = searchClient.initIndex(sellerIndexName);
const productIndexToArrangeByDate = editSettingClient.initIndex(productIndexName);

productIndexToArrangeByDate.setSettings({
  replicas:[
    'product_date_desc'
  ]
})

productIndexToArrangeByDate.setSettings({
  ranking:[
    "desc(created)",
    "typo",
    "geo",
    "words",
    "filters",
    "proximity",
    "attribute",
    "exact",
    "custom"
  ]
})

const SearchService = {
  postSearch(data) {
    const path = Urls.search;
    return API.makePostRequest(path, null, null, data, null).then((res) => {
      return res;
    });
  },
  algoliaSellerSearch(id) {
    return sellerIndex.search(id).then(({ hits }) => {
      return hits;
    });
  },

  algoliaProductsearch(searchString = "", payload = {}) {
    return productIndex.search(searchString, payload).then(({ hits }) => {
      if (hits.length) {
        hits.forEach(item => {
          if (item && item.mapProductFileUploadedDatas[0] && item.mapProductFileUploadedDatas[0].fileUploadedData["url"]) {
            let url = MediaUrl.completeMediaUrl(item.mapProductFileUploadedDatas[0].fileUploadedData["url"], true, 100, 100)
            item.imageUrl = url
          }
          let priceWithGst;
          let price = Number(item.mapProductSets[0].pricePerPiece);
          if (!item.isGSTIncluded) {
            let gstPercentage = Number(item.gstMaster.percentage);
            let gst = (Number(price) * gstPercentage) / 100;
            let num = price + gst;
            priceWithGst = (Math.round(num * 100) / 100).toFixed(2);
          } else {
            priceWithGst = price;
          }
          item.priceWithGst = priceWithGst;
        })
      }
      return hits;
    });
  },
  //  samplePayload :{
  //   "filters": "(categoryList.parentCategory.name : \"Women's Ethnic\" OR categoryName : \"Women's Ethnic\") OR (categoryList.parentCategory.name : \"Men's Garments\" OR categoryName : \"Men's Garments\")",
  //   "hitsPerPage": 10
  // },
  postAlgoliaMultipleQueries(request) {
    // debugger
    return searchClient.multipleQueries(request).then(({ results }) => {
      return results;
    });
  },

  createMultipleQuery(searchString, rootCategory = "Electronics & Appliances") {
    const queries = [
      {
        indexName: categoryIndexName,
        query: searchString,
        params: {
          hitsPerPage: 4,
        },
      },
      {
        indexName: brandIndexName,
        query: searchString,
        params: {
          hitsPerPage: 3,
        },
      },
      {
        indexName: sellerIndexName,
        query: searchString,
        params: {
          filters:`categoryList.name : "${rootCategory}"`,
          hitsPerPage: 3,
        },
      },
      {
        indexName: productIndexName,
        query: searchString,
        params: {
          filters: `categoryList.parentCategory.name : "${rootCategory}"`,
          hitsPerPage: 10,
        },
      },
    ];
    return queries;
  },
};

export default SearchService;