import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import myimage from '../../imageNotFound.jpeg'
import Api from '../../service/network'
import MediaUrl from '../../service/mediaUrl'
import SectionLoader from './sectionLoader'

class ImageCardView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageCardData: []
        }
    }
    componentDidMount() {
        const { itemData } = this.props
        if (itemData.callType === 'Get') {
            Api.makeGetRequest(itemData.dataUrl).then((res) => {

                let output = res.data.data.data
                output.forEach(item => {
                    if (item.imageUrl) {
                        let url = MediaUrl.completeMediaUrl(item.imageUrl, true, 100, 100)
                        //console.log(url, "url")
                        item.imageUrl = url
                    }
                })
                this.setState({ imageCardData: output })
            })
        }

    }

    render() {
        const { imageCardData } = this.state
        const { itemData } = this.props
        return (<>{itemData ?
            <div className="toolbar_navigation-items mt-2 slider_div">
                <h4 className={'mb-3'}>{itemData.name}</h4>
                <ul>
                    {imageCardData && imageCardData.length > 0 && imageCardData.map((imgCardVal, imgkey) => {
                        return (<li key={imgkey}>
                            <img height='160' src={myimage} alt='banner' />
                        </li>)
                    })}
                </ul>
            </div>
            :
            <div className="sectionloader">
                <SectionLoader />
            </div>
        }
        </>)
    }
}
export default withRouter(ImageCardView)
