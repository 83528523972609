import { Tabs } from "antd";
import React, { Component } from "react";
import { Row, Col } from "antd";
import ProductCard from "../ProductCard";
import { withRouter } from "react-router-dom";
import SearchService from "../../../service/searchService";
import "./index.scss";
import group27 from '../../../assests/group27.png'
const { TabPane } = Tabs;

let payloads = {
  Clothings: {
    filters: `( categoryList.parentCategory.name : "Clothing & Accessories" OR categoryName : "Clothing & Accessories")`,
    hitsPerPage: 12,
  },
  Electronics: {
    filters: `( categoryList.parentCategory.name : "Electronics & Appliances" OR categoryName : "Electronics & Appliances")`,
    hitsPerPage: 12,
  }
};

class ProductCardsHolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Clothings",
      visibleProducts: [],
    };
  }

  componentDidMount = () => {
    let { activeTab } = this.state;
    let payload = payloads[activeTab];
    this.fetchProductsData(payload);
  };

  fetchProductsData = (payload) => {
    SearchService.algoliaProductsearch("", payload).then((data) => {
      this.setState({ visibleProducts: data });
    });
  };

  handleTabChange = (data) => {
    this.setState({ activeTab: data });
    let payload = payloads[data];
    this.fetchProductsData(payload);
  };

  renderTabsData = () => {
    let { visibleProducts } = this.state;
    return (
      <div className="card-grid-container-lg">
        <Row className="py-1" align="middle">
          {visibleProducts.map((item, index) => {
            return (
              <Col key={index} className="center py-2" xs={12} sm={{ span: 4 }}>
                <ProductCard data={item} token={true} />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };
  render() {
    return (
      <Row className="py-5">
        <Col span={24} className="">
          <p className="text-center text-blue small-heading">Categories</p>
          <p className="text-center text-blue medium-heading">
            Akar Popular Item
          </p>
          <div className="w-100 text-center" style={{marginTop:"-8px"}}>
          <img className="text-center" src={group27}  />
          </div>
        </Col>
        <Col xs={{span:24}} sm={{span:22,offset:1}}>
          <Tabs defaultActiveKey="1" centered onChange={this.handleTabChange}>
            <TabPane tab="Clothings" key="Clothings">
              {this.renderTabsData()}
            </TabPane>
            <TabPane tab="Electronics" key="Electronics">
              {this.renderTabsData()}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    );
  }
}
export default withRouter(ProductCardsHolder);
