import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Drawer, message, Tabs, Button, Row, Col } from "antd";
import LayoutMain from "./layoutMain";
import { RightOutlined } from "@ant-design/icons";
import queryString from "query-string";
import _ from "lodash";
import myimage from "../imageNotFound.jpeg";
import AddListingService from "../service/addListingService";
import MediaUrl from "../service/mediaUrl";
import GridSection from "./sections/grid";
import CardSliderSection from "./sections/cardslider";
import FullWidthBannerSection from "./sections/fullwidthbanner";
import ImageCardSection from "./sections/imagecard";
import SquareCardSection from "./sections/sqaurecard";
import TwoColumnSection from "./sections/twoColoumnSection";
import ButtonSliderSection from "./sections/buttonSlider";
import CarouselBannerSection from "./sections/carouselBanner";
import LoaderComponent from "./loader";
import BannerWithStaticPage from "./sections/BannerWithStaticPage";
import ImageCardSlider from "./elements/ProductCardsHolder/ImageCardSlider";
import chevron from '../assests/chevron.png';
import BrandCardCarousel from "./elements/carousel/BrandCardCarousel";
const { TabPane } = Tabs;
class TopFixedSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { setSubcategory, childData } = this.props;
    return (
      <>
        {childData ? (
          <div className={"toolbar_navigation-items mt-2 slider_div"}>
            <ul>
              {childData &&
                childData.mapCategories &&
                childData.mapCategories.length > 0 &&
                childData.mapCategories.map((val, indexkey) => {
                  return (
                    <li
                      key={indexkey}
                      className={"mb-2"}
                      onClick={() => {
                        setSubcategory(val);
                      }}
                    >
                      <img
                        height="90"
                        src={
                          val.childCategory &&
                            val.childCategory.imageFileUploadedData
                            ? `${val.childCategory.imageFileUploadedData.url}`
                            : ""
                        }
                        alt="test_banner"
                      />
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : (
          <div className={"sectionloader"}>
            <LoaderComponent />
          </div>
        )}
      </>
    );
  }
}

class HomeMainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allcategory: [],
      selectedCategory: {
        mapCategories: [],
        name: "",
        displayCode: "",
        id: null,
        imageFileUploadDataId: null,
        imageFileUploadedData: null,
        mapCategories: [],
      },
      childCategory: [],
      availableChildCategory: [],
      unAvailableChildCategory: [],
      selectedTab: "All",
      visible: false,
      sectionData: [],
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search) || {};
    let category = (values && values.category) || null;
    let tab = (values && values.tab) || "All";
    this.setState({ selectedTab: tab }, () => {
      this.getCategoryHierarchy();
      if (category) {
        this.getSectionData(_.toNumber(values.category));
      } else {
        this.getSectionData();
      }
    });
  }

  getFilteredData = () => {
    const values = queryString.parse(this.props.location.search) || {};
    let { selectedTab, selectedCategory } = this.state;

    if (values && values.tab && values.category) {
      if (values.tab !== selectedTab) {
        let selectedSubCategory = _.find(
          selectedCategory.mapCategories,
          (item) => {
            return item.childCategory.displayCode === values.tab;
          }
        );
        if (selectedSubCategory) {
          this.setState({
            childCategory: selectedSubCategory.childCategory.mapCategories,
          });
        }
        this.setState({ selectedTab: values.tab });
      }
    } else {
      this.setState({ selectedTab: "All" }, () => {
        if (values.category) {
          this.getSectionData(_.toNumber(values.category));
        } else {
          this.getSectionData();
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    prevProps?.location?.search !== location.search &&
      this.getFilteredData();
  }

  changeCategory = (id) => {
    const values = queryString.parse(this.props.location.search);
    let { allcategory } = this.state;
    let index = allcategory.findIndex((item) => item.id == id);
    if (index > -1) {
      this.setState({
        selectedCategory: allcategory[index],
        sectionData: [],
        selectedTab: "All",
      });
    }
    this.props.history.push({
      pathname: "/",
      search: `?category=${id || values.category}`,
    });
    let category = allcategory[index].displayCode

    sessionStorage.setItem("rootCategory", category)
    sessionStorage.setItem("rootCategoryId", allcategory[index].id)
    this.getSectionData(id);
    this.onClose();
  };

  // for electronics prod id = 189 & for electronics staging id = 171 & for clothing id = 1.
  // getCategoryId = () => {
  //   const url = process.env.REACT_APP_WEBSITE_URL;
  //   let id = null;
  //   if (url.includes("dev.akarindia.com") || url.includes("localhost")) {
  //     // For Clothing in dev
  //     id = 1;
  //     // For Electronics in dev
  //     // id = 171
  //   } else if (url.includes("www.akarindia.com")) {
  //     // For Clothing in production
  //     //id = 1
  //     // For Electronics in production
  //     id = 189;
  //   }
  //   return id;
  // };

  // for electronics prod id = 189 & for electronics staging id = 171 & for clothing id = 1.
  getCategoryId = () => {
    const url = process.env.REACT_APP_WEBSITE_URL;
    let id = Number(sessionStorage.getItem('rootCategoryId')) ?? null;
    let name = sessionStorage.getItem('rootCategory') ?? "";
    if (!id) {
      if (url.includes("dev.akarindia.com") || url.includes("localhost")) {
        // For Clothing in dev
        id = 1;
        name = "Clothing & Accessories";
        // For Electronics in dev
        // id = 171
      } else if (url.includes("www.akarindia.com")) {
        // For Clothing in production
        //id = 1
        // For Electronics in production
        id = 189;
        name = "Electronics & Appliances";
      }
    }

    sessionStorage.setItem("rootCategory", name)
    sessionStorage.setItem("rootCategoryId", id)

    return id;
  }

  getSectionData = async (id = this.getCategoryId() || 1) => {
    console.log(id)
    const { t } = this.props;
    let obj = { pageTypeId: 3, id }
    let { data } = await AddListingService.getSectionsData(obj);
    if (data.isSuccess) {
      let sectionData = data.data;
      this.setState({ sectionData });
    } else {
      message.error(`${t('Error in loading data')}`)
    }
  }

  getCategoryHierarchy = () => {
    const values = queryString.parse(this.props.location.search);
    console.log(values)
    const categoryId = values.category ?? sessionStorage.getItem('rootCategoryId') ?? ''
    AddListingService.getCategoryHierarchy(0).then(
      (res) => {
        let dummy_categories = [];
        let allCategory = res.data.data.mapCategories;
        allCategory.forEach((item) => {
          dummy_categories.push(item.childCategory);
        });
        let dummy_selectedCategory
        const url = process.env.REACT_APP_WEBSITE_URL;
        if (categoryId) {
          dummy_selectedCategory = _.find(dummy_categories, (item) => {
            return item.id == categoryId;
          });
        } else if (url.includes("dev.akarindia.com") || url.includes("localhost")) {
          dummy_selectedCategory = _.find(dummy_categories, (item) => {
            return item.id == 1;
          });
        } else if (url.includes("www.akarindia.com")) {
          dummy_selectedCategory = _.find(dummy_categories, (item) => {
            return item.id == 189;
          });
        }


        this.setState({
          allcategory: dummy_categories,
          selectedCategory: dummy_selectedCategory || dummy_categories[0],
        });

        if (values && values.tab) {
          let selectedSubCategory = _.find(
            dummy_selectedCategory.mapCategories,
            (item) => {
              return item.childCategory.displayCode === values.tab;
            }
          );
          if (selectedSubCategory) {
            this.setChildCategories(selectedSubCategory.childCategory.mapCategories)
          }
        }
      },
      (err) => { }
    );
  };

  setChildCategories = (categories) => {
    let availableCategory = []
    let unAvailableCategory = []

    categories.forEach(item => {

      let availableChildCategory = []
      let unAvailableChildCategory = []
      item.childCategory.mapCategories.forEach(child => {
        if (child.childCategory.isProductAvailable) {
          availableChildCategory.push(child)
        } else {
          unAvailableChildCategory.push(child)
        }
      })
      if (availableChildCategory.length) {
        let category = JSON.parse(JSON.stringify(item))
        category.childCategory.mapCategories = availableChildCategory
        availableCategory.push(category)
      }

      if (unAvailableChildCategory.length) {
        let category = JSON.parse(JSON.stringify(item))
        category.childCategory.mapCategories = unAvailableChildCategory
        unAvailableCategory.push(category)
      }
    })

    this.setState({
      availableChildCategory: availableCategory,
      unAvailableChildCategory: unAvailableCategory,
      childCategory: categories,
    });

  }

  showDrawer = () => {
    this.setState({
      visible: true,
      placement: "bottom",
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getAllCategories = async () => {
    const { t } = this.props;
    let { data } = await AddListingService.getAllCategory();
    if (data.isSuccess) {
      let { mapCategories } = data.data;
      let categoriesArr = [];
      mapCategories &&
        mapCategories.forEach((item) => {
          let obj = {
            description: item.childCategory.description,
            name: item.childCategory.name,
            id: item.childCategoryId,
            imageUrl:
              (item.childCategory.imageFileUploadedData &&
                item.childCategory.imageFileUploadedData.url) ||
              "",
          };
          categoriesArr.push(obj);
        });
      this.setState({ categoriesList: categoriesArr }, () => {
        this.showDrawer();
      });
    } else {
      message.error(`${t("Data not found.")}`);
    }
  };

  onTabChange = (key) => {
    let values = this.props.location.search;
    const { selectedCategory } = this.state;
    if (key !== "All") {
      let selectedSubCategory = _.find(
        selectedCategory.mapCategories,
        (item) => {
          return item.childCategory.displayCode === key;
        }
      );
      if (selectedSubCategory) {
        this.setChildCategories(selectedSubCategory.childCategory.mapCategories)
      }
      this.props.history.push({
        pathname: "/",
        search: `?category=${selectedCategory.id}&tab=${encodeURIComponent(
          key
        )}`,
      });
      this.setState({ selectedTab: key });
    } else {
      this.props.history.push({
        pathname: "/",
        search: `?category=${selectedCategory.id}`,
      });
      this.setState({ selectedTab: key }, () => {
        this.getSectionData(selectedCategory.id);
      });
    }
  };

  getHomeData = () => {
    const { sectionData, allcategory } = this.state;
    return sectionData.map((item, index) => {
      console.log(item.viewType)
      console.log(item)
      switch (item.viewType) {
        case "ImageCard":
          return (
            <div className={"my-5"} key={index}>
              <ImageCardSection itemData={item} />
            </div>
          );
        case "BrandCardCarousel":
          return (
            <div className={"my-5"} key={index}>
              <BrandCardCarousel itemData={item} />
            </div>
          );
        case "ButtonSlider":
          return (
            <div className={"my-5"} key={index}>
              <ButtonSliderSection itemData={item} />
            </div>
          );
        case "SquareCard":
          return (
            <div className={"my-5"} key={index}>
              <SquareCardSection itemData={item} />
              {/* <BrandCardCarousel itemData={item} /> */}
            </div>
          );
        case "Grid":
          return (
            <div className={"my-5"} key={index}>
              <GridSection itemData={item} />
            </div>
          );
        case "CarouselBanner":
          return (
            <div className={"my-3"} key={index}>
              <CarouselBannerSection itemData={item} />
            </div>
          );
        case "FullWidthBanner":
          return (
            <div className={"my-3"} key={index}>
              <FullWidthBannerSection itemData={item} />
            </div>
          );
        case "CardSlider":
          return (
            <div className={"my-5"} key={index}>
              <CardSliderSection showScrollBar={true} itemData={item} />
            </div>
          );
        case "BannerWithStaticPage":
          return (
            <div className={"my-5"} key={index}>
              <BannerWithStaticPage itemData={item} />
            </div>
          );
        case "ImageCardSlider":
          return (
            <div className={"my-5"} key={index}>
              <ImageCardSlider itemData={item} />
            </div>
          );
        case "TwoColumnSection":
          return (
            <div className={"my-5"} key={index}>
              <TwoColumnSection itemData={item} />
              {/* <BrandCardCarousel itemData={item} /> */}
            </div>
          );
        default:
          return (
            <div className={"my-3"} key={index}>
              <TopFixedSection
                childData={allcategory}
                setSubcategory={(val) => {
                  this.changeCategoryTab(val);
                }}
              />
            </div>
          );
      }
    });
  };

  render() {
    const {
      selectedCategory,
      childCategory,
      selectedTab,
      categoriesList,
      sectionData,
      visible,
      placement,
      availableChildCategory,
      unAvailableChildCategory
    } = this.state;
    const { t } = this.props;
    console.log(childCategory)
    return (
      <LayoutMain>
        <div className="mt-2"></div>
        <div className="category_tab_bar">
          <div className="categoryHeading">
            <div>
              <p className="text-blue">Categories</p>
              <h3 className="mr-4">
                {selectedCategory && selectedCategory.name}
              </h3>
            </div>
            <button
              onClick={() => {
                this.getAllCategories();
              }}
            >

              {t("Change")}
              <img src={chevron} alt='' />
            </button>
          </div>
          <div>
            <Tabs
              className="tabHeader"
              size={"large"}
              onChange={this.onTabChange}
              activeKey={selectedTab}
            >
              <TabPane tab={t("All")} key="All">
                <div
                  id="main-category-tabs-div"
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    overflowX: "scroll",
                  }}
                >
                  {selectedCategory.mapCategories &&
                    selectedCategory.mapCategories.map((item, index) => {
                      let imageUrl =
                        (item.childCategory.cardImageFileUploadedData &&
                          item.childCategory.cardImageFileUploadedData.url) ||
                        "";
                      return (
                        <div
                          key={index}
                          style={{
                            margin: "0px 8px 8px 8px",
                            boxShadow: "5px 0px 8px #eee",
                            height: "100px",
                            width: "150px",
                            minWidth: "150px",
                            overflow: "hidden",
                          }}
                          onClick={() =>
                            this.onTabChange(item.childCategory.displayCode)
                          }
                        >
                          <img src={MediaUrl.completeMediaUrl(imageUrl, true, 120, 150)}
                            height="100%" width="100%"
                            style={{ objectFit: "cover", objectPosition: "center" }}
                            alt='some category'
                          />
                        </div>
                      );
                    })}
                </div>
                {sectionData && sectionData.length > 0
                  ? this.getHomeData()
                  : null}
              </TabPane>

              {selectedCategory.mapCategories &&
                selectedCategory.mapCategories.map((item, index) => {
                  return (
                    <TabPane
                      tab={`${item.childCategory.name}`}
                      key={`${item.childCategory.displayCode}`}
                    >
                      <div>
                        {availableChildCategory.map((item, i) => {
                          return (
                            <div className="category_page" key={i}>
                              <div className="category_title">
                                <div className="top_bar"></div>
                                <p>{item.childCategory.name} </p>
                              </div>
                              {item.childCategory.mapCategories.map(
                                (child, cindex) => {
                                  let imgurl;
                                  if (
                                    child.childCategory.imageFileUploadedData &&
                                    child.childCategory.imageFileUploadedData
                                      .url
                                  ) {
                                    imgurl = MediaUrl.completeMediaUrl(
                                      child.childCategory.imageFileUploadedData
                                        .url,
                                      true,
                                      100,
                                      100
                                    );
                                  }
                                  return (
                                    <>
                                      {child.childCategory.isProductAvailable ?
                                        <Link
                                          key={cindex}
                                          to={{
                                            pathname: `/subcategory/${child.childCategoryId}`,
                                            search: queryString.stringify({
                                              categoryId: item.childCategoryId,
                                              title: child.childCategory.name,
                                            }),
                                          }}
                                        >
                                          <div className="subcategory_div">
                                            <div className="image_div">
                                              <img
                                                src={imgurl ? imgurl : myimage}
                                                alt="banner"
                                              />
                                            </div>
                                            <div
                                              key={cindex}
                                              className="subcategory_title"
                                            >
                                              {child.childCategory.name}
                                            </div>
                                            <div className="spacer" />
                                            <RightOutlined />
                                          </div>
                                        </Link> : null
                                      }</>

                                  );
                                }
                              )}
                            </div>
                          );
                        })}
                      </div>

                      <div>
                        {unAvailableChildCategory.length ?
                          <h6 className="mt-4 mb-4" style={{
                            color: 'white', backgroundColor: '#f26522',
                            padding: '10px', borderRadius: '8px',
                            fontWeight: 'bolder'
                          }}>Coming Soon</h6> : null}
                        {unAvailableChildCategory.map((item, i) => {
                          return (
                            <>
                              <div className="category_page" key={i}>
                                <div className="category_title">
                                  <div className="top_bar"></div>
                                  <p>{item.childCategory.name} </p>
                                </div>
                                {item.childCategory.mapCategories.map(
                                  (child, cindex) => {
                                    let imgurl;
                                    if (
                                      child.childCategory.imageFileUploadedData &&
                                      child.childCategory.imageFileUploadedData
                                        .url
                                    ) {
                                      imgurl = MediaUrl.completeMediaUrl(
                                        child.childCategory.imageFileUploadedData
                                          .url,
                                        true,
                                        100,
                                        100
                                      );
                                    }
                                    return (
                                      <>
                                        {!child.childCategory.isProductAvailable ?
                                          <div
                                            key={cindex}
                                          >
                                            <div className="subcategory_div" style={{ color: '#848482' }}>
                                              <div className="image_div">
                                                <img
                                                  src={imgurl ? imgurl : myimage}
                                                  alt="banner"
                                                />
                                              </div>
                                              <div
                                                key={cindex}
                                                className="subcategory_title"
                                              >
                                                {child.childCategory.name}
                                              </div>
                                            </div>
                                          </div> : null
                                        }
                                      </>

                                    );
                                  }
                                )}
                              </div>

                            </>
                          );
                        })}
                      </div>
                    </TabPane>
                  );
                })}

            </Tabs>
          </div>
        </div>
        <Drawer
          height={500}
          placement={placement}
          maskClosable={true}
          onClose={this.onClose}
          visible={visible}
          key={placement}
          title={t("Select Category")}
        >
          <div className="categoryDrawer">
            {categoriesList &&
              categoriesList.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "24px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={MediaUrl.completeMediaUrl(
                        item.imageUrl,
                        true,
                        120,
                        150
                      )}
                      style={{
                        objectFit: "cover",
                        objectPosition: "center",
                        height: "80px",
                        width: "80px",
                      }}
                    />
                    <h3
                      style={{ display: "flex", flex: "1" }}
                      className="ml-4 mr-4 mb-0"
                    >
                      {item.name}
                    </h3>
                    {selectedCategory && selectedCategory.id === item.id ? (
                      <Button disabled type="primary">
                        {t("Selected")}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          this.changeCategory(item.id);
                        }}
                        type="primary"
                      >
                        {t("Select")}
                      </Button>
                    )}
                  </div>
                );
              })}
          </div>
        </Drawer>
      </LayoutMain>
    );
  }
}

export default withTranslation()(withRouter(HomeMainComponent));
