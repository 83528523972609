import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../../layoutComponents/layoutComponent';
import { Row, Col, Checkbox, message } from 'antd';
import { withTranslation } from 'react-i18next';
import BrandService from '../../../service/brandService'
import SaveListing from '../../../service/saveListingService'
import MediaUrl from '../../../service/mediaUrl'


class WholesalerVarificationComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            disableSubmitBtn: true,
            brand: {}
        }
    }

    componentDidMount() {
        let brand = typeof window !== 'undefined' ? localStorage.getItem('brand') : null
        if (brand) {
            this.setState({ brand: JSON.parse(brand) })
        }
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    onChange = (e) => {
        this.setState({ disableSubmitBtn: !e.target.checked })

    }

    submitBrandVarification = () => {
        if (SaveListing.brand.relationType === 'TRADER') {
            this.wholesalerVarification()
        } else {
            this.distributorVarification()
        }

    }

    wholesalerVarification = () => {
        let dataToPost = {
            "isNewBrand": SaveListing.brand.isNewBrand,
            "relationType": 'TRADER',
            "brand": this.state.brand,
            "description": SaveListing.brand.brand.description,
            "IsAcknowledged": true
        }
        this.createBrand(dataToPost)
    }

    distributorVarification = () => {
        let mapBrandAccountFileUploadedDatas = []
        SaveListing.brand.mapBrandAccountFileUploadedDatas.forEach(item => {
            item.url = MediaUrl.trimMediaUrl(item.url)
            let obj = {
                "type": SaveListing.brand.brandDocTYpe,
                "fileUploadedData": {
                    "name": item.fileName,
                    "url": item.url
                }
            }
            mapBrandAccountFileUploadedDatas.push(obj)
        })

        let dataToPost = {
            "isNewBrand": SaveListing.brand.isNewBrand,
            "relationType": SaveListing.brand.relationType,
            "brand": this.state.brand,
            "description": SaveListing.brand.brand.description,
            "IsAcknowledged": true,
            "mapBrandAccountFileUploadedDatas": mapBrandAccountFileUploadedDatas
        }
        this.createBrand(dataToPost)
    }

    createBrand = (dataToPost) => {
        if (SaveListing.brand.isNewBrand) {
            dataToPost['imageFileUploadedData'] = SaveListing.brand.imageFileUploadedData
        }

        BrandService.createBrand(dataToPost)
            .then((res) => {
                message.success('Brand added successfully');
                SaveListing.isBrandSelected = true;
                SaveListing.brand = res.data.data
                if (typeof window !== 'undefined') { localStorage.removeItem('brand') }
                this.navigate('/create-list')

            }, err => {
                if (err && err.status === 300) {
                    message.error(err.data.message);
                    this.navigate('/create-list')
                } if (err && err.data && err.data.message) {
                    message.error(err.data.message);
                }
            })
    }

    render() {
        const { disableSubmitBtn } = this.state;
        const { t } = this.props;

        return (
            <LayoutComponent title={t('Brand')}>
                <Row className='sidebar_navigation-items divWithFixButton'>
                    <Col span={24}>
                        <h3 className="mt-3">{t('Details')}</h3>
                        <div className='brand_list mt-0 pt-0 pb-0 border'>
                            <div className='brand'>
                                <p>{t('Brand name')}:</p> <p className='ml-3'>{SaveListing.brand.brand.name}</p>
                            </div>
                            <div className='brand mt-1'>
                                {SaveListing.brand.relationType === 'TRADER' &&
                                    <>
                                        <p>{t('Relationship')}:</p> <p className='ml-3'>{t('Wholesaler/Trader')}</p>
                                    </>
                                }
                                {SaveListing.brand.relationType === 'OWNER' &&
                                    <>
                                        <p>{t('Relationship')}:</p> <p className='ml-3'>{t('Brand owner')}</p>
                                    </>
                                }
                                {SaveListing.brand.relationType === 'DISTRIBUTOR' &&
                                    <>
                                        <p>{t('Relationship')}:</p> <p className='ml-3'>{t('Authorized Distributor/Reseller')}</p>
                                    </>
                                }
                            </div>
                        </div>
                    </Col>

                    <Col span={24} className='mt-5' style={{ marginBottom: '75px' }}>
                        <h3>{t('Terms_Conditions')}</h3>
                        <div className='brand_list mt-0 pt-0 pb-0 border'>
                            <div className='brand'>
                                <div>
                                    <h4> {t('By clicking I agree')}:</h4>
                                    <ol>
                                        <li>
                                            I am a seller on the ‘akar’ platform and I am competent to swear to the
                                            contents of this declaration.
                                        </li>
                                        <li>All of the products displayed or offered for sale through my listings on the
                                            akar platform are my/our authentic products that have been legally
                                            manufactured by me/us/original manufacturer and have not been impaired in
                                            any manner.
                                        </li>
                                        <li>I agree to indemnify akar against any and all liability, loss or damage
                                            arising out of any action which is in breach of this declaration.
                                        </li>
                                        <li>All warranties and guarantees in respect of the products displayed or
                                            offered for sale through my/our listings on the akar platform remain in the
                                            manner as provided by us for those products without any change in any
                                            manner.
                                        </li>
                                        <li>The products listed on the akar platform by us are not counterfeits, fakes,
                                            replicas or those that have been produced illegally, duplicated, bootlegged,
                                            copied to pass off as being original products or violate third party
                                            intellectual property rights.
                                        </li>
                                        <li>I am aware that in case the products listed by me for sale on the akar
                                            platform are found to be of counterfeit nature or have been impaired in any
                                            manner, akar shall take appropriate action such as but not limited to
                                            suspending or terminating my/our account, removing any or all of my/our
                                            product listings or any other necessary action and this shall be without any
                                            admission as to liability and without prejudice to any rights, remedies or
                                            defense, all of which are expressly reserved with akar.Ï
                                        </li>
                                        <li>I am also aware that by displaying or offering counterfeit products for sale
                                            or such products which result in third party intellectual property
                                            violation, I/We shall be independently and individually be subject to civil
                                            and criminal prosecution by akar or such third parties. I understand and
                                            confirm that in such circumstances akar being only a platform service
                                            provider owes no responsibility on authenticity of the product and product
                                            listing.
                                        </li>
                                    </ol>
                                    <h4>{t('I solemnly state that')}</h4>
                                </div>
                            </div>
                        </div>

                        <Checkbox className='mt-1' onChange={this.onChange}>
                            {t('I agree akar rights')}
                        </Checkbox>
                    </Col>


                </Row>
                <Row>
                    <Col span={24} className='stickToBottom'>
                        <div className='button_div'>
                            <button disabled={disableSubmitBtn} onClick={() => this.submitBrandVarification()}>
                                {t('Submit')}
                            </button>
                        </div>
                    </Col>
                </Row>
            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(WholesalerVarificationComponent));