import API from './network';
import Urls from './urls'

const ChatService = {
    getAllChats(pageNumber, limit) {
        const path = `${Urls.getChats}?pageNumber=${pageNumber}&limit=${limit}`
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            })
    },
    sendChatMessage(data) {
        const path = Urls.createChat
        return API.makePostRequest(path, null, null, data, null)
            .then((res) => {
                return res
            })
    },
    getAllChatByGuid(chatGuId, pageNumber, limit) {
        const path = `${Urls.getAllChatsByGuid}?chatThreadGuid=${chatGuId}&pageNumber=${pageNumber}&limit=${limit}`
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            })
    },
    createChatThread(orderId, sellerAccountId) {
        const path = Urls.chatThreadPost
        let dataToPost = { "orderId": `${orderId}`, "to": sellerAccountId }
        return API.makePostRequest(path, null, null, dataToPost, null)
            .then((res) => {
                return res
            })
    }
}
export default ChatService;