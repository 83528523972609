import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Row, Col, Button, message, Modal } from 'antd';
import { Card } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import LayoutComponent from '../layoutComponents/layoutComponent';
import ModalComponent from '../modal/modal'
import AuthService from '../../service/authService';
import whiteLogo from '../../akar_dark.png'

let imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

class ApplyToSellComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCompleteKycBtn: false,
            isOpenModal: false,
            becomeSeller: true,
            gstDetailsData: [],
            openNoticeModal: false
        }
    }

    componentDidMount() {
        this.getGSTAccountDetails()
    }

    getGSTAccountDetails = async () => {
        const { t } = this.props
        let { data } = await AuthService.getAllGSTAccounts()
        if (data.isSuccess) {
            this.setState({ gstDetailsData: data.data })
        } else {
            return message.error(`${t('Error in fetching gst details')}`)
        }
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    handleRedirection = () => {
        this.setState({ openNoticeModal: false }, () => {
            this.props.history.push({ pathname: '/account-profile' })
        })
    };

    render() {
        const { currentUser, t } = this.props;
        if (currentUser && !currentUser.id) return null;
        const { isOpenModal, gstDetailsData, openNoticeModal } = this.state
        let {
            isBankDetailsExist,
            isTermsAndConditionApprovedForSeller
        } = currentUser.account;

        const becomeASeller = <>
            {(!isBankDetailsExist || !isTermsAndConditionApprovedForSeller) ?
                <Row>
                    <Col span={24}>
                        <Button type="primary"
                            onClick={(e) => {
                                if (!gstDetailsData.length) {
                                    this.setState({ openNoticeModal: true })
                                } else {
                                    this.setState({ isOpenModal: true })
                                }
                            }}
                            style={{ width: '100%', margin: '10px' }}>
                            {t('Become a seller')}
                        </Button>
                    </Col>
                </Row> : null}
        </>

        return (<LayoutComponent title={t('Sell on Akar')}>

            <div className='mt-5'>
                <img
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                        width: '100%'
                    }}
                    src={imageBaseUrl + 'StaticAssets/Seller_1.png'}
                    alt='test_banner' />
            </div>

            <div className='mb-5 mt-5'>
                <img
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                        width: '100%'
                    }}
                    src={imageBaseUrl + 'StaticAssets/Seller_2_1.png'}
                    alt='test_banner' />
            </div>

            <div className='mb-3'>
                <img
                    style={{
                        objectFit: 'contain',
                        objectPosition: 'center',
                        width: '100%'
                    }}
                    src={imageBaseUrl + 'StaticAssets/Seller_3.png'}
                    alt='test_banner' />
            </div>

            {becomeASeller}

            {
                isOpenModal ?
                    <ModalComponent showModal={isOpenModal} becomeSeller={true}
                        onClose={() => {
                            this.setState({ isOpenModal: false, becomeSeller: false })
                        }} /> : null
            }

            {openNoticeModal ?
                <Modal visible={openNoticeModal}
                    centered={true}
                    closable={false}
                    footer={null}>
                    <div className='text-align-center'>
                        <div className='mb-2'>
                            <img height='60'
                                src={whiteLogo} />
                        </div>
                        <div className='mb-2'
                            style={{ fontWeight: '700', fontSize: '22px' }}>
                            {t('GST details mandatory')}</div>

                        <p style={{ color: '#cacaca', fontSize: '14px', marginVertical: '12px' }} className="mb-4">
                            {t('Please provide GST details')}
                        </p>

                        <div className='mb-2' >
                            <Button type="primary" onClick={() => {
                                this.handleRedirection()
                            }}>{t('Goto account settings')}</Button></div>
                    </div>

                </Modal>
                : null}
        </LayoutComponent >)
    }
}

const mapStateToProps = ({ global }) => ({
    loading: global.buttonLoading,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ApplyToSellComponent)));

