import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import "./index.scss";
import myimage from "../../../imageNotFound.jpeg";
import Api from "../../../service/network";
import MediaUrl from "../../../service/mediaUrl";
import { commonRedirections } from "../../sections/redirections";
import SectionLoader from "../../sections/sectionLoader";

class BrandCardCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      displayData: [],
      startIndex: 0,
      lastIndex: 6,
      allData: [],
      squareCardData: [],
    };
  }

  componentDidMount() {
    let { itemData = [] } = this.props;
    this.setState({ allData: itemData });
    if (itemData.callType === "Get") {
      let path = `${itemData.dataUrl}${itemData.queryParam}`;
      Api.makeGetRequest(path, null, null, null).then((res) => {
        let output = res.data.data.data;
        output.forEach((item) => {
          if (item.imageUrl) {
            let url = MediaUrl.completeMediaUrl(item.imageUrl, true, 100, 100);
            item.imageUrl = url;
          }
        });
        this.setState({ squareCardData: output });
      });
    }
  }
  scrollLeft=()=>{
    let slider = document.getElementById("slider")
    slider.scrollLeft -= 340;
  }
  scrollRight=()=>{
    let slider = document.getElementById("slider");
    slider.scrollLeft += 340;
  }
  render() {
    let { squareCardData } = this.state;
    const { itemData } = this.props;
    return (
      <div className="brand-card-container">
        <div className="text-center mt-5">
          <p className="text-blue medium-heading">{itemData.name}</p>
        </div>
        <div className="main-slider-container">
          <div className="arrow-container" onClick={() => this.scrollLeft()}>
            <LeftCircleOutlined
              className="arrow-icon"
            />
          </div>
          <div className="" id="slider">
            {squareCardData &&
                    squareCardData.length > 0 ?
                    squareCardData.map((sqCardVal, sqkey) => {
                      return (
                        <div
                          className="slider-card "
                          key={sqkey}
                          onClick={() => {
                            let response = commonRedirections(sqCardVal);
                            this.props.history.push({ pathname: response });
                          }}
                        >
                          <img
                            className="slider-card-image"
                            src={
                              sqCardVal.imageUrl ? sqCardVal.imageUrl : myimage
                            }
                            alt="banner"
                          />
                        </div>
                      );
                    }):null}
           
          </div>

          <div className="arrow-container" onClick={() => this.scrollRight()}>
            <RightCircleOutlined
              className="arrow-icon"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BrandCardCarousel);
