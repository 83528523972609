import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import LayoutComponent from '../../layoutComponents/layoutComponent';
import { Row, Tabs, Col, Input, Checkbox } from 'antd';
import { withTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import myimage from '../../../imageNotFound.jpeg'
import SaveListing from '../../../service/saveListingService'

const { TabPane } = Tabs;
const CheckboxGroup = Checkbox.Group;
const { Search } = Input;

class BrandOwnerVarificationComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    trademarkCertificate = () => {
        SaveListing.brand.brandDocTYpe = 'TradeMarkCertificateBrandAssociation'
        this.navigate('/brand-docs')
    }

    trademarkApplication = () => {
        SaveListing.brand.brandDocTYpe = 'TradeMarkApplicationBrandAssociation'
        this.navigate('/brand-docs')

    }

    letterheadDoc = () => {
        SaveListing.brand.brandDocTYpe = 'LetterHeadBrandAssociation'
        this.navigate('/brand-letterhead')
    }

    render() {
        const { t } = this.props;
        return (
            <LayoutComponent title={t('Brand')}>
                <Row className=' sidebar_navigation-items' >
                    <Col span={24}>
                        <h3 className="mt-3">{t('Upload any document as a proof')}: </h3>
                        <div className='brand_list mt-0 border pt-0 pb-0'>
                            <div className='brand' onClick={(e) => this.trademarkCertificate()}>
                                <div>
                                    <h4>
                                        {t('Trademark certificate')}
                                    </h4>
                                    <p className='label'>
                                        {t('Registered Trademark Certificate')}
                                    </p>
                                </div>
                                <div className='spacer'></div>
                                <RightOutlined />
                            </div>
                            <div className='brand' onClick={(e) => this.trademarkApplication()} >
                                <div>
                                    <h4>
                                        {t('Trademark application')}
                                    </h4>
                                    <p className='label'>
                                        {t('Application for registration of a Trademark')}
                                    </p>
                                </div>
                                <div className='spacer'></div>
                                <RightOutlined />
                            </div>

                            <div className='brand' onClick={(e) => this.letterheadDoc()}>
                                <div>
                                    <h4>
                                        {t('Letterhead')}
                                    </h4>
                                    <p className='label'>
                                        {t('Declaration printed on letterhead')}
                                    </p>
                                </div>
                                <div className='spacer'></div>
                                <RightOutlined />
                            </div>
                        </div>
                    </Col>

                </Row>
            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(BrandOwnerVarificationComponent))