import React, { Component } from "react";
import { Card } from "antd";
import { withRouter } from "react-router-dom";
import "./index.scss";
import _ from 'lodash';
const { Meta } = Card;


class ProductCard extends Component {
  render() {
    let { data, token } = this.props;
    let priceWithGst;
    let priceWithoutGST;
    let price = data.minPrice
    let gstPercentage = Number(data.gstMaster.percentage);
    let gst = (Number(price) * gstPercentage) / 100;
    if (data.minPrice === data.maxPrice) {
      priceWithoutGST = price
      priceWithGst = _.round((price + gst), 2)
    } else {
      let maxPriceGst = (Number(data.maxPrice) * gstPercentage) / 100;
      priceWithoutGST = `${price} - ${data.maxPrice}`
      priceWithGst = `${_.round((price + gst), 2)} - ${_.round((data.maxPrice + maxPriceGst), 2)}`
    }
    return (
      <div
        className="product-card"
        key={data.id}
        onClick={() => {
          const token = typeof window !== 'undefined' ? localStorage.getItem('Authorization') : null;
          if (token) {
            this.props.history.push({
              pathname: `/view-product/${data["id"]}`,
            });
            window.location.reload();
          } else {
            this.props.history.push({
              pathname: `/login`,
              search: `?redirect=view-product/${data["id"]}`
            })
            window.location.reload();
          }
        }}
      >
        <div className="card-header-container">
          <img alt="image" src={data.imageUrl} />
        </div>
        <div className="card-body-container">
          <p>{data.name}</p>
          {
            token ?
              <>
                <p style={{ color: "#093776" }}>₹ {priceWithGst}</p>
                <label style={{ fontSize: '12px', color: "#093776" }}>₹ {priceWithoutGST} + {gstPercentage}% GST</label>
              </>
              : <p style={{ color: "#093776" }}>{data.supplierName}</p>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(ProductCard);
