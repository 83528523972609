import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DeleteFilled, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import LayoutComponent from '../layoutComponents/layoutComponent';
import {
	Row, Col, Input, Button, message, Drawer, Select, Switch, Tooltip,
	Form, Modal, Skeleton, Radio, Checkbox, Spin, InputNumber
} from 'antd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import AuthService from '../../service/authService';
import { setVacationMode } from '../../actions/index';
const { Option } = Select;

class AccountProfileComponent extends Component {

	formRef = React.createRef()
	bankRef = React.createRef()
	gstRef = React.createRef()
	upiRef = React.createRef()
	verifyBankRef = React.createRef()
	minValueRef = React.createRef()

	constructor(props) {
		super(props);
		this.isSeller = props.currentUser.account.type === 'Seller' ? true : false;
		this.state = {
			addressData: [],
			states: [],
			loading: false,
			visibleAddAddressDrawer: false,
			showBankModal: false,
			bankDetailsData: [],
			addressId: '',
			gstDetailsData: [],
			showGSTModal: false,
			addressLine1Data: '',
			showVerifyBankModal: false,
			selectedBankId: '',
			onVacation: false,
			showMinValueAmountModal: false,
			minOrderValue: 0,
			modalLoader: false,
			minOrderAmount: 0,
			showLabelInput: false,
			labelValue: '',
			bankDetailType: 'upi'
		}
	}

	componentDidMount() {
		this.getAddressDetails();
		this.getBankDetails();
		this.getGSTAccountDetails();
		this.loadAccountDetails()
		this.setState({
			onVacation: !this.props.currentUser.account.isAcceptingOrder || false
		});
	}

	loadAccountDetails = () => {
		AuthService.getAccountDetails().then((res) => {
			let { data } = res.data
			this.setState({ minOrderAmount: data.minimumOrderValue })
		})
	}

	// componentDidUpdate = (prevProps) => {
	// 	if(prevProps?.currentUser?.account?.isAcceptingOrder !== this.props?.currentUser?.account?.isAcceptingOrder) {
	// 		this.setState({
	// 			onVacation: !this.props.currentUser?.account?.isAcceptingOrder || false
	// 		})
	// 	}
	// }

	getAddressDetails = () => {
		AuthService.getAddressLists().then((res) => {
			let { data } = res.data
			this.setState({ addressData: data })
		},
			(err) => {
				//console.log(err)
			})
	}

	getStates = () => {
		AuthService.getStates()
			.then((res) => {
				this.setState({ states: res.data.data })
			}, err => {
				//console.log(err)
			})
	}

	getBankDetails = () => {
		AuthService.getAllBanksList().then((res) => {
			let { data } = res.data
			this.setState({ bankDetailsData: data }, () => {
			})
		},
			(err) => {
				//console.log(err)
			})
	}

	getGSTAccountDetails = async () => {
		const { t } = this.props
		let { data } = await AuthService.getAllGSTAccounts()
		if (data.isSuccess) {
			this.setState({ gstDetailsData: data.data })
		} else {
			return message.error(`${t('Error in fetching gst details')}`)
		}
	}

	showDrawer = () => {
		this.setState({ labelValue: '', visibleAddAddressDrawer: true, showLabelInput: false }, () => { this.getStates() })
	}

	onFinish = async (values) => {
		const { t } = this.props
		const { addressId } = this.state
		values['country'] = "India";
		const regExp = /[a-zA-Z]/g;
		const specialChars = /[`!@#$%^&*()_+\=\[\]{};'"\\|<>\?~]/;

		values.label = values.label === 'Other' ? this.state.labelValue : values.label

		if (values && values.addressLine1 && !regExp.test(values.addressLine1)) {
			return message.error(`${t('AddressLine1 should contain alphabets also')}`)
		
	} else if(specialChars.test(values.addressLine1) || specialChars.test(values.addressLine2)){
		return message.error(`${t("Special characters are not allowed")}`)
	  }

		this.setState({ loading: true })

		if (addressId) {
			let { data } = await AuthService.updateAddressDetails(values, addressId)
			if (data.isSuccess) {
				message.success('Address updated successfully')
				this.formRef.current.resetFields()
				this.getAddressDetails()

				this.setState({
					visibleAddAddressDrawer: false, loading: false,
					addressId: null, addressLine1Data: ''
				})
			}
			else {
				message.error(`${t('Error in updation')}`)
			}

		} else {
			let { data } = await AuthService.addAddress(values)
			if (data.isSuccess) {
				message.success(`${t('Address added successfully')}`)
				this.formRef.current.resetFields()
				this.getAddressDetails()
				this.setState({ visibleAddAddressDrawer: false, loading: false })
			}
			else {
				this.setState({ loading: false })
				message.error(`${t('Error in saving')}`)
			}
		}
	}

	closeDrawer = () => {
		this.formRef.current.resetFields()
		this.setState({ visibleAddAddressDrawer: false, addressId: null, addressLine1Data: '' })
	}

	handleBankModalCancel = () => {
		this.setState({ showBankModal: false })
	}

	handleVerifyBankModalCancel = () => {
		this.verifyBankRef.current.resetFields();
		this.setState({ showVerifyBankModal: false })
	}

	verifyPayment = async (values) => {
		const { t } = this.props
		let { data: res } = await AuthService.verifyBank(values, this.state.selectedBankId);    //-> data = {isSuccess: ..., data: ..., applicationCode: ... }
		if (res.applicationCode === 200) {
			this.getBankDetails();
			this.verifyBankRef.current.resetFields();
			this.setState({
				showVerifyBankModal: false,
				selectedBankId: '',
				loading: false
			})
		} else {
			this.verifyBankRef.current.resetFields();
			message.error(`${t('Entered amount is incorrect')}`);
			this.setState({
				loading: false
			})
		}
	}

	addBankDetail = (values) => {
		const { t } = this.props
		if (values.reEnterAccountNumber !== values.accountNumber) {
			return alert(`${t('Account number mismatch')}`)
		} else {
			this.setState({ loading: true })
			AuthService.addBankDetails(values)
				.then((res) => {
					if (res && res.data && res.data.applicationCode === 200) {
						this.setState({
							loading: false,
							showBankModal: false,
							selectedBankId: res.headers.bankaccountid,
							showVerifyBankModal: true,
						})
						// let { data } = res.data
						this.bankRef.current.resetFields()
						this.getBankDetails()
					} else {
						this.setState({ loading: false })
						{
							res.data.message ? message.error(res.data.message) : message.error("User does not have permission")
						}

					}

				}, (err) => {
					this.setState({ loading: false })
				});
		}
	}

	deleteAddressData = async (item) => {
		const { t } = this.props
		let { data } = await AuthService.deleteAddress(item.id)
		if (data.isSuccess) {
			this.getAddressDetails()
		}
		else {
			message.error(`${t('Error in deleting address')}`)
		}
	}

	retryAccountValidation = async () => {
		this.setState({ modalLoader: true });
		let res = await AuthService.resendBankAccountValidationAmount(this.state.selectedBankId);
		if (res.data.isSuccess) {
			message.success(res.data['message']);
			this.setState({ modalLoader: false });
		}
		else {
			message.error("User does not have permission");
			this.setState({ modalLoader: false });
		}
	}

	changeDefaultAddressSwitch = (val, id) => {
		let { addressData } = this.state
		const { t } = this.props
		AuthService.updateAddressDefaultStatus(id)
			.then((res) => {
				if (res.data.isSuccess) {
					addressData = _.map(addressData, (item, index) => {
						if (item.id === id) {
							item.isDefault = val
						} else {
							item.isDefault = !val
						}
						return item
					})
					this.setState({ addressData })
				}
				message.success(`${t('Status updated successfully')}`)
			}, err => {
				message.error(`${t('Error in updation')}`)
			})
	}

	changeBankDefaultSwitch = (val, id) => {
		const { t } = this.props
		let { bankDetailsData } = this.state
		AuthService.updateBankDefaultStatus(id)
			.then((res) => {
				if (res.data.isSuccess) {
					bankDetailsData = _.map(bankDetailsData, (item, index) => {
						if (item.id === id) {
							item.isDefault = val
							item.active = true
						} else {
							item.isDefault = !val
						}
						return item
					})
					this.setState({ bankDetailsData })
				}
				message.success(`${t('Status updated successfully')}`)
			}, err => {
				message.error(`${t('Error in updation')}`)
			})
	}

	changeBankActiveStatus = (val, id) => {
		let { bankDetailsData } = this.state
		const { t } = this.props
		// let activeAccount = bankDetailsData.filter((item) => item.active)

		AuthService.updateBankActiveStatus(id, val)
			.then((res) => {
				if (res.data.isSuccess) {
					bankDetailsData = _.map(bankDetailsData, (item, index) => {
						if (item.id === id) {
							item.active = val
						}
						return item
					})
					this.setState({ bankDetailsData })
				}
				message.success(`${t('Status updated successfully')}`)
			}, err => {
				message.error(`${t('Error in updation')}`)
			})
		// if (activeAccount.length > 1) {

		// } else {
		//     message.error('Atleast 1 bank account must be active!')
		// }
	}

	handleGSTModalCancel = () => {
		this.setState({ showGSTModal: false }, () => {
			this.gstRef.current.resetFields()
		})
	}

	handleMinValueModalCancel = () => {
		this.setState({ showMinValueAmountModal: false }, () => {
			this.minValueRef.current.resetFields()
		})
	}

	addGSTDetail = (values) => {
		let temp = values;
		let { name, number } = temp;
		let newName = name.trim();
		let newNumber = number.trim();
		temp = {
			...temp,
			name: newName,
			number: newNumber
		}
		this.setState({ loading: true })
		AuthService.createGSTAccount(temp)
			.then((res) => {
				this.setState({ loading: false, showGSTModal: false })
				this.gstRef.current.resetFields()
				this.getGSTAccountDetails()
			}, (err) => {
				this.setState({ loading: false })
			});
	}

	deleteGSTData = async (item) => {
		const { t } = this.props
		let { data } = await AuthService.deleteGSTAccount(item.id)
		if (data.isSuccess) {
			this.getGSTAccountDetails()
		}
		else {
			message.error(`${t('Error in deleting GST details')}`)
		}
	}

	toggleVacation = () => {
		this.setState({ onVacation: !this.state.onVacation }, () => {
			let acceptingorder = !this.state.onVacation;
			this.props.dispatch(setVacationMode(acceptingorder, (status) => {
				if (status) {
					let notice = `Vacation mode ${acceptingorder ? 'Off !' : 'On !'}`;
					message.success(notice);
				} else {
					this.setState({ onVacation: !acceptingorder });
				}
			}))
		});
	}

	changeDefaultGSTSwitch = (val, id) => {
		let { gstDetailsData } = this.state
		const { t } = this.props
		AuthService.updateGSTDefaultStatus(id)
			.then((res) => {
				if (res.data.isSuccess) {
					gstDetailsData = _.map(gstDetailsData, (item, index) => {
						if (item.id === id) {
							item.isDefault = val
						} else {
							item.isDefault = !val
						}
						return item
					})
					this.setState({ gstDetailsData })
				}
				message.success(`${t('Status updated successfully')}`)
			}, err => {
				message.error(`${t('Error in updation')}`)
			})
	}

	addMinOrderValue = (values) => {
		const { t } = this.props
		let minVal = values && values.MinimumOrderValue && Number(values.MinimumOrderValue)

		if (!(minVal > 0) || minVal === '') {
			return message.error(`${t('Enter valid minimum order value')}`)
		}

		let obj = { MinimumOrderValue: minVal }
		this.setState({ loading: true })
		AuthService.updateMinOrderValue(obj)
			.then((res) => {
				let { data } = res.data
				this.setState({ minOrderAmount: data.minimumOrderValue, loading: false, showMinValueAmountModal: false })
				this.minValueRef.current.resetFields()
			}, (err) => {
				this.setState({ loading: false })
			});
	}


	checkLabelValue = (val) => {
		console.log(val)
		if (val === 'Home' || val === 'Office') {
			this.setState({ showLabelInput: false })
		} else {
			this.setState({ showLabelInput: true })
		}
	}

	addUPIDetail = (values) => {
		console.log(values)
		this.setState({ loading: true })
		let data = { 'vpa': values.upi }
		AuthService.createUPIAccount(data)
			.then((res) => {
				if (res && res.data && res.data.applicationCode === 200) {
					this.setState({
						loading: false,
						showBankModal: false,
						selectedBankId: res.headers.bankaccountid,
						showVerifyBankModal: true,
					})
					this.upiRef.current.resetFields()
					this.getBankDetails()
				} else {
					this.setState({ loading: false })
					{
						res.data.message ? message.error(res.data.message) : message.error("User does not have permission")
					}

				}

			}, (err) => {
				this.setState({ loading: false })
			});

	}

	changeBankDetailType = (val) => {
		this.setState({ bankDetailType: val })
	}

	render() {
		const {
			addressData, states, loading, visibleAddAddressDrawer, showBankModal,
			bankDetailsData, addressId, gstDetailsData, showGSTModal,
			showVerifyBankModal, showMinValueAmountModal, minOrderAmount,
			modalLoader, addressLine1Data, showLabelInput, labelValue, bankDetailType
		} = this.state

		console.log(bankDetailsData)
		const { t } = this.props
		const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
		const layout = {
			labelCol: { span: 8, offset: 4 },
			wrapperCol: { span: 16, offset: 4 },
		};
		return (<>
			<LayoutComponent title={t('Account settings')}>
				<Row>
					{
						this.isSeller
							? <Col span={24}>
								<div className='outer_card'>
									<div className='heading'>
										<h2>{t('My vacation')}</h2>
									</div>
									<div className='spacer'></div>
									<div className='profile_card'>
										<div className='d-flex justify-content-between align-items-center'>
											<p>{t('On vacation')}</p>
											<div className='switch'>
												<Switch
													checked={this.state.onVacation}
													onChange={() => this.toggleVacation()} />
											</div>
										</div>
									</div>
								</div>
							</Col>
							: null
					}

					{
						this.isSeller
							? <Col span={24}>
								<div className='outer_card'>
									<div className='profile_card'>
										<Row>
											<Col span={11}><p className="mb-0">{t('Minimum order value')}:</p></Col>
											<Col span={11}><h4 className="mb-0">₹{minOrderAmount}/-</h4></Col>
											<Col span={2} className="text-right">
												<span className="pointer_Cursor mr-3"
													onClick={() => {
														this.setState({
															showMinValueAmountModal: true
														}, () => {
															this.minValueRef.current.setFieldsValue({ MinimumOrderValue: minOrderAmount.toString() })
														})
													}}><EditOutlined />
												</span>
											</Col>
											<Col>
												<b>{t('What minimum order value')} ?</b>
												<p className="mb-0">{t('Min price message')}</p>
											</Col>
										</Row>
									</div>
								</div>
							</Col>
							: null
					}

					<Col span={24}>
						<div className='outer_card'>
							<div className='heading'>
								<h2>
									{t('My addresses')}
								</h2>
								<div className='spacer'></div>
								<button onClick={(e) => {
									if (addressData.length === 10) {
										return message.warning(`${t('You cannot add more than 10 Address details')}`)
									} else {
										this.showDrawer()
									}
								}} className='card_button'>
									{t('Add')}
								</button>
							</div>
							{addressData && addressData.length > 0 ? addressData.map((item, index) => {
								return (<div className='profile_card' key={index}>
									<div className='heading'>
										<h2 className="pl-1">{item.label}</h2>
									</div>
									<div>
										<Row>
											<Col span={24}>
												<p className='label pl-3'>{item.addressLine1},</p>
												<p className='label pl-3'>{item.addressLine2}</p>
												<p className='label pl-3'>{item.city}</p>
												<p className='label pl-3'>{item.stateName} - {item.zipCode}</p>
											</Col>
											<hr />
											<Col span={24}>
												<div className='heading'>
													<div style={{
														display: 'flex', width: '100%', flexDirection: 'row',
														alignItems: 'center', justifyContent: 'space-between'
													}}>
														<div>
															{!item.isDefault ?
																<div className='switch mr-3'>
																	<div className='text-center'>
																		<Checkbox
																			checked={item.isDefault}
																			onChange={(e) => this.changeDefaultAddressSwitch(e, item.id)}>
																			{t('Set default')}
																		</Checkbox>
																	</div>
																</div> : <p style={{
																	color: '#cacaca', fontStyle: 'italic',
																	fontSize: '12px', marginBottom: '0px'
																}}>{t('Default address')}</p>}
														</div>

														<div>
															<span className="mr-3 pointer_Cursor"
																onClick={() => {
																	this.setState({
																		visibleAddAddressDrawer: true,
																		addressId: item.id,
																		addressLine1Data: item.addressLine1
																	}, () => {
																		this.getStates()

																		const label = item?.label
																		console.log(label)
																		if (label === undefined) {
																			this.formRef.current.setFieldsValue({ label: 'Home' })

																		} else if (label === 'Home' || label === 'Office') {
																			this.setState({ showLabelInput: false })
																		} else {
																			this.setState({ showLabelInput: true, labelValue: label })
																			this.formRef.current.setFieldsValue({ label: 'Other' })
																		}
																		this.formRef.current.setFieldsValue({
																			addressLine1: item && item.addressLine1 ? item.addressLine1 : '',
																			addressLine2: item && item.addressLine2 ? item.addressLine2 : '',
																			city: item && item.city ? item.city : '',
																			zipCode: item && item.zipCode ? item.zipCode : '',
																			stateId: item && item.stateId
																		})
																	})

																}}><EditOutlined />
															</span>

															{!item.isDefault ?
																<span className="pointer_Cursor" onClick={() => {
																	this.deleteAddressData(item)
																}}><DeleteFilled /></span>
																: null}
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</div>
								</div>)
							}) : (addressData.length === 0 ? <div className='profile_card'>{t('No address Found')}</div> : <Skeleton active />)}

						</div>
					</Col>

					<Col span={24}>
						<div className='outer_card'>
							<div className='heading'>
								<h2>
									{t('Bank details')}
								</h2>
								<div className='spacer'></div>
								<button className='card_button' onClick={(e) => {
									if (bankDetailsData.length === 10) {
										return message.warning(`${t('You cannot add more than 10 Bank accounts')}`)
									} else {
										this.setState({ showBankModal: true })
									}
								}}>
									{t('Add')}
								</button>
							</div>

							{bankDetailsData && bankDetailsData.length > 0 ? bankDetailsData.map((item, bIndex) => {
								return (<div key={bIndex} className='profile_card'>
									{item.upi ? <>
										<Row>
											<Col span={10}><p>UPI:</p></Col>
											<Col span={10}><h4>{item.upi}</h4></Col>
											<Col span={4}>
												{!item.isDefault ?
													<div className='switch'>
														<Checkbox
															checked={item.isDefault}
															onChange={(e) => this.changeBankDefaultSwitch(e, item.id)}>
															{t('Set default')}
														</Checkbox>
													</div>
													: <p style={{
														color: '#cacaca', fontStyle: 'italic',
														fontSize: '12px', marginBottom: '0px'
													}}>{t('Default account')}</p>
												}
											</Col>

											<Col span={20}>
												{
													item.status === 'Pending'
														? <Button onClick={() => {
															this.setState
																({
																	selectedBankId: item.id,
																	showVerifyBankModal: true,
																})
														}} type='primary'>{t('Verify account now')}</Button>
														: <p>{t('Bank verified')}</p>
												}
											</Col>
											<Col span={4}>
												{!item.isDefault ?
													<div className='switch'>
														<Tooltip title={item.active ? `${t('Set as Inactive')}` : `${t('Set as Active')}`}>
															<Switch checked={item.active}
																onChange={(e) => this.changeBankActiveStatus(e, item.id)} />
															<p style={{ fontSize: '10px', color: '#cacaca' }}>{item.active ? `${t('Active')}` : `${t('Inactive')}`}</p>
														</Tooltip>
													</div> : null}
											</Col>
										</Row>
									</> : <Row>
										<Col span={10}><p>{t('Bank name')}:</p></Col>
										<Col span={10}><h4>{item.bankName ? item.bankName : ''}</h4></Col>
										<Col span={4}>
											{!item.isDefault ?
												<div className='switch'>
													<Checkbox
														checked={item.isDefault}
														onChange={(e) => this.changeBankDefaultSwitch(e, item.id)}>
														{t('Set default')}
													</Checkbox>
												</div>
												: <p style={{
													color: '#cacaca', fontStyle: 'italic',
													fontSize: '12px', marginBottom: '0px'
												}}>{t('Default account')}</p>
											}
										</Col>

										<Col span={10}><p>{t('Holder name')}:</p></Col>
										<Col span={10}><h4>{item.holderName ? item.holderName : ''}</h4></Col>

										<Col span={10}><p>{t('Account number')}:</p></Col>
										<Col span={14}><h4>{item.accountNumber ? item.accountNumber : ''}</h4></Col>

										<Col span={10}><p>{t('IFSC code')}:</p></Col>
										<Col span={10}><h4>{item.ifscCode ? item.ifscCode : ''}</h4></Col>

										<Col span={4}>
											{!item.isDefault ?
												<div className='switch'>
													<Tooltip title={item.active ? `${t('Set as Inactive')}` : `${t('Set as Active')}`}>
														<Switch checked={item.active}
															onChange={(e) => this.changeBankActiveStatus(e, item.id)} />
														<p style={{ fontSize: '10px', color: '#cacaca' }}>{item.active ? `${t('Active')}` : `${t('Inactive')}`}</p>
													</Tooltip>
												</div> : null}
										</Col>

										<Col span={24}>
											{
												item.status === 'Pending'
													? <Button onClick={() => {
														this.setState
															({
																selectedBankId: item.id,
																showVerifyBankModal: true,
															})
													}} type='primary'>{t('Verify account now')}</Button>
													: <p>{t('Bank verified')}</p>
											}
										</Col>
									</Row>}
								</div>)
							}) : (bankDetailsData.length === 0 ? <div className='profile_card'>{t('No bank details found')}</div> : <Skeleton active />)}

						</div>
					</Col>

					<Col span={24}>
						<div className='outer_card'>
							<div className='heading'>
								<h2>{t('GST details')}</h2>
								<div className='spacer'></div>
								<button className='card_button' onClick={(e) => {
									if (gstDetailsData.length === 10) {
										return message.warning(`${t('You cannot add more than 10 GST accounts')}`)
									} else {
										this.setState({ showGSTModal: true })
									}
								}}>
									{t('Add')}
								</button>
							</div>

							{gstDetailsData && gstDetailsData.length > 0 ? gstDetailsData.map((item, gIndex) => {
								return (<div key={gIndex} className='profile_card'>
									<Row>
										<Col span={10}><p>{t('GST name')}:</p></Col>
										<Col span={14}><h4>{item.name ? item.name : ''}</h4></Col>

										<Col span={10}><p>{t('GST number')}:</p></Col>
										<Col span={14}><h4>{item.number ? item.number : ''}</h4></Col>

										<Col span={10}><p>{t('Address')}:</p></Col>
										<Col span={14}>
											<h4>
												{`${item.address.addressLine1}, ${item.address.addressLine2} ${item.address.city} ${item.address.stateName} ${item.address.zipCode}`}</h4>
										</Col>
										<Col span={24}>
											<div className='heading'>
												<div className='spacer'></div>
												<>
													<br />
													<div className='switch mr-3'>
														<div className='text-center'>
															{!item.isDefault ?
																<div className='switch'>
																	<Checkbox
																		checked={item.isDefault}
																		onChange={(e) => this.changeDefaultGSTSwitch(e, item.id)}>
																		{t('Set default')}
																	</Checkbox>
																</div>
																: <p style={{
																	color: '#cacaca', fontStyle: 'italic',
																	fontSize: '12px', marginBottom: '0px'
																}}>{t('Default GST Number')}</p>
															}
														</div>
													</div>
													{!item.isDefault ?
														<span className="pointer_Cursor"
															onClick={() => {
																this.deleteGSTData(item)
															}}><DeleteFilled /></span>
														: null}
												</>
											</div>
										</Col>
									</Row>
								</div>)
							}) : (gstDetailsData.length === 0 ? <div className='profile_card'>{t('No GST Details Found')}</div> : <Skeleton active />)}

						</div>
					</Col>
				</Row>
			</LayoutComponent>

			<Modal
				title={t("Verify bank details")}
				visible={showVerifyBankModal}
				onCancel={this.handleVerifyBankModalCancel}
				footer={null}
				centered={true}
				maskClosable={false}
			>
				<div>
					<Form ref={this.verifyBankRef}
						layout={'vertical'}
						scrollToFirstError
						onFinishFailed={this.onFinishFailed}
						onFinish={this.verifyPayment}
						requiredMark={false}>
						<Row>
							<Col span={24}>
								<Form.Item
									label={`${t('Verify bank details')}*`}
									name="verifyAmount"
									rules={[{
										required: true,
										whitespace: true,
										message: `${t('Please enter debited amount reqd validation')}`
									}]}
								>
									<Input type="number" placeholder={t('Verify bank details')}
										onChange={(val) => { }} />
								</Form.Item>
								<p style={{ color: '#636363', fontSize: 12 }}>Note: A small amount between Rs 1 and Rs 2 is credited to your account. Please enter that amount to verify the bank details.</p>
								<p style={{ color: '#636363', fontSize: 12 }}>
									नोट: 1 रुपये से 2 रुपये के बीच की एक छोटी राशि आपके खाते में जमा की जाती है। बैंक विवरण सत्यापित करने के लिए कृपया वह राशि दर्ज करें।
								</p>
							</Col>
							<Col span={24}>
								<Form.Item>
									<div>
										{modalLoader ? <Spin indicator={antIcon} size={'small'} /> :
											<a className='card_button'
												onClick={() => this.retryAccountValidation()}
											>{t('Resend amount')}</a>
										}
									</div>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item>
									<Button
										htmlType='submit'
										className={'submitBtn'}
										loading={this.state.loading}
										type="primary"
									>{t('Verify')}</Button>
									<Button
										className={'cancelBtn'}
										type="default"
										onClick={this.handleVerifyBankModalCancel}
									>{t('Skip')}</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>

			<Modal
				title={t("Add bank details")}
				visible={showBankModal}
				onCancel={this.handleBankModalCancel}
				footer={null}
				centered={true}
				maskClosable={false}
			>
				<>
					<Row>
						<Col span={24}>
							<Select defaultValue="upi" className='ant-col ant-col-24' onChange={(val) => this.changeBankDetailType(val)}>
								<Option value='upi'>Add UPI</Option>
								<Option value='bank'>Add Bank Detail</Option>
							</Select>
						</Col>
					</Row>
					<div className='mt-4'>
						{bankDetailType == 'upi' ?

							<Form ref={this.upiRef}
								layout={'vertical'}
								scrollToFirstError
								onFinishFailed={this.onFinishFailed}
								onFinish={this.addUPIDetail}
								requiredMark={false}>
								<Row>
									<Col span={24}>
										<Form.Item
											label='Your UPI Id'
											name="upi"
											rules={[
												{
													required: true, whitespace: true,
													message: 'Please input your UPI id ',
												},
											]}
										>
											<Input placeholder={t('UPI Id')} />
										</Form.Item>
									</Col>

									<Col span={24}>
										<Form.Item>
											<Button
												htmlType='submit'
												className={'submitBtn'}
												loading={this.state.loading}
												type="primary"
											>{t('Save')}</Button>
											<Button
												className={'cancelBtn'}
												type="default"
												onClick={this.handleBankModalCancel}
											>{t('Cancel')}</Button>
										</Form.Item>
									</Col>
								</Row>
							</Form> :
							<Form ref={this.bankRef}
								layout={'vertical'}
								scrollToFirstError
								onFinishFailed={this.onFinishFailed}
								onFinish={this.addBankDetail}
								requiredMark={false}>
								<Row>
									<Col span={24}>

										<Form.Item
											label={`${t('Account holder name')}*`}
											name="holderName"
											rules={[{
												required: true, whitespace: true,
												message: `${t('Account holder name reqd validation')}`
											},
											{
												min: 4,
												message: `${t('Username must be minimum 4 characters')}`
											},
											{
												max: 50,
												message: `${t('Username must not exceed 50 characters')}`
											}
											]}
										>
											<Input type="text" placeholder={t('Account holder name')}
												onChange={(val) => {
												}} />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item
											label={`${t("IFSC code")}*`}
											name="iFSCCode"
											rules={[
												{
													required: true, whitespace: true,
													message: `${t('IFSC code reqd validation')}`
												}, {
													len: 11,
													message: `${t('IFSC Code must be 11-digit')}`
												}

											]}
										>
											<Input placeholder={t("IFSC code")} />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item
											label={`${t('Account number')}*`}
											name="accountNumber"
											rules={[
												{
													required: true, whitespace: true,
													message: `${t('Account no reqd validation')}`
												}
											]}
										>
											<Input placeholder={t('Account number')} />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item
											type="password"
											label={`${t('Re enter account number')}*`}
											name="reEnterAccountNumber"
											rules={[
												{
													required: true, whitespace: true,
													message: `${t('Re-enter account no reqd validation')}`
												}
											]}
										>
											<Input type="password" placeholder={t('Account number')} />
										</Form.Item>
									</Col>
									<Col span={24}>
										<Form.Item>
											<Button
												htmlType='submit'
												className={'submitBtn'}
												loading={this.state.loading}
												type="primary"
											>{t('Save')}</Button>
											<Button
												className={'cancelBtn'}
												type="default"
												onClick={this.handleBankModalCancel}
											>{t('Cancel')}</Button>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						}
					</div>
				</>
			</Modal>

			<Modal
				title={t("Add GST details")}
				visible={showGSTModal}
				onCancel={this.handleGSTModalCancel}
				footer={null}
				centered={true}
				width="600px"
				maskClosable={false}
			>
				<div>
					<Form ref={this.gstRef}
						layout={'vertical'}
						scrollToFirstError
						onFinishFailed={this.onFinishFailed}
						onFinish={this.addGSTDetail}
						requiredMark={false}>
						<Row>
							<Col span={24}>
								<Form.Item
									label={`${t('Choose address')}*`}
									name="addressId"
									rules={[{
										required: true,
										message: `${t('Choose address reqd validation')}`
									}]}
								>
									<Radio.Group
										className='radio_group_gst'>
										{addressData && addressData.length > 0 ? addressData.map((item, aKey) => {
											return (<Radio key={aKey}
												value={item.id}>
												{`${item.addressLine1}, ${item.addressLine2}, ${item.city} ${item.stateName} ${item.zipCode}`}
											</Radio>)
										}) : null}

									</Radio.Group>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label={`${t('GST name')}*`}
									name="name"
									rules={[
										{
											required: true, whitespace: true,
											message: `${t('Gst name reqd validation')}`,
										},
										{
											pattern: new RegExp(/^[a-zA-Z][a-zA-Z ]*$/),
											required: true,
											message: `${t('GST Name must contain alphabets only')}`
										}
									]}
								>
									<Input placeholder={t('GST name')} />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item
									label={`${t('GST number')}*`}
									name="number"
									rules={[
										{
											required: true, whitespace: true,
											message: `${t('GST Number reqd validation')}`
										},
										{
											pattern: new RegExp(/^[a-zA-Z0-9]*$/),
											required: true,
											message: `${t('GST Number must be a combination of alphabets and numbers only')}`
										}
									]}
								>
									<Input placeholder={t('GST number')}
										onChange={(e) => {
											this.gstRef.current.setFieldsValue({
												number: e.target.value.toUpperCase()
											})
										}} />
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item>
									<Button
										htmlType='submit'
										className={'submitBtn'}
										loading={this.state.loading}
										type="primary"
									>{t('Save')}</Button>
									<Button
										className={'cancelBtn'}
										type="default"
										onClick={this.handleGSTModalCancel}
									>{t('Cancel')}</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>

			<Modal
				title={t("Add minimum order value")}
				visible={showMinValueAmountModal}
				onCancel={this.handleMinValueModalCancel}
				footer={null}
				centered={true}
				width="600px"
				maskClosable={false}
			>
				<div>
					<Form ref={this.minValueRef}
						layout={'vertical'}
						onFinish={this.addMinOrderValue}
						requiredMark={false}>
						<Row>
							<Col span={24}>
								<Form.Item
									label={`${t("Minimum order value")}*`}
									name="MinimumOrderValue"
									rules={[
										{
											required: true, whitespace: true,
											message: `${t('Minimum order value reqd validation')}`
										}
									]}
								>
									<Input type="number" placeholder={t("Enter minimum order value")} />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item>
									<Button
										htmlType='submit'
										className={'submitBtn'}
										loading={this.state.loading}
										type="primary"
									>{t('Save')}</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Modal>

			<Drawer title={addressId ? `${t('Edit address details')}` : `${t('Add new address')}`}
				placement={'bottom'}
				onClose={this.closeDrawer}
				visible={visibleAddAddressDrawer}
				maskClosable={false}
				footer={null}
				height={'80vh'}
				key={'3'}>
				<div className={'addressDrawer'}>
					<Form {...layout}
						requiredMark={false}
						onFinish={this.onFinish}
						ref={this.formRef}
						scrollToFirstError
						name="horizontal_address_new"
						layout="vertical"
					>
						<Row>
							<Col span={24}>
								<Form.Item label={`${t('Address label')}*`}
									rules={[
										{
											required: true,
											whitespace: true,
											message: `${t('Address label reqd validation')}`
										}
									]} name="label"
									style={{ marginBottom: `${showLabelInput ? '5px' : '24px'}` }}>

									<Select placeholder='Please select the label' onChange={(val) => this.checkLabelValue(val)}>
										<Option value="Home">Home</Option>
										<Option value="Office">Office</Option>
										<Option value="Other">Other</Option>
									</Select>

								</Form.Item>

								{showLabelInput ?
									<Col span={24}>
										<div className="ant-col ant-col-16 ant-col-offset-4 ">
											<Input style={{ marginBottom: '24px' }} defaultValue={labelValue} placeholder={t('Home_office')}
												onChange={(e) => this.setState({ labelValue: e.target.value })} />
										</div>
									</Col>
									: null
								}
							</Col>

							<Col span={24}>
								<Form.Item rules={[
									{
										required: true,
										message: `${t('Address line 1 reqd validation')}`,
										whitespace: true,
									},
									{
										pattern: new RegExp(/^([a-zA-Z\-_/,. ]*)+\d+[a-zA-Z0-9\-_/,. ]*/g),
										required: true,
										message: `${t('Address Line 1 combination validation')}`
									},
									{
										min: 10,
										message: `${t('Address Line 1 length10 validation')}`
										// validateTrigger: 'onSubmit'
									},
									{
										max: 50,
										message: `${t('Address Line 1 length50 validation')}`
									},
									

								]}
                                validateTrigger="onBlur"
								 name="addressLine1"
									label={`${t('Address line 1')}*`}>
									<Tooltip
										title={t('Address line 1 tooltip msg')}>
										<Input value={addressLine1Data}
											placeholder={t('Building No_colony')}
											onChange={(e) => {
												this.setState({
													addressLine1Data: e.target.value
												})
												this.formRef.current.setFieldsValue({ addressLine1: e.target.value })
											}}
										/>
									</Tooltip>
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item rules={[
									{
										required: true,
										message: `${t('Address line 2 reqd validation')}`,
										whitespace: true
									},
									{
										min: 10,
										message: `${t('Address Line 2 length10 validation')}`
										
									},
									{
										max: 50,
										message: `${t('Address Line 2 length50 validation')}`
									},
								]}
                                validateTrigger="onBlur"
								 name="addressLine2" label={`${t('Address line 2')}*`}>
									<Input placeholder={t('Area_District')} />
								</Form.Item>
							</Col>
							<Col span={24}>
								<Form.Item rules={[
									{
										required: true,
										message: `${t('City reqd validation')}`,
										whitespace: true
									}
								]} name="city" label={`${t('City')}*`}>
									<Input placeholder={t('City')} />
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item
									name="stateId"
									label={`${t('State')}*`}
									rules={[
										{
											required: true,
											message: `${t('State reqd validation')}`,
										}
									]}
								>
									<Select
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}

										// onInputKeyDown={(e) => {
										//     e.stopPropagation();
										//      e.preventDefault();
										// }}

										placeholder={t('Select state')}  >
										{states && states.map((item, skey) => {
											return (
												<Option key={skey}
													value={item.id}>{item.name}</Option>
											)
										})}
									</Select>
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item
									rules={[
										{
											required: true,
											message: `${t('Pincode reqd validation')}`,
											whitespace: true,

										},
										{
											len: 6,
											message: `${t('Invalid length pincode')}`,
										}

									]}

									name="zipCode" label={`${t('PinCode')}*`}>
									<Input type="number"
										placeholder={t('6 digit pincode')} />
								</Form.Item>
							</Col>

							<Col span={24}>
								<Form.Item >
									<Button className='my-button'
										loading={loading}
										htmlType="submit" >
										{addressId ? `${t("Update")}` : `${t("Save")}`}
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
			</Drawer>
		</>
		)
	}
}

const mapStateToProps = ({ global }) => {
	return ({
		currentUser: global.currentUser,
	})
}

const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}

export default withTranslation()(connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AccountProfileComponent)));