const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
const cloudfrontBaseUrl = process.env.REACT_APP_CLOUD_FRONT_BASE_URL;

const MediaUrl = {

    completeMediaUrl(path, isImage, height, width) {
        if (path) {
            if (path.includes('https' || 'http')) {
                return path;
            } else if (path.includes('?')) {
                return baseUrl + path
            } else {
                if (isImage) {
                    return cloudfrontBaseUrl + path;
                } else {
                    return cloudfrontBaseUrl + path;
                }
            }
        }
    },

    trimMediaUrl(path) {
        if (path) {
            if (path.includes(baseUrl)) {
                path = path.replace(baseUrl, "")
            }
            if (path.includes(cloudfrontBaseUrl)) {
                path = path.replace(cloudfrontBaseUrl, "")
            }
            if (path.includes('?')) {
                path = path.split('?')[0]
            }
            return path
        }
    }
}


export default MediaUrl;