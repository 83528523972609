import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col, message, Result, Skeleton, Button, Modal, Input, Form } from 'antd';
import queryString from 'query-string';
import { CheckOutlined } from '@ant-design/icons';
import CartService from '../../service/cart'
import AuthService from '../../service/authService'
import { connect } from 'react-redux';
import './index.scss'
import { values } from 'lodash';
import { Collapse } from "antd";
const { Panel } = Collapse;

const src = "https://checkout.razorpay.com/v1/checkout.js"
const RazorPayKey = process.env.REACT_APP_RAZORPAY_KEY_ID;
const paymentOptions = [
  { type: "Wallet", value: "wallet", isLoading: false },
  { type: "Net Banking", value: "netbanking", isLoading: false },
  { type: "UPI", value: "upi", isLoading: false },
  { type: "Debit/Credit Card", value: "card", isLoading: false },
  { type: "Pay Later", value: "paylater", isLoading: false },
];

class PaymentComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartData: {},
      addressDetail: {},
      isCOD: false,
      isPrepaid: true,
      isReturnable: false,
      paymentMethods: [],
      paymentItem: {},
      makingPayment: false,
      showReturnableWarning: false,
      codWarning: false,
      visible: false,
      SucessModalVisible: false,
      orderDetails: {},
      gstAccountNumber: "",
      gstAccountId: 0,
      invalidPincode: false,
      refId: "",
      partialPayment: {},
      isModalOpen: false,
      partialPaymentItem: {},
      newRozarPayId: "",
      newRozarPayObject: {},
      partialPaymentOption: {},
    };
    this.razorPayOrderObject = null;
  }

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({
      gstAccountNumber: values.gstNumber,
      gstAccountId: values.gstId,
    });
    if (values && values.shoppingCartToken) {
      this.getCartData(values.shoppingCartToken);
      this.getAddressDetails(values.shipToAddressId);
      this.getPaymentOptions(values);
    }
    if (values && values.orderCustomId) {
      this.getOrderDetails(values.orderCustomId);
      this.getPaymentOptions(values);
    }
    this.loadScript(src);
  }

  getPaymentOptions = async (values) => {
    let { paymentMethods, partialPaymentOption } = this.state;
    let obj = {};

    if (values && values.orderCustomId) {
      obj.orderCustomId = values.orderCustomId;
    }
    if (values && values.shoppingCartToken && values.shipToAddressId) {
      obj.cartToken = values.shoppingCartToken;
      obj.shipToAddressId = values.shipToAddressId;
    }

    let { data: paymentOptn } = await CartService.getAllPaymentOption(obj);
    if (paymentOptn.isSuccess) {
      let { data } = paymentOptn;
      if (data && data.courierName) {
        if (data.isCOD) {
          paymentMethods.unshift({
            isCOD: data.isCOD,
            type: "Cash On Delivery",
            value: "Cash On Delivery",
            isLoading: false,
          });
          this.setState({ isCOD: data.isCOD, paymentMethods });
        }
        if (data.isReturnsLimitExceeded) {
          paymentMethods.unshift({
            isReturnsLimitExceeded: data.isReturnsLimitExceeded,
          });
          this.setState({ paymentMethods });
        }
        if (data.isPrepaid) {
          paymentMethods.push(...paymentOptions);
          this.setState({ isPrepaid: data.isPrepaid, paymentMethods });
        }
        if (data.isReturnable) {
          this.setState({ isReturnable: data.isReturnable });
        }
        if (
          Object.keys(data.partialPayment).length > 0 &&
          data.partialPayment.partialAmount > 0
        ) {
          paymentMethods.push({
            type: "Partial Payment",
            value: "partialpayment",
            isLoading: false,
          });
          this.setState({
            partialPayment: data.partialPayment,
            paymentMethods,
          });
        }
      } else {
        this.setState({
          invalidPincode: true,
        });
      }
    } else {
      message.error(paymentOptn.message);
    }
  };

  getAddressDetails = async (addressId) => {
    let { data: addressResp } = await AuthService.getAddressById(addressId);
    if (addressResp.isSuccess) {
      let { data } = addressResp;
      this.setState({ addressDetail: data });
    } else {
      // message.error(addressResp.message)
    }
  };

  getCartData = async (cartToken) => {
    let { data: cartResp } = await CartService.getCartItemById(cartToken);
    if (cartResp && cartResp.isSuccess) {
      let { data } = cartResp;
      this.setState({ cartData: data });
    } else {
      alert("Payment Interrupted ! You will be redirected to your orders.");
      this.props.history.replace("/your-orders");
    }
  };

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  getOrderDetails = async (id) => {
    const { t } = this.props;
    let x = await CartService.orderById(id);
    if (x.data.isSuccess) {
      let { data } = x.data;
      this.setState({ orderDetails: data });
    } else {
      message.error(`${t("Error in fetching data")}`);
    }
  };

  navigate = (url) => {
    this.props.history.push({ pathname: url });
  };

  proceedToRazorpay = (data, mode) => {
    let self = this;
    var options = {
      key: RazorPayKey,
      amount: data.amount * 100,
      currency: "INR",
      order_id: data.paymentGatewayOrderId,
      handler: async function (response) {
        let { data: verifyResp } = await CartService.verifyPayment(
          response,
          1,
          data.orderId
        );
        if (
          verifyResp &&
          verifyResp.data &&
          verifyResp.data.paymentGatewaySignatureId
        ) {
          window.location = `#/order/success/${data.orderId}`;
        }
      },
      modal: {
        ondismiss: function () {
          self.setState({ makingPayment: false });
        },
      },
      prefill: {
        name: data.customerName,
        email: data.customerEmail,
        contact: data.customerPhoneNumber,
        method: mode,
      },
    };

    var paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", async function (response) {
      let { data: verifyResp } = await CartService.verifyPayment(
        response,
        1,
        data.orderId
      );
      if (
        verifyResp &&
        verifyResp.data &&
        verifyResp.data.paymentGatewaySignatureId === null
      ) {
        //  window.location = `#/order/failure/${verifyResp.data.id}`;
      }
    });

    paymentObject.open();
  };

  retryOrder = async (selectedPaymentMode) => {
    const values = queryString.parse(this.props.location.search);
    const { t } = this.props;
    if (this.razorPayOrderObject !== null) {
      if (selectedPaymentMode.isCOD) {
        let { data: verifyResp } = await CartService.verifyPayment(
          this.razorPayOrderObject.paymentGatewayOrderId,
          0,
          this.razorPayOrderObject.orderId
        );
        if (verifyResp && verifyResp.applicationCode === 200) {
          window.location = `#/order/success/${this.razorPayOrderObject.orderId}?mode=COD`;
        }
      } else {
        await this.proceedToRazorpay(
          this.razorPayOrderObject,
          selectedPaymentMode.value
        );
        this.setState({ makingPayment: false });
      }
    } else {
      let { data: retryOrderResp } = await CartService.retryOrderPayment(
        values.orderCustomId,
        selectedPaymentMode.isCOD ? 0 : 1
      );
      if (retryOrderResp.isSuccess) {
        let { data } = retryOrderResp;

        if (!selectedPaymentMode.isCOD) {
          this.razorPayOrderObject = data;
          await this.proceedToRazorpay(data, selectedPaymentMode.value);
          this.setState({ makingPayment: false });
        } else {
          window.location = `#/order/success/${data.orderId}?mode=COD`;
        }
      } else {
        message.error(`${t("Error in redirection")}`);
      }
    }
  };

  isReturnableCheck = async () => {
    const { t } = this.props;
    const { partialPayment } = this.state;
    let { paymentItem, partialPaymentItem } = this.state;
    if (paymentItem && paymentItem.value) {
      this.setState({ makingPayment: true }); // setting loader true
      const values = queryString.parse(this.props.location.search);
      let { isReturnable } = this.state;

      if (values && values.orderCustomId && !isReturnable) {
      } else if (values && values.orderCustomId && isReturnable) {
        this.retryOrder(paymentItem);
        this.setState({
          showReturnableWarning: false,
        });
      } else if (!isReturnable) {
        // cannot return---- show warning
        this.setState({
          visible: true,
          makingPayment: false,
          showReturnableWarning: true,
          paymentItem,
          partialPaymentItem,
        });
      }
      else {
        if(paymentItem.value!=="partialpayment"){

          await this.placeOrder(paymentItem);
          this.setState({
            showReturnableWarning: false,
          });
        } else {
          await this.placeOrder(partialPaymentItem);
          this.setState({
            showReturnableWarning: false,
          });
        }
        // can return
      }
    } else {
      message.error(`${t("Please select payment type")}`);
    }
  };

  checkIsCod = () => {
    this.setState({ visible: true, codWarning: true });
  };

  getCartItemsData = async () => {
    const { dispatch } = this.props;
    let x = await CartService.getCartItemsData();
    if (x.data.isSuccess) {
      let { data } = x.data;
      if (data.length > 0) {
        dispatch({
          type: "SET_CART_ITEMS_FLAG",
          isExist: true,
        });
      } else {
        dispatch({
          type: "SET_CART_ITEMS_FLAG",
          isExist: false,
        });
      }
    }
  };

  placeOrder = async (selectedPaymentMode) => {
    const { paymentItem } = this.state;
    const { t } = this.props;
    const values = queryString.parse(this.props.location.search);

    if (this.razorPayOrderObject !== null) {
      if (selectedPaymentMode.isCOD) {
        let { data: verifyResp } = await CartService.verifyPayment(
          this.razorPayOrderObject.paymentGatewayOrderId,
          0,
          this.razorPayOrderObject.orderId
        );
        if (verifyResp && verifyResp.applicationCode === 200) {
          window.location = `#/order/success/${this.razorPayOrderObject.orderId}?mode=COD`;
        }
      } else {
        if (paymentItem.value === "partialpayment") {
          const obj = {
            razorpayOrderId: this.razorPayOrderObject.paymentGatewayOrderId,
            paymentType: 2,
          };
          let { data } = await CartService.updatePaymentTypeStatus(obj);
          let res = data.data;
          this.setState({ newRozarPayObject: data.data }, () => {
            this.proceedToRazorpay(res, selectedPaymentMode.value);
          });
        } else {
          const obj = {
            razorpayOrderId: this.razorPayOrderObject.paymentGatewayOrderId,
            paymentType: 1,
          };
          let { data } = await CartService.updatePaymentTypeStatus(obj);
          let res = data.data;

          this.setState({ newRozarPayObject: data.data }, () => {
            this.proceedToRazorpay(res, selectedPaymentMode.value);
          });
        }
      }
    } else {
      let obj = {
        gstAccountId: 1,
        paymentType: selectedPaymentMode.isCOD
          ? 0
          : paymentItem.value == "partialpayment"
            ? 2
            : 1,
        shoppingCartToken: values.shoppingCartToken,
        buyerAddressId: parseInt(values.shipToAddressId),
        buyerGstNumber: this.state.gstAccountNumber,
        referenceId: this.state.refId,

      };
      let { data: orderResp } = await CartService.orderByCartId(obj);
      if (orderResp.isSuccess) {
        let { data } = orderResp;
        if (!selectedPaymentMode.isCOD) {
          this.razorPayOrderObject = data;
          this.proceedToRazorpay(data, selectedPaymentMode.value);
        } else {
          window.location = `#/order/success/${data.orderId}?mode=COD`;
        }

        if (values.shoppingCartToken) {
          this.getCartItemsData();
        }
      } else {
        message.error(`${t("Error in redirection")}`);
      }
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      codWarning: false,
      isModalOpen: false,
    });
  };
  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  render() {
    const {
      cartData,
      addressDetail,
      paymentMethods,
      showReturnableWarning,
      codWarning,
      invalidPincode,
      orderDetails,
      paymentItem,
      partialPayment,
      isModalOpen,
      partialPaymentItem,
      partialPaymentOption,
    } = this.state;

    const { t } = this.props;

    return (
      <LayoutComponent title={t("Payment")}>
        <div className={"payment_container"}>
          <Row>
            <Col xl={12} xs={24}>
              <div>
                {/* {cartData.totalPrice ? <div className={'payment_flexDiv'}>
								<div className={'left font14px'}><b>Total Order Amount</b></div>
								<div className={'right font14px'}><b>₹ {cartData.totalPrice}</b></div>
						</div> : (orderDetails.totalAmount ?
								<div className={'payment_flexDiv'}>
										<div className={'left font14px'}><b>Total Order Amount</b></div>
										<div className={'right font14px'}><b>₹ {orderDetails.totalAmount}</b></div>
								</div> : <Skeleton active />)}
						<hr /> */}

                {cartData.totalPrice ? (
                  <div className={"payment_flexDiv"}>
                    <div className={"left"}>
                      <p className={"label"}>
                        <b>{t("To Pay")}</b>
                      </p>
                      <p className="font10px">
                        {t("Order amount can change based on invoice")}
                      </p>
                    </div>
                    <div className={"right priceColor font14px"}>
                      <b>₹ {cartData.totalPrice + cartData.shippingCharges}</b>
                    </div>
                  </div>
                ) : orderDetails.totalAmount ? (
                  <div className={"payment_flexDiv"}>
                    <div className={"left"}>
                      <p className={"label"}>
                        <b>{t("To Pay")}</b>
                      </p>
                      <p className="font10px">
                        {t("Order amount can change based on invoice")}
                      </p>
                    </div>
                    <div className={"right priceColor font14px"}>
                      <b>₹ {orderDetails.totalAmount}</b>
                    </div>
                  </div>
                ) : (
                  <Skeleton active />
                )}

                <div className={"gstdiv font12px"}>
                  <b>{t("GST Info")}</b>
                </div>

                <div className={"payment_flexDiv mt-3"}>
                  <div className={"left font12px"}>
                    {t("Invoice issued against GSTIN")}:
                  </div>
                  <div className={"right font12px"}>
                    <b>{this.state.gstAccountNumber}</b>
                  </div>
                </div>

                <div className={"gstdiv font12px mt-3"}>
                  <b>{t("Address details")}</b>
                </div>

                {addressDetail.addressLine1 ? (
                  <div className={"payment_flexDiv mt-3"}>
                    <div className={"left font12px"}>
                      <div className="heading">
                        <h2>{addressDetail ? addressDetail.label : ""}</h2>
                      </div>
                      <p className="label">
                        {addressDetail ? addressDetail.addressLine1 : ""},
                      </p>
                      <p className="label">
                        {addressDetail ? addressDetail.addressLine2 : ""},
                      </p>
                      <p className="label">
                        {addressDetail ? addressDetail.city : ""}
                      </p>
                      <p className="label">
                        {addressDetail ? addressDetail.stateName : ""} -{" "}
                        {addressDetail ? addressDetail.zipCode : ""}
                      </p>
                    </div>
                  </div>
                ) : orderDetails.deliveryOrderAddress ? (
                  <div className={"payment_flexDiv mt-3"}>
                    <div className={"left font12px"}>
                      <div className="heading">
                        <h2>
                          {orderDetails.deliveryOrderAddress
                            ? orderDetails.deliveryOrderAddress.label
                            : ""}
                        </h2>
                      </div>
                      <p className="label">
                        {orderDetails.deliveryOrderAddress
                          ? orderDetails.deliveryOrderAddress.addressLine1
                          : ""}
                      </p>
                      <p className="label">
                        {orderDetails.deliveryOrderAddress
                          ? orderDetails.deliveryOrderAddress.addressLine2
                          : ""}
                      </p>
                      {/* <p className='label'>{orderDetails.deliveryOrderAddress ? orderDetails.deliveryOrderAddress.state.name : ''}</p> */}
                      <p className="label">
                        {orderDetails.deliveryOrderAddress
                          ? orderDetails.deliveryOrderAddress.zipCode
                          : ""}
                      </p>
                    </div>
                  </div>
                ) : (
                  <Skeleton active />
                )}
              </div>
              <div className={'gstdiv font12px mt-3'}>
                <b>Referral Code</b>
              </div>

              <Form style={{marginTop:"16px"}}>
                <Form.Item>
                  <Input
                    onChange={(e) => {
                      this.setState({ refId: e.target.value });
                    }}
                  />
                </Form.Item>
              </Form>

            </Col>

            <Col
              xs={24}
              xl={1}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "2px",
                  height: "100%",
                  background: "rgba(0,0,0,.1)",
                }}
              ></div>
            </Col>
            <Col xs={24} xl={11}>
              <div>
                <div className={"gstdiv font12px mb-3"}>
                  <b>{t("Payment method")}</b>
                </div>
                {invalidPincode ? (
                  <p
                    style={{
                      fontWeight: "700",
                      marginTop: 24,
                      textAlign: "center",
                      color: "#f00",
                    }}
                  >
                    {t("Unable to place order msg")}
                  </p>
                ) : paymentMethods.length > 0 ? (
                  <>
                    {paymentMethods.map((Item, pkey) => {
                      let paymentOptionId = Item.value;
                      if (Item.isReturnsLimitExceeded) {
                        return (
                          <div className={"pl-2"}>
                            <p>
                              <b>
                                {t("COD not available due to too many returns")}
                              </b>
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            id={paymentOptionId}
                            className={
                              "paymentOptions_flexDiv pt-3 pb-3 border_bottom pointer_Cursor"
                            }
                            key={pkey}
                            onClick={(e) => {
                              this.setState({ paymentItem: Item });
                            }}
                          >
                            <div
                              style={{ backGroundColor: "none", color: "none" }}
                              className={"left"}
                            >
                              {Item && Item.value == "partialpayment" ? (
                                <>
                                  <Collapse className="collapse-custom" defaultActiveKey={["1"]} ghost>
                                    <Panel
                                      
                                      showArrow={false}
                                      header={Item.type}
                                      key="2"
                                    >
                                      <p>
                                        Partial Amount:{" "}
                                        {partialPayment.partialAmount}
                                      </p>
                                      <p>
                                        COD Amount: {partialPayment.codAmount}
                                      </p>
                                    </Panel>
                                  </Collapse>
                                </>
                              ) : (
                                <b>{t(Item.type)}</b>
                              )}
                            </div>

                            <div className={"right font12px"}>
                              {paymentItem && paymentItem.type == Item.type ? (
                                <CheckOutlined />
                              ) : null}
                            </div>
                          </div>
                        );
                      }
                    })}
                    <Button
                      size={"large"}
                      loading={this.state.makingPayment}
                      className={"manageBtn mt-4"}
                      style={{ width: "100%" }}
                      id={"makePaymentBtn"}
                      onClick={
                        paymentItem && paymentItem.value == "partialpayment"
                          ? this.showModal
                          : () => {
                            this.isReturnableCheck();
                          }
                      }
                    >
                      {t("Make payment")}
                    </Button>
                  </>
                ) : (
                  <Skeleton active />
                )}
              </div>
            </Col>
          </Row>
        </div>
        <Modal
          destroyOnClose={true}
          open={isModalOpen}
          onCancel={this.handleCancel}
          key={"partialModal"}
          title={`Amount ${partialPayment.partialAmount}`}
          footer={[
            <Button
              id={"makepartialpayment"}
              block
              onClick={() => {
                this.isReturnableCheck();
              }}
              type="primary"
              loading={this.state.makingPayment}
            >
              {t("Make payment")}
            </Button>,
          ]}
        >
          {paymentOptions.map((idom, ind) => {
            let partialpaymentOptionId = idom.value;

            return (
              <div
                id={partialpaymentOptionId + "partial"}
                className={
                  "paymentOptions_flexDiv pt-3 pb-3 border_bottom pointer_Cursor"
                }
                key={ind}
                onClick={(e) => {
                  this.setState({ partialPaymentItem: idom });
                }}
              >
                <>
                  <div className={"left"}>
                    <b>{t(idom.type)}</b>
                  </div>

                  <div className={"right font12px"}>
                    {partialPaymentItem &&
                      partialPaymentItem.type == idom.type ? (
                      <CheckOutlined />
                    ) : null}
                  </div>
                </>
              </div>
            );
          })}
        </Modal>

        <Modal
          centered={true}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div>
            {/* ----------------returning warning ===========*/}
            {showReturnableWarning && (
              <Result
                status="warning"
                title={t("Order cannot be returned")}
                subTitle={t("Sure to place order msg")}
                extra={[
                  <Button
                    type="primary"
                    key="3"
                    onClick={() => {
                      if (paymentItem.isCOD) {
                        this.setState(
                          {
                            visible: false,
                            showReturnableWarning: false,
                          },
                          () => {
                            this.checkIsCod();
                          }
                        );
                      } else {
                        this.setState(
                          {
                            visible: false,
                            showReturnableWarning: false,
                          },
                          () => {
                            this.placeOrder(paymentItem);
                          }
                        );
                      }
                    }}
                  >
                    {t("Proceed")}
                  </Button>,
                  <Button key="4" onClick={(e) => this.handleCancel()}>
                    {t("Cancel")}
                  </Button>,
                ]}
              />
            )}
            {/*------------ cod warning---------------- */}
            {codWarning && (
              <Result
                status="warning"
                title={t("Order place on cod msg")}
                subTitle={t("Sure to place order msg")}
                extra={[
                  <Button
                    type="primary"
                    key="5"
                    onClick={() => {
                      this.setState(
                        { visible: false, codWarning: false },
                        () => {
                          this.placeOrder(paymentItem);
                        }
                      );
                    }}
                  >
                    {t("Proceed")}
                  </Button>,
                  <Button key="6" onClick={(e) => this.handleCancel()}>
                    {t("Cancel")}
                  </Button>,
                ]}
              />
            )}
          </div>
        </Modal>
      </LayoutComponent>
    );
  }
}

const mapStateToProps = ({ global }) => ({});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentComponent))
);
