import React, { useEffect } from 'react';
import LayoutComponent from '../layoutComponents/layoutComponent';
import Footer from '../footer';
import { withTranslation } from 'react-i18next';

const ReturnShipmentPolicy = (props) => {
    const { t } = props
    useEffect(() => { window.scroll(0, 0) }, []);
    return (
        <>
            <LayoutComponent title={t('Return Shipment Policy')}>
                <div className={'contact_container'}>

                    <div className='akarTermsAndPolicies'>
                        <p className='title'>{t('Case 1 – RTO')}</p>

                        <p className='description'><span className='highlightedText'>{t('RTO')}</span>
                            {t('RTO para1')}</p>

                        <p className='description'>{t('RTO para2')}</p>

                        <p className='description'>{t('RTO para3')}</p>


                        <p className='subTitle'>{t('Time window for raising dispute is 36 hours after the time of delivery of RTO')}</p>

                        <p className='description'>
                            {t('For further clarification the AKAR executive para')}
                        </p>

                        <p className='description'>{t('If all the proofs and documentation provided by the seller para')}</p>

                        < p className='description'>{t('If the proofs and documentation provided by the seller does not satisfy para')}
                        </p>

                        <p className='title' > {t('The criteria of recording packing video')}</p>

                        <p className='description'>{t('The criteria of recording packing video para')}
                        </p>

                        <p className='title'>{t('The criteria of recording unpacking video')}</p>

                        <p className='description'>
                            {t('While making the unpacking of any shipment para')}
                        </p>

                        <p className='description'>{t('If any cut , edit or any other discrepancy para')}
                        </p>

                        <p className='title'>{t('Case 2 – RVP')}</p>

                        <p className='desctiption'>
                            <span className='highlightedText'>{t('RVP')}</span>
                            {t('RVP para')}
                        </p>
                        <ol>
                            <li><p className='description'>{t('Product(s) is physically damaged or is defective')}</p></li>

                            <li><p className='description'>
                                {t('Warranty issues or quality related issues with respect to the product delivered')}</p>
                            </li>

                            <li><p className='description'>
                                {t('Wrong product or the product is not matching para')}
                            </p></li>
                            <li>
                                <p className='description'>
                                    {t('Part of the order/products is found missing due to reasons para')}
                                </p>
                            </li>
                        </ol>

                        <p className='subTitle'>{t('Time window for raising return request is 36 hours after the time of delivery')}</p>

                        <p className='descripton'>
                            {t('Time window for raising return request is 36 hours after the time of delivery para1')}
                        </p>

                        <p className='description'>
                            {t('Time window for raising return request is 36 hours after the time of delivery para2')}
                        </p>

                        <p className='description'>
                            {t('Time window for raising return request is 36 hours after the time of delivery para3')}
                        </p>

                        <p className='description'>
                            {t('Time window for raising return request is 36 hours after the time of delivery para4')}
                        </p>

                        <p className='description'>
                            {t('Time window for raising return request is 36 hours after the time of delivery para5')}
                        </p>

                        <p className='title'>{t('The criteria of recording packing video')}</p>

                        <p className='description'>
                            {t('The criteria of recording packing video para')}
                        </p>

                        <p className='title'>{t('The criteria of recording unpacking video')}</p>

                        <p className='description'>{t('The criteria of recording unpacking video para1')}</p>

                        <p className='description'>{t('The criteria of recording unpacking video para2')}</p>

                        <p className='description'>{t('The criteria of recording unpacking video para3')}</p>

                        <p className='description'>{t('The criteria of recording unpacking video para4')}</p>

                        <p className='description'>{t('The criteria of recording unpacking video para5')}</p>

                        <p className='description'>{t('The criteria of recording unpacking video para6')}</p>

                        <p className='subTitle'>{t('THE RETURN SHIPMENT POLICY CAN BE CHANGED FROM TIME TO TIME')}</p>
                    </div>
                </div>
            </LayoutComponent>
            <Footer />
        </>
    )
}

export default withTranslation()(ReturnShipmentPolicy);