import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Row, Col, Modal, Input, Form, Button, Select, message } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux'
import { MdCreate } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import LayoutComponent from '../layoutComponents/layoutComponent';
import AuthService from '../../service/authService'
import userImg from '../../assests/user.png'
import MediaUrl from '../../service/mediaUrl'
const { Option } = Select;

class BusinessProfileComponent extends Component {
    formRef = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false,
            businessObj: {},
            businessTypesList: [],
            establishmentYearsList: []
        }
    }

    mapEstablishmentYears = () => {
        let yearsArr = [moment().format("YYYY")]
        for (let x = 1; x < 100; x++) {
            yearsArr.push(moment().subtract(x, 'years').format("YYYY"))
        }
        this.setState({ establishmentYearsList: yearsArr })
    }

    loadAccountDetails = () => {
        AuthService.getAccountDetails().then((res) => {
            let { data } = res.data

            let obj = {
                name: data.name,
                establishmentYear: data.establishmentYear,
                description: data.description,
                mapAccountBusinessTypes: [],
                businessNames: [],
                imageFileUploadedData: data.imageFileUploadedData,
                panNumber: data.panNumber
            }
            let businessNames = []
            data.mapAccountBusinessTypes.forEach((item, key) => {
                obj.mapAccountBusinessTypes.push({
                    id: item.businessType.id,
                    name: item.businessType.name
                })
                businessNames.push(item.businessType.name)
            })
            obj.businessNames = businessNames.join(', ')

            this.setState({ businessObj: obj })
        })
    }

    getBusinessTypesData = () => {
        AuthService.getBusinessTypes()
            .then((res) => {
                let { data } = res.data
                data = data.map((item, key) => {
                    return { id: item.id, name: item.name }
                })
                this.setState({ businessTypesList: data })
            }, err => {
                //console.log(err)
            })
    }

    componentDidMount() {
        this.loadAccountDetails()
    }

    handleFormCancel = () => {
        this.setState({ showModal: false }, () => {
            this.formRef.current.resetFields()
        })
    }

    onEditClick = () => {
        const { businessObj } = this.state
        this.setState({ showModal: true }, () => {
            this.getBusinessTypesData()
            this.mapEstablishmentYears()
            this.formRef.current.setFieldsValue({
                name: businessObj.name,
                establishmentYear: businessObj.establishmentYear,
                description: businessObj.description,
                mapAccountBusinessTypes: businessObj.mapAccountBusinessTypes ? businessObj.mapAccountBusinessTypes.map((item) => item.id) : null,
                panNumber: businessObj.panNumber
            })
        })
    }

    onFinish = values => {

        let businessArr = []
        if (values.mapAccountBusinessTypes) {
            values.mapAccountBusinessTypes.forEach(element => {
                let obj = { businessTypeId: element }
                businessArr.push(obj)
            });
        }

        values.establishmentYear = parseInt(values.establishmentYear)
        values.mapAccountBusinessTypes = businessArr
        this.setState({ loading: true })

        AuthService.updateAccountDetails(values).then((res) => {
            let { data } = res.data
            let obj = {
                name: data.name,
                establishmentYear: data.establishmentYear,
                description: data.description,
                mapAccountBusinessTypes: [],
                imageFileUploadedData: data.imageFileUploadedData,
                businessNames: [],
                panNumber: data.panNumber
            }

            let businessNames = []

            data.mapAccountBusinessTypes.forEach((item, key) => {
                obj.mapAccountBusinessTypes.push({
                    id: item.businessType.id,
                    name: item.businessType.name
                })
                businessNames.push(item.businessType.name)
            })

            obj.businessNames = businessNames.join(', ')

            this.setState({ businessObj: obj })

            this.formRef.current.resetFields()
            this.setState({ loading: false, showModal: false })
        }, (err) => { })

    }

    editAccountBusinessImage = (values) => {
        //  const { dispatch } = this.props
        this.setState({ loading: true })
        AuthService.updateAccountDetails(values).then((res) => {
            let { data } = res.data
            // dispatch({
            //     type: 'SET_CURRENT_USER',
            //     user: data
            // })
            let obj = {
                name: data.name,
                establishmentYear: data.establishmentYear,
                description: data.description,
                mapAccountBusinessTypes: [],
                imageFileUploadedData: data.imageFileUploadedData
            }
            data.mapAccountBusinessTypes.forEach((item, key) => {
                obj.mapAccountBusinessTypes.push({
                    id: item.businessType.id,
                    name: item.businessType.name
                })
            })

            this.setState({ businessObj: obj, loading: false })

            document.getElementById('profileImage').value = null
        }, (err) => {
            //console.log(err)
        })
    }

    addImageInAWS = (data) => {
        AuthService.addUserKYCDocImage(data, 'ACCOUNT_IMAGE_FILE')
            .then((res) => {
                let data = res.data.data[0]
                let url = MediaUrl.trimMediaUrl(data.url);
                let obj = {
                    "imageFileUploadedData": {
                        "name": data.fileName,
                        "url": url,
                        "type": "AccountProfileImage"
                    }
                }
                this.editAccountBusinessImage(obj)
            }, err => {
                //console.log(err)
            })
    }

    onImageChange = (event) => {
        const { t } = this.props
        let imageFile = event.target.files[0];
        const fileExtn = imageFile.name.substr(imageFile.name.lastIndexOf("."))

        if ((fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png') && imageFile) {
            let blob = imageFile.slice(0, imageFile.size, imageFile.type);
            imageFile = new File([blob], moment().valueOf() + fileExtn, { type: imageFile.type });
            let filearr = []
            filearr.push(imageFile)
            this.addImageInAWS(filearr)
        } else {
            return message.error(`${t('Attach image in png/jpg/jpeg file format')}`)
        }
        document.getElementById("profileImage").value = null;
    }

    render() {
        const { t } = this.props
        const { businessTypesList, showModal, businessObj, establishmentYearsList } = this.state

        return (
            <LayoutComponent title={t('Business details')}>
                <Row>
                    <Col span={24} className=''>
                        <div className='profile_card'>
                            <div className='justify-content-center mb-5'>
                                <input id="profileImage"
                                    type="file"
                                    ref={(ref) => this.myInput = ref}
                                    onChange={this.onImageChange.bind(this)}
                                    accept=".png,.jpg,.jpeg"
                                    style={{ display: 'none' }} />

                                <div className="profile_img_div">
                                    <img
                                        src={businessObj.imageFileUploadedData && businessObj.imageFileUploadedData.url ? MediaUrl.completeMediaUrl(businessObj.imageFileUploadedData.url, true, 100, 100) : userImg}
                                        alt={'userImg'} />

                                    <div className="icon_container">
                                        <span className="edit-btn"
                                            onClick={() => this.myInput.click()}>
                                            <MdCreate />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='heading'>
                                <h2>{t('Business details')}</h2>
                                <div className='spacer'></div>
                                <button onClick={(e) => this.onEditClick()}
                                    className='card_button'>
                                    {t('Edit')}
                                </button>
                            </div>

                            <div>
                                <p className='label'>
                                    {t('Your business name')}
                                </p>
                                <h4 className="mb-3">
                                    {businessObj.name ? businessObj.name : "---"}
                                </h4>

                                <p className='label'>
                                    {t('Establishment year')}
                                </p>
                                <h4 className="mb-3">
                                    {businessObj.establishmentYear ? businessObj.establishmentYear : "---"}
                                </h4>

                                <p className='label'>
                                    {t('Business type')}
                                </p>
                                <h4 className="mb-3">{businessObj.businessNames ? businessObj.businessNames : '---'}</h4>

                                <p className='label'>{t('PAN number')}</p>
                                <h4 className="mb-3">
                                    {businessObj.panNumber ? businessObj.panNumber : '---'}
                                </h4>

                                <p className='label'>
                                    {t('Description')}
                                </p>
                                <h4 className="mb-3">
                                    {businessObj.description ? businessObj.description : t('Describe your business')}
                                </h4>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Modal
                    title={t('Edit business details')}
                    visible={showModal}
                    onCancel={this.handleFormCancel}
                    footer={null}
                    centered={true}
                    maskClosable={false}
                >
                    <div>
                        <Form ref={this.formRef}
                            layout={'vertical'}
                            requiredMark={false}
                            scrollToFirstError
                            onFinish={this.onFinish}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('Business name')}*`}
                                        name="name"
                                        rules={[{
                                            required: true, whitespace: true,
                                            message: `${t('Business name reqd validation')}`,

                                        }]} >
                                        <Input placeholder={t("Business name")} /></Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('Establishment year')}*`}
                                        name="establishmentYear"
                                        rules={[{
                                            required: true,
                                            message: `${t('Establishment year reqd validation')}`
                                        }]}
                                    >
                                        <Select placeholder={t('Select establishment year')} >
                                            {establishmentYearsList && establishmentYearsList.map((item, iKey) => {
                                                return (<Option
                                                    key={iKey}
                                                    value={item}>{item}</Option>)
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('Business type')}*`}
                                        name="mapAccountBusinessTypes"

                                        rules={[{
                                            required: true,
                                            message: `${t('Buisness type reqd validation')}`,
                                            type: 'array'
                                        }]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder={t('Select business type')}
                                        >
                                            {businessTypesList && businessTypesList.map(item => {
                                                return (
                                                    <Option key={item.id}
                                                        value={item.id}>{item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>

                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('PAN number')}*`}
                                        name="panNumber"
                                        rules={[{
                                            required: true, whitespace: true,
                                            message: `${t('Pan reqd validation')}`,
                                        }, {
                                            pattern: new RegExp(/^[a-zA-Z0-9]*$/),
                                            required: true,
                                            message: `${t('Incorrect Pan')}`
                                        }
                                        ]} >
                                        <Input placeholder={t('PAN number')} onChange={(e) => {
                                            this.formRef.current.setFieldsValue({
                                                panNumber: e.target.value.toUpperCase()
                                            })
                                        }} /></Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={`${t('Description')}*`}
                                        name="description"
                                        rules={[{
                                            required: true,
                                            message: `${t('Description reqd validation')}`,
                                            whitespace: true
                                        }]}
                                    >
                                        <Input.TextArea
                                            autoSize={{ minRows: 3, maxRows: 6 }}
                                            placeholder={t("Description")} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            htmlType='submit'
                                            className={'submitBtn'}
                                            loading={this.state.loading}
                                            type="primary"
                                        >{t('Save')}</Button>
                                        <Button
                                            className={'cancelBtn'}
                                            type="default"
                                            onClick={this.handleFormCancel}
                                        >{t('Cancel')}</Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Form>
                    </div>
                </Modal>
            </LayoutComponent >
        )
    }
}

const mapStateToProps = ({ global }) => ({
    token: global.token,
    currentUser: global.currentUser,
    loading: global.loading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessProfileComponent)));
