
const SaveListing = {
    mapCategoryId: '',
    isDataStoredFromApi: false,
    isBrandSelected: false,
    productImages: [],
    productVideo: {},
    isCoverImageAdded: false,
    coverImages: [],
    isSetCreated: false,
    isNewSet: true,
    isProductDetailAdded: false,
    selectProductSetIndex: 0,
    productID: '',
    isClone: false,
    mapSetMultipleSelectableAttributes:[],
    selectProductSetID: '',
    mapProductSets: [],
    mapProductAttributes: [],
    addedFileUploadedDatas: [],
    additionDetails: {},
    title: '',
    brand: {
        isNewBrand: false,
        // isReapplyingBrand: false,
        brand: {},
        relationType: '',
        mapBrandAccountFileUploadedDatas: [],
        imageFileUploadedData: {},
        brandDocTYpe: ''
    },
    reapplyBrand: {
        id: '', 
        isReapplyingBrand: false,
        brandId: ''
    },
    productSetObj: {},
    needToProductCall: false,
    newMultipleSelectedAttribute : false
}

export default SaveListing;