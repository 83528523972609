import React from 'react';
import { Spin } from 'antd';

class LoaderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (

            <div className="loader">
                <Spin />
            </div>

        );
    }
}

export default LoaderComponent;
