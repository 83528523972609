import API from './network';
import Urls from './urls'

const DisputeService = {
    createOrderDispute(body) {
        const path = Urls.createDispute
        return API.makePostRequest(path, null, null, body, null)
            .then((res) => {
                return res
            })
    },

    getAllOrderDisputes(id) {
        let data = {
            orderReturnCustomId: id,
            pageNumber: 1,
            limit: 100
        }
        const path = `${Urls.getOrderDisputes}?orderReturnCustomId=${data.orderReturnCustomId}&pageNumber=${data.pageNumber}&limit=${data.limit}`
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            })
    },

    updateOrderDispute(body) {
        const path = Urls.updateOrderDispute
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            })
    },

    createDisputeComment(body) {
        const path = Urls.createCommentOnDispute
        return API.makePostRequest(path, null, null, body, null)
            .then((res) => {
                return res
            })
    },

    getAllDisputeComments(id) {
        let data = {
            disputeCustomId: id,
            pageNumber: 1,
            limit: 100
        }
        const path = `${Urls.getCommentsOverDispute}?disputeCustomId=${data.disputeCustomId}&pageNumber=${data.pageNumber}&limit=${data.limit}`
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            })
    },

    getAllDisputes(data) {
        let newStatus = data.status;
        let path = '';
        if(newStatus && Array.isArray(newStatus)) {
            newStatus = newStatus.map(key=>`${'status'}=${key}`).join("&");
            path = `${Urls.currentUserDisputeList}?pageNumber=${data.pageNumber}&limit=${data.limit}&${newStatus}`
        } else {
            path = `${Urls.currentUserDisputeList}?pageNumber=${data.pageNumber}&limit=${data.limit}&status=${newStatus}`
        }
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            })
    },

    getDisputeDetails(disputeCustomId) {
        const path = Urls.getDisputeDetailsById + disputeCustomId
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            })
    }
}
export default DisputeService;