import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col, Skeleton, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { AlertService } from '../../service/alertService'
import './index.scss'
import moment from 'moment';

class AlertsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      status: '',
      loading: false,
      alertList: [],
      pageSize: 10,
      pageNumber: 1,
      loadMore: true
    }
  }

  alertlist = async () => {
    const { pageSize, pageNumber, totalCount } = this.state;
    const { t } = this.props
    const { data } = await AlertService.getAllAlerts({
      pageNumber,
      results: pageSize ? pageSize : 10,
    })
    if (data.applicationCode === 200) {
      this.setState({
        alertList: data.data.notificationLogs,
        loading: false,
      }, () => {
        if (data.data.notificationLogs.length >= data.data.totalCount) {
          this.setState({ loadMore: false })
        }
        else {
          this.setState({ loadMore: true })
        }
      })
    } else {
      message.error(`${t("Something went wrong")}`)
    }
  }

  loadMoreHandle = async () => {
    this.setState({ loading: true });
    await this.setState(previousState => ({
      pageSize: previousState.pageSize + 10
    }))
    this.alertlist()
  }

  componentDidMount() {
    this.alertlist()
  }

  handleAlertClick = (item) => {
    const { history } = this.props;
    let data = JSON.parse(item.data);
    let { type, id, body } = data;
    switch (type) {
      case "ProductDetail":
        history.push({
          pathname: `/view-product/${id}`
        })
        break;
      case "AccountDetail":
        history.push({
          pathname: `/user-profile`
        })
        break;
      case "OrderReturnDetail":
        history.push({
          pathname: `/return-details/${id}`
        })
        break;
      case "BrandDetail":
        history.push({
          pathname: `/manage-brands`
        })
        break;
      case "UserChat":
        let jsonBody = JSON.parse(body);
        history.push({
          pathname: `/chat`,
          search: `?threadId=${jsonBody.chatThreadGuid}`
        })
        break;
      case "OrderDisputeDetail":
        history.push({
          pathname: `/rto-dispute/dispute`,
          search: `?disputeCustomId=${id}`
        })
        break;
      case "OrderDetail":
        history.push({
          pathname: `/order-details/${id}`
        })
        break;
      default:
        return;
    }
  }

  render() {
    const { alertList, loading } = this.state
    const { t } = this.props

    return (
      <LayoutComponent title={t('Alerts')}>
        <div >
          {alertList && alertList.length > 0 ?
            <Row>
              <Col span={22} offset={1} style={{ marginTop: '20px' }}>
                {alertList && alertList.length > 0 ?
                  alertList.map((item) => {
                    return (
                      <Row className='order-content-container' key={item.id}
                        onClick={() => this.handleAlertClick(item)}>
                        <Col md={24} className='order-container'>
                          <div className='orderCard pointer_Cursor' >

                            <div className={'right_div px-4'}>

                              <p className='order_title'> <span className='seller_name'>{item.title}</span></p>

                              <p className='order_title'> <span >{item.message}</span></p>

                              <div className='order_title'>{moment.utc(item.createdAt).utcOffset('+0530').format('lll')}</div>
                            </div>

                          </div>

                        </Col>
                      </Row>
                    )
                  })
                  : null
                }
                <Row>
                  {this.state.loadMore ?
                    <Button className="btn"
                      loading={loading} onClick={this.loadMoreHandle}
                      size="large"
                    >{t('Load more')}</Button>
                    : null}
                </Row>
              </Col>
            </Row>
            :
            <Col justify="center" align="center" style={{ marginTop: "30vh" }}>
              <h3>{t('No Alerts exists to show')}</h3>
            </Col>
          }
        </div>

      </LayoutComponent>)
  }
}

const mapStateToProps = ({ global }) => ({
  currentUser: global.currentUser,
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(AlertsMain)));

