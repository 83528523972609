import API from './network';
import Urls from './urls'

const TeamService = {

    inviteMember(data) {
        const path = Urls.inviteTeamMember
        var body = {"PhoneNumber":data}
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    removeMember(id) {
        const path = Urls.removeTeamMember + id
        return API.makePutRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getAllMembers(id) {
        const path = Urls.getAllTeamMembers + id
        return API.makeGetRequest(path, null, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    closeAccount() {
        const path = Urls.closeAccount
        return API.makePutRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },


}
export default TeamService;