import React from "react";
import LayoutComponent from "./layoutComponents/layoutComponent";
import StaticOfferContent from "./StaticOfferContent";
import Layout from "./layout";

function StaticOfferPage() {
    const [loggedIn,setLoggedIn] = React.useState(false)

    React.useEffect(()=>{
        if(typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null){
            setLoggedIn(true)
        }
    },[])

  return (
          loggedIn ?
          <LayoutComponent title={"Offer Details"}>
          <StaticOfferContent />
          </LayoutComponent>
          :
          <Layout title={"Offer Details"}>
          <StaticOfferContent />
          </Layout>

  );
}

export default StaticOfferPage;
