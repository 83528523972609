import ChatService from '../service/chatService';
import AuthService from '../service/authService';
import MediaUrl from '../service/mediaUrl'
import { ChatDirection } from '../service/statusTypes'
import _ from 'lodash'

export const getAllChatThreads = (page, limit, type) => {
  return async (dispatch) => {
    try {
      let { data: response } = await ChatService.getAllChats(page, limit)
      if (type === 'assign') {
        dispatch({ type: 'GET_ALL_CHAT_THREADS', payload: { ...response } })
      } else {
        //case of append
        dispatch({ type: 'APPEND_TO_CHAT_THREADS', payload: { ...response } })
      }

    } catch (err) {
      dispatch({
        type: 'GET_ALL_CHAT_THREADS',
        payload: { data: null }
      });
    }
  }
}

export const setVacationMode = (status, callback) => {
  return async (dispatch) => {
    try {
      let res = await AuthService.setVacationMode(status);
      if (res.status == 200) {
        dispatch({
          type: 'SET_CURRENT_USER_ACCOUNT_DETAILS',
          account: res.data.data
        });
        callback(true);
      }
    } catch (err) {
      dispatch({
        type: 'GET_ALL_CHAT_THREADS',
        account: { data: null }
      });
    }
  }
}

export const getAllChatsByGuid = (chatThreadGuid, page, limit, type) => {
  return async (dispatch) => {
    try {
      let { data: response } = await ChatService.getAllChatByGuid(chatThreadGuid, page, limit)
      let chatResults = response.data.results
      chatResults.forEach((item) => {
        if (item.chatFile && item.chatFile.url) {
          item.chatFile.url = MediaUrl.completeMediaUrl(item.chatFile.url, true, 100, 100)
        }
      })
      response.data.chatThreadId = chatThreadGuid

      if (type === 'assign') {
        //  console.log("done")
        dispatch({ type: 'GET_ALL_CHATS', payload: { ...response } })
      } else {
        //case of append
        dispatch({ type: 'APPEND_TO_ALL_CHATS', payload: { ...response } })
      }

      // dispatch({
      //   type: 'GET_ALL_CHATS',
      //   payload: { ...response }
      // });

    } catch (err) {
      dispatch({
        type: 'GET_ALL_CHATS',
        payload: { data: null }
      });
    }
  }
}
//send data to user request
export const sendChatMessage = (receiverAccountId, orderId, messageType, optionalData) => {
  return async (dispatch) => {
    try {

      let obj = {
        "ToAccountId": receiverAccountId,
        "message": optionalData.textMessage ? optionalData.textMessage : null,
        "type": messageType,     // 0,1,2 as per text,image,video
        "orderId": orderId,
        "chatFile": optionalData.url ? optionalData : {},
        "chatData": "{}",
      }

      let { data } = await ChatService.sendChatMessage(obj)

      let sendChatData = _.find(data.data, (item) => {
        return item.direction === ChatDirection.Out
      })

      if (sendChatData.chatFile.url) {
        sendChatData.chatFile.url = MediaUrl.completeMediaUrl(sendChatData.chatFile.url, true, 100, 100)
      }

      dispatch({
        type: 'SEND_CHAT_MESSAGE',
        payload: { data: sendChatData }
      });
    } catch (err) {
      dispatch({
        type: 'SEND_CHAT_MESSAGE',
        payload: { data: null }
      });
    }
  }
}

export const clearUserChat = () => {
  return (dispatch) => {
    dispatch({ type: 'CLEAR_CHAT' })
  }
}

export const showLoader = () => {
  return { type: 'SHOW_BTN_LOADING' }
}

export const hideLoader = () => {
  return { type: 'HIDE_BTN_LOADING' }
}

export const logout = () => {
  return { type: 'LOGOUT' }
}

export default {}