import React from "react";
import { Drawer, Button, Col, Modal, Radio, Input } from "antd";
import {
  MenuOutlined,
  CreditCardOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import whiteLogo from "../akar_White.png";
import logoutImg from "../assests/logout.svg";
import termsConditionSVG from "../assests/terms-and-conditions.svg";
import supportImg from "../assests/support.svg";
import aboutImg from "../assests/about.svg";
import profileImg from "../assests/profile.svg";
import login from "../assests/Grouplogin.svg";
import faq from "../assests/faq.png";
import HeaderInfo from "./elements/headerInfo";
import SearchBar from "./elements/searchBar";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isOpenModal: false,
      selectedLanguage: "",
      screenWidth: window.innerWidth,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  openLanguageModal = () => {
    const { i18n } = this.props;
    let language = typeof window !== 'undefined' ? localStorage.getItem('language') : null;
    this.setState({
      openLanguageModal: true,
      visible: false,
      selectedLanguage: i18n.language || language,
    });
  };

  render() {
    const { visible, openLanguageModal, selectedLanguage, screenWidth } = this.state;
    const { t, i18n } = this.props;

    return (
      <Col span={24} className="main-container">
        <>
          <header className="toolbar z_index_1000 fixedHeader">
            {/* {screenWidth > 576 ? <HeaderInfo />:null} */}
            <nav className="toolbar__navigation bg_theme_Color" style={{ paddingRight: "5%", paddingLeft: "5%" }}>
              <div className="responsive-navbar" style={{ height: "35px" }}>
                <MenuOutlined
                  className="white_color nav-menu-icon"
                  onClick={this.showDrawer}
                />
                <Link to="/">
                  <img
                    className="ml-3"
                    height="35px"
                    src={whiteLogo}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="toolbar_navigation-items hideScroller responsive_web">
                <ul>
                  <li className="search-container-li">
                    <a onClick={() => { this.props.history.push({ pathname: '/quick-search' }) }}>
                      <SearchBar />
                    </a>
                  </li>
                  <li className="download-container-li">
                    <a id="navbar-download-button" href="https://play.google.com/store/apps/details?id=com.akar&hl=en_IN" target={"blank"} >
                      <div className="download-icon-container">
                        <DownloadOutlined style={{ fontSize: "20px" }} id="download-icon" />
                      </div>
                      <p>{t("Download")}</p>
                    </a>
                  </li>
                  <Link to="/login">
                    <li style={{ display: "flex", alignItems: "center" }}>
                      <div className="login-icon-container"
                        style={{
                          backgroundColor: "white",
                          fontSize: "20px",
                          margin: "auto 12px",
                          width: "35px",
                          borderRadius: "4px",
                          textAlign: 'center',
                          paddingBottom: '5px'
                        }}>
                        <img
                          src={login}
                        />
                      </div>
                      {t("login")}
                    </li>
                  </Link>
                </ul>
              </div>
            </nav>
          </header>

          <Drawer
            placement={"left"}
            closable={true}
            maskClosable={true}
            onClose={this.onClose}
            visible={visible}
            key={"1"}
            className={"layout-main"}
          >
            <div className="sidebar_navigation-items">
              <ul>
                <li onClick={this.onClose}>
                  <div className="logo_div">
                    <img src={profileImg} alt="about" />
                  </div>
                  <Link to="/login">{t("login")}</Link>
                </li>
                <li className="saperator">
                  <p>{t("Information")}</p>
                </li>
                <li onClick={this.onClose}>
                  <div className="logo_div">
                    <img src={aboutImg} alt="about" />
                  </div>
                  <Link to="/about-us">{t("About Akar")}</Link>
                </li>
                <li onClick={this.onClose}>
                  <div className="logo_div">
                    <img src={termsConditionSVG} alt="terms" />
                  </div>
                  <Link to="/terms-and-conditions">
                    {t("Terms and conditions")}
                  </Link>
                </li>
                <li onClick={this.openLanguageModal}>
                  <div className="logo_div">
                    <img src={logoutImg} alt="language" />
                  </div>
                  <a>{t("Change Language")}</a>
                </li>
                <li onClick={this.onClose}>
                  <div className="logo_div">
                    <img src={faq} alt="terms" />
                  </div>
                  <Link to="/FAQ">{t("FAQs")}</Link>
                </li>
                <li onClick={this.onClose}>
                  <div className="logo_div">
                    <img src={supportImg} alt="support" />
                  </div>
                  <Link to="/contact-us">{t("Support")}</Link>
                </li>
              </ul>
              <Modal
                footer={null}
                centered={true}
                onCancel={() => {
                  this.setState({
                    openLanguageModal: false,
                    selectedLanguage: i18n.language,
                  });
                }}
                maskClosable={false}
                visible={openLanguageModal}
              >
                <div className="text-center">
                  <h3 className="mb-4">CHOOSE LANGUAGE / अपनी भाषा चुनें</h3>
                  <div className="d-flex" style={{ justifyContent: "center" }}>
                    <Radio.Group
                      value={selectedLanguage}
                      onChange={(e) => {
                        this.setState({ selectedLanguage: e.target.value });
                      }}
                    >
                      <Radio value={"en"}>English</Radio>
                      <Radio value={"hi"}>हिंदी</Radio>
                    </Radio.Group>
                  </div>
                  <Button
                    type={"primary"}
                    className="my-3"
                    onClick={() => {
                      i18n.changeLanguage(selectedLanguage);
                      if (typeof window !== 'undefined') { localStorage.setItem("language", selectedLanguage) };
                      this.setState({ openLanguageModal: false });
                      window.location.reload();
                    }}
                  >
                    {selectedLanguage === "en" ? "CONTINUE IN ENGLISH" : null}
                    {selectedLanguage === "hi" ? "हिंदी में जारी रखें" : null}
                  </Button>
                  <p>
                    {selectedLanguage === "en"
                      ? "Language options can be changed anytime. We will translate information to help you browse and order. Please note that translations have been provided for convenience only and English version of the Akar's, including our Conditions of Use, is the definitive version."
                      : null}
                    {selectedLanguage === "hi"
                      ? "भाषा विकल्प कभी भी बदले जा सकते हैं। ब्राउज़ करने और ऑर्डर करने में आपकी सहायता के लिए हम जानकारी का अनुवाद करेंगे। कृपया ध्यान दें कि अनुवाद केवल सुविधा के लिए प्रदान किए गए हैं और हमारी उपयोग की शर्तों सहित आकार का अंग्रेजी संस्करण निश्चित संस्करण है।"
                      : null}
                  </p>
                </div>
              </Modal>
            </div>
          </Drawer>
          <div className="content-container">{this.props.children}</div>
          {/* <Footer/> */}
        </>
      </Col>
    );
  }
}

export default withTranslation()(withRouter(Layout));
