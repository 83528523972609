import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LayoutMain from "../layoutMain";
import Layout from "../layout";
import Footer from "../elements/footer";
import { withTranslation } from "react-i18next";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.token = typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null;
  }
  faqSection = () => {
    const { t } = this.props;
    return (
      <div className={"contact_container"}>
        <div style={{ minHeight: "70vh" }}>
          <h2 style={{ marginBottom: "10px" }}>
            {t("Frequently Asked Questions (FAQs)")}
          </h2>
          <p className="faq_ques">{t("FAQ - Q1")}</p>
          <p className="faq_ans">{t("FAQ - A1")}</p>
          <p className="faq_ques">{t("FAQ - Q2")}</p>
          <p className="faq_ans">{t("FAQ - A2")}</p>
          <p className="faq_ques">{t("FAQ - Q3")}</p>
          <p className="faq_ans">{t("FAQ - A3")}</p>
          <p className="faq_ques">{t("FAQ - Q4")}</p>
          <p className="faq_ans">{t("FAQ - A4")}</p>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    if (this.token) {
      return (
        <>
          <LayoutMain title={t("FAQ")}>{this.faqSection()}</LayoutMain>
          <Footer />
        </>
      );
    } else {
      return (
        <>
          <Layout title={t("FAQ")}>{this.faqSection()}</Layout>
          <Footer />
        </>
      );
    }
  }
}

export default withTranslation()(withRouter(FAQ));
