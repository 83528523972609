import React from 'react';
import { withRouter, Link } from "react-router-dom";


class PageNotFoundComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (

            <div style={{ textAlign: "center", margin: '100px 0px' }}>
                <h1>Page Not Found</h1>
                <Link to={'/'}><span style={{color:'orange'}}>Go To HomePage</span></Link>
            </div>

        );
    }
}

export default withRouter(PageNotFoundComponent);
