import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Row, Col, Select, message, Button, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import { CloseCircleFilled, FileAddOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';
import _ from 'lodash';
import { DisputeReason } from '../../service/statusTypes'
import LayoutComponent from '../layoutComponents/layoutComponent'
import BrandService from '../../service/brandService'
import DisputeService from '../../service/disputeService'
import MediaUrl from '../../service/mediaUrl'
import CartService from '../../service/cart';
import { showLoader, hideLoader } from '../../actions';
import { withTranslation } from 'react-i18next';
const { Option } = Select

class OrderDisputeComponent extends Component {

    myInput;
    constructor(props) {
        super(props);
        this.state = {
            disputeReasonsArr: [],
            fileList: [],
            vedioList: [],
            imageList: [],
            disputeReason: '',
            returnDetailsData: {},
            selectedList: [],
            disableMinusBtn: false,
            disablePlusBtn: true,
            otherDisputeSelected: false,
            otherDisputeSelectedReason: ''
        };
    }
    navigate = (url) => {
        this.props.history.push({ pathname: url, search: `?status=Open` })
    }

    disputeReasonList = () => {
        let disputeReasons = []
        for (var key in DisputeReason) {
            let obj = { text: key, value: DisputeReason[key] }
            disputeReasons.push(obj)
        }
        this.setState({ disputeReasonsArr: disputeReasons })
    }

    getOrderReturnDetail = async () => {
        const searchParams = queryString.parse(this.props.location.search)
        const { t } = this.props
        let x = await CartService.getSingleReturnOrder(searchParams.orderReturnCustomId)
        if (x.data.isSuccess) {
            let { data } = x.data
            data.orderReturnLineItems.forEach((item, key) => {
                item['optedQty'] = item.vendorQuantity
            })
            this.setState({ returnDetailsData: data })
        }
        else {
            message.error(`${t('Error in fetching data')}`)
        }
    }

    componentDidMount() {
        this.disputeReasonList()
        this.getOrderReturnDetail()
    }

    createDispute = async () => {
        const searchParams = queryString.parse(this.props.location.search)
        let { fileList, disputeReason, returnDetailsData } = this.state
        const { dispatch, t } = this.props

        let disputeFileUploads = []
        let disputeLineItems = []


        if (!disputeReason) {
            return message.error(`${t('Please select reason')}`)
        }

        if (!this.state.fileList.length) {
            return message.error(`${t('Upload Media')}`)
        } else {
            fileList.forEach((item, key) => {
                let obj = { fileUploadedData: { url: item.url } }
                disputeFileUploads.push(obj)
            })
        }
        dispatch(showLoader())

        if (returnDetailsData.orderReturnLineItems) {
            let disputedItems = _.filter(returnDetailsData.orderReturnLineItems, (item) => {
                return item.optedQty !== 0
            })

            if (disputedItems.length) {
                disputeLineItems = _.map(disputedItems, (item) => {
                    return {
                        orderReturnLineItemId: item.id,
                        mapProductSetId: item.mapProductSetId,
                        vendorQuantity: item.optedQty
                    }
                })

            }
        }

        let obj = {
            orderReturnCustomId: searchParams.orderReturnCustomId,
            disputeReason: disputeReason,
            disputeFileUploads: disputeFileUploads,
            disputeLineItems: disputeLineItems
        }

        let { data } = await DisputeService.createOrderDispute(obj)
        if (data.isSuccess) {
            dispatch(hideLoader())
            message.success(`${t('Dispute raised successfully')}`)
            this.navigate('/rto-dispute/disputes')
        } else {
            dispatch(hideLoader())
            return message.error(`${t('Error in raising dispute')}`)
        }
    };

    onChange = (e) => {
        const { t } = this.props
        let selectedFile = e.target.files[0]
        const fileExtn = selectedFile.name.substr(selectedFile.name.lastIndexOf("."))

        if (fileExtn === '.jpg' || fileExtn === '.jpeg' || fileExtn === '.png' || fileExtn === '.mov' || fileExtn === '.mp4') {

            if (selectedFile) {
                let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
                selectedFile = new File([blob], moment().valueOf() + fileExtn, { type: selectedFile.type });

                let localFileUrl = URL.createObjectURL(selectedFile)
                if (fileExtn === '.mov' || fileExtn === '.mp4') {
                    //vedio
                    const fileSize = this.returnFileSize(selectedFile.size)

                    if (selectedFile.size >= 20971520) {
                        return message.error(`${t('Video size limit validation')}`)
                    } else {
                        let vedioList = this.state.vedioList
                        vedioList.push(localFileUrl)
                        this.setState({ vedioList }, () => {
                            this.addImageInAWS(selectedFile)
                        })
                    }
                }
                else {
                    //image
                    let imageList = this.state.imageList
                    imageList.push(localFileUrl)
                    this.setState({ imageList }, () => {
                        this.addImageInAWS(selectedFile)
                    })
                }
            }
        } else {
            return message.error(`${t('Attach file in png/jpg/jpeg/mp4/mov format')}`)
        }
        document.getElementById("myInput").value = null;
    }

    addImageInAWS = (data) => {
        BrandService.addImage(data, 'PRODUCT_DISPLAY_FILE')
            .then((res) => {
                let data = res.data.data[0]
                let mediaList = this.state.fileList
                mediaList.push(data)
                this.setState({ fileList: mediaList })
            }, err => { })
    }

    deleteImage = (index) => {
        var media = [...this.state.imageList];
        this.deleteMediaFromAWS(media[index].url)
        media.splice(index, 1);
        this.setState({ imageList: media });
    }

    deleteMediaFromAWS = (data) => {
        BrandService.deleteImage(data)
            .then((res) => { }, err => { })
    }

    returnFileSize = (number) => {
        if (number < 1024) {
            return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
            return (number / 1048576).toFixed(1) + 'MB';
        }
    }

    decreaseQty = (buyItem, index) => {
        let { returnDetailsData } = this.state
        if (buyItem.optedQty === 0) {
            this.setState({ disableMinusBtn: true, disablePlusBtn: false })
        }
        else {
            if ((buyItem.optedQty < buyItem.vendorQuantity) || (buyItem.optedQty === buyItem.vendorQuantity)) {
                this.setState({ disableMinusBtn: false })
                returnDetailsData.orderReturnLineItems[index].optedQty = buyItem.optedQty - 1
                this.setState({ returnDetailsData, disablePlusBtn: false })
            } else { }

        }
    }

    increaseQty = (buyItem, index) => {
        let { returnDetailsData } = this.state

        if (buyItem.optedQty > 0) {
            this.setState({ disableMinusBtn: false })
        }
        if (buyItem.optedQty === buyItem.vendorQuantity) {
            this.setState({ disablePlusBtn: true })
        }
        if (buyItem.optedQty < buyItem.vendorQuantity) {
            this.setState({ disablePlusBtn: false })
            returnDetailsData.orderReturnLineItems[index].optedQty = buyItem.optedQty + 1
            this.setState({ returnDetailsData, disableMinusBtn: false })
        }
    }
    render() {
        const { disputeReasonsArr,
            vedioList, fileList, disableMinusBtn, disablePlusBtn,
            imageList, disputeReason, returnDetailsData, otherDisputeSelected,
            otherDisputeSelectedReason } = this.state

        const { t } = this.props

        return (
            <LayoutComponent title={t('Raise Dispute')}>
                <div className='divWithFixButton' >
                    <Row className='fixed_container'>
                        <Col span={24}>
                            <p className="font14px mt-2 mb-1" >{t('Select Reason')}*</p>
                            <Select id='dispute-select-rsn' placeholder={t('Select Reason')}
                                onChange={(val) => {
                                    {
                                        val === "Other" ? this.setState({ otherDisputeSelected: true }) :
                                            this.setState({ disputeReason: val })
                                    }
                                }}
                                value={disputeReason}
                                style={{ width: '100%' }} >
                                {disputeReasonsArr && disputeReasonsArr.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val.value}>
                                            {t(val.value)}
                                        </Option>
                                    )
                                })}
                            </Select>
                            <Modal
                                title={t('Mention Reason')}
                                visible={otherDisputeSelected}
                                onCancel={() => this.setState({ otherDisputeSelected: false })}
                                footer={null}
                                width={700}
                                centered={true}
                            >
                                <TextArea placeholder={t('Start typing')} style={{ width: "90%", marginLeft: "5%" }}
                                    onChange={(e) => this.setState({ otherDisputeSelectedReason: e.target.value })} />
                                <br />
                                <br />
                                <Button style={{ marginLeft: "5%" }}
                                    onClick={() => {
                                        this.setState({ disputeReason: otherDisputeSelectedReason }, () => {
                                            this.setState({ otherDisputeSelected: false })
                                        })
                                    }} >{t('Confirm')}</Button>
                                <br />
                            </Modal>


                            <p className='mt-3 mb-0 font14px'>{t('Add Image/Video')}*</p>
                            <div className='assets'>
                                <input id="myInput"
                                    type="file"
                                    ref={(ref) => this.myInput = ref}
                                    onChange={this.onChange.bind(this)}
                                    accept=".mp4,.mov,.jpg,.png,.jpeg"
                                    style={{ display: 'none' }} />

                                <button className="float-right mr-3 file" id='add-imgBtn' onClick={() => {
                                    this.myInput.click()
                                }}><FileAddOutlined /></button>

                                {imageList.map((item, i) => {
                                    return (<div key={i} className='file'>
                                        <img src={item} alt='Something wrong' />
                                        <div className="container">
                                            <span className="remove-btn"
                                                onClick={() => this.deleteImage(i)}>
                                                <CloseCircleFilled />
                                            </span>
                                        </div>
                                    </div>)
                                })}

                            </div>

                            {vedioList.length > 0 ? <>
                                <h2 className="mt-3 mb-3">Video</h2>
                                <div style={{ display: 'flex' }}>
                                    {vedioList.map((item, i) => {
                                        return (<div key={i} style={{
                                            margin: '10px',
                                            width: '300px', height: '150px'
                                        }}>
                                            <video src={item}
                                                controls
                                                width="100%"
                                                height="100%"
                                                controlsList="nodownload"
                                                autoPlay={false} alt="preview" />
                                        </div>)
                                    })}</div>

                            </> : null}

                            <h2 className="mt-3 mb-4">{t('Return Items')}</h2>
                            <div style={{ marginBottom: '10%' }}>
                                {returnDetailsData.orderReturnLineItems &&
                                    returnDetailsData.orderReturnLineItems.length > 0 ? returnDetailsData.orderReturnLineItems.map((item, rkey) => {
                                        let imgUrl = item.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url
                                        imgUrl = MediaUrl.completeMediaUrl(imgUrl, true, 100, 100)

                                        return (<div key={rkey}
                                            className={'buyingBrder'}>

                                            {imgUrl ? <div className={'imgdiv'}>
                                                <img src={imgUrl} alt={'clothImg'} />
                                            </div> : <div className={'imgdiv'} ></div>}

                                            <div className={'textdiv'}>
                                                <b>{item.productOrderLineItem.name}</b>
                                                <p>{`${t('Price')}/${t('Unit')} (${t('Including tax')}) : ${item.vendorPricePerSet}`}</p>
                                            </div>

                                            <div className={'counter'}>
                                                <div>
                                                    <button
                                                        disabled={disableMinusBtn}
                                                        onClick={(e) => this.decreaseQty(item, rkey)}>
                                                        - </button>
                                                </div>
                                                <div>{item.optedQty}</div>
                                                <div>
                                                    <button disabled={disablePlusBtn}
                                                        onClick={(e) => this.increaseQty(item, rkey)}>
                                                        + </button>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    }) : null}
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className='stickToBottom'>
                            <div className='button_div'>
                                <Button
                                    className={'buynowBtn'}
                                    loading={this.props.loading}
                                    disabled={fileList.length > 0 ? false : true}
                                    onClick={() => { this.createDispute() }}>
                                    {t('Submit')}</Button>
                            </div>
                        </Col>
                    </Row>

                </div>
            </LayoutComponent >
        );
    }
}

const mapStateToProps = ({ global }) => ({
    loading: global.buttonLoading,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderDisputeComponent)));
