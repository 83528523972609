import React, { Component } from "react";
import {
  Button,
  Card,
  Select,
  message,
  Input,
  Form,
  Tooltip,
  Radio,
} from "antd";
import {
  CloseCircleFilled,
  FileAddOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import KycImage from "../../assests/KYC_Document.svg";
import logo from "../../akar_dark.png";
import AuthService from "../../service/authService";
import MediaUrl from "../../service/mediaUrl";
import _ from "lodash";
import { PDFDocument } from "pdf-lib";

const { Option } = Select;
const { TextArea } = Input;

class KYCComponent extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      addressList: [],
      brand: {},
      isloaderVisible: false,
      selectedDoc: "",
      loading: false,
      enableAddressForm: false,
      states: [],
      addressData: [],
      gstNumber: "",
      gstName: "",
      selectedAddressId: "",
      gstDetailsData: [],
      defaultGstDetail: null,
      showLabelInput: false,
      labelValue: "",
    };
  }

  componentDidMount() {
    const { addresses } = this.props.currentUser.account;
    const defaultAddress = _.find(addresses, (item) => item.isDefault);
    this.setState({
      addressData: addresses,
      selectedAddressId: defaultAddress.id,
    });
    this.getGSTAccountDetails();
  }

  handleAddressChange = (val) => {
    if (val === "newAddress") {
      this.setState(
        {
          enableAddressForm: true,
          selectedAddressId: val,
        },
        () => {
          this.getStates();
        }
      );
    } else {
      this.setState({
        selectedAddressId: val,
        enableAddressForm: false,
      });
    }
  };

  getStates = () => {
    AuthService.getStates().then(
      (res) => {
        this.setState({ states: res.data.data });
      },
      (err) => {}
    );
  };

  getGSTAccountDetails = async () => {
    const { t } = this.props;
    let { data } = await AuthService.getAllGSTAccounts();
    if (data.isSuccess) {
      const defaultGst = _.find(data.data, (item) => item.isDefault);
      this.setState({
        defaultGstDetail: defaultGst,
        gstDetailsData: data.data,
      });
    } else {
      return message.error(`${t("Error in fetching gst details")}`);
    }
  };

  isPdfPasswordProtected(file) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        const pdfBytes = new Uint8Array(event.target.result);
        try {
          const pdfDoc = await PDFDocument.load(pdfBytes, { password: "" });
          resolve(false);
        } catch (error) {
          if (
            error instanceof Error &&
            error.message ===
              "Input document to `PDFDocument.load` is encrypted. You can use `PDFDocument.load(..., { ignoreEncryption: true })` if you wish to load the document anyways."
          ) {
            resolve(true);
          } else {
            reject(error);
          }
        }
      };
    })
  }

  onChange = (e) => {
    const { t } = this.props;
    if (e.target.files) {
      let selectedFiles = [],
        files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        let imagefile = files[i];
        let nameStr = files[i].name;
        const fileExtn = nameStr.substr(nameStr.lastIndexOf("."));
        if (
          fileExtn === ".jpg" ||
          fileExtn === ".jpeg" ||
          fileExtn === ".png"
        ) {
          let blob = imagefile.slice(0, imagefile.size, imagefile.type);
          imagefile = new File([blob], moment().valueOf() + fileExtn, {
            type: imagefile.type,
          });
          selectedFiles.push(imagefile);
          this.addDocImageInAWS(selectedFiles);
        } else if(fileExtn === ".pdf" && files[i]){
          let blob = imagefile.slice(0, imagefile.size, imagefile.type);
          imagefile = new File([blob], moment().valueOf() + fileExtn, {
            type: imagefile.type,
          });
          this.isPdfPasswordProtected(imagefile)
          .then((isProtected) => {
            if(isProtected){
              return message.error(`${t("Password protected documents cannot be uploaded")}`)
            } else{
              selectedFiles.push(imagefile);
              this.addDocImageInAWS(selectedFiles);
            }
          })
          .catch((err) => {
            console.log(err);
          })
        }
        else {
          return message.error(
            `${t("Attach file in png/jpg/jpeg/pdf format")}`
          );
        }
      }
    }
    document.getElementById("myInput").value = null;
  };

  deleteImage = (file, index) => {
    this.deleteDocImageFromAWS(file);
    var array = [...this.state.fileList];
    array.splice(index, 1);
    this.setState({ fileList: array });
  };

  onChangeAdd = (e) => {
    const { t } = this.props;
    if (e.target.files) {
      let selectedAddFiles = [],
        files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        let imagefile = files[i];
        let nameStr = files[i].name;
        const fileExtn = nameStr.substr(nameStr.lastIndexOf("."));
        if (
          fileExtn === ".jpg" ||
          fileExtn === ".jpeg" ||
          fileExtn === ".png"
        ) {
          let blob = imagefile.slice(0, imagefile.size, imagefile.type);
          imagefile = new File([blob], moment().valueOf() + fileExtn, {
            type: imagefile.type,
          });
          selectedAddFiles.push(imagefile);
          this.addAddressDocInAWS(selectedAddFiles);
        } else if(fileExtn === ".pdf" && files[i]){
          let blob = imagefile.slice(0, imagefile.size, imagefile.type);
          imagefile = new File([blob], moment().valueOf() + fileExtn, {
            type: imagefile.type,
          });
          this.isPdfPasswordProtected(imagefile)
          .then((isProtected) => {
            if(isProtected){
              return message.error(`${t("Password protected documents cannot be uploaded")}`)
            } else{
              selectedAddFiles.push(imagefile);
              this.addAddressDocInAWS(selectedAddFiles);
            }
          })
          .catch((err) => {
            console.log(err);
          })
        }
        else {
          return message.error(
            `${t("Attach file in png/jpg/jpeg/pdf format")}`
          );
        }
      }
    }
    document.getElementById("myInput").value = null;
  };

  deleteAddImage = (file, index) => {
    this.deleteDocImageFromAWS(file);
    var array = [...this.state.addressList];
    array.splice(index, 1);
    this.setState({ addressList: array });
  };

  handleChange = (value) => {
    this.setState({ selectedDoc: value });
    this.setState({ fileList: [] });
  };

  addAddressDocInAWS = (data) => {
    AuthService.addUserKYCDocImage(data, "ACCOUNT_DOCUMENT_FILE").then(
      (res) => {
        let response = res.data.data;
        let addressFiles = this.state.addressList;
        addressFiles.push(...response);
        this.setState({ addressList: addressFiles });
      },
      (err) => {}
    );
  };

  addDocImageInAWS = (data) => {
    this.setState({ isloaderVisible: true });
    AuthService.addUserKYCDocImage(data, "ACCOUNT_DOCUMENT_FILE").then(
      (res) => {
        let response = res.data.data;
        let imageFiles = this.state.fileList;
        imageFiles.push(...response);
        this.setState({ fileList: imageFiles, isloaderVisible: false });
      },
      (err) => {}
    );
  };

  deleteDocImageFromAWS = (data, index) => {
    AuthService.deleteDocImage(data).then(
      (res) => {},
      (err) => {}
    );
  };

  hitKycDocsApi = (dataObj) => {
    const { dispatch, onKycDone } = this.props;
    AuthService.uploadKYCDocuments(dataObj).then(
      (res) => {
        let { data } = res.data;
        this.setState({ loading: false });
        dispatch({
          type: "SET_CURRENT_USER_ACCOUNT_DETAILS",
          account: data,
        });
        if (onKycDone) {
          onKycDone();
        }
      },
      (err) => {}
    );
  };

  onGoToRegistration = () => {
    const { goToRegistration } = this.props;
    goToRegistration();
  };

  submitKYC = async () => {
    const { t } = this.props;
    const {
      selectedDoc,
      addressList,
      fileList,
      gstName,
      gstNumber,
      selectedAddressId,
      gstDetailsData,
      labelValue,
    } = this.state;

    let dataObj = [];
    let addressObject =
      (this.formRef &&
        this.formRef.current &&
        this.formRef.current.getFieldsValue()) ||
      {};

    if(selectedDoc === "GST" && fileList.length > 1){
      return message.error(`${t("Cannot submit more than one GST file")}`)
    }

    if (selectedDoc === "GST" && !gstDetailsData.length) {
      if (selectedAddressId === "newAddress") {
        addressObject.label =
          addressObject.label === "Other" ? labelValue : addressObject.label;
        if (
          addressObject.label === "" ||
          addressObject.label === undefined ||
          addressObject.label === null
        ) {
          return message.error(`${t("Address label reqd validation")}`);
        }
        if (
          addressObject.addressLine1 === "" ||
          addressObject.addressLine1 === undefined ||
          addressObject.addressLine1 === null
        ) {
          return message.error(`${t("Address line 1 reqd validation")}`);
        }
        if (
          addressObject.addressLine2 === "" ||
          addressObject.addressLine2 === undefined ||
          addressObject.addressLine2 === null
        ) {
          return message.error(`${t("Address line 2 reqd validation")}`);
        }
        if (
          addressObject.city === "" ||
          addressObject.city === undefined ||
          addressObject.city === null
        ) {
          return message.error(`${t("City reqd validation")}`);
        }

        if (!addressObject.stateId) {
          return message.error(`${t("State reqd validation")}`);
        }

        if (!addressObject.zipCode) {
          return message.error(`${t("Pincode reqd validation")}`);
        }
      }

      if (gstName === "") {
        return message.error(`${t("Gst name reqd validation")}`);
      }
      if (gstNumber === "") {
        return message.error(`${t("GST Number reqd validation")}`);
      }
    }
    if (addressList.length > 5 || fileList.length > 5) {
      return message.error(`${t('"Documents files need to be less than 5"')}`);
    }

    if (addressList.length > 0) {
      addressList.forEach((item, key) => {
        item.url = MediaUrl.trimMediaUrl(item.url);
        let obj = {
          type: "KycDocuments",
          fileUploadedData: {
            name: item.fileName,
            type: selectedDoc,
            url: item.url,
          },
        };
        dataObj.push(obj);
      });
    }

    if (fileList.length > 0) {
      fileList.forEach((item, key) => {
        item.url = MediaUrl.trimMediaUrl(item.url);
        let obj = {
          type: "KycDocuments",
          fileUploadedData: {
            name: item.fileName,
            type: selectedDoc,
            url: item.url,
          },
        };
        dataObj.push(obj);
      });
    }
    if (selectedDoc === "GST" && !gstDetailsData.length) {
      let temp = { name: gstName.trim(), number: gstNumber.trim() };
      if (selectedAddressId === "newAddress" && addressObject) {
        const regExp = new RegExp(
          /^([a-zA-Z\-_/,. ]*)+\d+[a-zA-Z0-9\-_/,. ]*/g
        );
        addressObject["country"] = "India";

        if (
          addressObject.addressLine1 &&
          !regExp.test(addressObject.addressLine1)
        ) {
          return message.error(`${t("Address Line 1 combination validation")}`);
        }

        if (
          addressObject.addressLine1 &&
          addressObject.addressLine1.length < 10
        ) {
          return message.error(`${t("Address Line 1 length10 validation")}`);
        }
        if (
          addressObject.addressLine1 &&
          addressObject.addressLine1.length > 50
        ) {
          return message.error(`${t("Address Line 1 length50 validation")}`);
        }

        this.setState({ loading: true });
        let { data } = await AuthService.addAddress(addressObject);
        if (data.isSuccess) {
          message.success(`${t("Address added successfully")}`);
          this.formRef.current.resetFields();
          this.setState((state, props) => ({
            enableAddressForm: false,
            selectedAddressId: data.data.id,
            addressData: [...state.addressData, data.data],
          }));
          temp["addressId"] = data.data.id;
          let { data: gstResp } = await AuthService.createGSTAccount(temp);
          if (gstResp.isSuccess) {
            message.success(`${t("Gst account added successfully")}`);
            this.hitKycDocsApi(dataObj);
          }
        } else {
          message.error(`${t("Error in saving")}`);
        }
      } else {
        temp["addressId"] = selectedAddressId;
        this.setState({ loading: true });
        let { data: gstResp } = await AuthService.createGSTAccount(temp);
        if (gstResp.isSuccess) {
          message.success(`${t("Gst account added successfully")}`);
          this.hitKycDocsApi(dataObj);
        }
      }
    } else {
      this.setState({ loading: true });
      this.hitKycDocsApi(dataObj);
    }
  };

  checkLabelValue = (val) => {
    if (val === "Home" || val === "Office") {
      this.setState({ showLabelInput: false });
    } else {
      this.setState({ showLabelInput: true });
    }
  };
  onLoadSuccess = () => {
    alert("hello");
  };

  render() {
    const {
      fileList,
      selectedDoc,
      addressList,
      loading,
      states,
      gstDetailsData,
      labelValue,
      enableAddressForm,
      selectedAddressId,
      addressData,
      gstNumber,
      gstName,
      defaultGstDetail,
      showLabelInput,
    } = this.state;
    const { currentUser, t } = this.props;
    const userType =
      currentUser && currentUser.account && currentUser.account.type;

    return (
      <>
        <div
          className={
            selectedDoc !== "PAN and AddressProof" ? "" : "content-center"
          }
          style={{
            height: selectedDoc !== "PAN and AddressProof" ? "" : "120vh",
          }}
        >
          <div className="justify-content-center">
            <div>
              <div className="justify-content-center mb-4">
                <img height="100" src={logo} alt="logo" />
              </div>
              <Card className="kyc_card">
                <div className="justify-content-center mb-4">
                  <img height="120" src={KycImage} alt={"kycdocument"} />
                </div>
                <div className="text-align-center mb-3">
                  <h3> {t("KYC")} </h3>
                </div>

                <p className="font16px mb-2 textcolor">{t("Document type")}*</p>

                <Select
                  defaultValue=""
                  onChange={this.handleChange}
                  style={{ width: "100%" }}
                >
                  <Option value="" disabled>
                    {t("Select document type")}
                  </Option>
                  {userType === "Seller" && (
                    <>
                      <Option value="GST"> {t("GST")}</Option>
                    </>
                  )}
                  {userType === "Buyer" && (
                    <>
                      <Option value="Aadhaar">{t("Aadhaar")}</Option>
                      <Option value="FSSAI">{t("FSSAI")}</Option>
                      <Option value="Trademark">{t("Trademark")}</Option>
                      <Option value="Municipal Trade License">
                        {t("Municipal Trade License")}
                      </Option>
                      <Option value="PAN and AddressProof">
                        {t("PAN + AddressProof")}
                      </Option>
                      <Option value="Trade Licence">
                        {t("Trade Licence")}
                      </Option>
                      <Option value="GST"> {t("GST Number")}</Option>
                      <Option value="Shop Visiting Card">
                        {t("Shop Visiting Card")}
                      </Option>
                      <Option value="Invoice (With Shop Name)">
                        {t("Invoice (With Shop Name)")}
                      </Option>
                      <Option value="Shop Image (With Shop Name Banner)">
                        {t("Shop Image (With Shop Name Banner)")}
                      </Option>
                    </>
                  )}
                </Select>

                {selectedDoc !== "" && (
                  <>
                    {/* {(selectedDoc === 'GST' && userType === 'Seller') && <> */}

                    {selectedDoc ===
                      ("GST" ||
                        "Invoice (With Shop Name)" ||
                        "Shop Image (With Shop Name Banner)" ||
                        "Trade Licence") && !gstDetailsData.length ? (
                      <>
                        <p className="font16px mb-1 mt-2 textcolor">
                          {t("GST address")}*
                        </p>

                        <Select
                          placeholder={t("Select GST address")}
                          onChange={this.handleAddressChange}
                          value={selectedAddressId}
                          style={{ width: "100%" }}
                        >
                          {addressData && addressData.length > 0
                            ? addressData.map((item, aKey) => {
                                return (
                                  <Option value={item.id} key={aKey}>
                                    {`${item.addressLine1}, ${item.addressLine2}, ${item.city} ${item.stateName} ${item.zipCode}`}
                                  </Option>
                                );
                              })
                            : null}
                          <Option value="newAddress">
                            {t("Add new address")}
                          </Option>
                        </Select>

                        {enableAddressForm === true && (
                          <Form
                            ref={this.formRef}
                            name="addGstAddress_form"
                            scrollToFirstError
                            layout="vertical"
                            style={{
                              margin: "30px 0 0 0",
                              padding: "16px 8px 0 8px",
                              border: "1px solid #d9d9d9",
                            }}
                            requiredMark={false}
                          >
                            <Form.Item
                              label={`${t("Address label")}*`}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: `${t(
                                    "Address label reqd validation"
                                  )}`,
                                },
                              ]}
                              name="label"
                              style={{
                                marginBottom: `${
                                  showLabelInput ? "5px" : "24px"
                                }`,
                              }}
                            >
                              <Select
                                onChange={(val) => this.checkLabelValue(val)}
                              >
                                <Option value="Home">Home</Option>
                                <Option value="Office">Office</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Form.Item>

                            {showLabelInput ? (
                              <Input
                                style={{ marginBottom: "24px" }}
                                maxLength={80}
                                defaultValue={labelValue}
                                placeholder={t("Home_office")}
                                onChange={(e) =>
                                  this.setState({ labelValue: e.target.value })
                                }
                              />
                            ) : null}

                            <Form.Item
                              label={`${t("Address line 1")}*`}
                              name="addressLine1"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: `${t(
                                    "Address line 1 reqd validation"
                                  )}`,
                                },
                                {
                                  pattern: new RegExp(
                                    /^([a-zA-Z\-_/,. ]*)+\d+[a-zA-Z0-9\-_/,. ]*/g
                                  ),
                                  required: true,
                                  message: `${t(
                                    "Address Line 1 combination validation"
                                  )}`,
                                },
                                {
                                  min: 10,
                                  message: `${t(
                                    "Address Line 1 length10 validation"
                                  )}`,
                                },
                                {
                                  max: 50,
                                  message: `${t(
                                    "Address Line 1 length50 validation"
                                  )}`,
                                },
                              ]}
                              validateTrigger="onBlur"
                            >
                              <Tooltip title={t("Address line 1 tooltip msg")}>
                                <Input
                                  placeholder={t("Building No_colony")}
                                  onChange={(e) => {
                                    this.formRef.current.setFieldsValue({
                                      addressLine1: e.target.value,
                                    });
                                  }}
                                />
                              </Tooltip>
                            </Form.Item>

                            <Form.Item
                              label={`${t("Address line 2")}*`}
                              name="addressLine2"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: `${t(
                                    "Address line 2 reqd validation"
                                  )}`,
                                },
                                {
                                  pattern: new RegExp(
                                    /^([a-zA-Z\-_/,. ]*)+\d+[a-zA-Z0-9\-_/,. ]*/g
                                  ),
                                  required: true,
                                  message: `${t(
                                    "Address Line 2 combination validation"
                                  )}`,
                                },
                                {
                                  min: 10,
                                  message: `${t(
                                    "Address Line 2 length10 validation"
                                  )}`,
                                },
                                {
                                  max: 50,
                                  message: `${t(
                                    "Address Line 2 length50 validation"
                                  )}`,
                                },
                              ]}
                              validateTrigger="onBlur"
                            >
                              <Input
                                maxLength={80}
                                placeholder={t("Area_District")}
                              />
                            </Form.Item>

                            <Form.Item
                              label={`${t("City")}*`}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: `${t("City reqd validation")}`,
                                },
                              ]}
                              name="city"
                            >
                              <Input maxLength={80} placeholder={t("City")} />
                            </Form.Item>

                            <Form.Item
                              name="stateId"
                              label={`${t("State")}*`}
                              rules={[
                                {
                                  required: true,
                                  message: `${t("State reqd validation")}`,
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder={t("Select state")}
                              >
                                {states &&
                                  states.map((item, skey) => {
                                    return (
                                      <Option key={skey} value={item.id}>
                                        {item.name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label={`${t("PinCode")}*`}
                              name="zipCode"
                              rules={[
                                {
                                  required: true,
                                  message: `${t("Pincode reqd validation")}`,
                                  whitespace: true,
                                },
                                {
                                  len: 6,
                                  message: `${t("Invalid length pincode")}`,
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                placeholder={t("6 digit pincode")}
                              />
                            </Form.Item>
                          </Form>
                        )}

                        <p className="font16px my-1 textcolor">
                          {t("GST name")}*
                        </p>
                        <Input
                          style={{ width: "100%" }}
                          value={gstName}
                          onChange={(val) => {
                            const regExp = new RegExp(/^[a-zA-Z][a-zA-Z ]*$/);
                            if (
                              regExp.test(val.target.value) ||
                              val.target.value === ""
                            ) {
                              this.setState({ gstName: val.target.value });
                            } else {
                              return message.error(
                                `${t("GST Name must contain alphabets only")}`
                              );
                            }
                          }}
                          className="mb-2"
                          placeholder={t("GST name")}
                        />

                        <p className="font16px mb-1 textcolor">
                          {t("GST number")}*
                        </p>
                        <Input
                          style={{ width: "100%" }}
                          value={gstNumber}
                          className="mb-2"
                          onChange={(val) => {
                            const regExp = new RegExp(/^[a-zA-Z0-9]*$/);
                            if (
                              regExp.test(val.target.value) ||
                              val.target.value === ""
                            ) {
                              this.setState({
                                gstNumber: val.target.value.toUpperCase(),
                              });
                            } else {
                              return message.error(
                                `${t(
                                  "GST Number must be a combination of alphabets and numbers only"
                                )}`
                              );
                            }
                          }}
                          placeholder={t("GST number")}
                        />
                      </>
                    ) : selectedDoc === "GST" && gstDetailsData.length ? (
                      <div className={"mt-2"} style={{ fontWeight: "500" }}>
                        <b>Note - </b>
                        {`${t(
                          "Please upload GST documents corresponding to Gst Number"
                        )} - `}
                        <b>
                          {defaultGstDetail && `${defaultGstDetail.number}.`}
                        </b>
                      </div>
                    ) : null}

                    <div className="brand_list mt-2 pl-1 pr-0">
                      <div className="">
                        {selectedDoc !== "PAN and AddressProof" ? (
                          <p>
                            {selectedDoc === "GST"
                              ? `${t("Add Documents for GST")}`
                              : null}
                            {selectedDoc === "Aadhaar"
                              ? `${t("Add Documents for Aadhaar")}`
                              : null}
                            {selectedDoc === "FSSAI"
                              ? `${t("Add Documents for FSSAI")}`
                              : null}
                            {selectedDoc === "Trademark"
                              ? `${t("Add Documents for Trademark")}`
                              : null}
                            {selectedDoc === "Municipal Trade License"
                              ? `${t(
                                  "Add Documents for Municipal Trade License"
                                )}`
                              : null}
                          </p>
                        ) : (
                          <p>{t("Add Documents for PAN")}</p>
                        )}

                        <div className="step">
                          <div className="assets">
                            <input
                              id="myInput"
                              type="file"
                              ref={(ref) => (this.myInput = ref)}
                              multiple
                              onChange={this.onChange.bind(this)}
                              name="files"
                              accept=".png,.jpeg,.jpg,.pdf"
                              style={{ display: "none" }}
                              onLoad={this.onLoadSuccess}
                            />

                            <button
                              className="float-right mr-3 file"
                              onClick={() => {
                                this.myInput.click();
                              }}
                            >
                              <FileAddOutlined />
                            </button>
                            {fileList.map((item, i) => {
                              let pdfCondition = item.url.includes(".pdf");

                              if (pdfCondition) {
                                let url = MediaUrl.completeMediaUrl(
                                  item.url,
                                  false,
                                  100,
                                  100
                                );
                                return (
                                  <div key={i} className="file">
                                    <a
                                      className="fileDoc"
                                      href={`${url}`}
                                      target="_blank"
                                    >
                                      <FilePdfOutlined />
                                    </a>

                                    <span
                                      className="remove-pdf"
                                      onClick={() =>
                                        this.deleteImage(item.url, i)
                                      }
                                    >
                                      <CloseCircleFilled />
                                    </span>
                                  </div>
                                );
                              } else {
                                let url = MediaUrl.completeMediaUrl(
                                  item.url,
                                  true,
                                  100,
                                  100
                                );
                                return (
                                  <div key={i} className="file">
                                    <img src={url} alt="Preview unavailable" />
                                    <div className="container">
                                      <span
                                        className="remove-btn"
                                        onClick={() =>
                                          this.deleteImage(item.url, i)
                                        }
                                      >
                                        <CloseCircleFilled />
                                      </span>
                                    </div>
                                    {/* {isloaderVisible ? <LoaderComponent /> : <>
                                                                        <img src={url}
                                                                            alt='Preview unavailable' />
                                                                        <div className="container">
                                                                            <span className="remove-btn"
                                                                                onClick={() => this.deleteImage(item.url, i)}>
                                                                                <CloseCircleFilled />
                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                    } */}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>

                    {selectedDoc === "PAN and AddressProof" && (
                      <div className="brand_list mt-2 pl-1 pr-0">
                        <div className="">
                          <p>{t("Add Documents for AddressProof")}</p>

                          <div className="step">
                            <div className="assets">
                              <input
                                id="myInput"
                                type="file"
                                name="files"
                                multiple
                                ref={(ref) => (this.myAddInput = ref)}
                                onChange={this.onChangeAdd.bind(this)}
                                accept=".png,.jpeg,.jpg,.pdf"
                                style={{ display: "none" }}
                              />
                              <button
                                className="float-right mr-3 file"
                                onClick={() => {
                                  this.myAddInput.click();
                                }}
                              >
                                <FileAddOutlined />
                              </button>
                              {addressList.map((item, i) => {
                                let pdfCondition = item.url.includes(".pdf");

                                if (pdfCondition) {
                                  let url = MediaUrl.completeMediaUrl(
                                    item.url,
                                    false,
                                    100,
                                    100
                                  );
                                  return (
                                    <div key={i} className="file">
                                      <a
                                        className="fileDoc"
                                        href={`${url}`}
                                        target="_blank"
                                      >
                                        <FilePdfOutlined />
                                      </a>

                                      <span
                                        className="remove-pdf"
                                        onClick={() =>
                                          this.deleteAddImage(item.url, i)
                                        }
                                      >
                                        <CloseCircleFilled />
                                      </span>
                                    </div>
                                  );
                                } else {
                                  let url = MediaUrl.completeMediaUrl(
                                    item.url,
                                    true,
                                    100,
                                    100
                                  );
                                  return (
                                    <div key={i} className="file">
                                      <img
                                        src={url}
                                        alt="Preview unavailable"
                                      />
                                      <div className="container">
                                        <span
                                          className="remove-btn"
                                          onClick={() =>
                                            this.deleteAddImage(item.url, i)
                                          }
                                        >
                                          <CloseCircleFilled />
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {this.state.selectedDoc === "PAN and AddressProof" ? (
                  fileList.length !== 0 && addressList.length !== 0 ? (
                    <Button
                      className="my-button mt-4"
                      htmlType="button"
                      loading={loading}
                      onClick={this.submitKYC}
                    >
                      {t("Submit")}
                    </Button>
                  ) : null
                ) : fileList.length !== 0 ? (
                  <Button
                    className="my-button mt-4"
                    htmlType="button"
                    loading={loading}
                    onClick={this.submitKYC}
                  >
                    {t("Submit")}
                  </Button>
                ) : null}
                <Button
                  className="mt-4"
                  style={{ float: "right" }}
                  htmlType="button"
                  loading={loading}
                  onClick={this.onGoToRegistration}
                >
                  Go Back
                </Button>
              </Card>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  loading: global.buttonLoading,
  token: global.token,
  currentUser: global.currentUser,
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(KYCComponent))
);
