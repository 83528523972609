import API from './network';
import Urls from './urls'
import { PaymentStatus } from './statusTypes'
const CartService = {

    addItemsToCart(data) {
        const path = Urls.addCartItems
        var body = data
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getCartItemsData() {
        const path = Urls.allCartItems
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    deleteCartById(id) {
        const path = Urls.deleteCartById + id
        return API.makeDeleateRequest(path, null, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getCartItemById(id) {
        const path = Urls.getCartById + id
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    deleteOrderLineItemById(data) {
        const path = Urls.deleteOrderLineItemById + data.cartId + '/orderlineitem/' + data.orderId
        return API.makeDeleateRequest(path, null, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    updateOrderLineItemById(data) {
        const path = Urls.updateOrderLineItem + data.cartId + '/orderlineitem/' + data.orderId
        var body = data.body
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    updateOrderLineItemSpecialNote(data) {
        const path = Urls.updateSpecialNote + data.cartToken
        var body = data.body
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    orderByCartId(data) {
        const path = Urls.createOrderByCartId
        let body = data

        return API.makePostRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getAllOrdersList(data) {
        let path
        if (data.status) {
            path = `${Urls.getAllOrders}?pageNumber=${data.pageNumber}&limit=${data.limit}&q=${data.searchStr}&status=${data.status}&filter=${data.filter}`
        } else {
            path = `${Urls.getAllOrders}?pageNumber=${data.pageNumber}&limit=${data.limit}&q=${data.searchStr}&filter=${data.filter}`
        }
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    orderById(orderId) {
        const path = Urls.getOrderById + orderId
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    updatePaymentTypeStatus(data){
        const path = Urls.updatePaymentStatus;
        return API.makePutRequest(path, null, null, data, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getAllPaymentOption(data) {
        let path
        if (data.orderCustomId) {
            path = `${Urls.getPaymentOptions}?orderCustomId=${data.orderCustomId}`

        } else {
            path = `${Urls.getPaymentOptions}?shoppingCartToken=${data.cartToken}&shipToAddressId=${data.shipToAddressId}`
        }
        // path = `${Urls.getPaymentOptions}?shoppingCartToken=${shopingCartId}&shipToAddressId=${shipToAddressId}`
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    verifyPayment(response, type, orderId) {
        const path = Urls.verifyPayment
        let body = { paymentType: type, orderCustomId: orderId }
        if (type === 0) {
            //cod case
            body.paymentGatewayOrderId = response

        } else {
            //case prepaid 
            if (response && response.razorpay_signature) {
                //case of success
                body.paymentStatus = PaymentStatus.Success
                body.paymentGatewayId = response.razorpay_payment_id
                body.paymentGatewayOrderId = response.razorpay_order_id
            }
            else {
                //case of failure
                body.paymentStatus = PaymentStatus.Failure
                body.paymentGatewayId = response.error.metadata.payment_id
                body.paymentGatewayOrderId = response.error.metadata.order_id
            }
        }

        return API.makePutRequest(path, { "paymentGatewaySignature": response.razorpay_signature || '' }, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    readyToShip(body) {
        const path = Urls.readyToShipPacket
        return API.makePostRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    updateShipPacket(data) {
        const path = Urls.updateShipedPacket
        return API.makePutRequest(path, null, null, data, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getAllReturnOrders(data) {
        let path
        if (data.status) {
            let newStatus = data.status;
            if (newStatus && Array.isArray(newStatus)) {
                newStatus = newStatus.map(key => `${'status'}=${key}`).join("&");
                path = `${Urls.returnOrders}?${newStatus}&filter=${data.filter}&pageNumber=${data.pageNumber}&limit=${data.limit}&q=${data.searchStr}`
            } else {
                path = `${Urls.returnOrders}?status=${newStatus}&filter=${data.filter}&pageNumber=${data.pageNumber}&limit=${data.limit}&q=${data.searchStr}`
            }
        } else {
            path = `${Urls.returnOrders}?filter=${data.filter}&pageNumber=${data.pageNumber}&limit=${data.limit}&q=${data.searchStr}`
        }

        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getSingleReturnOrder(orderReturnId) {
        const path = Urls.singleReturnOrder + orderReturnId
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getSingleGetReturnOrder(orderReturnId) {
        const path = Urls.singleGetReturnOrder + orderReturnId
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },


    getReturnShipmentDetails(orderReturnId) {
        const path = Urls.returnShipment + orderReturnId
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    processToHold(days, comment, orderId) {
        const path = Urls.updateOrderStatusToHold
        let body = {
            orderCustomId: orderId,
            comment: comment,
            numberOfDays: days
        }
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    cancelOrderById(orderId, reason) {
        const path = Urls.cancelOrder + orderId + '/cancel'
        let body = { reason }
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    shipmentTrackingDetails(orderId) {
        const path = Urls.shipmentTracking + orderId
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    retryOrderPayment(orderId, paymentType) {
        const path = Urls.retryFailedOrder + orderId + '/retry'
        let body = { paymentType }
        return API.makePutRequest(path, null, null, body, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },

    getorderTracking(id, status) {
        const path = Urls.orderTracking + id + '/track/' + status;
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                if (res.status === 200) {
                    return res.data.data || {}
                } else {
                    return {}
                }
            }).catch((err) => { return {} })
    },
    createOrderReturn(orderId, body) {
        const path = Urls.singleReturnOrder + orderId
        return API.makePostRequest(path, null, null, body, null)
            .then((res) => {
                if (res.status === 200) {
                    return res.data || {}
                } else {
                    return {}
                }
            }).catch((err) => { return err })
    },
    getWarehouseDetails(orderReturnId) {
        const path = Urls.warehouse + orderReturnId
        return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res
            }).catch((err) => { return err })
    },
    submitProductRating(body) {
        const path = Urls.addProductRating
        return API.makePostRequest(path, null, null, body, null)
            .then((res) => {
                if (res.status === 200) {
                    return res.data || {}
                } else {
                    return {}
                }
            }).catch((err) => { return err })
    },
}


export default CartService;