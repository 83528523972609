import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LayoutComponent from "../layoutComponents/layoutComponent";
import {
  Row,
  Col,
  Input,
  Button,
  message,
  Select,
  Form,
  Modal,
  Skeleton,
} from "antd";
import userImg from "../../assests/user.png";
import MediaUrl from "../../service/mediaUrl";
import { logout } from "../../actions";
import AuthService from "../../service/authService";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import TeamService from "../../service/teamService";

class ManageTeam extends Component {
  constructor(props) {
    super(props);
    this.isSeller = props.currentUser.account.type === "Seller" ? true : false;
    this.currentUserAccountId = props.currentUser.account.id
      ? props.currentUser.account.id
      : null;
    this.currentUserId = props.currentUser.id ? props.currentUser.id : null;
    this.isOwner = props.currentUser.roles.includes("AccountOwner");
    this.state = {
      loading: false,
      showBankModal: false,
      mobileNumber: "",
      inviteTeamMemberModal: false,
      selectedMemberId: "",
      teamMembers: null,
      warning: false,
      confirmDeleteAccountModal: false,
    };
  }

  componentDidMount() {
    this.getAllMembers(this.currentUserAccountId);
  }

  getAllMembers = async (id) => {
    let { data } = await TeamService.getAllMembers(id);
    if (data) {
      this.setState({ teamMembers: data.data });
    }
  };

  removeMember = async (id) => {
    this.setState({ loading: true });
    const { t } = this.props;
    let { data } = await TeamService.removeMember(id);
    if (data.isSuccess) {
      this.getAllMembers(this.currentUserAccountId);
      this.setState({ loading: false });
      message.success(`${t("Removed member successfully")}`);
    } else {
      {
        (data.errors && message.error(data.errors)) ||
          message.error(data.message);
      }
    }
  };

  inviteMember = async (mobile) => {
    const { t } = this.props;
    if (mobile.length === 10) {
      if (mobile[0] >= 6) {
        this.setState({
          warning: false,
          loading: true,
        });
        let { data } = await TeamService.inviteMember(mobile);
        if (data.isSuccess) {
          this.getAllMembers(this.currentUserAccountId);
          this.setState({ loading: false });
          message.success(data.message);
          this.handleInviteModalCancel();
        } else {
          {
            (data.errors && message.error(data.errors)) ||
              (data.message &&
                data.message === "User already exists with another account" &&
                message.error(`${t("User already exists")}`)) ||
              message.error(data.message);
          }
          this.setState({ loading: false });
        }
      } else {
        message.error(`${t("Invalid phone number")}`);
      }
    } else {
      this.setState({
        warning: true,
        loading: false,
      });
    }
  };

  deleteAccount = async () => {
    let { data } = await TeamService.closeAccount();
    if (data.isSuccess) {
      message.success(data.message);
      this.handleDeleteAccountModalCancel();
      this.logout();
    } else {
      message.error(data.message);
      this.handleDeleteAccountModalCancel();
    }
  };

  logout = async () => {
    const { dispatch } = this.props;
    await AuthService.deleteNotificationToken();
    dispatch(logout());
    dispatch({ type: "SET_AUTH_TOKEN", token: null });
    dispatch({ type: "SET_CART_ITEMS_FLAG", isExist: false });
    if (typeof window !== 'undefined') { localStorage.removeItem('Authorization') };
    this.props.history.push({ pathname: "/" });
  };

  deleteAccountModalHandler = () => {
    this.setState({ confirmDeleteAccountModal: true });
  };

  handleMobileNumber = (value) => {
    if (value.length < 11) {
      this.setState({
        mobileNumber: value,
        warning: false,
      });
    }
  };

  handleInviteModalCancel = () => {
    this.setState({ inviteTeamMemberModal: false, mobileNumber: "" });
  };
  handleDeleteAccountModalCancel = () => {
    this.setState({ confirmDeleteAccountModal: false });
  };

  logoutFromAllDevices = async () => {
    let { data } = await AuthService.logoutFromAllDevices();
    if (data.isSuccess && data.applicationCode === 200) {
      message.success(data.message);
      this.logout();
    } else {
      message.error(data.message);
    }
  };

  render() {
    const {
      mobileNumber,
      inviteTeamMemberModal,
      teamMembers,
      confirmDeleteAccountModal,
    } = this.state;

    const { t } = this.props;

    return (
      <>
        <LayoutComponent title={t("Manage team")}>
          <Row>
            <Col span={24}>
              <div className="outer_card manage_team_container">
                <div className="text-right">
                  <Button
                    type="primary"
                    onClick={(e) => {
                      this.logoutFromAllDevices();
                    }}
                  >
                    {t("Logout from all devices")}
                  </Button>
                </div>
                <div className="heading">
                  {teamMembers && teamMembers.length === 1 ? (
                    <h2>
                      {teamMembers.length} {t("Member found")}
                    </h2>
                  ) : teamMembers ? (
                    <h2>
                      {teamMembers.length} {t("Members found")}
                    </h2>
                  ) : null}
                  <div className="spacer"></div>
                  {this.isOwner ? (
                    <button
                      className="card_button"
                      onClick={(e) =>
                        this.setState({ inviteTeamMemberModal: true })
                      }
                    >
                      {t("Invite to Team")}
                    </button>
                  ) : null}
                </div>

                {teamMembers && teamMembers.length > 0 ? (
                  teamMembers.map((item, index) => {
                    let imageUrl = MediaUrl.completeMediaUrl(
                      item.profileImageFileUploadedData.url,
                      true,
                      40,
                      40
                    );
                    return (
                      <div key={item.id} className="profile_card">
                        <Row className="row">
                          <Col sm={3} xs={3} className="col">
                            <img
                              src={imageUrl ? imageUrl : userImg}
                              alt="user image"
                              width="50"
                              height="50"
                            />
                          </Col>
                          <Col sm={8} xs={5} className="col">
                            <h4>{item.name ? item.name : ""}</h4>
                          </Col>
                          <Col sm={9} xs={6} className="col">
                            <h4>{item.phoneNumber ? item.phoneNumber : ""}</h4>
                          </Col>
                          <Col sm={4} xs={24} className="col">
                            {this.isOwner && item.id === this.currentUserId ? (
                              <Button
                                onClick={() => this.deleteAccountModalHandler()}
                                type="primary"
                                style={{ width: "160px" }}
                              >
                                {" "}
                                {t("Delete account")}{" "}
                              </Button>
                            ) : this.isOwner &&
                              item.id !== this.currentUserId ? (
                              <Button
                                onClick={() => this.removeMember(item.id)}
                                type="primary"
                                style={{ width: "90px" }}
                              >
                                {t("Remove")}
                              </Button>
                            ) : item.id === this.currentUserId ? (
                              <Button
                                type="primary"
                                style={{ width: "90px" }}
                              >
                                {" "}
                                {t("Leave")}{" "}
                              </Button>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                ) : (
                  <Skeleton active />
                )}
              </div>
            </Col>
          </Row>
        </LayoutComponent>

        <Modal
          title={t("Invite")}
          visible={inviteTeamMemberModal}
          onCancel={this.handleInviteModalCancel}
          footer={null}
          centered={true}
          maskClosable={false}
        >
          <div>
            <Form
              layout={"vertical"}
              scrollToFirstError
              onFinishFailed={this.onFinishFailed}
              onFinish={this.verifyPayment}
              requiredMark={false}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={`${t(
                      "Please enter the Mobile Number you want to invite to your team"
                    )}*`}
                    name="verifyAmount"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      prefix="+91 "
                      placeholder={t("Enter 10 digit mobile number")}
                      value={mobileNumber}
                      onChange={(val) =>
                        this.handleMobileNumber(val.target.value)
                      }
                    />
                    {this.state.warning ? (
                      <p style={{ color: "red", fontSize: 10 }}>
                        {`${t(
                          "Mobile number must contain 10 digits validation"
                        )}*`}
                      </p>
                    ) : null}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className={"submitBtn"}
                      loading={this.state.loading}
                      type="primary"
                      onClick={() => this.inviteMember(mobileNumber)}
                    >
                      {t("Invite")}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>
        <Modal
          title={t("Confirm")}
          visible={confirmDeleteAccountModal}
          onCancel={this.handleDeleteAccountModalCancel}
          onOk={() => this.deleteAccount()}
          okText={t("Delete account")}
          centered={true}
          maskClosable={false}
        >
          {t("Are you sure you want to delete this Account")}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ global }) => {
  return {
    currentUser: global.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageTeam))
);
