import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { commonRedirections } from "../../sections/redirections";
import { Carousel, Row, Col } from "antd";
import Api from "../../../service/network";
import MediaUrl from "../../../service/mediaUrl";
import "./index.scss";

const cloudFrontUrl = process.env.REACT_APP_CLOUD_FRONT_BASE_URL;

let staticPayloads = [
  {
    url: `${cloudFrontUrl}applicationdata/homepage/static/Abhipriya-Global.png`,
    id: 433,
    viewType: "seller",
    payLoad: null,
    name: null,
  },
  {
    url: `${cloudFrontUrl}applicationdata/homepage/static/Mobile-Charging.png`,
    id: 247,
    viewType: "category",
    payLoad: { filters: `( categoryName : "Mobile Charger")` },
    name: "Mobile Charger",
  },
  {
    url: `${cloudFrontUrl}applicationdata/homepage/static/Cables-Starting.png`,
    id: 246,
    viewType: "category",
    payLoad: { filters: `( categoryName : "Mobile Cable")` },
    name: "Mobile Cable",
  },
  {
    url: `${cloudFrontUrl}applicationdata/homepage/static/Memory-Card.png`,
    id: 247,
    viewType: "category",
    payLoad: { filters: `( categoryName : "Memory Card")` },
    name: "Memory Card",
  },
  {
    url: `${cloudFrontUrl}applicationdata/homepage/static/Mobile-Battery.png`,
    id: 265,
    viewType: "category",
    payLoad: { filters: `( categoryName : "Mobile Battery")` },
    name: "Mobile Battery",
  },
  // {
  //   url: "https://akar-prod-media.s3.ap-south-1.amazonaws.com/applicationdata/homepage/static/Pendrive-Starting.png",
  //   id: 247,
  //   viewType: "category",
  //   payLoad: { filters: `( categoryName : "Mobile Charger")` },
  //   name: "Mobile Charger"
  // },
  {
    url: `${cloudFrontUrl}applicationdata/homepage/static/Powerbank.png`,
    id: 248,
    viewType: "category",
    payLoad: { filters: `( categoryName : "Power Bank")` },
    name: "Power Bank",
  },
  {
    url: `${cloudFrontUrl}applicationdata/homepage/static/Wired-Earphones-Earbuds.png`,
    id: 192,
    viewType: "category",
    payLoad: { filters: `( categoryName : "Headphones & Earphones")` },
    name: "Headphones & Earphones",
  },
  {
    url: `${cloudFrontUrl}applicationdata/homepage/static/Wireless-Earphones-Earbuds.png`,
    id: 192,
    viewType: "category",
    payLoad: { filters: `( categoryName : "Headphones & Earphones")` },
    name: "Headphones & Earphones",
  },
];

class CarouselBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerItems: [],
    };
    this.token = typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null;
  }

  componentDidMount = () => {
    const { itemData } = this.props;
    if (!this.token) {
      this.setState({ bannerItems: staticPayloads });
    } else {
      if (itemData && itemData.callType === "Get") {
        let path = `${itemData.dataUrl}${itemData.queryParam}`;
        Api.makeGetRequest(path, null, null, null).then((res) => {
          let output = res.data.data.data;
          output.forEach((item) => {
            if (item.imageUrl) {
              let url = MediaUrl.completeMediaUrl(
                item.imageUrl,
                true,
                100,
                100
              );
              item.url = url;
            }
            if (item.payLoad && typeof item.payLoad === "string") {
              item.payLoad = JSON.parse(item.payLoad);
            }
            let parsedPayLoad = item.payLoad;
            item.payLoad = parsedPayLoad.payload;
            item.name = parsedPayLoad.displayName;
          });
          this.setState({ bannerItems: output });
        });
      }
    }
  };

  redirect = (item) => {
    if (!this.token) {
      if (item.viewType == "category") {
        let encoded = Buffer.from(
          JSON.stringify(item.payLoad),
          "ascii"
        ).toString("base64");
        let title = encodeURIComponent(item.name);
        this.props.history.push({
          pathname: "/login",
          search: `?redirect=search/listings/title=${title}/${encoded}`,
        });
      }
      if (item.viewType == "seller") {
        this.props.history.push({
          pathname: "/login",
          search: `?redirect=view-profile/${item.id}`,
        });
      }
    } else {
      let encoded = Buffer.from(JSON.stringify(item.payLoad), "ascii").toString(
        "base64"
      );
      let title = encodeURIComponent(item.name);
      this.props.history.push({
        pathname: `/search/listings/title=${title}/${encoded}`,
        search: ``,
      });

      if (item.viewType == "seller") {
        this.props.history.push({
          pathname: `/view-profile/${item.id}`,
          search: "",
        });
      }
    }
  };
  render() {
    const { bannerItems } = this.state;
    return (
      <Carousel effect="fade" autoplay className="carousel-body">
        {bannerItems &&
          bannerItems.length > 0 &&
          bannerItems.map((item, cKey) => {
            return (
              <div className="" key={cKey}>
                <Row
                  align="center"
                  justify="center"
                  className="carousel-body-row"
                >
                  <Col
                    span={24}
                    className="carousel-body-col"
                    onClick={() => this.redirect(item)}
                  >
                    <img src={item.url} alt="image" />
                  </Col>
                </Row>
              </div>
            );
          })}
      </Carousel>
    );
  }
}

export default withRouter(CarouselBanner);
