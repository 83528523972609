import React, { useEffect } from 'react';
import LayoutComponent from '../layoutComponents/layoutComponent';
import Footer from '../footer';
import { withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Layout from "./../layout";



const Content = (props) => {
    const { t } = props;
    return (
        <div className={'contact_container'}>
            <div className="WordSection1">
                <p className="MsoNormal" style={{
                    marginTop: '15.65pt', marginRight: '0in',
                    marginBottom: '9.4pt', marginLeft: '0in', lineHeight: 'normal'
                }}>
                    <b><span style={{
                        fontSize: '24.0pt', fontFamily: '"Times New Roman",serif',
                        color: '#221341'
                    }}>{t('Privacy Policy')}</span></b></p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                        {t("Last updated")}: February 17, 2021</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}>
                    <span style={{
                        fontSize: '12.0pt',
                        fontFamily: '"Times New Roman",serif'
                    }}>{t('Privacy p1')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Privacy p2')}
                </span></p>

                <p className="MsoNormal" style={{
                    marginTop: '15.65pt', marginRight: '0in',
                    marginBottom: '9.4pt', marginLeft: '0in', lineHeight: 'normal'
                }}><b>
                        <span style={{
                            fontSize: '24.0pt', fontFamily: '"Times New Roman",serif',
                            color: '#221341'
                        }}>{t('Interpretation and Definitions')}</span>
                    </b>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '0in', lineHeight: 'normal'
                }}><b>
                        <span style={{
                            fontSize: '18.0pt', fontFamily: '"Times New Roman",serif',
                            color: '#221341'
                        }}>{t('Interpretation')}</span>
                    </b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t('Interpretation p1')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '18.0pt',
                    fontFamily: '"Times New Roman",serif', color: '#221341'
                }}>{t('Definitions')}</span></b></p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}>
                    <span style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                        {t('For the purposes of this Privacy Policy')}
                    </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ fontSize: '12.0pt', lineHeight: '200%', fontFamily: '"Times New Roman",serif' }}>{t('Account')}</span></b>
                    <span style={{ fontSize: '12.0pt', lineHeight: '200%', fontFamily: '"Times New Roman",serif' }}>&nbsp;{t('Account para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Affiliate')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('Affiliate para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{
                    fontSize: '10.0pt', lineHeight: '200%',
                    fontFamily: 'Symbol'
                }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>{t('Application')}</span></b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>&nbsp;{t('Application para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{
                    fontSize: '10.0pt',
                    lineHeight: '200%', fontFamily: 'Symbol'
                }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>{t('Company')}</span></b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>&nbsp;{t('Company para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{
                    fontSize: '10.0pt', lineHeight: '200%',
                    fontFamily: 'Symbol'
                }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>{t('Country')}</span></b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>&nbsp;{t('Country para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{
                    fontSize: '10.0pt', lineHeight: '200%',
                    fontFamily: 'Symbol'
                }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>{t('Device')}</span></b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>&nbsp;{t('Device para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{
                    fontSize: '10.0pt', lineHeight: '200%',
                    fontFamily: 'Symbol'
                }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>{t('Personal Data')}</span></b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>&nbsp;{t('Personal Data para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{
                    fontSize: '10.0pt', lineHeight: '200%',
                    fontFamily: 'Symbol'
                }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span></span><b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>{t('Service')}</span></b><span style={{
                            fontSize: '12.0pt', lineHeight: '200%',
                            fontFamily: '"Times New Roman",serif'
                        }}>&nbsp;{t('Service para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Service Provider')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('Service Provider para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Third-party Social Media Service')}</span></b><span style={{
                    fontSize: '12.0pt',
                    lineHeight: '200%', fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('Third-party Social Media Service para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Usage Data')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('Usage Data para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('You')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('You para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '15.65pt', marginRight: '0in', marginBottom: '9.4pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '24.0pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Collecting and Using Your Personal Data')}</span></b>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '18.0pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Types of Data Collected')}</span></b></p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '13.5pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Personal Data')}</span></b></p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t('Personal Data h')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Email address')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('First name and last name')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Phone number')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Address, State, Province, ZIP/Postal code, City')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Usage Data')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '13.5pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Usage Data')}</span></b></p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t('Usage Data para1')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t('Usage Data para2')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t('Usage Data para3')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t('Usage Data para4')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '13.5pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Information from Third-Party Social Media Services')}</span>
                    </b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Information from Third-Party Social Media Services h')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span
                    style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span style={{
                        fontSize: '12.0pt', lineHeight: '200%',
                        fontFamily: '"Times New Roman",serif'
                    }}>{t('Google')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span
                    style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span style={{
                        fontSize: '12.0pt', lineHeight: '200%',
                        fontFamily: '"Times New Roman",serif'
                    }}>{t('Facebook')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span
                    style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><span style={{
                        fontSize: '12.0pt', lineHeight: '200%',
                        fontFamily: '"Times New Roman",serif'
                    }}>{t('Twitter')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Information from Third-Party Social Media Services para1')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Information from Third-Party Social Media Services para2')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt',
                    marginRight: '0in', marginBottom: '6.25pt', marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '13.5pt',
                    fontFamily: '"Times New Roman",serif', color: '#221341'
                }}>{t('Information Collected while Using the Application')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{
                        fontSize: '12.0pt',
                        fontFamily: '"Times New Roman",serif'
                    }}>{t('Information Collected while Using the Application para1')}</span></p>

                <p className="MsoNormal" style={{
                    marginLeft: '18.8pt', textIndent: '-.25in',
                    lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Information regarding your location')}</span></p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t("Information from your Device's phone book (contacts list)")}</span></p>

                <p className="MsoNormal" style={{
                    marginLeft: '18.8pt', textIndent: '-.25in',
                    lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t("Pictures and other information from your Device's camera and photo library")}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Information Collected while Using the Application para2')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Information Collected while Using the Application para3')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '18.0pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Use of Your Personal Data')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Use of Your Personal Data h')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('To provide and maintain our Service')}</span></b><span style={{
                    fontSize: '12.0pt',
                    lineHeight: '200%', fontFamily: '"Times New Roman",serif'
                }}>{t('including to monitor the usage of our Service')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('To manage Your Account')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('To manage Your Account para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('For the performance of a contract')}</span></b><span
                    style={{
                        fontSize: '12.0pt', lineHeight: '200%',
                        fontFamily: '"Times New Roman",serif'
                    }}>&nbsp;{t('For the performance of a contract para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('To contact You')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('To contact You para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('To provide You')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('To provide You para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('To manage Your requests')}</span></b><span
                    style={{ fontSize: '12.0pt', lineHeight: '200%', fontFamily: '"Times New Roman",serif' }}>&nbsp;{t('To manage Your requests para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('For business transfers')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>&nbsp;{t('For business transfers para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '0in', marginBottom: '6.25pt', marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('For other purposes')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('For other purposes para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t('We may share Your personal information in the following situations')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{ fontSize: '12.0pt', lineHeight: '200%', fontFamily: '"Times New Roman",serif' }}>{t('With Service Providers')}</span></b>
                    <span style={{ fontSize: '12.0pt', lineHeight: '200%', fontFamily: '"Times New Roman",serif' }}>&nbsp;{t('With Service Providers para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('For business transfers')}</span></b><span
                    style={{ fontSize: '12.0pt', lineHeight: '200%', fontFamily: '"Times New Roman",serif' }}>&nbsp;{t('For business transfers para_2')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('With Affiliates')}</span></b><span
                    style={{ fontSize: '12.0pt', lineHeight: '200%', fontFamily: '"Times New Roman",serif' }}>&nbsp;{t('With Affiliates para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span
                    style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><b><span style={{
                        fontSize: '12.0pt', lineHeight: '200%',
                        fontFamily: '"Times New Roman",serif'
                    }}>{t('With business partners')}</span></b><span style={{
                        fontSize: '12.0pt', lineHeight: '200%',
                        fontFamily: '"Times New Roman",serif'
                    }}>&nbsp;{t('With business partners para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span
                    style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><b><span style={{
                        fontSize: '12.0pt', lineHeight: '200%',
                        fontFamily: '"Times New Roman",serif'
                    }}>{t('With other users')}</span></b><span style={{
                        fontSize: '12.0pt',
                        lineHeight: '200%', fontFamily: '"Times New Roman",serif'
                    }}>&nbsp;{t('With other users para')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('With Your consent')}</span></b><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('With Your consent para')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '18.0pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Retention of Your Personal Data')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Retention of Your Personal Data para1')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Retention of Your Personal Data para2')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '18.0pt',
                    fontFamily: '"Times New Roman",serif', color: '#221341'
                }}>{t('Transfer of Your Personal Data')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Transfer of Your Personal Data para1')}
                </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Transfer of Your Personal Data para2')}
                </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Transfer of Your Personal Data para3')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '0in', lineHeight: 'normal'
                }}><b><span
                    style={{
                        fontSize: '18.0pt', fontFamily: '"Times New Roman",serif',
                        color: '#221341'
                    }}>{t('Disclosure of Your Personal Data')}</span></b>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '0in', lineHeight: 'normal'
                }}><b><span
                    style={{
                        fontSize: '13.5pt', fontFamily: '"Times New Roman",serif',
                        color: '#221341'
                    }}>{t('Business Transactions')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Business Transactions para')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in',
                    marginBottom: '6.25pt', marginLeft: '0in', lineHeight: 'normal'
                }}><b><span
                    style={{
                        fontSize: '13.5pt', fontFamily: '"Times New Roman",serif',
                        color: '#221341'
                    }}>{t('Law enforcement')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Law enforcement para')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '13.5pt',
                    fontFamily: '"Times New Roman",serif', color: '#221341'
                }}>{t('Other legal requirements')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Other legal requirements h')}
                </span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Comply with a legal obligation')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Protect and defend the rights or property of the Company')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginLeft: '18.8pt', textIndent: '-.25in',
                    lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Prevent or investigate possible wrongdoing in connection with the Service')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginLeft: '18.8pt', textIndent: '-.25in',
                    lineHeight: '200%'
                }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span
                    style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Protect the personal safety of Users of the Service or the public')}</span>
                </p>

                <p className="MsoNormal" style={{ marginLeft: '18.8pt', textIndent: '-.25in', lineHeight: '200%' }}><span style={{ fontSize: '10.0pt', lineHeight: '200%', fontFamily: 'Symbol' }}>·<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span><span style={{
                    fontSize: '12.0pt', lineHeight: '200%',
                    fontFamily: '"Times New Roman",serif'
                }}>{t('Protect against legal liability')}</span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '12.5pt', marginRight: '0in', marginBottom: '6.25pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '18.0pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Security of Your Personal Data')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Security of Your Personal Data h')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '15.65pt', marginRight: '0in', marginBottom: '9.4pt',
                    marginLeft: '0in', lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '24.0pt',
                    fontFamily: '"Times New Roman",serif', color: '#221341'
                }}>{t("Children's Privacy")}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t("Children's Privacy para2")}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t("Children's Privacy para2")}</span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '15.65pt', marginRight: '0in', marginBottom: '9.4pt', marginLeft: '0in', lineHeight: 'normal' }}><b><span style={{
                    fontSize: '24.0pt',
                    fontFamily: '"Times New Roman",serif', color: '#221341'
                }}>{t('Links to Other Websites')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>{t('Links to Other Websites para1')}</span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Links to Other Websites para2')}
                </span>
                </p>

                <p className="MsoNormal" style={{
                    marginTop: '15.65pt', marginRight: '0in', marginBottom: '9.4pt', marginLeft: '0in',
                    lineHeight: 'normal'
                }}><b><span style={{
                    fontSize: '24.0pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Changes to this Privacy Policy')}</span></b>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Changes to this Privacy Policy para1')}
                </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Changes to this Privacy Policy para2')}
                </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Changes to this Privacy Policy para3')}
                </span>
                </p>

                <p className="MsoNormal" style={{ marginTop: '15.65pt', marginRight: '0in', marginBottom: '9.4pt', marginLeft: '0in', lineHeight: 'normal' }}><b><span style={{
                    fontSize: '24.0pt', fontFamily: '"Times New Roman",serif',
                    color: '#221341'
                }}>{t('Contact Us')}</span></b></p>

                <p className="MsoNormal" style={{ marginBottom: '6.25pt', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Contact Us para1')}
                    <span style={{ textDecoration: 'underline' }}>
                        <Link to="/contact-us">
                            Contact Us
                        </Link>
                    </span>
                </span>
                </p>

                <p className="MsoNormal" style={{ marginBottom: '0in', lineHeight: 'normal' }}><span
                    style={{ fontSize: '12.0pt', fontFamily: '"Times New Roman",serif' }}>
                    {t('Contact Us para2')}

                </span></p>

                <p className="MsoNormal">&nbsp;</p>
            </div>
        </div>
    )
}

const PrivatePolicy = (props) => {
    const { t } = props
    const token = typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null;

    useEffect(() => { window.scroll(0, 0) }, []);
    if (token) {
        return (
            <>
                <LayoutComponent title={t('Privacy Policy')}>
                    <Content {...props} />
                </LayoutComponent>
                <Footer />
            </>
        )
    } else {
        return (
            <>
                <Layout title={t("Privacy Policy")}><Content {...props} /></Layout>
                <Footer />
            </>
        );
    }
}

export default withTranslation()(PrivatePolicy);