import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../../layoutComponents/layoutComponent';
import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import SaveListing from '../../../service/saveListingService'

class RelationWithBrandComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            brandName: ''
        }
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        let brand = typeof window !== 'undefined' ? localStorage.getItem('brand') : null
        if (brand) {
            let brandName = JSON.parse(brand)
            this.setState({ brandName: brandName.name })
        }
        if (values && values.id && values.brandId) {
            SaveListing.reapplyBrand = {
                id: values.id,
                isReapplyingBrand: true,
                brandId: values.brandId
            }
            this.setState({ brandName: values.name })

        } else {
            SaveListing.reapplyBrand = {
                id: '',
                isReapplyingBrand: false,
                brandId: ''
            }

        }
    }

    selectOwner = () => {
        SaveListing.brand.relationType = 'OWNER'
        this.navigate('/relation-owner')
    }

    selectDistributor = () => {
        SaveListing.brand.relationType = 'DISTRIBUTOR'
        SaveListing.brand.brandDocTYpe = 'AuthorizationCertificateBrandAssociation'
        this.navigate('/brand-docs')
    }

    selectWholesaler = () => {
        SaveListing.brand.relationType = 'TRADER'
        this.navigate('/brand-terms')
    }

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    render() {
        const { brandName } = this.state;
        const { t } = this.props;
        return (
            <LayoutComponent title={t('Brand')}>
                <Row className=' sidebar_navigation-items' >
                    <Col span={24}>
                        <h3 className="mt-3">{t('Select your relationship with')}: {brandName} </h3>

                        <div className='brand_list mt-0 border pt-0 pb-0'>
                            <div className='brand' onClick={(e) => this.selectOwner()}>
                                <div>
                                    <h4>
                                        {t('Brand owner')}
                                    </h4>
                                    <p className='label'>{t('I am the manufacturer/Owner of this brand')}</p>
                                </div>
                                <div className='spacer'></div>
                                <RightOutlined />
                            </div>
                            <div className='brand' onClick={(e) => this.selectDistributor()}>
                                <div>
                                    <h4>
                                        {t('Authorized Distributor/Reseller')}
                                    </h4>
                                    <p className='label'>{t('I am the Authorized Distributor')}</p>
                                </div>
                                <div className='spacer'></div>
                                <RightOutlined />
                            </div>

                            <div className='brand' onClick={(e) => this.selectWholesaler()}>
                                <div>
                                    <h4>
                                        {t('Wholesaler/Trader')}
                                    </h4>
                                    <p className='label'>{t('I am the Third party Seller of this brand')}</p>
                                </div>
                                <div className='spacer'></div>
                                <RightOutlined />
                            </div>

                        </div>

                    </Col>

                </Row>
            </LayoutComponent>
        )
    }
}

export default withTranslation()(withRouter(RelationWithBrandComponent))