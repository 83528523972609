import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Row, Col, Button, Tag, message } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import LayoutComponent from '../layoutComponents/layoutComponent';
import {
	YoutubeOutlined, ClockCircleOutlined,
} from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import CartService from '../../service/cart';
import MediaUrl from '../../service/mediaUrl'
import userImg from '../../assests/user.png';

class OrderFormsComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			cartData: []
		}
	}

	componentDidMount() {
		this.getCartItemsData()
	}

	getCartItemsData = async () => {
		const { dispatch } = this.props
		let x = await CartService.getCartItemsData()
		if (x.data.isSuccess) {
			let { data } = x.data
			if (data.length > 0) {
				data.forEach(item => {
					if (item.sellerAccount && item.sellerAccount.imageFileUploadedData.url) {
						let url = MediaUrl.completeMediaUrl(item.sellerAccount.imageFileUploadedData.url, true, 100, 100)
						item.sellerAccount.imageFileUploadedData.url = url
					}
					item.orderLineItems.forEach(orderItem => {
						let { mapProductFileUploadedDatas } = orderItem.productOrderLineItem
						if (mapProductFileUploadedDatas.length && mapProductFileUploadedDatas[0] && mapProductFileUploadedDatas[0].fileUploadedData && mapProductFileUploadedDatas[0].fileUploadedData.url) {
							let url = MediaUrl.completeMediaUrl(mapProductFileUploadedDatas[0].fileUploadedData.url, true, 100, 100)
							orderItem.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url = url
						} else {
							orderItem.productOrderLineItem.mapProductFileUploadedDatas = []

						}
					})
				})

				dispatch({
					type: 'SET_CART_ITEMS_FLAG',
					isExist: true
				})
			} else {
				dispatch({
					type: 'SET_CART_ITEMS_FLAG',
					isExist: false
				})
			}

			this.setState({ cartData: data })

		}
	}

	removeCart = async (shoppingCartToken) => {
		const { t } = this.props
		let x = await CartService.deleteCartById(shoppingCartToken)
		if (x.data.isSuccess) {
			this.getCartItemsData()
			message.success(`${t('Item remove from cart')}`)
		}
		else {
			message.error(`${t('Error in deletion')}`)
		}
	}

	navigateToProductDetailPage = (productId) => {
		this.props.history.push({ pathname: '/view-product/' + productId })
	}

	navigateToSellerPage = (sellerAccountId) => {
		this.props.history.push({ pathname: '/view-profile/' + sellerAccountId })
	}


	render() {

		const { cartData } = this.state
		const { t } = this.props

		return (
			<LayoutComponent title={t('Order forms')}>

				{cartData && cartData.length > 0 && cartData.map((item, index) => {
					return (<Row key={index}>
						<Col className='cartItem' span={24}>
							<div className='profileDiv' >
								<div className={'flexContainer pointer_Cursor'}
									onClick={() => {
										this.navigateToSellerPage(item.sellerAccountId)
									}}
								>
									<div className='sellerImg'>
										<img
											src={item.sellerAccount && item.sellerAccount.imageFileUploadedData.url ? item.sellerAccount.imageFileUploadedData.url : userImg}
											alt={'smallimg'} />
									</div>
									<div className={'flexRightDiv'}>
										<div><b className='font14px'>{item.sellerAccount && item.sellerAccount.name}</b></div>
										<div className={'textcolor font12px'}>{item.sellerAccount && item.sellerAccount.addresses && item.sellerAccount.addresses.length && item.sellerAccount.addresses[0] ? item.sellerAccount.addresses[0].city + ',' + item.sellerAccount.addresses[0].stateName : ''}</div>
									</div>
								</div>
							</div>

							{item.orderLineItems && item.orderLineItems.length > 0 && item.orderLineItems.map((order, orderIndex) => {
								return (<div key={orderIndex} className={'productHeader'} onClick={(e) => this.navigateToProductDetailPage(order.productOrderLineItem.productId)}>
									<div className={'flexContainer'}>
										<div className={'productImg'}><img src={order.productOrderLineItem && order.productOrderLineItem.mapProductFileUploadedDatas[0] && order.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url} alt={''} />
										</div>
										<div className={'flexRightDiv'}>
											<div><b className='font14px'>{order.productOrderLineItem.name}</b></div>
											<div className={'textcolor font12px'}>{order.quantity} {t('Sets')}</div>
										</div>
									</div>
									<hr />
								</div>)
							})}

							<div className={'m-4'}>
								<div className={'flexContainer'}>
									<div className={'halfDiv'}>
										<div className='font14px'>{t('Total qty')}</div>
										<div className='font14px'>{t('Total amount')}</div>
									</div>
									<div className={'halfDiv'}>
										<div><b className='font14px'>{item.totalQuantity} {t('Sets')}</b></div>
										<div><b className='font14px'>₹ {item.totalPrice}</b></div>
										{/* <div className={'textcolor font12px'}>{`(₹ ${item.priceWithoutGst} + ₹ ${item.gstAmount} GST)`}</div> */}
									</div>
								</div>
								<div style={{ float: 'right' }} className={'my-2'}>
									<Button className={'mr-2 removeBtn'} onClick={() => {
										this.removeCart(item.shoppingCartToken)
									}}>{t('Remove')}</Button>
									<Button id={'reviewOrderBtn'} className={'manageBtn'} onClick={() => {
										this.props.history.push({ pathname: `/order-forms/checkout/${item.shoppingCartToken}` })
									}}>{t('Review And Order')}</Button>
								</div>
							</div>
						</Col>
					</Row>)
				})}
				{cartData && cartData.length === 0 &&
					<div className='no_data_div'>
						<h6>
							{t('Your Akar basket is empty')}
						</h6>
					</div>
				}
			</LayoutComponent>
		)
	}
}

const mapStateToProps = ({ global }) => ({
	loading: global.buttonLoading,
	token: global.token,
	currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderFormsComponent)));