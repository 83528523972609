import React, { Component } from 'react';
import './index.scss';
import { Modal, Card, Button } from 'antd';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import RegistrationForm from '../auth/registeration'
import KycSubmission from '../auth/kyc'
import BankDetailsForm from '../auth/bankDetails'
import TermsOfServiceSubmission from '../auth/termsOfService';
import BankVerification from '../auth/bankVerify';
import whiteLogo from '../../akar_dark.png'
import { withTranslation } from 'react-i18next';

const screenTypes = {
    signUp: 'signup',
    kyc: "kyc",
    bankDetails: "bankDetails",
    verifyPayment: 'verifyPayment',
    termsOfService: 'termsOfService',
    kycCompleted: 'kycCompleted'
}

const userTypes = {
    Seller: "Seller",
    Buyer: "Buyer"
}

class ModalComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentScreen: true,
            renderComponent: '',
            selectedBankId: ''
        }
    }

    loadCurrentScreen = () => {
        const { currentUser, becomeSeller } = this.props

        if (currentUser) {

            if (currentUser.account && !currentUser.account.isUserRegistered) {
                this.setState({ renderComponent: screenTypes.signUp })
            }

            else if (currentUser.account &&
                currentUser.account.isUserRegistered &&
                (currentUser.account.kycStatus === 'NoDocuments' || currentUser.account.kycStatus === 'Rejected')) {
                this.setState({ renderComponent: screenTypes.kyc })
            }

            else if ((currentUser.account.type === userTypes.Seller || becomeSeller)
                && currentUser.account &&
                currentUser.account.kycStatus !== 'NoDocuments' &&
                !currentUser.account.isBankDetailsExist) {
                this.setState({ renderComponent: screenTypes.bankDetails })
            }
            else if ((currentUser.account.type === userTypes.Seller || becomeSeller) &&
                currentUser.account && currentUser.account.isBankDetailsExist
                && !currentUser.account.isTermsAndConditionApprovedForSeller) {
                this.setState({ renderComponent: screenTypes.termsOfService })
            }
            // else if (currentUser.account &&
            //     currentUser.account.kycStatus &&
            //     currentUser.account.kycStatus === 'Rejected') {
            //     this.setState({ renderComponent: screenTypes.kyc })
            // }
            else {
                this.setState({ renderComponent: '' })
                this.modalClose()
            }
        }

    }

    componentDidMount() {
        this.loadCurrentScreen()
    }

    modalClose = () => {
        const { onClose } = this.props
        this.setState({ currentScreen: false })
        onClose()
    }

    render() {

        const { showModal, currentUser, becomeSeller, t } = this.props
        const { currentScreen, renderComponent, selectedBankId } = this.state

        const kycSuccessfull = <>
            <div className='justify-content-center'>
                <Card style={{ width: 380 }} bordered={false}>
                    <div className='text-align-center mb-2'>
                        <img height='60'
                            src={whiteLogo} />
                    </div>
                    <div className='text-align-center mb-2' style={{ fontWeight: '700', fontSize: '22px' }}>{t('Thankyou')}</div>
                    <div className='text-align-center mb-2' style={{ fontSize: '18px', marginVertical: '12px' }}>{t('KYC Details Submitted')}</div>
                    <div className='text-align-center mb-4' style={{ color: '#cacaca' }}>{t('You KYC will be approved shortly - para')}</div>
                    <div className='text-align-center mb-2' >
                        <Button type="primary" onClick={() => {
                            this.modalClose()
                        }}>{t('Ok')}</Button></div>
                </Card>

            </div>
        </>

        return (<>

            <Modal
                width={renderComponent === screenTypes.termsOfService ? '95%' : 520}
                visible={showModal}
                footer={null}
                centered={true}
                onCancel={this.modalClose}
                maskClosable={false}
            >
                <>
                    {currentScreen ? <>
                        {renderComponent && renderComponent === screenTypes.signUp ? <RegistrationForm
                            onRegistrationDone={() => {
                                this.setState({ renderComponent: screenTypes.kyc })
                            }} /> : null}

                        {renderComponent && renderComponent === screenTypes.kyc ? <KycSubmission
                            onKycDone={() => {
                                if (currentUser && (currentUser.account.type === userTypes.Seller || becomeSeller)
                                    && !currentUser.account.isBankDetailsExist) {
                                    this.setState({ renderComponent: screenTypes.bankDetails })
                                } else {
                                    // this.modalClose()
                                    this.setState({ renderComponent: screenTypes.kycCompleted })

                                }
                            }}
                            goToRegistration={() =>
                                this.setState({ renderComponent: screenTypes.signUp })

                            }
                        /> : null}


                        {renderComponent && renderComponent === screenTypes.bankDetails ? <BankDetailsForm
                            onBankDetailsSubmit={(selectedBankId) => {
                                if (currentUser && (currentUser.account.type === userTypes.Seller || becomeSeller) &&
                                    !currentUser.account.isTermsAndConditionApprovedForSeller) {
                                    this.setState({ selectedBankId, renderComponent: screenTypes.verifyPayment })
                                } else {
                                    this.modalClose()
                                }
                            }} /> : null}

                        {
                            renderComponent && renderComponent === screenTypes.verifyPayment && selectedBankId
                                ? <BankVerification
                                    selectedBankId={selectedBankId}
                                    onBankVerifySubmit={() => {
                                        this.setState({ renderComponent: screenTypes.termsOfService })
                                    }} />
                                : null
                        }

                        {renderComponent && renderComponent === screenTypes.termsOfService ? <TermsOfServiceSubmission onTermsAccept={() => {
                            // this.modalClose()
                            this.setState({ renderComponent: screenTypes.kycCompleted })

                        }} /> : null}

                        {renderComponent && renderComponent === screenTypes.kycCompleted ? kycSuccessfull : null}
                    </> : null}
                </>
            </Modal>
        </>)
    }
}

const mapStateToProps = ({ global }) => {
    return {
        // loading: global.buttonLoading,
        // token: global.token,
        currentUser: global.currentUser
    }
}
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalComponent)));
