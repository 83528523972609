import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Row, Col, Drawer, Input, message } from 'antd'
import CartService from '../../service/cart';

class HoldComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            holdDetailsVisible: false,
            loading: false,
            days: '',
            comment: ''
        };
    }

    componentDidMount() {
        this.setState({
            holdDetailsVisible: true
        })
    }

    onClose = () => {
        const { onclose } = this.props
        this.setState({
            holdDetailsVisible: false,
        }, () => {
            onclose();
        });
    };

    proceedToHold = async () => {
        let { comment, days } = this.state
        const { orderData, onclose, t } = this.props

        if (!days) {
            this.setState({ loading: false })
            return message.error(`${t('Enter no of days')}`)
        }
        if (days > 5) {
            this.setState({ days: '', loading: false })
            return message.error(`${t('No of days should be less than 5')}`)
        }
        if (!comment.trim().length) {
            this.setState({ loading: false })
            return message.error(`${t('Enter comment')}`)
        }
        this.setState({ loading: true })

        let { data: holdRespp } = await CartService.processToHold(days, comment, orderData.orderId)
        if (holdRespp.isSuccess) {
            this.setState({ loading: false, comment: '', days: '', holdDetailsVisible: false, })
            if (onclose) {
                onclose(holdRespp);
            }

        }
    }

    render() {

        const { holdDetailsVisible, loading, days, comment } = this.state

        const { orderData, t } = this.props

        const proceedButton = <>
            <Row>
                <Col span={24}>
                    <Button loading={loading}
                        className={'manageBtn'}
                        style={{ width: '100%' }}
                        onClick={() => {
                            this.proceedToHold()
                        }}>{t('Proceed')}</Button>
                </Col>
            </Row>
        </>

        return (<>
            <Drawer title={t("Hold Details")}
                placement={'bottom'}
                visible={holdDetailsVisible}
                onClose={this.onClose}
                footer={proceedButton}
                height={'100vh'}
                key={'hold'}
            >
                <div className={'qtyDrawer'}>
                    <div className={'mt-4'}>
                        {orderData && orderData.orderId ?
                            <Row >
                                <Col xs={24}>
                                    <p className="label">{t('No of days')}</p>
                                    <Input type="number"
                                        onChange={(val) => {
                                            this.setState({ days: val.target.value })
                                        }}
                                        value={days}
                                        placeholder={t('No of days')} />

                                </Col>
                                <Col xs={24}>
                                    <p className="label mt-3">{t('Comment')}</p>
                                    <Input.TextArea
                                        autoSize={{ minRows: 3, maxRows: 6 }}
                                        value={comment}
                                        onChange={(val) => {
                                            this.setState({ comment: val.target.value })
                                        }}
                                        placeholder={t('Comment')} />
                                </Col>
                            </Row>
                            : null}
                    </div>
                </div>
            </Drawer>
        </>

        );
    }
}

export default withTranslation()(withRouter(HoldComponent));