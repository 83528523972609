import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import myimage from '../../imageNotFound.jpeg'
import Api from '../../service/network'
import MediaUrl from '../../service/mediaUrl'
import SectionLoader from './sectionLoader'
import { commonRedirections } from '../sections/redirections';



class FullWidthBannerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerData: []
        }
    }
    componentDidMount() {
        const { itemData } = this.props
        if (itemData.callType === 'Get') {
            let path = `${itemData.dataUrl}${itemData.queryParam}`
            Api.makeGetRequest(path, null, null, null).then((res) => {
                let output = res.data.data.data;
                output.forEach(img => {
                    if (img.imageUrl) {
                        let url = MediaUrl.completeMediaUrl(img.imageUrl, true, 100, 100)
                        img.imageUrl = url
                    }
                })
                this.setState({ bannerData: output });
                // console.log(bannerData,"bannerData")
            })
        }
    }
    

    render() {
        const { itemData } = this.props
        const { bannerData } = this.state
        return (<>{itemData ? <div style={{cursor: 'pointer'}} className="toolbar_navigation-items mt-2 slider_div">

            {bannerData && bannerData.length > 0 && bannerData.map((item, bannerKey) => {
                return (<div className='fullWidthBanner' key={bannerKey} onClick={() => {
                    let response = commonRedirections(item)
                    
                    this.props.history.push({ pathname: response })
                }}>
                    <img src={item && item.imageUrl ? item.imageUrl : ''} alt='test_banner' />
                </div>)
            })}

        </div> : <div className="sectionloader">
            <SectionLoader />
        </div>}</>)
    }
}
export default withRouter(FullWidthBannerView)
