export const ListingStatus = { Pending: '0', Approved: '1', Rejected: '2' }

export const OrderStatus = {
    'ReadyToShip': "ReadyToShip",
    'CourierReturn': "CourierReturn",
    'CancelledByVendor': "CancelledByVendor",
    'PickupRescheduled': "PickupRescheduled",
    "Failed": "Failed",
    "Shipped": "Shipped",
    "Cancelled": "Cancelled",
    'Partial': "Partial",
    'Pending': "Pending",
    'Placed': "Placed",
    'Rejected':"Rejected",
    'Expired': "Expired",
    "Delivered": "Delivered",
    "Hold": "Hold",
    "RTOByBuyer": "RTOByBuyer",
    "RTOBySeller": "RTOBySeller",
    "PickedUp": "PickedUp",
    "WaitingForApproval" : "WaitingForApproval"
    //"CourierReturn": "Courier Returned",
    //'ReadyToShip': "Ready To Ship",
    //'CancelledByVendor': "Cancelled By Vendor",
    //'PickupError': 'Pickup Rescheduled'
}

export const OrderReturnStatus = {
    Placed: 4,
    Shipped: 7,
    Delivered: 10,
    Dispute: 11,
    CourierReturn: 13,
    Approved: 14,
    Rejected: 15
}

export const ChatType = { Text: 0, Image: 1, Video: 2 }
export const ChatDirection = { In: 0, Out: 1 }
export const PaymentStatus = { Success: 1, Failure: 2, Pending: 0 }

export const PaymentType = {
    COD: 0,
    PaymentGateway: 1,
    Partial: 2
}

export const DisputeStatus = { Rejected: 0, Accepted: 1, Open: 2 }

export const DisputeReason = {
    WrongItemSent: 'Wrong items sent',
    ItemMissing: 'Items missing',
    ItemDamaged: 'Items damaged',
    Other: 'Other'
}

export const DisputeSettled = {
    Buyer: 0,
    Seller: 1,
    Partial: 2
}

export const ScheduledTransactionStatus = {
    Pending: 0,
    InProcess: 1,
    Completed: 2,
    Failed: 3
}

