import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Switch, Drawer, message, Button, Skeleton } from 'antd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import queryString from 'query-string';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
// import OrderListingHeader from './listingHeader'
import myimage from '../../imageNotFound.jpeg'
import AddListingService from '../../service/addListingService'
import LayoutComponent from '../layoutComponents/layoutComponent'
import MediaUrl from '../../service/mediaUrl'
//import { ListingStatus } from '../../service/statusTypes'

class ActiveListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            filterVisible: false,
            productList: [],
            listingStatus: '',
            currentPage: 1,
            totalPageCount: 0
        }
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        if (values && values.status) {
            this.setState({ listingStatus: values.status }, () => {
                this.getProductList()
            })
        } else {
            this.setState({ listingStatus: 'Pending' }, () => {
                this.getProductList()

            })
        }

        let id = typeof window !== 'undefined' ? localStorage.getItem('id') : null
        if (id) {
            if (typeof window !== 'undefined') localStorage.removeItem('id')
        }
    }

    loadMoreData = () => {
        let { productList, currentPage, totalPageCount } = this.state
        if (currentPage < totalPageCount) {
            const values = queryString.parse(this.props.location.search)
            let obj = {
                pageNumber: currentPage + 1,
                status: values && values.status ? values.status : this.state.listingStatus
            }

            AddListingService.getProductList(obj)
                .then((res) => {
                    let { data } = res.data
                    data.products.forEach(item => {
                        if (item.mapProductFileUploadedDatas.length) {
                            item.mapProductFileUploadedDatas.forEach(img => {
                                if (img && img.fileUploadedData.url) {
                                    let url = MediaUrl.completeMediaUrl(img.fileUploadedData.url, true, 100, 100)
                                    img.fileUploadedData.url = url
                                }
                            })
                        }
                    })

                    productList.push(...data.products)

                    this.setState({
                        productList,
                        totalCount: data.totalCount,
                        totalPageCount: data.totalPageCount,
                        currentPage: data.currentPage
                    })
                }, (err) => { })
        }
    }

    getProductList = () => {
        const values = queryString.parse(this.props.location.search)
        // this.setState({ totalCount: 0, productList: [] })

        let obj = {
            pageNumber: this.state.currentPage,
            //status: this.state.listingStatus
            status: values && values.status ? values.status : this.state.listingStatus
        }

        AddListingService.getProductList(obj)
            .then((res) => {
                let { data } = res.data
                data.products.forEach(item => {
                    if (item.mapProductFileUploadedDatas.length) {
                        item.mapProductFileUploadedDatas.forEach(img => {
                            if (img && img.fileUploadedData.url) {
                                let url = MediaUrl.completeMediaUrl(img.fileUploadedData.url, true, 100, 100)
                                img.fileUploadedData.url = url
                            }
                        })
                    }
                })
                this.setState({
                    productList: data.products,
                    totalCount: data.totalCount,
                    totalPageCount: data.totalPageCount,
                    currentPage: data.currentPage
                })
            }, (err) => { })
    }

    showFilterDrawer = () => {
        this.setState({
            filterVisible: true,
        });
    };

    onFilterClose = () => {
        this.setState({
            filterVisible: false,
        });
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    onChangeSwitch = (val, id) => {

        let { productList } = this.state
        let { t } = this.props

        AddListingService.updateProductIsAvailableStatus(id, val)
            .then((res) => {
                if (res.data.isSuccess) {

                    productList = _.map(productList, (item, index) => {

                        if (item.id === id) {
                            item.isAvailable = val
                        }
                        return item
                    })

                    this.setState({ productList })
                    message.success(`${t('Status updated successfully')}`)

                }
            }, err => {
                message.error(`${t('Error in updation')}`)
            })
    }

    navigateToViewProduct = (url, id) => {
        this.props.history.push({ pathname: url + id })
    }

    changeListing = (status) => {
        this.props.history.push({ pathname: '/manage-listings', search: `?status=${status}` })
        this.setState({
            listingStatus: status,
            totalCount: 0,
            currentPage: 1,
            totalPageCount: 0,
            productList: []
        }, () => {
            this.getProductList()
        })
    }

    clearPreviousSetData = () => {
        if (typeof window !== 'undefined') { localStorage.removeItem('currentCopySet') }
    }

    render() {
        const { visible, filterVisible, productList, listingStatus, totalCount } = this.state;
        const { t } = this.props

        return (
            <>
                <LayoutComponent title={t('Your listings')}>
                    <div className='divWithoutFixButton'>
                        <Row>
                            <Col span={24}>
                                <header className="toolbar order-tab-bar">
                                    <nav className="toolbar__navigation" style={{ overflowX: "auto", overflowY: 'hidden' }}>
                                        <div className="toolbar_navigation-items hideScroller theme-color">
                                            <ul className='theme-color'>
                                                <li>
                                                    <p className={listingStatus === 'Pending' ? 'selected_listing' : 'unSelected_listing'}
                                                        onClick={() => { this.changeListing('Pending') }}>{t('Pending')}</p>
                                                </li>
                                                <li>
                                                    <p className={listingStatus === 'Approved' ? 'selected_listing' : 'unSelected_listing'}
                                                        onClick={() => { this.changeListing('Approved') }}>{t('Approved')}</p>
                                                </li>
                                                <li>
                                                    <p className={listingStatus === 'Rejected' ? 'selected_listing' : 'unSelected_listing'}
                                                        onClick={() => { this.changeListing('Rejected') }}>{t('Rejected')}</p>
                                                </li>
                                                <li>
                                                    <p className={listingStatus === 'unavailable' ? 'selected_listing' : 'unSelected_listing'}
                                                        onClick={() => { this.changeListing('unavailable') }}>{t('Inactive')}</p>
                                                </li>
                                                <li>
                                                    <p className={listingStatus === 'Expired' ? 'selected_listing' : 'unSelected_listing'}
                                                        onClick={() => { this.changeListing('Expired') }}>{t('Expired')}</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </header>
                            </Col>


                            {/* <OrderListingHeader> */}
                            <Col span={24} className='bottom-border'>
                                <header className="toolbar filter-bar">
                                    <nav className="toolbar__navigation">
                                        <Link to='category' className='button_Color smallHeading' onClick={() => { this.clearPreviousSetData() }}>
                                            <PlusCircleOutlined style={{ fontSize: '26px', marginRight: '5px' }} /> {t('New listing')}
                                        </Link>
                                    </nav>
                                </header>
                            </Col>

                            <Col span={24} >
                                <header className="toolbar filter-bar">
                                    <nav className="toolbar__navigation">
                                        {/* {productList.length} Items Found */}
                                        {totalCount} {t('Items found')}

                                        <div className="spacer" />
                                        <div className="toolbar_navigation-items theme-color">
                                            <ul>
                                                {/* <li  onClick={this.showDrawer}>
                                                Sort
                                        </li>

                                        <li onClick={this.showFilterDrawer}>
                                                Filter
                                        </li> */}
                                            </ul>
                                        </div>
                                    </nav>
                                </header>
                            </Col>
                        </Row>

                        <Row className='order-content-container'>
                            {productList && productList.length > 0 ? productList.map(item => {
                                return (
                                    <Col key={item.id} className='order-container pointer_Cursor'>
                                        <div className='orderCard'>
                                            <img className='mr-2' src={item && item.mapProductFileUploadedDatas[0] ? `${item.mapProductFileUploadedDatas[0].fileUploadedData.url}` : `${myimage}`} alt={'productImg'} onClick={(e) => this.navigateToViewProduct('/view-product/', item.id)} />
                                            <div onClick={(e) => this.navigateToViewProduct('/view-product/', item.id)}>
                                                <p className='title'>
                                                    {item.name}
                                                </p>
                                                <p className='brand_name mb-1'>
                                                    {item.mapBrandAccount.brand.name}
                                                </p>
                                                <p style={{ fontWeight: '700', color: 'rgb(0, 128, 0)' }} className='mb-1'>
                                                    {t(item.status)}
                                                </p>

                                                {
                                                    item.isExpired
                                                        ? <p style={{
                                                            fontSize: '14px', color: 'red',
                                                            fontWeight: 'bold',
                                                        }}>{t('Product expired status')}</p>
                                                        : item && item.expiryDate
                                                            ? <p>{`${t('Expires on')}: ${moment(item.expiryDate).format('DD-MMM-YYYY')}`}</p>
                                                            : null
                                                }

                                                {
                                                    item.status === 'Rejected' && item.reason
                                                        ? <p style={{ color: '#cacaca', fontStyle: 'italic' }}>{t('Reason of rejection')}: <span>{item.reason}</span></p>
                                                        : null
                                                }

                                            </div>
                                            <div className="spacer" onClick={(e) => this.navigateToViewProduct('/view-product/', item.id)} />

                                            {item.status === 'Approved' && !item.isExpired ?
                                                <div className='switch'>
                                                    <div className='text-center'>
                                                        <Switch checked={item.isAvailable} onChange={(e) => this.onChangeSwitch(e, item.id)} />
                                                        {item.isAvailable ?
                                                            <p className='button_Color smallHeading mt-1'>{t('Available')}</p>
                                                            :
                                                            <p className='theme-color smallHeading mt-1'>{t('Unavailable')}</p>
                                                        }
                                                    </div>
                                                </div>
                                                : null}

                                        </div>

                                    </Col>
                                )

                            }) : (productList.length === 0 ? <div></div> : <Skeleton active />)}

                        </Row>

                        {productList && totalCount && productList.length !== totalCount ?
                            <Row>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', margin: '10px' }}>
                                    <Button type={'primary'}
                                        onClick={this.loadMoreData}>{t('Load more')}</Button>
                                </div>
                            </Row>
                            : null}

                        <Drawer
                            height={270}
                            placement={'bottom'}
                            closable={false}
                            onClose={this.onClose}
                            visible={visible}
                            key={'2'}
                        >
                            <div className="sidebar_navigation-items">
                                <ul className='mb-0'>
                                    <li onClick={this.onClose}>New & Popular</li>
                                    <li onClick={this.onClose}> Popularity </li>
                                    <li onClick={this.onClose}>Latest</li>
                                    <li onClick={this.onClose}>₹: Low to High</li>
                                    <li onClick={this.onClose}>₹: High to Low</li>
                                    <li onClick={this.onClose}>MOQ Low to High</li>
                                </ul>
                            </div>
                        </Drawer>

                        <Drawer
                            height={'100vh'}
                            placement={'bottom'}
                            closable={false}
                            onClose={this.onFilterClose}
                            visible={filterVisible}
                            key={'1'}
                        >
                            <div className="sidebar_navigation-items">
                                <header className="toolbar component-toolbar">
                                    <nav className="toolbar__navigation">
                                        <div >
                                            <CloseOutlined onClick={this.onFilterClose} />
                                            Filter
                                        </div>
                                    </nav>
                                </header>
                                <Row>
                                    <Col span={8} className='filter_container'>
                                        <ul className='mb-0 '>

                                            <li onClick={this.onClose}>New & Popular</li>
                                            <li onClick={this.onClose}> Popularity </li>
                                            <li onClick={this.onClose}>Latest</li>
                                            <li onClick={this.onClose}>₹: Low to High</li>
                                            <li onClick={this.onClose}>₹: High to Low</li>
                                            <li onClick={this.onClose}>MOQ Low to High</li>

                                        </ul>
                                    </Col>
                                    <Col span={16} className='border_left filter_container' >
                                        <ul className='mb-0'>
                                            <li onClick={this.onClose}>New & Popular</li>
                                            <li onClick={this.onClose}> Popularity </li>
                                            <li onClick={this.onClose}>Latest</li>
                                            <li onClick={this.onClose}>₹: Low to High</li>
                                            <li onClick={this.onClose}>₹: High to Low</li>
                                            <li onClick={this.onClose}>MOQ Low to High</li>

                                        </ul>
                                    </Col>
                                </Row>

                                <div className='button_div'>
                                    <button onClick={this.onClose}>
                                        Apply
                                    </button>
                                </div>

                            </div>
                        </Drawer>
                    </div>
                    {/* </OrderListingHeader> */}
                </LayoutComponent>

            </>

        )
    }
}

export default withTranslation()(withRouter(ActiveListComponent));