import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import { createMemoryHistory } from 'history';
import HomeMainComponent from './homeMain'
import HomeComponent from './home'
import LoginComponent from './auth/login'
import OtpLoginComponent from './auth/otpLogin'

import ActiveListComponent from './orderListings/activeListing'
import ExpiryListComponent from './orderListings/expiryList'
import CategoryComponent from './orderListings/category'
import CreateListComponent from './orderListings/createList'
import BrandListComponent from './orderListings/brandList'
import CreateSetsComponent from './orderListings/createSets'
import SetContentComponent from './orderListings/setContent'
import AddEditNewSetComponent from './orderListings/addEditNewSet'
import ProductDetailComponent from './orderListings/productDetail'
import AdditionalDetailComponent from './orderListings/additionalDetail'
import ApplyToSellComponent from './orderListings/applyToSell'

import UserBrands from './brands';
import MasterBrands from './brands/addNewBrand/selectBrand';
import AddNewBrand from './brands/addNewBrand/newBrand';
import AddBrandRelation from './brands/addNewBrand/authoriseBrand';
import BrandDocs from './brands/addNewBrand/brandDocsVarification';
import OwnerRelation from './brands/addNewBrand/brandOwnerVarification'
import BrandTermsAndConditions from './brands/addNewBrand/terms&ConditionVarification';
import BrandLetterhead from './brands/addNewBrand/brandOwnerLetterhead';

import AccountComponent from './account/account'
import BusinessProfileComponent from './account/businessProfile'
import YourProfileComponent from './account/yourProfile'
import AccountSettingComponent from './account/accountSetting'
import ManageTeam from './account/ManageTeam'

import BrandComponent from './orderListings/addNewBrand/selectBrand'
import RelationWithBrandComponent from './orderListings/addNewBrand/authoriseBrand'
import BrandOwnerVarificationComponent from './orderListings/addNewBrand/brandOwnerVarification'
import LetterheadComponent from './orderListings/addNewBrand/brandOwnerLetterhead'

import BrandDocsVarification from './orderListings/addNewBrand/brandDocsVarification'
import WholesalerVarificationComponent from './orderListings/addNewBrand/terms&ConditionVarification'
import NewBrandComponent from './orderListings/addNewBrand/newBrand'
import ViewProductComponent from './product/viewProduct'
import ProductSearch from './productListingSearch'
import OrderFormsComponent from './checkout/orderForms'
import OrderFormsCheckOutComponent from './checkout/checkout'
import OrderPaymentComponent from './checkout/payment';
import YourOrdersComponent from './orders/yourorders';
import ShipmentTrackingComponent from './orders/shipmentTracking';
import OrderDetailComponent from './orders/orderDetail';
import ContactUsComponent from './contactUs';
import Terms from './termsAndConditions';
import ViewProfileComponent from './seller/viewProfile';
import AutoSearchComponent from './search/autosearch'
import ThirdLevelCategoryComponent from './categoryIntermediatePage'
import PageNotFoundComponent from './pageNotFound'
import ChatComponent from './chat/chat'
import OrderSuccessComponent from './orders/orderSuccess'
// import OrderFailureComponent from './orders/orderFailure'
import ReturnDetailComponent from './orders/returnDetail';
import YourReturnsComponent from './orders/yourReturns';
import OrderDisputeComponent from './dispute/addDispute';
import OrderReturnComponent from './orders/orderReturn';
import DisputeDetailComponent from './dispute/disputeDetail';
import DisputeListingComponent from './dispute/disputeListing';
import AboutUsComponent from './bottomSections/aboutAkar';
import FAQ from './bottomSections/faq';
import TermsOfUseComponent from './bottomSections/termsOfUse';
import PrivacyPolicy from './bottomSections/policy';
import ReturnShipmentPolicy from './bottomSections/returnShipment';
import ReturnPolicy from './bottomSections/returnPolicy';
import PaymentCycle from './bottomSections/paymentCycle';


import FinanceComponent from './ledger';

import AlertsMain from './alert/AlertsMain'

import TOS from './auth/termsOfService';
import StaticOfferPage from './StaticOfferPage';
import StaticOfferContent from './StaticOfferContent';
// import orderDetail from './orders/orderDetail';
const history = createMemoryHistory();

const Router = (props) => {
	const { token } = props
	if (!token) {
		return (
			<Switch history={history}>
				<Route exact path="/" component={HomeComponent} />
				<Route exact path="/login" component={LoginComponent} />
				<Route exact path="/otp" component={OtpLoginComponent} />
				<Route exact path="/terms-and-conditions" component={Terms} />
				<Route exact path="/about-us" component={AboutUsComponent} />
				<Route exact path="/FAQ" component={FAQ} />
				<Route exact path="/contact-us" component={ContactUsComponent} />
				<Route exact path='/diwali-offer' component={StaticOfferPage} />
				<Route exact path='/diwali-offer-app' component={StaticOfferContent} />
				<Route exact path="/refund-cancellation-policy" component={ReturnPolicy} />
				<Route exact path="/payment-cycle" component={PaymentCycle} />
				<Route exact path='/privacy-policy' component={PrivacyPolicy} />
				<Route exact path="/subcategory/:subcategoryid" component={ThirdLevelCategoryComponent} />
				<Redirect to='/login' />
			</Switch>
		)
	}
	return (
		<Switch>
			<Route exact path="/" component={HomeMainComponent} />
			<Route exact path='/diwali-offer-app' component={StaticOfferContent} />
			<Route exact path="/apply-to-sell" component={ApplyToSellComponent} />
			<Route exact path="/manage-listings" component={ActiveListComponent} />
			<Route exact path="/category" component={CategoryComponent} />
			<Route exact path="/create-list" component={CreateListComponent} />
			<Route exact path="/create-list/:id" component={CreateListComponent} />
			<Route exact path="/brand" component={BrandListComponent} />
			<Route exact path="/create-sets" component={CreateSetsComponent} />
			<Route exact path="/add-edit-set" component={AddEditNewSetComponent} />
			<Route exact path="/set-quantity" component={SetContentComponent} />
			<Route exact path="/product-detail" component={ProductDetailComponent} />
			<Route exact path="/additional-detail" component={AdditionalDetailComponent} />
			<Route exact path='/diwali-offer' component={StaticOfferPage} />
			<Route exact path='/manage-brands' component={UserBrands} />
			<Route exact path='/master-brands' component={MasterBrands} />
			<Route exact path='/add-new-brand' component={AddNewBrand} />
			<Route exact path='/brand-relationship' component={AddBrandRelation} />
			<Route exact path="/brand-docs" component={BrandDocs} />
			<Route exact path="/relation-owner" component={OwnerRelation} />
			<Route exact path="/brand-terms" component={BrandTermsAndConditions} />
			<Route exact path="/brand-letterhead" component={BrandLetterhead} />

			<Route exact path="/account" component={AccountComponent} />
			<Route exact path="/user-profile" component={YourProfileComponent} />
			<Route exact path="/business-profile" component={BusinessProfileComponent} />
			<Route exact path="/account-profile" component={AccountSettingComponent} />
			<Route exact path="/manage-team" component={ManageTeam} />


			<Route exact path="/onBoarding" component={BrandComponent} />
			<Route exact path="/brand-relation" component={RelationWithBrandComponent} />
			<Route exact path="/brandonwer-varification" component={BrandOwnerVarificationComponent} />
			<Route exact path="/brand-doc-varification" component={BrandDocsVarification} />
			<Route exact path="/letterhead" component={LetterheadComponent} />
			<Route exact path="/new-brand" component={NewBrandComponent} />
			<Route exact path="/terms-condition-varification" component={WholesalerVarificationComponent} />
			<Route exact path="/manage-listings/expire" component={ExpiryListComponent} />
			<Route exact path="/view-product/:id" component={ViewProductComponent} />
			<Route exact path="/search/listings/title=:title/:payload" component={ProductSearch} />
			<Route exact path="/order-forms" component={OrderFormsComponent} />
			<Route exact path="/order-forms/checkout/:id" component={OrderFormsCheckOutComponent} />
			<Route exact path="/order/payment" component={OrderPaymentComponent} />
			<Route exact path="/your-orders" component={YourOrdersComponent} />
			<Route exact path="/shipment-tracking" component={ShipmentTrackingComponent} />
			<Route exact path="/order-details/:id" component={OrderDetailComponent} />
			<Route exact path="/return/:orderId" component={OrderReturnComponent} />
			<Route exact path="/contact-us" component={ContactUsComponent} />

			<Route exact path="/terms-and-conditions" component={Terms} />

			<Route exact path="/view-profile/:id" component={ViewProfileComponent} />
			<Route exact path="/quick-search" component={AutoSearchComponent} />
			<Route exact path="/subcategory/:subcategoryid" component={ThirdLevelCategoryComponent} />
			<Route exact path="/chat" component={ChatComponent} />
			<Route exact path="/order/success/:orderId" component={OrderSuccessComponent} />
			<Route exact path="/your-returns" component={YourReturnsComponent} />
			<Route exact path="/return-details/:id" component={ReturnDetailComponent} />

			<Route exact path="/rto-dispute/dispute" component={DisputeDetailComponent} />
			<Route exact path="/create-dispute" component={OrderDisputeComponent} />
			<Route exact path="/rto-dispute/disputes" component={DisputeListingComponent} />
			<Route exact path="/finance" component={FinanceComponent} />
			<Route exact path="/about-us" component={AboutUsComponent} />
			<Route exact path="/FAQ" component={FAQ} />
			<Route exact path="/terms-of-use" component={TermsOfUseComponent} />
			<Route exact path='/privacy-policy' component={PrivacyPolicy} />
			<Route exact path='/return-shipment-policy' component={ReturnShipmentPolicy} />
			<Route exact path='/alerts' component={AlertsMain} />
			<Route exact path="/refund-cancellation-policy" component={ReturnPolicy} />
			<Route exact path="/payment-cycle" component={PaymentCycle} />

			<Redirect to='/' />
			{/* <Route exact path="/order/failure/:orderId" component={OrderFailureComponent} /> */}
			<Route component={PageNotFoundComponent} />

		</Switch>
	);

}

const mapStateToProps = ({ global }) => ({
	token: global.token
})
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Router);
