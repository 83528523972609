import React, { Component } from 'react';
import { Form, Input, Button, Card, Col, Row, message } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Authentication from "../../service/authService"

class BankVerifyComponent extends Component {
  verifyBankRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }
  componentDidMount() { }

  verifyPayment = async (values) => {
    const { onBankVerifySubmit, selectedBankId, t } = this.props;
    let { data: res } = await Authentication.verifyBank(values, selectedBankId);    //-> data = {isSuccess: ..., data: ..., applicationCode: ... }
    if (res.applicationCode === 200) {
      this.verifyBankRef.current.resetFields();
      this.setState({
        loading: false
      })
      if (onBankVerifySubmit) {
        onBankVerifySubmit()
      }
    } else {
      this.verifyBankRef.current.resetFields();
      message.error(`${t('Entered amount is incorrect')}`);
      this.setState({
        loading: false
      })
    }
  }

  skipVerification = () => {
    const { onBankVerifySubmit } = this.props;
    if (onBankVerifySubmit) {
      onBankVerifySubmit()
    }
  }

  render() {

    const { t } = this.props

    return (<>

      <div className='justify-content-center'>
        <Card style={{ width: 380 }} bordered={false}>
          <div className='text-align-center mb-4'>
            <h3>{t('Enter bank account info')}</h3>
          </div>

          <Form ref={this.verifyBankRef}
            layout={'vertical'}
            scrollToFirstError
            onFinish={this.verifyPayment}
            requiredMark={false}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label={`${t('Verify bank details')}*`}
                  name="verifyAmount"
                  rules={[{
                    required: true,
                    whitespace: true,
                    message: `${t('Please enter debited amount reqd validation')}`
                  }]}
                >
                  <Input type="number" placeholder={t('Verify bank details')}
                    onChange={(val) => { }} />
                </Form.Item>
                <p style={{ color: '#636363', fontSize: 12 }}>Note: A small amount between Rs 1 and Rs 2 is credited to your account. Please enter that amount to verify the bank details.</p>
                <p style={{ color: '#636363', fontSize: 12 }}>
                  नोट: 1 रुपये से 2 रुपये के बीच की एक छोटी राशि आपके खाते में जमा की जाती है। बैंक विवरण सत्यापित करने के लिए कृपया वह राशि दर्ज करें।
                </p>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button
                    htmlType='submit'
                    className={'submitBtn2'}
                    loading={this.state.loading}
                    type="primary"
                  >{t('Verify')}</Button>
                  <Button
                    className={'cancelBtn'}
                    type="default"
                    onClick={this.skipVerification}
                  >{t('Skip')}</Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>

    </>
    );
  }
}


const mapStateToProps = ({ global }) => ({
  token: global.token,
  currentUser: global.currentUser,
  loading: global.loading
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(BankVerifyComponent)));


