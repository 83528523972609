import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import LayoutComponent from "../layoutComponents/layoutComponent";
import { Row, Col, Switch, message, Drawer } from "antd";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import myimage from "../../imageNotFound.jpeg";
import SaveListing from "../../service/saveListingService";
import AddListingService from "../../service/addListingService";
import { DeleteOutlined, CopyOutlined, EditOutlined } from "@ant-design/icons";
import MediaUrl from "../../service/mediaUrl";

class AddEditNewSetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapProductSets: [],
      visible: false,
      placement: "bottom",
      setDetail: {},
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      localStorage.removeItem("currentCopySet");
    }
    if (SaveListing.mapCategoryId === "") {
      window.onbeforeunload = function (evt) {
        var message = "Are you sure you want to leave?";
        if (typeof evt == "undefined") {
          evt = window.event;
        }
        if (evt) {
          evt.returnValue = message;
        }
        return message;
      };
      let productId =
        typeof window !== "undefined" ? localStorage.getItem("id") : null;
      if (productId) {
        productId = parseInt(productId);
        if (typeof window !== "undefined") localStorage.removeItem("id");
        this.props.history.push({ pathname: `/create-list/${productId}` });
      } else {
        this.props.history.push({ pathname: `/category` });
      }
    } else {
      if (SaveListing.mapProductSets.length > 0) {
        let productID = parseInt(SaveListing.productID);
        this.setState({
          mapProductSets: SaveListing.mapProductSets,
          productId: productID,
        });
      }
    }
  }

  navigateToCreateListPage = () => {
    SaveListing.isSetCreated = true;
    let productID = parseInt(SaveListing.productID);
    if (productID) {
      SaveListing.isNewSet = false;
      this.props.history.push({ pathname: `/create-list/${productID}` });
    } else {
      this.props.history.push({ pathname: "/create-list" });
    }
  };

  navigateToCreateSetPage = () => {
    SaveListing.isSetCreated = true;
    SaveListing.isNewSet = true;
    SaveListing.selectProductSetID = "";
    this.props.history.push({ pathname: "/create-sets" });
  };

  EditSet = (set, index) => {
    SaveListing.isSetCreated = true;
    SaveListing.isNewSet = false;
    SaveListing.selectProductSetID = set.id;
    SaveListing.selectProductSetIndex = index;
    this.props.history.push({ pathname: "/create-sets" });
  };

  copySet = (set, index) => {
    SaveListing.isSetCreated = true;
    SaveListing.isNewSet = true;
    SaveListing.selectProductSetID = "";
    if (typeof window !== "undefined") {
      localStorage.setItem("currentCopySet", JSON.stringify(set));
    }
    this.props.history.push({ pathname: "/create-sets" });
  };

  deleteSet = async (itemData, index) => {
    let { productId } = this.state;
    const { t } = this.props;
    if (SaveListing.mapProductSets && SaveListing.mapProductSets.length > 1) {
      SaveListing.mapProductSets.splice(index, 1);
      this.setState({ mapProductSets: SaveListing.mapProductSets });

      if (productId && itemData.id) {
        let x = await AddListingService.deleteSetById({
          id: productId,
          setId: itemData.id,
        });
        if (x.data.isSuccess) {
          this.setState({ mapProductSets: SaveListing.mapProductSets });
        } else {
          message.error(`${t("Error in deleting set")}`);
        }
      }
    } else {
      message.error(`${t("Product must contain atleast 1 set")}`);
    }
  };

  onSwitchChange = (val, setId) => {
    let { mapProductSets, productId } = this.state;
    const { t } = this.props;
    if (mapProductSets.length > 1) {
      AddListingService.updateSetIsAvailableStatus(productId, setId, val).then(
        (res) => {
          if (res.data.isSuccess) {
            mapProductSets = _.map(mapProductSets, (item, index) => {
              if (item.id === setId) {
                item.isAvailable = val;
              }
              return item;
            });
            this.setState({ mapProductSets });
          }
          message.success(`${t("Status updated successfully")}`);
        },
        (err) => {
          message.error(err.data.message);
        }
      );
    } else {
      message.error(`${t("Product contain only 1 set")}`);
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  renderSize = (item) => {
    let arr = [];
    const { t } = this.props;
    item &&
      item.mapAttributes &&
      item.mapAttributes.length > 0 &&
      item.mapAttributes.forEach((size, key) => {
        if (size.selected) {
          arr.push(size.childAttribute.value);
        }
      });
    return (
      <p className={"ml-1"}>
        {t("Size")} - {`${arr.join(", ")} `}
        <span>|</span>
      </p>
    );
  };

  renderModelName = (data) => {
    const { t } = this.props;
    const { selectedModelSet } = data;
    let modelCount = Object.keys(selectedModelSet).length;
    let name = [];
    if (modelCount) {
      for (var item in selectedModelSet) {
        selectedModelSet[item].parentAttribute.mapAttributes.forEach((attr) => {
          if (attr.selected) {
            name.push(attr.childAttribute.value);
          }
        });
      }
      return (
        <p className={"ml-1"}>
          {t("Compatible Device")}: {name.join(", ")} <span></span>
        </p>
      );
    }
  };

  render() {
    const { mapProductSets, visible, placement, setDetail } = this.state;
    const { t } = this.props;

    return (
      <LayoutComponent title={t("Create Sets")}>
        <Row className="divWithFixButton">
          <Col span={24} className="bottom-border">
            <div className="button_div">
              <button onClick={(e) => this.navigateToCreateSetPage()}>
                + {t("Create new set")}
              </button>
            </div>
          </Col>

          <Col span={24} className="bottom-border mt-3">
            {/* <h6>Edit Sets</h6> */}
            <p>
              {mapProductSets.length} {t("Sets")}
            </p>
          </Col>

          {mapProductSets &&
            mapProductSets.map((item, i) => {
              return (
                <Col span={24} key={i} className="addEditSet">
                  <div className="setData pb-3">
                    {item.colorWithSize.parentAttribute.mapAttributes.map(
                      (img, ikey) => {
                        return (
                          <div key={ikey}>
                            {img.selected && img.images.length > 0 ? (
                              <div className={"setImage"}>
                                <img
                                  src={MediaUrl.completeMediaUrl(
                                    img.images[0].url,
                                    true,
                                    100,
                                    100
                                  )}
                                  alt="banner"
                                />
                              </div>
                            ) : null}
                          </div>
                        );
                      }
                    )}
                    <div className="row w-100">
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                          <div className="row no-gutters">
                              <strong>
                                {t("Set")} {i + 1}:{" "}
                              </strong>
                              {this.renderSize(item.selectedSizeSet)}
                           
                              <p className="ml-1">
                                {t("Price")}: ₹{item.pricePerPiece}{" "}
                                <span>|</span>
                              </p>
                           
                              {item.mapProductSetBulkPrices.length ? (
                                <p className="ml-1">
                                  {t("Bulk Prices")} <span>|</span>
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="row ml-1 mt-1">
                            
                      <p>
                        {item.colorWithSize.parentAttribute.mapAttributes.map(
                          (color, cIndex) => {
                            return (
                              <span key={cIndex}>
                                {color.selected ? (
                                  color.childAttribute.value ===
                                  "Multicolor" ? (
                                    <span
                                      key={`color${cIndex}`}
                                      style={{
                                        display: "inline-block",
                                        height: 22,
                                        width: 22,
                                        backgroundImage:
                                          "url(https://akar-prod-media.s3.ap-south-1.amazonaws.com/StaticAssets/color-circle.png)",
                                        marginRight: "4px",
                                        backgroundSize: "contain",
                                      }}
                                    ></span>
                                  ) : (
                                    <span
                                      key={`color${cIndex}`}
                                      style={{
                                        display: "inline-block",
                                        height: 22,
                                        width: 22,
                                        backgroundColor:
                                          color.childAttribute.value,
                                        marginRight: "4px",
                                      }}
                                    ></span>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            );
                          }
                        )}
                      </p>
                          </div>
                          {/* <p>Size - {item && item.selectedSizeSet && item.selectedSizeSet.mapAttributes.map((size, sIndex) => <span key={`size${sIndex}`}> {size.selected ? <span> {size.childAttribute.value}, </span> : ''}  </span>)} <span >|</span></p> */}
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12 ">
                          <div className="row">
                            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
                              {item &&
                              item.selectedModelSet &&
                              typeof item.selectedModelSet["undefined"] !==
                                "object"
                                ? this.renderModelName(item)
                                : null}
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                              <p className="ml-1">
                                {" "}
                                MOQ- {item.minimumSetOrder}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                        </div>
                      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                    <div className="ml-1">
                      <p
                        className="button_Color"
                        onClick={() => {
                          this.setState({ setDetail: item }, () => {
                            this.showDrawer();
                          });
                        }}
                      >
                        {t("View details")}
                      </p>
                      <div>
                        <a className="">
                          {item.isAvailable
                            ? `${t("Available")}`
                            : `${t("Unavailable")}`}
                        </a>
                        <Switch
                          checked={item.isAvailable}
                          onChange={(e) => this.onSwitchChange(e, item.id)}
                        />
                      </div>
                    </div>

                      </div>

                    </div>
                    {/* <div className="spacer" /> */}
                  </div>
                  <div>
                    <Row style={{ textAlign: "center", cursor: "pointer" }}>
                      <Col span={8} onClick={(e) => this.EditSet(item, i)}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <EditOutlined />
                          <span className="fontSize13Px">{t("Edit")}</span>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                          onClick={() => this.copySet(item, i)}
                        >
                          <CopyOutlined />
                          <span className="fontSize13Px">{t("Copy")}</span>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                          onClick={() => this.deleteSet(item, i)}
                        >
                          <DeleteOutlined />
                          <span className="fontSize13Px">{t("Delete")}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
        </Row>
        <Row>
          <Col span={24} className="stickToBottom">
            <div className="button_div">
              <button onClick={(e) => this.navigateToCreateListPage()}>
                {t("Done")}
              </button>
            </div>
          </Col>
        </Row>

        <Drawer
          title={t("Set details")}
          height={"100vh"}
          placement={placement}
          onClose={this.onClose}
          visible={visible}
          key={placement}
          className={"setDetailDrawer"}
          headerStyle={{ background: "rgba(19, 39, 67, 1)" }}
        >
          <div className="setDrawer">
            {setDetail && (
              <table className="setTable">
                <thead>
                  <tr>
                    <th>{t("Color")}</th>
                    <th>{t("Size")}</th>
                    <th>{t("Quantity")}</th>
                  </tr>
                </thead>
                <tbody>
                  {setDetail.colorWithSize &&
                    setDetail.colorWithSize.parentAttribute &&
                    setDetail.colorWithSize.parentAttribute.mapAttributes.map(
                      (color, cIndex) => {
                        return (
                          <>
                            {color.selected ? (
                              <tr>
                                <td>
                                  {color.childAttribute.value ===
                                  "Multicolor" ? (
                                    <span
                                      key={`color${cIndex}`}
                                      style={{
                                        display: "inline-block",
                                        height: 22,
                                        width: 22,
                                        backgroundImage:
                                          "url(https://akar-prod-media.s3.ap-south-1.amazonaws.com/StaticAssets/color-circle.png)",
                                        marginRight: "4px",
                                        backgroundSize: "contain",
                                      }}
                                    ></span>
                                  ) : (
                                    <span
                                      key={`color${cIndex}`}
                                      style={{
                                        display: "inline-block",
                                        height: 22,
                                        width: 22,
                                        backgroundColor:
                                          color.childAttribute.value,
                                        marginRight: "4px",
                                      }}
                                    ></span>
                                  )}
                                </td>
                                <td>
                                  {color.sizes &&
                                    color.sizes.mapAttributes &&
                                    color.sizes.mapAttributes.map(
                                      (size, sIndex) => {
                                        return (
                                          <ul key={sIndex}>
                                            {size.selected ? (
                                              <li>
                                                {size.childAttribute.value}
                                              </li>
                                            ) : (
                                              ""
                                            )}
                                          </ul>
                                        );
                                      }
                                    )}
                                </td>
                                <td>
                                  {color.sizes &&
                                    color.sizes.mapAttributes &&
                                    color.sizes.mapAttributes.map(
                                      (size, sIndex) => {
                                        return (
                                          <ul key={sIndex}>
                                            {size.selected ? (
                                              <li>{size.quantity}</li>
                                            ) : (
                                              ""
                                            )}
                                          </ul>
                                        );
                                      }
                                    )}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }
                    )}
                </tbody>
              </table>
            )}
          </div>
        </Drawer>
      </LayoutComponent>
    );
  }
}

export default withTranslation()(withRouter(AddEditNewSetComponent));
