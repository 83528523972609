import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Footer from '../footer';
import LayoutComponent from '../layoutComponents/layoutComponent';
import Layout from "./../layout";
const content =
    <div className={'contact_container'}>
        <div>
            <h1>Refund & Cancellation Policy</h1>
        </div>

        <div>
            <h1 className='mt-4'>Case 1 – RTO :</h1>
        </div>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                RTO is the case when the shipment is not delivered to the buyer and the shipment returns to seller , the reason for the same can be multiple (Example : buyer refuses to take the parcel , cash not ready , logisitics policy of attempt completion etc.)                    </span>
        </p>
        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                In case the parcel or shipment is not delivered to customer  or buyer because of any of the reason , the seller is bound to accept the return, the seller will not be charged the platform fees and other charges in such case.
            </span>
        </p>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                In case the seller find any product missing , or change in product , or any other issue in RTO delivered to him he can raise a dispute against the RTO ( within specified time window formulated by the company i.e. within 36 hours of RTO delivery )  and has to mention the reason of dispute with valid proofs and documentation.                    </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                <b> Time window for raising dispute is 36 hours after the time of delivery of RTO.        </b>            </span>
        </p>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                For further clarification the AKAR executive can contact the seller and can ask for other documentation and valid proofs such as packing video, unpacking video or images of the RTO received.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                The packing video and unpacking video should be recorded and shall fulfill the criteria of video made by the company <b>‘AKAR COMMERCE INDIA PRIVATE LIMITED’</b> or <b>‘AKAR’  </b>                  </span>
        </p>



        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                If all the proofs and documentation provided by the seller stand valid and genuine then the dispute will settled against the seller and this case will be then treated same as the shipment  which get delivered and the seller will get order amount after deducting all the platform charges and taxes after 4 working days from once the dispute have been resolved
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>

                If the proofs and documentation provided by the seller does not satisfy the claim reason or does not stand as a valid proof , then the claim will be rejected and will be settled against seller and he will not get any claim, and also the seller has to accept the same .
            </span>
        </p>


        <h2>The criteria of recording packing video :
        </h2>
        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                The packing video should be recorded from the very beginning . firstly all the corner and each side of the box should be recorded clearly, number of pieces or set of the product should be clearly visible and countable while recording the video , the video should only end when the box is completely packed and the shipping label is pasted on the box and the shipping label should also be clearly visible.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                The video should not contain any cut or edit from start to end. If any cut , edit or any other discrepancy found in the video it will not accepted as a valid proof and the dispute may set against the seller , which means the seller will not compensation against the RTO delivered, and the seller has to accept the same.                    </span>
        </p>

        <h2>The criteria of recording unpacking video :</h2>
        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                While making the unpacking of any shipment , the video shall start from clearly capturing the shipping label before starting unpacking , also the every side and corner of the box or shipment should clearly recorded fully sealed and taped , after capturing all these things stated , the unpacking shall start , while recording it should be kept in mind that the number of pieces or sets of product should be clearly visible and countable, at the end the empty box have to be recorded clearly, the video should not contain any cut or edit from start to end.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                If any cut , edit or any other discrepancy is found , it will not be accepted as the valid proof and the claim may be rejected.
            </span>
        </p>


        <h1 className='mt-4'>Case 2 – RVP :</h1>
        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                RVP is the case when the buyer accept the delivery and raise the return request, with in formulated time span by the company ‘AKAR’ the reason may be multiple such as                    </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                For further clarification the AKAR executive can contact the seller and can ask for other documentation and valid proofs such as packing video, unpacking video or images of the RTO received.
            </span>
        </p>

        <ul style={{ paddingLeft: '10px' }}>
            <li
                className="MsoNormal mt-4"
                style={{ marginBottom: "0in", lineHeight: "normal" }}>
                <span style={{ fontSize: "14.0pt" }}>
                    Product(s) is physically damaged or is defective,
                </span>
            </li>

            <li
                className="MsoNormal mt-4"
                style={{ marginBottom: "0in", lineHeight: "normal" }}>
                <span style={{ fontSize: "14.0pt" }}>
                    Warranty issues or quality related issues with respect to the product delivered
                </span>
            </li>

            <li
                className="MsoNormal mt-4"
                style={{ marginBottom: "0in", lineHeight: "normal" }}>
                <span style={{ fontSize: "14.0pt" }}>
                    Wrong product or the product is not matching the description or specifications mentioned on the listing page on platform                        </span>
            </li>
            <li
                className="MsoNormal mt-4"
                style={{ marginBottom: "0in", lineHeight: "normal" }}>
                <span style={{ fontSize: "14.0pt" }}>
                    Part of the order/products is found missing due to reasons attribute to the seller, etc;                        </span>
            </li>
        </ul>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>

                At the time of raising return the buyer can only raise the request if the time window has not been closed, if the window is closed then the return request will be rejected by the company and buyer can not return the products and also the company is liable for the products being delivered .                    </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                <b>
                    Time window for raising return request is 36 hours after the time of delivery.
                </b>
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                In case the buyer raise a return request within formulated time span he has to provide the valid reason , proofs and documentation for the same.
                For further clarification AKAR executive may contact the buyer and can ask for valid proofs such as unpacking video and image , the executive can also ask for the clarification of what quality is differed from the listing of seller that he is not satisfied with, if the buyer provides valid and satisfactory proofs and answers , then the return would be accepted and if not ,the return request will be rejected and the buyer is bound to accept the decision of company.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                If the buyer raise a return request , and the request is accepted by company ‘AKAR’ , the buyer has to repack the parcel with the genuine products which he/she/they received , with the genuine quantity for which the return have been accepted or approved,
                And the packing video of the same is mandatory or compulsory for the buyer. The packing video must be recorded as per the terms specified and formulated by the company ‘AKAR’. In case of any dispute , if the buyer is unable to provide the proper packing video the dispute will settled against buyer and he will not get any refund or compensation for the parcel he returned, neither ‘we’ or ‘AKAR’ is responsible for the same and also not responsible for returning the shipment or parcel again to the buyer.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                Also, if the buyer returns the wrong product(s), different product(s), damaged product(s) or any other product or the same product in different state from which he received , the buyer refund request will not be approved and neither the ‘AKAR’ is responsible for returning the shipment or parcel again to the buyer , if any dispute is raised by the seller the dispute will settled in favor of seller.
            </span>
        </p>
        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                At the time of raising return the buyer can only raise the request if the time window has not been closed, if the window is closed then the return request will be rejected by the company and buyer can not return the products and also the company is liable for the products being delivered .
            </span>
        </p>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                In case the seller has to raise a dispute against the RVP  received, he/she/they has to raise a dispute within the formulated time span i.e. within 36 hours of RVP received. The seller has not right to raise the dispute after the time span formulated.                    </span>
        </p>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                If the seller raise a dispute within time span formulated, he has to mention the reason of dispute with proper proofs and documentation. For further clarification the ‘AKAR’ executive may contact the seller for further clarification and can also ask for valid proofs such as packing and unpacking video, images or any other relevant proof.
            </span>
        </p>
        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                The packing video and unpacking video should fulfill the criteria made by the company, ‘AKAR’. In the case the seller is unable to provide the valid proofs asked by the company, the dispute will settled against the seller and also the seller have to bear the transit cost of delivery of the product as well as the RVP received to him.
            </span>
        </p>
        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                And if the seller provides all the proofs and documentation satisfactory to the company the dispute will settled in favor of the seller. In such case after the dispute is solved the seller will get the payment with in the 4 days.
            </span>
        </p><p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                The buyer and seller has to make the packing and unpacking video according to the criteria mentioned and formulated by the company ‘AKAR’.
            </span>
        </p>

        <h1 className='mt-4'>The criteria of recording packing video :</h1>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                The packing video should be recorded from the very beginning. firstly all the corner and each side of the box should be recorded clearly, number of pieces or set of the product should be clearly visible and countable while recording the video , the video should only end when the box is completely packed and the shipping label is pasted on the box and the shipping label should also be clearly visible.
            </span>
        </p>
        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                The video should not contain any cut or edit from start to end. If any cut , edit or any other discrepancy found in the video it will not accepted as a valid proof and the dispute may set against the seller , which means the seller will not compensation against the RTO delivered, and the seller has to accept the same.
            </span>
        </p>
        <h1 className='mt-4'>The criteria of recording unpacking video :
        </h1>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                While making the unpacking of any shipment , the video shall start from clearly capturing the shipping label before starting unpacking , also the every side and corner of the box or shipment should clearly recorded fully sealed and taped , after capturing all these things stated , the unpacking shall start , while recording it should be kept in mind that the number of pieces or sets of product should be clearly visible and countable, at the end the empty box have to be recorded clearly, the video should not contain any cut or edit from start to end.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                If any cut, edit or any other discrepancy is found, it will not be accepted as the valid proof and the claim may be rejected.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                While the dispute is being solved the funds of both the side i.e. buyer and seller will be on hold, the funds will released within in the 4 days after the dispute is solved.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                If the dispute is solved in favor of buyer the buyer will get the refund of the product along with the delivery charges, if any, charged on him during the delivery of order, within 4 days after the dispute is solved, till then the balance will credited in the electronic ledger of the buyer and the buyer can use the balance to buy or purchase any of the products from the platform ‘AKAR’.
            </span>
        </p>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>

                If the dispute is settled against the buyer the buyer will not get the refund or any other charges he paid during the delivery of products ,and  neither the company nor the seller is responsible for delivering the parcel back to the buyer (parcel which was returned by the buyer.)                    </span>
        </p>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                If the dispute is settled in favor of seller, the seller will get order amount within 4 days after solving the dispute after deducting the platform charges or any other charges the seller is liable to pay for .
            </span>
        </p>

        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                And if the dispute is settled against the seller, the seller will not get the order amount , also the logistic charges incurred on delivering the parcel and return both will be deducted from the future payment and will be debited in the electronic ledger of seller.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                The both buyer and seller is bound to accept the decision made by the company ‘AKAR’ or ‘ AKAR COMMERCE INDIA PRIVATE’.
            </span>
        </p>


        <p
            className="MsoNormal mt-4"
            style={{ marginBottom: "0in", lineHeight: "normal" }}
        >
            <span style={{ fontSize: "14.0pt" }}>
                <b> THE RETURN SHIPMENT POLICY CAN BE CHANGED FROM TIME TO TIME.</b>
            </span>
        </p>
    </div>


class ReturnPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null
        }

        window.scroll(0, 0);
    }

    render() {

        return (

            <>
                {this.state.token ?
                    <LayoutComponent>
                        {content}
                    </LayoutComponent> :
                    <Layout>
                        {content}
                    </Layout>}

                <Footer />

            </>)
    }
}


export default withRouter(ReturnPolicy);

