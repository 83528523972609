import API from "./network";
import Urls from "./urls";

const LedgerService = {
  async getLedger(data) {
    let { fromDate, toDate, pageNumber, lookForOrderID } = data;
    let queryParam = "";
    if (lookForOrderID) {
      queryParam = `?startDate=${fromDate}&endDate=${toDate}&limit=${10}&pageNumber=${pageNumber}&orderCustomId=${lookForOrderID}`;
    } else {
      queryParam = `?startDate=${fromDate}&endDate=${toDate}&limit=${10}&pageNumber=${pageNumber}`;
    }
    const path = Urls.getLedger + queryParam;
    let res = await API.makeGetRequest(path, null, null, null);
    if (res) {
      return res.data;
    } else {
      return null;
    }
  },
  getScheduledtransaction(args){
    let { id, pageNumber = 1, limit = 10 } = args;
    let path = `${Urls.getScheduledTransactions}?pageNumber=${pageNumber}&limit=${limit}&accountId=${id}`;
    return API.makeGetRequest(path, null, null, null)
            .then((res) => {
                return res.data
            })
  },
};

export default LedgerService;
