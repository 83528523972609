import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MediaUrl from "../../service/mediaUrl";
import ProductCard from "../elements/ProductCard";
import SectionLoader from "./sectionLoader";
import { withTranslation } from "react-i18next";
import SearchService from "../../service/searchService";
import Api from "../../service/network";
import { Row, Col, Button } from "antd";
import ComingSoonComponent from "./comingSoonSection"

class CardSliderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],
      cardPostData: [],
      payLoad: {},
    };
  }

  componentDidMount() {

    const { itemData,showScrollBar } = this.props;
    if (itemData.callType === "Get") {
      //let repURL = itemData.dataUrl.replace("devapi.akarindia.com/business-service/v1/","localhost:4132/")
      Api.makeGetRequest(itemData.dataUrl, null, null, null).then((res) => {
        let output = res.data.data.data;
        output.forEach((img) => {
          if (img.imageUrl) {
            let url = MediaUrl.completeMediaUrl(img.imageUrl, true, 100, 100);
            img.imageUrl = url;
          }
        });
        this.setState({ cardData: output });
      });
    }
    if (itemData.callType === "Post") {
      let payLoad = itemData.payLoad;
      let filters;
      try {
        if (typeof payLoad === "string") {
          filters = JSON.parse(payLoad);
        } else {
          filters = payLoad;
        }
      } catch (error) {
        //console.log("catch block error =",error)
      }
      SearchService.algoliaProductsearch("", filters).then((data) => {
        this.setState({ cardPostData: data, payLoad: filters });
      });
    }
  }

  goToAllDataPage = () => {
    const { payLoad } = this.state;
    const { itemData } = this.props;
    let parsedPayload = payLoad;
    if (parsedPayload && parsedPayload["hitsPerPage"]) {
      delete parsedPayload["hitsPerPage"];
    }
    let title = encodeURIComponent(itemData.name);
    let encoded = Buffer.from(
      JSON.stringify(parsedPayload),
      "ascii"
    ).toString("base64");
    this.props.history.push({
      pathname: `/search/listings/title=${title}/${encoded}`,
    })
  }

  render() {
    const { cardData, cardPostData } = this.state;
    const { itemData, token, t, showLoadMore } = this.props;

    if (itemData && ((cardPostData && cardPostData.length > 0) || cardData)) {
      return (
        <div className="toolbar_navigation-items mt-2">
          {cardPostData && cardPostData.length > 0 ? (
            <div className="card_div"
              style={{
                display: "flex",
                
                justifyContent: "space-between",
                alignItems: "center",
                background: "#F4F5F7",
              }}
              onClick={() => { this.goToAllDataPage() }}
            >
              <div className={"productSectionTitle"}>{itemData.name}</div>
              <div className={" d-flex align-items-center"}>
                <p
                  style={{ textDecoration: "underline" }}
                  className="pointer_Cursor tab_heading mt-3 mr-3"
                >
                  {t("View All")}
                </p>
              </div>
            </div>
          ) : null}
          <div style={{}}>

          <Row 
            gutter={{ xs: 8, sm: 16, md: 24, lg:32 }}
            className="cardSliderRow"
            style={{ marginTop: "15px" ,justifyContent:"space-between"}}
          >
            {cardData &&
              cardData.length > 0 &&
              cardData.map((cardval, cardKey) => {
                return (
                  <Col className="colFive" key={cardKey}>
                    <ProductCard data={cardval} token={token} />
                  </Col >
                );
              })}
            {cardPostData && cardPostData.length > 0
              ? cardPostData.map((item, index) => {
                if(itemData.name == 'ERD' || itemData.name == 'Suiteville' || itemData.name == 'Clothing' || itemData.name == 'Speakers'){
                return (
                    <Col xs={12} sm={12} md={8} lg={6} xl={6} className="colFive" key={index}>
                    <ProductCard data={item} token={token} />
                  </Col>
                );
                }
                else if(itemData.name == "Women's Ethnic"){
                  return (
                    <Col xs={12} sm={12} md={6} lg={4} xl={4} className="colFive" key={index}>
                      <ProductCard data={item} token={token} />
                    </Col>
                  );
                }
                else{
                  return (
                    <Col xs={12} sm={12} md={6} lg={4} xl={4} className="colFive" key={index}>
                      <ProductCard data={item} token={token} />
                    </Col>
                  );
                }
              })
              : null}
          </Row>
          </div>

          {showLoadMore && cardPostData && (cardPostData.length === 10 || cardPostData.length > 10) ? (
            <div className="text-center">
              <Button
                type="primary"
                className="mt-4"
                onClick={() => { this.goToAllDataPage() }}
              >
                Load More
              </Button>
            </div>
          ) : null}
        </div>
      );
    }

    return <div className="sectionloader"><SectionLoader /></div >
  }
}

const mapStateToProps = ({ global }) => {
  return {
    currentUser: global.currentUser,
    token: global.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CardSliderView))
);
