import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent';
import { Row, Col, message, Button, Skeleton } from 'antd';
// import { DeleteFilled } from '@ant-design/icons';
import moment from 'moment';
import { connect } from 'react-redux';
import CartService from '../../service/cart';
import DisputeService from '../../service/disputeService';
import MediaUrl from '../../service/mediaUrl'
import myImage from '../../imageNotFound.jpeg';
import './index.scss'
import Footer from '../footer'
import { withTranslation } from 'react-i18next';

class ReturnDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			returnDetailsData: {},
			disputesListCount: 0,
			isSeller: false,
			shippingDetails: {
				id: '',
				awbNumber: '',
				manifestUrl: '',
				labelUrl: '',
			}
		}
	}

	componentDidMount() {
		this.getOrderReturnDetail();
	}

	getAllDisputesByReturnCustomId = async () => {
		let orderReturnCustomId = this.props.match.params.id
		let { data } = await DisputeService.getAllOrderDisputes(orderReturnCustomId)
		if (data.applicationCode === 200) {
			this.setState({ disputesListCount: data.data.totalCount })
		}
	}

	getOrderReturnDetail = async () => {
		let orderReturnId = this.props.match.params.id;
		const { currentUser, t } = this.props

		let x = await CartService.getSingleReturnOrder(orderReturnId);

		if (x.data.isSuccess) {
			let { data } = x.data;

			if (data.status === 'Approved' || data.status === 'Shipped') {
				let details = await CartService.getReturnShipmentDetails(orderReturnId);
				if (details && details.data.isSuccess) {
					let { data } = details.data;
					if (data) {
						this.setState({
							shippingDetails: {
								id: data.id,
								awbNumber: data.awbNumber || '',
								labelUrl: data.labelUrl || '',
								manifestUrl: data.manifestUrl || '',
								shippingCharges: data.shippingCharges || ''
							}
						})
					}
				}
			}
			data.orderReturnLineItems.forEach(item => {
				let imgUrl = item.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url
				if (imgUrl) {
					let url = MediaUrl.completeMediaUrl(imgUrl, true, 100, 100)
					item.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url = url
				}
			})

			if (data.sellerAccountId === currentUser.accountId && currentUser.account.type === 'Seller') {
				this.setState({ isSeller: true })
			} else {
				this.setState({ isSeller: false })
			}

			this.setState({ returnDetailsData: data }, () => {
				if (data.status === 'Delivered') {
					this.getAllDisputesByReturnCustomId()
				}
			})
		}
		else {
			message.error(`${t('Error in fetching data')}`)
		}
	}

	invoiceHandler = () => {
		const { returnDetailsData, disputesListCount, isSeller, shippingDetails } = this.state
		if (shippingDetails.shippingCharges + returnDetailsData.approvedTotalAmount == returnDetailsData.totalAmount) {
			return false;
		}
		return true;
	}

	navigate = (url) => {
		const { returnDetailsData } = this.state
		this.props.history.push({ pathname: url, search: `?orderReturnCustomId=${returnDetailsData.orderReturnId}` })
	}

	render() {
		const { returnDetailsData, disputesListCount, isSeller, shippingDetails } = this.state
		const { currentUser, t } = this.props;
		return (<>
			<LayoutComponent title={t("Return Details")}>
				<div className="divWithoutFixButton mt-4 mb-5">
					{returnDetailsData && returnDetailsData.orderReturnId ?
						<Row>
							<Col span={24}>
								{disputesListCount === 0 &&
									isSeller &&
									returnDetailsData && returnDetailsData.isDisputable &&
									returnDetailsData && returnDetailsData.status &&
									returnDetailsData.status === 'Delivered' ?
									<div className={'p-3 profile_card'}>
										<Button id='raise-dispute-btn'
											type="primary"
											onClick={() => {
												this.navigate('/create-dispute')
											}}>{t('Raise Dispute')}</Button>
									</div>
									: null}
							</Col>
							<Col span={24}>
								<div className={'p-3 profile_card'}>
									<div className={'flexContainer pointer_Cursor'} >
										<div className={'flexRightDiv'}>

											<p className='textcolor font14px'>{t('Order By')} : <span style={{ fontWeight: '700', color: '#000', fontSize: '16px' }}>{returnDetailsData['buyerAccountName']}</span></p>
											<p className='textcolor font14px'>{t('Sold By')} : <span style={{ fontWeight: '700', color: '#000', fontSize: '16px' }}>{returnDetailsData['sellerAccountName']}</span></p>
										</div>
										<div className='flexLeftDiv'> </div>
									</div>
									<hr />
									<div className={'flexContainer'}>
										<div className={'flexFullDiv'} id= "return-detail-div">
											<div className='font14px mt-2'><b>{t('Return Order Id')}: </b>{returnDetailsData.orderReturnId}</div>
											<div className='font14px mt-2'>
												<b>{t('Status')} : </b><span>{returnDetailsData.status === 'ApprovedWithDiscount' ? `${t('Approved with Discount')}` : `${t(returnDetailsData.status)}`}</span>
											</div>
											<div className='font14px mt-2'><b>{t('Reason')} : </b>{returnDetailsData.reason || ''}</div>
											<div className='font14px mt-2'><b>{t('Comment')} : </b>{returnDetailsData.comment}</div>
											<div className='font14px mt-2'><b>{t('Created On')}:</b> {moment(returnDetailsData.createdOn).format('LL')}</div>
											{
												isSeller
													? null
													: <div className='font14px mt-2'><b>{t('Shipping_Charges')} : </b>₹ {returnDetailsData.shippingCharges ? returnDetailsData.shippingCharges : 0}/-</div>
											}

											{
												isSeller
													? <div className='font14px mt-2'><b>{t('Total Amount')} : </b>₹ {returnDetailsData && (returnDetailsData.totalAmount - returnDetailsData.shippingCharges).toFixed(2)}/-</div>
													: <div className='font14px mt-2'><b>{t('Total Amount')} : </b>₹ {returnDetailsData ? returnDetailsData.totalAmount : 0}/-</div>
											}
										</div>
									</div>
								</div>
							</Col>
							<Col span={24}>
								<div className={'p-3 profile_card'}>
									{
										this.invoiceHandler() ?
											<div className={'flexContainer'}>
												<div className={'flexFullDiv'}>
													<h2 className='mb-2'>{t('Invoice Details')}</h2>
													{returnDetailsData.buyerInvoiceNumber ?
														<>
															<div className='textcolor font14px'>{t('Buyer Invoice Number')}</div>
															<div><b className='font14px'>{returnDetailsData.buyerInvoiceNumber}</b></div>
															<br />
														</>
														: null}
													{returnDetailsData.sellerInvoiceNumber ?
														<>
															<div className='textcolor font14px'>{t('Seller Invoice Number')}</div>
															<div><b className='font14px'>{returnDetailsData.sellerInvoiceNumber}</b></div>
														</> : null}
													{!returnDetailsData.sellerInvoiceNumber && !returnDetailsData.buyerInvoiceNumber ?
														<div><b className='font14px'>{t("Invoice will be available once the order fulfilment has been completed")}</b></div>
														: null}
												</div>
											</div>
											: null}

									{
										shippingDetails.shippingCharges
											? <>
												<div className='textcolor font16px'>{t('Approved amount')}</div>
												<div><b className='font14px'> ₹ {shippingDetails.shippingCharges}</b></div>
											</>
											: null
									}
									<div style={{
										display: 'flex', flexDirection: 'row', justifyContent: 'flex-start',
										alignItems: 'center', marginTop: '24px'
									}}>
										{
											shippingDetails.labelUrl
												? <Button
													type="primary"
													className='mr-2 btn-btn-sm'
													onClick={() => {
														window.open(shippingDetails.labelUrl)
													}}>{t('Downlaod Shipping Label')}</Button>
												: null
										}
										{
											shippingDetails.manifestUrl
												? <Button
													type="primary"
													className='mr-2 btn-btn-sm'
													onClick={() => {
														window.open(shippingDetails.manifestUrl)
													}}>{t('Download Manifest url')}</Button>
												: null
										}
									</div>
								</div>
							</Col>
							{
								shippingDetails.awbNumber
									? <Col span={24}>
										<div className={'p-3 profile_card'}>
											<div className={'flexContainer'}>
												<div className={'flexFullDiv'}>
													<h2>{t('Pickup Details')}</h2>
													<div className='textcolor font16px'>{t('AWB Number')}</div>
													<div><b className='font14px'>{shippingDetails.awbNumber}</b></div>

													{(returnDetailsData.status === 'Shipped') ?
														<Button
															type="primary"
															className='mr-2 mt-4 btn-btn-sm'
															onClick={() => {
																window.open(`https://shiprocket.co//tracking/${shippingDetails.awbNumber}`)
															}}>{t('View Tracking Details')}</Button> : null}
												</div>
											</div>
										</div>
									</Col>
									: null
							}

							<Col span={24}>
								<div className={'p-3 profile_card'}>
									<div className={'flexContainer'}>
										<div className={'flexFullDiv'}>
											<h2>{t('Refund Details')}</h2>
											<div className='textcolor font16px'>{t('Approved amount')}</div>
											<div><b className='font14px'> ₹ {returnDetailsData.status === "ApprovedWithDiscount" ? returnDetailsData.approvedDiscountAmount : returnDetailsData.approvedTotalAmount}</b></div>
										</div>
									</div>
								</div>
							</Col>
							<Col span={24}>
								<div className='profile_card'>
									<div className='heading'>
										<h2 className="pl-2"> {t('Items')} </h2>
									</div>

									{returnDetailsData.orderReturnLineItems && returnDetailsData.orderReturnLineItems.length > 0 ? returnDetailsData.orderReturnLineItems.map((item, rKey) => {
										let imgUrl = item.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url
										return (

											<div className='profile_card' key={rKey}>
												<Row>
													<Col span={24}>
														<Row>
															<Col xl={4} xs={24}>
																<div className="prodImg_div">
																	<img src={imgUrl ? imgUrl : myImage}
																		alt="preview"
																		style={{
																			width: '100px',
																			height: '100px',
																			objectFit: 'contain',

																		}} /></div>
															</Col>
															<Col xl={20} xs={24}>
																<div >
																	<div className='font16px mb-3'>
																		<b>{item.productOrderLineItem.name}</b></div>
																	<Row >
																		<Col span={8} >
																			<b className="textcolor">{t('Units')}</b>
																		</Col>
																		<Col span={8}>
																			<b className="textcolor">{t('Amount')}/{t('Unit')}</b>
																		</Col>
																		<Col span={8}>
																			<b className="textcolor">{t('Amount')}/{t('Unit')} ({t('Including Gst')})</b>
																		</Col>
																	</Row>
																	<hr />
																	<Row >
																		<Col span={8}>{item.vendorQuantity}</Col>
																		<Col span={8}>₹{item.productOrderLineItem.pricePerSetWithoutGst}</Col>
																		<Col span={8}>₹{item.vendorPricePerSet}</Col>
																	</Row>
																</div>
															</Col>
														</Row>

													</Col>
												</Row>
											</div>
										)
									}) : null}


								</div>
							</Col>
						</Row>
						: <Skeleton active />}

				</div>
			</LayoutComponent><Footer /></>)
	}
}


const mapStateToProps = ({ global }) => ({
	currentUser: global.currentUser,
})
const mapDispatchToProps = dispatch => {
	return {
		dispatch
	}
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ReturnDetail)));
