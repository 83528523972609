import API from './network';
import Urls from './urls'

const Authentication = {
	postLoginMobile(mobile) {
		const path = Urls.login;
		var body = { "PhoneNumber": mobile, };
		return API.makePostRequest(path, null, null, body, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	setVacationMode(status) {
		const path = Urls.vacation;
		var body = { "IsAcceptingOrder": status };
		return API.makePutRequest(path, null, null, body, null)
			.then((res) => {
				return res;
			}).catch((err) => { return err })
	},

	getStates() {
		const path = Urls.states
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	getBusinessTypes() {
		const path = Urls.businessTypes
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	postLoginOtp(mobile, otp, isRetry, businessName) {
		const path = Urls.login
		var body = {
			"PhoneNumber": mobile, "password": String(otp),
			"IsTermsAndConditionApprovedByBuyer": true,
			businessName
		};
		if (isRetry) {
			body.isRetry = true
		}
		return API.makePostRequest(path, null, null, body, null)
			.then((res) => {
				let Authorization = res.headers.authorization
				if (typeof window !== 'undefined') { localStorage.setItem('Authorization', Authorization) }
				return res
			}).catch((err) => { return err })
	},

	postSignup(data) {
		const path = Urls.signup
		var body = data
		body['country'] = 'India'
		return API.makePutRequest(path, null, null, body, null)
			.then((res) => {
				let Authorization = res.headers.authorization
				if (typeof window !== 'undefined') { localStorage.setItem('Authorization', Authorization) }
				return res
			}).catch((err) => { return err })
	},

	addUserKYCDocImage(data, type) {
		const path = Urls.addUserDocImage
		var formdata = new FormData();
		for (let i = 0; i < data.length; i++) {
			formdata.append('files', data[i]);
		}

		formdata.append('type', type);

		return API.makePostRequest(path, null, null, formdata, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	deleteDocImage(data) {
		const path = Urls.deleteUserDocImage
		var bodyFormData = new FormData();
		bodyFormData.append('fileurl', data);
		return API.makeDeleateRequest(path, null, null, bodyFormData, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	uploadKYCDocuments(data) {
		const path = Urls.updateKYCDocuments
		var body = data
		return API.makePostRequest(path, null, null, body, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	getAccountDetails() {
		const path = Urls.accountDetails
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	updateAccountDetails(data) {
		const path = Urls.updateAccountDetails
		var body = data

		return API.makePutRequest(path, null, null, body, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	getAddressLists() {
		const path = Urls.addressList
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	addAddress(data) {
		const path = Urls.createAddress
		return API.makePostRequest(path, null, null, data, null)
			.then((res) => {
				return res
			}).catch((err) => {
				return err
			})
	},

	getAddressById(id) {
		const path = Urls.addressById + id
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	updateAddressDetails(body, id) {
		const path = Urls.updateAddres + id
		return API.makePutRequest(path, null, null, body, null)
			.then((res) => {
				return res
			}).catch((err) => {
				return err
			})
	},

	deleteAddress(id) {
		const path = Urls.deleteAddress + id
		return API.makeDeleateRequest(path, null, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},


	addNotificationToken(notificationToken) {
		let deviceId = typeof window !== 'undefined' ? localStorage.getItem('deviceId') : null
		let body = {
			"Platform": "web",
			"Token": notificationToken,
			"DeviceId": deviceId
		}
		const path = Urls.updateNotificationToken
		return API.makePutRequest(path, null, null, body, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	deleteNotificationToken() {
		const device_id = typeof window !== 'undefined' ? localStorage.getItem('deviceId') : null
		const path = `${Urls.deleteNotificationToken}?deviceId=${device_id}`
		return API.makeDeleateRequest(path, null, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	sendNotificationToUser(data) {
		let obj = {
			"Message": "4th message here",
			"Data": {
				"Id": 4,
				"Type": "CHAT_ADDED",
				"NotificationId": "7ce70b65-60e3-4806-a497-839346e90d0b"
			},
			"UserId": 1,
			"Title": "Title yaha hai"
		}
		const path = Urls.sendNotification
		return API.makePostRequest(path, null, null, data, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	getSellerSections(sellerId) {
		const path = Urls.sellerSections + sellerId
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},


	getVendorDetailsById(sellerId) {
		const path = Urls.vendorDetails + sellerId
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	// getUserDetailsById(userId) {
	//     const path = Urls.userById + userId
	//     return API.makeGetRequest(path, null, null, null)
	//         .then((res) => {
	//             return res
	//         }).catch((err) => { return err })
	// },

	getUserDetails() {
		const path = Urls.userDetails
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	addBankDetails(data) {
		const path = Urls.addBankAccountDetails
		return API.makePostRequest(path, data, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => {
				return err
			})
	},

	agreeTermsBySeller() {
		const path = Urls.agreeSellerTermsOfService
		return API.makePutRequest(path, null, null, null, null)
			.then((res) => {
				let Authorization = res.headers.authorization
				if (typeof window !== 'undefined') { localStorage.setItem('Authorization', Authorization) }
				return res
			}).catch((err) => { return err })
	},

	updateUserDetails(body) {
		const path = Urls.updateCurrentUserDetails
		return API.makePutRequest(path, null, null, body, null)
			.then((res) => {
				return res
			}).catch((err) => {
				return err
			})
	},

	getAllBanksList() {
		const path = Urls.bankLists
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	updateAddressDefaultStatus(id) {
		const path = Urls.updateAddressIsDefaultStatus + id + '/isdefault'
		return API.makePutRequest(path, null, null, null, null)
			.then((res) => { return res }).catch((err) => { return err })
	},

	updateBankDefaultStatus(id) {
		const path = Urls.updateBankIsDefaultStatus + id + '/isdefault'
		return API.makePutRequest(path, null, null, null, null)
			.then((res) => { return res }).catch((err) => { return err })
	},

	resendBankAccountValidationAmount(id) {
		let BankID = Number(id);
		const path = Urls.retryBankAccountVerification + BankID;
		return API.makePutRequest(path, null, null, null, null)
			.then((res) => { return res }).catch((err) => { return err })
	},

	updateBankActiveStatus(id, active) {
		const path = Urls.updateBankIsActiveStatus + id + '/active/' + active
		return API.makePutRequest(path, null, null, null, null)
			.then((res) => { return res }).catch((err) => { return err })
	},

	getAllGSTAccounts() {
		const path = Urls.gstAccountList
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	createGSTAccount(data) {
		const path = Urls.createGST
		return API.makePostRequest(path, null, null, data, null)
			.then((res) => {
				return res
			}).catch((err) => {
				return err
			})
	},

	updateMinOrderValue(data) {
		const path = Urls.minOrderValue
		return API.makePutRequest(path, null, null, data, null)
			.then((res) => {
				return res
			}).catch((err) => {
				return err
			})
	},

	deleteGSTAccount(id) {
		const path = Urls.deleteGST + id
		return API.makeDeleateRequest(path, null, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	updateGSTDefaultStatus(id) {
		const path = Urls.makeGSTdefault + id + '/isdefault'
		return API.makePutRequest(path, null, null, null, null)
			.then((res) => { return res }).catch((err) => { return err })
	},

	verifyBank(data, id) {
		const path = Urls.bankVerification + id + '/verify'
		let header = data
		return API.makePutRequest(path, header, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => {
				return err
			})
	},

	getBusinessName() {
		let mob = typeof window !== 'undefined' ? localStorage.getItem('mobile') : null
		mob = mob.substring(3, 13)
		const path = Urls.getBusinessName + '?phoneNumber=' + mob
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	logoutFromAllDevices() {
		const path = Urls.logoutFromAllPlatform
		return API.makeGetRequest(path, null, null, null)
			.then((res) => {
				return res
			}).catch((err) => { return err })
	},

	createUPIAccount(data) {
		const path = Urls.createUPI
		return API.makePostRequest(path, data, null, {}, null)
			.then((res) => {
				return res
			}).catch((err) => {
				return err
			})
	},
};

export default Authentication;