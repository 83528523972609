import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Row, Col, Input, Modal, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import myimage from '../../imageNotFound.jpeg'
import LayoutComponent from '../layoutComponents/layoutComponent';
import BrandService from '../../service/brandService'
import SaveListing from '../../service/saveListingService'
import { BorderOutlined, CheckSquareOutlined } from '@ant-design/icons';
import MediaUrl from '../../service/mediaUrl';

const { Search } = Input;

class UserBrands extends Component {

	constructor(props) {
		super(props);
		this.state = {
			userBrandList: [],
			userSearchBrands: [],
			selectedBrand: {},
			disableDoneBtn: true,
			showDetailsModal: false,
			brandDetails: {
				status: '',
				relationType: '',
				reason: '',
				isAcknowledged: false,
				isDeleted: false,
				name: '',
				description: '',
				isActive: false,
				url: ''
			}
		}
	}

	componentDidMount() {
		if (SaveListing.brand.brand.name) {
			this.setState({ selectedBrand: SaveListing.brand })
			this.setState({ disableDoneBtn: false })
		}
		this.getAllUsersBrands()
	}

	searchBrand = (searchTerm) => {
		if (searchTerm) {
			let { userSearchBrands } = this.state
			let options = userSearchBrands.filter((item) => {
				return item.brand && item.brand.name && item.brand.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
			})
			this.setState({ userBrandList: options })
		} else {
			this.getAllUsersBrands()
		}
	}

	getAllUsersBrands() {
		BrandService.getUsersBrand()
			.then((res) => {
				this.setState({ userBrandList: res.data.data, userSearchBrands: res.data.data }, () => {
				})
			}, err => { })
	}

	navigate = (url) => {
		this.props.history.push({ pathname: url })
	}

	openDetailsModal = (details) => {
		this.setState({
			showDetailsModal: true,
			brandDetails: {
				id: details.id,
				brandId: details.brandId,
				status: details.status,
				relationType: details.relationType,
				reason: details.reason,
				isAcknowledged: details.isAcknowledged,
				isDeleted: details.isDeleted,
				name: details.brand && details.brand.name,
				description: details.brand && details.brand.description,
				isActive: details.brand && details.brand.isActive,
				url: details.brand && details.brand.imageFileUploadedDataId && details.brand.imageFileUploadedData.url || ''
			}
		})
	}

	closeDetailsModal = () => {
		this.setState({
			showDetailsModal: false,
			brandDetails: {
				status: '',
				relationType: '',
				reason: '',
				isAcknowledged: false,
				isDeleted: false,
				name: '',
				description: '',
				isActive: false,
				url: ''
			}
		})
	}

	jsUcfirst = (val) => {
		if (val.length) {
			return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
		}
		return '';
	}

	render() {
		const { userBrandList, selectedBrand, disableDoneBtn, brandDetails } = this.state;
		const { t } = this.props

		return (
			<LayoutComponent title={t('New listing')}>
				<Row className='divWithFixButton'>
					<Col span={24} className='toolbar create-listing-bar '>
						<div>
							<h4 className='mb-0'>{t('Select listing brand')}</h4>
							<p className='label'>
								{t('You can create listing only of your registered brand')}
							</p>
						</div>
					</Col>
					<Col span={24} className='toolbar brand_search_bar'>
						<p className='label mb-1'>
							{t('My brands')}
						</p>

						<Search
							placeholder={t("Search brand")}
							onChange={e => this.searchBrand(e.target.value)}
							style={{ width: "100%", background: 'rgb(237, 237, 237)', borderRadius: '2px' }}
						/>
					</Col>

					<Col span={24} className='brand_list bottom_margin'>
						<Row>
							<Col span={24} >
								{userBrandList.map((item) => {
									return (
										<div
											key={item.id}
											className='brand'
											onClick={(e) => this.openDetailsModal(item)}
										>
											<img src={item.brand.imageFileUploadedData.url ? MediaUrl.completeMediaUrl(item.brand.imageFileUploadedData.url) : myimage} alt='banner' />
											<p className='label'>{item.brand.name}</p>
											<div className='spacer'></div>
											<p style={{ color: '#cacaca' }}>{item.status}</p>
										</div>
									)
								})}
							</Col>

							<Col span={24} className='add_brand' onClick={(e) => this.navigate('/master-brands')}>
								<div className='plus_icon'>
									<div className='plus'>
										<p>+</p>
									</div>
								</div>
								<p className='label'>{t('Add a new brand')}</p>
							</Col>
						</Row>
					</Col>
				</Row>

				<Modal
					visible={this.state.showDetailsModal}
					footer={null}
					centered={true}
					onCancel={this.closeDetailsModal}
				>
					<div style={{ marginTop: 24 }}>

						<div style={{ height: '140px', display: 'flex', justifyContent: 'center', alignitems: 'center' }}>
							<img
								style={{ objectPosition: 'center', objectFit: 'contain', width: '100%', height: '100%' }}
								src={brandDetails.url ? MediaUrl.completeMediaUrl(brandDetails.url) : myimage}
								alt='banner' />
						</div>
						<div style={{ margin: '12px 0px' }}>
							<p style={{ color: '#cacaca', marginBottom: '0px' }}>{t('Brand name')} :</p>
							<p style={{ fontSize: 22, fontWeight: '700' }}>{brandDetails.name}</p>
							<p style={{ color: '#cacaca', marginBottom: '0px' }}>{t('Relation type')} :</p>
							<p style={{ fontWeight: '700' }}>{this.jsUcfirst(brandDetails.relationType)}</p>
							<p style={{ color: '#cacaca', marginBottom: '0px' }}>{t('Status')} :</p>
							<p style={{ fontWeight: '700' }}>{this.jsUcfirst(brandDetails.status)}</p>
							{
								brandDetails.status == 'REJECTED'
									? <>
										<p style={{ color: '#cacaca', marginBottom: '0px' }}>{t('Reason Of rejection')} :</p>
										<p>{brandDetails.reason}</p>
									</>
									: null
							}
							{
								brandDetails.description
									? <>
										<p style={{ color: '#cacaca', marginBottom: '0px' }}>{t('Brand description')} :</p>
										<p style={{ fontSize: 14, fontStyle: 'italic' }}>{brandDetails.description}</p>
									</>
									: null
							}
							{
								brandDetails.status == 'REJECTED'
									? <Button style={{ marginTop: 16 }} className={'buynowBtn'} onClick={() => {
										this.props.history.push(`brand-relationship?brandId=${brandDetails.brandId}&id=${brandDetails.id}&name=${brandDetails.name}`)
									}}>{t('Reapply')}</Button>
									: null
							}

						</div>
					</div>
				</Modal>

			</LayoutComponent>
		)
	}
}

export default withTranslation()(withRouter(UserBrands));