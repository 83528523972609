import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LayoutComponent from "../layoutComponents/layoutComponent";
import { withTranslation } from 'react-i18next';
import { Row, Tabs, Col, Input, Modal, Drawer, message, Button } from "antd";
import {
  FileAddOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  CloseCircleOutlined,
  CheckOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import AddListingService from "../../service/addListingService";
import SaveListing from "../../service/saveListingService";
import BrandService from "../../service/brandService";
import MediaUrl from "../../service/mediaUrl";
import LoaderComponent from "./../loader";


const { TabPane } = Tabs;

class CreateSetsComponent extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      productImages: [],
      drawerVisible: false,
      allSizes: [],
      selectedSizeSet: {},
      selectedModelSet: {},
      selectedModelSetCopy: {},
      selectedModelsList: [],
      visible: false,
      selectedColors: [],
      allModelsSelectedCount: {},
      allModelChildSelectedCount: {},
      colorPalet: [],
      myColors: [],
      disabledColors: false,
      colorsSetCount: [
        { count: "1", selected: false },
        { count: "2", selected: false },
        { count: "3", selected: false },
        { count: "4", selected: false },
        { count: "5", selected: false },
        { count: "6", selected: false },
        { count: "7", selected: false },
      ],
      selectedSize: "",
      selectedSizes: [],
      isPriceMOQSet: false,
      bulkPrice: [{ set: null, price: null, buyerPrice: null }],
      colorsCount: 0,
      selectedColorCount: "",
      isAllImagesSelectedForColor: false,
      colorList: [],
      sku: "",
      minimumSetOrder: "",
      pricePerPiece: "",
      mrpPerPiece: "",
      availableQuantity: "",
      selectedColorSetForImage: {},
      renderCheckIconForBulk: false,
      pricePerPieceModal: false,
      buyerPricePerPieceModal: false,
      pricePerPieceWithoutCommision: "",
      buyerPricePerPieceWithoutCommision: "",
      bulkSetIndex: null,
      priceCommission: {},
      allModels: [],
      allModelsCopy: [],
      buyerPriceCommission: {},
      buyerPricePerPiece: 0,
      loading: false,
      pricePerPieceWithoutDiscount: "",
      isModelsSelected: false,
      searchedQuery: '',
      selectedDeviceCount: 0,
      isLoaderVisible: false
    };
  }

  componentDidMount() {
    window.scroll(0, 0);

    if (!SaveListing.isNewSet && SaveListing.mapProductSets.length) {
      let data = JSON.parse(
        JSON.stringify(
          SaveListing.mapProductSets[SaveListing.selectProductSetIndex]
        )
      );
      // let data = SaveListing.mapProductSets[SaveListing.selectProductSetIndex];
      this.setDataOnPage(data);
    } else {
      if (SaveListing.mapCategoryId !== "") {
        let copiedSet = typeof window !== 'undefined' ? localStorage.getItem("currentCopySet") : null

        if (copiedSet) {
          let data = JSON.parse(copiedSet);
          this.setDataOnPage(data);
        } else {
          this.setState({ renderCheckIconForBulk: false });
          this.setState({ productImages: SaveListing.productImages });
          this.getCategoryAttribute();
        }
      } else {
        window.onbeforeunload = function (evt) {
          var message = "Are you sure you want to leave?";
          if (typeof evt == "undefined") {
            evt = window.event;
          }
          if (evt) {
            evt.returnValue = message;
          }
          return message;
        };
        let productId = typeof window !== 'undefined' ? localStorage.getItem("id") : null
        if (productId) {
          productId = parseInt(productId);
          if (typeof window !== 'undefined') { localStorage.removeItem("id") }
          this.props.history.push({ pathname: `/create-list/${productId}` });
        } else {
          this.props.history.push({ pathname: `/category` });
        }
      }
    }
  }

  setDataOnPage = (data) => {
    let colorsSetCount = this.state.colorsSetCount;
    colorsSetCount.forEach((clr, i) => {
      if (i === data.colorsCount - 1) {
        clr.selected = true;
      } else {
        clr.selected = false;
      }
    });

    let bulkPriceSet = [{ set: null, price: null, buyerPrice: null }];
    if (data.mapProductSetBulkPrices.length) {
      this.setState({ renderCheckIconForBulk: true });
      bulkPriceSet = data.mapProductSetBulkPrices;
    }
    let isModelsSelected = false;
    let selectedModelsCount = Object.keys(data.selectedModelSet).length;
    let selectedModelSetObj = {};
    if (selectedModelsCount === 1) {
      for (let modelId in data.selectedModelSet) {
        selectedModelSetObj = data.selectedModelSet[modelId];
      }
      isModelsSelected = true;
    }
    let allModels = [];
    if (data?.allModels?.length) {

      if (!Object.keys(data.allModels).length) {
        console.log(allModels)
        allModels = JSON.parse(JSON.stringify(data.selectedModals));
      } else {
        allModels = JSON.parse(JSON.stringify(data.allModels));
      }
    }
    let modelMap = {};
    if (allModels.length) {
      allModels.forEach(model => {
        modelMap[model.id] = [];
        if (model.selected) {
          model.parentAttribute.mapAttributes.forEach(modelChild => {
            if (modelChild.selected) {
              modelMap[model.id].push(modelChild.id);
            }
          })
        }
      })
    }
    for (let modelId in modelMap) {
      if (modelMap[modelId].length) {
        isModelsSelected = true;
        break;
      }
    }
    this.setState(
      {
        productImages: SaveListing.productImages,
        allSizes: data.allSizes,
        bulkPrice: bulkPriceSet,
        minimumSetOrder: data.minimumSetOrder,
        sku: data.sku,
        pricePerPiece: data.pricePerPiece,
        mrpPerPiece: data.mrpPerPiece,
        availableQuantity: data.availableQuantity,
        colorPalet: data.colorWithSize,
        selectedSizeSet: data.selectedSizeSet,
        selectedModelSet: selectedModelSetObj,
        selectedModelSetCopy: selectedModelSetObj,
        colorsCount: data.colorsCount,
        selectedColorCount: data.colorsCount,
        colorsSetCount: colorsSetCount,
        allModels: allModels,
        allModelsCopy: allModels,
        allModelsSelectedCount: modelMap,
        isModelsSelected: isModelsSelected
      },
      () => {
        this.checkForPriceAndMOQ();
        this.checkForSelectedImagesForColors();
      }
    );
  };

  checkForSelectedImagesForColors = () => {
    this.setState({ isAllImagesSelectedForColor: true });
    this.state.colorPalet.parentAttribute.mapAttributes.forEach((item) => {
      if (item.images) {
        if (item.selected && item.images.length === 0) {
          this.setState({ isAllImagesSelectedForColor: false });
        }
      }
    });
  };

  getCategoryAttribute = () => {
    AddListingService.getCategoryAtrribute(SaveListing.mapCategoryId).then(
      (res) => {
        let size = [];
        let color = {};
        let models = [];
        res.data.data.forEach((item) => {
          if (item.parentAttribute.type === "SIZE") {
            size.push(item);
          } else if (item.parentAttribute.type === "COLOR") {
            item.parentAttribute.mapAttributes.forEach((clr) => {
              clr["images"] = [];
            });
            color = item;
          } else if (item.parentAttribute.type === 'COMPATIBLE_DEVICES') {
            models.push(item);
          }
        });
        const modelMap = {};
        models.forEach(model => {
          modelMap[model.id] = [];
        })
        this.setState({ allSizes: size, colorPalet: color, allModels: models, allModelsCopy: models, allModelsSelectedCount: modelMap, isLoaderVisible: false });
      },
      (err) => {
        //console.log(err)
      }
    );
  };

  onSelectColorCount = (index) => {
    let colorsCount = this.state.colorsCount;
    let colorsSetCount = this.state.colorsSetCount;
    let selectedColorCount = this.state.selectedColorCount;

    colorsSetCount.forEach((clr, i) => {
      if (i === index) {
        clr.selected = true;
      } else {
        clr.selected = false;
      }
    });

    if (selectedColorCount < index + 1) {
      this.setState({
        disabledColors: false,
        visible: true,
        colorsSetCount: colorsSetCount,
        colorsCount: index + 1,
      });
    } else if (selectedColorCount === index + 1) {
      this.setState({
        disabledColors: true,
        visible: true,
        colorsSetCount: colorsSetCount,
        colorsCount: index + 1,
      });
    } else if (colorsCount < index + 1) {
      this.setState({
        disabledColors: false,
        visible: true,
        colorsSetCount: colorsSetCount,
        colorsCount: index + 1,
      });
    } else {
      this.setState({
        disabledColors: true,
        visible: true,
        colorsSetCount: colorsSetCount,
        colorsCount: index + 1,
      });
    }
  };

  showColorModal = () => {
    this.setState({ visible: true });
  };

  onSelectColor = (element) => {
    let colorPalet = this.state.colorPalet;
    let disabledColors = this.state.disabledColors;
    let colorsCount = this.state.colorsCount;
    let colorCount = 0;
    if (!disabledColors || element.selected) {
      colorPalet.parentAttribute.mapAttributes.forEach((item) => {
        if (element.childAttribute.id === item.childAttribute.id) {
          if (item.selected) {
            item.selected = false;
          } else {
            item["selected"] = true;
          }
        }
        if (item.selected) {
          colorCount = colorCount + 1;
        }
        if (item.images) {
          if (item.selected && item.images.length === 0) {
            this.setState({ isAllImagesSelectedForColor: false });
          }
        }
      });

      if (colorCount !== 0) {
        if (colorCount === colorsCount) {
          this.setState({ disabledColors: true });
        } else {
          this.setState({ disabledColors: false });
        }
      } else {
        this.setState({
          disabledColors: false,
          isAllImagesSelectedForColor: false,
        });
      }
      this.setState({ colorPalet: colorPalet, selectedColorCount: colorCount });
    }
  };

  addNewPriceSet = () => {
    const { t } = this.props
    let bulkPrice = this.state.bulkPrice;
    let minimumSetOrder = this.state.minimumSetOrder;
    let isSetValid = true;
    let isDublicateSet = false;
    this.setState({ renderCheckIconForBulk: false });

    bulkPrice.forEach((item) => {
      if (Number(item.set) <= Number(minimumSetOrder)) {
        isSetValid = false;
        message.error(
          `${t('Bulk')} MOQ (${item.set}) ${t('should be more than original')} MOQ (${minimumSetOrder})`
        );
        return;
      }
      let set = bulkPrice.filter(
        (number) => Number(number.set) === Number(item.set)
      );

      if (set.length > 1) {
        isSetValid = false;
        isDublicateSet = true;
        return;
      }
    });

    if (bulkPrice.length > 1) {
      if (
        Number(bulkPrice[bulkPrice.length - 2].set) >
        Number(bulkPrice[bulkPrice.length - 1].set)
      ) {
        message.error(`${t("New Bulk MOQ must be greater than previous MOQ")}`);
        return;
        // isSetValid = false
      }
    }
    if (isDublicateSet) {
      message.error(`${t('Add unique')} MOQ`);
      return;
    }

    if (isSetValid) {
      bulkPrice.push({ set: null, price: null, buyerPrice: null });
      this.setState(bulkPrice);
    } else {
      message.error(`${t("Invalid set")}`);
    }
  };

  deletePriceSet = (index) => {
    let bulkPrice = this.state.bulkPrice;
    if (bulkPrice.length === 1) {
      bulkPrice[0].price = null;
      bulkPrice[0].buyerPrice = null;
      bulkPrice[0].set = null;
      this.setState({ renderCheckIconForBulk: false });
    } else {
      this.setState({ renderCheckIconForBulk: true });
      bulkPrice.splice(index, 1);
    }
    this.setState(bulkPrice);
  };

  selectSizes = (item, selectedChild) => {
    item.mapAttributes.forEach((element) => {
      if (element.childAttribute.id === selectedChild.childAttribute.id) {
        element["selected"] = true;
      }
    });
    this.setState({ selectedSizeSet: item });
  };

  selectedSizeChild = (selectedChild) => {
    let item = this.state.selectedSizeSet;
    let isAnyItemSelected = false;
    item.mapAttributes.forEach((element) => {
      if (element.childAttribute.id === selectedChild.childAttribute.id) {
        if (element.selected) {
          element.selected = false;
        } else {
          element["selected"] = true;
          isAnyItemSelected = true;
        }
      }
      if (element.selected) {
        isAnyItemSelected = true;
      }
    });

    if (isAnyItemSelected) {
      this.setState({ selectedSizeSet: item });
    } else {
      this.setState({ selectedSizeSet: {} });
    }
  };

  selectSingleModel = (selectedModelSetObj, selectedChild) => {
    selectedModelSetObj.parentAttribute.mapAttributes.forEach((element) => {
      if (element.childAttribute.id === selectedChild.id) {
        element["selected"] = true;
      } else {
        element["selected"] = false;
      }
    });
    this.setState({ selectedModelSet: selectedModelSetObj, selectedModelSetCopy: selectedModelSetObj });
  };

  convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item
      };
    }, initialValue);
  }

  selectMultipleModels = (selectedModel, selectedChild) => {

    let allModels = this.state.allModels;
    let selectedModelId = selectedModel.id;
    let selectedChildId = selectedChild.id;
    let allModelsSelectedCount = this.state.allModelsSelectedCount;
    let selectedModelCount = allModelsSelectedCount[selectedModelId];

    allModels.forEach(model => {
      if (model.id == selectedModelId) {
        if (model["selected"] && selectedModelCount.includes(selectedChildId)) {
          selectedModelCount = selectedModelCount.filter(item => item != selectedChildId);
          if (selectedModelCount.length < 1) {
            model["selected"] = false;
          }
        } else {
          selectedModelCount.push(selectedChildId)
          model["selected"] = true;
        }
        model.parentAttribute.mapAttributes.forEach(modelChild => {
          if (modelChild.id == selectedChildId) {
            if (modelChild["selected"]) {
              modelChild["selected"] = false;
            } else {
              modelChild["selected"] = true;
            }
          }
        })
      }
    })

    allModelsSelectedCount[selectedModelId] = selectedModelCount;
    let isModelSelected = false;
    let count = 0
    for (let selectedModelId in allModelsSelectedCount) {
      if (allModelsSelectedCount[selectedModelId].length) {
        isModelSelected = true;
        break;
      }
    }
    for (let selectedModelId in allModelsSelectedCount) {
      count += allModelsSelectedCount[selectedModelId].length
    }


    this.setState({ allModels: allModels, allModelsCopy: allModels, allModelsSelectedCount: allModelsSelectedCount, isModelsSelected: isModelSelected, selectedDeviceCount: count });
  }

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handlePricingCancel = () => {
    this.setState({
      pricePerPieceModal: false,
      pricePerPieceWithoutCommision: "",
      priceCommission: {},
    });
  };

  handleBuyerPricingCancel = () => {
    this.setState({
      buyerPricePerPieceModal: false,
      buyerPricePerPieceWithoutCommision: "",
      buyerPriceCommission: {},
    });
  };

  checkSetAndNavigate = () => {
    const { t } = this.props
    if (!this.state.colorsCount) {
      message.error(`${t("Please select number of colors")}`);
      return;
    }
    if (this.state.colorsCount !== this.state.selectedColorCount) {
      message.error(`${t('Colors not Selected as per count')}`);
      return;
    }
    if (!this.state.selectedSizeSet?.mapAttributes) {
      // message.error(`${t('Please select a size')}`);
      // return;
    }
    if (!this.state.minimumSetOrder) {
      message.error(`${t('Min sets to order is required')}`);
      return;
    }
    if (!this.state.pricePerPiece) {
      message.error(`${t('Price per piece is required')}`);
      return;
    }
    if (this.state.mrpPerPiece) {
      if (Number(this.state.pricePerPiece) > Number(this.state.mrpPerPiece)) {
        message.error(`${t('Price per piece cannot be greater than MRP per piece')}`);
        return;
      }
    }
    if (this.state.allModels.length) {
      if (SaveListing.isNewSet) {
        let isModelSelected = false;
        let selectedModelsObj = this.state.allModelsSelectedCount;
        for (let model in selectedModelsObj) {
          if (model.length) {
            isModelSelected = true;
            break;
          }
        }
        if (!isModelSelected) {
          message.error(`${t('Please select Compatible Devices')}`);
          return;
        }
      }
      if (!SaveListing.isNewSet) {
        let selectedModelsCount = Object.keys(this.state.selectedModelSet);
        let isModelSelected = false;
        let selectedModelsObj = this.state.allModelsSelectedCount;
        for (let model in selectedModelsObj) {
          if (model.length) {
            isModelSelected = true;
            break;
          }
        }
        if (!selectedModelsCount.length && !isModelSelected) {
          message.error(`${t('Please select Compatible Device')}`);
          return;
        }
      }
    }

    if (Number(this.state.availableQuantity) > 0) {
      if (
        Number(this.state.availableQuantity) <
        Number(this.state.minimumSetOrder)
      ) {
        message.error(`${t('Available quantity must be greater than Min. set to order.')}`);
        return;
      }
    }

    let bulkPrice = this.state.bulkPrice;
    let minimumSetOrder = this.state.minimumSetOrder;

    if (bulkPrice && bulkPrice.length > 0) {
      let isBulksetValid = true;
      let isSetValid = true;
      let isDublicateSet = false;

      bulkPrice.forEach((item, i) => {
        if (item.set || item.price) {
          if (!item.set) {
            isSetValid = false;
            message.error(`${t('Bulk MOQ cannot be empty')}`);
            return;
          }
          if (!item.price) {
            isSetValid = false;
            message.error(`${t('Bulk price per piece cannot be empty')}`);
            return;
          }
          if (item.set && item.price) {
            if (Number(item.set) <= Number(minimumSetOrder)) {
              isSetValid = false;
              message.error(`${t('Bulk')} MOQ (${item.set}) ${t('should be more than original')} MOQ (${minimumSetOrder})`);
              return;
            }
            let set = bulkPrice.filter(
              (number) => Number(number.set) === Number(item.set)
            );
            if (set.length > 1) {
              isSetValid = false;
              isDublicateSet = true;
              return;
            }

            if (bulkPrice.length > 1) {
              if (
                Number(bulkPrice[bulkPrice.length - 2].set) >
                Number(bulkPrice[bulkPrice.length - 1].set)
              ) {
                isBulksetValid = false;
                return;
              }
            }
          } else {
            this.deletePriceSet(i);
          }
        }
      });
      if (isDublicateSet) {
        message.error(`${t('Add unique')} MOQ`);
        return;
      }
      if (!isSetValid) {
        return;
      }
      if (!isBulksetValid) {
        message.error(`${t("New Bulk MOQ must be greater than previous MOQ")}`);
        return;
      }
    }
    let selectSizes = this.state.selectedSizeSet;
    selectSizes && selectSizes.mapAttributes.forEach((item) => {
      if (item.selected) {
        if (!item.quantity) {
          item["quantity"] = 1;
        }
      }
    });


    if (SaveListing.isNewSet) {
      this.state.colorPalet.parentAttribute.mapAttributes.forEach((item) => {
        if (item.selected) {
          item["sizes"] = selectSizes;
        }
      });
    }

    if (!SaveListing.isNewSet) {
      this.state.colorPalet.parentAttribute.mapAttributes.forEach((item) => {
        if (item.selected && !item.sizes) {
          item["sizes"] = selectSizes;
        } else if (item.selected && item.sizes) {
          let newSizes = selectSizes
          item && item.sizes && item.sizes.mapAttributes && item.sizes.mapAttributes.forEach((val, i) => {
            if (val.quantity) {
              newSizes.mapAttributes[i].quantity = val.quantity
            }
          })
          item.sizes = newSizes;
        }
      });
    }
    let isNewSet = SaveListing.isNewSet;
    let selectedModels = [];
    let selectedModelsObj = {};
    let selectedChildModels = [];
    // if(SaveListing.isNewSet){
    if (this.state.allModels.length) {
      this.state.allModels.forEach(model => {
        if (model.selected) {
          selectedModels.push(model);
          model.parentAttribute.mapAttributes.forEach(modelChild => {
            if (modelChild.selected) {
              selectedChildModels.push(modelChild)
            }
          })
        }
      })
      // if (selectedChildModels.length === 0) {
      //   console.log(this.state.selectedModelSet)
      //   this.state.selectedModelSet.parentAttribute.mapAttributes.forEach(modelChild => {
      //     if (modelChild.selected) {
      //       selectedChildModels.push(modelChild)
      //     }
      //   })
      // }
      selectedModelsObj = this.convertArrayToObject(selectedModels, "id")
    } else {
      selectedModels.push(this.state.selectedModelSet)
      selectedModelsObj = this.convertArrayToObject(selectedModels, "id")
    }

    if (!isNewSet && !selectedModels.length) {
      selectedModels.push(this.state.selectedModelSet)
      selectedModelsObj = this.convertArrayToObject(selectedModels, "id")
    }

    if (Object.keys(this.state.selectedModelSet).length && !selectedModels.length) {
      selectedModels.push(this.state.selectedModelSet)
      selectedModelsObj = this.convertArrayToObject(selectedModels, "id")
    }

    let obj = {
      minimumSetOrder: this.state.minimumSetOrder,
      pricePerPiece: this.state.pricePerPiece,
      mrpPerPiece: this.state.mrpPerPiece,
      PricePerPieceBeforeDiscount: this.state.pricePerPieceWithoutDiscount,
      availableQuantity: this.state.availableQuantity,
      sku: this.state.sku,
      mapProductSetBulkPrices: this.state.bulkPrice,
      colorWithSize: this.state.colorPalet,
      allSizes: this.state.allSizes,
      selectedSizeSet: this.state.selectedSizeSet,
      selectedModelSet: selectedModelsObj,
      selectedModels: selectedModels,
      colorsCount: this.state.colorsCount,
      allModels: this.state.allModels || []
    };
    SaveListing.mapSetMultipleSelectableAttributes = selectedChildModels;
    SaveListing.productSetObj = obj;
    this.props.history.push({ pathname: "/set-quantity" });
  };

  setMinSet = (e) => {
    if (e.target.value > 0 || e.target.value === "") {
      this.setState({ minimumSetOrder: e.target.value }, () => {
        this.checkForPriceAndMOQ();
      });
    }
  };

  setPricePerPieceWithoutCommission = (e) => {
    if (e.target.value > 0 || e.target.value === "") {
      this.setState(
        { pricePerPieceWithoutCommision: e.target.value, priceCommission: {} },
        () => {
          this.checkForPriceAndMOQ();
        }
      );
    }
  };

  setBuyerPricePerPieceWithoutCommission = (e) => {
    if (e.target.value > 0 || e.target.value === "") {
      this.setState(
        {
          buyerPricePerPieceWithoutCommision: e.target.value,
          buyerPriceCommission: {},
        },
        () => {
          this.checkForPriceAndMOQ();
        }
      );
    }
  };

  setMRPPerPiece = (e) => {
    if (e.target.value > 0 || e.target.value === "") {
      this.setState({
        mrpPerPiece: e.target.value,
        pricePerPieceWithoutDiscount: e.target.value,
      });
    }
  };

  setPricePerPiece = (e) => {
    if (e.target.value > 0 || e.target.value === "") {
      this.setState({
        pricePerPiece: e.target.value,
        pricePerPieceWithoutDiscount: e.target.value,
      });
    }
  };


  setAvailableSet = (e) => {
    if (e.target.value > 0 || e.target.value === "") {
      this.setState({ availableQuantity: e.target.value });
    }
  };

  setSKUId = (e) => {
    let str = e.target.value;
    str = str.replace(/[^a-zA-Z0-9_-]+/gi, "");
    this.setState({ sku: str });
  };

  setBulkSet = (e, i) => {
    if (e.target.value > 0 || e.target.value === "") {
      let bulkPrice = this.state.bulkPrice;
      if (e.target.value === "") {
        bulkPrice[i].set = null;
        if (bulkPrice[i].price) {
          this.setState({ renderCheckIconForBulk: false });
        } else {
          this.setState({ renderCheckIconForBulk: true });
        }
      } else {
        bulkPrice[i].set = Number(e.target.value);

        if (bulkPrice[i].price) {
          this.setState({ renderCheckIconForBulk: true });
        } else {
          this.setState({ renderCheckIconForBulk: false });
        }
      }
      this.setState(bulkPrice);
    }
  };

  setBulkPrice = (e, i) => {
    if (e.target.value > 0 || e.target.value === "") {
      let bulkPrice = this.state.bulkPrice;
      if (e.target.value === "") {
        bulkPrice[i].price = null;
        if (bulkPrice[i].set) {
          this.setState({ renderCheckIconForBulk: false });
        } else {
          this.setState({ renderCheckIconForBulk: true });
        }
      } else {
        bulkPrice[i].price = Number(e.target.value);

        if (bulkPrice[i].set) {
          this.setState({ renderCheckIconForBulk: true });
        } else {
          this.setState({ renderCheckIconForBulk: false });
        }
      }
      this.setState(bulkPrice);
    }
  };


  setBulkSetPrice = (i) => {
    let { buyerPricePerPieceWithoutCommision } = this.state;

    if (
      buyerPricePerPieceWithoutCommision > 0 ||
      buyerPricePerPieceWithoutCommision === ""
    ) {
      let bulkPrice = this.state.bulkPrice;
      if (buyerPricePerPieceWithoutCommision === "") {
        // here buyerprice and price have been interchanged
        bulkPrice[i].buyerPrice = null;
        if (bulkPrice[i].set) {
          this.setState({ renderCheckIconForBulk: false });
        } else {
          this.setState({ renderCheckIconForBulk: true });
        }
      } else {
        bulkPrice[i].buyerPrice = Number(buyerPricePerPieceWithoutCommision);
        if (bulkPrice[i].set) {
          this.setState({ renderCheckIconForBulk: true });
        } else {
          this.setState({ renderCheckIconForBulk: false });
        }
      }
      this.setState(bulkPrice);
    }
  };

  setBulkSetBuyerPrice = (i) => {
    let { buyerPricePerPiece } = this.state;

    if (buyerPricePerPiece > 0 || buyerPricePerPiece === "") {
      let bulkPrice = this.state.bulkPrice;
      if (buyerPricePerPiece === "") {
        // here buyerprice and price have been interchanged
        bulkPrice[i].price = null;
        if (bulkPrice[i].set) {
          this.setState({ renderCheckIconForBulk: false });
        } else {
          this.setState({ renderCheckIconForBulk: true });
        }
      } else {
        bulkPrice[i].price = Number(buyerPricePerPiece);
        if (bulkPrice[i].set) {
          this.setState({ renderCheckIconForBulk: true });
        } else {
          this.setState({ renderCheckIconForBulk: false });
        }
      }
      this.setState(bulkPrice);
    }
  };

  onSelectImageForColor = (item) => {
    this.setState({ selectedColorSetForImage: item, drawerVisible: true });
  };

  saveNewImageForColors = (image) => {
    let colorPalet = this.state.colorPalet;
    let temp = [...colorPalet];

    temp.parentAttribute.mapAttributes.forEach((item) => {
      if (item.images && item.images.length > 0) {
        item.images.push(image);
      }
    });
    this.setState({ colorPalet: temp });
  };

  onCloseDrawer = () => {
    this.setState({ drawerVisible: false });
  };

  onSelectImage = (image) => {
    let colorPalet = this.state.colorPalet;
    let selectedColorSetForImage = this.state.selectedColorSetForImage;
    this.setState({ isAllImagesSelectedForColor: true });
    colorPalet.parentAttribute.mapAttributes.forEach((item) => {
      if (item.images) {
        if (
          item.childAttributeId === selectedColorSetForImage.childAttributeId
        ) {
          var index = item.images.findIndex((x) => x.url === image.url);
          if (index === -1) {
            item.images.push(image);
          } else {
            item.images.splice(index, 1);
          }
          this.setState({ selectedColorSetForImage: item });
        }
        if (item.selected && item.images.length === 0) {
          this.setState({ isAllImagesSelectedForColor: false });
        }
      }
    });
    this.setState(colorPalet);
  };

  onChange = (e) => {
    const { t } = this.props
    let selectedFile = e.target.files[0];
    const fileExtn = selectedFile.name.substr(
      selectedFile.name.lastIndexOf(".")
    );

    if (
      (fileExtn === ".jpg" || fileExtn === ".jpeg" || fileExtn === ".png") &&
      selectedFile
    ) {
      let blob = selectedFile.slice(0, selectedFile.size, selectedFile.type);
      selectedFile = new File([blob], moment().valueOf() + fileExtn, {
        type: selectedFile.type,
      });
      this.addImageInAWS(selectedFile);
    } else {
      return message.error(`${t('Attach image in png/jpg/jpeg file format')}`);
    }
  };

  checkForPriceAndMOQ = () => {
    let pricePerPiece = this.state.pricePerPiece;
    let minimumSetOrder = this.state.minimumSetOrder;
    let pricePerPieceWithoutCommision = this.state
      .pricePerPieceWithoutCommision;
    if ((pricePerPiece && minimumSetOrder) || pricePerPieceWithoutCommision) {
      this.setState({ isPriceMOQSet: true });
    } else {
      this.setState({ isPriceMOQSet: false });
    }
  };

  addImageInAWS = (data) => {
    BrandService.addImage(data, "PRODUCT_DISPLAY_FILE").then(
      (res) => {
        let productImages = this.state.productImages;
        let data = res.data.data[0];
        SaveListing.addedFileUploadedDatas.push({
          type: "PRODUCT_IMAGE",
          url: data.url,
        });

        let url = MediaUrl.completeMediaUrl(data.url, true, 100, 100);
        data.url = url;
        data["type"] = "ProductImage";
        productImages.push(data);
        SaveListing.productImages = productImages;
        this.setState(productImages);
      },
      (err) => {
        //console.log(err)
      }
    );
  };

  deleteImage = (file, index) => {
    var images = [...this.state.productImages];
    this.deleteImageFromAWS(images[index].url);
    images.splice(index, 1);
  };

  deleteImageFromAWS = (data) => {
    BrandService.deleteImage(data).then(
      (res) => {
        //console.log(res)
      },
      (err) => {
        //console.log(err)
      }
    );
  };

  changeTab = (activeKey) => {
    this.setState({
      activeTab: activeKey,
    });
  };

  calculateCommissionPrice = async () => {
    let { pricePerPieceWithoutCommision } = this.state;
    this.setState({ loading: true });
    let { data: priceResp } = await AddListingService.getProductCommission(
      SaveListing.mapCategoryId,
      pricePerPieceWithoutCommision
    );
    if (priceResp.isSuccess) {
      this.setState({ priceCommission: priceResp.data, loading: false });
    }
  };

  calculateCommissionBuyerPrice = async () => {
    let { buyerPricePerPieceWithoutCommision, bulkSetIndex } = this.state;
    this.setState({ loading: true });
    let { data: priceResp } = await AddListingService.getProductCommission(
      SaveListing.mapCategoryId,
      buyerPricePerPieceWithoutCommision
    );
    if (priceResp.isSuccess) {
      this.setState({ buyerPriceCommission: priceResp.data, loading: false });

      this.setBulkSetBuyerPrice(bulkSetIndex);
    }
  };

  bulkSetInd = (i) => {
    let _i = Number(i);
    let { bulkSetIndex } = this.state;
    this.setState({ bulkSetIndex: _i });
  };

  removeImageFromSet = (paletItem, image) => {
    let colorPalet = this.state.colorPalet;
    colorPalet.parentAttribute.mapAttributes.forEach((item) => {
      if (item.images) {
        if (item.childAttributeId === paletItem.childAttributeId) {
          var index = item.images.findIndex((x) => x.url === image.url);
          if (index || index === 0) {
            item.images.splice(index, 1);
          }
        }
      }
    });
    this.setState(colorPalet);
  };

  setQuery = (e) => {

    let val = e.target.value

    if (this.state.selectedModelSetCopy?.parentAttribute) {
      let option = JSON.parse(JSON.stringify(this.state.selectedModelSetCopy))
      let result = option.parentAttribute.mapAttributes.filter(item => {
        if ((item.childAttribute.value).toLowerCase().includes(val.toLowerCase())) {
          return item
        }
      })
      option.parentAttribute.mapAttributes = result
      this.setState({ selectedModelSet: option })

    } else {
      let devices = JSON.parse(JSON.stringify(this.state.allModels))
      if (val === "") {
        this.setState({ allModelsCopy: devices })
      } else {
        let result = devices.map(elem => elem.parentAttribute.mapAttributes.filter(item => {
          if ((item.childAttribute.value).toLowerCase().includes(val.toLowerCase())) {
            return item
          }
        }))

        devices = devices.map((item, ind) => {
          if (result[ind].length) {
            item.parentAttribute.mapAttributes = result[ind]
            return item
          } else {
            return null
          }
        })
        devices = devices.filter(item => (item != null))
        this.setState({ allModelsCopy: devices })
      }
    }

  }

  render() {
    const {
      colorsSetCount, selectedModelSet, selectedModelSetCopy, bulkPrice, allSizes, colorPalet, selectedSizeSet,
      disabledColors, colorsCount, drawerVisible, productImages, selectedColorSetForImage,
      minimumSetOrder, selectedColorCount, isAllImagesSelectedForColor, isPriceMOQSet,
      activeTab, pricePerPiece, sku, mrpPerPiece, availableQuantity,
      renderCheckIconForBulk, pricePerPieceModal, pricePerPieceWithoutCommision,
      priceCommission, loading, allModels, allModelsCopy, allModelsSelectedCount, bulkSetIndex,
      buyerPriceCommission, buyerPricePerPieceModal, buyerPricePerPieceWithoutCommision, selectedDeviceCount, isLoaderVisible
    } = this.state;

    const { t } = this.props;
    let colorPaletTitle = "";
    if (colorsCount) {
      colorPaletTitle = `${t('Please select any')} ${colorsCount} ${t('Colors to proceed')}`;
    }

    return (
      <LayoutComponent title={t("Create Sets")} >
        {!isLoaderVisible ? <>
          <Row className="sidebar_navigation-items">
            <Tabs
              className="custom_tabs"
              activeKey={activeTab}
              onChange={this.changeTab}
              tabPosition="left"
            >
              <TabPane
                style={{ width: "100%" }}
                tab={
                  <div className="tab_heading">
                    <p>{t('Colors')}*</p>
                    <div className="spacer" />{" "}
                    {colorsCount === selectedColorCount ? (
                      <CheckCircleOutlined className="float_right" />
                    ) : (
                      ""
                    )}{" "}
                  </div>
                }
                key="1"
              >
                <p className="pt-3">{t('How many colors')}</p>

                <Row className="">
                  {colorsSetCount.map((item, i) => {
                    return (
                      <Col
                        onClick={() => this.onSelectColorCount(i)}
                        key={i}
                        xs={7}
                        xl={2}
                      >
                        {item.selected ? (
                          <div className="number_span bgColor">
                            <p>{item.count}</p>
                          </div>
                        ) : (
                          <div className="number_span">
                            <p>{item.count}</p>
                          </div>
                        )}
                      </Col>
                    );
                  })}

                  {selectedColorCount > 0 ? (
                    <Col span={24}>
                      <div className="add_button_container mt-5 mb-3 selected-color-list">
                        <p style={{ fontWeight: "700" }}>
                          {t('Selected colors')}
                          <span>
                            <button
                              onClick={(e) => this.showColorModal()}
                              style={{ fontWeight: "700" }}
                              className="float-right"
                            >
                              {t('Edit')}
                            </button>
                          </span>
                        </p>
                      </div>
                    </Col>
                  ) : null}

                  {colorPalet &&
                    colorPalet.parentAttribute &&
                    colorPalet.parentAttribute.mapAttributes.map((item, i) => {
                      if (item.selected) {
                        return (
                          <Col key={i} span={24} className="mt-2">
                            <div className="flexContainer justify-content-between selected-color-list">
                              <p>{item.childAttribute.key}</p>
                              {item.childAttribute.value === "Multicolor" ? (
                                <div
                                  style={{
                                    height: 32,
                                    width: 32,
                                    backgroundSize: "contain",
                                    backgroundImage:
                                      "url(https://akar-prod-media.s3.ap-south-1.amazonaws.com/StaticAssets/color-circle.png)",
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    height: 32,
                                    width: 32,
                                    backgroundColor: item.childAttribute.value,
                                  }}
                                />
                              )}
                            </div>
                          </Col>
                        );
                      } else {
                        return null;
                      }

                    })}
                </Row>
              </TabPane>

              <TabPane
                style={{ width: "100%" }}
                tab={
                  <div className="tab_heading">
                    {" "}
                    <p>{t('Sizes')}*</p> <div className="spacer" />{" "}
                    {selectedSizeSet && selectedSizeSet.mapAttributes ? (
                      <CheckCircleOutlined className="float_right" />
                    ) : (
                      ""
                    )}{" "}
                  </div>
                }
                key="2"
              >
                <p className="pt-3">
                  {t('Select multiple sizes')}
                </p>

                {selectedSizeSet && selectedSizeSet.mapAttributes ? (
                  <div className="mt-1">
                    <Col span={24}>
                      <p
                        className="label mb-3 mt-2"
                        style={{ fontWeight: "700", fontSize: 14 }}
                      >
                        {t('Selected sizes')}
                      </p>
                    </Col>
                    <Row className="mt-1">
                      {selectedSizeSet.mapAttributes &&
                        selectedSizeSet.mapAttributes.map((element) => {
                          return (
                            <div
                              onClick={() => this.selectedSizeChild(element)}
                              key={`${element.childAttribute.type} ${element.childAttribute.id}`}
                            >
                              {element.selected ? (
                                <div className="number_span bgColor">
                                  <p>{element.childAttribute.value}</p>
                                </div>
                              ) : (
                                <div className="number_span">
                                  <p>{element.childAttribute.value}</p>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </Row>
                  </div>
                ) : (
                  <>
                    {allSizes.map((item, index) => {
                      return (
                        <div key={item.id}>
                          <div className="mt-1">
                            <Col span={24}>
                              <p
                                className="label mb-3 mt-2"
                                style={{ fontWeight: "700", fontSize: 14 }}
                              >
                                {t('Size option')} {index + 1}
                              </p>
                            </Col>
                            <Row className="mt-1">
                              {item.parentAttribute.mapAttributes.map(
                                (element) => {
                                  return (
                                    <div
                                      onClick={() =>
                                        this.selectSizes(
                                          item.parentAttribute,
                                          element
                                        )
                                      }
                                      key={`${element.childAttribute.type} ${element.childAttribute.id}`}
                                    >
                                      <div className="number_span">
                                        <p>{element.childAttribute.value}</p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </TabPane>

              {allModels && allModels.length ?
                <TabPane
                  style={{ width: "100%" }}
                  tab={
                    <div className="tab_heading">
                      {" "}
                      <p>{t('Compatible Device')}*</p> <div className="spacer" />{" "}
                      {this.state.isModelsSelected ? (
                        <CheckCircleOutlined className="float_right" />
                      ) : (
                        ""
                      )}{" "}
                    </div>
                  }
                  key={"10"}
                >
                  <p className="pt-3">
                    {t('Select item')}
                  </p>

                  <Col span={24}>
                    <input placeholder={t('Select item')} className="akr-search-input mb-2" onChange={this.setQuery} />
                  </Col>

                  {selectedModelSet && selectedModelSet.parentAttribute && selectedModelSet.parentAttribute.mapAttributes ? (
                    <div className="mt-1">
                      <Col span={24}>
                        <p
                          className="label mb-3 mt-2"
                          style={{ fontWeight: "700", fontSize: 14 }}
                        >
                          {selectedModelSet.parentAttribute.value}
                        </p>
                      </Col>
                      <Row className="mt-1">
                        {selectedModelSet.parentAttribute.mapAttributes &&
                          selectedModelSet.parentAttribute.mapAttributes.map((element) => {
                            return (
                              <div
                                onClick={() => this.selectSingleModel(selectedModelSet, element.childAttribute)}
                                key={`${element.childAttribute.type} ${element.childAttribute.id}`}
                              >
                                {element.selected ? (
                                  <div className="number_span bgColor">
                                    <p>{element.childAttribute.value}</p>
                                  </div>
                                ) : (
                                  <div className="number_span">
                                    <p>{element.childAttribute.value}</p>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </Row>
                    </div>

                  ) : (
                    < >
                      <div>
                        {selectedDeviceCount ? <> {selectedDeviceCount} {t('Device selected')} </> : ''} </div>
                      {allModels?.map((item, index) => {
                        return (
                          <span key={`selected${item.id}key`} className='mt-2'>
                            {item.parentAttribute.mapAttributes.map(
                              (element) => {
                                return (
                                  <span key={`selected${element.childAttribute.id}key`}>
                                    {element.selected ? (
                                      <span className="selected-pills">
                                        {element.childAttribute.value}
                                        <span className="close-x-btn"

                                          onClick={() =>
                                            this.selectMultipleModels(item, element)}>
                                          X
                                        </span>
                                      </span>
                                    ) : ''}
                                  </span>
                                )
                              })}
                          </span>
                        )
                      })}
                      {
                        allModelsCopy.length ?
                          <>
                            {allModelsCopy?.map((item, index) => {
                              return (
                                <>

                                  <div key={item.id}>
                                    <div className="mt-1">
                                      <Col span={24}>
                                        <p
                                          className="label mb-3 mt-2"
                                          style={{ fontWeight: "700", fontSize: 14 }}
                                        >
                                          {item.parentAttribute.value}
                                        </p>
                                      </Col>
                                      <Row className="mt-1">
                                        {item.parentAttribute.mapAttributes.map(
                                          (element) => {
                                            return (
                                              <>

                                                <div
                                                  onClick={() =>
                                                    this.selectMultipleModels(item, element)
                                                  }
                                                  key={`${element.childAttribute.type} ${element.childAttribute.id}`}
                                                >
                                                  {element.selected ? (
                                                    <div className="number_span bgColor">
                                                      <p>{element.childAttribute.value}</p>
                                                    </div>
                                                  ) : (
                                                    <div className="number_span">
                                                      <p>{element.childAttribute.value}</p>
                                                    </div>
                                                  )}
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </Row>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </> :
                          <div className="text-center pt-2">
                            No data found
                          </div>
                      }
                    </>
                  )}
                </TabPane>
                : null}
              <TabPane
                style={{ width: "100%" }}
                tab={
                  <div className="tab_heading">
                    {" "}
                    <p>{t('Images')}</p> <div className="spacer" />{" "}
                    {isAllImagesSelectedForColor ? (
                      <CheckCircleOutlined className="float_right" />
                    ) : (
                      ""
                    )}{" "}
                  </div>
                }
                key="3"
              >
                {selectedColorCount > 0 ? (
                  <p className="pt-3">{t('Provide image for every color')}</p>
                ) : (
                  <p className="pt-3">{t('Please select color from color tab')}</p>
                )}

                <Row>
                  {colorPalet.parentAttribute &&
                    colorPalet.parentAttribute.mapAttributes.map((item, i) => {
                      return (
                        <div key={i} style={{ width: "100%" }}>
                          {item.selected ? (
                            <Col key={i} span={24}>
                              <div className="image_color">
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* <div className='number'>
                                                                    {i + 1}.
                                                </div> */}
                                    <div
                                      style={{
                                        height: 32,
                                        width: 32,
                                        backgroundColor:
                                          item.childAttribute.value,
                                      }}
                                    />
                                  </div>
                                  <p
                                    style={{
                                      alignSelf: "center",
                                      fontSize: 10,
                                      marginTop: 6,
                                      fontSize: 12,
                                    }}
                                  >
                                    ( {item.childAttribute.key} )
                                  </p>
                                </div>
                                <div className="spacer" />
                                <div className="assets">
                                  {item.images.length > 0
                                    ? item.images.map((img, i) => {
                                      return (
                                        <div key={i} className="file">
                                          <img
                                            src={MediaUrl.completeMediaUrl(
                                              img.url
                                            )}
                                            alt="Something wrong"
                                          />
                                          <div className="container">
                                            <span
                                              className="remove-btn"
                                              onClick={() => {
                                                this.removeImageFromSet(
                                                  item,
                                                  img
                                                );
                                              }}
                                            >
                                              <CloseCircleFilled />
                                            </span>
                                          </div>
                                        </div>
                                      );
                                    })
                                    : null}
                                </div>

                                <div className="image" style={{ overflowX: "hidden" }}>
                                  <button
                                    className="float-right mr-3 file"
                                    onClick={() => {
                                      this.onSelectImageForColor(item);
                                    }}
                                  >
                                    <FileAddOutlined />
                                  </button>
                                </div>
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                </Row>
              </TabPane>

              <TabPane
                style={{ width: "100%" }}
                tab={
                  <div className="tab_heading">
                    {" "}
                    <p>{t('Price')}, MOQ*</p> <div className="spacer" />{" "}
                    {isPriceMOQSet ? (
                      <CheckCircleOutlined className="float_right" />
                    ) : (
                      ""
                    )}{" "}
                  </div>
                }
                key="4"
              >
                <p className="label pt-3">{t('Min set of order')}*</p>
                <Input
                  className="inputMaxWidth"
                  placeholder=""
                  value={minimumSetOrder}
                  onChange={this.setMinSet}
                />

                <p className="label mt-1">{t('Price per piece')}*</p>
                {/* <div style={{ display: "inline-flex" }}>
                  <Input
                    placeholder="1"
                    style={{ width: "60%" }}
                    value={pricePerPiece}
                    disabled={true}
                  />
                  <Button
                    className="enterPriceButton"
                    type={"primary"}
                    style={{ width: "40%" }}
                    onClick={() => {
                      this.setState({ pricePerPieceModal: true });
                    }}
                  >
                    {t('Enter price')}
                  </Button>
                </div> */}
                <Input
                  className="inputMaxWidth"
                  value={pricePerPiece}
                  disabled={false}
                  onChange={this.setPricePerPiece}
                />
                {/* <p className='label mt-1'>Price per piece without discount</p>
							<Input className='inputMaxWidth' placeholder=""
								value={pricePerPieceWithoutDiscount}
								onChange={this.setPricePerPieceWithoutDiscount} /> */}

                <p className="label mt-1">{t('MRP per piece')}</p>
                <Input
                  className="inputMaxWidth"
                  placeholder=""
                  value={mrpPerPiece}
                  onChange={this.setMRPPerPiece}
                />

                <p className="label mt-1">{t('Available quantity')}({t('Sets')})</p>
                <Input
                  className="inputMaxWidth"
                  placeholder=""
                  value={availableQuantity}
                  onChange={this.setAvailableSet}
                />

                <p className="label mt-1">{t('SKU ID')}</p>
                <Input
                  className="inputMaxWidth"
                  placeholder=""
                  value={sku}
                  onChange={this.setSKUId}
                />
              </TabPane>

              <TabPane
                tab={
                  <div className="tab_heading" >
                    <p>{t('Bulk Price')}</p>
                    <div className="spacer" />{" "}
                    {renderCheckIconForBulk ? (
                      <CheckCircleOutlined className="float_right" />
                    ) : (
                      ""
                    )}{" "}
                  </div>
                }
                key="5"
              >
                {minimumSetOrder && pricePerPiece ? (
                  <>
                    <h4 className="pt-3">{t('Regular price')}</h4>
                    <Row className="price_label">
                      <Col sm={11} xs={{ span: 24, pull: 1 }} className="label">
                        <h4>MOQ:</h4>
                        <p>{minimumSetOrder} {t('Sets')}</p>
                      </Col>

                      <Col sm={11} xs={{ span: 24, pull: 1 }} className="label">
                        <h4>{t('Price per piece')} ({t('Including GST')}):</h4>
                        <p> {pricePerPiece}</p>
                      </Col>
                    </Row>

                    <h4 className="mt-3 mb-0" style={{ marginTop: "15px" }}>{t('Bulk Price')}</h4>
                    <Row className="price_input" >
                      <Col sm={11} xs={{ span: 8, pull: 2 }} className="input">
                        <p className="label">MOQ({t('Set')})</p>
                      </Col>
                      {/* <Col span={6} className='input'>
											<p className='label'>
												Price per pc (exluding GST)
                                    </p>
										</Col> */}
                      <Col sm={11} xs={{ span: 16, pull: 2 }} className="input">
                        <p className="label">{t('Price per piece')} ({t('Including GST')})</p>
                      </Col>

                      {bulkPrice.length &&
                        bulkPrice.map((item, i) => {
                          return (
                            <Col sm={{ span: 24, pull: 0 }} xs={{ span: 24, pull: 2 }} key={i} style={{ marginTop: "-10px" }}>
                              <Row>
                                <Col sm={11} xs={8} className="input">
                                  <Input
                                    type="number"
                                    placeholder={t("Sets")}
                                    value={item.set}
                                    onChange={(e) => {
                                      this.setBulkSet(e, i);
                                      this.bulkSetInd(i);
                                    }}
                                  />
                                </Col>

                                {/* <Col span={6} className='input'>
															<Input type='number' placeholder="Rs." value={item.buyerPrice} disabled={true}/>
														</Col> */}
                                <Col
                                  sm={11}
                                  xs={8}
                                  className="input"
                                // style={{
                                //   display: "inline-flex",
                                //   alignItems: "flex-end",
                                // }}
                                >
                                  {/* <Input
                                    className={'enterPriceInput'}
                                    placeholder="1"
                                    style={{ width: "60%" }}
                                    value={item.price}
                                    disabled={true}
                                  /> */}
                                  <Input
                                    className={'enterPriceInput'}
                                    value={item.price}
                                    disabled={false}
                                    onChange={(e) => {
                                      this.setBulkPrice(e, i);
                                      this.bulkSetInd(i);
                                    }}
                                  />

                                  {/* <Button
                                    className={'enterPriceButton'}
                                    type={"primary"}
                                    onClick={(e) => {
                                      this.bulkSetInd(i);
                                      this.setState({
                                        buyerPricePerPieceModal: true,
                                      });
                                    }}
                                  >
                                    {t('Enter price')}
                                  </Button> */}
                                </Col>

                                {bulkPrice[bulkPrice.length - 1].price !== null ||
                                  bulkPrice[bulkPrice.length - 1].set !== null ||
                                  bulkPrice.length > 1 ? (
                                  <Col
                                    span={1}
                                    onClick={() => this.deletePriceSet(i)}
                                    className="input mt-1"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CloseCircleOutlined />
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Col>
                          );
                        })}

                      {/* <p style={{ fontSize: '10px', color: '#cacaca', fontStyle: 'italic', marginTop: '0px' }}>( Note: Bulk Pricing entered by you will include Akar's profit )</p> */}

                      {bulkPrice.length &&
                        bulkPrice[bulkPrice.length - 1].price !== null &&
                        bulkPrice[bulkPrice.length - 1].set !== null ? (
                        <Col span={22}>
                          <div className="add_button_container">
                            <button
                              className="float-right"
                              onClick={() => this.addNewPriceSet()}
                            >
                              {" "}
                              + {t('Add more')}
                            </button>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </>
                ) : (
                  <div className=" text-center">
                    <h4>{t('Set Price Per Piece and Min set of order first')}</h4>
                    <p
                      className="button_Color"
                      onClick={() => this.changeTab("4")}
                    >
                      {" "}
                      {t('Add now')}
                    </p>
                  </div>
                )}
              </TabPane>
            </Tabs>
          </Row>
          <Row>
            <Col span={24} className="stickToBottom">
              <div className="button_div">
                <button onClick={this.checkSetAndNavigate}>{t('Save_Next')}</button>
              </div>
            </Col>
          </Row> </> :
          <div className={"sectionloader"}>
            <LoaderComponent />
          </div>
        }


        <Modal
          title={t("Pricing calculator")}
          visible={buyerPricePerPieceModal}
          onCancel={this.handleBuyerPricingCancel}
          footer={null}
          centered={true}
          maskClosable={false}
        >
          <div>
            <Row>
              <Col span={24}>
                <p className="label mb-2">
                  {t('Enter the amount without gst')}
                </p>
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder={t("Enter price")}
                    value={
                      buyerPricePerPieceWithoutCommision
                    }
                    onChange={
                      this
                        .setBuyerPricePerPieceWithoutCommission
                    }
                  />
                  <Button
                    type={"primary"}
                    style={{
                      visibility: buyerPricePerPieceWithoutCommision
                        ? "visible"
                        : "hidden",
                    }}
                    loading={loading}
                    onClick={() => {
                      this.setBulkSetPrice(bulkSetIndex);
                      this.calculateCommissionBuyerPrice();
                    }}
                  >
                    {t('Calculate')}
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {buyerPricePerPieceWithoutCommision &&
                  buyerPriceCommission.pricePerPieceWithCommission && (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "50px",
                        }}
                      >
                        <div className="mb-2">
                          {t('Price including Akar charges')}: -{" "}
                          <b>
                            Rs{" "}
                            {
                              buyerPriceCommission.pricePerPieceWithCommission
                            }{" "}
                            /-
                          </b>
                        </div>
                        <Button
                          type={"primary"}
                          onClick={() => {
                            this.setState(
                              {
                                buyerPricePerPiece:
                                  buyerPriceCommission.pricePerPieceWithCommission,
                                buyerPricePerPieceModal: false,
                                buyerPricePerPieceWithoutCommision:
                                  "",
                                buyerPriceCommission: {},
                              },
                              () => {
                                this.setBulkSetBuyerPrice(
                                  bulkSetIndex
                                );
                              }
                            );
                          }}
                        >
                          {t('Use this price')}
                        </Button>
                      </div>
                    </>
                  )}
              </Col>
            </Row>
          </div>
        </Modal>


        <Modal
          title={t('Pricing calculator')}
          visible={pricePerPieceModal}
          onCancel={this.handlePricingCancel}
          footer={null}
          centered={true}
          maskClosable={false}
        >
          <div>
            <Row>
              <Col span={24}>
                <p className="label mb-2">
                  {t('Price per piece that includes your profit')}*
                </p>
                <div style={{ display: "inline-flex", width: "100%" }}>
                  <Input
                    placeholder={t("Enter price")}
                    value={pricePerPieceWithoutCommision}
                    onChange={this.setPricePerPieceWithoutCommission}
                  />
                  <Button
                    type={"primary"}
                    style={{
                      visibility: pricePerPieceWithoutCommision
                        ? "visible"
                        : "hidden",
                    }}
                    loading={loading}
                    onClick={() => {
                      this.calculateCommissionPrice();
                    }}
                  >
                    {t('Calculate')}
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {pricePerPieceWithoutCommision &&
                  priceCommission.pricePerPieceWithCommission && (
                    <>
                      <div style={{ textAlign: "center", marginTop: "50px" }}>
                        <div className="mb-2">
                          {t('Price you should use')}: -{" "}
                          <b>
                            Rs {priceCommission.pricePerPieceWithCommission} /-
                          </b>
                        </div>
                        <Button
                          type={"primary"}
                          onClick={() => {
                            this.setState({
                              pricePerPieceModal: false,
                              pricePerPieceWithoutCommision: "",
                              pricePerPiece:
                                priceCommission.pricePerPieceWithCommission,
                              priceCommission: {},
                            });
                          }}
                        >
                          {t('Use this price')}
                        </Button>
                      </div>
                    </>
                  )}
              </Col>
            </Row>
          </div>
        </Modal>

        <Modal
          title={colorPaletTitle}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Row>
            {colorPalet.parentAttribute &&
              colorPalet.parentAttribute.mapAttributes.map((item, attrkey) => {
                return (
                  <Col
                    xs={8}
                    xl={4}
                    key={attrkey}
                    onClick={() => this.onSelectColor(item)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          {item.childAttribute.value === "Multicolor" ? (
                            <div
                              style={{
                                height: 32,
                                width: 32,
                                backgroundSize: "contain",
                                backgroundImage:
                                  "url(https://akar-prod-media.s3.ap-south-1.amazonaws.com/StaticAssets/color-circle.png)",
                                borderRadius: "4px",
                                boxShadow: item.selected
                                  ? "0px 0px 1px 2px rgba(15, 15, 15, 0.1)"
                                  : "",
                                opacity:
                                  !item.selected && disabledColors ? 0.2 : 1,
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                height: 32,
                                width: 32,
                                backgroundColor: item.childAttribute.value,
                                borderRadius: "4px",
                                boxShadow: item.selected
                                  ? "0px 0px 1px 2px rgba(15, 15, 15, 0.1)"
                                  : "",
                                opacity:
                                  !item.selected && disabledColors ? 0.2 : 1,
                              }}
                            />
                          )}

                          {item.selected ? (
                            <CheckCircleFilled
                              style={{
                                position: "absolute",
                                color: "white",
                                opacity: 0.8,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <p
                            style={{
                              alignSelf: "center",
                              fontSize: 10,
                              marginTop: 6,
                              opacity:
                                !item.selected && disabledColors ? 0.2 : 1,
                            }}
                          >
                            {item.childAttribute.key}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Modal>

        <Drawer
          height={270}
          placement="bottom"
          onClose={this.onCloseDrawer}
          visible={drawerVisible}
          title={t("Select images")}
          maskClosable={true}
          key={"select_bottom"}
          className="bottom-drawer1280px"
        >
          <div className="sidebar_navigation-items">
            <div className="assets">
              <input
                id="myInput"
                type="file"
                ref={(ref) => (this.myInput = ref)}
                onChange={(e) => this.onChange(e)}
                accept=".png,.jpg,.jpeg"
                style={{ display: "none" }}
              />

              <button
                className=" mr-3 file"
                onClick={() => {
                  this.myInput.click();
                }}
              >
                <FileAddOutlined />
              </button>

              {productImages.map((item, i) => {
                if (item.type !== "ProductVideo") {
                  return (
                    <div
                      key={i}
                      className="file"
                      onClick={() => this.onSelectImage(item, i)}
                    >
                      {selectedColorSetForImage.images &&
                        selectedColorSetForImage.images.map((img, imgkey) => {
                          return (
                            <div key={imgkey}>
                              {img.url === item.url ? (
                                <div className="imageCheckDiv">
                                  <CheckOutlined
                                    style={{
                                      position: "absolute",
                                      color: "white",
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                      <img src={item.url} alt="Something wrong" />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </Drawer>

      </LayoutComponent >
    );
  }
}

export default withTranslation()(withRouter(CreateSetsComponent));
