import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import LayoutComponent from "../layoutComponents/layoutComponent";
import { Row, Col, message, Modal, Form, Button, Radio, Rate, Popconfirm } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import { RightOutlined } from "@ant-design/icons";
import myImage from "../../imageNotFound.jpeg";
import CartService from "../../service/cart";
import MediaUrl from "../../service/mediaUrl";
import ChatService from "../../service/chatService";
import ShipPacket from "./shipPacket";
import UpdateShipmentPacket from "./updateShipPacket";
import HoldOrder from "./holdOrder";
import Footer from "../footer";
import { OrderStatus } from "../../service/statusTypes";
import "./index.scss";
import TextArea from "antd/lib/input/TextArea";
import { withTranslation } from "react-i18next";
import verifiedImg from "../../verified.png";
import unverifiedImg from "../../unverified.png";
import { Card } from "antd";

// Rating component
import Rating from '@mui/material/Rating';

const cancelReasonsList = [
  "Buyer not reachable",
  "Buyer do not want to order",
  "Stock unavailable",
  "Duplicate order from buyer",
  "Combined with other order",
  "Incomplete information provided",
  "Non serviceable by logistics",
  "Other",
];

const cancelReasonListForBuyer = [
  "Order is arriving late",
  "Product is not required anymore",
  "Other",
];

const orderMessages = [
  { text: "Failed", value: "Your order has been Failed" },
  { text: "Shipped", value: "Your order has been picked and is in-transit" },
  { text: "Cancelled", value: "Your order has been Cancelled" },
  { text: "CourierReturn", value: "Your order has been returned to seller" },
  { text: "Delivered", value: "Your Order has been Delivered" },
  { text: "Expired", value: "Order has been Expired" },
  { text: "Hold", value: "Your Order has been put on Hold" },
  { text: "Placed", value: "Order has been Placed sucessfully" },
  { text: "ReadyToShip", value: "Your Order is ready to shipped" },
  { text: "CancelledByVendor", value: "Order has been cancelled by seller" },
  { text: "Partial", value: "Order is in partial state" },
  {text: "WaitingForApproval", value :"Your Order is Waiting For Approval"},
  {
    text: "Pending",
    value:
      "Please wait while we verify your payment. We will update the status real soon",
  },
  { text: "PickupError", value: "Pickup of order has been rescheduled" },
  { text: "RTOBySeller", value: "Order Returned to origin by seller" },
  { text: "RTOByBuyer", value: "Order Returned to origin by Buyer" },
  { text: "PickedUp", value: "Your order has been Picked up" },
];

const PaymentOptions = {
  COD: "Cash On Delivery",
  PaymentGateway: "Prepaid",
};

class OrderDetailComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      processShipment: false,
      isReadyToShip: false,
      isOnHold: false,
      processHold: false,
      retryOrder: false,
      shipmentData: [],
      isSeller: false,
      isPacketInShipmentState: false,
      updatePacketShipment: false,
      trackingDetails: {},
      otherOrderCancelReason: "",
      selectedCancelRadioBtn: "",
      isBankAccountVerified: false,
      isInvoiceVisible: true,
      warehouse: {},
      orderData: {},
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.getOrderDetails();
    this.getSingleOrderReturn(id);
  }

  getShipmentTrackingData = async () => {
    const { t } = this.props;
    let id = this.props.match.params.id;
    let { data } = await CartService.shipmentTrackingDetails(id);
    if (data.applicationCode === 200) {
      this.setState({ shipmentData: data.data });
    } else {
      message.error(`${t("Error in fetching shipment details")}`);
    }
  };

  getOrderDetails = async () => {
    const { currentUser, t } = this.props;
    let id = this.props.match.params.id;
    let x = await CartService.orderById(id);
    if (x.data.isSuccess) {
      let { data } = x.data;
      data.orderLineItems.forEach((item) => {
        let colors = [];
        let sizes = [];

        item.productOrderLineItem.mapProductSetAttributes.forEach((set) => {
          if (set.mapAttribute.childAttribute.type === "COLOR") {
            colors.push(set.mapAttribute.childAttribute.key);
          } else {
            sizes.push(set.mapAttribute.childAttribute.key);
          }
        });

        let imgUrl =
          item.productOrderLineItem.mapProductFileUploadedDatas[0]
            .fileUploadedData.url;

        if (imgUrl) {
          let url = MediaUrl.completeMediaUrl(imgUrl, true, 100, 100);
          item.productOrderLineItem.mapProductFileUploadedDatas[0].fileUploadedData.url =
            url;
        }

        let clrs = colors.join(", ");
        let sizs = sizes.join(", ");
        item["colors"] = clrs;
        item["sizes"] = sizs;
      });

      let isSeller =
        currentUser.accountId === data.sellerAccountId ? true : false;
      this.setState({ isSeller });

      this.setState({
        isBankAccountVerified:
          this.props.currentUser.account.isBankDetailsExist,
      });

      if (data.orderStatus === "Placed") {
        this.setState({ isOnHold: true, isReadyToShip: true });
      }
      if (data.orderStatus === "Hold") {
        let trackingDetails = await this.getTracking(data.orderId, "Hold");
        if (trackingDetails && trackingDetails.id) {
          this.setState({
            isReadyToShip: true,
            trackingDetails: trackingDetails,
          });
        } else {
          this.setState({ isReadyToShip: true });
        }
      }

      if (data.orderStatus === "Failed") {
        this.setState({ retryOrder: true });
      }

      if (data.orderStatus === "ReadyToShip") {
        this.getWarehouse();
        this.setState({
          isPacketInShipmentState: true,
        });
      }

      if (
        data.orderStatus === "Shipped" ||
        data.orderStatus === "ReadyToShip" ||
        data.orderStatus === "PickupError" ||
        data.orderStatus === "PickedUp"
      ) {
        this.getShipmentTrackingData();
      }

      this.setState({ orderData: data });
    } else {
      if (x.data.applicationCode === 400) {
        return message.error(x.data.message);
      } else {
        message.error(`${t("Error in fetching data")}`);
      }
    }
  };

  redirectToReturnPage = () => {
    let id = this.props.match.params.id;
    this.props.history.push({ pathname: `/return/${id}` });
  };
  openShipmentDrawer = () => {
    this.getOrderDetails()
      .then(() => this.setState({ updatePacketShipment: true }))
      .catch((err) => console.log(err));
  };

  getTracking = async (id, status) => {
    let res = await CartService.getorderTracking(id, status);
    return res;
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancelReason = (reason) => {
    if (reason === "Other") {
      this.cancelOrderRequest(this.state.otherOrderCancelReason);
    } else {
      this.cancelOrderRequest(this.state.selectedCancelRadioBtn);
    }
  };
  cancelOrderRequest = async (reason) => {
    let { currentUser, t } = this.props;
    const { orderData } = this.state;

    let { data: cancelRespp } = await CartService.cancelOrderById(
      orderData.orderId,
      reason
    );
    if (cancelRespp.isSuccess) {
      if (currentUser.id === orderData.buyerAccountId) {
        this.props.history.push({
          pathname: "/your-orders",
          search: `?status=Cancelled`,
        });
        message.success(
          `${t("Order")} ${t("Cancelled")}, ${t(
            "Any amount deducted would be refunded in 48 hours"
          )}`
        );
      } else {
        this.props.history.push({
          pathname: "/your-orders",
          search: `?status=Cancelled`,
        });
      }
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  submitRating = async(itemData)=>{
    let payload = {
      "ProductId":itemData.productOrderLineItem.productId,
      "MapProductSetId":itemData.mapProductSetId,
      "Rating":itemData.productRatingByUser
    }
    let {data} = await CartService.submitProductRating(payload)
    this.getOrderDetails();
    message.success("Rating submitted successfully")
  }
  cancel = (e)=>{
    console.log(e)
  }



  navigateToProductDetailPage = (id) => {
    this.props.history.push({ pathname: "/view-product/" + id });
  };

  navigateToChat = async () => {
    const { orderData } = this.state;
    let { data: response } = await ChatService.createChatThread(
      orderData.orderId,
      orderData.sellerAccountId
    );
    this.props.history.push({
      pathname: "/chat",
      search: `?threadId=${response.data[0].chatThreadGuid}`,
    });
  };

  renderOrderMessage = () => {
    const { orderData } = this.state;
    const { t } = this.props;
    let status = _.find(
      orderMessages,
      (item) => item.text === orderData.orderStatus
    );
    if (
      status &&
      status.text != "Cancelled" &&
      status.text != "CancelledByVendor"
    ) {
      return `${t(status.value)}`;
    } else return null;
  };
  getSingleOrderReturn = async (id) => {
    let response = await CartService.getSingleGetReturnOrder(id);
    this.setState({ orderReturnData: response.data });
  };
  getWarehouse = async () => {
    let id = this.props.match.params.id;
    let response = await CartService.getWarehouseDetails(id);
    this.setState({ warehouse: response.data.data });
    console.log(response.data.data);
  };

  renderOrderStatus = (status) => {
    const { t } = this.props;
    if (status === "CourierReturn") {
      return `${t("Courier Returned")}`;
    } else if (status === "ReadyToShip") {
      return `${t("Ready To Ship")}`;
    } else if (status === "CancelledByVendor") {
      return `${t("Cancelled By Vendor")}`;
    } else if (status === "PickupError") {
      return `${t("Pickup Rescheduled")}`;
    } else if (status === "RTOInitiated") {
      return `${t("RTO Initiated")}`;
    } else if (status === "RTOInTransit") {
      return `${t("RTO In Transit")}`;
    } else if (status === "RTODelivered") {
      return `${t("RTO Delivered")}`;
    } else if (status === "ApprovedWithDiscount") {
      return `${t("Approved With Discount")}`;
    } else if (status === "PickedUp") {
      return `${t("PickedUp")}`;
    } else if(status === "WaitingForApproval"){
      return `${"Waiting For Approval"}`
    }
    else {
      return `${t(OrderStatus[status])}`;
    }
  };

  handleInvoices = () => {
    const { orderData, isSeller } = this.state;
    const { t } = this.props;
    if (
      orderData &&
      orderData.orderInvoices &&
      orderData.orderInvoices.length
    ) {
      return (
        <>
          {isSeller ? (
            <>
              {orderData.orderInvoices[0].sellerInvoiceFileUploadedData?.url ? (
                <div>
                  <a
                    target="_blank"
                    href={MediaUrl.completeMediaUrl(
                      orderData.orderInvoices[0].sellerInvoiceFileUploadedData
                        .url,
                      false
                    )}
                  >
                    <span style={{ fontSize: 14 }} className={"mx-2"}>
                      {t("View seller invoice")}
                      <span className={"rightIcon mx-2"}>
                        <RightOutlined />
                      </span>
                    </span>
                  </a>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              {orderData.orderInvoices[0].buyerShipmentInvoiceFileUploadedData
                ?.url ? (
                <>
                  <div>
                    <a
                      target="_blank"
                      href={MediaUrl.completeMediaUrl(
                        orderData.orderInvoices[0]
                          .buyerShipmentInvoiceFileUploadedData.url,
                        false
                      )}
                    >
                      <span style={{ fontSize: 14 }} className={"mx-2"}>
                        View buyer shipment invoice
                        <span className={"rightIcon mx-2"}>
                          <RightOutlined />
                        </span>
                      </span>
                    </a>
                  </div>
                  <hr />
                </>
              ) : (
                ""
              )}
            </>
          )}

          {orderData.orderInvoices[0].buyerInvoiceFileUploadedData?.url ? (
            <>
              {orderData.orderInvoices[0].sellerInvoiceFileUploadedData?.url ? (
                <hr />
              ) : (
                ""
              )}
              <div>
                <a
                  target="_blank"
                  href={MediaUrl.completeMediaUrl(
                    orderData.orderInvoices[0].buyerInvoiceFileUploadedData.url,
                    false
                  )}
                >
                  <span style={{ fontSize: 14 }} className={"mx-2"}>
                    {t("View buyer invoice")}
                    <span className={"rightIcon mx-2"}>
                      <RightOutlined />
                    </span>
                  </span>
                </a>
              </div>
            </>
          ) : (
            ""
          )}

          {orderData.orderInvoices[0].buyerReturnInvoiceFileUploadedData
            ?.url ? (
            <>
              <hr />
              <div>
                <a
                  target="_blank"
                  href={MediaUrl.completeMediaUrl(
                    orderData.orderInvoices[0]
                      .buyerReturnInvoiceFileUploadedData.url,
                    false
                  )}
                >
                  <span style={{ fontSize: 14 }} className={"mx-2"}>
                    View buyer return invoice
                    <span className={"rightIcon mx-2"}>
                      <RightOutlined />
                    </span>
                  </span>
                </a>
              </div>
            </>
          ) : (
            ""
          )}
          {orderData.orderInvoices[0].buyerReturnShipmentInvoiceFileUploadedData
            ?.url ? (
            <>
              <hr />
              <div>
                <a
                  target="_blank"
                  href={MediaUrl.completeMediaUrl(
                    orderData.orderInvoices[0]
                      .buyerReturnShipmentInvoiceFileUploadedData.url,
                    false
                  )}
                >
                  <span style={{ fontSize: 14 }} className={"mx-2"}>
                    Buyer Return Shipment Invoice
                    <span className={"rightIcon mx-2"}>
                      <RightOutlined />
                    </span>
                  </span>
                </a>
              </div>
            </>
          ) : (
            ""
          )}
          {orderData.orderInvoices[0].buyerFlatDiscountInvoiceFileUploadedData
            ?.url ? (
            <>
              <hr />
              <div>
                <a
                  target="_blank"
                  href={MediaUrl.completeMediaUrl(
                    orderData.orderInvoices[0]
                      .buyerFlatDiscountInvoiceFileUploadedData.url,
                    false
                  )}
                >
                  <span style={{ fontSize: 14 }} className={"mx-2"}>
                    View buyer flat discount invoice
                    <span className={"rightIcon mx-2"}>
                      <RightOutlined />
                    </span>
                  </span>
                </a>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      );
    } else {
      return (
        <a
          onClick={() => {
            alert(`${t("Unable to fetch invoice at the moment")}`);
          }}
        >
          <p style={{ fontSize: 12 }} className={"mx-2"}>
            {t(
              "Invoice will be available once the order fulfilment has been completed"
            )}
            <span className={"rightIcon "}>
              <RightOutlined />
            </span>
          </p>
        </a>
      );
    }
  };

  // handleInvoices = () => {
  // 	const { orderData, isSeller } = this.state;
  // 	const { t } = this.props;
  // 	if (orderData && orderData.orderInvoices && orderData.orderInvoices.length) {
  // 		 if (isSeller) {
  // 		if (orderData && orderData.orderInvoices[0].sellerInvoiceFileUploadedData && orderData.orderInvoices[0].sellerInvoiceFileUploadedData.url) {
  // 			return (<>
  // 				<div>
  // 					<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].sellerInvoiceFileUploadedData.url, false)}>
  // 						<span style={{ fontSize: 14 }} className={'mx-2'}>{t('View seller invoice')} here
  // 							<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 					</a>
  // 				</div>
  // 				{/* {orderData.orderInvoices[0].buyerInvoiceFileUploadedData && orderData.orderInvoices[0].buyerInvoiceFileUploadedData.url && <>
  // 						<hr />
  // 						<div>
  // 							<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerInvoiceFileUploadedData.url, false)}>
  // 								<span style={{ fontSize: 14 }} className={'mx-2'}>{t('View buyer invoice')}
  // 									<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 							</a>
  // 						</div>
  // 					</>} */}
  // 			</>
  // 			)
  // 		}

  // 		//   else {
  // 		if (orderData && orderData.orderInvoices[0].buyerInvoiceFileUploadedData && orderData.orderInvoices[0].buyerInvoiceFileUploadedData.url) {
  // 			return (<>
  // 				<hr />
  // 				<div>
  // 					<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerInvoiceFileUploadedData.url, false)}>
  // 						<span style={{ fontSize: 14 }} className={'mx-2'}>{t('View buyer invoice')}
  // 							<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 					</a>
  // 				</div>
  // 			</>
  // 			)
  // 		} if (orderData && orderData.orderInvoices[0].buyerReturnInvoiceFileUploadedData && orderData.orderInvoices[0].buyerReturnInvoiceFileUploadedData.url) {
  // 			return (
  // 				<>
  // 					<hr />
  // 					<div>
  // 						<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerReturnInvoiceFileUploadedData.url, false)}>
  // 							<span style={{ fontSize: 12 }} className={'mx-2'}>View buyer return invoice
  // 								<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 						</a>
  // 					</div>
  // 				</>
  // 			)
  // 		} if (orderData && orderData.orderInvoices[0].buyerShipmentInvoiceFileUploadedData && orderData.orderInvoices[0].buyerShipmentInvoiceFileUploadedData.url) {
  // 			return (
  // 				<>
  // 					<hr />
  // 					<div>
  // 						<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerShipmentInvoiceFileUploadedData.url, false)}>
  // 							<span style={{ fontSize: 12 }} className={'mx-2'}>View buyer shipment invoice
  // 								<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 						</a>
  // 					</div>
  // 				</>
  // 			)
  // 		} if (orderData && orderData.orderInvoices[0].buyerFlatDiscountInvoiceFileUploadedData && orderData.orderInvoices[0].buyerFlatDiscountInvoiceFileUploadedData.url) {
  // 			return (
  // 				<>
  // 					<hr />
  // 					<div>
  // 						<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerFlatDiscountInvoiceFileUploadedData.url, false)}>
  // 							<span style={{ fontSize: 12 }} className={'mx-2'}>View buyer flat discount invoice
  // 								<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 						</a>
  // 					</div>
  // 				</>
  // 			)
  // 		} else {
  // 			this.setState({ isInvoiceVisible: false })
  // 			return null;
  // 		}
  // 		// }
  // 	}
  // 	 else {
  // 		if (orderData && orderData.orderInvoices[0].buyerInvoiceFileUploadedData && orderData.orderInvoices[0].buyerInvoiceFileUploadedData.url) {
  // 			return (
  // 				<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerInvoiceFileUploadedData.url, false)}>
  // 					<span style={{ fontSize: 12 }} className={'mx-2'}>{t('View Invoice')}
  // 						<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 				</a>
  // 			)
  // 		} if (orderData && orderData.orderInvoices[0].buyerReturnInvoiceFileUploadedData && orderData.orderInvoices[0].buyerReturnInvoiceFileUploadedData.url) {
  // 			return (
  // 				<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerReturnInvoiceFileUploadedData.url, false)}>
  // 					<span style={{ fontSize: 12 }} className={'mx-2'}>View return invoice
  // 						<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 				</a>
  // 			)
  // 		} if (orderData && orderData.orderInvoices[0].buyerShipmentInvoiceFileUploadedData && orderData.orderInvoices[0].buyerShipmentInvoiceFileUploadedData.url) {
  // 			return (
  // 				<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerShipmentInvoiceFileUploadedData.url, false)}>
  // 					<span style={{ fontSize: 12 }} className={'mx-2'}>View shipment invoice
  // 						<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 				</a>
  // 			)
  // 		} if (orderData && orderData.orderInvoices[0].buyerFlatDiscountInvoiceFileUploadedData && orderData.orderInvoices[0].buyerFlatDiscountInvoiceFileUploadedData.url) {
  // 			return (
  // 				<a target="_blank" href={MediaUrl.completeMediaUrl(orderData.orderInvoices[0].buyerFlatDiscountInvoiceFileUploadedData.url, false)}>
  // 					<span style={{ fontSize: 12 }} className={'mx-2'}>View flat discount invoice
  // 						<span className={'rightIcon mx-2'}><RightOutlined /></span></span>
  // 				</a>
  // 			)
  // 		} else {
  // 			this.setState({ isInvoiceVisible: false })
  // 			return null;
  // 		}
  // 	}
  // 	}
  // 	else {
  // 		return (
  // 			<a onClick={() => { alert(`${t('Unable to fetch invoice at the moment')}`) }}>
  // 				<p style={{ fontSize: 12 }} className={'mx-2'}>
  // 					{t("Invoice will be available once the order fulfilment has been completed")}
  // 					<span className={'rightIcon '}><RightOutlined /></span>
  // 				</p>
  // 			</a>

  // 		)
  // 	}
  // }

  navigate = (url) => {
    this.props.history.push({ pathname: url });
  };

  render() {
    const { t, currentUser } = this.props;

    const {
      isInvoiceVisible,
      visible,
      orderData,
      processShipment,
      isReadyToShip,
      updatePacketShipment,
      isBankAccountVerified,
      isOnHold,
      processHold,
      selectedCancelRadioBtn,
      shipmentData,
      isSeller,
      isPacketInShipmentState,
      trackingDetails,
      orderReturnData,
      warehouse,
    } = this.state;
    return (
      <>
        <LayoutComponent title={t("Order Details")}>
          <div className={"pt-4"}>
            <Row>
              <Col xl={10} xs={24}>
                <div className={"order_div"}>
                  {/* {(orderData?.verificationStatus === 0) ?
									<>{orderCard}</> :
									<Badge.Ribbon color={orderData?.verificationStatus === 1 ? 'green' : 'red'} text={orderData?.verificationStatus === 1 ? 'Verified by Akar' : orderData?.verificationStatus === 2 ? 'Rejected by Akar' : null}>
										{orderCard}
									</Badge.Ribbon>
								} */}
                  {/* {orderCard} */}
                  <div className={"order_card"}>
                    <p className={"packed"}>
                      <b>
                        {t("Order")}{" "}
                        {orderData && orderData.orderStatus
                          ? this.renderOrderStatus(orderData.orderStatus)
                          : null}{" "}
                      </b>
                    </p>

                    {isSeller &&
                      orderData &&
                      orderData.orderStatus === "Cancelled" ? (
                      <p style={{ color: "white" }}>
                        {t("Order has been cancelled")}{" "}
                        {orderData.cancelledBy === 0
                          ? "by Buyer"
                          : orderData.cancelledBy === 1
                            ? "by Seller"
                            : orderData.cancelledBy === 2
                              ? "by Akar"
                              : ""}{" "}
                      </p>
                    ) : null}
                    {isSeller &&
                      orderData &&
                      orderData.orderStatus === "CancelledByVendor" ? (
                      <p style={{ color: "white" }}>
                        {t("Order has been cancelled by You")}
                      </p>
                    ) : null}
                    {!isSeller &&
                      orderData &&
                      orderData.orderStatus === "CancelledByVendor" ? (
                      <p style={{ color: "white" }}>
                        {t("Order has been cancelled by Seller")}
                      </p>
                    ) : null}
                    {!isSeller &&
                      orderData &&
                      orderData.orderStatus === "Cancelled" ? (
                      <p style={{ color: "white" }}>
                        {t("Order has been cancelled by You")}
                      </p>
                    ) : null}

                    <p className={"company_name"}>
                      {orderData && orderData.orderStatus === "Hold" ? (
                        trackingDetails && trackingDetails.id ? (
                          isSeller ? (
                            <p>
                              {t("You put the order on hold for")}{" "}
                              {trackingDetails.numberOfDays} {t("days due to")}{" "}
                              '{trackingDetails.comment}' {t("reason")}
                            </p>
                          ) : (
                            <p>
                              {t("Your order is on hold for")}{" "}
                              {trackingDetails.numberOfDays} {t("days due to")}{" "}
                              '{trackingDetails.comment}' {t("reason")}
                            </p>
                          )
                        ) : (
                          this.renderOrderMessage()
                        )
                      ) : (
                        orderData && this.renderOrderMessage()
                      )}
                    </p>

                    {(orderData && orderData.orderStatus === "Cancelled") ||
                      (orderData &&
                        orderData.orderStatus === "CancelledByVendor") ? (
                      <p className={"company_name"}>
                        {t("Reason")}: {t(orderData.reason)}
                      </p>
                    ) : null}

                    {orderData &&
                      orderData.orderStatus === "Pending" &&
                      orderData.paymentType === "PaymentGateway" &&
                      !isSeller ? (
                      <p className={"company_name"}>
                        {t(
                          "Please wait while we verify your payment. We will update the status real soon"
                        )}
                      </p>
                    ) : null}

                    {shipmentData && shipmentData.length > 0 ? (
                      <>
                        <p className={"company_name"}>
                          {t("AWB Number")}:{" "}
                          <span className={"packed"}>
                            {shipmentData[0].awbNumber ||
                              `${t("Not Generated")}`}
                          </span>
                        </p>
                        <p className={"company_name"}>
                          {t("Delivery By")}:{" "}
                          <span className={"packed"}>
                            {moment(
                              shipmentData[0].estimatedDeliveryDate
                            ).format("ll")}
                          </span>
                        </p>
                      </>
                    ) : (
                      <p className={"company_name"}>
                        {t("Placed On")}:{" "}
                        <span className={"packed"}>
                          {orderData
                            ? moment
                              .utc(orderData.createdOn)
                              .utcOffset("+0530")
                              .format("ll")
                            : ""}
                        </span>
                      </p>
                    )}
                    {orderData?.verificationStatus !== 0 ? (
                      <img
                        className="verification-badge"
                        alt="t"
                        src={
                          orderData?.verificationStatus === 1
                            ? verifiedImg
                            : unverifiedImg
                        }
                      ></img>
                    ) : null}
                  </div>

                  {!isSeller ? (
                    <>
                      <div className={"trans_card"}>
                        <Card style={{ backgroundColor: "#132743" }}>
                          <p className={"meta"}>Transactions</p>

                          {orderData &&
                            orderData.transactions &&
                            orderData.transactions.map((item, index) => {
                              let paymentType = "COD";
                              let paymentStatus = "Pending";
                              if (
                                item.paymentType === 1 ||
                                item.paymentType === 2
                              ) {
                                paymentType = "Prepaid";
                              } else if (item.paymentType === 3) {
                                paymentType = "Payout";
                              }

                              if (item.paymentStatus === 1) {
                                paymentStatus = "Success";
                              } else if (item.paymentStatus === 2) {
                                paymentStatus = "Failed";
                              } else if (item.paymentStatus === 4) {
                                paymentStatus = "Cancelled";
                              } else if (item.paymentStatus === 5) {
                                paymentStatus = "Refund";
                              }
                              return (
                                <p key={index} className={"trans_para"}>
                                  {paymentType}
                                  {`(${paymentStatus})`} {`₹ ${item.price}`}
                                </p>
                              );
                            })}
                        </Card>
                      </div>

                      <hr />
                    </>

                  ) : null}


                  {orderData &&
                    orderData.orderStatus === "WaitingForApproval" &&
                    orderData.paymentLinks
                    ? orderData.paymentLinks.map((item, Ikey) => {
                      return (
                        <>
                          <p className={"title mt-4"}>{t("Payment Links")}</p>
                          <div
                            style={{backgroundColor:"transparent",display:"flex",justifyContent:"space-between",border:"1px solid transparent",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",alignItems:"center",padding:"1%"
                            }}>

                                <p style={{marginTop:"1%"}}>
                              <b>

                                Payable Amount : ₹ {item.amount}
                              </b>
                            </p>
                            <a target={'_blank'} href={`${item.url}`}>
                              <Button type="primary">{t("Pay Now")}</Button>
                            </a>
                          </div>
                            <hr/>
                        </>
                      );
                    })
                    : null}

                  {orderData &&
                    (orderData.orderStatus === "Shipped" ||
                      orderData.orderStatus === "ReadyToShip") ? (
                    <div
                      className={"viewDetail pointer_Cursor"}
                      onClick={() => {
                        if (
                          shipmentData[0].shipRocketShipmentStatus ===
                          "Tracking Not Started Yet"
                        ) {
                          window.alert(`${t("Tracking Not Started Yet")}`);
                        } else {
                          window.open(shipmentData[0].shipRocketTrackUrl);
                          // this.props.history.push({ pathname: '/shipment-tracking' })
                        }
                      }}
                    >
                      {t("View Tracking Details")}
                    </div>
                  ) : null}

                  {orderData &&
                    orderData.orderStatus === "Delivered" &&
                    orderData.isReturnable &&
                    !isSeller &&
                    !orderReturnData.data[0] ? (
                    <div className="return-div">
                      <h6 className={"mt-4 pt-3 pl-3"}>
                        Want to Return Items?
                      </h6>
                      <p className={"pl-3"}>
                        You can create a return for this order within 36 hrs of
                        delivery
                      </p>
                      <a
                        className={"ml-3 return-policy"}
                        href="#/terms-and-conditions"
                        target={"_blank"}
                      >
                        View Return Policy
                      </a>
                      <Button
                        block
                        className={"mt-4 mb-3"}
                        onClick={this.redirectToReturnPage}
                      >
                        Return Items
                      </Button>
                    </div>
                  ) : null}

                  <div>
                    {isInvoiceVisible &&
                      orderData &&
                      orderData.orderStatus != "Cancelled" &&
                      orderData.orderStatus != "CancelledByVendor" &&
                      orderData.orderStatus != "Pending" &&
                      orderData.orderStatus != "Failed" &&
                      orderData.orderStatus != "PickupError" &&
                      orderData.orderStatus != "Hold" &&
                      orderData.orderStatus != "Expired" ? (
                      <>
                        <p className={"title mt-4"}>{t("Invoice Details")}</p>
                        <hr />
                        {this.handleInvoices()}
                        <hr />
                      </>
                    ) : null}

                    <p className={"title mt-4"}>{t("SUPPORT")}</p>
                    <hr />

                    {orderData &&
                      (orderData.orderStatus === "Placed" ||
                        orderData.orderStatus === "Hold" ||
                        orderData.orderStatus === "ReadyToShip") ? (
                      <>
                        <div
                          className={"order_text px-2 pointer_Cursor"}
                          onClick={() => {
                            this.showModal();
                          }}
                        >
                          {t("Cancel Order")}
                        </div>
                        <hr />
                      </>
                    ) : null}

                    {orderData &&
                      (orderData.orderStatus === "Shipped" ||
                        orderData.orderStatus === "PickupError" ||
                        orderData.orderStatus === "PickedUp" ||
                        orderData.orderStatus === "ReadyToShip") &&
                      orderData.buyerAccountId !== currentUser.accountId ? (
                      <>
                        <div
                          className={"order_text px-2 pointer_Cursor"}
                          onClick={() => {
                            if (!shipmentData[0].labelUrl) {
                              return alert(
                                `${t(
                                  "Shipping not started yet. Please try again after 30 minutes"
                                )}`
                              );
                            }
                            return window.open(shipmentData[0].labelUrl);
                          }}
                        >
                          {t("Download Shipping Label")}
                        </div>
                        <hr />
                      </>
                    ) : null}

                    {orderData &&
                      (orderData.orderStatus === "Shipped" ||
                        orderData.orderStatus === "ReadyToShip" ||
                        orderData.orderStatus === "PickedUp") &&
                      orderData.buyerAccountId !== currentUser.accountId ? (
                      <>
                        <div
                          className={"order_text px-2 pointer_Cursor"}
                          onClick={() => {
                            if (!shipmentData[0].manifestUrl) {
                              return alert(
                                `${t("Unable to fetch manifest at the moment")}`
                              );
                            }
                            return window.open(shipmentData[0].manifestUrl);
                          }}
                        >
                          {t("Download Manifest url")}
                        </div>
                        <hr />
                      </>
                    ) : null}

                    {/* <div className={'order_text px-2'}>
										<a href="tel:+212522246981" ><span >Call Customer Support</span>
											<span className={'rightIcon'}><RightOutlined /></span></a>
									</div>

									<hr /> */}

                    {orderData &&
                      (orderData.orderStatus === "Pending" ||
                        orderData.orderStatus === "Failed" ||
                        orderData.orderStatus === "Partial" ||
                        orderData.orderStatus === "Cancelled") ? null : (
                      <div
                        className={"order_text px-2 pointer_Cursor"}
                        onClick={() => {
                          this.navigateToChat();
                        }}
                      >
                        {isSeller
                          ? `${t("Chat with Customer")}`
                          : `${t("Chat with Vendor")}`}
                        <span className={"rightIcon"}>
                          <RightOutlined />
                        </span>
                      </div>
                    )}
                  </div>

                  {isSeller && isBankAccountVerified && isReadyToShip ? (
                    <div>
                      <Button
                        style={{ width: "100%", marginTop: "18px" }}
                        type="primary"
                        onClick={() => {
                          this.setState({ processShipment: true });
                        }}
                      >
                        {t("Ship Package")}
                      </Button>
                    </div>
                  ) : isSeller && !isBankAccountVerified && isReadyToShip ? (
                    <Button
                      style={{ width: "100%", marginTop: "18px" }}
                      type="primary"
                      onClick={() => {
                        message.info(
                          `${t(
                            "Please Verify Bank details first from account settings in settings menu"
                          )}`
                        );
                      }}
                    >
                      {t("Ship Package")}
                    </Button>
                  ) : null}

                  {isSeller && isOnHold ? (
                    <div>
                      <Button
                        style={{ width: "100%", marginTop: "10px" }}
                        type="primary"
                        onClick={() => {
                          this.setState({ processHold: true });
                        }}
                      >
                        {t("Hold Order")}
                      </Button>
                    </div>
                  ) : null}

                  {isSeller && isPacketInShipmentState && !warehouse?.id ? (
                    <Button
                      style={{ width: "100%", marginTop: "10px" }}
                      type="primary"
                      onClick={() => this.openShipmentDrawer()}
                    >
                      {t("Update Shipment")}
                    </Button>
                  ) : null}

                  {/* {!isSeller && retryOrder ? (
                    <div>
                      <Button
                        style={{ width: "100%", marginTop: "10px" }}
                        type="primary"
                        onClick={() => {
                          this.props.history.push({
                            pathname: `/order/payment`,
                            search: `?orderCustomId=${orderData.orderId}`,
                          });
                        }}
                      >
                        {t("Retry")}
                      </Button>
                    </div>
                  ) : null} */}

                  {orderData?.orderReturns?.map((rItem) => {
                    return (
                      <>
                        <div
                          key={rItem.orderReturnId}
                          className={"order_card"}
                          style={{
                            backgroundColor: "#fff",
                            marginTop: "10px",
                            boxShadow: "0 0 3px #d9dbdd",
                          }}
                        >
                          <p className={"packed"} style={{ color: "#f26522" }}>
                            <b> Returned </b>
                          </p>
                          {rItem.status ? (
                            <p
                              style={{ color: "#132743", marginBottom: "2px" }}
                            >
                              {" "}
                              Status: {t("Order")}{" "}
                              {this.renderOrderStatus(rItem.status)}
                            </p>
                          ) : null}
                          <p style={{ color: "#132743" }}>
                            Reason: {rItem.reason}
                          </p>
                          <p
                            style={{ color: "#132743" }}
                            className={"company_name"}
                          >
                            Return ID:{" "}
                            <span
                              className={"packed"}
                              style={{ color: "#f26522" }}
                            >
                              {rItem.orderReturnId}
                            </span>
                          </p>
                        </div>
                        <div
                          className={"viewDetail pointer_Cursor"}
                          style={{ boxShadow: "0 0 3px #d9dbdd" }}
                          onClick={() =>
                            this.navigate(
                              `/return-details/${rItem.orderReturnId}`
                            )
                          }
                        >
                          View Return Details
                        </div>
                      </>
                    );
                  })}

                  {orderData?.disputes?.map((dItem) => {
                    return (
                      <>
                        {dItem ? (
                          <>
                            <div
                              key={dItem.disputeCustomId}
                              className={"order_card"}
                              style={{
                                backgroundColor: "#fff",
                                marginTop: "10px",
                                boxShadow: "0 0 3px #d9dbdd",
                              }}
                            >
                              <p
                                className={"packed"}
                                style={{ color: "#f26522" }}
                              >
                                <b> Dispute </b>
                              </p>
                              <p
                                style={{
                                  color: "#132743",
                                  marginBottom: "2px",
                                }}
                              >
                                {" "}
                                Status: {t("Order")} {dItem.status}
                              </p>
                              <p style={{ color: "#132743" }}>
                                Reason: {dItem.disputeReason}
                              </p>
                              <p
                                style={{ color: "#132743" }}
                                className={"company_name"}
                              >
                                Dispute ID:{" "}
                                <span
                                  className={"packed"}
                                  style={{ color: "#f26522" }}
                                >
                                  {dItem.disputeCustomId}
                                </span>
                              </p>
                            </div>
                            <div
                              className={"viewDetail pointer_Cursor"}
                              style={{ boxShadow: "0 0 3px #d9dbdd" }}
                              onClick={() =>
                                this.props.history.push({
                                  pathname: "/rto-dispute/dispute",
                                  search: `?disputeCustomId=${dItem.disputeCustomId}`,
                                })
                              }
                            >
                              View Dispute Details
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div>
              </Col>
              <Col xl={14} xs={24}>
                <div className={"order_div"}>
                  <p className={"title"}>{t("Ordered On")}</p>
                  <p className={"order_text"}>
                    {orderData
                      ? moment
                        .utc(orderData.createdOn)
                        .utcOffset("+0530")
                        .format("lll")
                      : ""}
                  </p>
                  <hr />

                  <p className={"title"}>{t("ORDER ID")}</p>
                  <p className={"order_text"}>
                    {orderData ? orderData.orderId : null}
                  </p>
                  <hr />

                  <p className={"title"}>
                    {t("Order Amount (excluding - GST)")}
                  </p>
                  <p className={"order_text"}>
                    ₹ {orderData ? orderData.amountWithoutGst : 0}
                  </p>
                  <hr />
                  {isSeller ? null : (
                    <>
                      <p className={"title"}>{t("Shipping Charges")}</p>
                      <p className={"order_text"}>
                        ₹ {orderData ? orderData.shippingCharges : 0}
                      </p>
                      <hr />
                    </>
                  )}

                  <p className={"title"}>{t("Total GST")}</p>
                  <p className={"order_text"}>
                    ₹ {orderData ? orderData.totalGst : ""}
                  </p>
                  <hr />

                  {isSeller ? (
                    <>
                      <p className={"title"}>{t("Total Amount")}</p>
                      <p className={"order_text"}>
                        ₹{" "}
                        {orderData &&
                          (
                            orderData.totalAmount - orderData.shippingCharges
                          ).toFixed(2)}
                      </p>
                      <hr />
                    </>
                  ) : (
                    <>
                      <p className={"title"}>{t("Total Amount")}</p>
                      <p className={"order_text"}>
                        ₹ {orderData && orderData.totalAmount}
                      </p>
                      <hr />
                    </>
                  )}

                  {orderData && orderData.orderStatus === "Failed" ? null : (
                    <>
                      <p className={"title"}>{t("Payment Method")}</p>
                      <p className={"order_text"}>
                        {orderData &&
                          `${t(PaymentOptions[orderData.paymentType])}`}
                      </p>
                      <hr />
                    </>
                  )}
                  {/* <p className={'title'}>INVOICE DETAILS</p> */}
                  {/* {this.handleInvoices()} */}
                  {/* <hr /> */}
                  <p className={"title"}>{t("Buyer GST Number")}</p>
                  <p className={"order_text"}>
                    {orderData && orderData.buyerGstNumber
                      ? orderData.buyerGstNumber
                      : `${t("Not Available")}`}
                  </p>
                  <hr />
                  <p className={"title"}>{t("Seller GST Number")}</p>
                  <p className={"order_text"}>
                    {orderData && orderData.sellerGstNumber
                      ? orderData.sellerGstNumber
                      : `${t("Not Available")}`}
                  </p>

                  <hr />
                  <p className={"title"}>{t("Delivery Address")}</p>
                  <p className={"order_text"}>
                    {orderData && orderData.deliveryOrderAddress
                      ? `${orderData.deliveryOrderAddress.addressLine1} ${orderData.deliveryOrderAddress.addressLine2}, ${orderData.deliveryOrderAddress.zipCode}`
                      : ""}
                  </p>
                  
                </div>
                <div className={"order_div "}>
                  <p className={"title"}>{t("Order Items")}</p>
                  {orderData &&
                    orderData.orderLineItems &&
                    orderData.orderLineItems.length > 0 &&
                    orderData.orderLineItems.map((item, val) => {
                      return (
                        <div
                          className={
                            "order_itemdiv pointer_Cursor"
                          }
                          key={val}

                        >
                          <div style={{ display: "flex", justifyContent: "space-between" }} onClick={(e) => {
                            e.preventDefault()
                            this.navigateToProductDetailPage(
                              item.productOrderLineItem.productId
                            )
                          }
                          }>
                            <div className={"flexRightDiv"}>
                              <p className={"item_name font14px mb-0"}>
                                {item.productOrderLineItem.name}
                              </p>
                              {item.specialNote ? (
                                <p className={"item_name font14px mb-0"}>
                                  <b>{t("Special Note")} :</b>{" "}
                                  {item.specialNote}
                                </p>
                              ) : null}
                              <p className="item_name font12px mt-0 light_font_Color">
                                {t("Size")}: {item.sizes} | {t("Colors")}:{" "}
                                {item.colors} | {t("Total Pieces")}:{" "}
                                {item.productOrderLineItem.piecePerSet *
                                  item.vendorQuantity}{" "}
                              </p>
                              <p className={"item_name"}>
                                <span className={"item_qty "}>
                                  {item.vendorQuantity}
                                </span>
                                <span className="font12px light_font_Color ml-4 pl-1">
                                  {" "}
                                  x ₹ {item.vendorPricePerSet} ({t("includes")}{" "}
                                  {item.productOrderLineItem.gstPercentage}%{" "}
                                  {t("GST")}){" "}
                                </span>
                              </p>
                            </div>
                            <div className={"my_order_image"}>
                              <img
                                src={
                                  item &&
                                    item.productOrderLineItem &&
                                    item.productOrderLineItem
                                      .mapProductFileUploadedDatas[0] &&
                                    item.productOrderLineItem
                                      .mapProductFileUploadedDatas[0]
                                      .fileUploadedData
                                    ? item.productOrderLineItem
                                      .mapProductFileUploadedDatas[0]
                                      .fileUploadedData.url
                                    : myImage
                                }
                                alt={"productImg"}
                              />
                            </div>
                          </div>
                          {!isSeller && orderData && orderData.orderStatus === 'Delivered' ?
                            <>
                              <p className={"title"}>{t("Rate Now")}</p>
                              <div style={{ display: "flex", justifyContent: "space-between" }} className={"order_text"}>
                                <Rating precision={0.5} disabled={item.isProductRatedByUser}
                                  onChange={(event,rateValue) => {
                                    let data = this.state.orderData.orderLineItems;
                                    for (let orderItem of data) {
                                      if (orderItem.id === item.id) {
                                        orderItem.productRatingByUser = rateValue;
                                      }
                                    }
                                    this.setState({
                                      ...orderData,
                                      orderLineItems: data
                                    });
                                  }}
                                  value={!item.productRatingByUser ? 1 : item.productRatingByUser}
                                />
                                { !item.isProductRatedByUser ?
                                  <>
                                    <Popconfirm
                                    title="Are you sure to submit this rating?"
                                    onConfirm={() => {
                                      if (item.productRatingByUser >= 1) {
                                        this.submitRating(item)
                                      }
                                      else {
                                        message.warning('Rating should be atleast 1')
                                      }
                                    }}
                                    onCancel={this.cancel}
                                    okText="Yes"
                                    cancelText="No"
                                    >
                                      <Button type="primary">Submit</Button>
                                    </Popconfirm>
                                  </> :
                                  <>
                                    <Button disabled style={{color: "white", border: "none", background: "#b0b7be"}}>Submit</Button>
                                  </>
                                }
                              </div>
                            </>
                            : "" 
                          }
                        </div>
                      );
                    })}
                </div>
              </Col>
            </Row>
          </div>

          <Modal
            title={t("Choose Action")}
            visible={visible}
            onCancel={this.handleCancel}
            footer={null}
            width={1000}
            centered={true}
          >
            {isSeller && cancelReasonsList ? (
              <Form>
                <Radio.Group>
                  {cancelReasonsList.map((item, cc) => {
                    return (
                      <li
                        key={cc}
                        style={{ listStyle: "none", margin: "10px" }}
                      >
                        <Radio
                          value={item}
                          onChange={(e) =>
                            this.setState({
                              selectedCancelRadioBtn: e.target.value,
                            })
                          }
                        >{`${t(item)}`}</Radio>
                      </li>
                    );
                  })}
                </Radio.Group>
                <br />
                {selectedCancelRadioBtn === "Other" ? (
                  <TextArea
                    style={{
                      margin: "30px 10px",
                      marginTop: "5px",
                      width: "50%",
                    }}
                    onChange={(e) =>
                      this.setState({ otherOrderCancelReason: e.target.value })
                    }
                  />
                ) : null}
                <br />
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    this.handleCancelReason(selectedCancelRadioBtn)
                  }
                >
                  {t("Confirm")}
                </Button>
              </Form>
            ) : (
              <Form>
                <Radio.Group>
                  {cancelReasonListForBuyer.map((item, buyerResp) => {
                    return (
                      <li
                        key={buyerResp}
                        style={{ listStyle: "none", margin: "10px" }}
                      >
                        <Radio
                          value={item}
                          onChange={(e) =>
                            this.setState({
                              selectedCancelRadioBtn: e.target.value,
                            })
                          }
                        >{`${t(item)}`}</Radio>
                      </li>
                    );
                  })}
                </Radio.Group>
                <br />
                {selectedCancelRadioBtn === "Other" ? (
                  <TextArea
                    style={{
                      margin: "30px 10px",
                      marginTop: "5px",
                      width: "50%",
                    }}
                    onChange={(e) =>
                      this.setState({ otherOrderCancelReason: e.target.value })
                    }
                  />
                ) : null}

                <br />
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    this.handleCancelReason(selectedCancelRadioBtn)
                  }
                >
                  {t("Confirm")}
                </Button>
              </Form>
            )}
          </Modal>

          {processShipment && (
            <ShipPacket
              onclose={(shipresponse) => {
                if (shipresponse && shipresponse.applicationCode === 200) {
                  this.setState(
                    { processShipment: false, isReadyToShip: false },
                    () => {
                      this.props.history.push({
                        pathname: "/your-orders",
                        search: `?status=ReadyToShip`,
                      });
                    }
                  );
                } else {
                  this.setState({
                    processShipment: false,
                    isReadyToShip: true,
                  });
                }
              }}
              orderData={orderData}
            />
          )}

          {updatePacketShipment && (
            <UpdateShipmentPacket
              onclose={(updatePacketResponse) => {
                if (
                  updatePacketResponse &&
                  updatePacketResponse.applicationCode === 200
                ) {
                  this.setState(
                    {
                      updatePacketShipment: false,
                      isPacketInShipmentState: false,
                    },
                    () => {
                      this.props.history.push({
                        pathname: "/your-orders",
                        search: `?status=ReadyToShip`,
                      });
                    }
                  );
                } else {
                  this.setState({
                    updatePacketShipment: false,
                    isPacketInShipmentState: true,
                  });
                }
              }}
              orderData={orderData}
            />
          )}

          {processHold && (
            <HoldOrder
              onclose={(holdResponse) => {
                if (holdResponse && holdResponse.applicationCode === 200) {
                  this.setState({ processHold: false, isOnHold: false }, () => {
                    this.props.history.push({
                      pathname: "/your-orders",
                      search: `?status=Hold`,
                    });
                  });
                } else {
                  this.setState({ processHold: false, isOnHold: true });
                }
              }}
              orderData={orderData}
            />
          )}
        </LayoutComponent>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = ({ global }) => ({
  loading: global.buttonLoading,
  token: global.token,
  currentUser: global.currentUser,
});
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderDetailComponent))
);
