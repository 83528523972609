import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


class SectionLoaderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        return (
            <Spin indicator={antIcon} />
        );
    }
}

export default SectionLoaderComponent;
