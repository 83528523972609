import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Row, Col, Card, Tabs, Input, Skeleton } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import LayoutComponent from './layoutComponents/layoutComponent'
import myImage from '../imageNotFound.jpeg'
import SearchService from '../service/searchService';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

const { TabPane } = Tabs;

class ProductListingSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterVisible: false,
            sortVisible: false,
            filters: [],
            dataToPost: {},
            searchVal: "",
            loader: false
        }
    }

    renderFiltersData = (productList) => {

        let filtersArr = [...this.state.filters]

        if (productList.sectionView.viewAll.payLoad.filters) {

            let { filters: loopFilters } = productList.sectionView.viewAll.payLoad

            for (var i in loopFilters.selectionFilters) {
                let innerValues = []
                if (loopFilters.selectionFilters[i].innerFilter) {
                    for (var j in loopFilters.selectionFilters[i].innerFilter) {
                        innerValues.push(loopFilters.selectionFilters[i].innerFilter[j])
                    }
                }

                filtersArr.push({
                    filterkey: loopFilters.selectionFilters[i].displayName,
                    filterValue: innerValues,
                    item: loopFilters.selectionFilters[i]
                })

            };
            this.setState({ filters: filtersArr })

        }

    }

    componentDidMount() {
        let payload = this.props.match.params.payload;
        console.log(payload);
        payload = decodeURIComponent(payload)
        let test = Buffer.from(payload, 'base64');
        console.log(test);
        let decoded = '';
        if (typeof (test) === 'string') {
            decoded = JSON.parse(test);
            console.log(decoded);
        } else {
            decoded = test;
        }
        let title = this.props.match.params.title
        title = decodeURIComponent(title)
        let words = title.split('+');
        let searchVal = words.join(' ')
        this.setState({ dataToPost: decoded, searchVal: searchVal })
        this.getSearchedData(title, decoded)
    }

    getSearchedData = async (title, decoded) => {
        const { t } = this.props;
        this.setState({ loader: true })
        let filter = JSON.parse(decoded);
        // console.log(typeof(filter))
        if (typeof (filter) == "string") {
            filter = JSON.parse(filter);
        }
        let x;
        if (filter && filter["query"]) {
            if(filter.hasOwnProperty('filterAttributes'))
            {
                x = await SearchService.algoliaProductsearch(filter["query"], filter.filterAttributes)
            }
            else{
                x = await SearchService.algoliaProductsearch(filter["query"], {})
            }
            
        } else {
            x = await SearchService.algoliaProductsearch("", filter)
        }
        console.log('i am', x)
        // if (x.length) {
        this.setState({ productList: x, loader: false }, () => {
            console.log("productsList = ", this.state.productList)
        })
        // }
        //  else {
        //     message.error(`${t('Error in loading data')}`)
        // }
    }

    showFilterDrawer = () => {
        this.setState({
            filterVisible: true,
        });
    };

    onFilterClose = () => {
        debugger
        this.setState({
            filterVisible: false,
        });
    };

    showSortDrawer = () => {
        this.setState({
            sortVisible: true,
        });
    };

    onSortClose = () => {
        debugger
        this.setState({
            sortVisible: false,
        });
    }

    onChangeSorting = (sortKey) => {
        debugger
        this.setState({
            sortVisible: false,
        });

        let { dataToPost } = this.state

        dataToPost.selectedSort = sortKey
        this.getSearchedData(dataToPost)
    }

    onTabChange = (currentTab) => {
        debugger
        this.setState({ currentTab: currentTab })
        // console.log(currentTab)
    }
    onChecked = (checkedList) => {
        //  console.log(checkedList, "list")
    };

    render() {
        const { productList, filterVisible, sortVisible, filters, searchVal, loader } = this.state
        console.log(this.state);
        console.log(productList);
        const { t } = this.props;
        return (<LayoutComponent>

            <Input
                style={{ paddingTop: '12px', paddingBottom: '12px' }}
                prefix={<SearchOutlined className='font16px lineHeight0 searchIconColor mr-1' />}
                value={searchVal}
                onClick={() => {
                    this.props.history.push({ pathname: '/quick-search' })
                }}
                placeholder={t("Start typing")} />

            {/* <div className={'topSection'}>
                <Row>
                    <Col span={24}><div className={'sortDiv'}>{productList && productList.length ? productList.length : 0} {t('Items found')}</div></Col>
                </Row>
            </div> */}

            <div className={'container bottomSection productListingSearchList'}>
                <Row>
                    {productList?.length ?
                        productList.map((productItem, index) => {
                            let priceWithGst;
                            let priceWithoutGST
                            // let price = Number(productItem.mapProductSets[0].pricePerPiece);
                            let price = productItem.minPrice
                            let gstPercentage = Number(productItem.gstMaster.percentage);
                            let gst = (Number(price) * gstPercentage) / 100;
                            if (productItem.minPrice === productItem.maxPrice) {
                                priceWithoutGST = price
                                priceWithGst = _.round((price + gst), 2)
                            } else {
                                let maxPriceGst = (Number(productItem.maxPrice) * gstPercentage) / 100;
                                priceWithoutGST = `${price} - ${productItem.maxPrice}`
                                priceWithGst = `${_.round((price + gst), 2)} - ${_.round((productItem.maxPrice + maxPriceGst), 2)}`
                            }
                            let discountPrice = (100 - productItem.maxPrice / productItem.mapProductSets[0].mrpPerPiece * 100).toFixed(2)
                            // if (!productItem.isGSTIncluded) {
                            //     let num = price + gst;
                            //     priceWithGst = (Math.round(num * 100) / 100).toFixed(2);
                            // } else {
                            //     priceWithGst = price;
                            // }
                            return (<Col sm={6} xs={24} key={index} className={'productListingSearchListCard'}>
                                <Card
                                    className={'category_card'}
                                    size={'small'}
                                    bordered={false} >
                                    <div className={'productDiv'}>
                                        <div className="pointer_Cursor" onClick={() => {
                                            this.props.history.push({ pathname: `/view-product/${productItem.id}` })

                                        }}>
                                            <img src={productItem && productItem.imageUrl ? `${productItem.imageUrl}` : `${myImage}`} alt={'clothImg'} />
                                            <p className='label'>{productItem && productItem.name ? productItem.name : null}</p>
                                            <p className='label'><b>{priceWithGst ? priceWithGst : ""}</b> per pc</p>
                                            <p className='label' >{priceWithoutGST ? (`${priceWithoutGST} + ${gstPercentage}%  GST`) : ""} per pc</p>
                                            {/* {productItem?.mapProductSets[0]?.mrpPerPiece ? <p className='label'><strike>₹ {productItem.mapProductSets[0].mrpPerPiece}</strike> {discountPrice}% off</p> : null} */}
                                            {productItem?.mapProductSets[0]?.mrpPerPiece ? <p className='label'>₹ {productItem.mapProductSets[0].mrpPerPiece}</p> : null}
                                        </div>
                                        <div className={'locationDiv pointer_Cursor'} onClick={() => {
                                            this.props.history.push({ pathname: `/view-profile/${productItem.accountId}` })
                                        }}>
                                            <div className='labelSupplier'>{productItem.supplierName ? productItem.supplierName : ''}</div>
                                            {/* <div>Mumbai , Maharashtra</div> */}
                                        </div>
                                    </div>
                                </Card>
                            </Col>)
                        }) :
                        <>
                            {loader ? <Skeleton active /> :
                                <Col span={24}>
                                    <div style={{ minHeight: '50vh' }}>
                                        <Card className={'no_data_div'} >{t('No data found')}</Card>
                                    </div>
                                </Col>
                            }
                        </>
                    }
                </Row>
                {/* {productList?.length === 0 && <Row>
                    <Col span={24}>
                        <div style={{ minHeight: '50vh' }}>
                            <Card className={'no_data_div'} >{t('No data found')}</Card>
                        </div>
                    </Col>
                </Row>} */}
            </div>

            {/* <Drawer
                height={370}
                placement={'bottom'}
                closable={false}
                onClose={this.onSortClose}
                visible={sortVisible}
                key={'sort'}
            >
                <div className="sidebar_navigation-items">
                    <ul className='mb-0'>
                        {productList && productList.sectionView.viewAll && Object.keys(productList.sectionView.viewAll.payLoad.sorts).map((key, skey) => {
                            return (
                                <li key={skey} className="pointer_Cursor"
                                    onClick={(e) => this.onChangeSorting(key)}>{productList.sectionView.viewAll.payLoad.sorts[key]}</li>
                            )
                        })}
                    </ul>
                </div>
            </Drawer>


            <Drawer
                height={'100vh'}
                placement={'bottom'}
                closable={false}
                onClose={this.onFilterClose}
                visible={filterVisible}
                key={'filter'}
            >
                <div className="sidebar_navigation-items">
                    <header className="toolbar component-toolbar">
                        <nav className="toolbar__navigation">
                            <div className='tab_heading' >
                                <CloseOutlined onClick={this.onFilterClose} />
                                <span className='ml-4'>Filter</span>
                            </div>
                        </nav>
                    </header>
                    <Row>

                        <Tabs className='custom_tabs hideOverflow' defaultActiveKey="0" tabPosition='left'
                            onChange={this.onTabChange}>
                            {filters && filters.length > 0 && filters.map((item, tabKey) => {
                                if (item.filterValue.length > 0) {
                                    return (<TabPane key={tabKey}
                                        tab={item.filterkey}>
                                        <Row>
                                            {item.filterValue[0].innerFilter ? "hello" :
                                                <Col span={24}>
                                                    <Checkbox.Group
                                                        style={{ height: '100%' }}
                                                        className='checkbox_div'
                                                        options={item.filterValue.map((i) => i.displayName)}
                                                        onChange={this.onChecked}
                                                    />
                                                </Col>
                                            }
                                        </Row>
                                    </TabPane>)
                                } else {
                                    return null;
                                }
                            })}
                        </Tabs>
                    </Row>

                    <div className='button_div'>
                        <button onClick={this.onClose}>
                            Apply
                        </button>
                    </div>

                </div>
            </Drawer> */}


        </LayoutComponent>)
    }
}

export default withTranslation()(withRouter(ProductListingSearch));