import React, { Component } from 'react';
import { Form, Input, Button, Card, message, Checkbox } from 'antd';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { showLoader, hideLoader } from '../../actions'
import { connect } from 'react-redux'
import { getMessaging, getToken } from "firebase/messaging";
import Authentication from "../../service/authService"
import OtpImage from '../../assests/OTP_Verify.svg'
import logo from '../../akar_dark.png'
const webPushPublicKey = process.env.REACT_APP_FCM_WEB_PUSH_PUBLIC_KEY;
const websiteUrl = process.env.REACT_APP_WEBSITE_URL;

class OtpLoginComponent extends Component {
    formRef = React.createRef()
    interval

    constructor(props) {
        super(props);
        this.state = {
            invalidPhoneError: false,
            invalidPhoneMsg: '',
            mobile: '',
            isRetry: false,
            timer: 30,
            canSendOtp: false,
            otpResendCount: 2,
            businessNameExist: false
        }
    }

    componentDidMount() {
        const { token, currentUser } = this.props
        if (token && currentUser.action.type !== null) {
            this.props.history.push({ pathname: '/' })
        }

        let mob = typeof window !== 'undefined' ? localStorage.getItem('mobile') : null
        this.setState({ mobile: mob }, () => {
            this.getBusinessName()
        })
        this.setTimerInterval(); // Setting Reset Timer
    }

    getBusinessName = () => {
        Authentication.getBusinessName().then((resp) => {
            const { data } = resp.data
            if (data.name) {
                this.setState({ businessNameExist: true }, () => {
                    this.formRef.current.setFieldsValue({ businessName: data.name })
                })
            }
        }, (error) => {
            console.log(error)
        })
    }

    setTimerInterval = () => {
        this.interval = setInterval(
            () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.interval);  // Removing Reset Timer
    }

    firebaseTokenRegistration = (messaging) => {
        getToken(messaging, { vapidKey: webPushPublicKey }).then((currentToken) => {
            if (currentToken) {

                this.registerForNotification(currentToken)
            } else {
                this.navigatePostLogin();
            }
        }).catch((err) => {
            this.navigatePostLogin();
        });
    }

    navigatePostLogin = () => {
        let redirect = this.props.location.search
        let x = redirect.search("=");
        let newstr = redirect.slice(x + 1)
        let final = decodeURI(newstr);
        final = final.replace(/\"/g, "")

        console.log(final, "llllll")

        if (final) {
            window.location = `${websiteUrl}` + final
        } else {
            this.props.history.push({ pathname: '/' })
        }

    }

    registerForNotification = (notificationToken) => {
        Authentication.addNotificationToken(notificationToken).then((resp) => {
            this.navigatePostLogin()
        }, (error) => {
            this.navigatePostLogin()
        })
    }

    onFinish = values => {
        const { dispatch, t } = this.props
        const { mobile } = this.state

        // if (!values.IsTermsAndConditionApprovedByBuyer) {
        //     return message.error('Please accept Terms and services!')
        // }
        dispatch(showLoader())

        Authentication.postLoginOtp(mobile, values.otp, this.state.isRetry, values.businessName)
            .then((res) => {
                if (typeof window !== 'undefined') { localStorage.removeItem('mobile') }
                this.setState({ isRetry: false })
                let { data } = res.data
                dispatch(hideLoader())
                dispatch({
                    type: 'SET_CURRENT_USER',
                    user: data
                })

                dispatch({
                    type: 'SET_AUTH_TOKEN',
                    token: res.headers.authorization
                })
                if (data) {
                    if (data.account.type === 'Seller' || data.account.type === 'Buyer') {
                        this.navigatePostLogin();

                        // if (firebase.messaging.isSupported()) {
                        const messaging = getMessaging();
                        this.firebaseTokenRegistration(messaging)
                        // } else {
                        //     this.navigatePostLogin();
                        // }
                    } else {
                        if (typeof window !== 'undefined') { localStorage.removeItem('Authorization') }
                        dispatch({ type: 'SET_AUTH_TOKEN', token: null })
                        dispatch({
                            type: 'SET_CURRENT_USER',
                            user: null
                        })
                        message.error(`${t('Access denied')}`)
                        this.navigate('/login')
                    }
                } else {
                    message.error(`${t('Invalid otp')}`)
                }

            }, (err) => {
                dispatch(hideLoader())
                this.setState({ mobile: '', invalidPhoneError: true, invalidPhoneMsg: err.data.message })

                setTimeout(() => {
                    this.setState({ invalidPhoneError: false, invalidPhoneMsg: '', mobile: '' })
                }, 5000);
            });
    };

    navigate = (url) => {
        this.props.history.push({ pathname: url })
    }

    resendOtp = async () => {
        let { data } = await Authentication.postLoginMobile(this.state.mobile)
        if (data.isSuccess) {
            message.success('Otp sent to your mobile!')
            this.setState({
                canSendOtp: false,
                otpResendCount: this.state.otpResendCount - 1
            }, () => {
                this.setTimerInterval();
            })
        }
    }

    componentDidUpdate = () => {
        // Updating Reset Timer
        if (this.state.timer === 0) {
            this.setState({
                canSendOtp: true,
                timer: 30
            })
            clearInterval(this.interval);
        }
    }

    render() {
        const { loading, t } = this.props
        const { mobile, canSendOtp, timer, otpResendCount, businessNameExist } = this.state

        return (
            <>
                <div className='content-center'>
                    <div className='justify-content-center'>

                        <div>
                            <div className='justify-content-center mb-4'>
                                <img height='100' src={logo} alt='logo' />
                            </div>
                            <Card
                                className='auth-card'
                                style={{ width: 380 }}>
                                <div className='justify-content-center mb-4'>
                                    <img height='120' src={OtpImage} alt={'otpVerify'} />
                                </div>
                                <div className='text-align-center mb-3'>
                                    <h3> {t('Verify mobile no')}</h3>
                                    <p> {t('Enter 4 digit code')}{` ${mobile} `}{t('To proceed')}</p>
                                </div>

                                <Form
                                    ref={this.formRef}
                                    name="horizontal_login"
                                    layout="vertical"
                                    onFinish={this.onFinish}>
                                    <Form.Item
                                        name="businessName"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('Buisname name validation')}`,
                                            }
                                        ]}
                                    ><Input disabled={businessNameExist} placeholder={t('Enter business name')} />
                                    </Form.Item>
                                    <Form.Item
                                        name="otp"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${t('Otp validation')}`,
                                            },
                                            {
                                                len: 4,
                                                message: `${t('Invalid otp')}`,
                                                validateTrigger: 'onSubmit'
                                            }
                                        ]}
                                    >
                                        <Input type='number' placeholder={t('Enter otp')} />
                                    </Form.Item>

                                    <div className='mt-1'>
                                        {
                                            otpResendCount > 0
                                                ? <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    padding: '8px 0px',
                                                    flexDirection: 'row'
                                                }}>  {canSendOtp
                                                    ? <button type='button'
                                                        className='card_button timer'
                                                        onClick={() => {
                                                            this.setState({ isRetry: true }, () => {
                                                                this.resendOtp()
                                                            })
                                                        }}>
                                                        {t('Resend otp')}
                                                    </button>
                                                    : <div className="timer">{t('Resend otp in')} {timer} {t('second')}</div>
                                                    }
                                                    <div className="timer">{t('Attempts left')}: {otpResendCount}</div>
                                                </div> : null}
                                    </div>
                                    <Form.Item>
                                        <Button
                                            loading={loading}
                                            className='my-button'
                                            htmlType="submit">
                                            {t('login')}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ global }) => ({
    loading: global.buttonLoading,
    token: global.token,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(OtpLoginComponent)));


