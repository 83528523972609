import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import CarouselBanner  from '../elements/carousel';
import SectionLoader from './sectionLoader'

class CarouselBannerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const { itemData } = this.props
        return (<>{itemData ? <div className="w-100 caraousel-container">
            <CarouselBanner itemData={itemData}/>
        </div> : <div className="sectionloader">
            <SectionLoader />
        </div>}</>)
    }
}
export default withRouter(CarouselBannerView)
