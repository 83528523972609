import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Api from "../../service/network";
import MediaUrl from "../../service/mediaUrl";
import SectionLoader from "./sectionLoader";

class BannerWithStaticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerData: [],
    };
  }
  componentDidMount() {
    const { itemData } = this.props;

    if (itemData.callType === "Get") {
      let path = `${itemData.dataUrl}${itemData.queryParam}`;
      Api.makeGetRequest(path, null, null, null).then((res) => {
        let output = res.data.data.data;
        output.forEach((img) => {
          if (img.imageUrl) {
            let url = MediaUrl.completeMediaUrl(img.imageUrl, true, 100, 100);
            img.imageUrl = url;
          }
        });
        this.setState({ bannerData: output });
      });
    }
  }

  render() {
    const { itemData } = this.props;
    const { bannerData } = this.state;
    console.log(bannerData,"banner")
    return (
      <>
        {itemData ? (
          <div
            style={{ cursor: "pointer" }}
            className="toolbar_navigation-items mt-2 slider_div"
          >
            {bannerData &&
              bannerData.length > 0 &&
              bannerData.map((item, bannerKey) => {
                return (
                  <div
                    className="fullWidthBanner"
                    key={bannerKey}
                    onClick={() => {
                      let payload = JSON.parse(item.payLoad);
                      if (payload.frontendPath) {
                        this.props.history.push(`/${payload.frontendPath}`);
                      }
                    }}
                  >
                    <img
                      src={item && item.imageUrl ? item.imageUrl : ""}
                      alt="test_banner"
                    />
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="sectionloader">
            <SectionLoader />
          </div>
        )}
      </>
    );
  }
}
export default withRouter(BannerWithStaticPage);
