import React, { Component } from 'react';
import { Form, Input, Button, Card, Select, message, Tooltip } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import Authentication from "../../service/authService"
const { Option } = Select;

class RegistrationComponent extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            states: [],
            showReferralCode: false,
            isBusinessNameExist: false,
            addressLine1: '',
            showLabelInput: false,
            labelValue: ''
        }
    }
    componentDidMount() {

        const { currentUser } = this.props;
        console.log(currentUser)

        // const { account } = this.props.currentUser
        // console.log(account)
        if (currentUser.account.name) {
            this.setState({
                isBusinessNameExist: true
            }, () => {
                this.formRef.current.setFieldsValue({ accountName: currentUser.account.name })
                this.formRef.current.setFieldsValue({ email: currentUser.email })
                this.formRef.current.setFieldsValue({ name: currentUser.name })
                this.formRef.current.setFieldsValue({ Type: currentUser.account.type })
                this.formRef.current.setFieldsValue({ label: currentUser.account?.addresses[0]?.label })
                this.formRef.current.setFieldsValue({ addressLine1: currentUser.account?.addresses[0]?.addressLine1 })
                this.formRef.current.setFieldsValue({ addressLine2: currentUser.account?.addresses[0]?.addressLine2 })
                this.formRef.current.setFieldsValue({ city: currentUser.account?.addresses[0]?.city })
                this.formRef.current.setFieldsValue({ stateId: currentUser.account?.addresses[0]?.stateId })
                this.formRef.current.setFieldsValue({ zipCode: currentUser.account?.addresses[0]?.zipCode })
                this.setState({ addressLine1: currentUser.account?.addresses[0]?.addressLine1 })
                const label = currentUser.account?.addresses[0]?.label
                console.log(label)
                if (label === undefined) {
                    this.formRef.current.setFieldsValue({ label: 'Home' })
                } else if (label === 'Home' || label === 'Office') {
                    this.setState({ showLabelInput: false })
                } else {
                    this.setState({ showLabelInput: true, labelValue: label })
                    this.formRef.current.setFieldsValue({ label: 'Other' })
                }

                this.getStates()
            })
        } else {
            this.getStates()
        }

        // const { token, currentUser } = this.props
        // if (token && currentUser.type !== null && currentUser.account.isUserRegistered) {
        //     this.props.history.push({ pathname: '/' })
        // }
    }
    onFinish = (values) => {
        const { dispatch, onRegistrationDone, t, currentUser } = this.props
        const { labelValue } = this.state
        const addressId = currentUser?.account?.addresses[0]?.id
        const regExp = /[a-zA-Z]/g;
    const specialChars = /[`!@#$%^&*()_+\=\[\]{};'"\\|<>\?~]/;


        if (values && values.addressLine1 && !regExp.test(values.addressLine1)) {
            return message.error(`${t('AddressLine1 should contain alphabets also')}`)
        
        } if(specialChars.test(values.addressLine1) || specialChars.test(values.addressLine2)){
      return message.error(`${t("Special characters are not allowed")}`)
    }

        const refCodeExp = /^[a-zA-Z0-9\-_]*$/;
        if (values && values.referralCode && !refCodeExp.test(values.referralCode)) {
            return message.error(`${t('Reference number tooltip msg')}`)
        }

        let dump = { ...values };
        if (!values.email) {
            dump = { ...values, email: null }
        }
        this.setState({ loading: true })

        console.log(dump)

        let dataToSend = {
            name: dump.name,
            email: dump.email,
            type: dump.Type,
            referralCode: dump.referralCode,
            accountName: dump.accountName,
            address: {}
        }
        const obj = {
            addressLine1: dump.addressLine1,
            addressLine2: dump.addressLine2,
            city: dump.city,
            label: dump.label === 'Other' ? labelValue : dump.label,
            stateId: dump.stateId,
            stateName: dump.stateName,
            zipCode: dump.zipCode,
        }

        console.log(obj)

        if (addressId) {
            Authentication.updateAddressDetails(obj, addressId)
                .then((res) => {
                    this.postRegisterData(dataToSend)
                }, (err) => {
                    message.error(`${t('Error in updation')}`)
                });
        }
        else {
            dataToSend.address = obj
            this.postRegisterData(dataToSend)

        }

    };

    postRegisterData = (dataToSend) => {
        const { dispatch, onRegistrationDone, t } = this.props

        Authentication.postSignup(dataToSend)
            .then((res) => {
                let { data } = res.data
                this.setState({ loading: false }, () => {
                    this.formRef.current.resetFields()
                })

                dispatch({
                    type: 'SET_CURRENT_USER',
                    user: data
                })
                if (onRegistrationDone) {
                    onRegistrationDone()
                }

            }, (err) => {
                this.setState({ loading: false })
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 5000);
                message.error(`${t('Error in registering user')}`)
            });
    }

    getStates = () => {
        Authentication.getStates()
            .then((res) => {
                this.setState({ states: res.data.data })
            }, err => { })
    }

    checkLabelValue = (val) => {
        if (val === 'Home' || val === 'Office') {
            this.setState({ showLabelInput: false })
        } else {
            this.setState({ showLabelInput: true })

        }
    }

    render() {
        const { states, loading, showReferralCode, isBusinessNameExist, showLabelInput, labelValue } = this.state
        const { t } = this.props
        return (<>
            <div
                className='content-center'
                style={{ height: '183vh' }}>
                <div className='justify-content-center registration_form_card' >
                    <Card bordered={false}
                        style={{ width: 380 }}>

                        <div className='text-align-center mb-3'>
                            <h3>{t('Enter account info')}</h3>
                            <p>{t('Register your business on Akar')}</p>
                        </div>

                        <Form ref={this.formRef}
                            name="signup_form"
                            scrollToFirstError
                            layout="vertical"
                            requiredMark={false}
                            onFinish={this.onFinish}>
                            <Form.Item
                                name="name"
                                label={`${t('Your name')}*`}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: `${t('Input name reqd validation')}`,
                                    }
                                ]}
                            >
                                <Input maxLength={80} type='text' placeholder={t('Your name')} />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                label={`${t('Your email')}`}
                                rules={[
                                    {
                                        required: false,
                                        whitespace: true,
                                        message: `${t('email reqd validation')}`
                                    },
                                    {
                                        type: 'email', message: `${t('Invalid email')}`,
                                        validateTrigger: 'onSubmit'
                                    },

                                ]}
                            >
                                <Input type='email' placeholder={t('Your email')} />
                            </Form.Item>

                            <Form.Item
                                label={`${t('Your business name')}*`}
                                rules={[
                                    {
                                        whitespace: true,
                                        required: true,
                                        message: `${t('Business name reqd validation')}`,
                                    }
                                ]}
                                name="accountName" >
                                <Input disabled={isBusinessNameExist} maxLength={80} placeholder={t('Your business name')} />
                            </Form.Item>

                            <Form.Item
                                label={`${t('Role')}*`}
                                name="Type"
                                rules={[
                                    {
                                        required: true,
                                        message: `${t('Please select role')}`,
                                    }
                                ]}
                            >
                                <Select placeholder={t('Select role')}  >
                                    <Option value="Buyer">{t('Retailer')} ({t('Buyer')})</Option>
                                    <Option value="Seller">{t('Wholesaler')} ({t('Seller')})</Option>
                                </Select>
                            </Form.Item>

                            <h2>{t('Address details')}</h2>

                            <Form.Item label={`${t('Address label')}*`}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: `${t('Address label reqd validation')}`
                                    }
                                ]} name="label"
                                style={{ marginBottom: `${showLabelInput ? '5px' : '24px'}` }}>

                                <Select placeholder='Please select the label' onChange={(val) => this.checkLabelValue(val)}>
                                    <Option value="Home">Home</Option>
                                    <Option value="Office">Office</Option>
                                    <Option value="Other">Other</Option>
                                </Select>

                            </Form.Item>

                            {showLabelInput ?
                                <Input style={{ marginBottom: '24px' }} maxLength={80} defaultValue={labelValue} placeholder={t('Home_office')}
                                    onChange={(e) => this.setState({ labelValue: e.target.value })} />
                                : null
                            }



                            <Form.Item
                                label={`${t('Address line 1')}*`}
                                name="addressLine1"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: `${t('Address line 1 reqd validation')}`
                                    },
                                    {
                                        pattern: new RegExp(/^([a-zA-Z\-_/,. ]*)+\d+[a-zA-Z0-9\-_/,. ]*/g),
                                        required: true,
                                        message: `${t('Address Line 1 combination validation')}`
                                    },
                                    {
                                        min: 10,
                                        message: `${t('Address Line 1 length10 validation')}`
                                        
                                    },
                                    {
                                        max: 50,
                                        message: `${t('Address Line 1 length50 validation')}`
                                        
                                    }
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Tooltip
                                    name="addressLine1"
                                    title={t('Address line 1 tooltip msg')}>
                                    <Input placeholder={t('Building No_colony')}
                                        value={this.state.addressLine1}
                                        onChange={(e) => {
                                            this.setState({ addressLine1: e.target.value })
                                            this.formRef.current.setFieldsValue({ addressLine1: e.target.value })
                                        }}
                                    />
                                </Tooltip>
                            </Form.Item>

                            <Form.Item
                                label={`${t('Address line 2')}*`}
                                name="addressLine2"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: `${t('Address line 2 reqd validation')}`
                                    },
                                    {
                                        pattern: new RegExp(/^([a-zA-Z\-_/,. ]*)+\d+[a-zA-Z0-9\-_/,. ]*/g),
                                        required: true,
                                        message: `${t('Address Line 2 combination validation')}`
                                    },
                                    {
                                        min: 10,
                                        message: `${t('Address Line 2 length10 validation')}`
                                        
                                    },
                                    {
                                        max: 50,
                                        message: `${t('Address Line 2 length50 validation')}`
                                        
                                    }
                                ]}
                                validateTrigger="onBlur"

                            >
                                <Input maxLength={80} placeholder={t('Area_District')} />
                            </Form.Item>


                            <Form.Item label={`${t('City')}*`} rules={[
                                {
                                    required: true,
                                    whitespace: true,
                                    message: `${t('City reqd validation')}`
                                }
                            ]} name="city" >
                                <Input maxLength={80} placeholder={t('City')} />
                            </Form.Item>


                            <Form.Item
                                name="stateId"
                                label={`${t('State')}*`}
                                rules={[
                                    {
                                        required: true,
                                        message: `${t('State reqd validation')}`
                                    }
                                ]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder={t('Select state')}>
                                    {states && states.map((item, skey) => {
                                        return (
                                            <Option key={skey}
                                                value={item.id}>{item.name}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={`${t('PinCode')}*`}
                                name="zipCode"
                                rules={[
                                    {
                                        required: true,
                                        message: `${t('Pincode reqd validation')}`,
                                        whitespace: true,

                                    },
                                    {
                                        len: 6,
                                        message: `${t('Invalid length pincode')}`
                                    }
                                ]}
                            >
                                <Input type="number" placeholder={t("6 digit pincode")} />
                            </Form.Item>

                            <div className={"pointer_Cursor mb-3"} onClick={() => {
                                this.setState((state, props) => ({
                                    showReferralCode: !state.showReferralCode
                                }));
                            }}><span style={{ paddingTop: '2px' }}>{t('Other details')}</span>
                                <span className="ml-1">
                                    {showReferralCode ? <UpOutlined style={{
                                        fontSize: '12px', position: 'absolute',
                                        top: '82.6%'
                                    }} /> : <DownOutlined style={{
                                        fontSize: '12px', position: 'absolute',
                                        top: '89.1%'
                                    }} />}
                                </span>
                            </div>

                            {showReferralCode ?
                                (<Form.Item
                                    label={t("Reference number")}
                                    name="referralCode"
                                    rules={[
                                        {
                                            message: `${t('Enter reference number')}`,
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Tooltip
                                        title={t('Reference number tooltip msg')}>
                                        <Input placeholder={t("Reference number")}
                                            onChange={(e) => {
                                                this.formRef.current.setFieldsValue({ referralCode: e.target.value })
                                            }}
                                        />
                                    </Tooltip>
                                </Form.Item>) : null}

                            <Form.Item >
                                <Button
                                    loading={loading}
                                    className='my-button'
                                    htmlType="submit" >
                                    {t("Sign up")}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
        );
    }
}

const mapStateToProps = ({ global }) => ({
    token: global.token,
    currentUser: global.currentUser,
    loading: global.loading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(RegistrationComponent)));


