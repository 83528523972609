import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import LayoutComponent from '../layoutComponents/layoutComponent'
import Footer from '../footer';
import Layout from "./../layout";

const content = <div className={'contact_container'}>
    <div>
        <h1>Payment Cycle & Platform Fees</h1>
    </div>
    <p
        className="MsoNormal mt-4"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
    >
        <span style={{ fontSize: "14.0pt" }}>
            The payment cycle for seller will be D+10Days (Delivery + 10Days), i.e. the seller payment will be initiated on the 10th days after delivery of the order is completed. AKAR will transfer or initiate the payment of a particular order or multiple orders delivered on the same day , if any.
        </span>
    </p>
    <p
        className="MsoNormal mt-4"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
    >
        <span style={{ fontSize: "14.0pt" }}>
            The payment which will be initiated by the AKAR and the payment for which the AKAR is liable to pay is the order amount less the platform fees and taxes. The platform fees will be deducted from the order amount and then it will transferred to seller.
        </span>
    </p>

    <p
        className="MsoNormal mt-4"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
    >
        <span style={{ fontSize: "14.0pt" }}>
            However the cycle may get delayed, if the payment day fall on a national holiday or a bank holiday. In such case the payment will be initiated on the next working day. The payment cycle may change from time to time and will be informed to seller at the time of change.
        </span>
    </p>


    <p
        className="MsoNormal mt-4"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
    >
        <span style={{ fontSize: "14.0pt" }}>
            The payment will only be transmitted by the means of RTGS/NEFT/IMPS or through bank transfers only, and not by any means of cash and neither the seller can demand the payment by any other means opted by AKAR.
        </span>
    </p>

    <p
        className="MsoNormal mt-4"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
    >
        <span style={{ fontSize: "14.0pt" }}>
            The commission charged by the platform will be calculated between 7% to 9.85% (excluding GST) , 18% GST will be charged on the platform fees charged by AKAR, and GST invoice for the same will be generated and delivered to the seller at the end of each month. The commission charged will vary from product to product.
        </span>
    </p>


    <p
        className="MsoNormal mt-4"
        style={{ marginBottom: "0in", lineHeight: "normal" }}
    >
        <span style={{ fontSize: "14.0pt" }}>
            The commission charged by the platform will be calculated between 7% to 9.85% (excluding GST) , 18% GST will be charged on the platform fees charged by AKAR, and GST invoice for the same will be generated and delivered to the seller at the end of each month. The commission charged will vary from product to product.
        </span>
    </p>

</div>

class PaymentCycle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: typeof window !== 'undefined' ? localStorage.getItem('Authorization')  : null
        }
        window.scroll(0, 0);
    }

    render() {

        return (
            <>
                {this.state.token ?
                    <LayoutComponent>
                        {content}
                    </LayoutComponent> :
                    <Layout>
                        {content}
                    </Layout>}
                <Footer />

            </>)
    }
}


export default withRouter(PaymentCycle);

