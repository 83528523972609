import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { DatePicker, message, Space, Collapse, Spin, Input } from "antd";
import LayoutComponent from "../layoutComponents/layoutComponent";
import { Button, Tabs, Row, Col, Card } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { getLedger } from "../../actions/ledger";
import LedgerService from "../../service/ledger";
import "./styles.scss";

const { TabPane } = Tabs;
const { Panel } = Collapse;

class Ledger extends Component {
  constructor(props) {
    super(props);
    this.isSeller =
      props.currentUser?.account?.type === "Seller" ? true : false;
    this.currentUserId = props.currentUser?.accountId;
    this.state = {
      lookForOrderID: "",
      fromDate: "",
      toDate: "",
      loader: props.loader,
      scheduledTransactionData: [],
      scheduledTransactionCount: 0,
      scheduledTransactionCurrentPage: 0,
      totalDebitPrice: 0,
      totalCreditPrice: 0,
    };
  }

  componentDidMount = () => {
    const {
      results: { fromDate, toDate },
    } = this.props;
    this.setState({ fromDate, toDate });
    if (fromDate && toDate) {
      this.props.getLedger(1, fromDate, toDate);
    }
    this.getScheduledData();
  };

  componentDidUpdate = (prevProps) => {
    const {
      results: { fromDate, toDate, orderCustomId },
      loader,
    } = this.props;
    if (
      prevProps.results.fromDate !== fromDate ||
      prevProps.results.toDate !== toDate ||
      prevProps.results.orderCustomId !== orderCustomId
    ) {
      this.setState({ loader });
    }
  };

  disabledFromDate = (current) => {
    const { toDate } = this.state;
    let date = moment(toDate).endOf("day");
    if (toDate) {
      return current && current > date.subtract(1, "days");
    } else {
      return current && current > moment().endOf("day");
    }
  };

  disabledToDate = (current) => {
    const { fromDate } = this.state;
    if (fromDate) {
      return (
        (current && current < moment(fromDate).endOf("day")) ||
        current > moment().endOf("day")
      );
    } else {
      return current && current > moment().endOf("day");
    }
  };

  handleOrderIdInput = (val) => {
    this.setState({
      lookForOrderID: val,
    });
  };

  getScheduledData = () => {
    let id = this.props.currentUser.accountId;
    let pageNumber = this.state.scheduledTransactionCurrentPage + 1;
    LedgerService.getScheduledtransaction({ id, pageNumber })
      .then((res) =>
        this.setState((prevState) => {
          return {
            scheduledTransactionData: [
              ...prevState.scheduledTransactionData,
              ...res.data.results,
            ],
            scheduledTransactionCurrentPage: res.data.currentPage,
            scheduledTransactionCount: res.data.totalCount,
            totalCreditPrice: res.data.totalCreditPrice,
            totalDebitPrice: res.data.totalDebitPrice,
          };
        })
      )
      .catch((err) => console.log(err));
  };

  fromDateHandler = (date, dateString) => {
    this.setState({
      fromDate: dateString,
    });
  };
  toDateHandler = (date, dateString) => {
    this.setState({
      toDate: dateString,
    });
  };

  hitSearch = () => {
    const { results, t } = this.props;
    const { fromDate, toDate, lookForOrderID } = this.state;
    if (fromDate && toDate) {
      if (
        results.fromDate !== fromDate ||
        results.toDate !== toDate ||
        results.orderCustomId !== lookForOrderID
      ) {
        this.setState(
          {
            loader: true,
          },
          () => {
            this.props.getLedger(
              1,
              this.state.fromDate,
              this.state.toDate,
              lookForOrderID
            );
          }
        );
      } else {
        message.info(
          `${t("Results are already fetched for selected filters")}`
        );
      }
    } else {
      message.error(`${t("Please provide valid time duration")}`);
    }
  };

  loadMoreData = () => {
    let { pagination } = this.props;
    let { fromDate, toDate } = this.state;
    let currentPage = pagination["currentPage"] || 0;
    let totalPageCount = pagination["totalPageCount"] || 0;
    if (currentPage < totalPageCount) {
      this.props.getLedger(currentPage + 1, fromDate, toDate);
    } else {
      // console.log("No More Data Available")
    }
  };

  toggleChildView = (childLedger, needsToRevert) => {
    const { t } = this.props;
    return (
      <div className="child-row">
        <div>
          <p style={{ color: "#333" }} className="mb-0">
            {moment(childLedger.modifiedOn).format("Do MMM")} ( {t("Order Id")}{" "}
            -{" "}
            <span style={{ color: "orange" }}>{childLedger.orderCustomId}</span>{" "}
            )
          </p>
          <p style={{ color: "#333" }} className="mb-0">
            {childLedger.description}
          </p>
        </div>
        {needsToRevert && this.isSeller ? (
          childLedger.transactionType == "Debit" ? (
            <p className="mb-0 credit">₹ {childLedger.price} </p>
          ) : (
            <p className="mb-0 debit">₹ {childLedger.price} </p>
          )
        ) : childLedger.transactionType == "Debit" ? (
          <p className="mb-0 debit">₹ {childLedger.price} </p>
        ) : (
          <p className="mb-0 credit">₹ {childLedger.price} </p>
        )}
      </div>
    );
  };

  childLedgerView = (item = []) => {
    if (item.childLedgers.length) {
      return item.childLedgers.map((childLedger) => {
        let needsToRevert =
          this.currentUserId !== childLedger.transactionAccountId
            ? true
            : false;
        return this.toggleChildView(childLedger, needsToRevert);
      });
    } else if (item.parentLedger) {
      return this.toggleChildView(
        item.parentLedger,
        this.currentUserId !== item.parentLedger.transactionAccountId
          ? true
          : false
      );
    } else return null;
  };

  parentRowView = (item) => {
    const { t } = this.props;
    let needsToRevert =
      this.currentUserId !== item.transactionAccountId ? true : false;
    return (
      <div className="parent-row">
        <div>
          <p style={{ color: "#333" }} className="mb-0">
            {moment(item.modifiedOn).format("Do MMM")} ( {t("Order Id")} -{" "}
            <span style={{ color: "orange" }}>{item.orderCustomId}</span> )
          </p>
          <p style={{ color: "#333" }} className="mb-0">
            {item.description}
          </p>
        </div>
        {this.isSeller && needsToRevert ? (
          item.transactionType == "Debit" ? (
            <p className="mb-0 credit">₹ {item.price} </p>
          ) : (
            <p className="mb-0 debit">₹ {item.price} </p>
          )
        ) : item.transactionType == "Debit" ? (
          <p className="mb-0 debit">₹ {item.price} </p>
        ) : (
          <p className="mb-0 credit">₹ {item.price} </p>
        )}
      </div>
    );
  };
  
  scheduledParentRowView = (item) => {
    const { t } = this.props;
    let needsToRevert =
      this.currentUserId !== item.transactionAccountId ? true : false;
    return (
      <div className="parent-row">
        <div>
          <p style={{ color: "#333" }} className="mb-0">
            {moment(item.scheduleDateTime ).format("Do MMM YYYY")} ( {t("Order Id")} -{" "}
            <span style={{ color: "orange" }}>{item.orderCustomId}</span> )
          </p>
          <p style={{ color: "#333" }} className="mb-0">
            {item.description}
          </p>
        </div>
        {this.isSeller && needsToRevert ? (
            <p className="mb-0 credit">₹ {item.price} </p>
        ) : (
          <p className="mb-0 credit">₹ {item.price} </p>
        )}
      </div>
    );
  };

  renderGeneralLeadger = () => {
    const { ledger, pagination, t } = this.props;
    let currentPage = pagination["currentPage"] || 0;
    let totalPageCount = pagination["totalPageCount"] || 0;
    if (!pagination.currentPage) {
      return (
        <div className="center-view">
          <p style={{ color: "#333" }}>{t("Please select time duration")}</p>
        </div>
      );
    } else if (this.state.loader) {
      return (
        <div className="center-view">
          <Spin size="large" />
        </div>
      );
    } else if (ledger.length) {
      return (
        <div>
          <Collapse
            accordion
            bordered={true}
            // defaultActiveKey={['0']}
            expandIconPosition={"right"}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            className="site-collapse-custom-collapse"
          >
            {this.props.ledger.map((item, index) => {
              return (
                <Panel
                  header={this.parentRowView(item)}
                  key={index.toString()} 
                  className="site-collapse-custom-panel"
                >
                  <p>{this.childLedgerView(item)}</p>
                </Panel>
              );
            })}
          </Collapse>
          {currentPage && currentPage < totalPageCount ? (
            <div style={{ margin: "24px 0px", textAlign: "center" }}>
              <Button type="primary" key="home" onClick={this.loadMoreData}>
                {t("Load more")}
              </Button>
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div className="center-view">
          <p style={{ color: "#333" }}>{t("No entries found")}</p>
        </div>
      );
    }
  };

  callback = (key) => {
    switch (key) {
      case "":
        this.props.history.push({ pathname: "/your-orders" });
        return this.setState({ status: "general", ordersData: [] }, () => {
          this.ordersList();
        });
      default:
        return;
    }
  };

  render() {
    const { t } = this.props;
    const {
      scheduledTransactionData,
      scheduledTransactionCount,
      totalCreditPrice,
      totalDebitPrice,
    } = this.state;
    const ledger = () => {
      return (
        <div className="content">
          <div className="topContainer">
            <div className="d-flex justify-content-between align-items-center">
              <Space direction="horizontal" size={12}>
                <DatePicker
                  disabledDate={this.disabledFromDate}
                  placeholder={t("Start Date")}
                  showToday={false}
                  onChange={this.fromDateHandler}
                />
                <DatePicker
                  disabledDate={this.disabledToDate}
                  placeholder={t("End Date")}
                  showToday={false}
                  onChange={this.toDateHandler}
                />
              </Space>

              <div>
                <Input
                  placeholder={`${t("Order Id")}...`}
                  value={this.state.lookForOrderID}
                  onChange={(e) => this.handleOrderIdInput(e.target.value)}
                />
              </div>

              <Button type="primary" key="home" onClick={this.hitSearch}>
                {t("SEARCH")}
              </Button>
            </div>
          </div>
          <div className="ledgerContent">{this.renderGeneralLeadger()}</div>
        </div>
      );
    };
    let scheduledTransaction = () => {
      return (
        <div className="content">
          <div className="topContainer">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between w-100 ">
                <h4>{t('Total Credit')} : {totalCreditPrice}</h4>
                <h4>{t('Total Debit')} : {totalDebitPrice}</h4>
              </div>
            </div>
          </div>
          <div className="ledgerContent">
            <Collapse
              bordered={true}
              // defaultActiveKey={['0']}
              expandIconPosition={"right"}
              expandIcon={({ isActive }) => <div></div>}
              className="site-collapse-custom-collapse"
            >
              {scheduledTransactionData.map((item, index) => {
                return (
                  <Panel header={this.scheduledParentRowView(item)} key={index}></Panel>
                );
              })}
            </Collapse>
          </div>
          {scheduledTransactionCount > 10 &&
            scheduledTransactionCount < scheduledTransactionData.length && (
              <Button style={{ width: "40%", marginLeft: "30%" }} onClick={()=> this.getScheduledData()}>
                {t("Load more")}
              </Button>
            )}
        </div>
      );
    };
    return (
      <LayoutComponent title={t("Finance")}>
        <Row>
          <Col span={24}>
            <Tabs
              className="tabHeader"
              defaultActiveKey={"general"}
              size={"large"}
              onChange={this.callback}
            >
              <TabPane tab={t("General Ledger")} key="general">
                {ledger()}
              </TabPane>
              <TabPane tab={t("Scheduled")} key="scheduled">
                {scheduledTransaction()}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </LayoutComponent>
    );
  }
}

const mapStateToProps = ({ ledger, global }) => {
  return {
    ledger: ledger.ledger,
    results: ledger.results,
    pagination: ledger.pagination,
    loader: ledger.loader,
    currentUser: global.currentUser || {},
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    getLedger,
  })(Ledger)
);